/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DashboardResponseDto } from '../models/dashboard-response-dto';
import { WhatsappDeliveryStatusRequestDto } from '../models/whatsapp-delivery-status-request-dto';
import { WhatsappStatusCardsResponseDto } from '../models/whatsapp-status-cards-response-dto';
import { WhatsappStatusPiechartResponseDto } from '../models/whatsapp-status-piechart-response-dto';

@Injectable({
  providedIn: 'root',
})
export class WhatsappDashboardControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getWhatsappDeliveryStatusPieChart
   */
  static readonly GetWhatsappDeliveryStatusPieChartPath = '/whatsappdashboard/piechart';

  /**
   * Get WhatsApp delivery status.
   *
   * The API to get WhatsApp delivery Status to depict Piechart
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhatsappDeliveryStatusPieChart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappDeliveryStatusPieChart$Response(params?: {
    body?: WhatsappDeliveryStatusRequestDto
  }): Observable<StrictHttpResponse<Array<WhatsappStatusPiechartResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDashboardControllerService.GetWhatsappDeliveryStatusPieChartPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhatsappStatusPiechartResponseDto>>;
      })
    );
  }

  /**
   * Get WhatsApp delivery status.
   *
   * The API to get WhatsApp delivery Status to depict Piechart
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWhatsappDeliveryStatusPieChart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappDeliveryStatusPieChart(params?: {
    body?: WhatsappDeliveryStatusRequestDto
  }): Observable<Array<WhatsappStatusPiechartResponseDto>> {

    return this.getWhatsappDeliveryStatusPieChart$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WhatsappStatusPiechartResponseDto>>) => r.body as Array<WhatsappStatusPiechartResponseDto>)
    );
  }

  /**
   * Path part for operation getWhatsappDeliveryStatusLineChart
   */
  static readonly GetWhatsappDeliveryStatusLineChartPath = '/whatsappdashboard/linechart';

  /**
   * Get WhatsApp delivery status.
   *
   * The API to get WhatsApp delivery Status to depict Linechart
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhatsappDeliveryStatusLineChart()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappDeliveryStatusLineChart$Response(params?: {
    body?: WhatsappDeliveryStatusRequestDto
  }): Observable<StrictHttpResponse<Array<DashboardResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDashboardControllerService.GetWhatsappDeliveryStatusLineChartPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DashboardResponseDto>>;
      })
    );
  }

  /**
   * Get WhatsApp delivery status.
   *
   * The API to get WhatsApp delivery Status to depict Linechart
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWhatsappDeliveryStatusLineChart$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappDeliveryStatusLineChart(params?: {
    body?: WhatsappDeliveryStatusRequestDto
  }): Observable<Array<DashboardResponseDto>> {

    return this.getWhatsappDeliveryStatusLineChart$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DashboardResponseDto>>) => r.body as Array<DashboardResponseDto>)
    );
  }

  /**
   * Path part for operation getWhatsappDeliveryStatusCards
   */
  static readonly GetWhatsappDeliveryStatusCardsPath = '/whatsappdashboard/cards';

  /**
   * Get WhatsApp delivery status.
   *
   * The API to get WhatsApp delivery Status to depict Cards
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhatsappDeliveryStatusCards()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappDeliveryStatusCards$Response(params?: {
    body?: WhatsappDeliveryStatusRequestDto
  }): Observable<StrictHttpResponse<Array<WhatsappStatusCardsResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappDashboardControllerService.GetWhatsappDeliveryStatusCardsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhatsappStatusCardsResponseDto>>;
      })
    );
  }

  /**
   * Get WhatsApp delivery status.
   *
   * The API to get WhatsApp delivery Status to depict Cards
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWhatsappDeliveryStatusCards$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappDeliveryStatusCards(params?: {
    body?: WhatsappDeliveryStatusRequestDto
  }): Observable<Array<WhatsappStatusCardsResponseDto>> {

    return this.getWhatsappDeliveryStatusCards$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WhatsappStatusCardsResponseDto>>) => r.body as Array<WhatsappStatusCardsResponseDto>)
    );
  }

}
