<!-- For Next PI Release -->

<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <span class="heading-label">
          <img class="navigate-back" (click)="navigateBack()" [src]="backImage" />
          <img class="page-icon-small" [src]="whatsappIcon" />
          <span>{{'WHATSAPP_DATA_ANALYTICS' | translate}}</span>
        </span>
      </div>
      <form class="form">
        <div [formGroup]="dateRangeForm" class="form-fields">
          <div class="input-field">
            <mat-form-field class="text">
              <mat-label class="label"></mat-label>
              <mat-select [formControlName]="'dateRange'" (selectionChange)="onDateRangeSelection($event)">
              <mat-option *ngFor="let val of dateRangeList" [value]="val.id">
                  {{val['label'] | translate}}</mat-option>
          </mat-select>
          </mat-form-field>
          </div>

          <div *ngIf="dateRangeForm.controls['fromDate']">
            <mat-form-field>
              <mat-label class="label">
            </mat-label>
              <input (dateChange)="checkDate()" matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [readonly]="true"  [placeholder]="'FROM_DATE' | translate " [formControlName]="'fromDate'">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div *ngIf="dateRangeForm.controls['fromDate'] && dateRangeForm.controls['fromDate'].touched && dateRangeForm.controls['fromDate'].errors">
            <div class="error-message" *ngIf="dateRangeForm.controls['fromDate'].errors?.invalidDate">
              <mat-error>{{'FROMDATE_LESSTHAN_TODATE' | translate }}</mat-error>
          </div>
          </div>
         </div>

         <div *ngIf="dateRangeForm.controls['toDate']"> 
          <mat-form-field>
            <mat-label class="label"></mat-label>
            <input (dateChange)="checkDate()" matInput [placeholder]="'TO_DATE' | translate" [matDatepicker]="picker" [readonly]="true"  [min]="minDate" [max]="maxDate" [formControlName]="'toDate'">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
         </div>
        </div>
        </form>
        <div class="form-spacing" #cardsContainer>
          <div>
          <app-dynamic-form [jsonFile]="'whatsapp-analytics'"
          [gridLayout]="'grid-container-whatsapp-analytics'" [dropDownValues]="dropDownValues"  (valueSelected)="updateDropdownValues($event)"
           [hideSubmitButton]="true" [emitValueAfterReset]="false"></app-dynamic-form>
           </div>
           <div>
            <button mat-button (click)="onReset()" class="button">{{
              'RESET' | translate }}</button>
           </div>

        </div>
<div class="cards">
  <mat-card [ngClass]="value['class']" *ngFor="let value of cards">
    <mat-card-header>
      <mat-card-title>{{value['title']| translate}}</mat-card-title>
    </mat-card-header>
    <mat-card-content >
            <span class="header-count">{{value['deliveryStatusCount']}}</span>
            <span  class="header-title">{{ value['deliveryStatus'] === 'SENT' ? ('SENT_CAPS' | translate) : 
              value['deliveryStatus'] === 'FAILED' ? ('FAILED_CAPS' | translate) :
              value['deliveryStatus'] === 'DELIVERED' ? ('DELIVERED_CAPS' | translate) :
              value['deliveryStatus'] === 'READ' ? ('READ_CAPS' | translate) : '' }}
            </span>
            <span class="header-percentage">{{value['deliveryStatusPercentage']}}</span>
    </mat-card-content>
    <mat-card-footer>
      <div>
        <span class="footer-data title">{{'SERVICE' | translate}}</span>
        <span class="footer-data count">{{value['serviceCount']}}</span>
    </div>
      <div>
          <span class="footer-data title">{{'MARKETING' | translate}}</span>
          <span class="footer-data count">{{value['marketingCount']}}</span>
      </div>

      <div>
        <span class="footer-data title">{{'UTILITY' | translate}}</span>
        <span class="footer-data count">{{value['utilityCount']}}</span>
    </div>
    <div>
      <span class="footer-data title">{{'AUTH' | translate}}</span>
      <span class="footer-data count">{{value['authenticationCount']}}</span>
  </div>
   </mat-card-footer>
  </mat-card>
</div>
<div class="charts">
  <div #pieChart class="pie-chart"></div>
  <div [hidden]="!showLineChart" class="line-chart-title">{{chartTitle | translate}} <div #lineChart></div></div>
</div>
    <div class="clear-float"></div>
    </section>
    </section>