/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SubscriptionBasicDto } from '../models/subscription-basic-dto';
import { SubscriptionConnStringDto } from '../models/subscription-conn-string-dto';
import { SubscriptionDto } from '../models/subscription-dto';
import { SubscriptionInformationDto } from '../models/subscription-information-dto';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSubscriptionByBoschIoSolutionId
   */
  static readonly GetSubscriptionByBoschIoSolutionIdPath = '/api/v1/solutions/{subscriptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubscriptionByBoschIoSolutionId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionByBoschIoSolutionId$Response(params: {
    subscriptionId: string;
  }): Observable<StrictHttpResponse<SubscriptionInformationDto>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.GetSubscriptionByBoschIoSolutionIdPath, 'get');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionInformationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubscriptionByBoschIoSolutionId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionByBoschIoSolutionId(params: {
    subscriptionId: string;
  }): Observable<SubscriptionInformationDto> {

    return this.getSubscriptionByBoschIoSolutionId$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionInformationDto>) => r.body as SubscriptionInformationDto)
    );
  }

  /**
   * Path part for operation generateSubcriptionConnString
   */
  static readonly GenerateSubcriptionConnStringPath = '/api/v1/subscriptionconnstring';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateSubcriptionConnString()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateSubcriptionConnString$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<SubscriptionConnStringDto>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.GenerateSubcriptionConnStringPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionConnStringDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateSubcriptionConnString$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateSubcriptionConnString(params?: {
    body?: string
  }): Observable<SubscriptionConnStringDto> {

    return this.generateSubcriptionConnString$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionConnStringDto>) => r.body as SubscriptionConnStringDto)
    );
  }

  /**
   * Path part for operation getSubscriptionByProfileAndSolDevIntfProv
   */
  static readonly GetSubscriptionByProfileAndSolDevIntfProvPath = '/api/v1/subscription/solDevIntfcProvId/{solDevIntfcProvId}/profileId/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubscriptionByProfileAndSolDevIntfProv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionByProfileAndSolDevIntfProv$Response(params: {
    solDevIntfcProvId: string;
    profileId: string;
  }): Observable<StrictHttpResponse<Array<SubscriptionBasicDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.GetSubscriptionByProfileAndSolDevIntfProvPath, 'get');
    if (params) {
      rb.path('solDevIntfcProvId', params.solDevIntfcProvId, {});
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubscriptionBasicDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubscriptionByProfileAndSolDevIntfProv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionByProfileAndSolDevIntfProv(params: {
    solDevIntfcProvId: string;
    profileId: string;
  }): Observable<Array<SubscriptionBasicDto>> {

    return this.getSubscriptionByProfileAndSolDevIntfProv$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionBasicDto>>) => r.body as Array<SubscriptionBasicDto>)
    );
  }

  /**
   * Path part for operation getSubscriptionDetailsByProfileAndSolDevIntfProv
   */
  static readonly GetSubscriptionDetailsByProfileAndSolDevIntfProvPath = '/api/v1/subscriptiondetails/solDevIntfcProvId/{solDevIntfcProvId}/profileId/{profileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubscriptionDetailsByProfileAndSolDevIntfProv()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionDetailsByProfileAndSolDevIntfProv$Response(params: {
    solDevIntfcProvId: string;
    profileId: string;
  }): Observable<StrictHttpResponse<Array<SubscriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.GetSubscriptionDetailsByProfileAndSolDevIntfProvPath, 'get');
    if (params) {
      rb.path('solDevIntfcProvId', params.solDevIntfcProvId, {});
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubscriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubscriptionDetailsByProfileAndSolDevIntfProv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionDetailsByProfileAndSolDevIntfProv(params: {
    solDevIntfcProvId: string;
    profileId: string;
  }): Observable<Array<SubscriptionDto>> {

    return this.getSubscriptionDetailsByProfileAndSolDevIntfProv$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionDto>>) => r.body as Array<SubscriptionDto>)
    );
  }

  /**
   * Path part for operation getSubscription
   */
  static readonly GetSubscriptionPath = '/api/v1/subscriptions/{subscriptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubscription()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscription$Response(params: {
    subscriptionId: string;
  }): Observable<StrictHttpResponse<SubscriptionDto>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.GetSubscriptionPath, 'get');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubscription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscription(params: {
    subscriptionId: string;
  }): Observable<SubscriptionDto> {

    return this.getSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionDto>) => r.body as SubscriptionDto)
    );
  }

  /**
   * Path part for operation saveSubscription
   */
  static readonly SaveSubscriptionPath = '/api/v1/solutiontype/{solutionTypeId}/subscriptions/{subscriptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSubscription$Response(params: {
    solutionTypeId: string;
    subscriptionId: string;
    body?: SubscriptionDto
  }): Observable<StrictHttpResponse<SubscriptionDto>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.SaveSubscriptionPath, 'post');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.path('subscriptionId', params.subscriptionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSubscription(params: {
    solutionTypeId: string;
    subscriptionId: string;
    body?: SubscriptionDto
  }): Observable<SubscriptionDto> {

    return this.saveSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionDto>) => r.body as SubscriptionDto)
    );
  }

  /**
   * Path part for operation updateSubscription
   */
  static readonly UpdateSubscriptionPath = '/api/v1/solutiontype/{solutionTypeId}/subscriptions/{subscriptionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSubscription$Response(params: {
    solutionTypeId: string;
    subscriptionId: string;
    body?: SubscriptionDto
  }): Observable<StrictHttpResponse<SubscriptionDto>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.UpdateSubscriptionPath, 'patch');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.path('subscriptionId', params.subscriptionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSubscription(params: {
    solutionTypeId: string;
    subscriptionId: string;
    body?: SubscriptionDto
  }): Observable<SubscriptionDto> {

    return this.updateSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionDto>) => r.body as SubscriptionDto)
    );
  }

  /**
   * Path part for operation getAllSubscriptions
   */
  static readonly GetAllSubscriptionsPath = '/api/v1/subscriptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSubscriptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSubscriptions$Response(params?: {
  }): Observable<StrictHttpResponse<Array<SubscriptionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.GetAllSubscriptionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubscriptionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSubscriptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSubscriptions(params?: {
  }): Observable<Array<SubscriptionDto>> {

    return this.getAllSubscriptions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionDto>>) => r.body as Array<SubscriptionDto>)
    );
  }

  /**
   * Path part for operation getAllSubscriptionsBasic
   */
  static readonly GetAllSubscriptionsBasicPath = '/api/v1/subscriptionsbasic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSubscriptionsBasic()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSubscriptionsBasic$Response(params?: {
    solutionTypeId?: string;
    providerId?: string;
  }): Observable<StrictHttpResponse<Array<SubscriptionBasicDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.GetAllSubscriptionsBasicPath, 'get');
    if (params) {
      rb.query('solutionTypeId', params.solutionTypeId, {});
      rb.query('providerId', params.providerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubscriptionBasicDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSubscriptionsBasic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSubscriptionsBasic(params?: {
    solutionTypeId?: string;
    providerId?: string;
  }): Observable<Array<SubscriptionBasicDto>> {

    return this.getAllSubscriptionsBasic$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionBasicDto>>) => r.body as Array<SubscriptionBasicDto>)
    );
  }

}
