import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { FotaManagementComponent } from './fota-management/fota-management.component';
import { CreateFotaComponent } from './create-fota/create-fota.component';
import { FotaJobDetailsComponent } from './fota-job-details/fota-job-details.component';

/**
  * @ngdoc overview
  * @name MODULE_NAME.module:FotaModule
  *
  * @description
  * description: To route to different components in fota module based on the path given
*/
const routes: Routes = [
  {
    path: 'fota/manage-job-completed',
    component: FotaManagementComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'DEV_FOTA_R', data: {} }
  },
  {
    path: 'fota/manage-job-inprogress',
    component: CreateFotaComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'DEV_FOTA_C', data: {} }
  },
  {
    path: 'job-details',
    component: FotaJobDetailsComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'DEV_FOTA_C', data: {} }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FotaRoutingModule { }
