import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  WHATSAPP_ICON,
  CHEVRON_LEFT,
  INFO_ICON_IMAGE,
  DOWNLOAD_ICON_SMALL,
  SUPPORTED_WHATSAPP_DOCUMENT_FORMAT,
  SUPPORTED_WHATSAPP_IMAGE_FORMAT,
  SUPPORTED_WHATSAPP_VIDEO_FORMAT,
  WHATSAPP_DARK_BACKGROUND,
  WHATSAPP_LIGHT_BACKGROUND,
  WHATSAPP_DOCUMENT,
  WHATSAPP_LOCATION, CLOSE_ICON
} from 'src/app/shared/constants/strings';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';
import { WhatsappProfileConnectionDetailsControllerService, WhatsappTemplateControllerService } from 'src/app/services/Whatsapp/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BciLoaderService } from '@bci-web-core/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-whatsapp-template',
  templateUrl: './create-whatsapp-template.component.html',
  styleUrls: ['./create-whatsapp-template.component.scss']
})
export class CreateWhatsappTemplateComponent implements OnInit, OnDestroy {
  public permissionList: Array<String> = [];
  public jsonFileLoaded;
  public whatsappIcon;
  public downloadIcon;
  public backImage;
  public infoIcon;
  public submitted = false;
  public showSpinner:any;
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public templateId: string;
  public fileForUpload;
  public invalidFile;
  public uploadedFileName;
  public fileUrl;
  public videoFormat;
  public imageFormat;
  public documentFormat;
  public supportedMediaType;
  public callPhoneType;
  public selectedTypeOfActionOne;
  public selectedTypeOfActionTwo;
  public disablePointer = false;
  public templateForm: FormGroup;
  public quickReplyFormArray: FormArray;
  public callToActionFormArray: FormArray;
  public dataLoaded = new BehaviorSubject<any>(false);
  public maxCharacterContentCount = 1024;
  public characterContentCount = 0;
  public maxCharacterFooterCount = 60;
  public characterFooterCount = 0;
  showEmojiPicker = false;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ];
  set = 'twitter';
public lightMode = true;
public optionsPopup = false;
public whatsAppDocumentImg;
public whatsAppLocationImg;
public whatsAppDarkImg;
public whatsAppLightImg;
public currentTime;
public closeIcon;
public disableFooterPointer = false;
public channelId: string;
  constructor(
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private route: ActivatedRoute,
    private responseHandlerService: ResponseHandlerService,
    private _matDialog: MatDialog,
    private whatsappTemplateControllerService: WhatsappTemplateControllerService,
    private router: Router,
    private loaderService: BciLoaderService, private translate: TranslateService,
    private whatsappProfileConnectionDetailsControllerService: WhatsappProfileConnectionDetailsControllerService,

  ) {}

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.jsonFileLoaded = 'whatsapp-create-template';
    this.whatsappIcon = WHATSAPP_ICON;
    this.backImage = CHEVRON_LEFT;
    this.infoIcon = INFO_ICON_IMAGE;
    this.downloadIcon = DOWNLOAD_ICON_SMALL;
    this.videoFormat = SUPPORTED_WHATSAPP_VIDEO_FORMAT;
    this.documentFormat = SUPPORTED_WHATSAPP_DOCUMENT_FORMAT;
    this.imageFormat = SUPPORTED_WHATSAPP_IMAGE_FORMAT;
    this.whatsAppDarkImg = WHATSAPP_DARK_BACKGROUND;
    this.whatsAppLightImg = WHATSAPP_LIGHT_BACKGROUND;
    this.whatsAppDocumentImg = WHATSAPP_DOCUMENT;
    this.whatsAppLocationImg = WHATSAPP_LOCATION;
    this.closeIcon = CLOSE_ICON;

    this.currentTime = new Date();
    this.accountId = this.route.snapshot.paramMap.get('accountId');
    this.tenantId = this.route.snapshot.paramMap.get('tenantId');
    this.profileId = this.route.snapshot.paramMap.get('profileId');
    this.templateId = this.route.snapshot.paramMap.get('templateId');
    this.channelId = this.route.snapshot.paramMap.get('channelId');
    this.createTemplateForm();
    this.getTypeOfActions();
    this.getAccount();
    this.getLanguages();
    this.getTemplateCategories();
    this.getHeaderType();
    this.getButtonsType();
    this.loadRouteData();
  }

  public createTemplateForm() {
    this.templateForm = new FormGroup({
      'account': this.getFormControl(),
      'tenant': this.getFormControl(),
      'businessProfile': this.getFormControl(),
      'channel': this.getFormControl(),
      'language': this.getFormControl(),
      'category': this.getFormControl(),
      'whatsappTemplateName': new FormControl('', [
        Validators.required,
        Validators.pattern('^[a-z0-9_]+$')]),
      'whatsappTemplateContent': this.getFormControl(true, 1024),
      'footer': this.getFormControl(false, 60),
      'buttonsType': this.getFormControl(false),
      'headerType': this.getFormControl(false),
    });
  }

  public updateDropdownValues($event) {
    if ($event.value) {
      if ($event.hasOwnProperty('child')) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'channel') {
          this.profileId = $event.value;
          this.getChannel($event.value);
        }
        if ($event.child === 'language') {
          this.channelId = $event.value;
        }
        if ($event.child === 'header') {
          this.fileUrl = null;
          this.fileForUpload = null;
          this.uploadedFileName = null;
          this.supportedMediaType = null;
          this.removeFormControl('file');
          this.removeFormControl('header');
          if ($event.value['name'] === 'Text') {
            this.addFormControl('header', this.getFormControl(true, 60));
          } else {
            this.addFormControl('file', this.getFormControl());
            if ($event.value['name'] === 'Video') {
              this.supportedMediaType = this.videoFormat;
            }
            if ($event.value['name'] === 'Image') {
              this.supportedMediaType = this.imageFormat;
            }
            if ($event.value['name'] === 'Document') {
              this.supportedMediaType = this.documentFormat;
            }
            if ($event.value['name'] === 'Location') {
              this.removeFormControl('file');
              this.removeFormControl('header');
            }
          }
        }
        if ($event.child === 'buttonText') {
          this.templateForm.controls['quickReply']
            ? this.templateForm.controls['quickReply'].reset()
            : '';
          this.templateForm.controls['callToActionArr']
            ? this.templateForm.controls['callToActionArr'].reset()
            : '';
          this.quickReplyFormArray = new FormArray([
            new FormGroup({
              buttonText: this.getFormControl(true, 25),
            }),
          ]);
          this.callToActionFormArray = new FormArray([
            new FormGroup({
              typeOfAction: new FormControl(
                this.callPhoneType,
                Validators.required
              ),
            }),
          ]);
          this.removeFormControl('callToActionArr');
          this.removeFormControl('quickReply');
          this.selectedTypeOfActionOne = null;
          this.selectedTypeOfActionTwo = null;
          if ($event.value['name'] === 'Quick reply') {
            this.addFormControl('quickReply', this.quickReplyFormArray);
          } else {
            this.addFormControl('callToActionArr', this.callToActionFormArray);
            const setOption = {
              child: 'actionType',
              value: this.callPhoneType,
              index: 0
            };
            if (!this.disablePointer) {
              this.updateDropdownValues(setOption);
            }
          }
        }
        if ($event.child === 'actionType') {
          const actionFormGroup = this.templateForm.get(
            'callToActionArr'
          ) as FormArray;
          const formGroup = actionFormGroup['controls'][
            $event['index']
          ] as FormGroup;
          if ($event['index'] === 0) {
            this.selectedTypeOfActionOne = $event.value['name'];
          } else if ($event['index'] === 1) {
            this.selectedTypeOfActionTwo = $event.value['name'];
          }
          if ($event.value['name'] === 'Visit Website') {
            formGroup.removeControl('phoneNumber');
            formGroup.controls['buttonText']
              ? formGroup.controls['buttonText'].reset()
              : formGroup.addControl(
                  'buttonText',
                  this.getFormControl(true, 25)
                );
            formGroup.addControl('urlType', this.getFormControl());
            formGroup.addControl(
              'websiteUrl',
              new FormControl('', [
                Validators.required,
                Validators.pattern('^(http:\/\/|https:\/\/)(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$')]));
          } else {
            formGroup.controls['buttonText']
              ? formGroup.controls['buttonText'].reset()
              : formGroup.addControl(
                  'buttonText',
                  this.getFormControl(true, 25)
                );
            formGroup.removeControl('urlType');
            formGroup.removeControl('websiteUrl');
            formGroup.addControl(
              'phoneNumber',
              new FormControl('', [
                Validators.required,
                Validators.pattern(/^\+[1-9][0-9]{1,14}$/),
              ])
            );
          }
        }
      }
    } else {
      if ($event.child === 'header') {
        this.fileForUpload = null;
        this.uploadedFileName = null;
        this.supportedMediaType = null;
        this.removeFormControl('header');
        this.removeFormControl('file');
      }
      if ($event.child === 'buttonText') {
        this.templateForm.controls['quickReply']
          ? this.templateForm.controls['quickReply'].reset()
          : '';
        this.quickReplyFormArray = new FormArray([
          new FormGroup({
            buttonText: this.getFormControl(true, 25),
          }),
        ]);
        this.removeFormControl('quickReply');
        this.templateForm.controls['callToActionArr']
          ? this.templateForm.controls['callToActionArr'].reset()
          : '';
        this.callToActionFormArray = new FormArray([
          new FormGroup({
            callToAction: this.getFormControl(),
          }),
        ]);
        this.removeFormControl('callToActionArr');
        this.selectedTypeOfActionOne = null;
        this.selectedTypeOfActionTwo = null;
      }
      if ($event.child === 'actionType') {
        const actionFormGroup = this.templateForm.get(
          'callToActionArr'
        ) as FormArray;
        const formGroup = actionFormGroup['controls'][
          $event['index']
        ] as FormGroup;
        formGroup.removeControl('phoneNumber');
        formGroup.removeControl('buttonText');
        formGroup.removeControl('urlType');
        formGroup.removeControl('websiteUrl');
        if ($event['index'] === 0) {
          this.selectedTypeOfActionOne = null;
        } else {
          this.selectedTypeOfActionTwo = null;
        }
      }
    }
  }

  public onSubmit() {
    this.submitted = true;
    let phoneNumberAction;
    let visitWebsiteAction;
    if (this.templateForm.valid) {
      this.showSpinner = this.loaderService.showProgressBar();
      const formValue = this.templateForm.value;
      if (formValue['callToActionArr'] && formValue['callToActionArr'].length) {
        formValue['callToActionArr'].forEach((element) => {
          if (element['typeOfAction']['name'] === 'Call Phone Number') {
            phoneNumberAction = element;
          } else {
            visitWebsiteAction = element;
          }
        });
      }
      const reqBody = {
        categoryDetails: {
          id: formValue['category'],
        },
        languageDetails: {
          id: formValue['language']
        },
        templateName: formValue['whatsappTemplateName'],
        templateContent: formValue['whatsappTemplateContent'],
        footerText: formValue['footer'],
        templateHeaderDto: {
          id: formValue['headerType'] ? formValue['headerType']['id'] : null,
          headerName: formValue['headerType']
            ? formValue['headerType']['name'] : null,
          text: formValue['header'] ? formValue['header'] : null,
        },
        templateButtonTypeDto: {
          buttonTypeName: formValue['buttonsType']
            ? formValue['buttonsType']['name'] : null,
          firstButton:
            formValue['quickReply'] && formValue['quickReply'][0]
              ? formValue['quickReply'][0]['buttonText'] : null,
          secondButton:
            formValue['quickReply'] && formValue['quickReply'][1]
              ? formValue['quickReply'][1]['buttonText'] : null,
          thirdButton:
            formValue['quickReply'] && formValue['quickReply'][2]
              ? formValue['quickReply'][2]['buttonText'] : null,
          callToAction: {
            callPhoneNumberButtontype: {
              buttonSupportTypeName:
                phoneNumberAction && phoneNumberAction['typeOfAction']
                  ? phoneNumberAction['typeOfAction']['name'] : null,
              buttonText:
                phoneNumberAction && phoneNumberAction['buttonText']
                  ? phoneNumberAction['buttonText'] : null,
              phoneNumber:
                phoneNumberAction && phoneNumberAction['phoneNumber']
                  ? phoneNumberAction['phoneNumber'] : null,
            },
            visitWebsiteButtonType: {
              buttonSupportTypeName:
                visitWebsiteAction && visitWebsiteAction['typeOfAction']
                  ? visitWebsiteAction['typeOfAction']['name'] : null,
              buttonText:
                visitWebsiteAction && visitWebsiteAction['buttonText']
                  ? visitWebsiteAction['buttonText'] : null,
              websiteUrl:
                visitWebsiteAction && visitWebsiteAction['websiteUrl']
                  ? visitWebsiteAction['websiteUrl'] : null,
              isLinkTrackingEnabled:
                visitWebsiteAction && visitWebsiteAction['urlType']
                  ? visitWebsiteAction['urlType'] : false,
            },
          },
        },
      };
      let grpData;
      const reqObj = {
        channelId: this.channelId,
        body: {
          templateMetaData: JSON.stringify(reqBody),
          file: formValue['file'] ? formValue['file'] : null,
        },
      };
      grpData = reqObj;
      this.whatsappTemplateControllerService
        .createWhatsAppTemplate(grpData)
        .subscribe(
          (resp) => {
            if (resp) {
              this.submitted = false;
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'success',
                'WHATSAPP_TEMPLATE_CREATED_SUCCESSFULLY'
              );
            }
          },
          (err) => {
            this.submitted = false;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }
  }

  public getTemplateDetails() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.dataLoaded.subscribe((result) => {
      if (result) {
        this.whatsappTemplateControllerService
          .fetchWhatsAppTemplateDetails({ templateId: this.templateId })
          .subscribe(
            (resp) => {
              if (resp) {
                const header = resp['templateHeaderDto'];
                const buttonType = resp['templateButtonTypeDto'];
                const callToAction =
                  buttonType && buttonType['callToAction']
                    ? buttonType['callToAction'] : null;
                const callPhoneNumberButton =
                  buttonType &&
                  callToAction &&
                  callToAction['callPhoneNumberButtontype']
                    ? callToAction['callPhoneNumberButtontype'] : null;
                const visitWebsiteButton =
                  buttonType &&
                  callToAction &&
                  callToAction['visitWebsiteButtonType']
                    ? callToAction['visitWebsiteButtonType'] : null;
                const quickReplyArray = [];
                const callToActionArray = [];
                if (header) {
                  this.updateDropdownValues({
                    child: 'header',
                    value: { id: header['id'], name: header['headerName'] },
                  });
                  if (header['headerName'] !== 'Text' && header['headerUrl']) {
                    this.fileUrl = header['headerUrl'];
                  }
                }
                if (buttonType) {
                  if (buttonType['buttonTypeName'] === 'Quick reply') {
                    this.updateDropdownValues({
                      child: 'buttonText',
                      value: {
                        id: buttonType['id'],
                        name: buttonType['buttonTypeName'],
                      },
                    });
                    if (buttonType['firstButton']) {
                      quickReplyArray.push({
                        buttonText: buttonType['firstButton'],
                      });
                    }
                    if (buttonType['secondButton']) {
                      this.addFormGroup('quickReply');
                      quickReplyArray.push({
                        buttonText: buttonType['secondButton'],
                      });
                    }
                    if (buttonType['thirdButton']) {
                      this.addFormGroup('quickReply');
                      quickReplyArray.push({
                        buttonText: buttonType['thirdButton'],
                      });
                    }
                  } else if (
                    buttonType['buttonTypeName'] === 'Call to action' &&
                    (visitWebsiteButton || callPhoneNumberButton)
                  ) {
                    this.updateDropdownValues({
                      child: 'buttonText',
                      value: { name: buttonType['buttonTypeName'] },
                    });
                    if (callPhoneNumberButton) {
                      visitWebsiteButton &&
                      callPhoneNumberButton &&
                      callToActionArray.length
                        ? this.addFormGroup('callToActionArr') : '';
                      this.updateDropdownValues({
                        child: 'actionType',
                        value: {
                          name: callPhoneNumberButton['buttonSupportTypeName'],
                          buttontypeId: buttonType['id'],
                        },
                        index: callToActionArray.length ? 1 : 0,
                      });
                      if (callToAction && callPhoneNumberButton) {
                        callToActionArray.push({
                          typeOfAction: {
                            id: callToAction['id'],
                            name: callPhoneNumberButton[
                              'buttonSupportTypeName'
                            ],
                            buttontypeId: buttonType['id'],
                          },
                          buttonText: callPhoneNumberButton['buttonText'],
                          phoneNumber: callPhoneNumberButton['phoneNumber'],
                        });
                      }
                    }
                    if (visitWebsiteButton) {
                      visitWebsiteButton &&
                      callPhoneNumberButton &&
                      callToActionArray.length
                        ? this.addFormGroup('callToActionArr') : '';
                      this.updateDropdownValues({
                        child: 'actionType',
                        value: {
                          name: visitWebsiteButton['buttonSupportTypeName'],
                        },
                        index: callToActionArray.length ? 1 : 0,
                      });
                      if (callToAction && visitWebsiteButton) {
                        callToActionArray.push({
                          typeOfAction: {
                            id: callToAction['id'],
                            name: visitWebsiteButton['buttonSupportTypeName'],
                            buttontypeId: buttonType['id'],
                          },
                          buttonText: visitWebsiteButton['buttonText'],
                          websiteUrl: visitWebsiteButton['websiteUrl'],
                          urlType: visitWebsiteButton['linkTrackingEnabled'],
                        });
                      }
                    }
                  }
                }
                const formValue = {
                  account: this.accountId,
                  tenant: this.tenantId,
                  businessProfile: this.profileId,
                  channel:this.channelId,
                  language: resp['languageDetails']['id'],
                  category: resp['categoryDetails']['id'],
                  whatsappTemplateName: resp['templateName'],
                  whatsappTemplateContent: resp['templateContent'],
                  headerType: header
                    ? {
                        id: header['id'],
                        name: header['headerName'],
                      }
                    : null,
                  header: header && header['text'] ? header['text'] : null,
                  buttonsType: buttonType
                    ? {
                        id: buttonType['id'],
                        name: buttonType['buttonTypeName'],
                      }
                    : null,
                  quickReply:
                    quickReplyArray && quickReplyArray.length
                      ? quickReplyArray : null,
                  callToActionArr:
                    callToActionArray && callToActionArray.length
                      ? callToActionArray : null,
                  footer: resp['footerText'] ? resp['footerText'] : null,
                };
                this.characterFooterCount = resp['footerText']?.length;
                this.characterContentCount = resp['templateContent'].length;
                this.templateForm.patchValue(formValue);
                this.loaderService.hideProgressBar(this.showSpinner);
              }
            },
            (err) => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'error',
                err.error.message
              );
            }
          );
      }
    });
  }

  public OpenUploadDialog(content) {
    this.invalidFile = true;
    this._matDialog.open(content, { disableClose: true });
  }

  public selectFiles(event) {
    if (event.target.files && event.target.files.length > 0) {
      if (this.validateMediaType(event.target.files[0])) {
        /** Code for Validating the duration and size of the video might be required in future
        if( this.validateMediaSize(event.target.files[0],this.supportedMediaType === this.videoFormat?2097152:20000)) {
           if(this.supportedMediaType === this.videoFormat) {
            this.checkValidDuration(event.target.files[0]).then(() => {
              this.invalidFile = false;
              this.fileForUpload = event.target.files[0];
            }).catch((error) => {
              this.invalidFile = true;
              this.responseHandlerService.returnToastMessage('error',`THE_MAXIMUM_DURATION_OF_VIDEO_SHOULD_NOT_BE_GREATER_THAN_30_SECONDS`);
            });
          } else {
            this.fileForUpload = event.target.files[0];
          }
        } else {
          if(this.supportedMediaType === this.videoFormat) {
            this.responseHandlerService.returnToastMessage('error',`THE_MAXIMUM_SIZE_OF_VIDEO_SHOULD_NOT_BE_GREATER_THAN_16_MB`);
          } else {
            this.responseHandlerService.returnToastMessage('error',`THE_MAXIMUM_SIZE_OF_IMAGE_SHOULD_NOT_BE_GREATER_THAN_16_MB`);
          }
        }*/

        if(this.supportedMediaType === this.videoFormat && !this.validateMediaSize(event.target.files[0], 104857600)) {
          this.responseHandlerService.returnToastMessage('error','THE_MAXIMUM_SIZE_OF_VIDEO_SHOULD_NOT_BE_GREATER_THAN_100_MB');
        } else {
          this.fileForUpload = event.target.files[0];
          const reader = new FileReader();
          reader.onload = () => {
            this.fileUrl = reader.result as string;
          };
          reader.readAsDataURL(this.fileForUpload);
        }
      } else {
        this.responseHandlerService.returnToastMessage(
          'error',
          `PLEASE_UPLOAD_VALID_${
            this.supportedMediaType === this.imageFormat
              ? 'IMAGE'
              : this.supportedMediaType === this.videoFormat
              ? 'MP4_OR_M4V'
              : 'PDF'
          }_FILE`
        );
      }
    }
  }

  public validateMediaType(file) {
    if (this.supportedMediaType) {
      const mediaTypes = this.supportedMediaType.split(',');
      if (mediaTypes.includes(`.${file.name.split('.').pop()}`)) {
        this.invalidFile = false;
        return true;
      } else {
        this.invalidFile = true;
        return false;
      }
    }
  }

  /** Code for Validating the duration and size of the video might be required in future
  public validateMediaSize(file,size) {
    if(file.size <= size) {
      this.invalidFile = false;
      return true;
    } else {
      this.invalidFile = true;
      return false;
    }
  }


  public checkValidDuration(file) {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);
        video.onloadedmetadata = function() {
          if(video.duration < 30) {
            resolve(true);
          } else {
            reject(false);
          }
        };
    });
  } */

  public validateMediaSize(file,size) {
    if(file.size <= size) {
      this.invalidFile = false;
      return true;
    } else {
      this.invalidFile = true;
      return false;
    }
  }

  public fileUpload() {
    this.templateForm.controls['file'].setValue(this.fileForUpload);
    this.uploadedFileName = this.fileForUpload['name'];
    this.onClose();
  }

  public downloadFile() {
    if (this.fileUrl) {
      window.open(this.fileUrl, '_blank');
    }
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  public addFormGroup(formArrayName) {
    if (formArrayName === 'quickReply') {
      const items = this.templateForm.get('quickReply') as FormArray;
      items.push(
        new FormGroup({
          buttonText: this.getFormControl(true, 25),
        })
      );
    } else if (formArrayName === 'callToActionArr') {
      const typeOfActionTwo = this.dropDownValues['typeOfAction'].find(
        (resp) => resp['name'] !== this.selectedTypeOfActionOne
      );
      const items = this.templateForm.get('callToActionArr') as FormArray;
      items.push(
        new FormGroup({
          typeOfAction: new FormControl(typeOfActionTwo, Validators.required),
        })
      );
      const setOption = {
        child: 'actionType',
        value: typeOfActionTwo,
        index: 1,
      };
      if (!this.disablePointer) {
        this.updateDropdownValues(setOption);
      }
    }
  }

  public removeFormGroup(formArrayName, indexNumber) {
    (<FormArray>this.templateForm.get(formArrayName)).removeAt(indexNumber);
    if (formArrayName === 'callToActionArr') {
      if (indexNumber === 0) {
        if (this.selectedTypeOfActionTwo) {
          this.selectedTypeOfActionOne = this.selectedTypeOfActionTwo;
          this.selectedTypeOfActionTwo = null;
        } else {
          this.selectedTypeOfActionOne = null;
        }
      } else {
        this.selectedTypeOfActionTwo = null;
      }
    }
  }

  public getFormControl(required = true, maxLength = null) {
    if (required && !maxLength) {
      return new FormControl('', Validators.required);
    } else if (required && maxLength) {
      return new FormControl('', [
        Validators.required,
        Validators.maxLength(maxLength),
      ]);
    } else if (!required && maxLength) {
      return new FormControl('', [Validators.maxLength(maxLength)]);
    } else {
      return new FormControl('');
    }
  }

  public addFormControl(controlName, control) {
    this.templateForm.addControl(controlName, control);
  }

  public removeFormControl(controlName) {
    this.templateForm.removeControl(controlName);
  }

  public removeControlsOnReset() {
    this.removeFormControl('file');
    this.removeFormControl('header');
    this.templateForm.controls['quickReply']
      ? this.templateForm.controls['quickReply'].reset()
      : '';
    this.quickReplyFormArray = new FormArray([
      new FormGroup({
        buttonText: this.getFormControl(true, 25),
      }),
    ]);
    this.templateForm.controls['callToActionArr']
      ? this.templateForm.controls['callToActionArr'].reset()
      : '';
    this.callToActionFormArray = new FormArray([
      new FormGroup({
        typeOfAction: this.getFormControl(),
      }),
    ]);
    this.removeFormControl('quickReply');
    this.removeFormControl('callToActionArr');
    this.characterFooterCount = 0;
    this.characterContentCount = 0;
    this.disableFooterPointer = false;
  }

  public onReset() {
    this.submitted = false;
    this.selectedTypeOfActionOne = null;
    this.selectedTypeOfActionTwo = null;
    this.templateForm.reset({
      account: this.accountId,
      tenant: this.tenantId,
      businessProfile: this.profileId,
    });
    this.removeControlsOnReset();
    this.fileForUpload = null;
    this.fileUrl = null;
    this.uploadedFileName = null;
  }

  public navigateBack() {
    this.router.navigate([
      'whatsapp/template-configuration',
      {
        accountId: this.accountId,
        tenantId: this.tenantId,
        profileId: this.profileId,
        channelId:this.channelId
      },
    ]);
  }

  public loadRouteData() {
    if (this.accountId && this.tenantId && this.profileId) {
      this.getTenant(this.accountId);
      this.getProfile(this.tenantId);
      this.getChannel(this.profileId);
      if (!this.templateId) {
        this.templateForm.patchValue({
          account: this.accountId,
          tenant: this.tenantId,
          businessProfile: this.profileId,
          channel: this.channelId,
        });
      } else if (this.templateId) {
        this.disablePointer = true;
        this.getTemplateDetails();
      }
    }
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getTemplateCategories() {
    this.whatsappTemplateControllerService
      .whatsAppTemplateCategories({})
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['category'] = resp;
          } else {
            this.dropDownValues['category'] = [];
          }
        },
        (err) => {
          this.dropDownValues['category'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getButtonsType() {
    this.whatsappTemplateControllerService.getButtonType({}).subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['buttonsType'] = resp;
        } else {
          this.dropDownValues['buttonsType'] = [];
        }
      },
      (err) => {
        this.dropDownValues['buttonsType'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTypeOfActions() {
    this.whatsappTemplateControllerService.getButtonTypeSupportList().subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.callPhoneType = resp.find(
            (element) => element['name'] === 'Call Phone Number'
          );
          this.dropDownValues['typeOfAction'] = resp;
          this.dataLoaded.next(true);
        } else {
          this.dropDownValues['typeOfAction'] = [];
        }
      },
      (err) => {
        this.dropDownValues['typeOfAction'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getLanguages() {
    this.whatsappTemplateControllerService.getMessageLanguage({}).subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['language'] = resp;
        } else {
          this.dropDownValues['language'] = [];
        }
      },
      (err) => {
        this.dropDownValues['language'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getHeaderType() {
    this.whatsappTemplateControllerService.getHeaderType({}).subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['headerType'] = resp;
        } else {
          this.dropDownValues['headerType'] = [];
        }
      },
      (err) => {
        this.dropDownValues['headerType'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public compareFn(option, value) {
    if (option && value && option.name && value.name) {
      return option.name === value.name;
    }
  }
  
  public onTextInputChange() {
   this.updateCharacterCount();
  }

  public onTextInputFooterChange() {
    this.characterFooterCount = this.templateForm.controls.footer.value.length;
  }

  public ngOnDestroy() {
    this.dataLoaded.unsubscribe();
  }
  
  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  addEmoji(event) {
    const messageControl = this.templateForm.get('whatsappTemplateContent') as FormControl;
    if (messageControl) {
      const currentMessage = messageControl.value || '';
      const updatedMessage = `${currentMessage}${event.emoji.native}`;
      if (updatedMessage.length <= 1024) {
        messageControl.setValue(updatedMessage);
      }
    }
    this.updateCharacterCount();
    this.showEmojiPicker = false;
  }

  updateCharacterCount() {
    const messageControl = this.templateForm.get('whatsappTemplateContent') as FormControl;
    if (messageControl) {
      this.characterContentCount = messageControl.value.length;
    }
  }

  onCheckboxChange(event: any) {
    const checkboxValue = event.checked;
    this.templateForm.controls.footer.setValue(checkboxValue ? this.getTranslatedValues('REPLY_STOP_TO_UNSUBSCRIBE') : '');
    this.disableFooterPointer = checkboxValue;
    this.characterFooterCount = this.templateForm.controls.footer.value.length;
  }

  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }
  
  public getChannel(profileId: string) {
    this.whatsappProfileConnectionDetailsControllerService
      .fetchProfileMappedChannels({ profileId: profileId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            const response = resp.map(item => ({
              'id': item.id,
              'name': item.channelName
            }));
            this.dropDownValues['channel'] = response;
          } else {
            this.dropDownValues['channel'] = [];
          }
        },
        (err) => {
          this.dropDownValues['channel'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
}
