/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DistributionPackageDto } from '../models/distribution-package-dto';
import { DistributionPackageSearchRequestDto } from '../models/distribution-package-search-request-dto';
import { DistributionPackageSearchResponseDto } from '../models/distribution-package-search-response-dto';

@Injectable({
  providedIn: 'root',
})
export class DistributionPackageControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllDistributionPackage
   */
  static readonly GetAllDistributionPackagePath = '/api/v1/DistributionPackages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDistributionPackage()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDistributionPackage$Response(params: {
    page?: number;
    limit?: number;
    distributionPackageSearchRequest: DistributionPackageSearchRequestDto;
  }): Observable<StrictHttpResponse<DistributionPackageSearchResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DistributionPackageControllerService.GetAllDistributionPackagePath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.query('distributionPackageSearchRequest', params.distributionPackageSearchRequest, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DistributionPackageSearchResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllDistributionPackage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDistributionPackage(params: {
    page?: number;
    limit?: number;
    distributionPackageSearchRequest: DistributionPackageSearchRequestDto;
  }): Observable<DistributionPackageSearchResponseDto> {

    return this.getAllDistributionPackage$Response(params).pipe(
      map((r: StrictHttpResponse<DistributionPackageSearchResponseDto>) => r.body as DistributionPackageSearchResponseDto)
    );
  }

  /**
   * Path part for operation getDistributionPackageById
   */
  static readonly GetDistributionPackageByIdPath = '/api/v1/DistributionPackages/{distributionPackageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDistributionPackageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDistributionPackageById$Response(params: {
    distributionPackageId: string;
    distributionPackageSearchRequest: DistributionPackageSearchRequestDto;
  }): Observable<StrictHttpResponse<DistributionPackageDto>> {

    const rb = new RequestBuilder(this.rootUrl, DistributionPackageControllerService.GetDistributionPackageByIdPath, 'get');
    if (params) {
      rb.path('distributionPackageId', params.distributionPackageId, {});
      rb.query('distributionPackageSearchRequest', params.distributionPackageSearchRequest, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DistributionPackageDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDistributionPackageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDistributionPackageById(params: {
    distributionPackageId: string;
    distributionPackageSearchRequest: DistributionPackageSearchRequestDto;
  }
): Observable<DistributionPackageDto> {
    return this.getDistributionPackageById$Response(params).pipe(
      map((r: StrictHttpResponse<DistributionPackageDto>) => r.body as DistributionPackageDto)
    );
  }

}
