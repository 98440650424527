<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="scheduler-icon" [src]="schedulerIcon" />
        <span>{{'SCHEDULER_MANAGEMENT' |translate}}</span>
      </span>
    </div>
    <div class="table-container" *ngIf="schedulerData && schedulerData?.length !== 0">
      <mat-table [dataSource]="dataSource" class="table-data">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
            <mat-cell class="tooltip " *matCellDef="let dataName">
              <span *ngIf="column !=='action'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column] | translate}}
              </span>
              <span *ngIf="column==='action'">
                <mat-slide-toggle [(ngModel)]="dataName['active']" (change)="gotoConfirmDialog( dataName,'edit')">
                </mat-slide-toggle>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <img [src]="moreOptionsImage">
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item [class.disabled]="!dataName['active']"
                    (click)="gotoScheduler(dataName)">
                    <img [src]="editImage" class="modify-button">
                    <span>{{ 'EDIT' | translate }}</span>
                  </button>
                  <button mat-menu-item (click)="gotoConfirmDialog(dataName, 'delete')">
                    <img [src]="deleteImage" class="modify-button">
                    <span>{{ 'DELETE' | translate }}</span>
                  </button>
                  <button mat-menu-item (click)="gotoShowHistoryModal(dataName)">
                    <img [src]="showHistoryImage" class="modify-button">
                    <span>{{ 'SHOW_EXECUTION_HISTORY' | translate }}</span>
                  </button>
                  <button mat-menu-item [class.disabled]="!dataName['active']"
                    (click)="executeNow(dataName)">
                    <img [src]="executeImage" class="modify-button">
                    <span>{{ 'EXECUTE_NOW' | translate }}</span>
                  </button>
                </mat-menu>
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator *ngIf="dataSource" [pageSizeOptions]="pageSizeOptions" [length]="schedulersCount" [pageSize]="page"
      showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
    <div *ngIf="schedulerData?.length === 0">{{ 'NO_DATA_AVAILABLE' | translate }}</div>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
