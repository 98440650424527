/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BulkSimOperationsDto } from '../models/bulk-sim-operations-dto';
import { CommonDetails } from '../models/common-details';
import { SimPlansDto } from '../models/sim-plans-dto';
import { SimResponseDto } from '../models/sim-response-dto';
import { SimWhiteListDto } from '../models/sim-white-list-dto';

@Injectable({
  providedIn: 'root',
})
export class SimMgmtControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSimPlans
   */
  static readonly GetSimPlansPath = '/api/v1/simPlans/customerVendor/{customerVendorId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimPlans()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimPlans$Response(params: {
    customerVendorId: string;
  }): Observable<StrictHttpResponse<Array<SimPlansDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SimMgmtControllerService.GetSimPlansPath, 'get');
    if (params) {
      rb.path('customerVendorId', params.customerVendorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimPlansDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSimPlans$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimPlans(params: {
    customerVendorId: string;
  }): Observable<Array<SimPlansDto>> {

    return this.getSimPlans$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimPlansDto>>) => r.body as Array<SimPlansDto>)
    );
  }

  /**
   * Path part for operation activateSim
   */
  static readonly ActivateSimPath = '/api/v1/sim/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateSim()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateSim$Response(params?: {
    body?: BulkSimOperationsDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SimMgmtControllerService.ActivateSimPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateSim$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateSim(params?: {
    body?: BulkSimOperationsDto
  }): Observable<string> {

    return this.activateSim$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation deActivateSim
   */
  static readonly DeActivateSimPath = '/api/v1/sim/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deActivateSim()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deActivateSim$Response(params?: {
    body?: BulkSimOperationsDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SimMgmtControllerService.DeActivateSimPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deActivateSim$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deActivateSim(params?: {
    body?: BulkSimOperationsDto
  }): Observable<string> {

    return this.deActivateSim$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation changeSimPlan
   */
  static readonly ChangeSimPlanPath = '/api/v1/changePlan/sims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeSimPlan()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeSimPlan$Response(params?: {
    body?: Array<SimResponseDto>
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SimMgmtControllerService.ChangeSimPlanPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `changeSimPlan$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeSimPlan(params?: {
    body?: Array<SimResponseDto>
  }): Observable<string> {

    return this.changeSimPlan$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation smsWhiteListSim
   */
  static readonly SmsWhiteListSimPath = '/api/v1/whitelist/sims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `smsWhiteListSim()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  smsWhiteListSim$Response(params?: {
    body?: Array<SimWhiteListDto>
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SimMgmtControllerService.SmsWhiteListSimPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `smsWhiteListSim$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  smsWhiteListSim(params?: {
    body?: Array<SimWhiteListDto>
  }): Observable<string> {

    return this.smsWhiteListSim$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getAllSimStatus
   */
  static readonly GetAllSimStatusPath = '/api/v1/simStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSimStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSimStatus$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, SimMgmtControllerService.GetAllSimStatusPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSimStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSimStatus(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.getAllSimStatus$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

}
