/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit } from '@angular/core';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import {
  AccountControllerService, CustomerControllerService, DomainControllerService, PlatformAdminUtilityControllerService,
  TenantControllerService
} from 'src/app/services/Platform/services';
import { Router } from '@angular/router';
import { CREATE, TENANT_ICON } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-create-tenant',
  templateUrl: './create-tenant.component.html',
  styleUrls: ['./create-tenant.component.scss']
})

/**
  * @ngdoc component
  * @name TenantsModule.component:createTenant
  *
  *
  * @description
  * description: To create new tenant
  *
*/
export class CreateTenantComponent implements OnInit {
  public jsonFileLoaded: string;
  public showSpinner :any;
  public dropDownValues: any = {};
  asideMenus: Array<AsideCard>;
  public pageKey: string;
  public tenantIcon;
  constructor(private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService, private responseHandlerService: ResponseHandlerService,
    private domainControllerService: DomainControllerService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private rightsideNavitemsService: RightsideNavitemsService, private router: Router,
    private objectToIdConversionService: ObjectToIdConversionService,
    private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.jsonFileLoaded = 'tenants';
    this.pageKey = 'tenant';
    this.rightsideNavitemsService.getRightsideNavItems(CREATE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.getAccount();
    this.getDomain();
    this.getCountry();
    this.tenantIcon = TENANT_ICON;
  }

  /**
    * @ngdoc method
    * @name CreateTenant#getAccount
    *
    * @methodOf
    * TenantsModule.controller:CreateTenant
    *
    * @description
    * Description: to populate account dropdown
    *
    * @param {type} name description
    * @return {type} name description
  */
  public getAccount() {
    this.accountControllerService
      .findAllAccounts({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.dropDownValues['account'] = resp;
          } else {
            this.dropDownValues['account'] = [];
          }
        },
        err => {
          this.dropDownValues['account'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
   * @ngdoc method
   * @name CreateTenant#getDomain
   *
   * @methodOf
   * TenantsModule.controller:CreateTenant
   *
   * @description
   * Description: to populate domain dropdown
   *
   * @param {type} name description
   * @return {type} name description
 */
  public getDomain() {
    this.domainControllerService
      .findAllDomains({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['domain'] = resp;
          } else {
            this.dropDownValues['domain'] = [];
          }
        },
        err => {
          this.dropDownValues['domain'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
   * @ngdoc method
   * @name CreateTenant#updateDropdownValues
   *
   * @methodOf
   * TenantsModule.controller:CreateTenant
   *
   * @description
   * Description: to populate dependent dropdowns
   *
   * @param {type} name description: name of parent dropdown
   * @return {type} name description
 */
  public updateDropdownValues($event) {
    this.dropDownValues[$event.child] = null;
    if ($event.value !== undefined && $event.child === 'state') {
      this.getState($event.value);
    }
  }

  /**
 * @ngdoc method
 * @name CreateTenant#getState
 *
 * @methodOf
 * TenantsModule.controller:CreateTenant
 *
 * @description
 * Description: to populate state dropdown based on selected country
 *
 * @param {type} name description : name of the selected country
 * @return {type} name description
*/
  public getState(countryName: string) {
    this.platformAdminUtilityControllerService
      .getAllStateOfCountry({ id: countryName })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['state'] = resp;
          } else {
            this.dropDownValues['state'] = [];
          }
        },
        err => {
          this.dropDownValues['state'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
  * @ngdoc method
  * @name CreateTenant#getCountry
  *
  * @methodOf
  * TenantsModule.controller:CreateTenant
  *
  * @description
  * Description: to populate country dropdown
  *
  * @param {type} name description
  * @return {type} name description
*/
  public getCountry() {
    this.platformAdminUtilityControllerService
      .getAllCountries()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['country'] = resp;
          } else {
            this.dropDownValues['country'] = [];
          }
        },
        err => {
          this.dropDownValues['country'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
   * @ngdoc method
   * @name CreateTenant#createTenant
   *
   * @methodOf
   * TenantsModule.controller:CreateTenant
   *
   * @description
   * Description: to create new tenant
   *
   * @param {type} name description: details of new tenant to be created
   * @return {type} name description
 */
  public createTenant(data: any) {
    if (data && data['account']) {
      data['account'] = this.objectToIdConversionService.convertObjectToId(data['account']);
    }

    if (data && data['domain']) {
      data['domain'] = this.objectToIdConversionService.convertObjectToId(data['domain']);
    }

    if (data && data['country']) {
      data['country'] = this.objectToIdConversionService.convertObjectToId(data['country']);
    }

    if (data && data['state']) {
      data['state'] = this.objectToIdConversionService.convertObjectToId(data['state']);
    }

    this.showSpinner = this.loaderService.showProgressBar();

    this.tenantControllerService
      .createTenant({ body: data })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'TENANT_CREATE_SUCCESS');
          this.router.navigate(['tenants/manage-tenants']);
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }
}
