<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="fotaIcon" />
        <span>{{'FOTA_JOB_MANAGEMENT' |translate}}</span>
      </span>
    </div>
   
        <span>
          <app-search-component *ngIf="formData" [comboFieldSearchArray]="formData" [dropDownValues]="dropDownValues"
            (valueSelected)="updateDropdownValues($event)" (searchFieldEmitter)="searchFotaJobs($event)">
          </app-search-component>
        </span>
        <div class="column-list" *ngIf="defaultColumns">
          <mat-form-field>
            <mat-label class="label">{{'SELECT_COLUMNS'| translate}}</mat-label>
              <mat-select multiple (ngModelChange)="getSelectedColumns($event.value)" [(value)]="columns"  > 
                  <mat-option *ngFor="let column of defaultColumns" [value]="column"
                   [disabled]="(column==='jobName'||column==='action')">{{ column | translate }}</mat-option>
              </mat-select>
          </mat-form-field>
      </div>
        <div class="table-container" *ngIf="dataSource">
          <mat-table [dataSource]="dataSource" class="table-data">
            <div *ngFor="let column of columns">
              <ng-container [matColumnDef]="column">
                <mat-header-cell [ngClass] = "determineColumnSize(column)" *matHeaderCellDef>
                  <header class="job-table heading-label">{{column | appendSpace | titlecase}}</header>
                </mat-header-cell>
                <mat-cell class="tooltip " [ngClass] = "determineColumnSize(column)" *matCellDef="let dataName">
                  <span *ngIf="column!=='action' && column!=='progress' && column!=='status'" title="{{dataName[column]}}"
                    class="cell-value">
                    {{dataName[column]}}
                  </span>
                  <span *ngIf="column === 'status'" title="{{dataName[column]}}" class="cell-value"
                    [ngStyle]="{'color': statusColor[dataName[column]]}">
                    {{dataName[column] | translate}}
                  </span>
                  <span *ngIf="column === 'progress'" title="{{dataName[column]}}" class="cell-value progress-bar">
                   
                    <button mat-raised-button class="button btn-background progress-btn" (click)="getOtaJobProgress(contentProgress, dataName['jobId'])">
                      <span>{{ 'VIEW_PROGRESS' | translate }}</span>
                    </button>   

                  </span>
                  <span *ngIf="column==='action'">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                      <img [src]="moreOptionsImage">
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="gotoConfirmDialog(dataName, 'play')"
                        *ngIf="dataName['status'] ==='CREATED' || dataName['status'] === 'RUNNING' || dataName['status'] === 'PAUSED'">
                        <img [src]="dataName['status'] === 'RUNNING' ?pauseImage:playImage" class="action-button">
                        <span>{{ (dataName['status'] === 'CREATED'?'START':dataName['status']==='RUNNING'
                          ?'PAUSE':'RESUME') | translate }}</span>
                      </button>
                      <button mat-menu-item (click)="gotoConfirmDialog(dataName, 'terminate')"
                        *ngIf="dataName['status'] ==='RUNNING' || dataName['status'] === 'PAUSED'">
                        <img [src]="terminateImage" class="action-button">
                        <span>{{ 'TERMINATE' | translate }}</span>
                      </button>
                      <button mat-menu-item (click)="goToDetailsPage(dataName)"
                        *ngIf="dataName['status'] !=='CREATED'">
                        <img [src]="detailsImage" class="action-button">
                        <span>{{ 'MORE_INFO' | translate }}</span>
                      </button>
                      <button mat-menu-item (click)="goToFotaEditPage(dataName)" *ngIf="dataName['status']  !== 'CREATED'">
                        <img [src]="editImage" [title]="'EDIT' | translate" class="action-button delete-edit-button">
                        <span>{{ 'EDIT' | translate }}</span>
                      </button>
                      <button mat-menu-item (click)="gotoConfirmDialog(dataName, 'delete')"
                        *ngIf="dataName['status'] === 'CREATED'">
                        <img [src]="deleteImage" [title]="'DELETE' | translate" class="action-button delete-edit-button"
                          (click)="gotoConfirmDialog(dataName, 'delete')">
                        <span>{{ 'DELETE' | translate }}</span>
                      </button>
                    </mat-menu>
                  </span>
                </mat-cell>
              </ng-container>
            </div>
            <mat-header-row class="table-header-row" *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let data; columns: columns;"></mat-row>
          </mat-table>
        </div>
        <mat-paginator *ngIf="dataSource" [pageSizeOptions]="pageSizeOptions" [length]="fotaJobsCount" [pageSize]="pageSize"
          showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
       
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>




<ng-template #contentProgress let-modal>
  <div class="close-btn"><img  class="rb-ic" (click)="onClose()"  [src]="closeIcon" /></div>
  <div class="modal-width">
    <div class="modal-header">
      <h6 class="modal-title" id="modal-basic-title">{{'OTA_JOB_PROGRESS'| translate}}</h6>
    </div>
    <div class="modal-body">
        <div class="progress-percent-color"> {{jobProgressVal}}% </div>
        <mat-progress-bar mode="determinate" value="{{jobProgressVal}}"></mat-progress-bar>
    </div>
  </div>
</ng-template>