import { EventEmitter, Injectable, Output } from '@angular/core';
import * as Highcharts from 'highcharts';
import highcharts3D from 'highcharts/highcharts-3d';
highcharts3D(Highcharts);
@Injectable({
    providedIn: 'root'
})
export class PieChartService {
    functionNameEmitter: EventEmitter<any> = new EventEmitter();
    defaultOptions;

    getOptions() {
        const componentScope = this;
        this.defaultOptions = {
            chart: {
                margin: 0,
                marginBottom: 40,
                plotShadow: false,
                type: 'pie',
                options3d: {
                    enabled: true,
                    alpha: 45,
                    beta: 0,
                    depth: 35,
                    viewDistance: 25
                },
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            subtitle: {
                text: ''
            },
            tooltip: {
                pointFormat: '<b>{point.count}</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    depth: 35,
                    dataLabels: {
                        enabled: true,
                        format: '{point.percentage:.1f} %'
                    },
                    showInLegend: true,
                    point: {
                        events: {
                            legendItemClick: function () {
                                return false;
                            }
                        }
                    }
                }
            },
            series: [{
                type: 'pie',
                name: '',
                data: [],
                events: {
                    click: function (event) {
                        componentScope.functionNameEmitter.emit((event.point['status']));
                    }
                },
            }],
            legend: {
                margin: 50,
                width: 450,
                floating: true,
                align: 'left',
                itemStyle: {
                   fontSize: 10
                }
            }
        };
        return this.defaultOptions;
    }

    constructor() {
        this.functionNameEmitter = new EventEmitter<any>();
    }

    createChart(container, options) {
        const chartOptions = this.getOptions();
        if(options.title === 'WhatsApp Delivery Status') {
            chartOptions['chart']['width'] = 400;
            chartOptions['chart']['height'] = 400;
            chartOptions['legend'] =  {
                itemStyle: {
                    fontSize: 8,
                 },
                 align: 'center',
                 x: 10,
                 y: -35,
                 floating: true
            };
            chartOptions['title']['style'] =  {
                fontSize: 14
            };
        }
        const chartData = [];
        options.details.forEach(data => {
            const chartDataObject = {
                name: '',
                y: 0,
                count: 0,
                status: '',
                color: ''
            };
            chartDataObject.name = data.name;
            chartDataObject.y = data.y;
            chartDataObject.count = data.count;
            chartDataObject.status = data.status;
            chartDataObject.status = data.status;
            chartDataObject.color = data.color;
            chartData.push(chartDataObject);
        });
        chartOptions.title.text = options.title;
        chartOptions.subtitle.text = options.subtitle;
        chartOptions.series[0].data = chartData;
        const element = document.createElement('div');
        element.className = 'pie-chart';
        container.appendChild(element);

        if (!!chartOptions.chart) {
            chartOptions.chart['renderTo'] = element;
        } else {
            options.chart.renderTo = 'pie-chart';
        }
        return new Highcharts.Chart(chartOptions);

    }

    public destroyChart(chart) {
        if (chart && chart.userOptions) {
            chart.destroy();
            chart = null;
        }
        return chart;
    }
}
