/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DownloadCsvDto } from '../models/download-csv-dto';
import { Resource } from '../models/resource';

@Injectable({
  providedIn: 'root',
})
export class UtilityControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation downloadJsonResponseFile
   */
  static readonly DownloadJsonResponseFilePath = '/api/v1/sim/registerationResponse/{fileRefId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadJsonResponseFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadJsonResponseFile$Response(params: {
    fileRefId: string;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, UtilityControllerService.DownloadJsonResponseFilePath, 'get');
    if (params) {
      rb.path('fileRefId', params.fileRefId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/txt'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadJsonResponseFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadJsonResponseFile(params: {
    fileRefId: string;
  }): Observable<Array<string>> {

    return this.downloadJsonResponseFile$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation downloadCsv
   */
  static readonly DownloadCsvPath = '/api/v1/bulkRegister/csvTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadCsv()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadCsv$Response(params?: {
    body?: DownloadCsvDto
  }): Observable<StrictHttpResponse<Resource>> {

    const rb = new RequestBuilder(this.rootUrl, UtilityControllerService.DownloadCsvPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Resource>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadCsv$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadCsv(params?: {
    body?: DownloadCsvDto
  }): Observable<Resource> {

    return this.downloadCsv$Response(params).pipe(
      map((r: StrictHttpResponse<Resource>) => r.body as Resource)
    );
  }

}
