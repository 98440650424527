<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="firmwareIcon" />
        <span>{{'FIRMWARE_MANAGEMENT' |translate}}</span>
      </span>
    </div>
    <app-search-component *ngIf="formData" [comboFieldSearchArray]="formData" [dropDownValues]="dropDownValues"
      (valueSelected)="updateDropdownValues($event)" (searchFieldEmitter)="searchForFirmwares($event)">
    </app-search-component>
    <div class="fota-btn" *ngIf="totalFirmwareCount&&!hideActionButtons">
      <button mat-raised-button class="button btn-background" *ngIf="totalFirmwareCount" (click)="selectFirmwares()">
        <span *ngIf="!selectAllFirmwares">{{ 'SELECT_ALL' | translate }}</span>
        <span *ngIf="selectAllFirmwares">{{ 'UNSELECT_ALL' | translate }}</span>
      </button>
      <button mat-raised-button class="button btn-width btn-background" [class.disabled]="!selection.hasValue()" *ngIf="totalFirmwareCount"
      (click)="openModal(content)">
      <span>{{ 'MANAGE_TAGS' | translate }} </span>
    </button>
    </div>
    <div class="table-container" *ngIf="dataSource">
      <mat-table [dataSource]="dataSource" class="bottom-space table-data">
        <div *ngFor="let column of columns">
          <!-- for future implementation -->
          <ng-container *ngIf="column === 'select'" matColumnDef="select">
            <mat-header-cell class="column-small" *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell class="column-small" *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
               (change)="$event ? selection.toggle(row['firmwareId']) : null; selectAllFirmwaresCheckBox()"
                [checked]="selection.isSelected(row['firmwareId'])" [disabled]="selectAllFirmwares">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="column !== 'select'" [matColumnDef]="column">
            <mat-header-cell [ngClass] = "[determineColumnSize(column),'column' ]" *matHeaderCellDef >
              <header class="firmware-table heading-label">{{column | appendSpace | titlecase |translate}}</header>
            </mat-header-cell>
            <mat-cell class="tooltip" [ngClass] = "[determineColumnSize(column),'column' ]" *matCellDef="let dataName" >
              <span *ngIf="column !== 'action' && column !== 'active'" title="{{ dataName[column] }}"
                class="cell-value">
                {{ dataName[column] }}
              </span>
              <span *ngIf="column === 'action'">
                <img [src]="deleteImage" class="modify-button" (click)="gotoConfirmDialog(dataName, 'delete')" />
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row class="table-header-row" *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns"></mat-row>
      </mat-table>
    </div>
    <mat-paginator *ngIf="dataSource" [pageSizeOptions]="pageSizeOptions" [length]="totalFirmwareCount"
      [pageSize]="pageSize" showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>

<ng-template #content let-modal>
  <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{ 'MANAGE_TAGS' | translate }} </h5>
    </div>
    <div class="modal-body">
      <app-static-tags (availableTags)="populateExistingTags()"
      (selectedTags)="addOrRemoveTags($event)"> 
   </app-static-tags>
    </div>
  </div>
  
</ng-template>