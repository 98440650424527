import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import {
  AccountControllerService,
  BusinessProfileControllerService,
  TenantControllerService,
} from 'src/app/services/Platform/services';
import {
  DELETE_IMAGE,
  VIEW_ICON,
  WHATSAPP_ICON,
} from 'src/app/shared/constants/strings';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { TranslationService } from 'src/app/providers/translation-service';
import { WhatsappProfileConnectionDetailsControllerService, WhatsappTemplateControllerService } from 'src/app/services/Whatsapp/services';
import { TemplateResponseDto } from 'src/app/services/Whatsapp/models';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { BciLoaderService } from '@bci-web-core/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-manage-whatsapp-template',
  templateUrl: './manage-whatsapp-template.component.html',
  styleUrls: ['./manage-whatsapp-template.component.scss'],
})
export class ManageWhatsappTemplateComponent implements OnInit {
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public whatsappIcon: string;
  public deleteImage: string;
  public viewImage: string;
  public dataSource;
  public columns: any[];
  public ignoreList = ['id'];
  public configurationsList: Array<String> = [];
  public dropdownList: [];
  public showSpinner:any;
  public templatesList: any[];
  public formData;
  public routeAccountId: string;
  public routeTenantId: string;
  public routeProfileId: string;
  public routeChannelId:string;
  public profileIdCheck = true;
  public permissionList: Array<String> = [];
  public channelId:string;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  profileName: string;
  exportTemplateCheck = false;

  constructor(
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private responseHandlerService: ResponseHandlerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private whatsappTemplateControllerService: WhatsappTemplateControllerService,
    private whatsappProfileConnectionDetailsControllerService: WhatsappProfileConnectionDetailsControllerService,
    private route: ActivatedRoute,
    private _matDialog: MatDialog,
    private translate: TranslationService,
    private router: Router,
    private loaderService: BciLoaderService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.routeAccountId = this.route.snapshot.paramMap.get('accountId');
    this.routeTenantId = this.route.snapshot.paramMap.get('tenantId');
    this.routeProfileId = this.route.snapshot.paramMap.get('profileId');
    this.routeChannelId = this.route.snapshot.paramMap.get('channelId');
    this.viewImage = VIEW_ICON;
    this.deleteImage = DELETE_IMAGE;
    this.whatsappIcon = WHATSAPP_ICON;
    if (this.routeAccountId && this.routeTenantId) {
      this.accountId = this.routeAccountId;
      this.tenantId = this.routeTenantId;
      this.profileId = this.routeProfileId;
      this.channelId = this.routeChannelId;
      this.getTenant(this.accountId);
      this.getProfile(this.tenantId);
      this.getChannel(this.profileId);
      this.profileIdCheck = false;
      this.exportTemplateCheck = true;
      this.formData = {
        account: this.accountId,
        tenant: this.tenantId,
        businessProfile: this.profileId,
        channel:this.channelId
      };
      this.getProfileMappedTemplates(this.channelId);
    }
    this.getAccount();
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child !== undefined) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
          this.profileIdCheck = true;
          this.exportTemplateCheck= false;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
          this.profileIdCheck = true;
          this.exportTemplateCheck= false;
        }
        if ($event.child === 'channel') {
          this.profileId = $event.value;
          this.getChannel($event.value);
          this.profileIdCheck = true;
          this.exportTemplateCheck= false;
          this.generateExcelFilename($event.value);
        }
        if ($event.child === 'template') {
          this.channelId = $event.value;
          this.profileIdCheck = true;
          this.exportTemplateCheck= false;
          this.getProfileMappedTemplates($event.value);
        }
      }
    }
  }


  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#getAccount
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To populate account dropdown.
   *
   * @param {type} null
   * @return {type} null
   */
  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#getTenant
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To populate account dropdown.
   *
   * @param {type} accountId
   * @return {type} null
   */
  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#getProfile
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To populate profile dropdown.
   *
   * @param {type} tenantId
   * @return {type} null
   */
  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#getProfileMappedTemplates
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To fetch profile mapped Templates.
   *
   * @param {type} profileId
   * @return {type} null
   */
  public getProfileMappedTemplates(channelId: string) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.templatesList = null;
    this.whatsappTemplateControllerService
      .fetchChannelMappedTemplates({ channelId: channelId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.exportTemplateCheck = true;
            this.profileIdCheck = false;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.templatesList = resp;
            const convertedData = this.templatesList.map(item => {
              const { status, ...rest } = item;
              return {
                ...rest,status: status ? 'Enabled' : 'Disabled'
              };
            });
            this.templatesList = convertedData;
            this.dataSource = new MatTableDataSource<TemplateResponseDto>(
              this.templatesList
            );
            for (const data of this.templatesList) {
              data['action'] = null;
            }
            this.columns = this.filterColumns(
              Object.keys(this.templatesList[0])
            );
          } else {
            this.profileIdCheck = false;
            this.exportTemplateCheck = false;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.templatesList = null;
            this.columns = null;
          }
        },
        (err) => {
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
          if (err.error.message === 'The Profile vendor map id is not found') {
            this.profileIdCheck = true;
          }
          if (err.error.message === 'No Template Mapped to this Channel') {
            this.profileIdCheck = false;
            this.exportTemplateCheck = false;
          }
          this.loaderService.hideProgressBar(this.showSpinner);
          this.templatesList = null;
          this.columns = null;
        }
      );
  }
  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#filterColumns
   *
   * @methodOf
   * Template.controller:manageWhatsappTemplate
   *
   * @description
   * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
   *
   * @param {type} columns
   * @return {type} list of columns
   */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#deleteTemplate
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: Deletes particular account.
   *
   * @param {type} data
   * @return {type} null
   */
  public deleteTemplate(data: any) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappTemplateControllerService
      .deleteTemplateById({
        channelId: this.channelId,
        templateId: data['id'],
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'TEMPLATE_DELETE_SUCCESS'
          );
          this.getProfileMappedTemplates(this.channelId);
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
          this.getProfileMappedTemplates(this.profileId);
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#onClose
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To close the popup.
   *
   * @param {type} null
   * @return {type} null
   */
  public onClose() {
    this._matDialog.closeAll();
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#gotoConfirmDialog
   *
   * @methodOf
   * mangeTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To open the popup.
   *
   * @param {type} null
   * @return {type} null
   */
  public gotoConfirmDialog(data = null) {
    this.templatesList = data;
    let message;
    message = this.translate.translateErrorMessages('DELETE_SELECTED_TEMPLATE');
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true,
      data: { message: message },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteTemplate(data);
      }
    });
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#redirectTo
   *
   * @methodOf
   * mangeTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To redirect to create whatsapp Template.
   *
   * @param {type} null
   * @return {type} null
   */
  redirectTo() {
    this.router.navigate([
      'whatsapp/create-template',
      {
        accountId: this.accountId,
        tenantId: this.tenantId,
        profileId: this.profileId,
        channelId:this.channelId
      },
    ]);
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#viewTemplate
   *
   * @methodOf
   * mangeTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To View the whatsapp Template.
   *
   * @param {type} null
   * @return {type} null
   */
  viewTemplate(data = null) {
    this.router.navigate([
      'whatsapp/create-template',
      {
        accountId: this.accountId,
        tenantId: this.tenantId,
        profileId: this.profileId,
        templateId: data['id'],
        channelId:this.channelId
      },
    ]);
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
        return false;
    }
    return true;
}

public getChannel(profileId: string) {
  this.whatsappProfileConnectionDetailsControllerService
    .fetchProfileMappedChannels({ profileId: profileId })
    .subscribe(
      (resp) => {
        if (resp && resp.length) {
          const response = resp.map(item => ({
            'id': item.id,
            'name': item.channelName
          }));
          this.dropDownValues['channel'] = response;
        } else {
          this.dropDownValues['channel'] = [];
        }
      },
      (err) => {
        this.dropDownValues['channel'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
}

downloadTemplate(){
  let timestamp: Date;
  const params = {
    channelId: this.channelId
  };
  this.whatsappTemplateControllerService
    .exportSmsTemplates(params )
    .subscribe(
      (resp) => {
       timestamp = new Date();
       const fileName = `${this.profileName}_Templates_${this.datePipe.transform(timestamp, 'yyyy-MM-dd_HH.mm.ss')}.csv`;
       this.downloadFile(resp,fileName);
      },
      (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error',err.error.message);
      }
    );

}

public downloadFile(data, fileName) {
  const FileSaver = require('file-saver');
  const blob = new Blob(['\ufeff'+data],{ type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(blob, fileName);
}

public generateExcelFilename(profileId) {
  this.dropDownValues['businessProfile'].forEach( row => {
    if (row['id'] === profileId) {
      this.profileName = row['name'];
    }
  });
}

  public onReset() {
    this.dynamicForm.createForm.reset();
    this.accountId = null;
    this.tenantId = null;
    this.profileId = null;
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.getAccount();
    this.columns = null;
    this.templatesList = null;
    this.profileIdCheck = true;
    this.exportTemplateCheck = false;
  }
}
