import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BciLoaderService } from '@bci-web-core/core';
import { DataSharingService } from 'src/app/providers/data-sharing.service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TranslationService } from 'src/app/providers/translation-service';
import { AccountControllerService, TenantControllerService, BusinessProfileControllerService, BulkSmsUploadControllerService } from 'src/app/services/Platform/services';
import { BulkWhatsappUploadControllerService, WhatsappMessageControllerService,WhatsappTemplateControllerService ,WhatsappMessageControllerV2Service, WhatsappProfileConnectionDetailsControllerService} from 'src/app/services/Whatsapp/services';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { DOWNLOAD_ICON, LINK_TRACKING, REJECT_ICON, VIEW_ICON, WHATSAPP_ICON,RERUN_ICON ,INFO_ICON_IMAGE} from 'src/app/shared/constants/strings';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AccesskeyLifeCycleControllerService } from 'src/app/services/Platform/services/accesskey-life-cycle-controller.service';
import { AccessKeyDto } from 'src/app/services/Platform/models';
import { OptInAndOptOutDto, WhatsappRequestDto } from 'src/app/services/Whatsapp/models';


@Component({
  selector: 'app-campaign-management',
  templateUrl: './campaign-management.component.html',
  styleUrls: ['./campaign-management.component.scss']
})
export class CampaignManagementComponent implements OnInit {
  public configurationsList: Array<String> = [];
  public permissionList: Array<String> = [];
  public selectedIndex = 0;
  public dropdownList: [];
  public dropDownValues: any = {};
  public tabName: string;
  public accountId: string;
  public tenantId: string;
  public solutionTypeId;
  public routeSolutionTypeId;
  public profileId: string;
  public bulkWhatsappData;
  public jsonFileLoaded: string;
  public data: any[];
  public reRunFormData;
  public showSpinner :any;
  public bulkWhatsappFormData;
  public startDateAndTime;
  public jobStartDate;
  public jobStartTime;
  public whatsappJobId;
  public searchCriteria;
  public fileForUpload;
  public selectedDateTime;
  public ignoreList = ['profileName', 'jobId','cancelEnabled','linkTrackingEnabled','estimatedCost','categoryName','phoneNumberCount'];
  public bulkWhatsappTableData;
  public bulkWhatsappColumns;
  public downloadImage: string;
  public rejectImage: string;
  public whatsappIcon: string;
  public reRunIcon: string;
  public bulkWhatsappEmailList = [];
  public routeAccountId: string;
  public routeTenantId: string;
  public routeProfileId: string;
  public viewImage : string;
  public linkIcon: string;
  public reRunJobId: string;
  public templateList = [];
  public estimatedCost: number = 0;
  public reRunData: any;
  public reRunForm: FormGroup;
  public testCampaignForm : FormGroup;
  public submitted = false;
  public todayDate = new Date();
  public selectedDate: Date;
  public checkTime: boolean;
  public infoIcon: string;
  public accesskey: string;
  public placeholderCount: number;
  public accessKeyList: AccessKeyDto[];
  public phoneNumberList: OptInAndOptOutDto[];
  public isTestCampaignActive: boolean =false;
  public sendMessageBody: WhatsappRequestDto;
  public templateContent: any;
  public templateId: any;
  public currencySymbol: string = '₹';
  public categoryName: string;
  public phoneNumberCount: number = 0 ;
  public channelId: any;
  public routerChannelId:string;
  public businessMobileNumber:string;

  constructor(private route: ActivatedRoute,
    private httpsService: HttpClient,
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService,
    private bulkWhatsappUploadControllerService: BulkWhatsappUploadControllerService,
    private bulkSmsControllerService:BulkSmsUploadControllerService,
    private loaderService:BciLoaderService,
    private datePipe: DatePipe,
    private _matDialog: MatDialog,
    private dataSharingService: DataSharingService,
    private router: Router, private translate: TranslationService,
    private whatsappTemplateControllerService:WhatsappTemplateControllerService,
    private accesskeyLifeCycleControllerService: AccesskeyLifeCycleControllerService,
    private whatsappMessageControllerService: WhatsappMessageControllerService,
    private whatsappMessageControllerV2Service: WhatsappMessageControllerV2Service,
    private whatsappProfileConnectionDetailsControllerService:WhatsappProfileConnectionDetailsControllerService) { }

  ngOnInit(): void {
    this.whatsappJobId = this.route.snapshot.paramMap.get('whatsappJobId');
    this.routeAccountId = this.route.snapshot.paramMap.get('accountId');
    this.routeTenantId = this.route.snapshot.paramMap.get('tenantId');
    this.routeSolutionTypeId = this.route.snapshot.paramMap.get('solutionTypeId');
    this.routeProfileId = this.route.snapshot.paramMap.get('profileId');
    this.routerChannelId = this.route.snapshot.paramMap.get('channelId');
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.whatsappIcon = WHATSAPP_ICON;
    this.downloadImage = DOWNLOAD_ICON;
    this.infoIcon = INFO_ICON_IMAGE;
    this.rejectImage = REJECT_ICON;
    this.viewImage = VIEW_ICON;
    this.linkIcon = LINK_TRACKING;
    this.reRunIcon = RERUN_ICON;
    this.getAccount();
    this.createReRunForm();
    this.httpsService
    .get('./assets/form-fields/bulk-whatsapp-tabs.json')
    .subscribe((data) => {
      this.configurationsList = data as string[];
      this.dropdownList = this.configurationsList['dropdownList'];
      this.configurationsList = this.configurationsList['fields'];
      if (this.routeSolutionTypeId && this.routeProfileId) {
        this.selectedIndex = 1;
        this.accountId = this.routeAccountId;
        this.tenantId = this.routeTenantId;
        this.profileId = this.routeProfileId;
        this.solutionTypeId = this.routeSolutionTypeId;
        this.channelId = this.routerChannelId;
        this.getTenant(this.accountId);
        this.getProfile(this.tenantId);
        this.getSolutionType(this.profileId);
        this.getChannel(this.profileId);
        this.searchCriteria = {
          account: this.accountId,
          tenant: this.tenantId,
          businessProfile: this.profileId,
          solutionType: this.solutionTypeId ,
          channel: this.channelId
        };
        this.getWhatsappJobs();
    }
    });
    if (this.permissionList.indexOf('WSP_BULK_R') > -1) {
      this.jsonFileLoaded = 'whatsapp-bulk-upload';
      this.httpsService
        .get('./assets/form-fields/bulk-whatsapp-manage.json')
        .subscribe((data) => {
          this.data = data as string[];
          this.bulkWhatsappData = this.data['fields'];
        });
    }
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined && $event.child.child !== undefined ) {
      if ( $event.child.child === 'tenant') {
        this.getTenant($event.value);
        this.accountId = $event.value;
      }
      if ($event.child.child === 'businessProfile') {
        this.getProfile($event.value);
        this.tenantId = $event.value;
      }
      if ($event.child.child === 'solutionType') {
        this.getSolutionType($event.value);
        this.getChannel( $event.value);
        this.profileId = $event.value;
      }
      if ($event.child.child === 'channel') {
        this.solutionTypeId = $event.value;
      }
      if ($event.child.child === 'generate') {
        this.channelId = $event.value;
      }
   } else if ($event.value !== undefined && $event.child !== undefined) {
        if ( $event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'solutionType') {
          this.getSolutionType($event.value);
          this.getChannel($event.value);
          this.profileId = $event.value;
          this.dropDownValues['templateName'] = [];
        }
        if ($event.child === 'channel') {
          this.solutionTypeId = $event.value;
        }
        if ($event.child === 'templateName') {
          this.channelId = $event.value;
          this.businessMobileNumber = this.getWabaNumberById(this.channelId);
          this.getProfileMappedTemplates($event.value);
          this.getAccessKey();
        }
        if ($event.child === 'jobName') {
          this.getTemplate($event.value);
        }
    }
    else if ($event.value === undefined) {
      this.isTestCampaignActive = false;
    }
  }

  public openTestCampaignForm(content) {
    this._matDialog.open(content, {
      maxHeight: '75vh',
      minWidth: '30vw',
      disableClose: true,
    });
  }
  public createReRunForm() {
    this.reRunForm = new FormGroup({
      'jobName': this.getFormControl(true,50),
      'jobStartDate': this.getFormControl(true),
      'jobStartTime': this.getFormControl(true)
    });

  }

  public createTestCampaignForm(paramCount: number) {
    this.testCampaignForm = new FormGroup({
      'accessKey': this.getFormControl(true),
      'sendTo': this.getFormControl(true, 150, /^(?:(?:\d+|\+),?){0,10}$/),
    });
    for (let i = 0; i < paramCount; i++) {
      this.testCampaignForm.addControl(`param${i+1}`, this.getFormControl(true,30));
    }
  }

  public getFormControl(required = true, maxLength = null, pattern = null) {
    const validators = [];
    if (required) {
      validators.push(Validators.required);
    }
    if (maxLength) {
      validators.push(Validators.maxLength(maxLength));
    }
    if (pattern) {
      validators.push(Validators.pattern(pattern));
    }
    return new FormControl('', validators);
  }
  

  public checkTimeAndDate(){
      if(this.reRunForm.controls['jobStartDate'].value){
        this.selectedDate = new Date(this.reRunForm.controls['jobStartDate'].value);
        if(this.reRunForm.controls['jobStartTime'].value) {
          this.reRunForm.controls['jobStartTime'].reset();
        }
      }
  }

  public timeValidator(maxTime?) {
    this.selectedDate = new Date(this.reRunForm.controls['jobStartDate'].value);
    if (this.reRunForm.controls['jobStartTime'].value && this.selectedDate) {
      const currentDate = new Date();
      const time = this.reRunForm.controls['jobStartTime'].value.match(/(\d+)(:(\d\d))?\s*(p?)/);
   const updatedCurrentDate = new Date(currentDate.getTime() + maxTime * 60 * 1000);
      if (time) {
        if (this.selectedDate.setHours(0, 0, 0, 0) === currentDate.setHours(0, 0, 0, 0)) {
          this.selectedDateTime = new Date();
          this.selectedDateTime.setHours(Number(time[1]) + (Number(time[1]) < 12 && time[4] ? 12 : 0));
            this.selectedDateTime.setMinutes(Number(time[3]) || 0);
            this.selectedDateTime.setSeconds(0, 0);
            const start_time = this.selectedDateTime;
            const diff = (Number(new Date()) - Number(start_time)) / (1000 * 60);
            this.checkTime = diff > 1 ? true : false;
            if (maxTime) {
              const diff =  (Number(updatedCurrentDate) - Number(this.selectedDateTime)) / (1000 * 60);
              this.checkTime = diff > 1 ? true : false;
            }
            if (!this.checkTime) {
              this.reRunForm.controls['jobStartTime'].setErrors(null);
            } else {
              this.reRunForm.controls['jobStartTime'].setErrors({
                incorrect: true,
              });
            }
        } else {
          this.reRunForm.controls['jobStartTime'].setErrors(null);
        }
      }
    }
  }

  public onSubmit(content) {
    this.submitted = true;
    if (this.reRunForm.valid) {
      const formValue = this.reRunForm.value;
      this.reRunFormData = {
        'jobName' : formValue['jobName'],
        'jobStartDate' : formValue['jobStartDate'],
        'jobStartTime': formValue['jobStartTime']
      };
      this.getEstimatedCost(this.reRunFormData,content);
    }
  }

  public onReset() {
    this.submitted = false;
    this.reRunForm.reset();
    this.testCampaignForm?.reset();
  }


  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getSolutionType(profileId: string) {
    const solutionTypeActiveArray = [];
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({
        businessProfileId: profileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach((element) => {
              if (element['active'] === true) {
                solutionTypeActiveArray.push(element);
              }
            });
            this.dropDownValues['solutionType'] = solutionTypeActiveArray;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfileMappedTemplates(channelId: string) {
    this.whatsappTemplateControllerService
      .fetchChannelMappedTemplates({ channelId: channelId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach(template => {
              this.dropDownValues['templateName'].push({name: template['templateName'], id: template['id'], categoryName: template['category']});
            });
          } else {
            this.dropDownValues['templateName'] = [];
          }
           
        },
        (err) => {
          this.dropDownValues['templateName'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getTemplate(templateId) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappTemplateControllerService.fetchWhatsAppTemplateDetails({
        templateId: templateId,
      })
      .subscribe(
        (resp) => {
          this.templateContent = resp.templateContent;
          this.placeholderCount = resp.placeHoldersCount;
          this.createTestCampaignForm(this.placeholderCount);
          this.isTestCampaignActive = true;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'TEST_CAMPAIGN_ENABLED' ,3000
          );

        },
        (err) => {
          this.createTestCampaignForm(0);
          this.isTestCampaignActive = false;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );

        }
      );
  }
  

  public selectFiles(event) {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].name.split('.').pop() === 'xlsx') {
        this.fileForUpload = event.target.files[0];
      } else {
        this.fileForUpload = null;
        this.responseHandlerService.returnToastMessage(
          'error',
          'UPLOAD_VALID_XLSX_FILE'
        );
      }
    }
  }

  public getEstimatedCost(reRunFormData,content){
    this.showSpinner = this.loaderService.showProgressBar();
    let reqData;
    if(this.reRunJobId!=null){
        this.reRunData = reRunFormData;   
    }else{
    this.categoryName = this.dropDownValues['templateName'].find(
      t => t.id == this.bulkWhatsappFormData.templateName
    ).categoryName;
    }
    const request ={
      body: {
        categoryName : this.categoryName,
        phoneNumberCount : this.reRunJobId !=null ? this.phoneNumberCount:0,
        file: this.reRunJobId == null? this.fileForUpload:null
      },
    };
    reqData = request;
    this.bulkWhatsappUploadControllerService.getBulkWhatsappJobCost(reqData).subscribe(
      (resp)=>{
        if(resp){
          this.estimatedCost = resp.rate;
          this.currencySymbol = resp.currencySymbol;
        }
        this.loaderService.hideProgressBar(this.showSpinner);
        this._matDialog.closeAll();
        this._matDialog.open(content, {
          maxHeight: '32vh',
          maxWidth: '36vw',
          disableClose: true,
        });
        
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this._matDialog.closeAll();
        this._matDialog.open(content, {
          maxHeight: '32vh',
          maxWidth: '36vw',
          disableClose: true,
        });
      }

    );
    }
  
  public uploadExcelFile() {
    this.showSpinner = this.loaderService.showProgressBar();
    let grpData;
    const groupData = {
      businessProfileId: this.bulkWhatsappFormData.businessProfile,
      solutionTypeId: this.bulkWhatsappFormData.solutionType,
      templateId: this.bulkWhatsappFormData.templateName,
      channelId :this.bulkWhatsappFormData.channel,
      headerId: this.bulkWhatsappFormData.senderId,
      emailIdList: this.bulkWhatsappEmailList,
      startDateAndTime: this.startDateAndTime,
      jobName: this.bulkWhatsappFormData.jobName,
      estimatedCost : this.estimatedCost,
    };

    const reqObj = {
      body: {
        bulkWhatsappDto: JSON.stringify(groupData),
        file: this.fileForUpload,
      },
    };
    grpData = reqObj;
    this.bulkWhatsappUploadControllerService.bulkWhatsappUploadExcel(grpData).subscribe(
      (resp) => {
        if (resp) {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'JOB_CREATION_SUCCESS'
          );
          this.searchCriteria = {
            account: this.accountId,
            tenant: this.tenantId,
            businessProfile: this.profileId,
            solutionType: this.solutionTypeId,
            channel :this.channelId };
          this.dataSharingService.setData(this.searchCriteria);
          this.getWhatsappJobs();
          this.onClose();
          this.selectedIndex = 1;
        }
      },

      (err) => {
        this.onClose();
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

public onUpload(data, content) {
 this.bulkWhatsappFormData = data;
 this.jobStartDate = new Date(this.bulkWhatsappFormData['jobStartDate']);
 this.jobStartTime = this.bulkWhatsappFormData['jobStartTime'].match(/(\d+)(:(\d\d))?\s*(p?)/);
 if (this.jobStartDate && this.jobStartTime) {
    this.selectedDateTime = this.jobStartDate;
            this.selectedDateTime.setHours(
              Number(this.jobStartTime[1]) + (Number(this.jobStartTime[1]) < 12 && this.jobStartTime[4] ? 12 : 0)
            );
            this.selectedDateTime.setMinutes(Number(this.jobStartTime[3]) || 0);
            this.selectedDateTime.setSeconds(0, 0);
            this.startDateAndTime = this.datePipe.transform( this.selectedDateTime, 'dd-MM-yyyy HH:mm:ss');
 }
 this.bulkWhatsappFormData['email'].forEach((email) => {
  this.bulkWhatsappEmailList.push(email.email);
});
    this._matDialog.open(content);
  }

  public gotoConfirmDialog(id) {
    const message = this.translate.translateErrorMessages('CANCEL_SELECTED_JOB');
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
  });
  dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const params = {
          jobId: id
        };
        this.showSpinner = this.loaderService.showProgressBar();
        this.bulkWhatsappUploadControllerService.cancelSmsJobProcess(params).subscribe((resp) => {
          if (resp) {
            
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage( 'success', resp);
            this.getWhatsappJobs();
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      } else {
        this._matDialog.closeAll();
      }
  });
  }
  
  public onDownload() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.bulkSmsControllerService.getBulkSmsTemplateUrl().subscribe(
      (resp) => {
        if (resp) {
          this.loaderService.hideProgressBar(this.showSpinner);
          window.location.assign(resp.url);
        }
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getWhatsappJobs() {
    if (this.solutionTypeId && this.profileId) {
      this.showSpinner = this.loaderService.showProgressBar();
      const params = {
        body: {
          channelId: this.channelId,
          solutionTypeId: this.solutionTypeId
        },
      };
      this.bulkWhatsappUploadControllerService
        .getWhatsappJobsByprofileIdAndSolutionTypeId(params)
        .subscribe(
          (resp) => {
            if (resp && resp.length) {
              const bulkWhatsappArray: any [] = [];
              resp.forEach(data => {
                bulkWhatsappArray.push (
                    {
                      'jobName': data['jobName'],
                      'createdBy': data['createdBy'],
                      'createdDate': data['createdDate'],
                      'profileName': data['profileName'],
                      'scheduledTime': data['scheduledTime'],
                      'status': data['status'],
                      'action': null,
                      'jobId': data['jobId'],
                      'cancelEnabled': data['cancelEnabled'],
                      'linkTrackingEnabled': data['linkTrackingEnabled'],
                      'estimatedCost': data['estimatedCost'],
					   'categoryName' : data['categoryName'],
                      'phoneNumberCount' : data['phoneNumberCount']
                    }
                  );
                });
              this.loaderService.hideProgressBar(this.showSpinner);
              this.bulkWhatsappTableData = new MatTableDataSource<any>(bulkWhatsappArray);
              this.bulkWhatsappColumns = this.filterColumns(Object.keys(bulkWhatsappArray[0]));
            } else {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.bulkWhatsappTableData = null;
              this.bulkWhatsappColumns = null;
              this.responseHandlerService.returnToastMessage(
                'error',
                'NO_DATA_AVAILABLE'
              );
            }
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }
  }

  public onClose() {
    this.fileForUpload = null;
    this.reRunJobId = null;
    this._matDialog.closeAll();
    this.onReset();
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public downloadFile(id) {
    const params = { jobId: id };
    this.bulkWhatsappUploadControllerService.getWhatsappJobFileUrlByJobName(params).subscribe((resp) => {
        this.showSpinner = this.loaderService.showProgressBar();
        if (resp.url) {
          window.location.assign(resp.url);
          this.loaderService.hideProgressBar(this.showSpinner);
        }
    },
    (err) => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  goToReRunDialog(content,id ,categoryName,phoneNumberCount) {
    this._matDialog.open(content, {
      height: '64vh',
      width: '27vw',
      disableClose: true,
    });
    this.reRunJobId = id;
    this.phoneNumberCount = phoneNumberCount;
    this.categoryName = categoryName;
  }

  public displayPieChart(data) {
    this.router.navigate(['whatsapp/job-details', { whatsappJobId : data['jobId']}]);
  } 

  public displayLinkTracking(data) {
    this.router.navigate(['whatsapp/click-tracking', { whatsappJobId : data['jobId']}]);
  }

  public executeReRun(){

    this.showSpinner = this.loaderService.showProgressBar();
    this.jobStartDate = new Date(this.reRunFormData.jobStartDate);
    this.jobStartTime = this.reRunFormData.jobStartTime.match(/(\d+)(:(\d\d))?\s*(p?)/);
    if (this.jobStartDate && this.jobStartTime) {
       this.selectedDateTime = this.jobStartDate;
          this.selectedDateTime.setHours(
              Number(this.jobStartTime[1]) + (Number(this.jobStartTime[1]) < 12 && this.jobStartTime[4] ? 12 : 0)
            );
            this.selectedDateTime.setMinutes(Number(this.jobStartTime[3]) || 0);
            this.selectedDateTime.setSeconds(0, 0);
            this.startDateAndTime = this.datePipe.transform( this.selectedDateTime, 'dd-MM-yyyy HH:mm:ss');
    }
    let grpData;
    const groupData = {
      jobId : this.reRunJobId,
      startDateAndTime: this.startDateAndTime ,
      jobName: this.reRunFormData.jobName,
    };

    const reqObj = {
      body: {
        bulkWhatsappDto: JSON.stringify(groupData),
        file: null,
      },
    };
    grpData = reqObj;
    this.bulkWhatsappUploadControllerService.bulkWhatsappUploadExcel(grpData).subscribe(
      (resp) => {
        if (resp) {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'JOB_CREATION_SUCCESS'
          );
          this.reRunJobId = null;
          this.onClose();
          this.getWhatsappJobs();
        }
      },

      (err) => {
        this.onClose();
        this.reRunJobId = null;
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  public getAccessKey() { 
    this.accesskeyLifeCycleControllerService.getAccessKeyDetails1({
      profileId: this.profileId, solutionTypeId: this.solutionTypeId
    }).subscribe(resp => {      
      if(resp){
        this.accessKeyList = resp;
      }
      else{
        this.accessKeyList = [];
      }
    }, err => {
       this.accessKeyList = [];
       this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public optInPhoneNumbers(){
    if (this.testCampaignForm.valid) {
      this.showSpinner = this.loaderService.showProgressBar();
      const formValue = this.testCampaignForm.value;
      this.accesskey= this.accessKeyList.find(key => key.keyName == formValue['accessKey'] ).accessKey;
      var inputString = formValue['sendTo'];
      var numbersArray = inputString.split(',');

      this.phoneNumberList = [];
      numbersArray.forEach((number) => {
        if (number !== '') {
          var receiver = {
            phoneNumber: number
          };
          this.phoneNumberList.push(receiver);
        }
      });
      const request = {
        businessMobileNumber: this.businessMobileNumber,
        phoneNumbers: this.phoneNumberList
      };
      this.whatsappMessageControllerService.optInMobileNumbers({
        accessKey : this.accesskey,
        body :request
      }).subscribe(resp => {      
          this.sendWhatsappMessage();
      }, err => {
         this.loaderService.hideProgressBar(this.showSpinner);
         this.responseHandlerService.returnToastMessage('error','NOT_SUBSCRIBED_TO_WHATSAPP');
      });
    }
  }

  public sendWhatsappMessage(){
    const formValue = this.testCampaignForm.value;
    let messageContent = this.templateContent;
    for (let i = 1; i <=this.placeholderCount; i++) {
      messageContent = messageContent.replace(/{#var#}/, formValue['param' + i]);
    }
    this.sendMessageBody = {
      businessMobileNumber: this.businessMobileNumber,
      message: messageContent,
      receivers: this.phoneNumberList,
    };
    this.whatsappMessageControllerService.sendWhatsappMessage({
      accessKey : this.accesskey,
      body : this.sendMessageBody
    }).subscribe(resp => {     
      this.onClose(); 
      if(resp){
        let message='';
        const totalMessages = resp.totalCount - resp.duplicateCount;
        const successMessage = totalMessages === 1 
        ? `${totalMessages} ${this.translate.translateErrorMessages('WHATSAPP_MESSAGE_SUCCESS_SINGULAR')}`
        : `${totalMessages} ${this.translate.translateErrorMessages('WHATSAPP_MESSAGES_SUCCESS')}`;

        if(resp.totalCount == resp.invalidPhoneNumbers?.length + resp.duplicateCount){
          message = this.translate.translateErrorMessages('WHATSAPP_MESSAGES_FAILED')+resp.invalidPhoneNumbers?.length+ this.translate.translateErrorMessages('INVALID_MOBILE_NUMBERS');
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
          'error',message,5000);
        }
        else if(resp.invalidPhoneNumbers?.length>0){
          message = successMessage +', '+ resp.invalidPhoneNumbers?.length + this.translate.translateErrorMessages('INVALID_MOBILE_NUMBERS');
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
          'warning',message,5000);
        }
        else{
          message = successMessage;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
          'success',message,5000);
        }
    }else{
      this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
          'error','WHATSAPP_MESSAGES_FAILED',5000);
    }
      
    }, err => {
      this.onClose();
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getChannel(profileId: string) {
    this.whatsappProfileConnectionDetailsControllerService
      .fetchProfileMappedChannels({ profileId: profileId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            const response = resp.map(item => ({
              'id': item.id,
              'name': item.channelName,
              'wabaNumber':item.wabaNumber
            }));
            this.dropDownValues['channel'] = response;
          } else {
            this.dropDownValues['channel'] = [];
          }
        },
        (err) => {
          this.dropDownValues['channel'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  getWabaNumberById(id: string): string {
    const item = this.dropDownValues['channel'].find(item => item.id === id);
    return item ? item.wabaNumber : '';
  }
  
}
