/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-dynamic-form-device',
  templateUrl: './dynamic-form-device.component.html',
  styleUrls: ['./dynamic-form-device.component.scss']
})
/**
 * @ngdoc component
 * @name SharedModule.component:DynamicFormDevice
 *
 *
 * @description
 * description: To render form dynamically for device configuration
 *
 */
export class DynamicFormDeviceComponent implements OnInit {
  configureForm: UntypedFormGroup;
  formBase: any[];
  submitted = false;
  public registrationForm = false;
  @Input() deviceId: string;
  @Input() deviceProperties: any;
  @Input() columnsInForm: string;
  @Output() formEmitter: EventEmitter<any> = new EventEmitter();
  @Input() required: boolean;
  @Output() resetForm: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {
    if (!this.deviceId) {
      this.registrationForm = true;
    } else {
      this.registrationForm = false;
    }
    this.formBase = this.deviceProperties;
    this.configureForm = this.toFormGroup(this.deviceProperties);
  }

  /**
   * @ngdoc method
   * @name DynamicFormDevice#toFormGroup
   *
   * @methodOf
   * SharedModule.controller:DynamicFormDevice
   *
   * @description
   * Description: Dynamically creates form control instances based on the JSON file received from server.
   *
   * @param {type} formBase
   * @return {type} null
   */
  public toFormGroup(formBase: any) {
    const group: any = {};
    formBase.forEach((formField) => {
      group[formField['lbl']] =
        formField['rgx'] && formField['min'] && formField['max']
          ? new UntypedFormControl(formField['val'] || null, [
              Validators.required,
              this.noWhitespaceValidator,
              Validators.pattern(formField['rgx']),
              Validators.minLength(formField['min']),
              Validators.maxLength(formField['max']),
            ])
          : formField['rgx'] && formField['min']
          ? new UntypedFormControl(formField['val'] || null, [
              Validators.required,
              this.noWhitespaceValidator,
              Validators.pattern(formField['rgx']),
              Validators.minLength(formField['min']),
            ])
          : formField['rgx'] && formField['max']
          ? new UntypedFormControl(formField['val'] || null, [
              Validators.required,
              this.noWhitespaceValidator,
              Validators.pattern(formField['rgx']),
              Validators.maxLength(formField['max']),
            ])
          : formField['min'] && formField['max']
          ? new UntypedFormControl(formField['val'] || null, [
              Validators.required,
              this.noWhitespaceValidator,
              Validators.min(formField['min']),
              Validators.max(formField['max']),
            ])
          : formField['min']
          ? new UntypedFormControl(formField['val'] || null, [
              Validators.required,
              this.noWhitespaceValidator,
              Validators.minLength(formField['min']),
            ])
          : formField['max']
          ? new UntypedFormControl(formField['val'] || null, [
              Validators.required,
              this.noWhitespaceValidator,
              Validators.maxLength(formField['max']),
            ])
          : formField['rgx']
          ? new UntypedFormControl(formField['val'] || null, [
              Validators.required,
              this.noWhitespaceValidator,
              Validators.pattern(formField['rgx']),
            ])
          : new UntypedFormControl(formField['val'] || null, [
              Validators.required,
              this.noWhitespaceValidator,
            ]);
    });
    return new UntypedFormGroup(group);
  }

  /**
   * @ngdoc method
   * @name DynamicFormDevice#noWhitespaceValidator
   *
   * @methodOf
   * SharedModule.controller:DynamicFormDevice
   *
   * @description
   * Description: to validate fields for null values and white spaces
   *
   * @param {type} control
   * @return {type}
   */
  noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace =
      ((control && control.value && control.value.toString()) || '').trim()
        .length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { required: true };
  }

  /**
   * @ngdoc method
   * @name DynamicFormDevice#onUpdate
   *
   * @methodOf
   * SharedModule.controller:DynamicFormDevice
   *
   * @description
   * Description: Emits the modified form values(twin properties) to configure device component.
   *
   * @param {type} formBase
   * @return {type} null
   */
  public onUpdate() {
    this.submitted = true;
    if (this.configureForm.invalid) {
      return;
    }
    this.formEmitter.emit(this.configureForm.value);
  }

  /**
   * @ngdoc method
   * @name DynamicFormDevice#onReset
   *
   * @methodOf
   * SharedModule.controller:DynamicFormDevice
   *
   * @description
   * Description: Resets the form fields.
   *
   * @param {type} formBase
   * @return {type} null
   */
  public onReset() {
    this.submitted = false;
    this.configureForm.reset();
    this.resetForm.emit('resetButtonClicked');
  }
}
