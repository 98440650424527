import { Component, ElementRef, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingCommonDto } from 'src/app/services/Platform/models';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TranslateService } from '@ngx-translate/core';
import { DOWNLOAD_IMAGE, SEARCH_IMAGE, SMS_ICON, CHEVRON_LEFT } from 'src/app/shared/constants/strings';
import { PieChartService } from 'src/app/providers/pie-chart-service';
import { SmsDeliveryReportControllerService } from 'src/app/services/SMS/services';
import { BulkSmsUploadControllerService } from 'src/app/services/Platform/services';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-sms-job-details',
  templateUrl: './sms-job-details.component.html',
  styleUrls: ['./sms-job-details.component.scss']
})
export class SmsJobDetailsComponent implements OnInit, OnDestroy {
  public showSpinner :any;
  public dataSource;
  public columns: any[];
  public ignoreList = [];
  public solutionTypeId: string;
  public smsJobId: string;
  public jobDetailsData;
  public chartDataObject = {
    title: '',
    subtitle: '',
    tooltip: '',
    details: []
  };
  public downloadImage;
  public totalJobsCount = 0;
  public totalSmsCount = 0;
  public successCount = 0;
  public failedCount = 0;
  public inProgressCount = 0;
  public smsJobName: string;
  public smsDetailsData;
  public smsIcon: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  public color;
  public backImage;
  public smsDetailsInputData = {};
  public status;
  public searchImage = SEARCH_IMAGE;
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public pieChartSubscription: Subscription;
  @ViewChild('charts', { static: true }) public chartElement: ElementRef;
  constructor(private smsDeliveryReportControllerService: SmsDeliveryReportControllerService,
      private  bulkSmsUploadControllerservice: BulkSmsUploadControllerService , private router: Router, private translate: TranslateService,
      private route: ActivatedRoute, private responseHandlerService: ResponseHandlerService, private pieChartService: PieChartService,
      private datepipe: DatePipe,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.smsJobId = this.route.snapshot.paramMap.get('smsJobId');
    this.backImage = CHEVRON_LEFT;
    this.searchImage = SEARCH_IMAGE;
    this.downloadImage = DOWNLOAD_IMAGE;
    this.smsIcon = SMS_ICON;
    this.color = {
      'IN_PROGRESS': '#35a6c9', 'FAILED': '#f75133', 'SUCCESS': '#78BE20'
    };
    if (this.smsJobId) {
      this.getSmsJobDeliveryStatus();
    }
  }

  ngOnDestroy() {
    if (this.pieChartSubscription) {
       this.pieChartSubscription.unsubscribe();
     }
    }


  public getSmsJobDeliveryStatus() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.bulkSmsUploadControllerservice.getSmsJobByJobId({jobId: this.smsJobId}).subscribe((resp) => {
      if (resp) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.accountId = resp['accountDetails'].id;
        this.tenantId = resp['tenantDetails'].id;
        this.solutionTypeId = resp['solutionTypeDetails'].id;
        this.profileId = resp['profileDetails'].id;
        this.smsJobName = resp['jobname'];
        this.smsDetailsData = {
          SMS_DETAILS: [{ 'label': 'SMS_JOB_NAME', 'value': resp['jobname'] },
          { 'label': 'SMS_START_DATE', 'value': this.datepipe.transform(resp['startDateAndTime'], 'dd-MM-yyyy') },
          { 'label': 'STATUS', 'value': resp['status'] }
          ],
          SEARCH_CRITERIA: [
          { 'label': 'ACCOUNT', 'value': resp['accountDetails'].name },
          { 'label': 'TENANT', 'value': resp['tenantDetails'].name },
          { 'label': 'BUSINESS_PROFILE', 'value': resp['profileDetails'].name },
          { 'label': 'SOLUTION_TYPE', 'value': resp['solutionTypeDetails'].name }]
        };
        this.getSmsCount();
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }


  public getSmsCount() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.smsDeliveryReportControllerService.getSmsJobDeliveryStatus({jobId: this.smsJobId}).subscribe(resp => {
        if (resp && resp.length) {
        this.getTotalJobCount(resp);
        this.chartDataObject.details = this.getChartData(resp);
        this.chartDataObject.title = this.getTranslatedValues('SMS_STATUS');
        this.pieChartService.createChart(this.chartElement.nativeElement, this.chartDataObject);
        this.pieChartSubscription = this.pieChartService.functionNameEmitter.subscribe(status => {
          if (this.smsJobId) {
            this.getSmsDetailsInputData(status);
          }
        });
        this.loaderService.hideProgressBar(this.showSpinner);
      } else {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('error', 'NO_DATA_AVAILABLE');
      }
      },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
  }


  public getSmsDetailsOnStatus(smsDetailsInputData) {
    this.jobDetailsData = null;
    this.showSpinner = this.loaderService.showProgressBar();
    this.smsDeliveryReportControllerService.getSmsJobPhoneNumbersBasedOnStatus(smsDetailsInputData).subscribe((resp) => {
        if (resp) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.jobDetailsData = resp;
              this.jobDetailsData.forEach((response) => {
                if (response['deliveryTimeStamp']) {
                  response['deliveryTimeStamp'] = this.datepipe.transform(response['deliveryTimeStamp'], 'dd-MM-yyyy HH:mm:ss');
                }
              });
              this.columns = this.filterColumns(Object.keys(this.jobDetailsData[0]));
              this.dataSource = new MatTableDataSource<SettingCommonDto>(this.jobDetailsData);
            } else {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
            }
     },
     err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    }
    );
  }


  public getTotalJobCount(tempData) {
    const data = tempData;
    data.forEach(element => {
    if (element['status'] === 'SUCCESS') {
      this.successCount = element['count'];
    } else if (element['status'] === 'FAILED') {
     this.failedCount = element['count'];
    } else {
      this.inProgressCount = element['count'];
    }

    this.totalJobsCount += element['count'];
    }
  );
  }

  public getSmsDetailsInputData(status) {
    if (this.status && this.status !== status && this.paginator.pageIndex) {
          this.paginator.pageIndex = 0;
    }
    if (status === 'SUCCESS') {
     this.totalSmsCount = this.successCount;
    } else if (status === 'FAILED') {
      this.totalSmsCount = this.failedCount;
    } else {
      this.totalSmsCount = this.inProgressCount;
    }
    this.smsDetailsInputData = {
      jobId: this.smsJobId, status: status
    };
    this.status = status;
    this.getSmsDetailsOnStatus(this.smsDetailsInputData);
  }



  public pageEvent(event) {
    this.smsDetailsInputData['status'] = this.status;
    this.smsDetailsInputData['page'] = event.pageIndex;
    this.smsDetailsInputData['limit'] = event.pageSize;
    this.getSmsDetailsOnStatus(this.smsDetailsInputData);
  }

  public getChartData(smsStatusData) {
    const data = smsStatusData;
    data.forEach(element => {
      element['name'] = (this.getTranslatedValues(element['status']));
      element['y'] = (element['count'] * this.totalJobsCount) / 100;
      element['color'] = this.color[element['status']];
    });
    return data;
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }


  public navigateBack() {
    this.router.navigate(['sms/bulk-sms', {smsJobId : this.smsJobId , accountId: this.accountId,
       tenantId: this.tenantId,  solutionTypeId :  this.solutionTypeId, profileId : this.profileId}]);
    this.smsJobId = null;
  }

}
