import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { TranslationService } from 'src/app/providers/translation-service';
import { JobStatusDto } from 'src/app/services/Scheduler/models/job-status-dto';
import { SchedulerControllerService } from 'src/app/services/Scheduler/services/scheduler-controller.service';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import {
  DELETE_IMAGE, EDIT_IMAGE, TOAST_MSG_DURATION, GENERATE_IMAGE, CONFIGURE_IMAGE,
  MANAGE, SCHEDULER_ICON, MORE_OPTION
} from 'src/app/shared/constants/strings';
import { ShowHistoryDailogComponent } from 'src/app/shared/show-history-dialog/show-history-dialog.component';
import { SchedulerDto } from 'src/app/services/Scheduler/models/scheduler-dto';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-manage-scheduler',
  templateUrl: './manage-scheduler.component.html',
  styleUrls: ['./manage-scheduler.component.scss']
})
export class ManageSchedulerComponent implements OnInit {

  public dataSource;
  public ignoreList = ['id', 'description', 'procedureId', 'parameterList', 'parameterValues', 'active', 'executionTime',
    'startDate', 'endDate', 'procedureInfo', 'nextExecutionTime', 'schedulerExecutionType', 'apiList',
    'lastExecutionTime', 'nextExecutionTime'];
  public columns: any[];
  public showSpinner :any;
  public schedulerData: any;
  editImage;
  deleteImage;
  executeImage;
  showHistoryImage;
  public mode: string;
  jobStatusDtoList: Array<JobStatusDto>;
  schedulerHistoryData: any;
  public pageKey: string;
  public schedulerIcon;
  asideMenus: Array<AsideCard>;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  public schedulersCount;
  limit: number = this.pageSizeOptions[0];
  searchDto: any = null;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  moreOptionsImage: string;
  searchObj: SchedulerDto = null;
  pageIndex: any;

  constructor(private schedulerControllerService: SchedulerControllerService, private responseHandlerService: ResponseHandlerService,
    private translate: TranslationService, private _matDialog: MatDialog, private router: Router, private _snackBar: MatSnackBar,
    private rightsideNavitemsService: RightsideNavitemsService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.pageKey = 'fota';
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.editImage = EDIT_IMAGE;
    this.deleteImage = DELETE_IMAGE;
    this.executeImage = GENERATE_IMAGE;
    this.showHistoryImage = CONFIGURE_IMAGE;
    this.schedulerIcon = SCHEDULER_ICON;
    this.moreOptionsImage = MORE_OPTION;
    this.searchDto = { body: this.searchObj, page: 0, limit: this.limit };
    this.getSchedulerCount();
    this.getSchedulers();
  }

  public getSchedulerCount() {
    this.schedulerControllerService.schedulersCount().subscribe(resp => {
      this.schedulersCount = resp;
    });
  }

  public getSchedulers() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.schedulerControllerService.fetchAllSchedules({ page: this.pageIndex, limit: this.limit, body: this.searchDto })
      .subscribe(resp => {
        if (resp) {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.schedulerData = resp;
          for (const data of this.schedulerData) {
            data['databaseName'] = data['procedureInfo']['databaseName'];
            data['procedure'] = data['procedureInfo']['procedureName'];
            data['next - ExecutionTime(24Hrs)'] = data['nextExecutionTime'];
            data['action'] = null;
          }
          this.dataSource = new MatTableDataSource<any>(this.schedulerData);
          if (this.schedulerData.length > 0) {
            this.columns = this.filterColumns(Object.keys(this.schedulerData[0]));
          }
        } else {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.dataSource = null;
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
        }
      },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.dataSource = null;
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  public gotoConfirmDialog(data = null, mode?) {
    this.schedulerData = data;
    let message;
    this.mode = mode;
    switch (mode) {
      case 'edit': if ((data['active'])) {
        message = this.translate.translateErrorMessages('ACTIVATE_SELECTED_SCHEDULER');
      } else {
        message = this.translate.translateErrorMessages('DEACTIVATE_SELECTED_SCHEDULER');
      }
        break;
      case 'delete': message = this.translate.translateErrorMessages('DELETE_SELECTED_SCHEDULER');
        break;
    }

    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (mode) {
          case 'edit': if (data['active']) {
            this.activateScheduler(data);
          } else {
            this.deActivateScheduler(data);
          }
            break;
          case 'delete': this.deleteScheduler(data);
            break;
        }
      } else {
        this.abortAction();
      }
    });
  }

  public activateScheduler(data) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    this.schedulerControllerService.activateScheduler({ schedulerId: data['id'] }).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this._snackBar.open(this.translate.translateErrorMessages('SCHEDULER_ACTIVATE_SUCCESS'), '', {
          duration: TOAST_MSG_DURATION,
          verticalPosition: 'top',
          panelClass: ['success'],
        });
        this.getSchedulers();
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this._snackBar.open(err.error.message, '', {
          duration: TOAST_MSG_DURATION,
          verticalPosition: 'top',
          panelClass: ['error'],
        });
        data['status'] = false;
      }
    );
  }

  public deActivateScheduler(data) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    this.schedulerControllerService.deactivateScheduler({ schedulerId: data['id'] })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this._snackBar.open(this.translate.translateErrorMessages('SCHEDULER_DEACTIVATE_SUCCESS'), '', {
            duration: TOAST_MSG_DURATION,
            verticalPosition: 'top',
            panelClass: ['success'],
          });
          this.getSchedulers();
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this._snackBar.open(err.error.message, '', {
            duration: TOAST_MSG_DURATION,
            verticalPosition: 'top',
            panelClass: ['error'],
          });
        }
      );
  }

  public deleteScheduler(data: any) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.schedulerControllerService.deleteScheduler({ schedulerId: data['id'] }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', 'SCHEDULER_DELETE_SUCCESS');
      this.getSchedulers();
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public abortAction() {
    this._matDialog.closeAll();
    if (this.mode !== 'delete') {
      this.schedulerData['active'] = !this.schedulerData['active'];
    }
  }

  gotoScheduler(schedulerId) {
    this.router.navigate(['schedulers/create-scheduler', { schedulerId: schedulerId['id'] }]);
  }


  gotoShowHistoryModal(data) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.schedulerControllerService.calllog1({ schedulerId: data['id'] })
      .subscribe(resp => {
        if (resp) {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.schedulerHistoryData = resp;
          const dialogRef = this._matDialog.open(ShowHistoryDailogComponent, {
            maxWidth: '100%',
            data: {
              title: 'SCHEDULER_HISTORY',
              dataKey: this.schedulerHistoryData,
            }

          });
        } else {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.dataSource = null;
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
        }
      },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.dataSource = null;
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
  }
  executeNow(data) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.schedulerControllerService.execute({ schedulerId: data['id'] })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'SCHEDULER_EXECUTED_SUCCESSFULLY');
          this.getSchedulers();
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error);


        });
  }
  public pageEvent(event) {
    this.pageIndex = event.pageIndex;
    this.limit = event.pageSize;
    this.getSchedulers();
  }
}
