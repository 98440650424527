/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class FotaCronControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fotaRollOut
   */
  static readonly FotaRollOutPath = '/api/v1/rollout/fota';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fotaRollOut()` instead.
   *
   * This method doesn't expect any request body.
   */
  fotaRollOut$Response(params?: {
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FotaCronControllerService.FotaRollOutPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fotaRollOut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fotaRollOut(params?: {
  }): Observable<{
}> {

    return this.fotaRollOut$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation updateFotaJobReqStatus
   */
  static readonly UpdateFotaJobReqStatusPath = '/api/v1/cron/fotaJobReqStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFotaJobReqStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFotaJobReqStatus$Response(params?: {
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FotaCronControllerService.UpdateFotaJobReqStatusPath, 'patch');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFotaJobReqStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFotaJobReqStatus(params?: {
  }): Observable<{
}> {

    return this.updateFotaJobReqStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation updateFotaJobCompleteStatus
   */
  static readonly UpdateFotaJobCompleteStatusPath = '/api/v1/cron/fotaJobUpdateStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFotaJobCompleteStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFotaJobCompleteStatus$Response(params?: {
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FotaCronControllerService.UpdateFotaJobCompleteStatusPath, 'patch');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateFotaJobCompleteStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFotaJobCompleteStatus(params?: {
  }): Observable<{
}> {

    return this.updateFotaJobCompleteStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation sendFotaEmail
   */
  static readonly SendFotaEmailPath = '/api/v1/cron/fota/sendEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendFotaEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendFotaEmail$Response(params?: {
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FotaCronControllerService.SendFotaEmailPath, 'patch');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendFotaEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendFotaEmail(params?: {
  }): Observable<{
}> {

    return this.sendFotaEmail$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation updateNotRespondingStatus
   */
  static readonly UpdateNotRespondingStatusPath = '/api/v1/cron/fota/notResponding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateNotRespondingStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateNotRespondingStatus$Response(params?: {
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, FotaCronControllerService.UpdateNotRespondingStatusPath, 'patch');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateNotRespondingStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateNotRespondingStatus(params?: {
  }): Observable<{
}> {

    return this.updateNotRespondingStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
