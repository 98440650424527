/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SmsJobPhoneNumbersDto } from '../models/sms-job-phone-numbers-dto';
import { SmsJobStatusDto } from '../models/sms-job-status-dto';
import { SmsReportRequestDto } from '../models/sms-report-request-dto';
import { SmsReportResponseDto } from '../models/sms-report-response-dto';
import { DeliveryReport } from '../models/delivery-report';

@Injectable({
  providedIn: 'root',
})
export class SmsDeliveryReportControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSmsJobDeliveryStatus
   */
  static readonly GetSmsJobDeliveryStatusPath = '/api/v1/sms-job/{jobId}/status';

  /**
   * Get SMS Job delivery status.
   *
   * The API to get SMS Job Status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsJobDeliveryStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobDeliveryStatus$Response(params: {
    jobId: string;
  }): Observable<StrictHttpResponse<Array<SmsJobStatusDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsDeliveryReportControllerService.GetSmsJobDeliveryStatusPath, 'get');
    if (params) {
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SmsJobStatusDto>>;
      })
    );
  }

  /**
   * Get SMS Job delivery status.
   *
   * The API to get SMS Job Status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsJobDeliveryStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobDeliveryStatus(params: {
    jobId: string;
  }): Observable<Array<SmsJobStatusDto>> {

    return this.getSmsJobDeliveryStatus$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SmsJobStatusDto>>) => r.body as Array<SmsJobStatusDto>)
    );
  }

  /**
   * Path part for operation getSmsDeliveryReports
   */
  static readonly GetSmsDeliveryReportsPath = '/api/v1/delivery-report';

  /**
   * Get SMS delivery report.
   *
   * The API to get Delivery report if records<50
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsDeliveryReports()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSmsDeliveryReports$Response(params?: {
    body?: SmsReportRequestDto
  }): Observable<StrictHttpResponse<SmsReportResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, SmsDeliveryReportControllerService.GetSmsDeliveryReportsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsReportResponseDto>;
      })
    );
  }

  /**
   * Get SMS delivery report.
   *
   * The API to get Delivery report if records<50
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsDeliveryReports$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSmsDeliveryReports(params?: {
    body?: SmsReportRequestDto
  }): Observable<SmsReportResponseDto> {

    return this.getSmsDeliveryReports$Response(params).pipe(
      map((r: StrictHttpResponse<SmsReportResponseDto>) => r.body as SmsReportResponseDto)
    );
  }

  /**
   * Path part for operation downloadSmsDeliveryReports
   */
  static readonly DownloadSmsDeliveryReportsPath = '/api/v1/delivery-report/excel';

  /**
   * Download SMS delivery report.
   *
   * The API to download SMS Delivery report in excel format
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadSmsDeliveryReports()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadSmsDeliveryReports$Response(params?: {
    body?: SmsReportRequestDto
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SmsDeliveryReportControllerService.DownloadSmsDeliveryReportsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Download SMS delivery report.
   *
   * The API to download SMS Delivery report in excel format
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadSmsDeliveryReports$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  downloadSmsDeliveryReports(params?: {
    body?: SmsReportRequestDto
  }): Observable<void> {

    return this.downloadSmsDeliveryReports$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getSmsJobPhoneNumbersBasedOnStatus
   */
  static readonly GetSmsJobPhoneNumbersBasedOnStatusPath = '/api/v1/sms-job/{jobId}';

  /**
   * Get SMS Job numbers.
   *
   * The API to get  SMS Job List of mobile numbers based on Status
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsJobPhoneNumbersBasedOnStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobPhoneNumbersBasedOnStatus$Response(params: {
    jobId: string;
    status: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<Array<SmsJobPhoneNumbersDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsDeliveryReportControllerService.GetSmsJobPhoneNumbersBasedOnStatusPath, 'get');
    if (params) {
      rb.path('jobId', params.jobId, {});
      rb.query('status', params.status, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SmsJobPhoneNumbersDto>>;
      })
    );
  }

  /**
   * Get SMS Job numbers.
   *
   * The API to get  SMS Job List of mobile numbers based on Status
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsJobPhoneNumbersBasedOnStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobPhoneNumbersBasedOnStatus(params: {
    jobId: string;
    status: string;
    page?: number;
    limit?: number;
  }): Observable<Array<SmsJobPhoneNumbersDto>> {

    return this.getSmsJobPhoneNumbersBasedOnStatus$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SmsJobPhoneNumbersDto>>) => r.body as Array<SmsJobPhoneNumbersDto>)
    );
  }

   /**
   * Path part for operation submitSmsdeliveryRequest
   */
   static readonly SubmitSmsdeliveryRequestPath = '/api/v2/delivery-report';

   /**
    * Place SMS delivery report request.
    *
    * The API to place a new request to download high volume SMS Delivery reports
    *
    * This method provides access to the full `HttpResponse`, allowing access to response headers.
    * To access only the response body, use `submitSmsdeliveryRequest()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   submitSmsdeliveryRequest$Response(params?: {
     body?: SmsReportRequestDto
   }
 ): Observable<StrictHttpResponse<SmsReportResponseDto>> {
 
     const rb = new RequestBuilder(this.rootUrl, SmsDeliveryReportControllerService.SubmitSmsdeliveryRequestPath, 'post');
     if (params) {
       rb.body(params.body, 'application/json');
     }
 
     return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
     })).pipe(
       filter((r: any) => r instanceof HttpResponse),
       map((r: HttpResponse<any>) => {
         return r as StrictHttpResponse<SmsReportResponseDto>;
       })
     );
   }

  /**
   * Place SMS delivery report request.
   *
   * The API to place a new request to download high volume SMS Delivery reports
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitSmsdeliveryRequest$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitSmsdeliveryRequest(params?: {
    body?: SmsReportRequestDto
  }
): Observable<SmsReportResponseDto> {

    return this.submitSmsdeliveryRequest$Response(params).pipe(
      map((r: StrictHttpResponse<SmsReportResponseDto>) => r.body as SmsReportResponseDto)
    );
  }

  /**
   * Path part for operation getAllSmsdeliveryReports
   */
  static readonly GetAllSmsdeliveryReportsPath = '/api/v2/delivery-report';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSmsdeliveryReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSmsdeliveryReports$Response(params?: {
  }
): Observable<StrictHttpResponse<Array<DeliveryReport>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsDeliveryReportControllerService.GetAllSmsdeliveryReportsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeliveryReport>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSmsdeliveryReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSmsdeliveryReports(params?: {
  }
): Observable<Array<DeliveryReport>> {

    return this.getAllSmsdeliveryReports$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeliveryReport>>) => r.body as Array<DeliveryReport>)
    );
  }

  
  /**
   * Path part for operation getZippedFile
   */
  static readonly GetZippedFilePath = '/api/v1/delivery-report/{requestId}';

  /**
   * Download SMS delivery report request.
   *
   * The API is to download high volume SMS Delivery reports for which the request is completed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getZippedFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getZippedFile$Response(params: {
    requestId: string;
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SmsDeliveryReportControllerService.GetZippedFilePath, 'get');
    if (params) {
      rb.path('requestId', params.requestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
/**
   * Download SMS delivery report request.
   *
   * The API is to download high volume SMS Delivery reports for which the request is completed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getZippedFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
getZippedFile(params: {
  requestId: string;
}
): Observable<void> {

  return this.getZippedFile$Response(params).pipe(
    map((r: StrictHttpResponse<void>) => r.body as void)
  );
}

  /**
   * Path part for operation getZippedFile
   */
  static readonly DeleteZippedFilePath = '/api/v1/delivery-report/{requestId}';

  /**
   * Download SMS delivery report request.
   *
   * The API is to download high volume SMS Delivery reports for which the request is completed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getZippedFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFile$Response(params: {
    requestId: string;
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SmsDeliveryReportControllerService.DeleteZippedFilePath, 'delete');
    if (params) {
      rb.path('requestId', params.requestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }
/**
   * Download SMS delivery report request.
   *
   * The API is to download high volume SMS Delivery reports for which the request is completed
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getZippedFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
deleteFile(params: {
  requestId: string;
}
): Observable<void> {

  return this.deleteFile$Response(params).pipe(
    map((r: StrictHttpResponse<void>) => r.body as void)
  );
}
}
