<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img  *ngIf="hasRouteData" class="back-btn" (click)="navigateBack()" [src]="backImage" />
        <img class="page-icon" [src]="billingIcon" />
        <span>{{'CREATE_PROPOSAL' |translate}}</span>
      </span>
    </div>
    <mat-accordion class="accordion-style">
      <mat-expansion-panel class="configuration-card" (click)="onAccordionClicked(generalConfigurationPanel, 'general-configuration')" (opened)="onAccordionExpansion('general-configuration')"  #generalConfigurationPanel>
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon" [src]="generalInfoImage" />
            <span>{{ 'GENERAL_CONFIGURATIONS' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body" *ngIf="showGeneralPanel">
          <app-dynamic-form [jsonFile]="generalJsonFileLoaded" [dropDownValues]="dropDownValues"
            (valueSelected)="updateDropdownValues($event)" [saveAndContinue]="true" [columnsInForm]="'table-form'"
            (formEmitter)="createGeneralConfiguration($event, infraConfigurationPanel)"  (emitOnFormArrayReset)="onFormArrayReset($event)"
            (emitOnInputBlur)="onInputBlur($event)" (checkboxChangeEvent)="premiumCheckboxChange($event)">
          </app-dynamic-form>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="configuration-card" (click)="onAccordionClicked(infraConfigurationPanel, 'infra-configuration')"  (opened)="onAccordionExpansion('infra-configuration')" #infraConfigurationPanel>
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon" [src]="infrastructureImage" />
            <span>{{ 'INFRASTRUCTURE_CONFIGURATIONS' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body" *ngIf="showInfraPanel">
          <app-dynamic-form [jsonFile]="infraJsonFileLoaded" [columnsInForm]="'single'"
            [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)" [saveAndContinue]="true"
            [checkboxFlag]="stpCheckbox" (formEmitter)="configureInfraConfiguration($event, saasConfigurationPanel)" (buttonClickEvent)="updateInfraConfigValidation();updateInControlTower()">
          </app-dynamic-form>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="configuration-card" (click)="onAccordionClicked(saasConfigurationPanel,'saas-configuration')" (opened)="onAccordionExpansion('saas-configuration')" #saasConfigurationPanel>
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon" [src]="saasConfigImage" />
            <span>{{ 'SAAS_CONFIGURATIONS' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="saas-div">
          <form [formGroup]="saasForm">
            <mat-tree [dataSource]="saasServices" [treeControl]="treeControl">
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node *matTreeNodeDef="let service" matTreeNodePadding>
                <button mat-icon-button disabled></button>
                <mat-checkbox *ngIf="service.level > 0" 
                  class="checklist-leaf-node" 
                  [formControlName]="service.skuId" 
                  (change)="checkboxChange(service.skuId,service.productId)"  
                  [id]="service.skuId">
                  &nbsp;&nbsp;
                </mat-checkbox>
                <div class="saas-service-div">
                  <span class="inner-content-flex">
                    {{ service.name }}
                  </span>
                    <div class="discount-input-align">
                      <div class="discount-percentage-saas">
                       <input class="sass-input" type="text" (input)="discountChange($event,service.productId,service.skuId)" matInput [formControlName]="service.skuId + '_discountPercentage'" />
                      </div>
                      <div class="sass-label">
                        <mat-label class="sassLabel">{{ 'SASS_DISCOUNT_PERCENTAGE' | translate }}</mat-label>
                        <span [id]="service.productId +'id'"  class="sass-mandatory">*</span>
                      </div>
                      <div class="flat-service" *ngIf="!service.defaultRatecard">
                        <span>{{'ONLINE_LIST_PRICE' |translate}} <span class="rate">{{service?.rate}} {{service?.displayCurrency}}</span></span>
                     </div>
                   </div>
                    <div>
                      <span [ngClass]="service.skuId +'id'"  class="discount-error">{{'DISCOUNT_PERCENTAGE_MANDATORY' | translate }}</span>
                      <span [id]="service.productId"  class="discount-error">{{'T_INVALID_DISCOUNT_PERCENTAGE' | translate }}</span>
                    </div>
                    <div class="skuid-productid">
                    {{ 'SKU_ID' | translate }} {{ service.productId }} &nbsp;&nbsp;
                    {{ 'PRODUCT_ID' | translate }} {{ service.skuId }}
                  </div>
                   </div>
                
              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-tree-node *matTreeNodeDef="let service;when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + service.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    <span *ngIf="!treeControl.isExpanded(service)" class="saas-expand-collapse-button">+</span>
                    <span *ngIf="treeControl.isExpanded(service)" class="saas-expand-collapse-button">-</span>
                  </mat-icon>
                </button>
                <span class="saas-parent-service">{{ service.name }}</span>
              </mat-tree-node>
            </mat-tree>
          </form>
          <div>
            <section class="section">
              <button (click)="saveSaasConfig(peripheralConfigurationPanel)" mat-raised-button
                [disabled]="disableSubmitButton" [ngClass]="{'btn-background': !disableSubmitButton}"
                class="button save-continue-button">{{
                'SAVE_AND_CONTINUE' | translate }}</button>
            </section>
          </div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="configuration-card" (click)="onAccordionClicked(peripheralConfigurationPanel, 'peripheral-configuration')" (opened)="onAccordionExpansion('peripheral-configuration')" #peripheralConfigurationPanel>
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon" [src]="peripheralImage" />
            <span>{{ 'PERIPHERAL_CONFIGURATIONS' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body" *ngIf="showPeripheralPanel">
          <app-dynamic-form [jsonFile]="peripheralJsonFileLoaded" [columnsInForm]="'single'"
            [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)" [saveAndContinue]="true"
            (formEmitter)="configurePeripheralConfiguration($event, attachmentsPanel)">
          </app-dynamic-form>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="onAccordionExpansion('attachments')" (click)="onAccordionClicked(attachmentsPanel, 'attachments')"  class="configuration-card" #attachmentsPanel>
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon attachment-icon" [src]="attachmentImage" />
            <span>{{ 'ATTACHMENTS' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body" *ngIf="showAttachmentsPanel">
          <div class="form-group file">
            <div class="upload-file-container" appDragAndDrop (fileDropped)="onFileDropped($event)">
                <img class="cloud-image"  [src]=" cloudUploadImage" />
                <input [disabled]="!checkPermission('PROPSL_C') || (status !== 'DRAFT' && (status === 'SUBMITTED' || !isProposalEditable))" class="input-file-btn" type="file" #fileDropRef id="fileDropRef"
                    (change)="onFileDropped($event)" [multiple]="true" />
                <h6 class="drop-file">{{ 'DROP_FILE_OR_CHOOSE_FILE' | translate }}</h6>
            </div>
        </div>
          <section class="uploaded-documents" *ngIf="uploadedDocumentsDataSource?.length">
            <mat-table [dataSource]="uploadedDocumentsDataSource" class="table-data">
            <div *ngFor="let column of uploadedDocumentsColumns;let even = even;">
              <ng-container [matColumnDef]="column">
                <mat-header-cell  *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
                </mat-header-cell>
                <mat-cell  class="tooltip" *matCellDef="let dataName; index as i">
                  <span *ngIf="column!=='action'" title="{{dataName[column]}}" class="cell-value">
                    {{dataName[column]}}
                  </span>
                  <span *ngIf="column==='action'">
                    <a [href]="dataName['url']" download class="sample-file-link"> <img [src]="downloadImage"
                        class="modify-button" [title]="'DOWNLOAD' | translate"></a>
                    <img *ngIf="checkPermission('PROPSL_C')" [src]="deleteImage" (click)="gotoDeleteConfirmDialog(dataName['proposalId'], dataName['id'])"
                      class="modify-button" [title]="'DELETE' | translate">
                  </span>
                </mat-cell>
              </ng-container>
            </div>
            <mat-header-row *matHeaderRowDef="uploadedDocumentsColumns;"></mat-header-row>
            <mat-row *matRowDef="let data; columns: uploadedDocumentsColumns"></mat-row>
          </mat-table>
          </section>
          <section class="section">
            <button (click)="continueAfterAttachmentUpload(termsAndConditionsPanel)" mat-raised-button
              [disabled]="disableSubmitButton" [ngClass]="{'btn-background': !disableSubmitButton}"
              class="button save-continue-button">{{
              'SAVE_AND_CONTINUE' | translate }}</button>
          </section>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel (click)="onAccordionClicked(termsAndConditionsPanel,'termsAndCondition')" (opened)="onAccordionExpansion('termsAndCondition')" class="configuration-card" #termsAndConditionsPanel>
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon" [src]="documentImage" />
            <span>{{ 'TERMS_AND_CONDITIONS' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body" *ngIf="showTermsAndConditions">
          <div class="modal-body">
            <textarea [formControl]="termsAndConditionsControl" (input)="onTextInputChange()"  maxlength="5000" class="terms text-area" matInput [placeholder]="'TERMS_AND_CONDITIONS_SPECIFIC_TO_THE_CUSTOMER' | translate" required></textarea>
            <div class="character-count">{{ characterCount }} / {{ maxCharacterCount }}</div>
            <div *ngIf="saveTermsAndConditionsClicked || termsAndConditionsControl.touched && termsAndConditionsControl.errors">
            <div *ngIf="termsAndConditionsControl.errors?.required">
              <mat-error class="terms-error mat-error-class">
                {{ 'TERMS_AND_CONDITIONS' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
            <div *ngIf="termsAndConditionsControl.errors?.pattern">
              <mat-error class="terms-error mat-error-class">
                {{ 'TERMS_AND_CONDITIONS_SHOULD_CONTAIN_ATLEAST_ONE_NON_WHITESPACE_CHARACTER' | translate}}
              </mat-error>
            </div>
          </div>
          <div class="clear-float"></div>
            <section class="section">
              <button (click)="saveTermsAndConditions(proposalPanel)" mat-raised-button
                [disabled]="disableSubmitButton" [ngClass]="{'btn-background': !disableSubmitButton}"
                class="button save-continue-button">{{
                'SAVE_AND_CONTINUE' | translate }}</button>
            </section>
          </div>

        </div>
      </mat-expansion-panel>

      <!-- tbd in next PI -->
      <!-- <mat-expansion-panel class="configuration-card" #workflowPanel
      (click)="onAccordionExpansion('workflow-panel')">
      <mat-expansion-panel-header class="card-header-style">
        <mat-panel-title class="card-header-label">
          <img class="accordion-icon" [src]="workFlowImage" />
          <span>{{ 'WORKFLOW' | translate }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="panel-body">
        <section class="section">
          <button (click)="saveWorkflow(proposalPanel)" mat-raised-button class="button">{{
            'SUBMIT' | translate }}</button>
        </section>
      </div>
    </mat-expansion-panel> -->

    <div class="action-button-sales">
      <button *ngIf="checkPermission('PROPSL_C') " (click)="gotoSubmitProposalDialog(submitContent)" mat-raised-button class="button"
      [disabled]="!isProposalEditable" [ngClass]="{'btn-background': isProposalEditable}">{{
      'SUBMIT' | translate }}</button>

    </div>
 

    </mat-accordion>
  </section>

  <ng-template #submitContent let-modal>
    <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
    <div class="modal-width">
      <div class="modal-body">
        <mat-label class="label">{{ 'COMMENTS' | translate }}
        </mat-label>
        <textarea class="text-area" matInput [(ngModel)]="proposalComments"></textarea>
        <section class="section">
          <button (click)="submitProposal()" mat-raised-button class="button btn-background">
            {{'SUBMIT' | translate }}</button>
        </section>
      </div>
    </div>
  </ng-template>

  <ng-template #UploadContent let-modal>
    <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
    <div class="modal-width">
      <div>
        <div class="modal-header">
          <h5 class="modal-title" id="modal-basic-title">{{'FILE_UPLOAD' | translate}} </h5>
        </div>
        <div class="modal-body">
          <input type="file" class="file-uploader" id="file" (change)="selectFiles($event)" />
        </div>
        <div class="modal-footer">
          <button [disabled]="!fileForUpload" (click)="uploadExcelFile()" mat-raised-button class="button-submit">{{
            'UPLOAD_FILE' | translate }}</button>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #saveGeneralConfigContent let-modal>
    <div>
      <div class="close-btn"><img class="accordion-icon info-image" [src]="infoImage" /></div>
      <div class="popup-body">
        <div class="popup-content">
          <p class="info-message">{{ 'CURRENT_VERSION_OF_PROPOSAL' | translate }} <span class="info-status">{{status | translate}}</span> {{'STATUS' | translate}} {{'SAVE_GENERAL_CONFIG_TO_CREATE_NEW_VERSION' | translate}}</p>          
          <div class="info-ok-button">
            <button mat-raised-button (click)="onClose()" [mat-dialog-close]="true" class="manage-action-btn btn-background">
              {{ 'OK' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</section>
