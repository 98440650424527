/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class BillingInvoiceControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation genarateBillingPdf
   */
  static readonly GenarateBillingPdfPath = '/api/v1/profiles';

  /**
   * Upload Billing Pdf.
   *
   * The API uploads billing pdf to azure storage account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `genarateBillingPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  genarateBillingPdf$Response(params: {
    action: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BillingInvoiceControllerService.GenarateBillingPdfPath, 'post');
    if (params) {
      rb.query('action', params.action, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Upload Billing Pdf.
   *
   * The API uploads billing pdf to azure storage account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `genarateBillingPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  genarateBillingPdf(params: {
    action: string;
  }): Observable<string> {

    return this.genarateBillingPdf$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation billingDetails
   */
  static readonly BillingDetailsPath = '/api/v1/profile/{profileid}/invoice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `billingDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetails$Response(params: {
    profileid: string;
    year: number;
    month?: number;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BillingInvoiceControllerService.BillingDetailsPath, 'post');
    if (params) {
      rb.path('profileid', params.profileid, {});
      rb.query('year', params.year, {});
      rb.query('month', params.month, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `billingDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  billingDetails(params: {
    profileid: string;
    year: number;
    month?: number;
  }): Observable<string> {

    return this.billingDetails$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
