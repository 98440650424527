import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { KeycloakService } from 'keycloak-angular';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authReq = req.clone({
      headers: req.headers.set('realmName', 'master'),
    });
    return next.handle(authReq);
  }
}
@Injectable()
export class AuthInterceptorIdToken implements HttpInterceptor {
  constructor(private keycloak: KeycloakService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const instance = this.keycloak.getKeycloakInstance();
    if (instance) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + instance.idToken),
      });
      return next.handle(authReq);
     }
  }
}
