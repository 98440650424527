import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { APPROVE_ICON_NO_FILL, DOWNLOAD_ICON, EDIT_IMAGE, PDF_ICON, PROPOSAL_ICON_NO_FILL,
          REJECT_ICON_NO_FILL, VIEW_ICON} from 'src/app/shared/constants/strings';
import { ProposalControllerService } from 'src/app/services/Billing/services';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { MatPaginator } from '@angular/material/paginator';
import { DatePipe } from '@angular/common';
import { ShowHistoryDailogComponent } from 'src/app/shared/show-history-dialog/show-history-dialog.component';
import { DataSharingService } from 'src/app/providers/data-sharing.service';
import { SearchComponentComponent } from 'src/app/shared/search-component/search-component.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BciLoaderService } from '@bci-web-core/core';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';

@Component({
  selector: 'app-proposals',
  templateUrl: './proposals.component.html',
  styleUrls: ['./proposals.component.scss']
})
export class ProposalsComponent implements OnInit, AfterViewInit {

  public proposalImage;
  public viewImage;
  public approveImage;
  public rejectImage;
  public editImage;
  public uploadImage;
  public downloadImage;
  public showSpinner :any;
  public jsonFileLoaded: string;
  public permissionList: Array<String> = [];
  public data: any[];
  public proposalsData: any;
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public proposalId: string;
  public proposalsTableData;
  public proposalsColumns;
  public proposalList;
  public proposalDescription: string;
  public proposalAction: string;
  public uploadedDocumentsList = [];
  public profileIdNotRequired = false;
  public getFilteredProposal = false;
  public pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  public pageSize: number = this.pageSizeOptions[0];
  public totalProposalsCount = 0;
  public ignoreList = ['editable', 'id', 'proposalId', 'profileId',  'approvedBy', 'approvedDt',
  'mssAckStatus' , 'mssAckErrorReason' , 'mssProposalRejectedReason' , 'tenantName',  'tenant', 'account'];
  public proposalDropdownList = [];
  public proposalSalesAdminDataSource;
  public proposalSalesAdminColumns;
  public proposalSalesAdminList;
  public proposalComments: string;
  public routeAccountId: string;
  public routeTenantId: string;
  public routeProfileId: string;
  public pdfImage;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(SearchComponentComponent) searchForm: SearchComponentComponent;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;

   constructor(private httpsService: HttpClient, private route: ActivatedRoute,
    private router: Router,    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,    private dataSharingService: DataSharingService,
    private businessProfileControllerService: BusinessProfileControllerService, private responseHandlerService: ResponseHandlerService,private httpService: HttpClient,
    private proposalControllerService: ProposalControllerService, private _matDialog: MatDialog, private datePipe: DatePipe,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.downloadImage = DOWNLOAD_ICON;
    this.proposalImage = PROPOSAL_ICON_NO_FILL;
    this.viewImage = VIEW_ICON;
    this.approveImage = APPROVE_ICON_NO_FILL;
    this.rejectImage = REJECT_ICON_NO_FILL;
    this.editImage = EDIT_IMAGE;
    this.pdfImage = PDF_ICON;
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    if (this.permissionList.indexOf('PROPSL_R') > -1 && this.permissionList.indexOf('PROPSL_APR_REJ') > -1) {
      this.getAllProposals();
      this.httpsService
        .get('./assets/form-fields/proposals-manage.json')
        .subscribe((data) => {
          this.data = data as string[];
          this.proposalsData = this.data['fields'];
        });
    } else if(this.permissionList.indexOf('PROPSL_R') > -1 && !(this.permissionList.indexOf('PROPSL_APR_REJ') > -1)) {
      this.loadProposalDropDowns();
    }
    this.getAccount();
  }

  ngAfterViewInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.proposal) {
        const data = JSON.parse(params.proposal);
        this.accountId = data['accountId'];
        this.tenantId = data['tenantId'];
        this.profileId = data['profileId'];
          this.getTenant(this.accountId);
          this.getProfile(this.tenantId);
          this.waitForDynamicForm().then((dynamicForm) => {
            this.dynamicForm.createForm.patchValue({account:data['accountId'], tenant: data['tenantId'], businessProfile: data['profileId']});
            this.getProposalsSalesAdmin();
          });
      }
    });  
  }

  public waitForDynamicForm() {
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        if (this.dynamicForm?.createForm) {
          clearInterval(intervalId);
          resolve(this.dynamicForm.createForm);
        }
      }, 100);
    });
  }


  public updateDropdownValues($event) {
    if ($event.value !== undefined && $event.child.child !== undefined ) {
      if ( $event.child.child === 'tenant') {
        this.getTenant($event.value);
        this.accountId = $event.value;
      }
      if ($event.child.child === 'businessProfile') {
        this.getProfile($event.value);
        this.tenantId = $event.value;
      }
      if ($event.child.child === 'generate') {
        this.profileId = $event.value;
      }
   }
  }


  public getAllProposals(proposalInputData = {}) {
    this.getFilteredProposal = false;
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService.getAllProposalsForApproval(proposalInputData).subscribe( (resp) => {
      if (resp && resp['proposalDetailsDtoList'].length) {
        this.totalProposalsCount = resp['count'];
        this.proposalList = resp['proposalDetailsDtoList'];
        this.proposalList.forEach(proposal => {
          proposal['createdDt'] = this.datePipe.transform(proposal['createdDt'], 'd-M-yyyy h:mm a');
          proposal['action'] = null;
        });
        this.loaderService.hideProgressBar(this.showSpinner);
        this.proposalsTableData = new MatTableDataSource<any>(this.proposalList);
        this.proposalsColumns = this.filterColumns(Object.keys(this.proposalList[0]));
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.proposalsTableData = null;
        this.proposalsColumns = null;
        this.responseHandlerService.returnToastMessage(
          'error',
          'NO_DATA_AVAILABLE'
        );
      }
    },
    err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.proposalsTableData = null;
      this.proposalsColumns = null;
      this.proposalList = null;
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getProposalList() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService.getAllProposals({ profileId: this.profileId }).subscribe(resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.proposalList = resp;
        this.proposalList.forEach(proposal => {
          proposal['revenueType'] = proposal['revenueType'] && proposal['revenueType']['name'];
        });
        this.proposalsTableData = new MatTableDataSource<any>(this.proposalList);

        for (const data of this.proposalList) {
          data['action'] = null;
          data['createdDt'] = this.datePipe.transform(data['createdDt'], 'd-M-yyyy h:mm a');
        }
        this.proposalsColumns = this.filterColumns(Object.keys(this.proposalList[0]));
        if (resp.length < 10) {
          this.getFilteredProposal = true;
        }
      } else {
        this.getFilteredProposal = true;
        this.loaderService.hideProgressBar(this.showSpinner);
        this.proposalsTableData = null;
        this.proposalsColumns = null;
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    },
      err => {
        this.getFilteredProposal = true;
        this.loaderService.hideProgressBar(this.showSpinner);
        this.proposalsTableData = null;
        this.proposalsColumns = null;
        this.proposalList = null;
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  public gotoProposalApproveRejectDialog(tableRow, content, action) {
    this.proposalDescription = null;
    this.proposalAction = action;
    if (tableRow !== undefined) {
      if (tableRow['id']) {
        this.proposalId = tableRow['id'];
      }
      if (tableRow['proposalId']) {
        this.proposalId = tableRow['proposalId'];
        this.profileIdNotRequired = true;
      }
    }
    this._matDialog.open(content, { maxHeight: '80vh', minWidth: '30vw', disableClose: true, data: null });
  }


  public approveRejectProposal(tableRow) {
    if (!this.proposalDescription && this.proposalAction === 'REJECT') {
      this.responseHandlerService.returnToastMessage('error', 'REASON_IS_MANDATORY');
      return;
    }
    if (tableRow !== undefined) {
      if (tableRow['id']) {
        this.proposalId = tableRow['id'];
      }
      if (tableRow['proposalId']) {
        this.proposalId = tableRow['proposalId'];
      }
      }
    this.showSpinner = this.loaderService.showProgressBar();
    const proposalStatus = this.proposalAction === 'APPROVE';
    const description = this.proposalDescription;
    const bodyJSON = { 'proposalId': this.proposalId, 'status': proposalStatus, 'description': description };
    this.onClose();
    this.proposalControllerService.approveRejectProposals({ body: bodyJSON })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage
          ('success', proposalStatus ? 'PROPOSAL_APPROVE_SUCCESS' : 'PROPOSAL_REJECT_SUCCESS');
          if (this.profileId) {
            this.getProposalList();
          } else {
            if (this.profileIdNotRequired) {
             this.getAllProposals();
             this.profileIdNotRequired = false;
            }
          }
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.profileIdNotRequired = false;
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public displayUploadedDocuments(proposalId) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService.getProposalDocuments({ proposalId: proposalId }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      if (resp) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.uploadedDocumentsList = [...resp];
        for (const data of this.uploadedDocumentsList) {
          data['uploadedOn'] = this.datePipe.transform(data['createdDate'], 'd-M-yyyy h:mm a');
          data['uploadedBy'] = data['createdBy'];
          data['action'] = null;
          delete data['createdDate'];
          delete data['createdBy'];
        }
        const dialogRef = this._matDialog.open(ShowHistoryDailogComponent, {
          maxWidth: '100%',
          data: {
            title: 'UPLOADED_DOCUMENTS',
            dataKey: this.uploadedDocumentsList,
          }
        });
        dialogRef.componentInstance.deleteItemEmitter.subscribe(
          response => {
            this.proposalControllerService.deleteProposalDocuments({proposalId: response['proposalId'], id: response['id']})
            .subscribe( apiresponse => {
              this.responseHandlerService.returnToastMessage('success', 'PROPOSAL_DOCUMENT_DELETED_SUCCESSFULLY');
              dialogRef.close();
            },
            err => {
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            });
          });
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public displayProposalDetails(data) {
    if(data['id']) {
      this.router.navigate(['billing/proposal-details',{ proposalId :data['id'],accountId:this.accountId,tenantId:this.tenantId,profileId:this.profileId}]);
    } else if(data['proposalId']) {
      this.router.navigate(['billing/proposal-details',{ proposalId :data['proposalId']}]);
    }
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  public openModal(content, proposalId) {
    this._matDialog.open(content);
    this.proposalId = proposalId;
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  public pageEvent(event) {
   const proposalData = {};
   proposalData['page'] = event.pageIndex;
   proposalData['limit'] = event.pageSize;
   this.getAllProposals(proposalData);
  }

  public onSubmit() {
    if (this.profileId) {
      this.getProposalList();
    }
  }

  public onReset() {
    this.proposalsTableData = null;
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.dropDownValues['businessProfile'] = [];
    this.accountId = null;
    this.tenantId = null;
    this.profileId = null;
    this.getAccount();
    this.getAllProposals();
  }


  //Sales Admin

  public loadProposalDropDowns() {
      this.httpService
        .get('./assets/form-fields/proposals.json')
        .subscribe((data) => {
          this.proposalDropdownList = data as string[];
          this.proposalDropdownList = this.proposalDropdownList['fields'];
        });
      this.getAccount();
  }

  public updateDropdownValuesSalesAdmin($event) {
    if ($event.value !== undefined) {
      if ($event.child === 'tenant') {
        this.accountId = $event.value;
        this.getTenant($event.value);
      }
      if ($event.child === 'businessProfile') {
        this.tenantId = $event.value;
        this.getProfile($event.value);
      }
      if ($event.child === 'proposals') {
        this.profileId = $event.value;
      }     
      }
    
  }

  public getProposalsSalesAdmin() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService
      .getAllProposals({ profileId: this.profileId })
      .subscribe(
        (resp) => {
          this.routeAccountId = this.accountId;
          this.routeTenantId = this.tenantId;
          this.routeProfileId = this.profileId;
          if (resp && resp['length']) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.proposalSalesAdminList = resp;
            this.proposalSalesAdminList.forEach((proposal) => {
              proposal['revenueType'] =
                proposal['revenueType'] && proposal['revenueType']['name'];
            });
            this.proposalSalesAdminDataSource = new MatTableDataSource<any>(this.proposalSalesAdminList);
            for (const data of this.proposalSalesAdminList) {
              data['createdDt'] = this.datePipe.transform(data['createdDt'], 'd-M-yyyy h:mm a');
              data['approvedDt'] = this.datePipe.transform(data['approvedDt'], 'd-M-yyyy h:mm a');
              data['action'] = null;
            }
            this.proposalSalesAdminColumns = this.filterColumns(
              Object.keys(this.proposalSalesAdminList[0])
            );
          } else {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.proposalSalesAdminList = [];
            this.responseHandlerService.returnToastMessage(
              'warning',
              'NO_DATA_AVAILABLE'
            );
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
          this.proposalSalesAdminList = null;
        }
      );
  }

  public editProposal(data) {
   const proposal = { accountId:this.routeAccountId, tenantId:this.routeTenantId,profileId:this.routeProfileId,id: data['id'], version: data['version'], status : data['status'], editable : data['editable'], revenueType:data['revenueType'] };
    this.router.navigate(['billing/create-proposal'], { queryParams: { proposal: JSON.stringify(proposal) } });
  }

  public gotoSubmitProposalDialog(content,proposalId = this.proposalId) {
    if(proposalId) {
      this.proposalId = proposalId;
    }
    this._matDialog.open(content, {
      maxHeight: '80vh',
      minWidth: '30vw',
      disableClose: true,
      data: null,
    });
  }


  public submitProposal() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService
      .submitRecallProposal({
        body: {
          proposalId: this.proposalId,
          status: true,
          comments: this.proposalComments,
        },
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'PROPOSAL_SUBMIT_SUCCESS'
          );
          if (this.profileId) {
            this.getProposalsSalesAdmin();
          }
          this.proposalComments = '';
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    this.onClose();
  }

  public recallProposal(proposalId: string = null) {
    if (proposalId) {
      this.proposalId = proposalId;
    }
    this.showSpinner = this.loaderService.showProgressBar();
    this.proposalControllerService
      .submitRecallProposal({
        body: { proposalId: this.proposalId, status: false },
      })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'PROPOSAL_RECALL_SUCCESS'
          );
          if (this.profileId) {
            this.getProposalsSalesAdmin();
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  
  public onResetSalesAdmin() {
    this.proposalSalesAdminList = null;
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.dropDownValues['businessProfile'] = [];
    this.dynamicForm.createForm.reset();
    this.accountId = null;
    this.tenantId = null;
    this.profileId = null;
    this.getAccount();
  }

}
