/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessKeyDto } from '../models/access-key-dto';
import { CommonDetails } from '../models/common-details';
import { ServiceMasterDto } from '../models/service-master-dto';

@Injectable({
  providedIn: 'root',
})
export class AccesskeyLifeCycleControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getServiceListWithAccessKeyFlag
   */
  static readonly GetServiceListWithAccessKeyFlagPath = '/api/v1/accessKey/{accessKeyId}/serviceListWithAccessKeyFlags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getServiceListWithAccessKeyFlag()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceListWithAccessKeyFlag$Response(params: {
    accessKeyId: string;
    type?: string;
  }): Observable<StrictHttpResponse<Array<ServiceMasterDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.GetServiceListWithAccessKeyFlagPath, 'get');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
      rb.query('type', params.type, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ServiceMasterDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getServiceListWithAccessKeyFlag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getServiceListWithAccessKeyFlag(params: {
    accessKeyId: string;
    type?: string;
  }): Observable<Array<ServiceMasterDto>> {

    return this.getServiceListWithAccessKeyFlag$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ServiceMasterDto>>) => r.body as Array<ServiceMasterDto>)
    );
  }

  /**
   * Path part for operation getAccessKeyDetails1
   */
  static readonly GetAccessKeyDetails1Path = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/accessKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessKeyDetails1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyDetails1$Response(params: {
    profileId: string;
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<Array<AccessKeyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.GetAccessKeyDetails1Path, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessKeyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessKeyDetails1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyDetails1(params: {
    profileId: string;
    solutionTypeId: string;
  }): Observable<Array<AccessKeyDto>> {

    return this.getAccessKeyDetails1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AccessKeyDto>>) => r.body as Array<AccessKeyDto>)
    );
  }

  /**
   * Path part for operation createAccessKeyForBusinessProfile
   */
  static readonly CreateAccessKeyForBusinessProfilePath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/accessKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccessKeyForBusinessProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  createAccessKeyForBusinessProfile$Response(params: {
    profileId: string;
    solutionTypeId: string;
    expiryDuration: string;
    keyName: string;
  }): Observable<StrictHttpResponse<{  }>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.CreateAccessKeyForBusinessProfilePath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.query('expiryDuration', params.expiryDuration, {});
      rb.query('keyName', params.keyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAccessKeyForBusinessProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createAccessKeyForBusinessProfile(params: {
    profileId: string;
    solutionTypeId: string;
    expiryDuration: string;
    keyName: string;
  }): Observable<{  }> {

    return this.createAccessKeyForBusinessProfile$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation deleteAccessKeyForBusinessProfile
   */
  static readonly DeleteAccessKeyForBusinessProfilePath = '/api/v1/accessKey/{accessKeyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAccessKeyForBusinessProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccessKeyForBusinessProfile$Response(params: {
    accessKeyId: string;
  }): Observable<StrictHttpResponse<{  }>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.DeleteAccessKeyForBusinessProfilePath, 'delete');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAccessKeyForBusinessProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccessKeyForBusinessProfile(params: {
    accessKeyId: string;
  }): Observable<{  }> {

    return this.deleteAccessKeyForBusinessProfile$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation regenerateAccessKeyForBusinessProfile
   */
  static readonly RegenerateAccessKeyForBusinessProfilePath = '/api/v1/accessKey/{accessKeyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `regenerateAccessKeyForBusinessProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAccessKeyForBusinessProfile$Response(params: {
    accessKeyId: string;
    expiryDuration?: string;
    action: string;
    keyName?: string;
  }): Observable<StrictHttpResponse<{  }>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.RegenerateAccessKeyForBusinessProfilePath, 'patch');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
      rb.query('expiryDuration', params.expiryDuration, {});
      rb.query('action', params.action, {});
      rb.query('keyName', params.keyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `regenerateAccessKeyForBusinessProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAccessKeyForBusinessProfile(params: {
    accessKeyId: string;
    expiryDuration?: string;
    action: string;
    keyName?: string;
  }): Observable<{  }> {

    return this.regenerateAccessKeyForBusinessProfile$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation subscribeServiceForProfile
   */
  static readonly SubscribeServiceForProfilePath = '/api/v1/accessKey/{accessKeyId}/service/{serviceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscribeServiceForProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeServiceForProfile$Response(params: {
    accessKeyId: string;
    serviceId: string;
  }): Observable<StrictHttpResponse<{  }>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.SubscribeServiceForProfilePath, 'post');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
      rb.path('serviceId', params.serviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscribeServiceForProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeServiceForProfile(params: {
    accessKeyId: string;
    serviceId: string;
  }): Observable<{  }> {

    return this.subscribeServiceForProfile$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation unsubscribeServiceForProfile
   */
  static readonly UnsubscribeServiceForProfilePath = '/api/v1/accessKey/{accessKeyId}/service/{serviceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unsubscribeServiceForProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsubscribeServiceForProfile$Response(params: {
    accessKeyId: string;
    serviceId: string;
  }): Observable<StrictHttpResponse<{  }>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.UnsubscribeServiceForProfilePath, 'delete');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
      rb.path('serviceId', params.serviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unsubscribeServiceForProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsubscribeServiceForProfile(params: {
    accessKeyId: string;
    serviceId: string;
  }): Observable<{  }> {

    return this.unsubscribeServiceForProfile$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation checkForExpiryOfAccessKeys
   */
  static readonly CheckForExpiryOfAccessKeysPath = '/api/v1/checkForExpiredAccessKeys';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkForExpiryOfAccessKeys()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkForExpiryOfAccessKeys$Response(params?: {
  }): Observable<StrictHttpResponse<{  }>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.CheckForExpiryOfAccessKeysPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkForExpiryOfAccessKeys$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkForExpiryOfAccessKeys(params?: {
  }): Observable<{  }> {

    return this.checkForExpiryOfAccessKeys$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation getPossibleValuesForAccessKeyExpiry
   */
  static readonly GetPossibleValuesForAccessKeyExpiryPath = '/api/v1/accessKeyExpiryValues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPossibleValuesForAccessKeyExpiry()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossibleValuesForAccessKeyExpiry$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.GetPossibleValuesForAccessKeyExpiryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getPossibleValuesForAccessKeyExpiry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPossibleValuesForAccessKeyExpiry(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.getPossibleValuesForAccessKeyExpiry$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation subscribeManagerialServicesForProfile
   */
  static readonly SubscribeManagerialServicesForProfilePath = '/api/v1/accessKey/{accessKeyId}/managerialServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscribeManagerialServicesForProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeManagerialServicesForProfile$Response(params: {
    accessKeyId: string;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.SubscribeManagerialServicesForProfilePath, 'post');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscribeManagerialServicesForProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeManagerialServicesForProfile(params: {
    accessKeyId: string;
  }): Observable<{
}> {

    return this.subscribeManagerialServicesForProfile$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation unsubscribeManagerialServicesForProfile
   */
  static readonly UnsubscribeManagerialServicesForProfilePath = '/api/v1/accessKey/{accessKeyId}/managerialServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unsubscribeManagerialServicesForProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsubscribeManagerialServicesForProfile$Response(params: {
    accessKeyId: string;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.UnsubscribeManagerialServicesForProfilePath, 'delete');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unsubscribeManagerialServicesForProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsubscribeManagerialServicesForProfile(params: {
    accessKeyId: string;
  }): Observable<{
}> {

    return this.unsubscribeManagerialServicesForProfile$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation configureExpiryEmailIds
   */
  static readonly ConfigureExpiryEmailIdsPath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/accessKey/emails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `configureExpiryEmailIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
  */
  configureExpiryEmailIds$Response(params: {
    profileId: string;
    solutionTypeId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<{  }>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.ConfigureExpiryEmailIdsPath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `configureExpiryEmailIds$Response()` instead.
  *
  * This method sends `application/json` and handles request body of type `application/json`.
  */
  configureExpiryEmailIds(params: {
    profileId: string;
    solutionTypeId: string;
    body?: Array<string>
  }): Observable<{
}> {

    return this.configureExpiryEmailIds$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation getAccessKeyAndSolutionIdForBusinessProfileSolutionName
   */
  static readonly GetAccessKeyAndSolutionIdForBusinessProfileSolutionNamePath = '/api/v1/businessProfile/{profileId}/solutiontypeName/{solutionName}/accessKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessKeyAndSolutionIdForBusinessProfileSolutionName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyAndSolutionIdForBusinessProfileSolutionName$Response(params: {
    profileId: string;
    solutionName: string;
  }): Observable<StrictHttpResponse<{ [key: string]: string }>> {

    const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.GetAccessKeyAndSolutionIdForBusinessProfileSolutionNamePath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionName', params.solutionName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: string }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessKeyAndSolutionIdForBusinessProfileSolutionName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyAndSolutionIdForBusinessProfileSolutionName(params: {
    profileId: string;
    solutionName: string;
  }): Observable<{ [key: string]: string }> {

    return this.getAccessKeyAndSolutionIdForBusinessProfileSolutionName$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: string }>) => r.body as { [key: string]: string })
    );
  }

  /**
   * Path part for operation getTenantAdminExpiryEmailIds
   */
   static readonly GetTenantAdminExpiryEmailIdsPath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/accessKey/emails';

   /**
    * This method provides access to the full `HttpResponse`, allowing access to response headers.
    * To access only the response body, use `getTenantAdminExpiryEmailIds()` instead.
    *
    * This method doesn't expect any request body.
    */
   getTenantAdminExpiryEmailIds$Response(params: {
     profileId: string;
     solutionTypeId: string;
   }): Observable<StrictHttpResponse<Array<string>>> {
 
     const rb = new RequestBuilder(this.rootUrl, AccesskeyLifeCycleControllerService.GetTenantAdminExpiryEmailIdsPath, 'get');
     if (params) {
       rb.path('profileId', params.profileId, {});
       rb.path('solutionTypeId', params.solutionTypeId, {});
     }
 
     return this.http.request(rb.build({
       responseType: 'json',
       accept: 'application/json'
     })).pipe(
       filter((r: any) => r instanceof HttpResponse),
       map((r: HttpResponse<any>) => {
         return r as StrictHttpResponse<Array<string>>;
       })
     );
   }
 
   /**
    * This method provides access to only to the response body.
    * To access the full response (for headers, for example), `getTenantAdminExpiryEmailIds$Response()` instead.
    *
    * This method doesn't expect any request body.
    */
   getTenantAdminExpiryEmailIds(params: {
     profileId: string;
     solutionTypeId: string;
   }): Observable<Array<string>> {
 
     return this.getTenantAdminExpiryEmailIds$Response(params).pipe(
       map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
     );
   }

}
