<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <span class="heading-label">
          <img class="page-icon-small" [src]="registerSimIcon" />
          <span>{{'REGISTER_SIM' | translate}}</span>
        </span>
      </div>
      <mat-tab-group (selectedTabChange)="matChange($event)"  [(selectedIndex)]="activeTabIndex">
        <mat-tab [label]="tab.label" *ngFor="let tab of matTabOptions">
        </mat-tab>
      </mat-tab-group>  
      <div *ngIf="!formData" class="create-form-spacing">
          <app-dynamic-form [jsonFile]="jsonFileLoaded" [dropDownValues]="dropDownValues"
          (valueSelected)="updateDropdownValues($event)" [columnsInForm]="'multi'" [gridLayout]="'grid-container-columns'"
          (formEmitter)="registerSim($event)" (resetForm)="resetForm($event)" (checkboxChangeEvent)="manageProfileCount($event)">
        </app-dynamic-form>
      </div>
      <div *ngIf="formData" class="create-form-spacing">
        <app-dynamic-form [jsonFile]="jsonFileLoaded" [formData]="formData" [dropDownValues]="dropDownValues"
        (valueSelected)="updateDropdownValues($event)" [columnsInForm]="'multi'" [gridLayout]="'grid-container-columns'"
        (formEmitter)="registerSim($event)" (resetForm)="resetForm($event)" (checkboxChangeEvent)="manageProfileCount($event)">
      </app-dynamic-form>
    </div>
    </section>
    <section *ngIf="asideMenus">
      <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
    </section>
  </section>
  