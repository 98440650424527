import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SmsRoutingModule } from './sms-routing.module';
import { SmsConfigurationComponent } from './sms-configuration/sms-configuration.component';
import { SharedModule } from '../shared/shared.module';
import { BciCoreModule } from '@bci-web-core/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { SmsBulkUploadComponent } from './sms-bulk-upload/sms-bulk-upload.component';
import { SmsStatusComponent } from './sms-status/sms-status.component';
import { SmsJobDetailsComponent } from './sms-job-details/sms-job-details.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [SmsConfigurationComponent, SmsBulkUploadComponent, SmsStatusComponent, SmsJobDetailsComponent],
  imports: [
    CommonModule,
    SmsRoutingModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    BciCoreModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatExpansionModule,
    MatCardModule,
    MatSlideToggleModule
  ]
})
export class SmsModule { }
