<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="back-image" (click)="navigateBack()" [src]="backLeftImage" />
        <img class="page-icon" [src]="accessKeyIcon" />
        <span>{{'LIST_KEYS' |translate}}</span>
      </span>
    </div>
    <div class="page-container-margin">
      <div class="request-field">
        <mat-form-field class="number-key">
        <mat-label class="label">{{'NUMBER_OF_KEYS' | translate}}
          <span class="mandatory">*</span>
        </mat-label>
        <input  type="number" matInput [(ngModel)]="numberOfKeys" required>
        </mat-form-field>
        <div class="submit-section">
        <button mat-raised-button class="btn-background"
          (click)="requestMoreKeys()">
          {{'REQUEST_FOR_MORE_KEYS' | translate}}
        </button>
        <button  mat-button class="button" (click)="requestRestrictionType()">
          {{'REQUEST_RESTRICTION_TYPE' | translate}}
        </button>
        </div>
      </div>
    </div>
      <mat-table [dataSource]="dataSource" class="bottom-space table-data" *ngIf="mapKeysData">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef [ngClass]="determineHeaderSize(column)">{{column | appendSpace | titlecase}} </mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName" [ngClass]="determineColumnSize(column)">
              <span *ngIf="column ==='apiKey'" class="cell-value">
                <mat-form-field class="api-key-input" >
                <input  matInput [type]="showKey?'text':'password'" [value]="dataName[column]" #mapKey  />
              </mat-form-field>
                <img [src]="showKey?hideKeyImage:showKeyImage" class="map-key-icons"
                  [title]="(showKey[dataName['id']]? 'HIDE_KEY': 'SHOW_KEY') |translate" (click)="showMapKey(dataName['id'])" />
                <img [src]="copyImage" class="map-key-icons" [title]="'COPY' |translate" (click)="copyText(mapKey)"
                  [class.disabled]="!showKey" />
                <img [src]="refreshImage" class="map-key-icons" [title]="'REGENERATE' |translate"
                  (click)="deleteKey(dataName['id'])" />
              </span>
              <span *ngIf="column !=='apiKey'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
            </mat-cell>
          </ng-container>

        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
  </section>