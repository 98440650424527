/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AwsIoTHubDto } from '../models/aws-io-t-hub-dto';

@Injectable({
  providedIn: 'root',
})
export class AwsIoTHubControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findByAwsIoTHubId
   */
  static readonly FindByAwsIoTHubIdPath = '/api/v1/awsiothub/{id}';

  /**
   * GET specific AwsIoTHub.
   *
   * The api fetches the specific AwsIoTHub for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByAwsIoTHubId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAwsIoTHubId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<AwsIoTHubDto>> {

    const rb = new RequestBuilder(this.rootUrl, AwsIoTHubControllerService.FindByAwsIoTHubIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AwsIoTHubDto>;
      })
    );
  }

  /**
   * GET specific AwsIoTHub.
   *
   * The api fetches the specific AwsIoTHub for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByAwsIoTHubId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAwsIoTHubId(params: {
    id: string;
  }): Observable<AwsIoTHubDto> {

    return this.findByAwsIoTHubId$Response(params).pipe(
      map((r: StrictHttpResponse<AwsIoTHubDto>) => r.body as AwsIoTHubDto)
    );
  }

  /**
   * Path part for operation updateAwsIoTHub
   */
  static readonly UpdateAwsIoTHubPath = '/api/v1/awsiothub/{id}';

  /**
   * Update AwsIoTHub.
   *
   * The api updates the specific AwsIoTHub for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAwsIoTHub()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAwsIoTHub$Response(params: {
    id: string;
    body?: AwsIoTHubDto
  }): Observable<StrictHttpResponse<AwsIoTHubDto>> {

    const rb = new RequestBuilder(this.rootUrl, AwsIoTHubControllerService.UpdateAwsIoTHubPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AwsIoTHubDto>;
      })
    );
  }

  /**
   * Update AwsIoTHub.
   *
   * The api updates the specific AwsIoTHub for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAwsIoTHub$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAwsIoTHub(params: {
    id: string;
    body?: AwsIoTHubDto
  }): Observable<AwsIoTHubDto> {

    return this.updateAwsIoTHub$Response(params).pipe(
      map((r: StrictHttpResponse<AwsIoTHubDto>) => r.body as AwsIoTHubDto)
    );
  }

  /**
   * Path part for operation activateAwsIoTHub
   */
  static readonly ActivateAwsIoTHubPath = '/api/v1/awsiothub/{id}/activate';

  /**
   * Activate AwsIoTHub.
   *
   * The api activates the specific AwsIoTHub for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateAwsIoTHub()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateAwsIoTHub$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AwsIoTHubControllerService.ActivateAwsIoTHubPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activate AwsIoTHub.
   *
   * The api activates the specific AwsIoTHub for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateAwsIoTHub$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateAwsIoTHub(params: {
    id: string;
  }): Observable<void> {

    return this.activateAwsIoTHub$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateAwsIoTHub
   */
  static readonly DeactivateAwsIoTHubPath = '/api/v1/awsiothub/{id}/deactivate';

  /**
   * Deactivate IoTHub.
   *
   * The api deactivates the specific AwsIoTHub for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateAwsIoTHub()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateAwsIoTHub$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AwsIoTHubControllerService.DeactivateAwsIoTHubPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deactivate IoTHub.
   *
   * The api deactivates the specific AwsIoTHub for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateAwsIoTHub$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateAwsIoTHub(params: {
    id: string;
  }): Observable<void> {

    return this.deactivateAwsIoTHub$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation findAllAwsIoTHubs
   */
  static readonly FindAllAwsIoTHubsPath = '/api/v1/awsiothub';

  /**
   * GET All AwsIoTHub.
   *
   * The api fetches all the Available awsIotHub
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllAwsIoTHubs()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAwsIoTHubs$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<AwsIoTHubDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AwsIoTHubControllerService.FindAllAwsIoTHubsPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AwsIoTHubDto>>;
      })
    );
  }

  /**
   * GET All AwsIoTHub.
   *
   * The api fetches all the Available awsIotHub
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllAwsIoTHubs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAwsIoTHubs(params?: {
    active?: string;
  }): Observable<Array<AwsIoTHubDto>> {

    return this.findAllAwsIoTHubs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AwsIoTHubDto>>) => r.body as Array<AwsIoTHubDto>)
    );
  }

  /**
   * Path part for operation createAwsIoTHub
   */
  static readonly CreateAwsIoTHubPath = '/api/v1/awsiothub';

  /**
   * Create a new AwsIoTHub.
   *
   * The api creates a new AwsIoTHub
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAwsIoTHub()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAwsIoTHub$Response(params?: {
    body?: AwsIoTHubDto
  }): Observable<StrictHttpResponse<AwsIoTHubDto>> {

    const rb = new RequestBuilder(this.rootUrl, AwsIoTHubControllerService.CreateAwsIoTHubPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AwsIoTHubDto>;
      })
    );
  }

  /**
   * Create a new AwsIoTHub.
   *
   * The api creates a new AwsIoTHub
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAwsIoTHub$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAwsIoTHub(params?: {
    body?: AwsIoTHubDto
  }): Observable<AwsIoTHubDto> {

    return this.createAwsIoTHub$Response(params).pipe(
      map((r: StrictHttpResponse<AwsIoTHubDto>) => r.body as AwsIoTHubDto)
    );
  }

}
