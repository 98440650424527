/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CloudTypeDto } from '../models/cloud-type-dto';
import { CommonDetails } from '../models/common-details';
import { InfraServiceDto } from '../models/infra-service-dto';
import { ProposalInfraConfigDto } from '../models/proposal-infra-config-dto';

@Injectable({
  providedIn: 'root',
})
export class ProposalInfraConfigControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation saveProposalInfraConfigForApprovedPropsl
   */
  static readonly SaveProposalInfraConfigForApprovedPropslPath = '/api/v1/proposal/infraConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProposalInfraConfigForApprovedPropsl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalInfraConfigForApprovedPropsl$Response(params?: {
    body?: ProposalInfraConfigDto
  }): Observable<StrictHttpResponse<ProposalInfraConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalInfraConfigControllerService.SaveProposalInfraConfigForApprovedPropslPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalInfraConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveProposalInfraConfigForApprovedPropsl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalInfraConfigForApprovedPropsl(params?: {
    body?: ProposalInfraConfigDto
  }): Observable<ProposalInfraConfigDto> {

    return this.saveProposalInfraConfigForApprovedPropsl$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalInfraConfigDto>) => r.body as ProposalInfraConfigDto)
    );
  }

  /**
   * Path part for operation saveProposalInfraConfig
   */
  static readonly SaveProposalInfraConfigPath = '/api/v1/proposal/infraConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProposalInfraConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalInfraConfig$Response(params?: {
    body?: ProposalInfraConfigDto
  }): Observable<StrictHttpResponse<ProposalInfraConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalInfraConfigControllerService.SaveProposalInfraConfigPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalInfraConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveProposalInfraConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalInfraConfig(params?: {
    body?: ProposalInfraConfigDto
  }): Observable<ProposalInfraConfigDto> {

    return this.saveProposalInfraConfig$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalInfraConfigDto>) => r.body as ProposalInfraConfigDto)
    );
  }

  /**
   * Path part for operation getAllCloudTypes
   */
  static readonly GetAllCloudTypesPath = '/api/v1/cloudTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCloudTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCloudTypes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CloudTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalInfraConfigControllerService.GetAllCloudTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CloudTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCloudTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCloudTypes(params?: {
  }): Observable<Array<CloudTypeDto>> {

    return this.getAllCloudTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CloudTypeDto>>) => r.body as Array<CloudTypeDto>)
    );
  }

  /**
   * Path part for operation getAllCloudTypes1
   */
  static readonly GetAllCloudTypes1Path = '/api/v1/cloudtype/{cloudTypeId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCloudTypes1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCloudTypes1$Response(params: {
    cloudTypeId: string;
  }): Observable<StrictHttpResponse<CloudTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalInfraConfigControllerService.GetAllCloudTypes1Path, 'get');
    if (params) {
      rb.path('cloudTypeId', params.cloudTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CloudTypeDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCloudTypes1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCloudTypes1(params: {
    cloudTypeId: string;
  }): Observable<CloudTypeDto> {

    return this.getAllCloudTypes1$Response(params).pipe(
      map((r: StrictHttpResponse<CloudTypeDto>) => r.body as CloudTypeDto)
    );
  }

  /**
   * Path part for operation findAllEnvironmentTypes
   */
  static readonly FindAllEnvironmentTypesPath = '/api/v1/environmentTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllEnvironmentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllEnvironmentTypes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalInfraConfigControllerService.FindAllEnvironmentTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllEnvironmentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllEnvironmentTypes(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.findAllEnvironmentTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation getProposalInfraConfig
   */
  static readonly GetProposalInfraConfigPath = '/api/v1/proposal/{proposalId}/infraConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProposalInfraConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalInfraConfig$Response(params: {
    proposalId: string;
  }): Observable<StrictHttpResponse<ProposalInfraConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalInfraConfigControllerService.GetProposalInfraConfigPath, 'get');
    if (params) {
      rb.path('proposalId', params.proposalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalInfraConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProposalInfraConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalInfraConfig(params: {
    proposalId: string;
  }): Observable<ProposalInfraConfigDto> {

    return this.getProposalInfraConfig$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalInfraConfigDto>) => r.body as ProposalInfraConfigDto)
    );
  }

  /**
   * Path part for operation getAllInfraServices
   */
  static readonly GetAllInfraServicesPath = '/api/v1/infra-services';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllInfraServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInfraServices$Response(params?: {
  }): Observable<StrictHttpResponse<Array<InfraServiceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalInfraConfigControllerService.GetAllInfraServicesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InfraServiceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllInfraServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInfraServices(params?: {
  }): Observable<Array<InfraServiceDto>> {

    return this.getAllInfraServices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InfraServiceDto>>) => r.body as Array<InfraServiceDto>)
    );
  }

}
