<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="profileIcon" />
        <span>{{'PROFILE_MANAGEMENT_MANAGE' |translate}}</span>
      </span>
    </div>
    <!-- activation/de-activation/deletion of multiple profiles to be taken up in next phase -->
    <!-- <span>
      <button *ngIf="checkPermission('BP_U')" (click)="gotoModal(popup, null, 'activate')"
        mat-raised-button class="manage-action-btn">{{ 'ACTIVATE' | translate }}</button>
      <button *ngIf="checkPermission('BP_U')" (click)="gotoModal(popup, null, 'deactivate')"
        class="manage-action-btn" mat-raised-button>{{ 'DE-ACTIVATE' | translate }}</button>
      <button *ngIf="checkPermission('BP_DEL')" (click)="gotoModal(popup, null, 'delete')"
        mat-raised-button color="warn" class="manage-action-btn">{{ 'DELETE' | translate }}</button>
    </span> -->
    <mat-form-field class="select-box">
      <mat-label class="label">{{'SELECT_TENANT' | translate}}</mat-label>
      <mat-select [placeholder]=" selectedTenant" (selectionChange)="getProfilesByTenantId($event.value)">
        <mat-option *ngFor="let val of tenantValues" [value]="val['id']" required>{{val['name']}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="table-container">
      <mat-table [dataSource]="dataSource" class="table-data" *ngIf="profileData">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
            <mat-cell class="tooltip " *matCellDef="let dataName">
              <span *ngIf="column!=='action' && column!=='active'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column==='action'">
                <mat-slide-toggle [disabled]="!checkPermission('BP_ACT') && !checkPermission('BP_DACT')"
                  [(ngModel)]="dataName['active']" (change)="gotoConfirmDialog( dataName,'edit')">
                </mat-slide-toggle>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <img class="more-img" [src]="moreOptionsImage">
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item *ngIf="checkPermission('BP_U')" [class.disabled]="!dataName['active']"
                    (click)="gotoProfilePage(dataName)">
                    <img [src]="editImage" class="modify-button">
                    <span>{{ 'EDIT' | translate }}</span>
                  </button>
                  <button mat-menu-item *ngIf="checkPermission('BP_DEL')" [class.disabled]="!dataName['active']"
                    (click)="gotoConfirmDialog(dataName, 'delete')">
                    <img [src]="deleteImage" class="modify-button">
                    <span>{{ 'DELETE' | translate }}</span>
                  </button>
                  <button mat-menu-item *ngIf="checkPermission('BP_C')" [class.disabled]="!dataName['active']"
                    (click)="gotoConfirmDialog(dataName, 'configure')">
                    <img [src]="configureImage" class="modify-button">
                    <span>{{ 'CONFIGURE' | translate }}</span>
                  </button>
                </mat-menu>
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
    <!-- paginator to be implemented -->
    <!-- <mat-paginator [pageSizeOptions]="pageLimit" showFirstLastButtons></mat-paginator> -->
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
