/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeviceDynamicGraphDto } from '../models/device-dynamic-graph-dto';
import { DeviceGraphDto } from '../models/device-graph-dto';

@Injectable({
  providedIn: 'root',
})
export class DeviceGraphControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation deleteGraphs
   */
  static readonly DeleteGraphsPath = '/api/v1/deleteGraphs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteGraphs()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteGraphs$Response(params?: {
    body?: DeviceGraphDto
  }): Observable<StrictHttpResponse<DeviceGraphDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceGraphControllerService.DeleteGraphsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
         responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceGraphDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteGraphs$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteGraphs(params?: {
    body?: DeviceGraphDto
  }): Observable<DeviceGraphDto> {

    return this.deleteGraphs$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceGraphDto>) => r.body as DeviceGraphDto)
    );
  }

  /**
   * Path part for operation saveDynamicGraph
   */
  static readonly SaveDynamicGraphPath = '/api/v1/device/{deviceId}/graphDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveDynamicGraph()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDynamicGraph$Response(params: {
    deviceId: string;
    body?: Array<DeviceGraphDto>
  }): Observable<StrictHttpResponse<Array<DeviceGraphDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceGraphControllerService.SaveDynamicGraphPath, 'post');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
         responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceGraphDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveDynamicGraph$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveDynamicGraph(params: {
    deviceId: string;
    body?: Array<DeviceGraphDto>
  }): Observable<Array<DeviceGraphDto>> {

    return this.saveDynamicGraph$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceGraphDto>>) => r.body as Array<DeviceGraphDto>)
    );
  }

  /**
   * Path part for operation generateDynamicGraph
   */
  static readonly GenerateDynamicGraphPath = '/api/v1/device/{deviceId}/generateDynamicGraph';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateDynamicGraph()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateDynamicGraph$Response(params: {
    deviceId: string;
    interval?: number;
    body?: DeviceDynamicGraphDto
  }): Observable<StrictHttpResponse<DeviceDynamicGraphDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceGraphControllerService.GenerateDynamicGraphPath, 'post');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
      rb.query('interval', params.interval, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
         responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceDynamicGraphDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateDynamicGraph$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  generateDynamicGraph(params: {
    deviceId: string;
    interval?: number;
    body?: DeviceDynamicGraphDto
  }): Observable<DeviceDynamicGraphDto> {

    return this.generateDynamicGraph$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceDynamicGraphDto>) => r.body as DeviceDynamicGraphDto)
    );
  }

   /**
   * Path part for operation getExistingDynamicGraphs
   */
   static readonly GetExistingDynamicGraphsPath = '/api/v1/deviceGraphs';

   /**
    * This method provides access to the full `HttpResponse`, allowing access to response headers.
    * To access only the response body, use `getExistingDynamicGraphs()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   getExistingDynamicGraphs$Response(params?: {
     body?: DeviceGraphDto
   }): Observable<StrictHttpResponse<Array<DeviceGraphDto>>> {
 
     const rb = new RequestBuilder(this.rootUrl, DeviceGraphControllerService.GetExistingDynamicGraphsPath, 'post');
     if (params) {
       rb.body(params.body, 'application/json');
     }
 
     return this.http.request(rb.build({
       responseType: 'json',
       accept: 'application/json'
     })).pipe(
       filter((r: any) => r instanceof HttpResponse),
       map((r: HttpResponse<any>) => {
         return r as StrictHttpResponse<Array<DeviceGraphDto>>;
       })
     );
   }
 
   /**
    * This method provides access to only to the response body.
    * To access the full response (for headers, for example), `getExistingDynamicGraphs$Response()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   getExistingDynamicGraphs(params?: {
     body?: DeviceGraphDto
   }): Observable<Array<DeviceGraphDto>> {
 
     return this.getExistingDynamicGraphs$Response(params).pipe(
       map((r: StrictHttpResponse<Array<DeviceGraphDto>>) => r.body as Array<DeviceGraphDto>)
     );
   }
 
   /**
    * Path part for operation generateExistingDynamicGraph
    */
   static readonly GenerateExistingDynamicGraphPath = '/api/v1/device/{deviceId}/generateExistingGraph';
 
   /**
    * This method provides access to the full `HttpResponse`, allowing access to response headers.
    * To access only the response body, use `generateExistingDynamicGraph()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   generateExistingDynamicGraph$Response(params: {
     deviceId: string;
     interval?: number;
     body?: DeviceDynamicGraphDto
   }): Observable<StrictHttpResponse<DeviceDynamicGraphDto>> {
 
     const rb = new RequestBuilder(this.rootUrl, DeviceGraphControllerService.GenerateExistingDynamicGraphPath, 'post');
     if (params) {
       rb.path('deviceId', params.deviceId, {});
       rb.query('interval', params.interval, {});
       rb.body(params.body, 'application/json');
     }
 
     return this.http.request(rb.build({
       responseType: 'json',
       accept: 'application/json'
     })).pipe(
       filter((r: any) => r instanceof HttpResponse),
       map((r: HttpResponse<any>) => {
         return r as StrictHttpResponse<DeviceDynamicGraphDto>;
       })
     );
   }
 
   /**
    * This method provides access to only to the response body.
    * To access the full response (for headers, for example), `generateExistingDynamicGraph$Response()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   generateExistingDynamicGraph(params: {
     deviceId: string;
     interval?: number;
     body?: DeviceDynamicGraphDto
   }): Observable<DeviceDynamicGraphDto> {
 
     return this.generateExistingDynamicGraph$Response(params).pipe(
       map((r: StrictHttpResponse<DeviceDynamicGraphDto>) => r.body as DeviceDynamicGraphDto)
     );
   }
}
