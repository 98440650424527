import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class checkboxAsRadioGroupService {
  formGroup: any;
  unselectCheckBoxValue: any;
  constructor() {}
  public getCheckBoxVal(dropDownValues, formGroup, value) {
    if(formGroup.controls.deviceProperties.value.includes(value.id) == false){
      return null;
    }
    this.formGroup = formGroup;
    let noSim, singleSim,dualSim;
    dropDownValues['deviceProperties'].map((element) => {
      element.name == 'SINGLE_SIM_SUPPORT'
        ? (singleSim = element.id)
        : element.name == 'NO_SIM_SUPPORT'
        ? (noSim = element.id)
        : element.name == 'DUAL_SIM_SUPPORT'
        ? (dualSim = element.id)
        : null;
    });

    if (value.id == singleSim) {
      this.unselectCheckBox(noSim);
      this.unselectCheckBox(dualSim);
      return this.unselectCheckBoxValue;
    }
    if (value.id == noSim) {
      this.unselectCheckBox(singleSim);
      this.unselectCheckBox(dualSim);
      return this.unselectCheckBoxValue;
    }
    if (value.id ==dualSim) {
      this.unselectCheckBox(noSim);
      this.unselectCheckBox(singleSim);
      return this.unselectCheckBoxValue;
    }
  }
  public unselectCheckBox(checkboxId) {
    const index =
      this.formGroup.controls.deviceProperties.value.indexOf(checkboxId);
    if (index > -1) {
      this.unselectCheckBoxValue =
        this.formGroup.controls.deviceProperties.value.slice();
      this.unselectCheckBoxValue.splice(index, 1);
    }
  }
}
