/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JobStatusDto } from '../models/job-status-dto';
import { ProcedureDto } from '../models/procedure-dto';
import { ProcedureListDto } from '../models/procedure-list-dto';
import { SchedulerDto } from '../models/scheduler-dto';

@Injectable({
  providedIn: 'root',
})
export class ProcedureControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation parentProcs
   */
  static readonly ParentProcsPath = '/api/v1/procedures/{procedureId}/parentProcs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parentProcs()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentProcs$Response(params: {
    procedureId: string;

  }): Observable<StrictHttpResponse<Array<ProcedureDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProcedureControllerService.ParentProcsPath, 'get');
    if (params) {

      rb.path('procedureId', params.procedureId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProcedureDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parentProcs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parentProcs(params: {
    procedureId: string;

  }): Observable<Array<ProcedureDto>> {

    return this.parentProcs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProcedureDto>>) => r.body as Array<ProcedureDto>)
    );
  }

  /**
   * Path part for operation procedures
   */
  static readonly ProceduresPath = '/api/v1/procedures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `procedures()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedures$Response(params?: {

  }): Observable<StrictHttpResponse<Array<ProcedureListDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProcedureControllerService.ProceduresPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProcedureListDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `procedures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  procedures(params?: {

  }): Observable<Array<ProcedureListDto>> {

    return this.procedures$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProcedureListDto>>) => r.body as Array<ProcedureListDto>)
    );
  }

  /**
   * Path part for operation fetchAllSchedulesByProc
   */
  static readonly FetchAllSchedulesByProcPath = '/api/v1/procedures/{procedureId}/schedulers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchAllSchedulesByProc()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchAllSchedulesByProc$Response(params: {
    procedureId: string;

  }): Observable<StrictHttpResponse<Array<SchedulerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProcedureControllerService.FetchAllSchedulesByProcPath, 'get');
    if (params) {

      rb.path('procedureId', params.procedureId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SchedulerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchAllSchedulesByProc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchAllSchedulesByProc(params: {
    procedureId: string;

  }): Observable<Array<SchedulerDto>> {

    return this.fetchAllSchedulesByProc$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SchedulerDto>>) => r.body as Array<SchedulerDto>)
    );
  }

  /**
   * Path part for operation calllog
   */
  static readonly CalllogPath = '/api/v1/procedures/{procedureId}/calllog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calllog()` instead.
   *
   * This method doesn't expect any request body.
   */
  calllog$Response(params: {
    procedureId: string;

  }): Observable<StrictHttpResponse<Array<JobStatusDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProcedureControllerService.CalllogPath, 'get');
    if (params) {

      rb.path('procedureId', params.procedureId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JobStatusDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calllog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calllog(params: {
    procedureId: string;

  }): Observable<Array<JobStatusDto>> {

    return this.calllog$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JobStatusDto>>) => r.body as Array<JobStatusDto>)
    );
  }

}
