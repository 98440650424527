/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetailsDto } from '../models/common-details-dto';
import { WhatsappOnBoardDetailsDto } from '../models/whatsapp-on-board-details-dto';

@Injectable({
  providedIn: 'root',
})
export class WhatsappWabaOnBoardControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetchIndustryList
   */
  static readonly FetchIndustryListPath = '/api/v1/form/industry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchIndustryList()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchIndustryList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappWabaOnBoardControllerService.FetchIndustryListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchIndustryList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchIndustryList(params?: {
  }): Observable<Array<CommonDetailsDto>> {

    return this.fetchIndustryList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetailsDto>>) => r.body as Array<CommonDetailsDto>)
    );
  }

  /**
   * Path part for operation fetchUseCaseList
   */
  static readonly FetchUseCaseListPath = '/api/v1/form/use-case';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchUseCaseList()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchUseCaseList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappWabaOnBoardControllerService.FetchUseCaseListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchUseCaseList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchUseCaseList(params?: {
  }): Observable<Array<CommonDetailsDto>> {

    return this.fetchUseCaseList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetailsDto>>) => r.body as Array<CommonDetailsDto>)
    );
  }

  /**
   * Path part for operation fetchOnBoardDetails
   */
  static readonly FetchOnBoardDetailsPath = '/api/v1/profile/{profileId}/whatsapp/on-board';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchOnBoardDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchOnBoardDetails$Response(params: {
    profileId: string;
  }): Observable<StrictHttpResponse<WhatsappOnBoardDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappWabaOnBoardControllerService.FetchOnBoardDetailsPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsappOnBoardDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchOnBoardDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchOnBoardDetails(params: {
    profileId: string;
  }): Observable<WhatsappOnBoardDetailsDto> {

    return this.fetchOnBoardDetails$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappOnBoardDetailsDto>) => r.body as WhatsappOnBoardDetailsDto)
    );
  }

  /**
   * Path part for operation saveOnBoardDetails
   */
  static readonly SaveOnBoardDetailsPath = '/api/v1/profile/{profileId}/whatsapp/on-board';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveOnBoardDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveOnBoardDetails$Response(params: {
    profileId: string;
    onBoardDetailsMeta: string;
    body?: {
'multipartFile'?: Blob;
}
  }): Observable<StrictHttpResponse<WhatsappOnBoardDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappWabaOnBoardControllerService.SaveOnBoardDetailsPath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('onBoardDetailsMeta', params.onBoardDetailsMeta, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsappOnBoardDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveOnBoardDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveOnBoardDetails(params: {
    profileId: string;
    onBoardDetailsMeta: string;
    body?: {
'multipartFile'?: Blob;
}
  }): Observable<WhatsappOnBoardDetailsDto> {

    return this.saveOnBoardDetails$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappOnBoardDetailsDto>) => r.body as WhatsappOnBoardDetailsDto)
    );
  }

  /**
   * Path part for operation updateOnBoardWaBaStatus
   */
  static readonly UpdateOnBoardWaBaStatusPath = '/api/v1/profile/{profileId}/whatsapp/on-board';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOnBoardWaBaStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateOnBoardWaBaStatus$Response(params: {
    profileId: string;
    status: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappWabaOnBoardControllerService.UpdateOnBoardWaBaStatusPath, 'patch');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('status', params.status, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOnBoardWaBaStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateOnBoardWaBaStatus(params: {
    profileId: string;
    status: string;
  }): Observable<string> {

    return this.updateOnBoardWaBaStatus$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation fetchCountryWithCurrencyList
   */
  static readonly FetchCountryWithCurrencyListPath = '/api/v1/country-currency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchCountryWithCurrencyList()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchCountryWithCurrencyList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappWabaOnBoardControllerService.FetchCountryWithCurrencyListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchCountryWithCurrencyList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchCountryWithCurrencyList(params?: {
  }): Observable<Array<CommonDetailsDto>> {

    return this.fetchCountryWithCurrencyList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetailsDto>>) => r.body as Array<CommonDetailsDto>)
    );
  }

}
