import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UploadFirmwareComponent } from './upload-firmware/upload-firmware.component';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { ManageFirmwareComponent } from './manage-firmware/manage-firmware.component';

const routes: Routes = [
  {
    path: 'firmware/upload-firmware',
    component: UploadFirmwareComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'FMW_C', data: {} }
  },
  {
    path: 'firmware/manage-firmware',
    component: ManageFirmwareComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'FMW_C', data: {} }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FirmwareRoutingModule { }
