<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="whatsappAnalyticsIcon" />
        <span>{{'WHATSAPP_ANALYTICS' | translate}}</span>
      </span>
    </div>
    <div class="form-spacing">
      <app-dynamic-form [jsonFile]="'whatsapp-analytics'"
          [gridLayout]="'grid-container-whatsapp-analytics'" [dropDownValues]="dropDownValues"  (valueSelected)="updateDropdownValues($event)"
          [hideSubmitButton]="true" [emitValueAfterReset]="false"></app-dynamic-form>
      </div>

    <div class="cards"  *ngIf="profileIdSelected">
      <div class="card">
        <img class="card-image" [src]="whatsappNumIcon">
        <div class="card-title">{{ 'WHATSAPP_BUSINESS_NUMBER' | translate }}</div>
        <div class="card-content">
          {{ 'WABA_NUMBER_DESCRIPTION' | translate }}
        </div>
        <div class="card-footer">
          <div>{{wabaNumber}}</div>
          <div class="info-icon">
            <img (click)="copyText(wabaNumber)" class="page-icon-small" [src]="copyIcon" />
          </div>
        </div>
      </div>
      <div class="card">
          <img class="card-image" [src]="whatsappQualityIcon ">
          <div class="card-title">{{'ACCOUNT_QUALITY' | translate}}</div>
          <div class="card-content"> 
            {{'ACCOUNT_QUALITY_DESCRIPTION' | translate}}
          </div>
          <div class="card-footer icon-style">
            <div class="decorated-score" [style.background-color]="qualityScore">
              {{qualityScore}}
            </div>
            <div class="info-icon">
              <img title="{{'ACCOUNT_QUALITY_INFO' | translate}}" class="page-icon-small info-image"[src]="infoIcon" />
            </div>
          </div>
        </div>
        <div class="card">
          <img class="card-image" [src]="whatsappMsgLimitIcon">
          <div class="card-title">{{'MSG_LIMIT' | translate}}</div>
          <div class="card-content">
            {{'MSG_LIMIT_DESCRIPTION' | translate}}
          </div>
          <div class="card-footer icon-style">
          <div class="decorated-limit">
            Msg Limit : {{ msgLimit }}
          </div>
          <div class="info-icon">
            <img title="{{'MSG_LIMIT_INFO' | translate}}" class="page-icon-small info-image" [src]="infoIcon" />
          </div>
          </div>
        </div>

        <div class="card">
          <img class="filter-icon" (click)="openFilterOptout(filterOptoutContent)" [src]="filterIcon">
          <img class="card-image" [src]="optOutIcon">
          <div class="card-title">{{'OPT_OUTS_NOTIFICATION' | translate}}</div>
          <div class="card-content">
            {{'OPT_OUTS_NOTIFICATION_DESCRIPTION' | translate}}
          </div>
          <div class="card-footer icon-style">
          <div class="decorated-limit">
            {{'TOTAL_OPTOUT' | translate}} : {{ optOutCounts }}
          </div>
          <div class="info-icon">
            <img class="page-icon-small download-icon" (click)="downloadOptoutReport()" [src]="downloadIcon" />
          </div>
          </div>
        </div>

    </div>
    <div *ngIf ="profileIdSelected && dataSource?.data?.length > 0">
    <mat-table [dataSource]="dataSource">
      <div *ngFor="let column of columns">
        <ng-container [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>
            <div [ngStyle]="{'margin-left.px': column === 'qualityScore' ? 25 : 0}">
            {{ column === 'readRate' ? ('% '+ column | appendSpace | titlecase | translate) : (column | appendSpace | titlecase | translate) }}
            </div>
          </mat-header-cell>
          <mat-cell class="tooltip" *matCellDef="let dataName; index as i">
            <ng-container *ngIf="column !== 'qualityScore'; else qualityScoreColumn">
              <span title="{{ dataName[column] }}" class="cell-value">
                {{ dataName[column] }}
              </span>
            </ng-container>
            <ng-template #qualityScoreColumn>
              <p class="decorated-score"
                  [ngClass]="{
                    'decorated-score-yellow': dataName[column] === 'YELLOW',
                    'decorated-score-green': dataName[column] === 'GREEN',
                    'decorated-score-red': dataName[column] === 'RED'
                  }">
                {{ dataName[column] ? dataName[column] : '' }}
              </p>
            </ng-template>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row *matRowDef="let data; columns: columns"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSize"  [length]="templateCount" 
    showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
  </div>
  </section>


  <ng-template #filterOptoutContent let-modal>
    <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
    <h6 class="filter-title">{{'SELECT_DATE'|translate}}</h6>
    <div class="modal-width">
      <div class="modal-body">
        <div [formGroup]="dateRangeForm" class="filter-fields">
          <div>
            <mat-form-field>
              <mat-label class="label"> {{'FROM_DATE' | translate}}
            </mat-label>
              <input required (dateChange)="checkDate()" matInput [matDatepicker]="picker" [min]="minDate" [max]="maxDate" [readonly]="true"  [formControlName]="'fromDate'"  [placeholder]="'FROM_DATE' | translate ">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            <div *ngIf="dateRangeForm.controls['fromDate'] && dateRangeForm.controls['fromDate'].touched && dateRangeForm.controls['fromDate'].errors">
            <div class="error-message" *ngIf="dateRangeForm.controls['fromDate'].errors?.invalidDate">
              <mat-error>{{'FROMDATE_LESSTHAN_TODATE' | translate }}</mat-error>
          </div>

          <div *ngIf="dateRangeForm.controls['fromDate']?.errors?.required">
            <mat-error class="mat-error-class">
                {{'FROM_DATE' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
        </div>
          </div>
         </div>
  
         <div> 
          <mat-form-field>
            <mat-label class="label">{{'TO_DATE' | translate}}</mat-label>
            <input required (dateChange)="checkDate()"  [formControlName]="'toDate'" matInput [placeholder]="'TO_DATE' | translate" [matDatepicker]="pickerTo" [readonly]="true"  [min]="minDate" [max]="maxDate">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>

          <div *ngIf="dateRangeForm.controls['toDate'] && dateRangeForm.controls['toDate'].touched && dateRangeForm.controls['toDate'].errors">
          <div *ngIf="dateRangeForm.controls['toDate']?.errors?.required">
            <mat-error class="mat-error-class">
                {{'TO_DATE' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
        </div>
          </div>
         </div>
        </div>
        <section class="filter-submit-section">
          <button (click)="filterOptout()" mat-raised-button class="button btn-background">
            {{'SUBMIT' | translate }}</button>
            <button (click)="onResetFilter()" mat-button class="button">{{
              'RESET' | translate }}</button>
        </section>
      </div>
    </div>
  </ng-template>
</section>
