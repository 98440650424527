import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpdatePackageComponent } from './update-package/update-package.component';
import { FotaAdvanceRoutingModule } from './fota-advance-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { AgmCoreModule } from '@agm/core';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../shared/shared.module';
import { DistributionPackageComponent } from './distribution-package/distribution-package.component';
import { OtaAssignmentComponent } from './ota-assignment/ota-assignment.component';
import { OtaCampaignComponent } from './ota-campaign/ota-campaign.component';
import { UpdatePackageDetailsComponent } from './update-package-details/update-package-details.component';
import { AddUpdatePackageComponent } from './add-update-package/add-update-package.component';



@NgModule({
  declarations: [
    UpdatePackageComponent,
    DistributionPackageComponent,
    OtaAssignmentComponent,
    OtaCampaignComponent,
    UpdatePackageDetailsComponent,
    AddUpdatePackageComponent
  ],
  imports: [
    CommonModule,
    FotaAdvanceRoutingModule,
    MatTabsModule,
    TranslateModule,
    MatTableModule,
    SharedModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatDatepickerModule,
    MatCardModule,
    MatExpansionModule,
    MatMenuModule,
    MatRadioModule,
    FormsModule,
    AgmCoreModule,
    MatTooltipModule
  ]
})
export class FotaAdvanceModule { }
