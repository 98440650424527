import { Component, OnInit } from '@angular/core';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { MapControllerService } from 'src/app/services/Platform/services';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { SettingCommonDto } from 'src/app/services/Platform/models';
import { ACCESS_KEY_ICON, BACK_LEFT, COPY_IMAGE, GENERATE_IMAGE, HIDE_KEY, SHOW_KEY } from 'src/app/shared/constants/strings';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-list-map-keys',
  templateUrl: './list-map-keys.component.html',
  styleUrls: ['./list-map-keys.component.scss']
})
export class ListMapKeysComponent implements OnInit {

  public showSpinner :any;
  public inputData;
  public numberOfKeys = 1;
  public mapKeysData;
  public columns: any[];
  public ignoreList = ['id', 'mapKey', 'action'];
  public dataSource;
  showKeyImage;
  hideKeyImage;
  copyImage;
  refreshImage;
  accessKeyIcon;
  showKey = false;
  public backLeftImage: string;

  constructor(private mapControllerService: MapControllerService, private router: Router,
    private responseHandlerService: ResponseHandlerService,
    private route: ActivatedRoute,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.inputData = { businessProfileId: this.route.snapshot.paramMap.get('businessProfileId'),
    solutionTypeId: this.route.snapshot.paramMap.get('solutionTypeId'),
    projectId: this.route.snapshot.paramMap.get('projectId') };
    this.copyImage = COPY_IMAGE;
    this.showKeyImage = SHOW_KEY;
    this.hideKeyImage = HIDE_KEY;
    this.backLeftImage = BACK_LEFT;
    this.accessKeyIcon = ACCESS_KEY_ICON;
    this.refreshImage = GENERATE_IMAGE;
      this.getMapKeys();
  }

  public onMapSubscribe(type) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.mapControllerService.subscribeMapServiceForProfile({
      profileId: this.inputData['businessProfileId'],
      solutionTypeId: this.inputData['solutionTypeId'],
      mode: type,
      noOfKeys: this.numberOfKeys
    }).subscribe(resp => {
      if (resp) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', resp.message);
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public requestMoreKeys() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.mapControllerService.requestMoreKeysForMapServiceForProfile({
      profileId: this.inputData['businessProfileId'],
      solutionTypeId: this.inputData['solutionTypeId'],
      noOfKeys: this.numberOfKeys
    }).subscribe(resp => {
      if (resp) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', resp.message);
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getMapKeys() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.mapControllerService.getMapProjectsforProfileAndSolutionType({
      profileId: this.inputData['businessProfileId'],
      solutionTypeId: this.inputData['solutionTypeId'],
      projectId: this.inputData['projectId'],
    }).subscribe(resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.mapKeysData = resp[0]['mapProjectApiKeys'];
        this.mapKeysData.forEach(mapKey => {
          mapKey['apiKey'] = mapKey['mapKey'];
          mapKey['restrictionType'] = mapKey['restrictionType']['name'];
        });
        this.dataSource = new MatTableDataSource<SettingCommonDto>(this.mapKeysData);
        this.columns = this.filterColumns(Object.keys(this.mapKeysData[0]));
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  public showMapKey() {
    this.showKey = !this.showKey;
  }

  public copyText(text) {
    text.select();
    document.execCommand('copy');
    this.responseHandlerService.returnToastMessage('success', 'COPIED_TO_CLIPBOARD');
  }

  public navigateBack() {
    this.router.navigate(['access-key/map-project-list', { businessProfileId: this.inputData.businessProfileId,
      solutionTypeId: this.inputData.solutionTypeId}]);
  }

  public requestRestrictionType() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.mapControllerService.requestRestrictionTypeForMapServiceForProfile({
      profileId: this.inputData['businessProfileId'],
      solutionTypeId: this.inputData['solutionTypeId']
    }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', resp.message);
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public deleteKey(mapKeyId) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.mapControllerService.requestRegernateKeyForMapService({
      profileId: this.inputData['businessProfileId'],
      solutionTypeId: this.inputData['solutionTypeId'],
      apiKeyId: mapKeyId
    }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', resp.message);
      this.getMapKeys();
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public determineColumnSize(columnName) {
    if (columnName === 'apiKey') {
      return 'column-large';
    } else {
      return 'column-small';
    }
  }

  public determineHeaderSize(columnName) {
    if (columnName === 'restrictionType') {
      return 'header-type';
    } else if (columnName === 'apiKey'){
      return 'header-key';
    } else{
      return;
    }
  }
  
}
