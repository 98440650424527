import { NgModule } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MaterialModule } from '../shared/material.module';
import { MatIconRegistry } from '@angular/material/icon';
@NgModule({
	declarations: [],
	imports: [MaterialModule],
	exports: [],
	providers: []
})
export class IconModule {
	private path = '../../assets/img/menu-icons';
	constructor(
		private domSanitizer: DomSanitizer,
		public matIconRegistry: MatIconRegistry
	) {
		this.matIconRegistry
			.addSvgIcon('account', this.setPath(`${this.path}/account-management.svg`))
			.addSvgIcon('tenant', this.setPath(`${this.path}/tenant-management.svg`))
			.addSvgIcon('profile', this.setPath(`${this.path}/profile-management.svg`))
			.addSvgIcon('user', this.setPath(`${this.path}/user-management.svg`))
			.addSvgIcon('device', this.setPath(`${this.path}/device-management.svg`))
			.addSvgIcon('fota', this.setPath(`${this.path}/fota-management.svg`))
			.addSvgIcon('firmware', this.setPath(`${this.path}/firmware-management.svg`))
			.addSvgIcon('scheduler', this.setPath(`${this.path}/scheduler-management.svg`))
			.addSvgIcon('access-key', this.setPath(`${this.path}/access-key.svg`))
			.addSvgIcon('certificate', this.setPath(`${this.path}/certificate-icon.svg`))
			.addSvgIcon('sms', this.setPath(`${this.path}/sms-management.svg`))
			.addSvgIcon('simcard', this.setPath(`${this.path}/simcard.svg`))
			.addSvgIcon('whatsapp', this.setPath(`${this.path}/whatsapp-management.svg`));
	}
	private setPath(url: string): SafeResourceUrl {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
