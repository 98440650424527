import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { CreateCertificatesComponent } from './create-certificates/create-certificates.component';
import { ManageCertificatesComponent } from './manage-certificates/manage-certificates.component';
const routes: Routes = [
  {
    path: 'certificate/create-certificates',
    component: CreateCertificatesComponent,
    canActivate: [CanAuthenticationGuard],
    data: {permission: 'CERT_C', data: {}}
  },
  {
    path: 'certificate/manage-certificates',
    component: ManageCertificatesComponent,
    canActivate: [CanAuthenticationGuard],
    data: {permission: 'CERT_R', data: {}}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CertificateRoutingModule { }
