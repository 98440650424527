/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  TenantControllerService, AccountControllerService, DomainControllerService,
  PlatformAdminUtilityControllerService,
  CustomerControllerService
} from 'src/app/services/Platform/services';
import { TenantDto } from 'src/app/services/Platform/models';
import { MatPaginator } from '@angular/material/paginator';
import {  MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/providers/translation-service';
import { TOAST_MSG_DURATION, MANAGE, EDIT_IMAGE, DELETE_IMAGE, TENANT_ICON, CLOSE_ICON } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-manage-tenant',
  templateUrl: './manage-tenant.component.html',
  styleUrls: ['./manage-tenant.component.scss']
})

// activation/de-activation/deletion of multiple tenants to be taken up in next phase

/**
  * @ngdoc component
  * @name TenantsModule.component:manageTenant
  *
  *
  * @description
  * description: To display the list of all tenants, perform update, activate, deactivate and delete operations on selected tenant.
  *
*/
export class ManageTenantComponent implements OnInit {
  public showSpinner:any;
  public tenantData;
  public columns: any[];
  public jsonFileLoaded;
  public dataSource;
  public dropDownValues: any = {};
  public pageKey: string;
  public firstTimeLoad = false;
  public mode = '';
  public permissionList: Array<String> = [];
  asideMenus: Array<AsideCard>;
  ignoreList = ['id', 'links', 'spocName', 'active', 'state', 'country', 'accountObject', 'domainObject',
    'countryObject', 'stateObject', 'account', 'altContactNumber', 'altEmailId', 'address', 'description',
    'domain', 'gstin', 'companyContactNumber', 'spocAltContactNumber', 'customerId'];
  selection = new SelectionModel<TenantDto>(true, []);
  editImage;
  deleteImage;
  tenantIcon;
  selectedAccount;
  tenantId: any;
  accountValues: any;
  accountId: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  pageIndex: number = 0;
  public tenantCount = 0;
  closeIcon: string;

  constructor(private translate: TranslationService, private accountControllerService: AccountControllerService,
    private domainControllerService: DomainControllerService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService, private route: ActivatedRoute,
    private tenantControllerService: TenantControllerService, private responseHandlerService: ResponseHandlerService,
    private _matDialog: MatDialog, private rightsideNavitemsService: RightsideNavitemsService,
    private objectToIdConversionService: ObjectToIdConversionService,
    private customerControllerService: CustomerControllerService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.getAcounts();
    this.jsonFileLoaded = 'tenants';
    this.pageKey = 'tenant';
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    if (this.route.snapshot.paramMap.get('tenantId') !== undefined || this.route.snapshot.paramMap.get('accountId') !== undefined) {
      this.accountId = this.route.snapshot.paramMap.get('accountId');
      this.tenantId = this.route.snapshot.paramMap.get('tenantId');
    }
    this.editImage = EDIT_IMAGE;
    this.deleteImage = DELETE_IMAGE;
    this.tenantIcon = TENANT_ICON;
    this.closeIcon = CLOSE_ICON;
  }


  /**
    * @ngdoc method
    * @name manageTenant#isAllSelected
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: To select or deselect all the tenants for bulk operations
  */
  public isAllSelected() {
    const numSelected = this.selection.selected.length;

    const numRows = this.dataSource.data.length;

    return numSelected === numRows;
  }

  /**
    * @ngdoc method
    * @name manageTenant#masterToggle
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: To select or deselect all the tenants for bulk operations
  */
  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
    * @ngdoc method
    * @name manageTenant#checkboxLabel
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description
    *
    * @param {type} name description
    * @return {type} name description
  */
  public checkboxLabel(row?: TenantDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }


  /**
    * @ngdoc method
    * @name manageTenant#gotoModal
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: To open a popup window to enter new values for formfields
    *
    * @param {type} name description: popup name, form data, mode
    * @return {type} name description
  */
  public gotoModal(content, data = null, mode?) {
    this.tenantData = data;
    this.mode = mode;
    this.firstTimeLoad = false;

    if (!this.mode) {
      this.getAccount();
      this.getDomain();
      this.getCountry();
      if (this.tenantData && this.tenantData['accountObject']) {
        this.tenantData['account'] = this.objectToIdConversionService.convertIdToObject(this.tenantData['accountObject']);
      }
      if (this.tenantData && this.tenantData['domainObject']) {
        this.tenantData['domain'] = this.objectToIdConversionService.convertIdToObject(this.tenantData['domainObject']);
      }
      if (this.tenantData && this.tenantData['countryObject']) {
        this.tenantData['country'] = this.objectToIdConversionService.convertIdToObject(this.tenantData['countryObject']);
        this.updateDropdownValues({ child: 'state', value: this.tenantData['country'] });
      }
      if (this.tenantData && this.tenantData['stateObject']) {
        this.tenantData['state'] = this.objectToIdConversionService.convertIdToObject(this.tenantData['stateObject']);
      }
    }
    this._matDialog.open(content, { disableClose: true, data });
  }

  /**
    * @ngdoc method
    * @name manageTenant#getTenants
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description : To fetch and display the list of all tenants
  */
  public getTenants() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.tenantControllerService
      .findAllTenants({})
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.tenantData = resp;
            this.tenantData.forEach((tenant) => {
              tenant['accountObject'] = tenant['account'];
              tenant['countryObject'] = tenant['country'];
              tenant['domainObject'] = tenant['domain'];
              tenant['stateObject'] = tenant['state'];
            });

            this.dataSource = new MatTableDataSource<TenantDto>(this.tenantData);
            for (const data of this.tenantData) {
              data['action'] = null;
            }
            this.columns = this.filterColumns(Object.keys(this.tenantData[0]));
            // this.columns.unshift('select');
          } else {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageTenant#getTenantsByAccountId
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: Fetch Tenants based on accountId.
    *
    * @param {type} accountId
    * @return {type} null
  */
  public getTenantsByAccountId(accountId, page, limit) {
    this.accountId = accountId;
    this.showSpinner = this.loaderService.showProgressBar();
    this.tenantControllerService
      .findAllTenantsByAccount({ accountId: accountId, page: page, limit: limit })
      .subscribe(
        resp => {
          if (resp['tenantDetailsList'] && resp['tenantDetailsList'].length ) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.tenantData = resp['tenantDetailsList'];
            this.tenantCount = resp['totalTenantDetails'];
            this.tenantData.paginator = this.paginator;
            this.tenantData.forEach((tenant) => {
              const interfaceValues = [];
              const solutionTypeValues = [];
              tenant['accountObject'] = tenant['account'];
              tenant['countryObject'] = tenant['country'];
              tenant['domainObject'] = tenant['domain'];
              tenant['stateObject'] = tenant['state'];
            });

            if (this.tenantId) {
              const tenant = this.tenantData.filter(tenant => {
                if (tenant['id'] === this.tenantId) {
                  this.selectedAccount = tenant['account']['name'];
                  return tenant;
                }
              });
              this.tenantData = tenant;
              this.tenantId = undefined;
            }

            this.dataSource = new MatTableDataSource<TenantDto>(this.tenantData);
            //this.dataSource.paginator = this.paginator;
            for (const data of this.tenantData) {
              data['action'] = null;
            }
            this.columns = this.filterColumns(Object.keys(this.tenantData[0]));
            
          } else {
            this.tenantData = null;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.dataSource = new MatTableDataSource<TenantDto>();
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
          this.tenantData = null;
          this.dataSource = null;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

    /**
   * @ngdoc method
   * @name manageSim#pageEvent
   *
   * @methodOf
   * sim.controller:manageSim
   *
   * @description
   * Description: To fetch the next list of sims.
   *
   * @param {type} event
   * @return {type} null
   */
    public pageEvent(event) {
      this.pageIndex = event.pageIndex;
      this.pageSize = event.pageSize;
      this.getTenantsByAccountId(this.accountId, this.pageIndex, this.pageSize);
      this.selection.clear();
    }

  /**
      * @ngdoc method
      * @name manageTenant#getAcounts
      *
      * @methodOf
      * account.controller:manageTenant
      *
      * @description
      * Description: Fetches accounts list.
      *
      * @param {type} null
      * @return {type} null
    */
  public getAcounts() {
    this.accountControllerService
        .findAllAccounts({})
        .subscribe(
            resp => {
                if (resp && resp['length']) {
                    this.accountValues = resp;
                    this.dropDownValues['account'] = resp;
                    if (this.accountId === null) {
                      this.selectedAccount = this.accountValues[0]['name'];
                      this.accountId = this.accountValues[0]['id'];
                      this.getTenantsByAccountId(this.accountId, 0, this.pageSize);
                    } else {
                      const accountDetails = this.accountValues.find(account => account['id'] === this.accountId);
                      this.selectedAccount = accountDetails['name'];
                      this.getTenantsByAccountId(this.accountId, 0, this.pageSize);
                    }
                  } else {
                    this.dropDownValues['account'] = [];
                    this.loaderService.hideProgressBar(this.showSpinner);
                    this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
                  }                
            },
            err => {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
        );
}

  /**
    * @ngdoc method
    * @name manageTenant#editTenant
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: To edit details of particular tenant
    *
    * @param {type} name description: New values of tenant details
  */
  public editTenant(data: any) {
    if (data && data['account']) {
      data['account'] = this.objectToIdConversionService.convertObjectToId(data['account']);
    }

    if (data && data['domain']) {
      data['domain'] = this.objectToIdConversionService.convertObjectToId(data['domain']);
    }

    if (data && data['country']) {
      data['country'] = this.objectToIdConversionService.convertObjectToId(data['country']);
    }

    if (data && data['state']) {
      data['state'] = this.objectToIdConversionService.convertObjectToId(data['state']);
    }
    this.showSpinner = this.loaderService.showProgressBar();
    this.tenantControllerService
      .updateTenant({
        id: this.tenantData['id'],
        body: data
      })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.getTenantsByAccountId(this.accountId, this.pageIndex, this.pageSize);
          this.tenantData = null;
          this._matDialog.closeAll();
          this.responseHandlerService.returnToastMessage('success', 'TENANT_EDIT_SUCCESS');
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this._matDialog.closeAll();
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageTenant#getAccount
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: To populate account dropdown
    *
    * @param {type} name description
    * @return {type} name description
  */
  /* @description:  */
  public getAccount() {
    this.accountControllerService
      .findAllAccounts({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.dropDownValues['account'] = resp;
          } else {
            this.dropDownValues['account'] = [];
          }
        },
        err => {
          this.dropDownValues['account'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageTenant#getDomain
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: to populate domain dropdown
    *
    * @param {type} name description
    * @return {type} name description
  */
  public getDomain() {
    this.domainControllerService
      .findAllDomains({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['domain'] = resp;
          } else {
            this.dropDownValues['domain'] = [];
          }
        },
        err => {
          this.dropDownValues['domain'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageTenant#updateDropdownValues
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: to populate dependent dropdown
    *
    * @param {type} name description: selected parent dropdown
    * @return {type} name description
  */
  public updateDropdownValues($event) {
    if (this.firstTimeLoad) {
      this.tenantData[$event.child] = null;
      this.dropDownValues[$event.child] = null;
    }

    this.firstTimeLoad = true;

    if ($event.value !== undefined && $event.child === 'state') {
      this.getState($event.value);
    }
  }

  /**
    * @ngdoc method
    * @name manageTenant#getState
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: to populate state dropdown based on the selected country
    *
    * @param {type} name description: selected country name
    * @return {type} name description
  */
  public getState(countryName: string) {
    this.platformAdminUtilityControllerService
      .getAllStateOfCountry({ id: countryName })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['state'] = resp;
          } else {
            this.dropDownValues['state'] = [];
          }
        },
        err => {
          this.dropDownValues['state'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageTenant#getCountry
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: to populate country dropdown
    *
    * @param {type} name description
    * @return {type} name description
  */
  public getCountry() {
    this.platformAdminUtilityControllerService
      .getAllCountries()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['country'] = resp;
          } else {
            this.dropDownValues['country'] = [];
          }
        },
        err => {
          this.dropDownValues['country'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageTenant#activateTenant
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: to activate particular tenant
    *
    * @param {type} name description: details of tenant to be activated
    * @return {type} name description
  */
  public activateTenant(data: any) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    // const selectedRows = this.selection.selected;
    // let iterations = 0;
    // for (const row of selectedRows) {
    this.tenantControllerService.activateTenant({ id: data['id'] }).subscribe(
      resp => {
        // iterations++;
        this.loaderService.hideProgressBar(this.showSpinner);
        // if (iterations === selectedRows.length) {
        this.responseHandlerService.returnToastMessage('success', 'TENANT_ACTIVATE_SUCCESS');
        this.getTenantsByAccountId(this.accountId, this.pageIndex, this.pageSize);
        // this.selection.clear();
        // }
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        this.tenantData['active'] = !this.tenantData['active'];
      }
    );
    // }

  }

  /**
    * @ngdoc method
    * @name manageTenant#deActivateTenant
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: to de-activate particular tenant
    *
    * @param {type} name description: details of tenant to be de-activated
    * @return {type} name description
  */
  public deActivateTenant(data: any) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    this.tenantControllerService.deactivateTenant({ id: data['id'] }).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'TENANT_DEACTIVATE_SUCCESS');
        this.getTenantsByAccountId(this.accountId, this.pageIndex, this.pageSize);
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        if (err && err['error']) {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
        this.tenantData['active'] = !this.tenantData['active'];
      }
    );
  }

  /**
    * @ngdoc method
    * @name manageTenant#deleteTenant
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: to delete particular tenant
    *
    * @param {type} name description: details of the tenant to be deleted
    * @return {type} name description
  */
  public deleteTenant(data: any) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    this.tenantControllerService.deleteTenant({ id: data['id'] }).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'TENANT_DELETE_SUCCESS');
        this.getTenantsByAccountId(this.accountId, this.pageIndex, this.pageSize);
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        if (err && err['error']) {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
        this.getTenantsByAccountId(this.accountId, this.pageIndex, this.pageSize);
      }
    );
  }

  /**
    * @ngdoc method
    * @name manageTenant#abortAction
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: to handle the operation on click of 'NO' in confirmation popup
    *
    * @param {type} name description
    * @return {type} name description
  */
  public abortAction() {
    this._matDialog.closeAll();
    // this.selection.clear();
    if (this.mode !== 'delete') {
      this.tenantData['active'] = !this.tenantData['active'];
    }
  }

  /**
    * @ngdoc method
    * @name manageTenant#filterColumns
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description:
    *
    * @param {type} name description
    * @return {type} name description
  */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  /**
    * @ngdoc method
    * @name manageTenant#checkPermission
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: Checks whether the logged in user has permission to perform crud operations.
    *
    * @param {type} name description: required permission name
    * @return {type} name description: boolean value based on available permission
  */
  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  /**
    * @ngdoc method
    * @name manageTenant#onClose
    *
    * @methodOf
    * TenantsModule.controller:manageTenant
    *
    * @description
    * Description: to close the popup
    *
    * @param {type} name description
    * @return {type} name description
  */
  public onClose() {
    this._matDialog.closeAll();
  }

  public gotoConfirmDialog(data = null, mode?) {
    this.tenantData = data;
    this.mode = mode;
    let message;
    switch (mode) {
        case 'edit': if ((data['active'])) {
            message = this.translate.translateErrorMessages('ACTIVATE_SELECTED_TENANT');
        } else {
            message = this.translate.translateErrorMessages('DEACTIVATE_SELECTED_TENANT');
        }
            break;
        case 'delete': message = this.translate.translateErrorMessages('DELETE_SELECTED_TENANT');
    }
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
        maxWidth: '400px',
        disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
        if (result) {
            switch (mode) {
                case 'edit': if (data['active']) {
                    this.activateTenant(data);
                } else {
                    this.deActivateTenant(data);
                }
                    break;
                case 'delete': this.deleteTenant(data);
                    break;
            }
        } else {
            this.abortAction();
        }
    });
  }
}




