<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <span class="heading-label">
          <img class="page-icon-small" [src]="whatsappIcon" />
          <span>{{'AGENT_CONFIGURATION' | translate}}</span>
        </span>
      </div>
      <div class="form-spacing">
        <app-dynamic-form   [jsonFile]="'whatsapp-agent-config'"
          [gridLayout]="'grid-container-whatsapp-agent-config'" [dropDownValues]="dropDownValues"
           (formEmitter)="createAgent($event)" (resetForm)="onReset()"></app-dynamic-form>
      </div>
      <div class="table-container" *ngIf="agentList">
        <mat-table [dataSource]="dataSource" class="table-data">
          <div *ngFor="let column of columns">
            <ng-container [matColumnDef]="column">
              <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
              <mat-cell class="tooltip" *matCellDef="let dataName">
                <span  title="{{dataName[column]}}" class="cell-value">
                  {{dataName[column]}}
                </span>
              </mat-cell>
            </ng-container>
          </div>
          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let data; columns: columns;"></mat-row>
        </mat-table>
      </div>

      </section>
      </section>