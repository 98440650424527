import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FotaJobControllerService, FotaSelectionControllerService } from 'src/app/services/DeviceMgmt/services';
import { ActivatedRoute, Router } from '@angular/router';
import { SettingCommonDto } from 'src/app/services/Platform/models';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TranslateService } from '@ngx-translate/core';
import { BACK_LEFT, DOWNLOAD_IMAGE, FOTA_ICON, SEARCH_IMAGE } from 'src/app/shared/constants/strings';
import { PieChartService } from 'src/app/providers/pie-chart-service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-fota-job-details',
  templateUrl: './fota-job-details.component.html',
  styleUrls: ['./fota-job-details.component.scss']
})
export class FotaJobDetailsComponent implements OnInit {
  public showSpinner :any;
  public dataSource;
  public columns: any[];
  public ignoreList = ['batchId', 'jobId', 'deviceLog', 'isDeviceLogAvailable', 'firmwareVersion'];
  public solutionTypeId: string;
  public fotaJobId: string;
  public jobDetailsData;
  public chartDataObject = {
    title: '',
    subtitle: '',
    tooltip: '',
    details: []
  };
  public downloadImage;
  public totalJobsCount;
  public totalDeviceCount = 0;
  public fotaJobName: string;
  public fotaDetailsData;
  public fotaIcon: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  public color;
  public backImage;
  public fotaDetailsInputData = {};
  public status;
  public searchImage = SEARCH_IMAGE;
  @ViewChild('charts', { static: true }) public chartElement: ElementRef;

  constructor(private fotaJobControllerService: FotaJobControllerService, private router: Router, private translate: TranslateService,
    private route: ActivatedRoute, private responseHandlerService: ResponseHandlerService, private pieChartService: PieChartService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.fotaJobId = this.route.snapshot.paramMap.get('fotaJobId');
    this.totalJobsCount = this.route.snapshot.paramMap.get('count');
    this.getFotaSelectionCriteria();
    this.backImage = BACK_LEFT;
    this.searchImage = SEARCH_IMAGE;
    this.downloadImage = DOWNLOAD_IMAGE;
    this.fotaIcon = FOTA_ICON;
    this.color = {
      'INITIATED': '#ffca18', 'REBOOTED': '#ffca18', 'AWAITING_RESPONSE': '#35a6c9', 'COMPLETED': '#78BE20',
      'ERROR': '#f75133', 'YET_TO_START': '#35a6c9', 'NOT_RESPONDING': 'grey', 'UPDATING ': '#ffca18', 'SUCCESS': '#78BE20'
    };
  }

  public getDeviceCount() {
    this.fotaJobControllerService.getFotaDeviceCount({ solutionTypeId: this.solutionTypeId, fotajobId: this.fotaJobId })
      .subscribe(resp => {
        if (resp && resp.length) {
        this.chartDataObject.details = this.getChartData(resp);
        this.chartDataObject.title = this.getTranslatedValues('DEVICE_COUNT');
        this.pieChartService.createChart(this.chartElement.nativeElement, this.chartDataObject);
        this.pieChartService.functionNameEmitter.subscribe(status => {
          this.getDeviceDetailsOnStatus(status);
          this.status = status;
        });
      } else {
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
      },
        err => {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
  }

  public getFotaDetailsInputData(event) {
    this.fotaDetailsInputData = {
      solutionTypeId: this.solutionTypeId, fotajobId: this.fotaJobId, status: event,
      page: 0, limit: this.pageSize
    };
    this.status = event;
    this.getDeviceDetailsOnStatus(this.fotaDetailsInputData);
  }

  public getDeviceDetailsOnStatus(fotaDetailsInputData) {
    this.jobDetailsData = null;
    this.showSpinner = this.loaderService.showProgressBar();
    this.fotaJobControllerService.getFotaDeviceDetails( {solutionTypeId: this.solutionTypeId, fotajobId: this.fotaJobId,
      status: fotaDetailsInputData}).subscribe(resp => {
      if (resp && resp['fotaDeviceDetailsList'] && resp['fotaDeviceDetailsList']['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.totalDeviceCount = resp['totalDeviceDetails'];
        this.jobDetailsData = resp['fotaDeviceDetailsList'];
        for (const data of this.jobDetailsData) {
          if (data['isDeviceLogAvailable']) {
            data['log'] = null;
          }
        }
        this.columns = this.filterColumns(Object.keys(this.jobDetailsData[0]));
        this.dataSource = new MatTableDataSource<SettingCommonDto>(this.jobDetailsData);
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public pageEvent(event) {
    this.fotaDetailsInputData['status'] = this.status;
    this.fotaDetailsInputData['page'] = event.pageIndex;
    this.fotaDetailsInputData['limit'] = event.pageSize;
    this.getDeviceDetailsOnStatus(this.fotaDetailsInputData);
  }

  public getChartData(tempData) {
    const data = tempData;
    data.forEach(element => {
      element['name'] = (this.getTranslatedValues(element['status']));
      element['y'] = (element['count'] * this.totalJobsCount) / 100;
      element['color'] = this.color[element['status']];
    });
    return data;
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }

  public downloadErrorLog(deviceId: string) {
    this.fotaJobControllerService.getdeviceErrorLog({ solutionTypeId: this.solutionTypeId, fotajobId: this.fotaJobId, deviceId: deviceId })
      .subscribe(resp => {
        this.downloadFile(resp, this.generateFilename(this.fotaJobId, deviceId));
      }, err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public downloadFile(data, fileName) {
    const FileSaver = require('file-saver');
    const blob = new Blob([data], { type: 'text/plain' });
    FileSaver.saveAs(blob, fileName);
  }

  public generateFilename(fotaJobId, deviceId) {
    return (fotaJobId + '-' + deviceId);
  }

  public getFotaSelectionCriteria() {
    this.fotaJobControllerService.getFotaJobBasicById({ fotaJobId: this.fotaJobId }).subscribe(resp => {
      if (resp) {
        this.solutionTypeId = resp['solutionTypeDetails'].id;
        this.fotaJobName = resp['jobName'];
        this.fotaDetailsData = {
          FOTA_DETAILS: [{ 'label': 'FOTA_JOB_NAME', 'value': resp['jobName'] },
          { 'label': 'STATUS', 'value': resp['jobStatus'] },
          { 'label': 'FOTA_START_DATE', 'value': resp['jobStartDate'] }],
          SEARCH_CRITERIA: [{ 'label': 'SOLUTION_TYPE', 'value': resp['solutionTypeDetails'].name },
          { 'label': 'DEVICE_TYPE', 'value': resp['deviceTypeDetails'].name }]
        };
        if (resp['groupDetails']) {
          this.fotaDetailsData['SEARCH_CRITERIA'].push({ 'label': 'GROUP_NAME', 'value': resp['groupDetails'].name });
        }
        if (resp['currentFirwareVersion']) {
          this.fotaDetailsData['SEARCH_CRITERIA'].push({ 'label': 'FIRMWARE_VERSION', 'value': resp['currentFirwareVersion']['id'] });
        }
        this.getDeviceCount();
      }
    }, err => {
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public navigateBack() {
    this.router.navigate(['fota/manage-job-completed']);
  }

  public onSearchClick(event) {
    this.dataSource = null;
    this.columns = null;
    this.jobDetailsData = null;
    this.showSpinner = this.loaderService.showProgressBar();
    const deviceDetailsInputData = { solutionTypeId: this.solutionTypeId, fotajobId: this.fotaJobId, status: this.status, deviceId: event };
    this.fotaJobControllerService.getFotaDeviceDetails(deviceDetailsInputData).subscribe(resp => {
      if (resp && resp['fotaDeviceDetailsList'] && resp['fotaDeviceDetailsList']['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.totalDeviceCount = resp['totalDeviceDetails'];
        this.jobDetailsData = resp['fotaDeviceDetailsList'];
        if (this.jobDetailsData['isDeviceLogAvailable']) {
          for (const data of this.jobDetailsData) {
            data['log'] = null;
          }
        }
        this.columns = this.filterColumns(Object.keys(this.jobDetailsData[0]));
        this.dataSource = new MatTableDataSource<SettingCommonDto>(this.jobDetailsData);
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }
}
