<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="scheduler-icon" [src]="schedulerIcon" />
        <span>{{'CREATE_SCHEDULER' |translate}}</span>
      </span>
    </div>
    <form [formGroup]="schedulerForm" class="create-form-spacing form-fields">
      <div>
        <mat-form-field>
          <mat-label class="label">{{'SCHEDULER_NAME' | translate}} <span class="mandatory">*</span> </mat-label>
          <input type="text" matInput formControlName="name" class="form-field" [readonly]="schedulerId"
            [placeholder]=" 'TYPE_HERE' | translate" />
        </mat-form-field>
        <div *ngIf="submitted || schedulerForm.controls.name?.touched && schedulerForm.controls.name?.errors">
          <div *ngIf="schedulerForm.controls.name?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'SCHEDULER_NAME' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
          <mat-error class="mat-error-class" *ngIf="schedulerForm.controls.name?.errors?.maxlength">
            {{ 'SCHEDULER_NAME_SHOULD_BE_OF_MAXIMUM_50_CHARACTERS' | translate}}</mat-error>
        </div>
      </div>

      <div>
        <mat-form-field>
          <mat-label class="label">{{ 'DATABASE_NAME' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-select class="form-field" (selectionChange)="updateDropdownValues($event)" formControlName="databaseName"
            [placeholder]=" 'SELECT' | translate">
            <mat-option>{{ 'SELECT' | translate }}</mat-option>
            <mat-option *ngFor="let val of dropDownValues['databaseName']" [value]="val">
              {{val}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div
          *ngIf="submitted || schedulerForm.controls.databaseName?.touched && schedulerForm.controls.databaseName?.errors">
          <div *ngIf="schedulerForm.controls.databaseName?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'DATABASE_NAME' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
        </div>
      </div>

      <div>
        <mat-form-field>
          <mat-label class="label">{{ 'PROCEDURE_NAME' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-select class="form-field" (selectionChange)="getParentProcedures($event)" formControlName="procedureId"
            [placeholder]=" 'SELECT' | translate">
            <mat-option>{{ 'SELECT' | translate }}</mat-option>
            <mat-option *ngFor="let val of dropDownValues['procedureId']" [value]="val.id">
              {{val['name']}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div
          *ngIf="submitted || schedulerForm.controls.procedureId?.touched && schedulerForm.controls.procedureId?.errors">
          <div *ngIf="schedulerForm.controls.procedureId?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'PROCEDURE_NAME' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
        </div>
      </div>

      <span *ngIf="procedureList" class="procedure">{{ 'SCHEDULE_PARENT_PROCEDURES_FIRST' | translate }}</span>
      <div *ngIf="procedureList">
        <mat-table [dataSource]="dataSource" class="bottom-space table-data">
          <div *ngFor="let column of columns">
            <ng-container [matColumnDef]="column">
              <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase}}
              </mat-header-cell>
              <mat-cell class="tooltip" *matCellDef="let dataName" [ngClass]="column">
                <span title="{{dataName[column]}}" class="cell-value">
                  {{dataName[column]}}
                </span>
              </mat-cell>
            </ng-container>
          </div>
          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let data; columns: columns;"></mat-row>
        </mat-table>
      </div>

      <div>
        <mat-form-field>
          <mat-label class="label">{{ 'SCHEDULE_TYPE' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-select (selectionChange)="changeScheduleType($event)" class="form-field" formControlName="schedulerType"
            [placeholder]=" 'SELECT' | translate">
            <mat-option *ngFor="let val of dropDownValues['scheduleType']" [value]="val.id">
              {{val['name']}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div
          *ngIf="submitted || schedulerForm.controls.schedulerType?.touched && schedulerForm.controls.schedulerType?.errors">
          <div *ngIf="schedulerForm.controls.schedulerType?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'SCHEDULE_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
        </div>
      </div>

      <div *ngIf="scheduleType !== 'SPECIFIC_DATES'" class="date-time">
        <div class="date-field">
          <mat-form-field>
            <mat-label class="label">{{'START_DATE' | translate}} <span class="mandatory">*</span>
            </mat-label>
            <input matInput [matDatepicker]="picker" [placeholder]="'SELECT' | translate" [min]="currentDate" formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <div
            *ngIf="submitted || schedulerForm.controls.startDate?.touched && schedulerForm.controls.startDate?.errors">
            <div *ngIf="schedulerForm.controls.startDate?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'START_DATE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{'START_TIME' | translate}} <span class="mandatory">*</span> </mat-label>
            <input type="time" matInput formControlName="startTime" class="form-field"
              [placeholder]=" 'TYPE_HERE' | translate" />
          </mat-form-field>
          <div
            *ngIf="submitted || schedulerForm.controls.startTime?.touched && schedulerForm.controls.startTime?.errors">
            <div *ngIf="schedulerForm.controls.startTime?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'START_TIME' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>

        <div class="date-field">
          <mat-form-field>
            <mat-label class="label">{{'END_DATE' | translate}} <span class="mandatory">*</span> </mat-label>
            <input matInput [matDatepicker]="picker1" [placeholder]="'SELECT' | translate" [min]="currentDate" formControlName="endDate">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <div *ngIf="submitted || schedulerForm.controls.endDate?.touched && schedulerForm.controls.endDate?.errors">
            <div *ngIf="schedulerForm.controls.endDate?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'END_DATE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>

        <div>
          <mat-form-field>
            <mat-label class="label">{{'END_TIME' | translate}} <span class="mandatory">*</span> </mat-label>
            <input type="time" matInput formControlName="endTime" class="form-field"
              [placeholder]=" 'TYPE_HERE' | translate" />
          </mat-form-field>
          <div *ngIf="submitted || schedulerForm.controls.endTime?.touched && schedulerForm.controls.endTime?.errors">
            <div *ngIf="schedulerForm.controls.endTime?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'END_TIME' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="scheduleType === 'SPECIFIC_DATES'">
        <mat-label class="label label-color">{{'SPECIFIC_DATES' | translate}} <span class="mandatory">*</span>
        </mat-label>
        <div formArrayName="executionTime">
          <div *ngFor="let group of schedulerForm.get('executionTime')['controls']; let indexNumber=index">
            <div class="email" [formGroupName]="indexNumber">
              <div class="email-input">
                <div>
                  <mat-label class="label label-color">{{'DATE' | translate}} </mat-label>
                  <mat-form-field floatLabel="never">
                    <input matInput [matDatepicker]="picker2" [placeholder]="'SELECT' | translate" [min] = "currentDate"
                      formControlName="specificDate">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                  <div *ngIf="submitted || group.get('specificDate').touched && group.get('specificDate').errors">
                    <div *ngIf="group.get('specificDate').errors?.required">
                      <mat-error class="mat-error-class">
                        {{ 'DATE' | translate}} {{'IS_REQUIRED' | translate }}
                      </mat-error>
                    </div>
                  </div>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label class="label">{{'START_TIME' | translate}} </mat-label>
                    <input type="time" matInput formControlName="executionTime" class="form-field"
                      [placeholder]=" 'TYPE_HERE' | translate" />
                  </mat-form-field>
                  <div *ngIf="submitted || group.get('executionTime').touched && group.get('executionTime').errors">
                    <div *ngIf="group.get('executionTime').errors?.required">
                      <mat-error class="mat-error-class">
                        {{ 'START_TIME' | translate}} {{'IS_REQUIRED' | translate }}
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <div class="action-buttons">
                <button type="button" mat-icon-button color="primary" aria-label="Add"
                  (click)="addSpecificDateGroups()">+</button>
                <button *ngIf="schedulerForm.get('executionTime')['controls'].length > 1" mat-icon-button
                  color="primary" aria-label="Remove/clear" (click)="removeSpecificDateGroups(indexNumber)">-</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="scheduleType === 'REGULAR_INTERVAL'">
        <div formGroupName="executionTime" class="frequency">
          <div>
            <mat-form-field>
              <mat-label class="label">{{'FREQUENCY' | translate}}
                <span class="mandatory">*</span></mat-label>
              <input class="frequency" type="number" class="form-field" matInput formControlName="days"
                [placeholder]=" 'TYPE_HERE' | translate" />
              <span matSuffix>{{'DAYS' | translate}}</span>
            </mat-form-field>
            <div
              *ngIf="submitted || schedulerForm.controls['executionTime'].controls['days'].touched && schedulerForm.controls['executionTime'].controls['days'].errors">
              <div *ngIf="schedulerForm.controls['executionTime'].controls['days'].errors?.required">
                <mat-error>
                  {{ 'DAYS' | translate}} {{'IS_REQUIRED' | translate }}
                </mat-error>
              </div>
            </div>
          </div>
          <div>
            <mat-form-field>
              <mat-label class="label"></mat-label>
              <input class="frequency" type="number" class="form-field" matInput formControlName="hours"
                [placeholder]=" 'TYPE_HERE' | translate" />
              <span matSuffix>{{'HOURS' | translate}}</span>
            </mat-form-field>
            <div
              *ngIf="submitted || schedulerForm.controls['executionTime'].controls['hours'].touched && schedulerForm.controls['executionTime'].controls['hours'].errors">
              <div *ngIf="schedulerForm.controls['executionTime'].controls['hours'].errors?.required">
                <mat-error>
                  {{ 'HOURS' | translate}} {{'IS_REQUIRED' | translate }}
                </mat-error>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="scheduleType === 'DAY_OF_WEEK'">
        <mat-label class="label label-color">{{'SCHEDULE_DAYS' | translate}} <span class="mandatory">*</span>
        </mat-label>
        <div formArrayName="executionTime">
          <div *ngFor="let group of schedulerForm.get('executionTime')['controls']; let indexNumber=index">
            <div class="email" [formGroupName]="indexNumber">
              <div class="email-input">
                <div>
                  <mat-form-field>
                    <mat-label class="label">{{ 'DAY' | translate}} </mat-label>
                    <mat-select class="form-field" formControlName="dayOfWeek" [placeholder]=" 'SELECT' | translate">
                      <mat-option>{{ 'SELECT' | translate }}</mat-option>
                      <mat-option *ngFor="let val of dropDownValues['days']" [value]="val.id">
                        {{val['name']}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngIf="submitted || group.get('dayOfWeek').touched && group.get('dayOfWeek').errors">
                    <div *ngIf="group.get('dayOfWeek').errors?.required">
                      <mat-error class="mat-error-class">
                        {{ 'DAY' | translate}} {{'IS_REQUIRED' | translate }}
                      </mat-error>
                    </div>
                  </div>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label class="label">{{'START_TIME' | translate}} </mat-label>
                    <input type="time" matInput formControlName="time" class="form-field"
                      [placeholder]=" 'TYPE_HERE' | translate" />
                  </mat-form-field>
                  <div *ngIf="submitted || group.get('time').touched && group.get('time').errors">
                    <div *ngIf="group.get('time').errors?.required">
                      <mat-error class="mat-error-class">
                        {{ 'START_TIME' | translate}} {{'IS_REQUIRED' | translate }}
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <div class="action-buttons">
                <button type="button" mat-icon-button color="primary" aria-label="Add"
                class="action-button"  (click)="addFormGroup()">+</button>
                <button *ngIf="schedulerForm.get('executionTime')['controls'].length > 1" mat-icon-button
                  class="action-button" color="primary" aria-label="Remove/clear" (click)="removeFormGroup(indexNumber)">-</button>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="schedulerForm.get('parameterValues') && schedulerForm.get('parameterValues')['controls'].length">
        <mat-label class="label label-color">{{'ATTRIBUTES' | translate}} <span class="mandatory">*</span> </mat-label>
        <div formArrayName="parameterValues">
          <div *ngFor="let group of schedulerForm.get('parameterValues')['controls']; let indexNumber=index">
            <div class="email" [formGroupName]="indexNumber">
              <div class="email-input">
                <div>
                  <mat-form-field>
                    <mat-label class="label">{{'NAME' | translate}} </mat-label>
                    <input type="text" matInput readonly formControlName="parameterName" class="form-field"
                      [placeholder]=" 'TYPE_HERE' | translate" />
                  </mat-form-field>
                  <div *ngIf="submitted || group.get('parameterName').touched && group.get('parameterName').errors">
                    <div *ngIf="group.get('parameterName').errors?.required">
                      <mat-error class="mat-error-class">
                        {{ 'NAME' | translate}} {{'IS_REQUIRED' | translate }}
                      </mat-error>
                    </div>
                  </div>
                </div>
                <div>
                  <mat-form-field>
                    <mat-label class="label">{{'TYPE' | translate}} </mat-label>
                    <input type="text" readonly matInput formControlName="parameterType" class="form-field"
                      [placeholder]=" 'TYPE_HERE' | translate" />
                  </mat-form-field>
                  <div *ngIf="submitted || group.get('parameterType').touched && group.get('parameterType').errors">
                    <div *ngIf="group.get('parameterType').errors?.required">
                      <mat-error class="mat-error-class">
                        {{ 'TYPE' | translate}} {{'IS_REQUIRED' | translate }}
                      </mat-error>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <mat-form-field>
                  <mat-label class="label">{{'VALUE' | translate}} </mat-label>
                  <ng-container [ngSwitch]="group.get('parameterType').value">
                    <input *ngSwitchCase="'Email'" type="email" matInput formControlName="value" class="form-field"
                      [placeholder]=" 'TYPE_HERE' | translate" />
                    <input *ngSwitchCase="'Date'" type="date" matInput formControlName="value" class="form-field"
                      [placeholder]=" 'TYPE_HERE' | translate" />
                    <input *ngSwitchCase="'Number'" type="number" matInput formControlName="value" class="form-field"
                      [placeholder]=" 'TYPE_HERE' | translate" />
                    <input *ngSwitchDefault type="text" matInput formControlName="value" class="form-field"
                      [placeholder]=" 'TYPE_HERE' | translate" />
                  </ng-container>
                </mat-form-field>
                <div *ngIf="submitted || group.get('value').touched && group.get('value').errors">
                  <div *ngIf="group.get('value').errors?.required">
                    <mat-error class="mat-error-class">
                      {{ 'VALUE' | translate}} {{'IS_REQUIRED' | translate }}
                    </mat-error>
                  </div>
                  <mat-error *ngIf="group.get('value').errors?.pattern">
                    {{ 'PLEASE_ENTER_A_VALID' | translate}} {{ group.get('parameterType').value }}
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </form>
    <div>
      <section class="section">
        <button *ngIf="!schedulerId" (click)="onSubmit('submit')" [disabled]="procedureList" mat-raised-button
          class="button button-submit">{{ 'SUBMIT' | translate }}</button>
        <button *ngIf="schedulerId" (click)="onSubmit('update')" mat-raised-button
          class="button btn-background">{{ 'UPDATE' | translate }}</button>
        <button (click)="onReset()" mat-button class="button">{{ 'RESET' | translate }}</button>
      </section>
    </div>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
