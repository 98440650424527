import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessKeysRoutingModule } from './access-keys-routing.module';
import { TenantAdminKeyComponent } from './tenant-admin-key/tenant-admin-key.component';
import { SolutionAdminKeyComponent } from './solution-admin-key/solution-admin-key.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ListMapKeysComponent } from './list-map-keys/list-map-keys.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MapProjectsListComponent } from './map-projects-list/map-projects-list.component';
@NgModule({
  declarations: [TenantAdminKeyComponent, SolutionAdminKeyComponent, ListMapKeysComponent, MapProjectsListComponent],
  imports: [
    CommonModule,
    AccessKeysRoutingModule,
    MatButtonModule,
    MatSelectModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    MatCheckboxModule,
    MatCardModule,
    FlexLayoutModule,
    MatTableModule,
    MatInputModule,
    ReactiveFormsModule,
    MatListModule,
  ]
})
export class AccessKeysModule { }
