import { Component, OnInit } from '@angular/core';
import { SolutionTypeControllerService } from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { ActivatedRoute } from '@angular/router';
import { ACCESS_KEY_ICON, COPY_IMAGE, DELETE_IMAGE, GENERATE_IMAGE,
  HIDE_KEY, MANAGE, SHOW_KEY, EDIT_IMAGE, CLOSE_ICON } from 'src/app/shared/constants/strings';
import { AccesskeyLifeCycleControllerService } from 'src/app/services/Platform/services/accesskey-life-cycle-controller.service';
import { TranslationService } from 'src/app/providers/translation-service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import {
  SolutionAdminAccesskeyLifeCycleControllerService
} from 'src/app/services/Platform/services/solution-admin-accesskey-life-cycle-controller.service';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-solution-admin-key',
  templateUrl: './solution-admin-key.component.html',
  styleUrls: ['./solution-admin-key.component.scss']
})

export class SolutionAdminKeyComponent implements OnInit {
  selectedValues = {};
  accessKeyProperties = { solutionAdminKey: null, managerialService: null, accessKeyExpiryDate: null };
  public dropdownValues: any = {};
  dropdownList = [];
  showKey = {};
  public showSpinner :any;
  copyImage;
  deleteImage;
  refreshImage;
  hideKeyImage;
  showKeyImage;
  accessKeyIcon;
  public pageKey: string;
  asideMenus: Array<AsideCard>;
  solutionTypeId: string;
  dataSource;
  columns = [];
  ignoreList = ['id', 'validity', 'managerialServices'];
  multipleKeys = false;
  noOfKeys = 0;
  jsonFileLoaded;
  public editData: any = {};
  editImage;
  accessKeyId: string;
  generateForm: UntypedFormGroup;
  keysList = [];
  closeIcon: string;
  constructor(private solutionAdminAccesskeyLifeCycleControllerService: SolutionAdminAccesskeyLifeCycleControllerService,
    private accesskeyLifeCycleControllerService: AccesskeyLifeCycleControllerService,
    private responseHandlerService: ResponseHandlerService, private route: ActivatedRoute,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private translate: TranslationService,
    private formBuilder: UntypedFormBuilder,
    private _matDialog: MatDialog, private rightsideNavitemsService: RightsideNavitemsService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.pageKey = 'solution-admin-key';
    this.jsonFileLoaded = 'create-access-key';
    this.getValuesForExpiryDuration();
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.dropdownList = [
      {
        label: 'SELECT_SOLUTION',
        id: 'Solution',
        dropdownName: 'SOLUTION',
        childArray: ['EMAIL']
      }
    ];
    this.getSolutionType();
    this.copyImage = COPY_IMAGE;
    this.deleteImage = DELETE_IMAGE;
    this.refreshImage = GENERATE_IMAGE;
    this.showKeyImage = SHOW_KEY;
    this.hideKeyImage = HIDE_KEY;
    this.accessKeyIcon = ACCESS_KEY_ICON;
    this.editImage = EDIT_IMAGE;
    this.closeIcon = CLOSE_ICON;

    this.selectedValues['solution'] = false;
    this.generateForm = this.formBuilder.group({
      emailList: this.formBuilder.array([this.createEmailFormControl()]),
    });
  }

  public copyText(id) {
    const text = document.getElementById(id) as HTMLInputElement;
    text.select();
    document.execCommand('copy');
    this.responseHandlerService.returnToastMessage('success', 'COPIED_TO_CLIPBOARD');
  }

  public getSolutionType() {
    this.solutionTypeControllerService.findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropdownValues['SOLUTION'] = resp;
          } else {
            this.dropdownValues['SOLUTION'] = [];
          }
        },
        err => {
          this.dropdownValues['SOLUTION'] = [];
        }
      );
  }

  public updateDropdownValue(dropdownOption, event) {
    if (dropdownOption.childArray) {
      this.selectedValues['VALIDITY'] = null;
      this.dropdownValues['VALIDITY'] = [];
      this.accessKeyProperties['solutionAdminKey'] = null;
      this.multipleKeys = false;
      this.dataSource = null;
      this.resetEmailControls();
    }
    if (dropdownOption.dropdownName === 'SOLUTION' && event.value !== undefined) {
      this.selectedValues['SOLUTION'] = event.value;
      this.solutionTypeId = event.value;
      this.getValuesForExpiryDuration();
      this.getAccessKey();
      this.getExpiryEmails();
    }
    if (dropdownOption === 'validity' && event.value !== undefined) {
      this.selectedValues['validity'] = event.value;
    }
  }

  public getValuesForExpiryDuration() {
    this.accesskeyLifeCycleControllerService.getPossibleValuesForAccessKeyExpiry().subscribe(resp => {
      if (resp && resp.length) {
        this.dropdownValues['validity'] = resp;
      } else {
        this.dropdownValues['validity'] = [];
      }
    },
      err => {
        this.dropdownValues['validity'] = [];
      });
  }

  public getAccessKey() {
    this.solutionAdminAccesskeyLifeCycleControllerService.getAccessKeyDetails1({ solutionTypeId: this.selectedValues['SOLUTION'] })
      .subscribe(resp => {
        this.accessKeyProperties['solutionAdminKey'] = true;
        const dataTable = [];
        resp.forEach( key => {
          dataTable.push({
            name: key['keyName'],
            accessKey: key['accessKey'],
            expiry: key['expiryDateTime'] ? this.changeDateFormat(key['expiryDateTime'].substring(0, 16)) : '',
            id: key['id'],
            validity: key['expiryDuration'],
            managerialServices: key['managerialServices']
          });
        });
        this.columns = this.filterColumns(
          Object.keys(dataTable[0])
        );
        this.columns.push('action');
        this.dataSource = dataTable;
        this.noOfKeys = this.dataSource.length;
        this.multipleKeys = this.dataSource.length > 0;
      }, err => {
        this.accessKeyProperties['solutionAdminKey'] = null;
        this.selectedValues['VALIDITY'] = null;
        this.multipleKeys = false;
        this.noOfKeys = 0;
      });
  }

  public generateAccessKey(event) {
    let duplicate = false;
    if (this.dataSource) {
      this.dataSource.forEach( row => {
        if (event.name === row.name) {
          duplicate = true;
        }
      });
    }
    if (!duplicate) {
      this.showSpinner = this.loaderService.showProgressBar();
      if ( !this.multipleKeys || this.noOfKeys < 5) {
        this.solutionAdminAccesskeyLifeCycleControllerService.createAccessKeyForSolutionType({
          solutionTypeId: this.solutionTypeId, expiryDuration: event.validity, keyName: event.name
        })
          .subscribe(resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'ACCESS_KEY_GENERATION_SUCCESS');
            this.getAccessKey();
          }, err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          });
      } else {
        this.responseHandlerService.returnToastMessage('warning', 'MAXIMUM_KEYS_ADDED');
      }
      this.selectedValues['validity'] = null;
      this._matDialog.closeAll();
    } else {
      this.responseHandlerService.returnToastMessage('warning', 'DUPLICATE_KEY_NAME');
    }
  }

  public regenerateAccessKey(accessKey) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.solutionAdminAccesskeyLifeCycleControllerService.regenerateAccessKeyForSolutionType({
      accessKeyId: accessKey.id, action: 'REGENERATE'
    })
      .subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'ACCESS_KEY_REGENERATION_SUCCESS');
        this.getAccessKey();
      }, err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public deleteAccessKey(accessKey) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.solutionAdminAccesskeyLifeCycleControllerService.deleteAccessKeyForSolutionType({
      accessKeyId: accessKey.id
    }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', 'ACCESS_KEY_DELETE_SUCCESS');
      this.getAccessKey();
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public gotoConfirmDialog(accessKey= null, mode?) {
    let message;
    switch (mode) {
      case 'regenerate': message = this.translate.translateErrorMessages('REGENERATE_ACCESS_KEY');
        break;
      case 'delete': message = this.translate.translateErrorMessages('DELETE_ACCESS_KEY');
        break;
    }
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (mode) {
          case 'regenerate': this.regenerateAccessKey(accessKey);
            break;
          case 'delete': this.deleteAccessKey(accessKey);
            break;
        }
      } else {
        this._matDialog.closeAll();
      }
    });
  }

  public showAccessKey(id) {
    this.showKey[id] = !this.showKey[id];
  }

  public onManagerialServicesChecked() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.dataSource.forEach( row => {
      if ( row['managerialServices'] === false && this.keysList.includes(row.id)) {
        this.solutionAdminAccesskeyLifeCycleControllerService.subscribeManagerialServicesForSolutionType({
          accessKeyId: row['id']
        }).subscribe(resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'SUBSCRIBED_TO_MANAGERIAL_SERVICES');
          this.getAccessKey();
        }, err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      } else if (row['managerialServices'] && !this.keysList.includes(row.id)) {
        this.solutionAdminAccesskeyLifeCycleControllerService.unsubscribeManagerialServicesForSolution({
          accessKeyId: row['id']
        }).subscribe(resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'UNSUBSCRIBED_TO_MANAGERIAL_SERVICES');
          this.getAccessKey();
        }, err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      }
    });
    this._matDialog.closeAll();
  }

  public changeDateFormat(dateTime) {
    if (dateTime) {
      const splitDateTime = dateTime.split('T');
      const splitDate = splitDateTime[0].split('-');
      const time = splitDateTime[1];
      return `${splitDate[2].split(' ')[0]}-${splitDate[1]}-${splitDate[0]} ${time}`;
    }
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public gotoEditDialog(content, event, eventData= null) {
    let data;
    this.keysList = [];
    switch (event) {
      case 'edit': data = {
        title : 'EDIT_ACCESS_KEY_NAME',
        body : 'edit'
      };
      this.editData['name'] = eventData['name'];
      this.editData['validity'] = eventData['validity'];
      this.accessKeyId = eventData['id'];
      break;
      case 'generate': data = {
        title : 'CREATE_ACCESS_KEY',
        body : 'generate'
      };
      if (!this.multipleKeys) {
        this.configureEmails();
      }
      break;
      case 'managerial':
      this.dataSource.forEach( row => {
        if ( row['managerialServices'] ) {
          this.keysList.push(row['id']);
        }
      });
      data = {
        title : 'MANAGERIAL_SERVICE_SUBSCRIPTION',
        body : 'managerial'
      };
    }
    this._matDialog.open(content, { maxHeight: '150vh', minWidth: '30vw', disableClose: true, data: data});
  }

  public determineColumnSize(columnName) {
    if (columnName === 'accessKey') {
      return 'large-column';
    } else if (columnName === 'name') {
      return 'small-column';
    } else {
      return 'medium-column';
    }
  }

  public editAccessKey(event) {
    let duplicate = false;
    this.dataSource.forEach( row => {
      if (event.name === row.name) {
        duplicate = true;
      }
    });
      let action;
      if (event.validity !== this.editData['validity']) {
        action = 'RESET';
      } else if (event.name !== this.editData['name']) {
        action = 'KEYNAMECHANGE';
      } else if ( event.name !== this.editData['name'] && !duplicate ) {
        this.responseHandlerService.returnToastMessage('warning', 'DUPLICATE_KEY_NAME');
      }
      if (action) {
        this.solutionAdminAccesskeyLifeCycleControllerService.regenerateAccessKeyForSolutionType({
          accessKeyId: this.accessKeyId, expiryDuration: event.validity, action: action,
            keyName: event.name
        })
          .subscribe(resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'ACCESS_KEY_REGENERATION_SUCCESS');
            this.getAccessKey();
          }, err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          });
      }
      this._matDialog.closeAll();
  }

  private createEmailFormControl() {
    return this.formBuilder.control('', [Validators.required,
    Validators.pattern('^[a-z0-9A-Z]+(\\.[_a-z0-9A-Z]+)*@[a-z0-9-A-Z]+(\\.[a-z0-9-A-Z]+)*(\\.[a-zA-Z]{2,15})$')]);
  }

  public addEmailControl() {
    const emailControl = this.createEmailFormControl();
    (<UntypedFormArray>this.generateForm.get('emailList')).push(emailControl);
  }

  public removeEmail(index: number) {
    (<UntypedFormArray>this.generateForm.get('emailList')).removeAt(index);
  }

  public resetEmailControls() {
    this.generateForm.reset();
    while (this.generateForm.get('emailList')['controls'].length !== 0) {
      this.removeEmail(0);
    }
    this.addEmailControl();
  }

  public configureEmails() {
    if ( this.generateForm.status === 'VALID' ) {
      this.solutionAdminAccesskeyLifeCycleControllerService.configureExpiryEmailForAccesskey({
        solutionTypeId: this.solutionTypeId,
        body: this.generateForm.value.emailList
      }).subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'EMAIL_CONFIGURED_SUCCESFULLY');
      }, err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
    }
  }

  public getExpiryEmails() {
    this.solutionAdminAccesskeyLifeCycleControllerService.getExpiryEmailForAccesskey({
      solutionTypeId: this.solutionTypeId
    }).subscribe( resp => {
      resp.forEach( (id, index) => {
        if ( index > 0) {
          this.addEmailControl();
        }
      });
      this.generateForm.get('emailList').setValue(resp);
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public onClose() {
    this._matDialog.closeAll();
  }

}
