<!--
  ~ Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
-->
<div fxLayout="row wrap" fxLayoutAlign="center center" class="footer-div">
    <div fxFlex="100%">
            <div class="footer-extension">
                <div class="footer-sections">
                        <ul class="useful-links">
                            <li class="footer-items"><a class="footer-atag" [href]="brandStoreUrl" target="_blank">{{'BRAND_STORE' |
                                    translate}}</a></li>
                            <li class="footer-items"><a class="footer-atag" [matMenuTriggerFor]="menu">{{'PRODUCTS_AND_SERVICES' |
                                    translate}}</a></li>
                            <mat-menu #menu="matMenu">
                                <li class="footer-sub-items"><a class="footer-atag" [href]="infraServicesUrl" target="_blank">{{'INFRA_SERVICES'
                                        |
                                        translate}}</a></li> 
                                <li class="footer-sub-items"><a class="footer-atag" [href]="softwareServicesUrl"
                                        target="_blank">{{'SOFTWARE_SERVICES' |
                                        translate}}</a></li>
                                <!-- <li class="footer-sub-items"><a class="footer-atag" [href]="peripheralServicesUrl" 
                                        target="_blank">{{'PERIPHERAL_SERVICES' |
                                        translate}}</a></li> -->
                            </mat-menu>
                            <li class="footer-items"><a class="footer-atag" [href]="salesContactUrl" target="_blank">{{'SALES_CONTACT' |
                                    translate}}</a></li>
                            <!-- <li class="footer-items"><a class="footer-atag" [href]="salesQueryUrl" target="_blank" >{{'SALES_QUERY' |
                                    translate}}</a></li> -->
                        </ul>
                    </div>
            </div>
            <span class="copy-rights">&copy; {{ 'COPYRIGHT_BOSCH' | translate }}</span>
            <ul class="footer-ul">
                <li *ngFor="let opt of footerOptions; let index = index" class={{opt.li_class}}>

                    <a *ngIf="opt.option_label !== 'PRIVACY_SETTINGS'" routerLink="/terms-and-conditions"
                        [queryParams]="{tabindex: index}" class={{opt.atag_class}} target="_blank">{{ opt.option_label |
                        translate }}</a>
                    <a *ngIf="opt.option_label === 'PRIVACY_SETTINGS'" (click)="onPrivacyClick()"
                        class={{opt.atag_class}}>{{ opt.option_label | translate }}</a>
                </li>
            </ul>
        <div class="tab-footer" fxHide>
            <ul class="tab-footer-ul">
                <li *ngFor="let opt of tabFooterOptions; let index = index" class={{opt.li_class}}>

                    <a *ngIf="opt.option_label !== 'PRIVACY_SETTINGS'" routerLink="/terms-and-conditions"
                        [queryParams]="{tabindex: index}" class={{opt.atag_class}} target="_blank">{{ opt.option_label |
                        translate }}</a>
                    <a *ngIf="opt.option_label === 'PRIVACY_SETTINGS'" (click)="onPrivacyClick()"
                        class={{opt.atag_class}}>{{ opt.option_label | translate }}</a>
                </li>
            </ul>
            <span class="tab-copy-rights">&copy; {{ 'COPYRIGHT_BOSCH' | translate }}</span>
            <!-- <span class="tab-footer-title">{{ 'FOOTER_PJMS' | translate }}</span> -->
        </div>
        <div class="rb-ribbon footer-rib">
        </div>
    </div>
</div>