/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AwsC2DResponseDto } from '../models/aws-c-2-d-response-dto';
import { MethodInvokeDto } from '../models/method-invoke-dto';
import { MethodResultDto } from '../models/method-result-dto';

@Injectable({
  providedIn: 'root',
})
export class MethodControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation provideHealthStatus
   */
  static readonly ProvideHealthStatusPath = '/api/v1/health';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `provideHealthStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  provideHealthStatus$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MethodControllerService.ProvideHealthStatusPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `provideHealthStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  provideHealthStatus(params?: {
  }): Observable<string> {

    return this.provideHealthStatus$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation sendMessage
   */
  static readonly SendMessagePath = '/api/v1/device/{id}/message';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMessage$Response(params: {
    id: string;
    body?: string
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MethodControllerService.SendMessagePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `sendMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendMessage(params: {
    id: string;
    body?: string
  }): Observable<void> {

    return this.sendMessage$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation findAwsC2DResponseForDevice
   */
  static readonly FindAwsC2DResponseForDevicePath = '/api/v1/device/{id}/messageDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAwsC2DResponseForDevice()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAwsC2DResponseForDevice$Response(params: {
    id: string;
    requestId?: string;
  }): Observable<StrictHttpResponse<Array<AwsC2DResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MethodControllerService.FindAwsC2DResponseForDevicePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('requestId', params.requestId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AwsC2DResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAwsC2DResponseForDevice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAwsC2DResponseForDevice(params: {
    id: string;
    requestId?: string;
  }): Observable<Array<AwsC2DResponseDto>> {

    return this.findAwsC2DResponseForDevice$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AwsC2DResponseDto>>) => r.body as Array<AwsC2DResponseDto>)
    );
  }

  /**
   * Path part for operation invokeAppCommand
   */
  static readonly InvokeAppCommandPath = '/api/v1/device/{id}/command';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invokeAppCommand()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeAppCommand$Response(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<StrictHttpResponse<MethodResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, MethodControllerService.InvokeAppCommandPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MethodResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `invokeAppCommand$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeAppCommand(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<MethodResultDto> {

    return this.invokeAppCommand$Response(params).pipe(
      map((r: StrictHttpResponse<MethodResultDto>) => r.body as MethodResultDto)
    );
  }

  /**
   * Path part for operation invokeAppCommandStatus
   */
  static readonly InvokeAppCommandStatusPath = '/api/v1/device/{id}/commandStatus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invokeAppCommandStatus()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeAppCommandStatus$Response(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<StrictHttpResponse<MethodResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, MethodControllerService.InvokeAppCommandStatusPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MethodResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `invokeAppCommandStatus$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeAppCommandStatus(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<MethodResultDto> {

    return this.invokeAppCommandStatus$Response(params).pipe(
      map((r: StrictHttpResponse<MethodResultDto>) => r.body as MethodResultDto)
    );
  }

  /**
   * Path part for operation invokeAppMethod
   */
  static readonly InvokeAppMethodPath = '/api/v1/device/{id}/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invokeAppMethod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeAppMethod$Response(params: {
    id: string;
    async?: boolean;
    body?: MethodInvokeDto;
    header: string;
  }): Observable<StrictHttpResponse<MethodResultDto>> {
    const rb = new RequestBuilder(this.rootUrl, MethodControllerService.InvokeAppMethodPath, 'patch');
    if (params) {
      rb.header('accessKey', params.header);
      rb.path('id', params.id, {});
      rb.query('async', params.async, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MethodResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `invokeAppMethod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeAppMethod(params: {
    id: string;
    async?: boolean;
    body?: MethodInvokeDto,
    header: string
  }): Observable<MethodResultDto> {

    return this.invokeAppMethod$Response(params).pipe(
      map((r: StrictHttpResponse<MethodResultDto>) => r.body as MethodResultDto)
    );
  }

  /**
   * Path part for operation updateResponse
   */
  static readonly UpdateResponsePath = '/api/v1/messageResponse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateResponse()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateResponse$Response(params?: {
    body?: string
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MethodControllerService.UpdateResponsePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateResponse$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateResponse(params?: {
    body?: string
  }): Observable<void> {

    return this.updateResponse$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
