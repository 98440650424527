import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BciLoaderService } from '@bci-web-core/core';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TranslationService } from 'src/app/providers/translation-service';
import { GeneralConfigurationControllerService, OrderTrackingControllerService, OrderControllerService, SubscriptionControllerService,
  InfrastructureConfigurationControllerService, GMapBillingControllerService } from 'src/app/services/Billing/services';
import { AccountControllerService, BusinessProfileControllerService, TenantControllerService } from 'src/app/services/Platform/services';
import { EDIT_IMAGE, INFRASTRUCTURE_ICON, CLOCK_24_7_ICON, LOCK_OPEN_ICON, UPLOAD_ICON, STANDBY_ICON,
  DOWNLOAD_ICON, LOCK_CLOSE_ICON, MORE_OPTION, RIGHT_ARROW_ICON, PERIPHERAL_ICON, BILLING_LIST_ICON,
  OPERATION_CONFIGURATION_ICON, REVIEW_OK_ICON, CI_CHARGES_ICON, CONVERSION_ICON, SG_AND_A_ICON, OT_RT_ICON,
  REVIEW_NOT_OK_ICON, APPROVE_ICON, CURRENCY_CHARGES_ICON, SAAS_CHARGES_ICON, PERIPHERAL_CHARGES_ICON,
  WORKFLOW_DEFAULT_ICON, SAAS_ICON, ACTIVITY_LOG } from 'src/app/shared/constants/strings';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { ShowHistoryDailogComponent } from 'src/app/shared/show-history-dialog/show-history-dialog.component';
@Component({
  selector: 'app-operation-configuration',
  templateUrl: './operation-configuration.component.html',
  styleUrls: ['./operation-configuration.component.scss']
})
export class OperationConfigurationComponent implements OnInit {
  public dropDownValues = [];
  public showSpinner :any;
  public columns: any[];
  public ignoreList = ['id', 'profileId', 'url', 'lock', 'reviewable', 'orderTrackingId', 'modified',
  'consumptionMetaDataId', 'year', 'month', 'active', 'consumptionUnit', 'consumptionDataType', 'sentEmail',
  'sentToMss', 'fromCurrency', 'supportType', 'currencyType', 'prediscountAmount', 'isGmapsService', 'gmapMetaDataId'];
  public matTabOptions = [];
  public currentTabValue;
  public infraDataSource;
  public businessProfileDropdownList = [];
  public infrastructureDataSource; infrastructureList = [];
  public profileId: string; orderId: string;
  public jsonFileLoaded: string;
  public formData;
  public action: string;
  public infrastructureImage; editImage;
  public subscriptionDataSource; subscriptionList = []; subscriptionDropdownList = [];
  public uploadIcon; downloadIcon;
  public reviewOKIcon; reviewNotOkIcon;
  public lockOpenIcon; lockCloseIcon;
  public standByIcon;
  public clockIcon;
  public orderIdForUploadFile: string;
  public files: any[];
  public fileForUpload;
  public moreOptionsImage;
  public reviewComments: string;
  public reviewType: string;
  public billingList = []; billingListDataSource; billingOrderStatus: string;
  public workflowExecutionStatus = [];
  public workflowExecutionIcon; workflowExecutionFailedIcon; rightArrowIcon;
  public orderTrackingId: string;
  public peripheralImage; billingListImage;
  public peripheralOrderServices = []; peripheralOrderDataSource;
  public showPmoTextBox = false; showNoOfPipelinesTextBox = false;
  public peripheralGridForm: UntypedFormGroup;
  public integer_Pattern; float_Pattern;
  public operationConfigurationIcon;
  public Ok_Icon;
  public activityLogIcon;
  public billingListPeriod: string;
  public Billing_WorkFlowIcons = {};
  public saasOrdersList = [];
  public saasOrderDataSource;
  public saasImage;
  public serviceType;
  public mongoServiceList;
  public mongoServiceDataSource;
  public mongoServiceColumns;
  public mongoSubscriptionList;
  public infraType;
  public activityLogDataSource;


  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;

  constructor(private accountControllerService: AccountControllerService,
    private tenantService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService,
    private orderControllerService: OrderControllerService,
    private subscriptionControllerService: SubscriptionControllerService,
    private httpService: HttpClient, private _matDialog: MatDialog,
    private translate: TranslationService,
    private orderTrackingControllerService: OrderTrackingControllerService,
    private objectToIdConversionService: ObjectToIdConversionService,
    private formBuilder: UntypedFormBuilder, private infrastructureConfigurationControllerService: InfrastructureConfigurationControllerService,
    private generalConfigurationControllerService: GeneralConfigurationControllerService,
    private datePipe: DatePipe, private gMapBillingControllerService: GMapBillingControllerService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.matTabOptions.push({ 'label': 'CONFIGURE_SUBSCRIPTION', 'value': 'configureSubscription' });
    this.matTabOptions.push({ 'label': 'MANAGE_SUBSCRIPTION', 'value': 'manageSubscription' });
    this.getBusinessProfileDropdowns('./assets/form-fields/operation-configuration.json');
    this.currentTabValue = 'configureSubscription';
    this.infrastructureImage = INFRASTRUCTURE_ICON;
    this.editImage = EDIT_IMAGE;
    this.uploadIcon = UPLOAD_ICON;
    this.downloadIcon = DOWNLOAD_ICON;
    this.reviewOKIcon = REVIEW_OK_ICON;
    this.reviewNotOkIcon = REVIEW_NOT_OK_ICON;
    this.lockOpenIcon = LOCK_OPEN_ICON;
    this.standByIcon = CLOCK_24_7_ICON;
    this.clockIcon = STANDBY_ICON;
    this.lockCloseIcon = LOCK_CLOSE_ICON;
    this.moreOptionsImage = MORE_OPTION;
    this.rightArrowIcon = RIGHT_ARROW_ICON;
    this.peripheralImage = PERIPHERAL_ICON;
    this.saasImage = SAAS_ICON;
    this.billingListImage = BILLING_LIST_ICON;
    this.operationConfigurationIcon = OPERATION_CONFIGURATION_ICON;
    this.activityLogIcon = ACTIVITY_LOG;
    this.peripheralGridForm = this.formBuilder.group({});
    this.integer_Pattern = '^[0-9]*$';
    this.float_Pattern = '^(\\d{0,2}(\\.\\d{1,2})?|99.99(\\.00?)?|100)$';
    this.Ok_Icon = APPROVE_ICON;
    this.getCurrencyTypes();
    this.Billing_WorkFlowIcons = {
      'CI-Charges': CI_CHARGES_ICON,
      'Forex-Conversion': CONVERSION_ICON,
      'SG&A': SG_AND_A_ICON,
      'OT-RT': OT_RT_ICON,
      'From Currency Charges': CURRENCY_CHARGES_ICON,
      'SaaS Charges': SAAS_CHARGES_ICON,
      'Peripheral Charges': PERIPHERAL_CHARGES_ICON,
      'Default-Icon': WORKFLOW_DEFAULT_ICON
    };
  }

  public matChange(event) {
    this.clearDataOnPanelChange();
    this.currentTabValue = this.matTabOptions[event.index].value;
    this.getBusinessProfileDropdowns('./assets/form-fields/operation-configuration.json');
  }

  public onAccordionExpansion(index) {
    this.clearDataOnPanelChange();
    this.getBusinessProfileDropdowns('./assets/form-fields/operation-configuration.json');
  }

  clearDataOnPanelChange() {
    this.businessProfileDropdownList = [];
    this.infraDataSource = null;
    this.mongoServiceDataSource = null;
    this.mongoServiceList = null;
    this.mongoServiceColumns = null;
    this.mongoSubscriptionList = null;
    this.columns = null;
    this.infrastructureList = null;
    this.subscriptionList = null;
    this.billingList = [];
    this.billingListDataSource = null;
    this.workflowExecutionStatus = [];
    this.peripheralOrderServices = [];
    this.peripheralOrderDataSource = null;
    this.saasOrdersList = [];
    this.saasOrderDataSource = null;
    this.billingListPeriod = null;
    this.profileId = null;
  }

  public updateDropdownValues($event, dropdownSelected?, panelSelected?) {
    if ($event.value !== undefined) {
      if (!dropdownSelected) { dropdownSelected = panelSelected; }
      if (dropdownSelected === 'tenant') {
        this.getTenant($event.value);
      }
      if (dropdownSelected === 'businessProfile') {
        this.getProfile($event.value);
      }
      if (dropdownSelected === 'infraStructure' && this.currentTabValue === 'configureSubscription') {
        this.getInfrastructureOrders($event.value);
        this.getMongoInfrastructureOrders($event.value);
      }
      if (dropdownSelected === 'infraStructure' && this.currentTabValue === 'manageSubscription') {
        this.getSubscriptionList($event.value);
        this.getMongoSubscriptionList($event.value);
      }
      if (dropdownSelected === 'billingList') {
        this.getBillingList($event.value);
      }
      if (dropdownSelected === 'peripheralServices') {
        this.getPeripheralOrders($event.value);
      }
      if (dropdownSelected === 'saasServices') {
        this.getSaaSOrderList($event.value);
      }
    }
  }

  public getAccount() {
    this.accountControllerService
      .findAllAccounts({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.dropDownValues['account'] = resp;
          } else {
            this.dropDownValues['account'] = [];
          }
        },
        err => {
          this.dropDownValues['account'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getTenant(accountId: string) {
    this.tenantService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        err => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        err => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getCurrencyTypes() {
    this.generalConfigurationControllerService
      .findAllCurrencyTypes()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['fromCurrency'] = resp;

          } else {
            this.dropDownValues['fromCurrency'] = [];
          }
        },
        err => {
          this.dropDownValues['fromCurrency'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  getBusinessProfileDropdowns(filePath) {
    this.httpService
      .get(filePath).subscribe(data => {
        this.businessProfileDropdownList = data as string[];
        this.businessProfileDropdownList = this.businessProfileDropdownList['fields'];
      });
    this.getAccount();
  }

  public getInfrastructureOrders(profileId: string) {
    this.profileId = profileId;
    this.showSpinner = this.loaderService.showProgressBar();
    this.orderControllerService.getAllInfrastructureByProfileId({ profileId: profileId })
      .subscribe(resp => {
        if (resp && resp['length']) {
          this.loaderService.hideProgressBar(this.showSpinner);
          const infraOrders: any[] = [];
          resp.forEach(infraOrder => {
            infraOrders.push({
              'orderId': infraOrder['orderId'],
              'subscriptionId': infraOrder['subscriptionId'] ? infraOrder['subscriptionId'] : null,
              'subscriptionName': infraOrder['subscriptionName'] ? infraOrder['subscriptionName'] : null,
              'infraService': this.objectToIdConversionService.convertObjectToName(infraOrder['infraService']),
              'fromCurrencyType': this.objectToIdConversionService.convertObjectToName(infraOrder['fromCurrency']),
              'fromCurrency': this.objectToIdConversionService.convertIdToObject(infraOrder['fromCurrency']),
              'action': null
            });
          });
          this.infrastructureList = infraOrders;
          this.infraDataSource = new MatTableDataSource<any>(this.infrastructureList);
          this.columns = this.filterColumns(Object.keys(this.infrastructureList[0]));
        } else {
          this.infraDataSource = null;
          this.columns = null;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', 'NO_DATA_AVAILABLE_CLOUD_INFRA');
        }
      },
        err => {
          this.infraDataSource = null;
          this.columns = null;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public openSubscriptionDialog(content, dataName, action) {
    this.jsonFileLoaded = 'add-subscription';
    this.formData = dataName;
    this.orderId = dataName['orderId'];
    this.action = action;
    this._matDialog.open(content, { maxHeight: '80vh', minWidth: '30vw', disableClose: true, data: null });
  }

  public addSubscription() {
    const form = this.dynamicForm.createForm.value;
    if (form.hasOwnProperty('subscriptionId')) {
      if (!form.subscriptionId || !form.subscriptionName || !form.fromCurrency) {
        this.responseHandlerService.returnToastMessage('error', 'PLEASE_ADD_SUBSCRIPTION');
        return;
      }
      const bodyJSON = {
        'orderId': this.orderId,
        'subscriptionId': form.subscriptionId ? form.subscriptionId : '',
        'subscriptionName': form.subscriptionName ? form.subscriptionName : '',
        'fromCurrencyId': form.fromCurrency
      };
      this.createOrUpdateSubscription(bodyJSON);
    } else {
      if (!form.organizationId || !form.organizationName ) {
        this.responseHandlerService.returnToastMessage('error', 'PLEASE_ADD_SUBSCRIPTION');
        return;
      }
        const bodyJSON = {
          'orderId': this.orderId,
          'organizationId': form.organizationId,
          'organizationName': form.organizationName,
        };
        this.createOrUpdateMongoSubscription(bodyJSON);
    }
      }


  public createOrUpdateSubscription(bodyJSON: any) {
    this.showSpinner = this.loaderService.showProgressBar();
    let httpClient = this.infrastructureConfigurationControllerService.addSubscriptionDetails({ body: bodyJSON });
    if (this.action === 'edit') {
      httpClient = this.infrastructureConfigurationControllerService.editSubscription({ body: bodyJSON });
    }
    httpClient.subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', this.action === 'add'
        ? 'SUBSCRIPTION_ADD_SUCCESS' : 'SUBSCRIPTION_UPDATE_SUCCESS');
        this.getInfrastructureOrders(this.profileId);
        this.onClose();
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  public getMongoInfrastructureOrders(profileId: string) {
    this.orderControllerService.getAllInfrastructureMongoByProfileId({ profileId: profileId })
    .subscribe(resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        const mongoServiceOrders: any[] = [];
        resp.forEach((mongoServiceOrder) => {
          mongoServiceOrders.push({
            'orderId': mongoServiceOrder['orderId'],
            'organizationId': mongoServiceOrder['organaizationId'] ? mongoServiceOrder['organaizationId'] : null,
            'organizationName': mongoServiceOrder['organaizationName'] ? mongoServiceOrder['organaizationName'] : null,
            'infraService': this.objectToIdConversionService.convertObjectToName(mongoServiceOrder['infraService']),
            'action': null
          });
        });

        this.mongoServiceList = mongoServiceOrders;
        this.mongoServiceDataSource = new MatTableDataSource<any>(this.mongoServiceList);
        this.mongoServiceColumns = this.filterColumns(Object.keys(this.mongoServiceList[0]));
      } else {
        this.mongoServiceDataSource = null;
        this.mongoServiceColumns = null;
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', 'NO_DATA_AVAILABLE_MONGO_SERVICE');
      }
    },
      err => {
        this.mongoServiceDataSource = null;
        this.mongoServiceColumns = null;
        setTimeout(() => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }, 1000);
      }
    );
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  public onClose() {
    this._matDialog.closeAll();
    if (this.subscriptionList && this.subscriptionList.length) {
      this.fileForUpload = null;
    }
  }

  public getSubscriptionList(profileId: string) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.profileId = profileId;
    const monthAndYear = this.getPreviousMonthAndYear();
    const params = {
      profileId: profileId,
      year: monthAndYear[0],
      month: monthAndYear[1]
    };
    this.subscriptionControllerService.getSubscriptionListByProfileId1(params)
      .subscribe(resp => {
        if (resp && resp['length']) {
          this.loaderService.hideProgressBar(this.showSpinner);
          const subscriptions: any[] = [];
          resp.forEach(subscription => {
            subscriptions.push({
              'id': subscription['id'],
              'orderId': subscription['orderId'],
              'subscriptionId': subscription['subscriptionId'],
              'subscriptionName': subscription['subscriptionName'],
              'infraService': this.objectToIdConversionService.convertObjectToName(subscription['infraService']),
              'status': this.translate.translateErrorMessages(subscription['status']),
              'active': subscription['active'],
              'lock': subscription['lock'],
              'reviewable': subscription['reviewable'],
              'url': subscription['url'],
              'uploadedBy': subscription['uploadedBy'],
              'uploadedDate': subscription['uploadedDt'] ? this.changeDateFormat(subscription['uploadedDt'].substring(0, 16)) : '',
              'actions': null
            });
          });
          this.subscriptionList = subscriptions;
          this.infraDataSource = new MatTableDataSource<any>(this.subscriptionList);
          this.columns = this.filterColumns(Object.keys(this.subscriptionList[0]));
        } else {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.infraDataSource = null;
          this.columns = null;
          this.responseHandlerService.returnToastMessage('error', 'NO_DATA_AVAILABLE_CLOUD_INFRA');
        }
      },
        err => {
          this.infraDataSource = null;
          this.columns = null;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getMongoSubscriptionList(profileId: string) {
    this.profileId = profileId;
    const monthAndYear = this.getPreviousMonthAndYear();
    const params = {
      profileId: profileId,
      year: monthAndYear[0],
      month: monthAndYear[1]
    };
      this.infrastructureConfigurationControllerService.getSubscriptionListByProfileId(params)
      .subscribe(resp => {
        if (resp && resp['length']) {
          this.loaderService.hideProgressBar(this.showSpinner);
          const mongoSubscriptions: any[] = [];
          resp.forEach(mongoSubscription => {
            mongoSubscriptions.push({
              'id': mongoSubscription['id'],
              'orderId': mongoSubscription['orderId'],
              'organizationId': mongoSubscription['organizationId'],
              'organizationName': mongoSubscription['organizationName'],
              'infraService': this.objectToIdConversionService.convertObjectToName(mongoSubscription['infraService']),
              'status': this.translate.translateErrorMessages(mongoSubscription['status']),
              'active': mongoSubscription['active'],
              'lock': mongoSubscription['lock'],
              'reviewable': mongoSubscription['reviewable'],
              'url': mongoSubscription['url'],
              'uploadedBy': mongoSubscription['uploadedBy'],
              'uploadedDate': mongoSubscription['uploadedDt'] ?
               this.changeDateFormat(mongoSubscription['uploadedDt'].substring(0, 16)) : '',
              'actions': null
            });

          });
          this.mongoSubscriptionList = mongoSubscriptions;
          this.mongoServiceDataSource = new MatTableDataSource<any>(this.mongoSubscriptionList);
          this.mongoServiceColumns = this.filterColumns(Object.keys(this.mongoSubscriptionList[0]));
        } else {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.mongoServiceDataSource = null;
          this.mongoServiceColumns = null;
          this.responseHandlerService.returnToastMessage('error', 'NO_DATA_AVAILABLE_MONGO_SERVICE');
        }
      },
        err => {
          this.mongoServiceDataSource = null;
          this.mongoServiceColumns = null;
          setTimeout(() => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }, 1000);
        }
      );

  }



  public openMongoSubscriptionDialog(content, dataName, action) {
      this.formData = null;
      this.action = null;
      this.orderId = null;
      this.jsonFileLoaded = 'add-mongo-subscription';
    this.orderId = dataName['orderId'];
    this.action = action;
    if (this.action === 'edit') {
      this.formData = {
        organizationId: dataName['organizationId'] ,
        organizationName: dataName['organizationName'],
      };
    }
    this._matDialog.open(content, { maxHeight: '80vh', minWidth: '30vw', disableClose: true, data: null });
  }



  public createOrUpdateMongoSubscription(bodyJSON: any) {
    this.showSpinner = this.loaderService.showProgressBar();
    let httpClient = this.infrastructureConfigurationControllerService.addOrganaisationDetails({ body: bodyJSON });
    if (this.action === 'edit') {
      httpClient = this.infrastructureConfigurationControllerService.editOrganaisationDetails({ body: bodyJSON });
    }
    httpClient.subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success',
         this.action === 'add' ? 'SUBSCRIPTION_ADD_SUCCESS' : 'SUBSCRIPTION_UPDATE_SUCCESS');
        this.getMongoInfrastructureOrders(this.profileId);
        this.onClose();
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }





  public openModal(content, orderId, infraType) {
    this.infraType = infraType;
    this.orderIdForUploadFile = orderId;
    this._matDialog.open(content);
  }

  public selectFiles(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.fileForUpload = event.target.files[0];
    }
  }

  public uploadExcelFile() {
    this.showSpinner = this.loaderService.showProgressBar();
    const params = {
      orderId: this.orderIdForUploadFile,
      body: {
        file: this.fileForUpload
      }
    };
    this.subscriptionControllerService.upload(params).subscribe(resp => {
      if (resp) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'FILE_UPLOAD_SUCCESS');
          if (this.infraType === 'cloudInfra') {
            this.getSubscriptionList(this.profileId);
          } else if (this.infraType === 'mongoService') {
            this.getMongoSubscriptionList(this.profileId);
          }
        this.onClose();
      }
    },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  public callInReviewAPI(rowData, serviceType) {
    if (rowData['status'] === 'Uploaded' && rowData['reviewable']) {
      this.showSpinner = this.loaderService.showProgressBar();
      if (serviceType === 'infra') {
        this.subscriptionControllerService.updateStatusUploadedToInReview({ 'subscriptionMetaDataId': rowData['id'] })
          .subscribe(resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            if (rowData.hasOwnProperty('organizationId' || 'organizationName')) {
              this.getMongoSubscriptionList(this.profileId);
            } else {
              this.getSubscriptionList(this.profileId);
            }

          },
            err => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            });
      } else if (serviceType === 'gmap') {
        this.gMapBillingControllerService.gmapBillingStatusInReview({ gmapMetadataId: rowData['gmapMetaDataId'] })
          .subscribe(resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.getSaaSOrderList(this.profileId);
          },
            err => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            });
      }
    }
  }

  public openReviewExcelDialog(reviewContent, data, reviewType, serviceType, infraType) {
    this.infraType = infraType;
    this.orderId = data['orderId'];
    this.reviewType = reviewType;
    this.reviewComments = null;
    this.serviceType = serviceType;
    this._matDialog.open(reviewContent, { maxHeight: '80vh', minWidth: '30vw', disableClose: true, data: null });
  }

  public reviewExcel(serviceType) {
    if (!this.reviewComments && this.reviewType === 'NR') {
      this.responseHandlerService.returnToastMessage('error', 'ADD_REVIEW_COMMENTS');
      return;
    }
    this.onClose();
    this.showSpinner = this.loaderService.showProgressBar();
    const monthAndYear = this.getPreviousMonthAndYear();
    const params = {
      orderId: this.orderId,
      year: monthAndYear[0],
      month: monthAndYear[1],
      comments: this.reviewComments
    };
    let httpClient;
    if (serviceType === 'infra') {
      // R is ReviewOK flow
      if (this.reviewType === 'R') {
        httpClient = this.subscriptionControllerService.reviewOk(params);
      } else if (this.reviewType === 'NR') { // NR is ReviewNotOk flow
        httpClient = this.subscriptionControllerService.reviewNotOk(params);
      }
      httpClient.subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', this.reviewType === 'R' ? 'REVIEW_OK_SUCCESS' : 'REVIEW_NOT_OK_SUCCESS');
        if (this.infraType === 'cloudInfra') {
          this.getSubscriptionList(this.profileId);
        } else if (this.infraType === 'mongoService') {
          this.getMongoSubscriptionList(this.profileId);
        }
      },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    } else if (serviceType === 'gmap') {
      if (this.reviewType === 'R') {
        httpClient = this.gMapBillingControllerService.reviewOk(params);
      }
      if (this.reviewType === 'NR') {
        httpClient = this.gMapBillingControllerService.gmapReviewNotOk(params);
      }
      httpClient.subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', this.reviewType === 'R' ? 'REVIEW_OK_SUCCESS' : 'REVIEW_NOT_OK_SUCCESS');
        this.getSaaSOrderList(this.profileId);
      },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    }
  }

  getPreviousMonthAndYear() {
    const currentDate = new Date();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();
    if(month === 0) {
      month = 12;
      year = year - 1;
    }
    return [year, month];
  }

  public getBillingListByMonth(selectedMonth, datepicker) {
    this.billingListPeriod = this.datePipe.transform(selectedMonth, 'yyyy-MM');
    datepicker.close();
    if (this.profileId) {
      this.getBillingList(this.profileId);
    }
  }
  public getBillingList(profileId: string) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.profileId = profileId;
    const monthAndYear = this.getPreviousMonthAndYear();
    const params = {
      profileId: profileId,
      year: monthAndYear[0],
      month: monthAndYear[1]
    };
    if (this.billingListPeriod) {
      const billingMonthAndYear = this.billingListPeriod.split('-');
      params.year = parseInt(billingMonthAndYear[0], 10);
      params.month = parseInt(billingMonthAndYear[1], 10);
    }
    this.orderTrackingControllerService.getBillingList(params).subscribe(resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        resp.forEach(billing => {
          this.billingList.push({
            'orderId': billing['orderId'],
            'orderTrackingId': billing['orderTrackingId'],
            'serviceType': billing['serviceType'],
            'serviceName': billing['displayName'],
            'currencyType': billing['currencyType'],
            'prediscountAmount': billing['prediscountAmount'],
            'reviewStatus': billing['reviewStatus'] ? this.translate.translateErrorMessages(billing['reviewStatus']) : '',
            'status': billing['status'],
            'sentToMss': billing['sentToMss']
          });
        });
        this.billingListDataSource = new MatTableDataSource<any>(this.billingList);
        this.columns = this.filterColumns(Object.keys(this.billingList[0]));
        this.billingOrderStatus = null;
        this.workflowExecutionStatus = [];
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    },
      err => {
        this.billingList = [];
        this.billingListDataSource = null;
        this.columns = null;
        this.workflowExecutionStatus = [];
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  public getWorkflowExecutionStatus(rowData) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.workflowExecutionStatus = [];
    this.orderTrackingId = rowData['orderTrackingId'];
    this.billingOrderStatus = rowData['status'];
    this.orderTrackingControllerService.getWorkflowExecutionStatus({ orderTrackingId: rowData['orderTrackingId'] })
      .subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        if (resp && resp['length']) {
          this.workflowExecutionStatus = resp;
          this.workflowExecutionStatus.forEach(workflow => {
            workflow['workflowStepIcon'] = this.Billing_WorkFlowIcons[workflow['workflowStepName']];
            if (!workflow['workflowStepIcon']) {
              workflow['workflowStepIcon'] = this.Billing_WorkFlowIcons['Default-Icon'];
            }
          });
        } else {
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
        }
      },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public retryWorkflow() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.orderTrackingControllerService.executeWorkflowSteps({ 'orderTrackingId': this.orderTrackingId })
      .subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        if (resp && resp['workflowExecutionStatuses'].length) {
          this.billingOrderStatus = resp['status'];
          const retryWorflow = resp['workflowExecutionStatuses'][0];
          this.workflowExecutionStatus.forEach(workflow => {
            if (workflow['workflowStepExecutionId'] === retryWorflow['workflowStepExecutionId']) {
              workflow['retryable'] = retryWorflow['retryable'];
            }
          });
          this.billingList.forEach(billing => {
            if (billing['orderTrackingId'] === this.orderTrackingId) {
              billing['status'] = this.billingOrderStatus;
            }
          });
          this.responseHandlerService.returnToastMessage('success', 'WORKFLOW_RETRY_INITIATED');
        } else {
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
        }
      },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getPeripheralOrders(profileId: string) {
    this.profileId = profileId;
    this.showSpinner = this.loaderService.showProgressBar();
    this.orderControllerService.getAllPerpheralByProfileId({ profileId: profileId }).subscribe(resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.peripheralOrderServices = resp;
        this.peripheralOrderDataSource = new MatTableDataSource<any>(this.peripheralOrderServices);
        this.peripheralOrderServices.forEach(peripheral => {
          peripheral['peripheralService'] = this.objectToIdConversionService.convertObjectToName(peripheral['peripheralService']);
          this.peripheralGridForm.addControl(peripheral['orderId'], this.formBuilder.control(''));
          this.peripheralGridForm.controls[peripheral['orderId']].setValue(peripheral['consumptionData']);
          peripheral['consumptionData'] =  peripheral['consumptionData'] ? peripheral['consumptionData'] : '';
        });
        this.columns = this.filterColumns(Object.keys(this.peripheralOrderServices[0]));
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    },
      err => {
        this.peripheralOrderServices = [];
        this.peripheralOrderDataSource = null;
        this.columns = null;
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  public addValuetoGrid(rowData: any) {
    const orderId = rowData['orderId'];
    const formValue = this.peripheralGridForm.value;
    if (formValue[orderId]) {
      this.peripheralOrderServices.forEach(peripheral => {
        if (peripheral['orderId'] === orderId) {
          if (this.isValidInput(peripheral, orderId)) {
            peripheral['consumptionData'] = formValue[orderId];
            peripheral['modified'] = true;
          }
        }
      });
    }
  }

  isValidInput(peripheral: any, orderId: string) {
    let pattern: string;
    const dataType = peripheral['consumptionDataType'];
    if (dataType === 'INTEGER') {
      pattern = this.integer_Pattern;
    } else if (dataType === 'FLOAT') {
      pattern = this.float_Pattern;
    }
    if (!pattern) { return; }
    const regExp = new RegExp(pattern);
    const form = this.peripheralGridForm;
    form.get(orderId).setValidators([Validators.required, Validators.pattern(regExp)]);
    form.get(orderId).updateValueAndValidity();
    if (form.controls[orderId].errors) {
      peripheral['errorMsg'] = this.translate.translateErrorMessages('INVALID_INPUT');
      return false;
    } else {
      peripheral['errorMsg'] = null;
      return true;
    }
  }

  public savePeripheralConsumptionOrder() {
    if (this.peripheralGridForm.invalid) {
      this.responseHandlerService.returnToastMessage('error', 'PLEASE_CORRECT_THE_ERRORS');
      return;
    }
    this.showSpinner = this.loaderService.showProgressBar();
    const orders: any[] = [];
    this.peripheralOrderServices.forEach(order => {
      if (order['modified']) {
        orders.push({
          'orderId': order['orderId'],
          'peripheralService': this.objectToIdConversionService.convertNameToObject(order['peripheralService']),
          'supportType': this.objectToIdConversionService.convertNameToObject(order['supportType']),
          'modified': order['modified'],
          'year': order['year'],
          'month': order['month'],
          'consumptionMetaDataId': order['consumptionMetaDataId'],
          'consumptionData': order['consumptionData'],
          'consumptionDataType': order['consumptionDataType'],
          'consumptionUnit': order['consumptionUnit']
        });
      }
    });
    this.orderControllerService.savePeripheralConsumptionMetaData1({ profileId: this.profileId, body: orders })
      .subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'UPDATE_SUCCESS');
        this.getPeripheralOrders(this.profileId);
      },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public changeDateFormat(dateTime) {
    if (dateTime) {
      const splitDateTime = dateTime.split('T');
      const splitDate = splitDateTime[0].split('-');
      const time = splitDateTime[1];
      return `${splitDate[2].split(' ')[0]}-${splitDate[1]}-${splitDate[0]} ${time}`;
    }
  }

  public getSaaSOrderList(profileId: string) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.profileId = profileId;
    const monthAndYear = this.getPreviousMonthAndYear();
    const params = {
      profileId: profileId,
      year: monthAndYear[0],
      month: monthAndYear[1]
    };
    this.orderControllerService.getSaasOrderListByProfileId(params)
      .subscribe(resp => {
        if (resp && resp['length']) {
          this.loaderService.hideProgressBar(this.showSpinner);
          const saasOrders: any[] = [];
          resp.forEach(saasOrder => {
            saasOrders.push({
              'orderId': saasOrder['orderId'],
              'saasService': saasOrder['saaSService'],
              'status': saasOrder['status'] ? this.translate.translateErrorMessages(saasOrder['status']) : 'NA',
              'lock': saasOrder['lock'],
              'reviewable': saasOrder['reviewable'],
              'url': saasOrder['url'],
              'isGmapsService': saasOrder['isGmapsService'] ? true : false,
              'gmapMetaDataId': saasOrder['gmapMetaDataId'] ? saasOrder['gmapMetaDataId'] : null,
              'uploadedBy': saasOrder['uploadedBy'] ? saasOrder['uploadedBy'] : 'NA',
              'uploadedDate': saasOrder['uploadedDt'] ? this.changeDateFormat(saasOrder['uploadedDt'].substring(0, 16)) : 'NA',
              'actions': null
            });
          });
          this.saasOrdersList = saasOrders;
          this.saasOrderDataSource = new MatTableDataSource<any>(this.saasOrdersList);
          this.columns = this.filterColumns(Object.keys(this.saasOrdersList[0]));
        } else {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
        }
      },
        err => {
          this.saasOrderDataSource = null;
          this.columns = null;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public uploadGMapExcelFile() {
    this.showSpinner = this.loaderService.showProgressBar();
    const params = {
      orderId: this.orderIdForUploadFile,
      body: {
        file: this.fileForUpload
      }
    };
    this.gMapBillingControllerService.upload(params).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', 'FILE_UPLOAD_SUCCESS');
      this.getSaaSOrderList(this.profileId);
      this.onClose();
    },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  public displayErrorLog(content, message) {
    this._matDialog.open(content, {data: message} );
  }

  public resetForm($event) {
    if ($event) {
      this.dynamicForm.createForm.reset();
    }
  }

  public getSubscriptionActivityLog(id) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.subscriptionControllerService.getSubscriptionActivityLogListByMetaDataId({subscriptionMetaDataId: id})
    .subscribe( resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.activityLogDataSource = [];
        resp.forEach(row => {
          this.activityLogDataSource.push({
            'status': row['status'],
            'responseDescription': row['responseDescription'],
            'orderId': row['orderId'],
            'comments': row['comments'] ? row['comments'] : null,
            'modifiedBy': row['createdBy'],
            'modifiedDate': row['createdDt'] ? this.changeDateFormat(row['createdDt'].substring(0, 16)) : '',
          });
        });
        const dialogRef = this._matDialog.open(ShowHistoryDailogComponent, {
          maxWidth: '100%',
          data: {
            title: 'ACTIVITY_LOG',
            dataKey: this.activityLogDataSource,
          }
        });
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getGmapActivityLog(id) {
    this.gMapBillingControllerService.getGmapActivityLogListByMetaDataId({gmapBillingMetadataId: id})
    .subscribe( resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.activityLogDataSource = [];
        resp.forEach(row => {
          this.activityLogDataSource.push({
            'status': row['status'],
            'responseDescription': row['responseDescription'],
            'orderId': row['orderId'],
            'comments': row['comments'] ? row['comments'] : null,
            'modifiedBy': row['createdBy'],
            'modifiedDate': row['createdDt'] ? this.changeDateFormat(row['createdDt'].substring(0, 16)) : '',
          });
        });
        const dialogRef = this._matDialog.open(ShowHistoryDailogComponent, {
          maxWidth: '100%',
          data: {
            title: 'ACTIVITY_LOG',
            dataKey: this.activityLogDataSource,
          }
        });
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }
}
