<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="registerSimIcon" />
        <span>{{'REGISTER_SIM' | translate}}</span>
      </span>
    </div>
    <div class="clear-float"></div>
      <div class="services-wrapper">
        <div fxLayout="row wrap" class="row-wrap">
          <div fxFlex="25%" *ngFor="let type of simRegOptios" class="service-list">
            <mat-card class="service-card">
              <mat-card-header class="service-card-header">
                <div class="service-card-name service-icon">
                  <img class="service-image" [src]="type.imgSrc"/>
                </div>
                <div class="service-card-name">
                  <span class="service-name">{{type.name}}</span>
                </div>
                <div>
                  <mat-label class="service-card-price">{{type.price}}</mat-label>
                </div>
              </mat-card-header>
              <mat-card-content class="service-card-content">
                <p class="message-font">{{type.description | translate}}</p>
                <div class="btn-container">
                  <button class="subscribe-btn" (click)="getRegistrationType(type.value)">
                    {{((type.value ==='withoutVendor')?'SAVE_SIM_DETAILS':'REGISTER_SIM') | translate}}
                  </button>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
          </div>
          </div>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
