/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-popup-overlay',
  templateUrl: './popup-overlay.component.html',
  styleUrls: ['./popup-overlay.component.scss']
})
/**
  * @ngdoc component
  * @name SharedModule.component:PopupOverlay
  *
  *
  * @description
  * description: To create reausable popups
  *
*/
export class PopupOverlayComponent implements OnInit {

  @Input() popupOptions: any;
  checkboxValue: boolean;
  enableSubmitButton = false;

  constructor(public dialogRef: MatDialogRef<PopupOverlayComponent>, private http: HttpClient, private router: Router,
    protected keycloakAngular: KeycloakService, @Inject(MAT_DIALOG_DATA) public data: any) { dialogRef.disableClose = true; }

  ngOnInit() {
    this.checkboxValue = true;
    this.http.get('./assets/footer/' + this.data.json + '.json')
      .subscribe(res => {
        this.popupOptions = res;
      },
        err => {
          console.log('Error in fetching privacy settings json file.');
        });
  }

  /**
    * @ngdoc method
    * @name PopupOverlay#onCloseClick
    *
    * @methodOf
    * SharedModule.controller:PopupOverlay
    *
    * @description
    * Description: on click of the close button ,privacy settings pop up will be closed and the background blur effect will be removed.
    *
    * @param {type} name description
    * @return {type} name description
  */
  onCloseClick(): void {
    this.dialogRef.close();
    document.getElementsByTagName('body')[0].classList.remove('blur-effect');
  }

  /**
    * @ngdoc method
    * @name PopupOverlay#submit
    *
    * @methodOf
    * SharedModule.controller:PopupOverlay
    *
    * @description
    * Description: implementation on click of 'YES' in popup.
    *
    * @param {type} name description
    * @return {type} name description
  */
  submit(): void {
    this.keycloakAngular.logout();
  }

  /**
    * @ngdoc method
    * @name PopupOverlay#changeInCheckboxValue
    *
    * @methodOf
    * SharedModule.controller:PopupOverlay
    *
    * @description
    * Description: used to check or uncheck the checkbox.
    *
    * @param {type} name description
    * @return {type} name description
  */
  changeInCheckboxValue(): void {
    this.enableSubmitButton = true;
  }
}
