import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ota-campaign',
  templateUrl: './ota-campaign.component.html',
  styleUrls: ['./ota-campaign.component.scss']
})
export class OtaCampaignComponent implements OnInit {

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

}
