<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="back-image" (click)="navigateBack()" [src]="backLeftImage" />
        <img class="page-icon-small" [src]="profileIcon" />
        <span>{{'PROFILE_MANAGEMENT_CONFIGURE' |translate}}</span>
      </span>
    </div>
    <mat-accordion class="accordion-style">
      <mat-expansion-panel *ngIf="profileJsonLoaded" class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'BUSINESS_PROFILE_USAGE_CONFIGURATIONS' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <app-dynamic-form [jsonFile]="profileJsonLoaded" [formData]="profileUsageData" [columnsInForm]="'multi'"
            [dropDownValues]="dropDownValues" (formEmitter)="configureProfileUsage($event)">
          </app-dynamic-form>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="otpJsonLoaded" class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'OTP_CONFIGURATIONS' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <app-dynamic-form [jsonFile]="otpJsonLoaded" [formData]="otpData" [columnsInForm]="'multi'"
            [dropDownValues]="dropDownValues" (formEmitter)="configureOTP($event)">
          </app-dynamic-form>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'DATA_RETENTION_CONFIGURATIONS' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <mat-form-field class="select-box-width">
            <mat-label class="label">{{'SELECT_SERVICE' | translate}}</mat-label>
            <mat-select (selectionChange)="getDataRetentionBasedOnService($event.value)">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let service of serviceList" [value]="service.id" required>
                {{service.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <form [formGroup]="dataRetentionForm"> 
        <table class="dataRetentionTable"  *ngIf="dataRetentionEnable" cellspacing="0" cellpadding="10">
          <thead>
              <tr class="headerimage" >
            <th >{{'SOURCE_TABLE'| translate}}</th><th>{{'ARCHIVE_ENABLED'| translate}}<th>{{'ARCHIVE_UPTO'| translate}}</th>
            <th>{{'PURGE_ENABLED'| translate}}</th><th>{{'PURGE_AFTER'| translate}}</th>
              </tr>
          </thead>
          <tbody >
              <tr class="retentiontableRow" *ngFor="let item of dataRetentionControlsData; let rowindex=index" formArrayName="dataRetentionDetails">
              <td [formGroupName]="rowindex"> {{item.sourceTableName}}
               </td>
              <td [formGroupName]="rowindex"> 
                 <mat-checkbox (click)="$event.stopPropagation()" formControlName="archFlag"></mat-checkbox>
               </td>
              <td  [formGroupName]="rowindex"> 
                <mat-checkbox (click)="$event.stopPropagation()" formControlName="isDefaultArchive"  >{{'DEFAULT'|translate }}</mat-checkbox> 
                <input  class="retentioninput" type="number"
                 min="{{item.minArchivalPurgePeriod}}"
                 max ="{{item.maxArchivalPurgePeriod}}"
                 formControlName="archivalPeriod" 
                [readonly]="dataRetentionForm.controls.dataRetentionDetails.value[rowindex].isDefaultArchive"/>
                <span>{{'DAYS'| translate}}</span>   
              </td>
              <td  [formGroupName]="rowindex">  
                 <mat-checkbox (click)="$event.stopPropagation()" formControlName="purgeFlag"></mat-checkbox>
              </td>
              <td  [formGroupName]="rowindex"> 
                 <mat-checkbox (click)="$event.stopPropagation()" formControlName="isDefaultPurge"  >{{'DEFAULT'|translate }}</mat-checkbox> 
                <input  class="retentioninput" type="number" min="{{item.minArchivalPurgePeriod}}" max ="{{item.maxArchivalPurgePeriod}}" formControlName="purgePeriod"
                [readonly]="dataRetentionForm.controls.dataRetentionDetails.value[rowindex].isDefaultPurge" />
                 <span>{{'DAYS'| translate}}</span>   
              </td>
              </tr>
          </tbody>
      </table>
    </form>   
    <section class="section">
      <button mat-raised-button (click)="configureDataRetention()" class="button btn-background" >
        {{'SUBMIT' | translate }}</button>
        <button  (click)="onResetDataRetentionGrid()"mat-button class="button">{{ 'RESET' | translate }}</button>
    </section>
       <div *ngIf="retentionData " class="warning-msg">{{'ARCHIVE_MSG'| translate}}</div>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="smsConfigurationJsonLoaded" class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'SMS_CONFIGURATIONS' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <app-dynamic-form [jsonFile]="smsConfigurationJsonLoaded" [formData]="smsConfigurationData"
            [columnsInForm]="'multi'" [dropDownValues]="dropDownValues" (formEmitter)="configureSms($event)">
          </app-dynamic-form>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="whatsappConfigurationJsonLoaded" class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'WHATSAPP_CONFIGURATIONS' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <app-dynamic-form [jsonFile]="whatsappConfigurationJsonLoaded" [formData]="whatsappConfigurationData"
          [gridLayout]="'grid-container-whatsapp-config'" [dropDownValues]="dropDownValues" [emitValueAfterReset]="false" (valueSelected)="updateWhatsappDropdownValues($event)"
          (formEmitter)="configureWhatsapp($event)"></app-dynamic-form>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="emailConfigurationJsonLoaded" class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'EMAIL_CONFIGURATIONS' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <app-dynamic-form [jsonFile]="emailConfigurationJsonLoaded" [formData]="emailConfigurationData"
            [columnsInForm]="'multi'" [dropDownValues]="dropDownValues"
            (formEmitter)="configureEmailConfiguration($event)">
          </app-dynamic-form>
        </div>
      </mat-expansion-panel>
<!-- Since Bosch_IOT_Suite is being decommissioned, commenting below code segment, which can be reused in future if Bosch_IOT_Suite brought back-->
      <!-- <mat-expansion-panel class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'ROOT_CERTIFICATE_CONFIGURATIONS' | translate }}
            <span class="info-icon">
              <img [src]="infoIconImage" alt="info" [title]="'ROOT_CERTIFICATE_INFO_MSG'  | translate">
            </span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <div class="solution-type-section">
            <mat-form-field class="select-solution-width">
              <mat-label class="label">{{'SOLUTION_TYPE' | translate}}</mat-label>
              <mat-select (selectionChange)="getRootCertificateConfiguration($event.value)"
                [placeholder]="'SELECT' | translate" [(ngModel)]="selectedSolutionType">
                <mat-option>{{ 'SELECT' | translate }}</mat-option>
                <mat-option *ngFor="let solutionType of solutionTypeList" [value]="solutionType.id" required>
                  {{solutionType.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="certificate-section">
            <input type="file" class="select-file" id="file" (change)="selectFiles($event)" />
            <label class="input-label" for="file" [class.disabled]="!selectedSolutionType">{{ 'UPLOAD_FILE' | translate }}</label>
          </div>
          <div class="clear-float"></div>
          <div class="files-list" *ngIf="files && files.message">
            <div class="single-file">
              <div class="file-icon">
                <img [src]="documentImage" />
              </div>
              <div class="info">
                <h4 class="name"> {{ files.message }} </h4>
              </div>
              <div class="delete" (click)="gotoConfirmDialog()">
                <img [src]="deleteImage" class="modify-button">
              </div>
            </div>
          </div>
          <section class="section">
            <button (click)="uploadRootCertificate()" mat-raised-button
              class="button btn-background">{{ 'SUBMIT' | translate }}</button>
            <button (click)="resetCertificateConfigurations()" mat-button
              class="button">{{ 'RESET' | translate }}</button>
          </section>
        </div>
      </mat-expansion-panel> -->

       <mat-expansion-panel  class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'OTA_CONFIGURATION' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
        
           <mat-label class="label">{{"GEOFENCE_CONFIGURATION" | translate}}
          </mat-label>
          <mat-checkbox class="checkbox-width"  [(ngModel)]="geoFenceEnabled" >
          </mat-checkbox>
          <section class="section">
            <button (click)="configureFotaConfiguration()" mat-raised-button 
               class="button-back btn-background">
               {{ 'SUBMIT' | translate }}</button>
            <button  mat-button class="button">
              {{'RESET' | translate }}</button>
        </section>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel  (opened)="storageAccounts()" class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'DYANAMIC_CERTIFICATE_CONFIGURATION' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <div class="geofence-container">
          <div class="geofenceform-row">
          <div class="lable-checkbox">
           <mat-label class="label">{{'DYANAMIC_CERTIFICATE_CONFIGURATION' | translate}}
          </mat-label>
          <mat-checkbox class="checkbox-width" (change)="certificateEnableDisable($event)" [(ngModel)]="dynamicCertificateEnabled" >
          </mat-checkbox>
        </div>
        <div>
          <a *ngIf="dynamicCertificateEnabled && storageAccountId" (click)="getSampleCSVFile()" class="sample-file-link">{{'DOWNLOAD_SAMPLE_FILE' |
            translate
            }}</a>
        </div>
        </div>
        <div class="storage-account-content">
          <div  *ngIf="dynamicCertificateEnabled" class="storgae-dropdown">
            <mat-form-field class="dynamic-form-text-fields" >
              <mat-label class="label storagelable">
              {{"STORAGE_ACCOUNT" | translate}}
              <span class="mandatory">*</span>
              </mat-label>
              <mat-select placeholder="{{'SELECT'| translate}}"  class="dynamic-input" (selectionChange)="getStorageAccount($event.value)">
                   <mat-option *ngFor="let account of storageAccountsList" [value]="account.id" required>
                  {{account.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-error class="mat-error-class" *ngIf="dynamicCertificateSubmit && !dynamicCertificateForm.value.storageAccountId">{{"STORAGE_ACCOUNT_REQUIRED" | translate}}</mat-error>
          </div>
          <div class="file-upload">
            <form [formGroup]="dynamicCertificateForm">
                <div *ngIf="dynamicCertificateEnabled && storageAccountId">
                      <input type="file" accept=".csv"   #inputCertificate class="select-file" id="file"
                        (click)="inputCertificate.value = null"
                        (change)="selectFiles($event)"/>
                      <label class="upload-input-label" for="file">{{ 'UPLOAD_FILE' | translate }}</label>
                      <span *ngIf="dynamicCertificateSubmit && !dynamicCertificateForm.value.excelFile">{{ "UPLOAD_FILE_REQUIRED" | translate}}</span>
                    </div>
                  </form>
                 <div class="file-name">
                      <div class="files-list" *ngIf="dynamicCertificateEnabled && dynamicCertificateForm.value.excelFile">
                        <div class="single-file">
                        <div class="file-icon">
                        <img [src]="documentImage" />
                      </div>
                          <div class="info">
                            <h4 class="name"> {{dynamicCertificateForm.value.excelFile.name}} </h4>
                          </div>
                          <div class="delete" (click)="deleteUploadFile()">
                            <img [src]="deleteImage" class="modify-button">
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
          
         </div>
         <section class="section">
          <button mat-raised-button (click)="uploadDynamicCertificate()" class="button btn-background" >
            {{'SUBMIT' | translate }}</button>
            <button  (click)="onResetDynamicCertificate()"mat-button class="button">{{ 'RESET' | translate }}</button>
        </section>
        <div class="certificates-table-container">
        <table class="dataRetentionTable certificate-table" *ngIf="dynamicCertificates?.length" cellspacing="0" cellpadding="10">
          <thead>
              <tr class="headerimage" >
            <th >{{'FILE_NAME'| translate}}</th>
            <th>{{'DATE'| translate}}</th>
            <th>{{'TIME'| translate}}</th>
            <th>{{'ACTION'| translate}}</th>
              </tr>
          </thead>
          <tbody >
              <tr class="retentiontableRow" *ngFor="let item of dynamicCertificates">
              <td > {{item.fileName}}
               </td>
               <td > {{item.createdDt | date:'yyyy-MM-dd'}}
              </td>
              <td > {{item.createdDt | date:'HH:mm:ss'}}
              </td>
              <td> 
                <div class="delete" (click)="downloadDynamicCertificate(item.id, item.fileName)">
                  <img [src]="downloadImage" class="modify-button">
                </div>
               </td>
                    
              </tr>
          </tbody>
      </table>
    </div>
       </div>
        </div>
      </mat-expansion-panel>


      <mat-expansion-panel *ngIf="demoAccountConfigForm" class="configuration-card">
        <mat-expansion-panel-header class="card-header-style">
          <mat-panel-title class="card-header-label">
            {{ 'DEMO_ACCOUNT_CONFIGURATIONS' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">

            <div class="label label-subHeader">{{ 'MAXIMUM_TRANSACTIONS_LIMIT' | translate }}
            </div>

            <form [formGroup]="demoAccountConfigForm" class="grid-container-demo-account-config">
              <div *ngFor="let service of demoAccountConfigServiceList">
              <mat-form-field class="demo-config-field-width">
                <mat-label class="label">{{ service['label'] | translate}}
                    <span class="mandatory">*</span>
                </mat-label>
                <input class="input-field" type="'text'" matInput [formControlName]="service['id']" 
                     [placeholder]="'TYPE_HERE' | translate" />
            </mat-form-field>

            <div
            *ngIf="demoAccountConfigFormSubmitted || demoAccountConfigForm.controls[service['id']].touched && demoAccountConfigForm.controls[service['id']].errors">
            <div *ngIf="demoAccountConfigForm.controls[service['id']]?.errors?.required">
                <mat-error>
                    {{service['label'] | translate}} {{'IS_REQUIRED' | translate }}
                </mat-error>
            </div>
            <mat-error *ngIf="demoAccountConfigForm.controls[service['id']]?.errors?.pattern">
              {{ 'INVALID_TRANSACTION_LIMIT' | translate }}
            </mat-error>
        </div>


          </div>
           </form>

           <div>
            <section class="section">
              <button mat-raised-button (click)="submitDemoAccountConfiguration()" class="button btn-background" >
                {{'SUBMIT' | translate }}</button>
                <button  (click)="resetDemoAccountConfiguration()"mat-button class="button">{{ 'RESET' | translate }}</button>
            </section>
          </div>  

        </div>
      </mat-expansion-panel>

        </mat-accordion>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>