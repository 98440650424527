/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { Router, ActivatedRoute } from '@angular/router';

export interface AsideCard {
  title: string;
  description: string;
  buttonText: string;
  isSelected: boolean;
  url: string;
  permission: string;
  image: string;
}

@Component({
  selector: 'app-aside-nav-card',
  templateUrl: './aside-nav-card.component.html',
  styleUrls: ['./aside-nav-card.component.scss']
})

/**
  * @ngdoc component
  * @name SharedModule.component:AsideNavCard
  *
  *
  * @description
  * description: To render right side menu
  *
*/
export class AsideNavCardComponent implements OnInit {
  public permissionList: Array<String> = [];
  @Input() asideCard: AsideCard;
  @Output() navigate = new EventEmitter();

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
  }

  /**
    * @ngdoc method
    * @name AsideNavCard#onBtnClick
    *
    * @methodOf
    * SharedModule.controller:AsideNavCard
    *
    * @description
    * Description: to navigate to resp screen based on the path given
    *
    * @param {type} name description: path of the screen to be navigated
    * @return {type} name description
  */
  onBtnClick() {
    this.router.navigate([this.asideCard.url]);
  }

   /**
    * @ngdoc method
    * @name AsideNavCard#checkPermission
    *
    * @methodOf
    * SharedModule.controller:AsideNavCard
    *
    * @description
    * Description: Checks whether the logged in user has permission to perform crud operations.
    *
    * @param {type} name description: required permission name
    * @return {type} name description: boolean value based on available permission
  */
  checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }
}
