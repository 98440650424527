import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { CreateSchedulerComponent } from './create-scheduler/create-scheduler.component';
import { ManageSchedulerComponent } from './manage-scheduler/manage-scheduler.component';

const routes: Routes = [
  {
    path: 'schedulers/create-scheduler',
    component: CreateSchedulerComponent ,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'SCH_C', data: {} }
  },
  {
    path: 'schedulers/manage-scheduler',
    component: ManageSchedulerComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'SCH_R', data: {} }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SchedulerRoutingModule { }
