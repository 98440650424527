/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DashboardReportDto } from '../models/dashboard-report-dto';
import { DashboardResponseDto } from '../models/dashboard-response-dto';
import { TimeIntervalDto } from '../models/time-interval-dto';
import { GmapsDashboardResponseDto } from '../models/gmaps-dashboard-response-dto';
import { GmapsDashboardReportDto } from '../models/gmaps-dashboard-report-dto';

@Injectable({
  providedIn: 'root',
})
export class DashBoardControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllTimeIntervals
   */
  static readonly GetAllTimeIntervalsPath = '/api/v1/timeInterval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllTimeIntervals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTimeIntervals$Response(params?: {
    dashboardType?: string;
  }): Observable<StrictHttpResponse<Array<TimeIntervalDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.GetAllTimeIntervalsPath, 'get');
    if (params) {
      rb.query('dashboardType', params.dashboardType, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TimeIntervalDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllTimeIntervals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllTimeIntervals(params?: {
   dashboardType?: string;
  }): Observable<Array<TimeIntervalDto>> {

    return this.getAllTimeIntervals$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TimeIntervalDto>>) => r.body as Array<TimeIntervalDto>)
    );
  }

  /**
   * Path part for operation dashBoardSmsReport
   */
  static readonly DashBoardSmsReportPath = '/api/v1/dashboard/sms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashBoardSmsReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardSmsReport$Response(params?: {
      body?: DashboardReportDto
  }): Observable<StrictHttpResponse<DashboardResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.DashBoardSmsReportPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashBoardSmsReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardSmsReport(params?: {
      body?: DashboardReportDto
  }): Observable<DashboardResponseDto> {

    return this.dashBoardSmsReport$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardResponseDto>) => r.body as DashboardResponseDto)
    );
  }

  /**
   * Path part for operation dashBoardEmailReport
   */
  static readonly DashBoardEmailReportPath = '/api/v1/dashboard/email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashBoardEmailReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardEmailReport$Response(params?: {
      body?: DashboardReportDto
  }): Observable<StrictHttpResponse<DashboardResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.DashBoardEmailReportPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashBoardEmailReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardEmailReport(params?: {
      body?: DashboardReportDto
  }): Observable<DashboardResponseDto> {

    return this.dashBoardEmailReport$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardResponseDto>) => r.body as DashboardResponseDto)
    );
  }

  /**
   * Path part for operation dashBoardUsersSummaryReport
   */
  static readonly DashBoardUsersSummaryReportPath = '/api/v1/dashboard/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashBoardUsersSummaryReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardUsersSummaryReport$Response(params?: {
      body?: DashboardReportDto
  }): Observable<StrictHttpResponse<DashboardResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.DashBoardUsersSummaryReportPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashBoardUsersSummaryReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardUsersSummaryReport(params?: {
      body?: DashboardReportDto
  }): Observable<DashboardResponseDto> {

    return this.dashBoardUsersSummaryReport$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardResponseDto>) => r.body as DashboardResponseDto)
    );
  }

  /**
   * Path part for operation dashBoardDeviceSummaryReport
   */
  static readonly DashBoardDeviceSummaryReportPath = '/api/v1/dashboard/devices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashBoardDeviceSummaryReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardDeviceSummaryReport$Response(params?: {
      body?: DashboardReportDto
  }): Observable<StrictHttpResponse<DashboardResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.DashBoardDeviceSummaryReportPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashBoardDeviceSummaryReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardDeviceSummaryReport(params?: {
      body?: DashboardReportDto
  }): Observable<DashboardResponseDto> {

    return this.dashBoardDeviceSummaryReport$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardResponseDto>) => r.body as DashboardResponseDto)
    );
  }

  /**
   * Path part for operation dashBoardConfigSummaryReport
   */
  static readonly DashBoardConfigSummaryReportPath = '/api/v1/dashboard/config';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashBoardConfigSummaryReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardConfigSummaryReport$Response(params?: {
      body?: DashboardReportDto
  }): Observable<StrictHttpResponse<DashboardResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.DashBoardConfigSummaryReportPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashBoardConfigSummaryReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardConfigSummaryReport(params?: {
      body?: DashboardReportDto
  }): Observable<DashboardResponseDto> {

    return this.dashBoardConfigSummaryReport$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardResponseDto>) => r.body as DashboardResponseDto)
    );
  }

  /**
   * Path part for operation dashBoardC2DSummaryReport
   */
  static readonly DashBoardC2DSummaryReportPath = '/api/v1/dashboard/c2d';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashBoardC2DSummaryReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardC2DSummaryReport$Response(params?: {
      body?: DashboardReportDto
  }): Observable<StrictHttpResponse<DashboardResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.DashBoardC2DSummaryReportPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashBoardC2DSummaryReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardC2DSummaryReport(params?: {
      body?: DashboardReportDto
  }): Observable<DashboardResponseDto> {

    return this.dashBoardC2DSummaryReport$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardResponseDto>) => r.body as DashboardResponseDto)
    );
  }

  /**
   * Path part for operation dashBoardMapsReport
   */
  static readonly DashBoardMapsReportPath = '/api/v1/dashboard/maps';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashBoardMapsReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardMapsReport$Response(params?: {
      body?: DashboardReportDto
  }): Observable<StrictHttpResponse<DashboardResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.DashBoardMapsReportPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashBoardMapsReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardMapsReport(params?: {
      body?: DashboardReportDto
  }): Observable<DashboardResponseDto> {

    return this.dashBoardMapsReport$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardResponseDto>) => r.body as DashboardResponseDto)
    );
  }

  
  /**
   * Path part for operation dashBoardWhatsappReport
   */
  static readonly DashBoardWhatsappReportPath = '/api/v1/dashboard/whatsapp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dashBoardWhatsappReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardWhatsappReport$Response(params?: {
    body?: DashboardReportDto
  }): Observable<StrictHttpResponse<DashboardResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.DashBoardWhatsappReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `dashBoardWhatsappReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dashBoardWhatsappReport(params?: {
    body?: DashboardReportDto
  }): Observable<DashboardResponseDto> {

    return this.dashBoardWhatsappReport$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardResponseDto>) => r.body as DashboardResponseDto)
    );
  }
/**
   * Path part for operation dashBoardGmapsReport
   */
static readonly DashBoardGmapsReportPath = '/api/v1/dashboard/gmap-service';

/**
 * This method provides access to the full `HttpResponse`, allowing access to response headers.
 * To access only the response body, use `dashBoardGmapsReport()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
dashBoardGmapsReport$Response(params?: {
  body?: GmapsDashboardReportDto
}
): Observable<StrictHttpResponse<GmapsDashboardResponseDto>> {

  const rb = new RequestBuilder(this.rootUrl, DashBoardControllerService.DashBoardGmapsReportPath, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json'
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<GmapsDashboardResponseDto>;
    })
  );
}

/**
 * This method provides access to only to the response body.
 * To access the full response (for headers, for example), `dashBoardGmapsReport$Response()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
dashBoardGmapsReport(params?: {
  body?: GmapsDashboardReportDto
}
): Observable<GmapsDashboardResponseDto> {

  return this.dashBoardGmapsReport$Response(params).pipe(
    map((r: StrictHttpResponse<GmapsDashboardResponseDto>) => r.body as GmapsDashboardResponseDto)
  );
}


}
