/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})

/**
  * @this vm
  * @ngdoc controller
  * @name SharedModule.controller:TermsAndConditions
  *
  * @description
  * description: footer links implementation
*/
export class TermsAndConditionsComponent implements OnInit {

  footerList: any;
  activeTabIndex: any;
  abc: any;
  selected: any;
  constructor(private httpService: HttpClient, private activatedRoute: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.httpService
      .get('./assets/footer/options.json')
      .subscribe(data => {
        this.footerList = data;
        this.footerList.splice(this.footerList.length - 1, 1);
        this.activatedRoute.queryParams.subscribe((urlParams: any) => {
          if (urlParams.tabindex) {
            this.activeTabIndex = (urlParams.tabindex);
            this.selected = new UntypedFormControl(this.activeTabIndex);
          }
        });
      });
  }


  /**
    * @ngdoc method
    * @name TermsAndConditions#switchTab
    *
    * @methodOf
    * SharedModule.controller:TermsAndConditions
    *
    * @description
    * Description: switching of tab in done with clicked event.
    *
    * @param {type} name description: clicked event
    * @return {type} name description
  */
  switchTab(event) {
    this.selected.setValue(event);
    this.activeTabIndex = this.selected.value;
    const queryParams: Params = { tabindex: event };
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }
}
