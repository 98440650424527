/**
 * Copyright © 2021-22 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import { CREATE, CERTIFICATE_ICON } from 'src/app/shared/constants/strings';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { CertificateManagementControllerService } from 'src/app/services/Certificate/services/certificate-management-controller.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DynamicSearchServiceService } from 'src/app/providers/dynamic-search-service.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  BusinessProfileControllerService, TenantControllerService, SolutionTypeControllerService, PlatformAdminUtilityControllerService
} from 'src/app/services/Platform/services';
import { CommonId, GroupSearchtDto } from 'src/app/services/DeviceMgmt/models';
import { SelectionModel } from '@angular/cdk/collections';
import { MANAGE, DELETE_IMAGE, ACTIVE, DOWNLOAD_IMAGE} from 'src/app/shared/constants/strings';
import { TranslationService } from 'src/app/providers/translation-service';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TagsServiceProvider } from 'src/app/providers/tags-service';
import { CertificateMgmtUtilityControllerService } from 'src/app/services/Certificate/services';
import { SearchComponentComponent } from 'src/app/shared/search-component/search-component.component';
import { BciLoaderService } from '@bci-web-core/core';

/**
  * @ngdoc component
  * @name certificate.component:manageCertificates
  *
  *
  * @description
  * description: To manage a root, intermediate and leaf.
  *
  *
*/
@Component({
  selector: 'app-manage-certificates',
  templateUrl: './manage-certificates.component.html',
  styleUrls: ['./manage-certificates.component.scss']
})
export class ManageCertificatesComponent implements OnInit {
  public showSpinner :any;
  public certificteData;
  public columns: any[];
  public jsonFileLoaded;
  public data;
  public certificateCount = 0;
  public mode: string;
  public matTabOptions = [];
  public dropDownValues: any = {};
  ignoreList = ['id', 'links', 'name', 'keyAlgorithm', 'keySize', 'country', 'location',
  'parentCertificate', 'name', 'organization', 'signatureAlgorithm', 'state', 'totalValidity',
  'validityLeft', 'active', 'startDate'];
  asideMenus: Array<AsideCard>;
  public pageKey: string;
  public formData: any;
  public certificateFormData: any;
  public currentTabValue = 'certificates';
  public certificateIcon: string;
  public solutionTypeId: string;
  public businessProfileId: string;
  public permissionList: Array<String> = [];
  public selectAllCertificates = false;
  public selectedTag;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  selection = new SelectionModel<CommonId>(true, []);
  searchDto: any = null;
  public searchData;
  public certificateData;
  public downloadIcon;
  deleteImage;
  @ViewChild(SearchComponentComponent) searchForm: SearchComponentComponent;
  constructor(private translate: TranslationService, private route: ActivatedRoute,
    private dynamicSearchServiceService: DynamicSearchServiceService,
    private tenantControllerService: TenantControllerService, private businessProfileControllerService: BusinessProfileControllerService,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private _matDialog: MatDialog, private httpService: HttpClient,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private rightsideNavitemsService: RightsideNavitemsService, private responseHandlerService: ResponseHandlerService,
    private certificateManagementControllerService: CertificateManagementControllerService,
    private certificateMgmtUtilityControllerService: CertificateMgmtUtilityControllerService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.jsonFileLoaded = 'certificate-search';
    this.pageKey = 'group';
    this.downloadIcon = DOWNLOAD_IMAGE;
    this.matTabOptions.push({ 'label': 'Certificates', 'value': 'certificates' });
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.httpService
      .get('./assets/form-fields/certificate-search.json')
      .subscribe(data => {
        this.dynamicSearchServiceService.setFormData(data as string[]);
        this.formData = this.dynamicSearchServiceService.generateDynamicForm(this.matTabOptions[0].value);
        this.dropDownValues = this.dynamicSearchServiceService.generateDynamicFormDropdown(this.formData);
      });
      if (this.matTabOptions[0].value === 'certificates') {
        this.getSolutionType();
        this.currentTabValue = 'certificates';
      }
      this.deleteImage = DELETE_IMAGE;
      this.certificateIcon = CERTIFICATE_ICON;
  }
/**
    * @ngdoc method
    * @name ManageCertificates#getSolutionType
    *
    * @methodOf
    * certificate.component:manageCertificates
    *
    * @description
    * gets the list of solution types using GET method
    *
    * @param {type} null
    * @return {type} null
  */
  /* @description: to populate solution type dropdown */
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
            } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }
  /**
    * @ngdoc method
    * @name ManageCertificates#getBusinessProfileOnSolution
    *
    * @methodOf
    * certificate.component:manageCertificates
    *
    * @description
    * Description: To populate profile dropdown.
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
 public getBusinessProfileOnSolution(solutionTypeId: string) {
  this.solutionTypeControllerService
    .findByBusinessProfilesListBasicForSolutionTypeId({ solutionTypeId: solutionTypeId })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['businessProfile'] = resp;
        } else {
          this.dropDownValues['businessProfile'] = [];
        }
      },
      err => {
        this.dropDownValues['businessProfile'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}

 /**
    * @ngdoc method
    * @name ManageCertificates#updateDropdownValues
    *
    * @methodOf
    * firmware.controller:manageCertificates
    *
    * @description
    * Description: To populate dependent dropdown.
    *
    * @param {type} dropdown select event
    * @return {type} null
  */
 public updateDropdownValues($event) {
  let certificateTypes = null;
  if ($event.value !== undefined) {
    if ($event.child) {
      if ($event.child.child === 'businessProfile') {
        this.searchForm.maxDate = null;
        this.getBusinessProfileOnSolution($event.value);
        this.getCertificateDetailsData();
        this.solutionTypeId = $event.value;
      } else if ($event.child.child === 'issuer') {
        const bodyJSON = {};
        if ($event.parentName === 'businessProfile') {
         this.businessProfileId = $event.value;
        } else if ($event.parentName === 'certificateType') {
          certificateTypes = $event.value;
        }
      bodyJSON['businessProfile'] = {'id': this.businessProfileId};
      bodyJSON['solutionType'] = {'id': this.solutionTypeId};
      if (certificateTypes && certificateTypes.length) {
        bodyJSON['certificateType'] = [];
        for (const certificate of certificateTypes) {
        bodyJSON['certificateType'].push({'id': certificate});
      }
    }
    this.getIssuerData(bodyJSON);
  }
  }
}
}

public getIssuerData(bodyJSON) {
  this.certificateManagementControllerService.findIssuerDetails({body: bodyJSON}).subscribe(
   resp => {
     if (resp) {
         this.dropDownValues['issuer'] = resp;
     } else {
      this.dropDownValues['issuer'] = [];
     }
   },
   err => {
    this.dropDownValues['issuerName'] = [];
     this.responseHandlerService.returnToastMessage('error', err.error.message);
   }
 );
}

public getCertificateDetailsData() {
  this.certificateMgmtUtilityControllerService.getListOfValues().subscribe(
   resp => {
     if (resp) {
       if (resp.certificateTypes.length) {
         this.dropDownValues['certificateType'] = resp.certificateTypes;
       }
       if (resp.validityList.length) {
        this.dropDownValues['validity'] = resp.validityList;
       }
     } else {
      this.dropDownValues['certificateType'] = [];
      this.dropDownValues['validity'] = [];
     }
   },
   err => {
     this.dropDownValues['certificateType'] = [];
     this.dropDownValues['validity'] = [];
     this.responseHandlerService.returnToastMessage('error', err.error.message);
   }
 );
}

   /**
    * @ngdoc method
    * @name ManageCertificate#searchGroupsEmitter
    *
    * @methodOf
    * device.controller:manageCertificate
    *
    * @description
    * Description:
    *
    * @param {type}
    * @return {type}
  */
 public searchCertEmitter(event) {
  this.data = null;
  this.searchForCertificate(event);
}

/**
    * @ngdoc method
    * @name ManageCertificate#searchForCertificate
    *
    * @methodOf
    * device.controller:manageCertificate
    *
    * @description
    * Description: below method is intended to form search object based on basic or advance search criteria
    *
    * @param {searchData}
    * @return {type}
  */
public searchForCertificate(searchData) {
  if (searchData['criteria'] === 'ADVANCE' && searchData['solutionType'] && searchData['businessProfile'] ) {
    const CertificateSearchDto = {};
    CertificateSearchDto['solutionType'] = { id: searchData['solutionType'] };
    CertificateSearchDto['businessProfile'] = { id: searchData['businessProfile'] };
    if (searchData['certificateType']) {
      const certificateTypes = [];
      for (const certificate of searchData['certificateType']) {
        certificateTypes.push({ id: certificate });
      }
      CertificateSearchDto['certificateType'] = certificateTypes;
    }
    if (searchData['issuer']) {
      CertificateSearchDto['issuer'] = { id: searchData['issuer'] };
    }
    if (searchData['validity']) {
      CertificateSearchDto['validity'] =  parseInt(searchData['validity'], 10);
    }
    CertificateSearchDto['validTill'] = this.formatDate(searchData['validTill']);
    this.searchData = {
                        body: CertificateSearchDto,
                        page: 0,
                        limit: this.pageSize
                      };
    this.getCertificateList(this.searchData);
  } else if (searchData['criteria'] === 'BASIC') {
    this.searchData = {
      body: searchData,
      page: 0,
      limit: this.pageSize
    };
    this.getCertificateList(this.searchData);
  } else {
    this.responseHandlerService.returnToastMessage('warning', 'PLEASE_SELECT_MANDATORY_FIELDS');
  }
}

/**
    * @ngdoc method
    * @name ManageCertificate#getCertificateList
    *
    * @methodOf
    * device.controller:manageCertificate
    *
    * @description
    * Description: Wil serch certificates and populates the data in certificate table
    *
    * @param {searchData}
    * @return {type}
  */
public getCertificateList(searchData) {
  this.showSpinner = this.loaderService.showProgressBar();
  this.certificateManagementControllerService.searchCertificates(searchData).subscribe(
    resp => {
      if (resp['totalElements'] && resp['certificateList'].length) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.certificateData = resp['certificateList'];
        this.data = resp['certificateList'];
        this.data.paginator = this.paginator;
        this.certificateCount = resp['totalElements'];
        for (const data of this.certificateData) {
          data['businessProfile'] = data['businessProfile'] && data['businessProfile']['name'];
          data['solutionType'] = data['solutionType'] && data['solutionType']['name'];
          data['certificateType'] = data['certificateType'] && data['certificateType']['name'];
          data['issuer'] = data['parentCertificate'] && data['parentCertificate']['name'];
          data['validity'] = data['totalValidity'];
          data['action'] = null;
        }
        this.columns = this.filterColumns(Object.keys(this.certificateData[0]));
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    },
    err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    }
  );
}

 /**
    * @ngdoc method
    * @name manageCertificate#pageEvent
    *
    * @methodOf
    * device.controller:manageCertificate
    *
    * @description
    * Description: To fetch the next list of devices.
    *
    * @param {type} event
    * @return {type} null
  */
 public pageEvent(event) {
  this.searchData['page'] = event.pageIndex;
  this.searchData['limit'] = event.pageSize;
  this.getCertificateList(this.searchData);
}

  /**
    * @ngdoc method
    * @name manageCertificate#filterColumns
    *
    * @methodOf
    * device.controller:manageCertificate
    *
    * @description
    * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
    *
    * @param {type} columns
    * @return {type} list of columns
  */
 public filterColumns(columns = []) {
  if (Array.isArray(columns)) {
    return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
  }
}
/**
    * @ngdoc method
    * @name manageCertificate#formatDate
    *
    * @methodOf
    * device.controller:manageCertificate
    *
    * @description
    * Description: Format the date in required format.
    *
    * @param {type} date
    * @return {type} date as a string
  */
 formatDate(date) {
  if (date !== undefined) {
    let day: string = date.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: string = (date.getMonth() + 1).toString();
    month = +month < 10 ? '0' + month : month;
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }
}

 /**
    * @ngdoc method
    * @name manageCertificate#matChange
    *
    * @methodOf
    * device.controller:manageCertificate
    *
    * @description
    * to trigger action like fetching data in case tab changes.
    *
    * @param {type} click event
    * @return {type} null
  */
 public matChange(event) {
  this.data = null;
  this.columns = null;
  this.currentTabValue = this.matTabOptions[event.index].value;
  this.formData = this.dynamicSearchServiceService.generateDynamicForm(this.currentTabValue);
  this.dropDownValues = this.dynamicSearchServiceService.generateDynamicFormDropdown(this.formData);
}

 /**
    * @ngdoc method
    * @name CreateCertificate#downloadCertificate
    *
    * @methodOf
    * device.controller:createCertificate
    *
    * @description
    * to trigger action like fetching data in case tab changes.
    *
    * @param {type} certificate {object}
    * @return {type} null
  */
public downloadCertificate(certificate) {
  if (certificate && certificate.id) {
    this.showSpinner = this.loaderService.showProgressBar();
  this.certificateManagementControllerService.downloadCertificateZip({certId: certificate.id}).subscribe(
    resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      if (resp) {
        this.downloadFile(resp, this.generateFileName(certificate.commonName, 'zip'));
      }
    },
    err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    }
  );
  }
}
  /**
    * @ngdoc method
    * @name ManageCertificates#downloadFile
    *
    * @methodOf
    * certificate.component:manageCertificates
    *
    * @description
    * Description: Helper method to convert responce of download certificateapi into downloadable file
    *
    * @param {type} data {any}, fileName {string}
    * @return {type} null
  */
 public downloadFile(data, fileName) {
  const FileSaver = require('file-saver');
  const blob = new Blob([data], { type: 'application/zip' });
  FileSaver.saveAs(blob, fileName);
}

/**
    * @ngdoc method
    * @name ManageCertificates#generateFileName
    *
    * @methodOf
    * certificate.component:ManageCertificates
    *
    * @description
    * Description: Helper method to generate file name as 'certificatename.zip' for root certificate.
    *
    * @param {type} filename {string}, format {zip}
    * @return {type} null
*/
public generateFileName(fileName, format) {
  return (fileName + '.' + format);
}

public gotoConfirmDialog(data = null, mode?) {
  this.certificateFormData = data;
  this.mode = mode;
  let message;
  switch (mode) {
    case 'edit': if ((data['active'])) {
      if (this.certificateFormData['validityLeft'] <= 0) {
        message = this.translate.translateErrorMessages('EXPIRED_CERT_MESSAGE');
      } else {
        message = this.translate.translateErrorMessages('ACTIVATE_SELECTED_CERTIFICATE');
      }
    } else {
      if (data['certificateType'] === 'LEAF_CERTIFICATE') {
        message = this.translate.translateErrorMessages('DEACTIVATE_LEAF_CERTIFICATE');
      } else {
        message = this.translate.translateErrorMessages('DEACTIVATE_SELECTED_CERTIFICATE');
      }
    }
      break;
  }
  const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
    maxWidth: '400px',
    disableClose: true, data: { message: message }
  });
  dialogRef.afterClosed().subscribe(result => {
    if (result) {

      switch (mode) {
        case 'edit': if (!this.certificateFormData['active']) {
          this.deActivateCertificate(this.certificateFormData);
        } else {
          this.activateCertificate(this.certificateFormData);
        }
          break;
      }
    } else {
      this.abortAction();
    }
  });
}

/**
    * @ngdoc method
    * @name manageCertificate#abortAction
    *
    * @methodOf
    * Certificates.controller:manageCertificate
    *
    * @description
    * Description: Closes the popup
    *
    * @param {type} null
    * @return {type} null
  */
 public abortAction() {
  this._matDialog.closeAll();
  this.certificateFormData['active'] = !this.certificateFormData['active'];

}

/**
    * @ngdoc method
    * @name manageCertificate#activateCertificate
    *
    * @methodOf
    * certificate.controller:manageCertificate
    *
    * @description
    * Description: Activates particular certificate.
    *
    * @param {type} null
    * @return {type} null
  */
 public activateCertificate(certificate) {
  this._matDialog.closeAll();
  if (certificate['validityLeft'] > 0) {
  this.showSpinner = this.loaderService.showProgressBar();
  this.certificateManagementControllerService
    .activateCertificate({certId: certificate['id']})
    .subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'CERTIFICATE_ACTIVATE_SUCCESS');
        this.getCertificateList(this.searchData);
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        certificate['active'] = !certificate['active'];
      }
    );
  } else {
    this.loaderService.hideProgressBar(this.showSpinner);
    certificate['active'] = !certificate['active'];
  }
}

/**
  * @ngdoc method
  * @name manageCertificate#deActivateCertificate
  *
  * @methodOf
  * certificates.controller:manageCertificates
  *
  * @description
  * Description: Deactivates particular certificates.
  *
  * @param {type} null
  * @return {type} null
*/
public deActivateCertificate(certificate) {
  this._matDialog.closeAll();
  this.showSpinner = this.loaderService.showProgressBar();
  this.certificateManagementControllerService
  .deactivateCertificate({certId: certificate['id']})
    .subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'CERTIFICATE_DEACTIVATE_SUCCESS');
        this.getCertificateList(this.searchData);
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        certificate['active'] = !certificate['active'];
      }
    );
}

  /**
    * @ngdoc method
    * @name manageCertificate#checkPermission
    *
    * @methodOf
    * certificates.controller:manageCertificate
    *
    * @description
    * Description: Checks whether the logged in user has permission to perform crud operations.
    *
    * @param {type} key
    * @return {type} boolean
  */
   public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

}
