/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit } from '@angular/core';
import { AccountControllerService } from 'src/app/services/Platform/services';
import { SettingCommonDto } from 'src/app/services/Platform/models';
import { SelectionModel } from '@angular/cdk/collections';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import { ActivatedRoute } from '@angular/router';
import { TranslationService } from 'src/app/providers/translation-service';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { MANAGE, EDIT_IMAGE, DELETE_IMAGE, ACCOUNT_ICON, CLOSE_ICON } from 'src/app/shared/constants/strings';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BciLoaderService } from '@bci-web-core/core';


/**
  * @ngdoc component
  * @name account.component:manageAccount
  *
  *
  * @description
  * description: To perform crud operations on the list of accounts.
  *
*/
@Component({
    selector: 'app-manage-account',
    templateUrl: './manage-account.component.html',
    styleUrls: ['./manage-account.component.scss']
})

// activation/de-activation/deletion of multiple accounts to be taken up in next phase

export class ManageAccountComponent implements OnInit {
    public showSpinner:any;
    public accountData;
    public columns: any[];
    public jsonFileLoaded;
    public dataSource;
    public mode: string;
    ignoreList = ['id', 'links', 'active'];
    asideMenus: Array<AsideCard>;
    public pageKey: string;
    public permissionList: Array<String> = [];
    selection = new SelectionModel<SettingCommonDto>(true, []);
    editImage;
    deleteImage;
    accountIcon;
    closeIcon: string;
    constructor(private translate: TranslationService, private route: ActivatedRoute,
        private accountControllerService: AccountControllerService, private _matDialog: MatDialog,
        private rightsideNavitemsService: RightsideNavitemsService, private responseHandlerService: ResponseHandlerService,
        private loaderService: BciLoaderService) { }

    ngOnInit() {
        this.permissionList = this.route.snapshot.data.data['permissionList'];
        this.getAcounts();
        this.jsonFileLoaded = 'accounts';
        this.pageKey = 'account';
        this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
            this.asideMenus = navItemsList as Array<AsideCard>;
        });
        // this.showSpinner = this.loaderService.showProgressBar();
        this.editImage = EDIT_IMAGE;
        this.deleteImage = DELETE_IMAGE;
        this.accountIcon = ACCOUNT_ICON;
        this.closeIcon = CLOSE_ICON;
    }

    public isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;

        return numSelected === numRows;
    }

    public masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    public checkboxLabel(row?: SettingCommonDto): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    /**
      * @ngdoc method
      * @name manageAccount#gotoModal
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: Open popup for crud operations.
      *
      * @param {type} content, data, mode
      * @return {type} null
    */
    public gotoModal(content, data = null, mode?) {
        this.accountData = data;
        this.mode = mode;
        this._matDialog.open(content, { disableClose: true, data });
    }

    /**
      * @ngdoc method
      * @name manageAccount#getAcounts
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: Fetches accounts list.
      *
      * @param {type} null
      * @return {type} null
    */
    public getAcounts() {
        this.showSpinner = this.loaderService.showProgressBar();
        this.accountControllerService
            .findAllAccounts({})
            .subscribe(
                resp => {
                    if (resp && resp['length']) {
                        this.loaderService.hideProgressBar(this.showSpinner);
                        this.accountData = resp;
                        this.dataSource = new MatTableDataSource<SettingCommonDto>(this.accountData);

                        for (const data of this.accountData) {
                            data['action'] = null;
                        }
                        this.columns = this.filterColumns(Object.keys(this.accountData[0]));
                        // this.columns.unshift('select');
                    } else {
                        this.loaderService.hideProgressBar(this.showSpinner);
                        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
                    }
                },
                err => {
                    this.loaderService.hideProgressBar(this.showSpinner);
                    this.responseHandlerService.returnToastMessage('error', err.error.message);
                }
            );
    }

    /**
      * @ngdoc method
      * @name manageAccount#editAccount
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: Edits particular account.
      *
      * @param {type} data
      * @return {type} null
    */
    public editAccount(data: any) {
        this.showSpinner = this.loaderService.showProgressBar();
        this.accountControllerService
            .updateAccount({
                id: this.accountData['id'],
                body: data
            })
            .subscribe(
                resp => {
                    this.loaderService.hideProgressBar(this.showSpinner);
                    this.getAcounts();
                    this.accountData = null;
                    this._matDialog.closeAll();
                    this.responseHandlerService.returnToastMessage('success', 'ACCOUNT_EDIT_SUCCESS');
                },
                err => {
                    this.loaderService.hideProgressBar(this.showSpinner);
                    this._matDialog.closeAll();
                    this.responseHandlerService.returnToastMessage('error', err.error.message);
                }
            );
    }

    /**
      * @ngdoc method
      * @name manageAccount#activateAccount
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: Activates particular account.
      *
      * @param {type} data
      * @return {type} null
    */
    public activateAccount(data: any) {
        this._matDialog.closeAll();
        this.showSpinner = this.loaderService.showProgressBar();
        const selectedRows = this.selection.selected;
        const iterations = 0;
        // for (const row of selectedRows) {
        this.accountControllerService.activateAccount({ id: data['id'] }).subscribe(
            resp => {
                // iterations++;
                this.loaderService.hideProgressBar(this.showSpinner);
                // if (iterations === selectedRows.length) {
                this.responseHandlerService.returnToastMessage('success', 'ACCOUNT_ACTIVATE_SUCCESS');
                this.getAcounts();
                // this.selection.clear();
                // }
            },
            err => {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('error', err.error.message);
                this.accountData['active'] = !this.accountData['active'];
            }
        );
        // }
    }

    /**
      * @ngdoc method
      * @name manageAccount#deActivateAccount
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: Deactivates particular account.
      *
      * @param {type} data
      * @return {type} null
    */
    public deActivateAccount(data: any) {
        this._matDialog.closeAll();
        this.showSpinner = this.loaderService.showProgressBar();
        const selectedRows = this.selection.selected;
        const iterations = 0;
        // for (const row of selectedRows) {
        this.accountControllerService.deactivateAccount({ id: data['id'] }).subscribe(
            resp => {
                this.loaderService.hideProgressBar(this.showSpinner);
                // iterations++;
                // if (iterations === selectedRows.length) {
                this.responseHandlerService.returnToastMessage('success', 'ACCOUNT_DEACTIVATE_SUCCESS');
                this.getAcounts();
                // this.selection.clear();
                // }
            },
            err => {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('error', err.error.message);
            });
        this.accountData['active'] = !this.accountData['active'];
        // }
    }

    /**
      * @ngdoc method
      * @name manageAccount#deleteAccount
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: Deletes particular account.
      *
      * @param {type} data
      * @return {type} null
    */
    public deleteAccount(data: any) {
        this._matDialog.closeAll();
        this.showSpinner = this.loaderService.showProgressBar();
        this.accountControllerService.deleteByAccountId({ id: data['id'] }).subscribe(
            resp => {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('success', 'ACCOUNT_DELETE_SUCCESS');
                this.getAcounts();
            },
            err => {
                this.loaderService.hideProgressBar(this.showSpinner);
                this.responseHandlerService.returnToastMessage('error', err.error.message);
                this.getAcounts();
            }
        );
    }

    /**
      * @ngdoc method
      * @name manageAccount#abortAction
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: Closes the popup and restore the account's active/inactive state.
      *
      * @param {type} null
      * @return {type} null
    */
    public abortAction() {
        this._matDialog.closeAll();
        // this.selection.clear();
        if (this.mode !== 'delete') {
            this.accountData['active'] = !this.accountData['active'];
        }
    }

    /**
      * @ngdoc method
      * @name manageAccount#filterColumns
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
      *
      * @param {type} columns
      * @return {type} list of columns
    */
    public filterColumns(columns = []) {
        if (Array.isArray(columns)) {
            return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
        }
    }

    /**
      * @ngdoc method
      * @name manageAccount#checkPermission
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: Checks whether the logged in user has permission to perform crud operations.
      *
      * @param {type} key
      * @return {type} boolean
    */
    public checkPermission(key: string) {
        if (this.permissionList.indexOf(key) <= -1) {
            return false;
        }
        return true;
    }

    /**
      * @ngdoc method
      * @name manageAccount#onClose
      *
      * @methodOf
      * account.controller:manageAccount
      *
      * @description
      * Description: To close the popup.
      *
      * @param {type} null
      * @return {type} null
    */
    public onClose() {
        this._matDialog.closeAll();
    }

    public gotoConfirmDialog(data = null, mode?) {
        this.accountData = data;
        this.mode = mode;
        let message;
        switch (mode) {
            case 'edit': if ((data['active'])) {
                message = this.translate.translateErrorMessages('ACTIVATE_SELECTED_ACCOUNT');
            } else {
                message = this.translate.translateErrorMessages('DEACTIVATE_SELECTED_ACCOUNT');
            }
                break;
            case 'delete': message = this.translate.translateErrorMessages('DELETE_SELECTED_ACCOUNT');
        }
        const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
            maxWidth: '400px',
            disableClose: true, data: { message: message }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                switch (mode) {
                    case 'edit': if (data['active']) {
                        this.activateAccount(data);
                    } else {
                        this.deActivateAccount(data);
                    }
                        break;
                    case 'delete': this.deleteAccount(data);
                        break;
                }
            } else {
                this.abortAction();
            }
        });
    }
}
