/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import {
  DeviceEnrollmentControllerService, DeviceTypeControllerService, DeviceMgmtUtilityControllerService, TagControllerService
} from 'src/app/services/DeviceMgmt/services';
import { AccountControllerService, BusinessProfileControllerService, PlatformAdminUtilityControllerService,
  TenantControllerService } from 'src/app/services/Platform/services';
import { CREATE, DEVICE_ICON,  DELETE_IMAGE, DOCUMENT_IMAGE, SUPPORTED_ATTESTATION_TYPE,
  ZELIOT_ATTESTATION_TYPE } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ObjectToIdConversionService  } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { EnvService } from 'src/app/env.service';
import { Router } from '@angular/router';
import { TagsServiceProvider } from 'src/app/providers/tags-service';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs/internal/Observable';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { startWith, map } from 'rxjs/operators';
import { BciLoaderService } from '@bci-web-core/core';
/**
  * @ngdoc component
  * @name device.component:createDevice
  *
  *
  * @description
  * description: To register a new device(individual enrollment).
  *
  *
*/
@Component({
  selector: 'app-create-device',
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.scss','../common-device-styles.scss']
})
export class CreateDeviceComponent implements OnInit {
  public jsonFileLoaded;
  public showSpinner :any;
  public pageKey: string;
  public dropDownValues: any = {};
  public deviceIcon: string;
  public profileId: string;
  asideMenus: Array<AsideCard>;
  public provider: any;
  public enrollmentType: string;
  public providerId: string;
  public getSolutionTypeId: string;
  individualRegistrationForm: UntypedFormGroup;
  public files: any = [];
  public excelFiles: any = [];
  submitted = false;
  deleteImage = DELETE_IMAGE;
  documentImage = DOCUMENT_IMAGE;
  public sampleFile: any;
  public displayCertInput = false;
  public displayFileInput = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  stringArray: any = [];
  selectedValuesArray: any = [];
  filteredStrings: Observable<string[]>;
  tags = new UntypedFormControl();
  public attestationType: any;
  public selectedCategory: any;
  public env: any;
  public selectedDeviceType: any;
  public simSupport: string;
  @ViewChild('inputCtrl', { static: true }) inputCtrl: ElementRef<HTMLInputElement>;
  sameRefValidation: boolean;
  simValueCompare: boolean;
  constructor(private businessProfileControllerService: BusinessProfileControllerService,
    private tenantControllerService: TenantControllerService,
    private deviceEnrollmentControllerService: DeviceEnrollmentControllerService,
    private accountControllerService: AccountControllerService, private deviceTypeControllerService: DeviceTypeControllerService,
    private deviceMgmtUtilityControllerService: DeviceMgmtUtilityControllerService, private responseHandlerService: ResponseHandlerService,
    private rightsideNavitemsService: RightsideNavitemsService, private objectToIdConversionService: ObjectToIdConversionService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private envService: EnvService, private router: Router, private tagControllerService: TagControllerService,
    private tagService: TagsServiceProvider, private formBuilder: UntypedFormBuilder,private loaderService: BciLoaderService) {
      this.filteredStrings = this.tags.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => value ? this.filterStringsList(value) : this.stringArray.slice()));
     }

  ngOnInit() {
    this.env = this.envService;
    this.pageKey = 'device';
    this.rightsideNavitemsService.getRightsideNavItems(CREATE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.getAccount();
    this.deviceIcon = DEVICE_ICON;
    this.individualRegistrationForm = this.formBuilder.group({
      account: ['', Validators.required],
      tenant: ['', Validators.required],
      businessProfile: ['', Validators.required],
      solutionType: ['', Validators.required],
      provider: ['', Validators.required],
      iotHub: ['', Validators.required],
      deviceType: ['', Validators.required],
      attestationType: ['', Validators.required],
      deviceSerialNo: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      imeiNo: ['', [Validators.minLength(15), Validators.maxLength(15)]],
      primarySim: ['', [Validators.minLength(36), Validators.maxLength(36)]],
      secondarySim: new FormControl('', [Validators.minLength(36), Validators.maxLength(36),this.dualSimValidation.bind(this)]),
      category: [''],
      gatewayDeviceName: ['', [ Validators.maxLength(15)]],
      certificateSubject: ['', [ Validators.maxLength(150)]],
      criteria: [''],
      cert: [''],
      base64Certificate: [''],
      tags: [''],
      tpmEndorsementKey: ['', [Validators.minLength(3), Validators.maxLength(500)]],
      symmetricPrimaryKey: ['', [Validators.minLength(3), Validators.maxLength(100)]],
      symmetricSecondaryKey: ['', [Validators.minLength(3), Validators.maxLength(100)]],
    });
    this.simSupport = 'noSim';
 
  }

  /**
    * @ngdoc method
    * @name createDevice#getAttestationType
    *
    * @methodOf
    * device.controller:createDevice
    *
    * @description
    * Description: To populate attestation types dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getAttestationType(enrollmentType) {
    this.deviceMgmtUtilityControllerService
      .getAllAttestationTypes({ enrollmentType: enrollmentType })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['attestationType'] = resp;
          } else {
            this.dropDownValues['attestationType'] = [];
          }
        },
        err => {
          this.dropDownValues['attestationType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createDevice#getAccount
    *
    * @methodOf
    * device.controller:createDevice
    *
    * @description
    * Description: To populate account dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getAccount() {
    this.accountControllerService
      .findAllAccounts({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.dropDownValues['account'] = resp;
          } else {
            this.dropDownValues['account'] = [];
          }
        },
        err => {
          this.dropDownValues['account'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createDevice#getTenant
    *
    * @methodOf
    * device.controller:createDevice
    *
    * @description
    * Description: To populate tenant dropdown.
    *
    * @param {type} accountId
    * @return {type} null
  */
  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        err => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createDevice#updateDropdownValues
    *
    * @methodOf
    * device.controller:createDevice
    *
    * @description
    * Description: To populate dependent dropdown.
    *
    * @param {type} dropdown select event
    * @return {type} null
  */
  public updateDropdownValues($event, childField) {
    if ($event.value !== undefined) {
      if (childField && childField === 'tenant') {
        this.getTenant($event.value);
      }
      if (childField && childField === 'businessProfile') {
        this.getProfile($event.value);
      }
      if (childField && childField === 'solutionType') {
        this.profileId = $event.value;
        this.getSolutionType($event.value);
      }
      if (childField && childField === 'provider') {
        this.getSolutionTypeId = $event.value;
        this.getDeviceInterFaceProvider($event.value, this.profileId);
        this.getAvailableTags($event.value);
      }
      if (childField && childField === 'iotHub') {
      this.provider = this.dropDownValues['provider'].find(dropDownOption => dropDownOption.id === $event.value);
      this.getIotHubList(this.getSolutionTypeId, $event.value, this.profileId);
      this.providerBasedManipulations(this.provider);
      }
      if (childField && childField === 'deviceType') {
        this.getDeviceType(this.getSolutionTypeId, this.provider['id']);
      }
      if (childField && childField === 'attestationType') {
        this.selectedDeviceType = this.dropDownValues['deviceType'].find(dropDownOption => dropDownOption.id === $event.value);
          if (this.provider['name'] === this.env['providerBosch']) {
            this.enrollmentType = this.env['providerBosch'];
          } else if (this.provider['name'] === this.env['providerAws']) {
            this.enrollmentType = this.env['providerAws'];
          } else if (this.provider['name'] === this.env['providerZelIot']) {
            this.enrollmentType = this.env['providerZelIot'];
          }  else if (this.provider['name'] === this.env['providerAzure']) {
            this.enrollmentType = 'individualEnrollment';
          }
        this.getAttestationType(this.enrollmentType);
        this.getSimSupportDetails();
      }
      if (childField && (childField === 'category' || childField === 'criteria' )) {
        this.attestationType = $event.source.selected.viewValue;
        if ( this.provider['name'] === this.env['providerBosch']) {
          this.getDeviceCategories();
        } else {
          this.getUploadType();
        }
    }
    if ( childField && childField === 'deviceCategory') {
      this.selectedCategory = $event.source.selected.viewValue;
      if ( this.selectedCategory && this.selectedCategory === 'Edge') {
      this.individualRegistrationForm.get('gatewayDeviceName').setValidators(Validators.required);
        this.individualRegistrationForm.get('gatewayDeviceName').updateValueAndValidity();
        this.individualRegistrationForm.get('certificateSubject').setValue('');
        this.individualRegistrationForm.get('certificateSubject').clearValidators();
        this.individualRegistrationForm.get('certificateSubject').updateValueAndValidity();
      } else if (this.selectedCategory) {
        this.individualRegistrationForm.get('certificateSubject').setValidators(Validators.required);
        this.individualRegistrationForm.get('certificateSubject').updateValueAndValidity();
        this.individualRegistrationForm.get('gatewayDeviceName').setValue('');
        this.individualRegistrationForm.get('gatewayDeviceName').clearValidators();
        this.individualRegistrationForm.get('gatewayDeviceName').updateValueAndValidity();
      }
    }
    if (!childField && $event.value === 'FILE') {
      this.displayFileInput = true;
      this.displayCertInput = false;
      this.individualRegistrationForm.get('base64Certificate').setValidators(Validators.required);
      this.individualRegistrationForm.get('base64Certificate').updateValueAndValidity();
      this.individualRegistrationForm.get('cert').patchValue('');
      this.individualRegistrationForm.get('cert').clearValidators();
      this.individualRegistrationForm.get('cert').updateValueAndValidity();

    } else if (!childField && $event.value === 'TEXTAREA') {
      this.displayFileInput = false;
      this.displayCertInput = true;
      this.files = [];
      this.individualRegistrationForm.get('cert').setValidators(Validators.required);
      this.individualRegistrationForm.get('base64Certificate').clearValidators();
      this.individualRegistrationForm.get('base64Certificate').patchValue('');
      this.individualRegistrationForm.get('base64Certificate').updateValueAndValidity();
    }
    }
 }

 /**
    * @ngdoc method
    * @name createDevice#disableInfoMessage
    *
    * @methodOf
    * device.controller:disableInfoMessage
    *
    * @description
    * Description: To remove didplay info message from UI when form is reset
    *
    * @param {type} $event)
    * @return {type} null
  */
 public disableInfoMessage($event) {
  if ($event) {
    this.dropDownValues['tenant'] = [];
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['solutionType'] = [];
    this.dropDownValues['deviceType'] = [];
    this.dropDownValues['provider'] = [];
    this.dropDownValues['attestationType'] = [];
    this.dropDownValues['tags'] = [];
    this.selectedValuesArray = [];
    this.stringArray = [];
    this.individualRegistrationForm.reset();
  }
}
  /**
    * @ngdoc method
    * @name createDevice#getProfile
    *
    * @methodOf
    * device.controller:createDevice
    *
    * @description
    * Description: To populate business profiles dropdown.
    *
    * @param {type} tenantId
    * @return {type} null
  */
  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        err => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createDevice#getSolutionType
    *
    * @methodOf
    * device.controller:createDevice
    *
    * @description
    * Description: To populate solution type dropdown.
    *
    * @param {type} profileId
    * @return {type} null
  */
  public getSolutionType(profileId: string) {
    const solutionTypeActiveArray = [];
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({ businessProfileId: profileId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            resp.forEach(element => {
              if (element['active'] === true) {
                solutionTypeActiveArray.push(element);
              }
            });
            this.dropDownValues['solutionType'] = solutionTypeActiveArray;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name CreateDevice#getDeviceType
    *
    * @methodOf
    * device.controller:createDevice
    *
    * @description
    * Description: To populate device type dropdown.
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
  public getDeviceType(solutionTypeId, deviceInterfaceProviderId ) {
    this.deviceTypeControllerService
      .findAllDeviceTypesBasedOnSolutionType({ id: solutionTypeId, deviceInterfaceProviderId: deviceInterfaceProviderId, active: 'true', devicePropertiesReqd: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['deviceType'] = resp;
          } else {
            this.dropDownValues['deviceType'] = [];
          }
        },
        err => {
          this.dropDownValues['deviceType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createDevice#getDeviceInterFaceProvider
    *
    * @methodOf
    * device.controller:getDeviceInterFaceProvider
    *
    * @description
    * Description: Fetches device interface provider associated with selected solutionType and businessProfile
    *
    * @param {type} solutionTypeId,profileId
    * @return {type} null
  */
  public getDeviceInterFaceProvider(solutionTypeId: string, profileId: string) {
    this.platformAdminUtilityControllerService.deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: profileId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['provider'] = resp;
          } else {
            this.dropDownValues['provider'] = [];
          }
        },
        err => {
          this.dropDownValues['provider'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createDevice#getDeviceCategories
    *
    * @methodOf
    * device.controller:getDeviceCategories
    *
    * @description
    * Description: Fetches a list of categories of devices when provider is 'BOSCH-IO'
    *
    * @param {type} null
    * @return {type} null
  */
  getDeviceCategories() {
    this.deviceEnrollmentControllerService.getDeviceCetegories({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['category'] = resp;
          } else {
            this.dropDownValues['category'] = [];
          }
        },
        err => {
          this.dropDownValues['category'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public dualSimValidation(control: FormControl) {
    let primary,secondary;
    if(control.value){
       primary =   this.individualRegistrationForm?.get('primarySim').value;
       secondary = control?.value;
    }
   if (!primary || !secondary) {
      return null;
    }
    const secondarySimControl = this.individualRegistrationForm.controls.secondarySim;
    secondarySimControl.valueChanges.subscribe(value=> {
    if ( primary == secondary) {
      secondarySimControl.setErrors({ ...control.errors,incorrectSim: true });
      }
       else {
        if( this.individualRegistrationForm.controls.secondarySim.hasError('incorrectSim')){
        const error = {...this.individualRegistrationForm.controls.secondarySim.errors};
        delete error.incorrectSim;
        secondarySimControl.setErrors(error);
      }
    }
    });
      return null;
 }


  /**
    * @ngdoc method
    * @name createDevice#createDevice
    *
    * @methodOf
    * device.controller:createDevice
    *
    * @description
    * Description: To register a new device.
    *
    * @param {type} data
    * @return {type} null
  */
  public createDevice() {
    this.submitted = true;
    let simRefIdArray = [];
    if (this.individualRegistrationForm.valid) {
    const data = this.individualRegistrationForm.value;
    this.showSpinner = this.loaderService.showProgressBar();
    const bodyJSON = data;
    const tags = [];
    if (!data['imeiNo']) {
      data['imeiNo'] = null ;
    }
    if (this.attestationType === SUPPORTED_ATTESTATION_TYPE || this.attestationType === ZELIOT_ATTESTATION_TYPE) {
      delete data['symmetricPrimaryKey'];
      delete data['symmetricSecondaryKey'];
      delete data['tpmEndorsementKey'];
    }
    if (this.simSupport !== 'noSim') {
        if (data['primarySim']) {
          simRefIdArray.push(data['primarySim']);
        }
        if (data['secondarySim']) {
          simRefIdArray.push(data['secondarySim']);
        }
      if (simRefIdArray && simRefIdArray.length) {
        bodyJSON['simReferenceIds'] = simRefIdArray;
      }
    }
    delete bodyJSON['primarySim'];
    delete bodyJSON['secondarySim'];
    bodyJSON['account'] = this.objectToIdConversionService.convertObjectToId(data['account']);
    bodyJSON['attestationType'] = this.objectToIdConversionService.convertObjectToId(data['attestationType']);
    bodyJSON['businessProfile'] = this.objectToIdConversionService.convertObjectToId(data['businessProfile']);
    bodyJSON['tenant'] = this.objectToIdConversionService.convertObjectToId(data['tenant']);
    bodyJSON['deviceType'] = this.objectToIdConversionService.convertObjectToId(data['deviceType']);
    bodyJSON['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
    bodyJSON['provider'] = this.objectToIdConversionService.convertObjectToId(this.providerId);
    bodyJSON['iotHub'] = this.objectToIdConversionService.convertObjectToId(data['iotHub']);
    if (this.provider['name'] === this.env['providerBosch']) {
      bodyJSON['category'] = this.objectToIdConversionService.convertObjectToId(data['category']);
      bodyJSON['gatewayDeviceName'] = data['gatewayDeviceName'];
      bodyJSON['certificateSubject'] = data['certificateSubject'];
    } else {
      delete bodyJSON['category'];
      delete bodyJSON['gatewayDeviceName'];
      delete bodyJSON['certificateSubject'];
    }
    for (const tag of this.selectedValuesArray) {
      const tagEntity = {};
      tagEntity['name'] = tag;
      tags.push(tagEntity);
    }
    bodyJSON['tags'] = tags;
    this.deviceEnrollmentControllerService
      .createIndividualEnrollment({ body: bodyJSON })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'DEVICE_CREATE_SUCCESS');
          this.router.routeReuseStrategy.shouldReuseRoute = function( ) { return false; };
          this.router.navigateByUrl(this.router.url).then(() => {
            this.router.navigated = false;
            this.router.navigate([this.router.url]);
          });
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    }
  }
/**
  * @ngdoc method
  * @name CreateDevice#getAvailableTags
  *
  * @methodOf
  * firmware.controller:CreateDevice
  *
  * @description
  * Description: Fetch the list of available static tags
  * @param {type} solutionTypeId
  * @return {type} null
  */
  public getAvailableTags(solutionTypeId: string) {
    this.dropDownValues['tags'] = null;
    this.tagControllerService.findAllTagsBySolutionTypeId({ solutionTypeId: solutionTypeId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['tags'] = resp;
              resp.forEach(tag => {
                if (tag) {
                  this.stringArray.push(tag.name);
              }
              });
          } else {
            this.dropDownValues['tags'] = [];
          }
        },
        err => {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getIotHubList(solutionTypeId: string, deviceInterfaceProviderId: string, profileId: string ) {
    this.platformAdminUtilityControllerService.getIotHubsBySolDevIntfProvAndProfile ({solutionTypeId: solutionTypeId,
      deviceInterfaceProviderId: deviceInterfaceProviderId, profileId: profileId }).subscribe(resp => {
      if (resp && resp.length) {
        this.dropDownValues['iotHub'] = resp;
      } else {
        this.dropDownValues['iotHub'] = [];
      }
    },
      err => {
        this.dropDownValues['iotHub'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }
  public providerBasedManipulations(selectedProvider) {
    this.provider = selectedProvider;
    this.providerId = selectedProvider['id'];
    this.individualRegistrationForm.get('category').clearValidators();
    this.individualRegistrationForm.get('category').updateValueAndValidity();
    this.individualRegistrationForm.get('criteria').clearValidators();
    this.individualRegistrationForm.get('criteria').updateValueAndValidity();
    this.individualRegistrationForm.get('symmetricPrimaryKey').setValue('');
    this.individualRegistrationForm.get('symmetricPrimaryKey').clearValidators();
    this.individualRegistrationForm.get('symmetricPrimaryKey').updateValueAndValidity();
    this.individualRegistrationForm.get('symmetricSecondaryKey').setValue('');
    this.individualRegistrationForm.get('symmetricSecondaryKey').clearValidators();
    this.individualRegistrationForm.get('symmetricSecondaryKey').updateValueAndValidity();
    this.individualRegistrationForm.get('tpmEndorsementKey').setValue('');
    this.individualRegistrationForm.get('tpmEndorsementKey').clearValidators();
    this.individualRegistrationForm.get('tpmEndorsementKey').updateValueAndValidity();
    this.individualRegistrationForm.get('gatewayDeviceName').setValue('');
    this.individualRegistrationForm.get('gatewayDeviceName').clearValidators();
    this.individualRegistrationForm.get('gatewayDeviceName').updateValueAndValidity();
    this.individualRegistrationForm.get('certificateSubject').setValue('');
    this.individualRegistrationForm.get('certificateSubject').clearValidators();
    this.individualRegistrationForm.get('certificateSubject').updateValueAndValidity();
    this.individualRegistrationForm.controls['primarySim'].setValidators([ Validators.minLength(36), Validators.maxLength(36),
      Validators.pattern('^[a-zA-Z0-9\\-]+$')]);
    this.individualRegistrationForm.controls['primarySim'].updateValueAndValidity();
    this.individualRegistrationForm.controls['secondarySim'].setValidators([ Validators.minLength(36), Validators.maxLength(36),Validators.pattern('^[a-zA-Z0-9\\-]+$'),this.dualSimValidation.bind(this)]);
    this.individualRegistrationForm.controls['secondarySim'].updateValueAndValidity();
    if (selectedProvider['name'] === this.env['providerZelIot']) {
      this.individualRegistrationForm.controls['imeiNo'].setValidators([Validators.required, Validators.minLength(15),
      Validators.maxLength(15), Validators.pattern('[0-9]+')]);
      this.individualRegistrationForm.controls['imeiNo'].updateValueAndValidity();
    } else if (selectedProvider['name'] !== this.env['providerZelIot']) {
        this.individualRegistrationForm.controls['imeiNo'].setValidators([ Validators.minLength(15), Validators.maxLength(15),
          Validators.pattern('[0-9]+')]);
        this.individualRegistrationForm.controls['imeiNo'].updateValueAndValidity();
    }
    if ( this.provider['name'] === this.env['providerBosch'] ) {
        this.individualRegistrationForm.get('category').setValidators(Validators.required);
        this.individualRegistrationForm.get('category').updateValueAndValidity();
    } else if (this.provider['name'] === this.env['providerAzure'] || this.provider['name'] === this.env['providerAws'] ) {
          if (this.attestationType === 'X509') {
            this.individualRegistrationForm.get('criteria').setValidators(Validators.required);
            this.individualRegistrationForm.get('criteria').updateValueAndValidity();
      } else if (this.provider['name'] === this.env['providerAzure'] && this.attestationType === 'TPM') {
          this.individualRegistrationForm.get('tpmEndorsementKey').setValidators(Validators.required);
          this.individualRegistrationForm.get('tpmEndorsementKey').updateValueAndValidity();
      } else if (this.provider['name'] === this.env['providerAzure'] && this.attestationType === 'SYMMETRICKEY') {
          this.individualRegistrationForm.get('symmetricPrimaryKey').setValidators(Validators.required);
          this.individualRegistrationForm.get('symmetricPrimaryKey').updateValueAndValidity();
          this.individualRegistrationForm.get('symmetricSecondaryKey').setValidators(Validators.required);
          this.individualRegistrationForm.get('symmetricSecondaryKey').updateValueAndValidity();
        }
      }
  }
 /**
     * @ngdoc method
     * @name CreateDevice#onReset
     *
     * @methodOf
     * SharedModule.controller:CreateDevice
     *
     * @description
     * Description: To reset group enrollment form
     *
     * @param {type} null
     * @return {type} null
    */
   public onReset() {
    this.submitted = false;
    this.individualRegistrationForm.reset();
    this.provider = {};
    this.displayFileInput = false;
    this.displayCertInput = false;
    this.files = [];
    this.excelFiles = [];
    this.dropDownValues = {};
    this.selectedCategory = '';
    this.attestationType = '';
    this.getAccount();
    if (this.selectedValuesArray && this.selectedValuesArray.length) {
      this.selectedValuesArray = [];
    }
    this.selectedDeviceType = null;
    this.simSupport = 'noSim';
    this.inputCtrl.nativeElement.value = '';
  }
   /**
       * @ngdoc method
       * @name CreateDevice#addValues
       *
       * @methodOf
       * SharedModule.controller:CreateDevice
       *
       * @description
       * Description: Triggers event to add selected tag to array
       *
       * @param {type} event: MatAutocompleteSelectedEvent
       * @return {type} null
      */
     addValues(event: MatChipInputEvent): void {
       this.addOrRemoveTags(event, 'addSelectedAttribute');
    }

    /**
       * @ngdoc method
       * @name CreateDevice#remove
       *
       * @methodOf
       * SharedModule.controller:CreateDevice
       *
       * @description
       * Description: Triggers the event for selected value to remove from list of tags
       *
       * @param {type} event: MatAutocompleteSelectedEvent
       * @return {type} null
      */
    removeEntities(event) {
      this.addOrRemoveTags(event, 'removeSelectedAttribute');
    }

    /**
       * @ngdoc method
       * @name CreateDevice#selectEntitiesFromList
       *
       * @methodOf
       * SharedModule.controller:CreateDevice
       *
       * @description
       * Description: Triggers the event with selected value from list of tags
       *
       * @param {type} event: MatAutocompleteSelectedEvent
       * @return {type} null
      */
    selectEntitiesFromList(event: MatAutocompleteSelectedEvent): void {
      this.addOrRemoveTags(event.option, 'addSelectedAttribute');
    }
    /**
       * @ngdoc method
       * @name CreateDevice#filterList
       *
       * @methodOf
       * SharedModule.controller:CreateDevice
       *
       * @description
       * Description:Filter the tags list based on key pressed event
       *
       * @param {type} value: string
       * @return {type} null
      */
     private filterStringsList(value: string): string[] {
      const filterValue = value.toLowerCase();
      return this.stringArray.filter(tag => tag.toLowerCase().includes(filterValue));
      }
/**
  * @ngdoc method
  * @name CreateDevice#getAvailableTags
  *
  * @methodOf
  * firmware.controller:CreateDevice
  *
  * @description
  * Description: Will call the tags common service to perform operations like adding and removing the tags from ui
  * @param {type} $event
  * @return {type} null
  */
addOrRemoveTags($event, action) {
  if ($event.value && action === 'addSelectedAttribute') {
    if (this.selectedValuesArray.length) {
      if (this.selectedValuesArray.includes($event.value)) {
        this.responseHandlerService.returnToastMessage('warning', 'DUPLICATE_TAG');
        this.inputCtrl.nativeElement.value = '';
      } else {
        this.selectedValuesArray.push($event.value);
        this.inputCtrl.nativeElement.value = '';
      }
    } else {
          this.selectedValuesArray.push($event.value);
          this.inputCtrl.nativeElement.value = '';
        }
    } else if ($event && action === 'removeSelectedAttribute') {
      const index = this.selectedValuesArray.indexOf($event);
      if (this.selectedValuesArray && this.selectedValuesArray.length && index >= 0) {
          this.selectedValuesArray.splice(index, 1);
      }
    }
}

/**
     * @ngdoc method
     * @name CreateDevice#getUploadType
     *
     * @methodOf
     * SharedModule.controller:CreateDevice
     *
     * @description
     * Description: Assigns values to upload type dropdown list
     *
     * @param {type} null
     * @return {type} null
    */
public getUploadType() {
  this.dropDownValues['criteria'] = [
    {
      'id': 'FILE',
      'name': 'FILE',
      'value': 'FILE'
    },
    {
      'id': 'TEXTAREA',
      'name': 'TEXTAREA',
      'value': 'TEXTAREA',
    }
  ];
}
/**
     * @ngdoc method
     * @name CreateDevice#getUploadType
     *
     * @methodOf
     * SharedModule.controller:CreateDevice
     *
     * @description
     * Description: Convert the selected file content to base64
     *
     * @param {type} event
     * @return {type} null
    */
public convertTobase64(event) {
  if (event) {
    for (const file of event.target.files) {
      if (this.files.length > 0) {
         this.files = [];
         this.files.push(file);
      } else {
        this.files.push(file);
      }
    }
    const fileReader: FileReader = new FileReader();
    let text;
    if (event.target.files && event.target.files.length > 0) {
      fileReader.onloadend = (e) => {
        text = fileReader.result;
        this.individualRegistrationForm.get('base64Certificate').setValue(btoa(text));
      };
      fileReader.readAsText(new Blob([event.target.files[0]]));
    }
  }
}

/**
     * @ngdoc method
     * @name CreateDevice#removeFile
     *
     * @methodOf
     * SharedModule.controller:CreateDevice
     *
     * @description
     * Description: Removes files from file array
     *
     * @param index (number)
    */
removeFile(index: number) {
    if (this.individualRegistrationForm.value.base64Certificate) {
          this.files.splice(index, 1);
          this.individualRegistrationForm.value.base64Certificate = null;
    }
}
/**
  * @ngdoc method
  * @name CreateDevice#selectFiles
  *
  * @methodOf
  * SharedModule.controller:CreateDevice
  *
  * @description
  * Description: To calculate size of file.
  *
  * @param bytes (File size in bytes)
  * @param decimals (Decimals point)
  * @return {type} number
*/
formatBytes(bytes, decimals) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const kiloBytes = 1024;
  const decimalValue = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const index = Math.floor(Math.log(bytes) / Math.log(kiloBytes));
  return parseFloat((bytes / Math.pow(kiloBytes, index)).toFixed(decimalValue)) + ' ' + sizes[index];
}

/**
     * @ngdoc method
     * @name CreateDevice#selectFiles
     *
     * @methodOf
     * SharedModule.controller:CreateDevice
     *
     * @description
     * Description: To add seleted excelfiles into file array
     *
     * @param {type} event
     * @return {type} null
    */
   public selectFiles(event) {
    if (event.target.files && event.target.files.length > 0) {
      this.individualRegistrationForm.value.file = event.target.files[0];
      this.files.push(this.individualRegistrationForm.value.file);
    }
  }
  public getSimSupportDetails() {
    if (this.selectedDeviceType && this.selectedDeviceType['deviceProperties'])
    {this.selectedDeviceType['deviceProperties'].find(property => {
      if (property.name === 'DUAL_SIM_SUPPORT' && property.active === true) {
        this.simSupport = 'dualSim';
      } else if (property.name === 'SINGLE_SIM_SUPPORT' && property.active === true) {
        this.simSupport = 'singleSim';
      } else if (property.name === 'NO_SIM_SUPPORT' && property.active === true) {
        this.simSupport = 'noSim';
      }
    });}
  }
}
