import { Component, OnInit, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-fota-device-list',
  templateUrl: './fota-device-list.component.html',
  styleUrls: ['./fota-device-list.component.scss']
})
export class FotaDeviceListComponent implements OnInit, AfterViewInit {
  public dataSource;
  public columns: any[];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];


  constructor(private _matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.columns = Object.keys(this.data['devices'][0]);
    this.dataSource = new MatTableDataSource<any>(this.data['devices']);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

}
