import { EventEmitter, Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';

@Injectable({
    providedIn: 'root'
})
export class AreaChartService {
    defaultOptions;
    chart;
    getOptions() {
        this.defaultOptions = {
            chart: {
                type: 'areaspline',
                height: 28 + '%'
            },
            title: {
                text: ''
            },
            legend: {
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
            },
            xAxis: {
                categories: []
            },
            yAxis: {
                title: {
                    text: ''
                }
            },
            tooltip: {
                shared: true
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                areaspline: {
                    fillOpacity: 0.5
                },
                series: {
                    fillColor: {}
                }
            },
            series: []
        };
        return this.defaultOptions;
    }

    constructor() { }

    public createChart(container, options, chartName = null) {
        const chartOptions = this.getOptions();
        if(chartName === 'whatsappChart') {
            chartOptions['legend'] = {
                itemStyle: {
                    fontSize: 8
                 }
            };
            chartOptions['chart'] = {
                type: 'areaspline',
                height: 350
            };
        }
        const chartData = [];
        options.forEach(data => {
            const chartDataObject = {
                name: '',
                data: '',
                color: '',
                fillColor: {}
            };
            chartDataObject.name = data.name;
            chartDataObject.data = data.values;
            chartDataObject.color = data.color;
            chartDataObject.fillColor = {
                linearGradient: [0, 0, 0, 150],
                stops: [
                    [0, data.color],
                    [1, Highcharts.color(data.color).setOpacity(0).get('rgba')]
                ]
            };
            chartOptions.xAxis.categories = data.dates;
            chartData.push(chartDataObject);
        });
        chartOptions.series = chartData;
        const element = document.createElement('div');
        container.appendChild(element);
        if (!!chartOptions.chart) {
            chartOptions.chart['renderTo'] = element;
        } else {
            options.chart.renderTo = 'area-chart';
        }
        this.chart = new Highcharts.Chart(chartOptions);
        return this.chart;

    }

    public getChart() {
        if(this.chart) {
            return this.chart;
        } else {
            return null;
        }
    }

    public destroyChart(chart) {
        if (chart && chart.userOptions) {
            chart.destroy();
            chart = null;
            this.chart = null;
        }
        return chart;
    }

    public redrawChart(chart, data) {
        chart.addSeries({
            name: data.name,
            data: data.values,
            color: data.color,
            fillColor: {
                linearGradient: [0, 0, 0, 150],
                stops: [
                    [0, data.color],
                    [1, Highcharts.color(data.color).setOpacity(0).get('rgba')]
                ]
            }
        });
    }

    public updateChart(chart, legendName, displayType) {
        if (chart.series) {
            chart.series.forEach(individualSeries => {
                if (individualSeries.name === legendName) {
                    if (displayType === 'hide' && individualSeries.visible) {
                        individualSeries.hide();
                    } else if (displayType === 'show') {
                        individualSeries.show();
                    }
                }
            });
        }
    }
}
