<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="tenantIcon" />
        <span>{{'TENANT_MANAGEMENT_MANAGE' | translate}}</span>
      </span>
    </div>
    <!-- activation/de-activation/deletion of multiple tenants to be taken up in next phase -->
    <!-- <span>
      <button *ngIf="checkPermission('TEN_U')" (click)="gotoModal(popup, null, 'activate')" mat-raised-button
        class="manage-action-btn">{{ 'Activate' | translate }}</button>
      <button *ngIf="checkPermission('TEN_U')" (click)="gotoModal(popup, null, 'deactivate')" class="manage-action-btn"
        mat-raised-button>{{ 'De-activate' | translate }}</button>
      <button *ngIf="checkPermission('TEN_DEL')" (click)="deleteTenant($event)" mat-raised-button color="warn"
        class="manage-action-btn">{{ 'Delete' | translate }}</button>
    </span> -->
    <mat-form-field class="select-box">
      <mat-label class="label">{{'SELECT_ACCOUNT' | translate}}</mat-label>
      <mat-select [placeholder]=" selectedAccount" (selectionChange)="getTenantsByAccountId($event.value,0,pageSize)">
        <mat-option *ngFor="let val of accountValues" [value]="val['id']" required>{{val['name']}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="table-container">
      <mat-table [dataSource]="dataSource" class="table-data" *ngIf="tenantData">
        <div *ngFor="let column of columns">
          <!-- checkbox for activation/de-activation/deletion of multiple tenants -->
          <!-- <ng-container *ngIf="column==='select'" matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)"></mat-checkbox>
            </mat-cell>
          </ng-container> -->
          <ng-container *ngIf="column!=='select'" [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName">
              <span *ngIf="column!=='action' && column!=='active'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column==='action'">
                <mat-slide-toggle [disabled]="!checkPermission('TEN_ACT') && !checkPermission('TEN_DACT')"
                  [(ngModel)]="dataName['active']" (change)="gotoConfirmDialog(dataName, 'edit')">
                </mat-slide-toggle>
                <img *ngIf="checkPermission('TEN_U')" [class.disabled]="!dataName['active']" [src]="editImage"
                  (click)="gotoModal(content, dataName)" class="modify-button">
                <img *ngIf="checkPermission('TEN_DEL')" [src]="deleteImage"
                  (click)="gotoConfirmDialog(dataName, 'delete')" class="modify-button">
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator *ngIf="dataSource" [pageSizeOptions]="pageSizeOptions" [length]="tenantCount" [pageSize]="pageSize"
      showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
<ng-template #content let-modal>
  <div class="close-btn"><img  class="rb-ic" (click)="onClose()"  [src]="closeIcon" /></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{jsonFileLoaded | titlecase}}</h5>
      <p class="disclaimer">{{'PLEASE_ENTER_CORPORATE_DETAILS_ONLY' | translate}}</p>
    </div>
    <div class="modal-body">
      <app-dynamic-form [jsonFile]="jsonFileLoaded" [columnsInForm]="'multi'" [formData]="tenantData"
        [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)"
        (formEmitter)="editTenant($event)"></app-dynamic-form>
    </div>
  </div>
</ng-template>