/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BaseDeviceDto } from '../models/base-device-dto';
import { DeviceEnrollmentCountDto } from '../models/device-enrollment-count-dto';
import { DeviceReferenceDto } from '../models/device-reference-dto';

@Injectable({
  providedIn: 'root',
})
export class ReferenceDataControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findDeviceByDeviceId
   */
  static readonly FindDeviceByDeviceIdPath = '/api/v1/device/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findDeviceByDeviceId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findDeviceByDeviceId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<BaseDeviceDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReferenceDataControllerService.FindDeviceByDeviceIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BaseDeviceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findDeviceByDeviceId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findDeviceByDeviceId(params: {
    id: string;
  }): Observable<BaseDeviceDto> {

    return this.findDeviceByDeviceId$Response(params).pipe(
      map((r: StrictHttpResponse<BaseDeviceDto>) => r.body as BaseDeviceDto)
    );
  }

  /**
   * Path part for operation findDeviesByBusinessProfileId
   */
  static readonly FindDeviesByBusinessProfileIdPath = '/api/v1/businessProfile/{businessProfileId}/devices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findDeviesByBusinessProfileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findDeviesByBusinessProfileId$Response(params: {
    businessProfileId: string;
    isDeviceDeleted?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<Array<BaseDeviceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ReferenceDataControllerService.FindDeviesByBusinessProfileIdPath, 'get');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.query('isDeviceDeleted', params.isDeviceDeleted, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BaseDeviceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findDeviesByBusinessProfileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findDeviesByBusinessProfileId(params: {
    businessProfileId: string;
    isDeviceDeleted?: boolean;
    page?: number;
    limit?: number;
  }): Observable<Array<BaseDeviceDto>> {

    return this.findDeviesByBusinessProfileId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BaseDeviceDto>>) => r.body as Array<BaseDeviceDto>)
    );
  }

  /**
   * Path part for operation countDevicesByBusinessProfileId
   */
  static readonly CountDevicesByBusinessProfileIdPath = '/api/v1/businessProfile/{businessProfileId}/devices/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countDevicesByBusinessProfileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  countDevicesByBusinessProfileId$Response(params: {
    active?: string;
    businessProfileId: string;
    isIndividualReqd: boolean;
    isGroupReqd: boolean;
  }): Observable<StrictHttpResponse<{
[key: string]: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ReferenceDataControllerService.CountDevicesByBusinessProfileIdPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.query('isIndividualReqd', params.isIndividualReqd, {});
      rb.query('isGroupReqd', params.isGroupReqd, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: number;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countDevicesByBusinessProfileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  countDevicesByBusinessProfileId(params: {
    active?: string;
    businessProfileId: string;
    isIndividualReqd: boolean;
    isGroupReqd: boolean;
  }): Observable<{
[key: string]: number;
}> {

    return this.countDevicesByBusinessProfileId$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: number;
}>) => r.body as {
[key: string]: number;
})
    );
  }

  /**
   * Path part for operation countDevicesEnrollmentsByBusinessProfileIdAndSolutionProviderId
   */
  static readonly CountDevicesEnrollmentsByBusinessProfileIdAndSolutionProviderIdPath = '/api/v1/businessProfile/{businessProfileId}/devices/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countDevicesEnrollmentsByBusinessProfileIdAndSolutionProviderId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  countDevicesEnrollmentsByBusinessProfileIdAndSolutionProviderId$Response(params: {
    active?: string;
    businessProfileId: string;
    isIndividualReqd: boolean;
    isGroupReqd: boolean;
    body?: DeviceEnrollmentCountDto
  }): Observable<StrictHttpResponse<{
[key: string]: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, ReferenceDataControllerService.CountDevicesEnrollmentsByBusinessProfileIdAndSolutionProviderIdPath, 'post');
    if (params) {
      rb.query('active', params.active, {});
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.query('isIndividualReqd', params.isIndividualReqd, {});
      rb.query('isGroupReqd', params.isGroupReqd, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: number;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `countDevicesEnrollmentsByBusinessProfileIdAndSolutionProviderId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  countDevicesEnrollmentsByBusinessProfileIdAndSolutionProviderId(params: {
    active?: string;
    businessProfileId: string;
    isIndividualReqd: boolean;
    isGroupReqd: boolean;
    body?: DeviceEnrollmentCountDto
  }): Observable<{
[key: string]: number;
}> {

    return this.countDevicesEnrollmentsByBusinessProfileIdAndSolutionProviderId$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: number;
}>) => r.body as {
[key: string]: number;
})
    );
  }

  /**
   * Path part for operation findDeviceReferenceById
   */
  static readonly FindDeviceReferenceByIdPath = '/api/v1/devicereference/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findDeviceReferenceById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findDeviceReferenceById$Response(params: {
    id: string;
    isDeviceDeleted?: boolean;
  }): Observable<StrictHttpResponse<DeviceReferenceDto>> {

    const rb = new RequestBuilder(this.rootUrl, ReferenceDataControllerService.FindDeviceReferenceByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('isDeviceDeleted', params.isDeviceDeleted, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DeviceReferenceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findDeviceReferenceById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findDeviceReferenceById(params: {
    id: string;
    isDeviceDeleted?: boolean;
  }): Observable<DeviceReferenceDto> {

    return this.findDeviceReferenceById$Response(params).pipe(
      map((r: StrictHttpResponse<DeviceReferenceDto>) => r.body as DeviceReferenceDto)
    );
  }

}
