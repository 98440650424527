/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { USER_IMAGE, MAIL_ICON, OPT_OUT_ICON } from '../constants/strings';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
/**
  * @ngdoc component
  * @name SharedModule.component:Menu
  *
  *
  * @description
  * description: To create dropdown menu for header component
  *
*/
export class MenuComponent implements OnInit {

  @Input() icon: boolean;
  @Input() listOptions: any;
  @Input() faqOptions: any;
  @Input() classList: String;
  @Input() dropDown: boolean;
  @Input() options: any;
  @Input() optionsWithIcon: any;
  @Input() defaultValue: any;
  @Input() dropDownWithIcon: any;
  @Input() userProfileName: any;
  @Input() userProfileRole: any;
  routerUrl: any;
  @Output() languageChange: EventEmitter<any> = new EventEmitter();
  public userIcon;
  public mailIcon;
  public optOutIcon;

  constructor(protected router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.routerUrl = evt.url;
      }
    });
    this.userIcon = USER_IMAGE;
    this.mailIcon = MAIL_ICON;
    this.optOutIcon = OPT_OUT_ICON;
  }

  /**
    * @ngdoc method
    * @name Menu#selectOption
    *
    * @methodOf
    * SharedModule.controller:Menu
    *
    * @description
    * Description: toggles the selected options.
    *
    * @param {type} name description: selected option of language drop down.
    * @return {type} name description: value changes as with the selected option.
  */
  selectOption(selectedOption) {
    this.languageChange.emit(selectedOption);
  }

  /**
    * @ngdoc method
    * @name Menu#optionSelectedListOption
    *
    * @methodOf
    * SharedModule.controller:Menu
    *
    * @description
    * Description: toggles the selected options.
    *
    * @param {type} name description: selected option drop down with icon.
    * @return {type} name description: value changes as with the selected option.
  */
  optionSelectedListOption(option) {
    this.languageChange.emit(option);
  }
}
