/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CanAuthenticationGuard } from '../providers/auth-guard';

/**
  * @ngdoc overview
  * @name MODULE_NAME.module:HomeModule
  *
  * @description
  * description: To route to different components in home module based on the path given
*/
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [CanAuthenticationGuard],
    data: {permission: 'HOME_R', data: {}}
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [CanAuthenticationGuard],
    data: {permission: 'HOME_R', data: {}}
  },
  {
    path: 'home/transactions',
    component: HomeComponent,
    canActivate: [CanAuthenticationGuard],
    data: {permission: 'HOME_R', data: {}}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
