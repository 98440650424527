/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
    constructor(private translate: TranslateService) { }

    /* @description: for i18n implementation of error messages across all modules
    */
    public translateErrorMessages(errorMsg) {
        let translatedErrorMsg;
        this.translate.get(errorMsg).subscribe((text: string) => {
            translatedErrorMsg = text;
        });
        return translatedErrorMsg;
    }
}
