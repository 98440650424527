    <section class="overview-container">
      <section class="main">
        <div class="header-style">
          <span class="heading-label">
            <img class="page-icon" [src]="deviceIcon" />
            <span>{{'INDIVIDUAL_DEVICE_REGISTRATION' |translate}}</span>
          </span>
        </div>
        <div class="">
           <p class="disclaimer" *ngIf="provider && provider['name']=== env['providerBosch']">{{'INFO_MSG_FOR_INDIVIDUAL_AND_GROUP_REGISTRATION' | translate}}</p>
      <form [formGroup]="individualRegistrationForm" class="create-form-spacing form-fields">
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'ACCOUNT' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="account"  [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'tenant')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['account']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.account?.touched && individualRegistrationForm.controls.account?.errors">
            <div *ngIf="individualRegistrationForm.controls.account?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'ACCOUNT' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'TENANT' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="tenant" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'businessProfile')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['tenant']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.tenant?.touched && individualRegistrationForm.controls.tenant?.errors">
            <div *ngIf="individualRegistrationForm.controls.tenant?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'TENANT' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'BUSINESS_PROFILE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="businessProfile" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'solutionType')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['businessProfile']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.businessProfile?.touched && individualRegistrationForm.controls.businessProfile?.errors">
            <div *ngIf="individualRegistrationForm.controls.businessProfile?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'BUSINESS_PROFILE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'SOLUTION_TYPE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="solutionType" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'provider')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['solutionType']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.solutionType?.touched && individualRegistrationForm.controls.solutionType?.errors">
            <div *ngIf="individualRegistrationForm.controls.solutionType?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'SOLUTION_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'DEVICE_INTERFACE_PROVIDER' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="provider" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'iotHub')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['provider']" [value]="val.id">
                {{val.name | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.provider?.touched && individualRegistrationForm.controls.provider?.errors">
            <div *ngIf="individualRegistrationForm.controls.provider?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'DEVICE_INTERFACE_PROVIDER' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'IOT_HUB' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="iotHub" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'deviceType')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['iotHub']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.iotHub?.touched && individualRegistrationForm.controls.iotHub?.errors">
            <div *ngIf="individualRegistrationForm.controls.iotHub?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'IOT_HUB' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'DEVICE_TYPE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="deviceType" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'attestationType')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['deviceType']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.deviceType?.touched && individualRegistrationForm.controls.deviceType?.errors">
            <div *ngIf="individualRegistrationForm.controls.deviceType?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'DEVICE_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'ATTESTATION_TYPE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="attestationType" [placeholder]=" 'SELECT' | translate"
             (selectionChange)="provider['name']=== env['providerAzure']? updateDropdownValues($event,'criteria'):  updateDropdownValues($event,'category')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['attestationType']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.attestationType?.touched && individualRegistrationForm.controls.attestationType?.errors">
            <div *ngIf="individualRegistrationForm.controls.attestationType?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'ATTESTATION_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{'DEVICE_SERIAL_NUMBER' | translate}} <span class="mandatory">*</span>
            </mat-label>
            <input type="text" matInput formControlName="deviceSerialNo" class="form-field"
              [placeholder]=" 'TYPE_HERE' | translate" pattern="^[a-zA-Z0-9\\-]+$"/>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.deviceSerialNo?.touched && individualRegistrationForm.controls.deviceSerialNo?.errors">
            <div *ngIf="individualRegistrationForm.controls.deviceSerialNo?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'DEVICE_SERIAL_NUMBER' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.deviceSerialNo?.errors?.minlength">
              <mat-error class="mat-error-class">
                {{ 'DEVICE_SERIAL_NUMBER_SHOULD_BE_OF_MINIMUM_3_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.deviceSerialNo?.errors?.maxlength">
              <mat-error class="mat-error-class">
                {{ 'DEVICE_SERIAL_NUMBER_SHOULD_BE_OF_MAXIMUM_50_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.deviceSerialNo?.errors?.pattern">
              <mat-error class="mat-error-class">
                {{ 'PLEASE_ENTER_A_VALID_DEVICE_SERIAL_NUMBER'| translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{'IMEI_NUMBER' | translate}} 
              <span class="mandatory" *ngIf="provider && (provider['name'] === env['providerZelIot'])">*</span>
            </mat-label>
            <input type="text" matInput formControlName="imeiNo" class="form-field"
              [placeholder]=" 'TYPE_HERE' | translate" pattern="[0-9]+"/>
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.imeiNo?.touched && individualRegistrationForm.controls.imeiNo?.errors">
            <div *ngIf="individualRegistrationForm.controls.imeiNo?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'IMEI_NUMBER' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.imeiNo?.errors?.minlength">
              <mat-error class="mat-error-class">
                {{ 'IMEI_NUMBER_SHOULD_BE_OF_15_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.imeiNo?.errors?.maxlength">
              <mat-error class="mat-error-class">
                {{ 'IMEI_NUMBER_SHOULD_BE_OF_15_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.imeiNo?.errors?.pattern">
              <mat-error class="mat-error-class">
                {{ 'PLEASE_ENTER_A_VALID_IMEI_NUMBER'| translate }}
              </mat-error>
            </div>
          </div>
        </div>
         <div *ngIf="simSupport !== 'noSim'">
          <mat-form-field>
            <mat-label class="label">{{(simSupport === 'singleSim'?'SIM_ID': 'SIM_REFERENCE_ID_1') | translate}}
              <!-- <span class="mandatory" *ngIf="provider && (provider['name'] === env['providerZelIot'])">*</span> -->
            </mat-label>
            <input type="text" matInput formControlName="primarySim" class="form-field"
              [placeholder]=" 'TYPE_HERE' | translate" pattern="^[a-zA-Z0-9\\-]+$" />
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.primarySim?.touched && individualRegistrationForm.controls.primarySim?.errors">
            <div *ngIf="individualRegistrationForm.controls.primarySim?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'SIM_REFERENCE_ID' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.primarySim?.errors?.minlength">
              <mat-error class="mat-error-class">
                {{ 'SIM_REFERENCE_ID_SHOULD_BE_OF_36_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.primarySim?.errors?.maxlength">
              <mat-error class="mat-error-class">
                {{ 'SIM_REFERENCE_ID_SHOULD_BE_OF_36_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.primarySim?.errors?.pattern">
              <mat-error class="mat-error-class">
                {{ 'PLEASE_ENTER_A_VALID_SIM_REFERENCE_ID'| translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div *ngIf="simSupport === 'dualSim'">
          <mat-form-field>
            <mat-label class="label">{{'SIM_REFERENCE_ID_2' | translate}}
              <!-- <span class="mandatory" *ngIf="provider && (provider['name'] === env['providerZelIot'])">*</span> -->
            </mat-label>
            <input type="text" matInput formControlName="secondarySim" class="form-field"
              [placeholder]=" 'TYPE_HERE' | translate" pattern="^[a-zA-Z0-9\\-]+$" />
          </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.secondarySim?.touched && individualRegistrationForm.controls.secondarySim?.errors">
            <div *ngIf="individualRegistrationForm.controls.secondarySim?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'SIM_REFERENCE_ID' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.secondarySim?.errors?.minlength">
              <mat-error class="mat-error-class">
                {{ 'SIM_REFERENCE_ID_SHOULD_BE_OF_36_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.secondarySim?.errors?.maxlength">
              <mat-error class="mat-error-class">
                {{ 'SIM_REFERENCE_ID_SHOULD_BE_OF_36_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.secondarySim?.errors?.pattern">
              <mat-error class="mat-error-class">
                {{ 'PLEASE_ENTER_A_VALID_SIM_REFERENCE_ID'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.secondarySim?.errors?.incorrectSim">
              <mat-error class="mat-error-class">
              {{ 'SHOULD_NOT_SAME'| translate}}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field class="mat-form-field">
            <mat-label class="label">{{'TAGS' | translate}}</mat-label>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let val of selectedValuesArray" class="mat-chip" [selectable]="selectable"
              [removable]="removable" (removed)="removeEntities(val)" [value]="val">
                {{val | translate }}
                <mat-icon matChipRemove *ngIf="removable" class="rb-ic" fontIcon="bosch-ic-close"></mat-icon>
                
              </mat-chip>
            </mat-chip-list>
            <input class="mat-chip-input"[formControl]="tags" [placeholder]="'TYPE_HERE' | translate" #inputCtrl [matAutocomplete]="auto"
                [matChipInputFor]="chipList" 
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addValues($event)">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectEntitiesFromList($event)">
              <mat-option *ngFor="let val of filteredStrings | async "  [value]="val">
                {{val | translate}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
      </div>
        <div *ngIf ="provider && ((provider['name'] === env['providerAzure'] || provider['name'] === env['providerAws']) && this.attestationType === 'X509' )">
          <mat-form-field>
            <mat-label class="label">{{ 'UPLOAD_TYPE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="criteria" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event)">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['criteria']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="submitted || individualRegistrationForm.controls.criteria?.touched && individualRegistrationForm.controls.criteria?.errors">
            <div *ngIf="individualRegistrationForm.controls.criteria?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'UPLOAD_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div *ngIf="((displayCertInput && provider && provider['name'] === env['providerAzure']) ||
        (displayCertInput && provider && provider['name'] === env['providerAws']))" >
          <mat-form-field floatLabel="always">
            <mat-label class="label">{{('SIGNER_CERTIFICATE') | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <textarea class="form-control text-area dynamic-input" matInput formControlName="cert"
                [placeholder]="'TYPE_HERE' | translate" rows="4">
            </textarea>
        </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.cert?.touched && individualRegistrationForm.controls.cert?.errors">
            <div *ngIf="individualRegistrationForm.controls.cert?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'SIGNER_CERTIFICATE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div *ngIf="((displayFileInput && provider && provider['name'] === env['providerAzure']) ||
        (displayFileInput && provider && provider['name'] === env['providerAws']))">
          <input type="file" fromControlName="base64Certificate" #inputCertificate class="select-file" id="file"
              (click)="inputCertificate.value = null"
              (change)="convertTobase64($event)"/>
          <label class="input-label" for="file">{{ 'UPLOAD_FILE' | translate }}</label>
          <div class="files-list">
            <div class="single-file" *ngFor="let file of files; let indexNumber = index">
                <div class="file-icon">
                    <img [src]="documentImage" />
                </div>
                <div class="info">
                    <h4 class="name"> {{ file?.name }} </h4>
                    <p class="size"> {{ formatBytes(file?.size) }} </p>
                </div>
                <div class="delete" (click)="removeFile(indexNumber, file?.type)">
                    <img [src]="deleteImage" class="modify-button">
                </div>
            </div>
        </div>
          <div *ngIf="submitted || individualRegistrationForm.controls.base64Certificate?.touched && individualRegistrationForm.controls.base64Certificate?.errors">
          <div *ngIf="individualRegistrationForm.controls.base64Certificate?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'SIGNER_CERTIFICATE' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
        </div>
      </div>
      <div *ngIf = "provider && provider['name'] === env['providerBosch']">
        <mat-form-field>
          <mat-label class="label">{{ 'DEVICE_CATEGORY' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-select class="form-field" formControlName="category" [placeholder]=" 'SELECT' | translate"
           (selectionChange)="updateDropdownValues($event, 'deviceCategory')">
            <mat-option>{{ 'SELECT' | translate }}</mat-option>
            <mat-option *ngFor="let val of dropDownValues['category']" [value]="val.id">
              {{val.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div
          *ngIf="submitted || individualRegistrationForm.controls.category?.touched && individualRegistrationForm.controls.category?.errors">
          <div *ngIf="individualRegistrationForm.controls.category?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'DEVICE_CATEGORY' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
        </div>
      </div>
      <div *ngIf = "provider && provider['name'] === env['providerBosch'] && selectedCategory==='Edge'">
        <mat-form-field>
          <mat-label class="label">{{'GATEWAY_DEVICE_NAME' | translate}} <span class="mandatory">*</span>
          </mat-label>
          <input type="text" matInput formControlName="gatewayDeviceName" class="form-field"
            [placeholder]=" 'TYPE_HERE' | translate"/>
        </mat-form-field>
        <div
          *ngIf="submitted || individualRegistrationForm.controls.gatewayDeviceName?.touched && individualRegistrationForm.controls.gatewayDeviceName?.errors">
          <div *ngIf="individualRegistrationForm.controls.gatewayDeviceName?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'GATEWAY_DEVICE_NAME' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
          <div *ngIf="individualRegistrationForm.controls.gatewayDeviceName?.errors?.maxlength">
            <mat-error class="mat-error-class">
              {{ 'GATEWAY_DEVICE_NAME_SHOULD_BE_OF_MAXIMUM_50_CHARACTERS'| translate }}
            </mat-error>
          </div>
        </div>
      </div>
      <div *ngIf = "provider && provider['name'] === env['providerBosch'] && (selectedCategory === 'Gateway' || selectedCategory === 'Standalone')">
        <mat-form-field>
          <mat-label class="label">{{'CERTIFICATE_SUBJECT' | translate}} <span class="mandatory">*</span>
          </mat-label>
          <input type="text" matInput formControlName="certificateSubject" class="form-field"
            [placeholder]=" 'CERT_SUBJECT_PLACEHOLDER' | translate"/>
        </mat-form-field>
        <div
          *ngIf="submitted || individualRegistrationForm.controls.certificateSubject?.touched && individualRegistrationForm.controls.certificateSubject?.errors">
          <div *ngIf="individualRegistrationForm.controls.certificateSubject?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'CERTIFICATE_SUBJECT' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
          <div *ngIf="individualRegistrationForm.controls.certificateSubject?.errors?.maxlength">
            <mat-error class="mat-error-class">
              {{ 'CERTIFICATE_SUBJECT_SHOULD_BE_OF_MAXIMUM_150_CHARACTERS'| translate }}
            </mat-error>
          </div>
        </div>
      </div>
      <div *ngIf="provider && provider['name'] === env['providerAzure'] && attestationType==='SYMMETRICKEY'">
        <mat-form-field>
          <mat-label class="label">{{'PRIMARY_SYMMETRIC_KEY' | translate}} <span class="mandatory">*</span>
          </mat-label>
          <input type="text" matInput formControlName="symmetricPrimaryKey" class="form-field"
            [placeholder]=" 'TYPE_HERE' | translate"/>
        </mat-form-field>
        <div
          *ngIf="submitted || individualRegistrationForm.controls.symmetricPrimaryKey?.touched && individualRegistrationForm.controls.symmetricPrimaryKey?.errors">
          <div *ngIf="individualRegistrationForm.controls.symmetricPrimaryKey?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'PRIMARY_SYMMETRIC_KEY' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
          <div *ngIf="individualRegistrationForm.controls.symmetricPrimaryKey?.errors?.minlength">
            <mat-error class="mat-error-class">
              {{ 'PRIMARY_SYMMETRIC_KEY_SHOULD_BE_OF_MINIMUM_3_CHARACTERS'| translate }}
            </mat-error>
          </div>
          <div *ngIf="individualRegistrationForm.controls.symmetricPrimaryKey?.errors?.maxlength">
            <mat-error class="mat-error-class">
              {{ 'PRIMARY_SYMMETRIC_KEY_SHOULD_BE_OF_MAXIMUM_100_CHARACTERS'| translate }}
            </mat-error>
          </div>
        </div>
      </div>
      <div *ngIf="provider && provider['name'] === env['providerAzure'] && attestationType==='SYMMETRICKEY'">
        <mat-form-field>
          <mat-label class="label">{{'SECONDARY_SYMMETRIC_KEY' | translate}} <span class="mandatory">*</span>
          </mat-label>
          <input type="text" matInput formControlName="symmetricSecondaryKey" class="form-field"
            [placeholder]=" 'TYPE_HERE' | translate"/>
        </mat-form-field>
        <div
          *ngIf="submitted || individualRegistrationForm.controls.symmetricSecondaryKey?.touched && individualRegistrationForm.controls.symmetricSecondaryKey?.errors">
          <div *ngIf="individualRegistrationForm.controls.symmetricSecondaryKey?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'SECONDARY_SYMMETRIC_KEY' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
          <div *ngIf="individualRegistrationForm.controls.symmetricSecondaryKey?.errors?.minlength">
            <mat-error class="mat-error-class">
              {{ 'SECONDARY_SYMMETRIC_KEY_SHOULD_BE_OF_MINIMUM_3_CHARACTERS'| translate }}
            </mat-error>
          </div>
          <div *ngIf="individualRegistrationForm.controls.symmetricSecondaryKey?.errors?.maxlength">
            <mat-error class="mat-error-class">
              {{ 'SECONDARY_SYMMETRIC_KEY_SHOULD_BE_OF_MAXIMUM_100_CHARACTERS'| translate }}
            </mat-error>
          </div>
        </div>
      </div>
      <div *ngIf="provider && provider['name'] === env['providerAzure'] && attestationType==='TPM'">
          <mat-form-field floatLabel="always">
            <mat-label class="label">{{'TPM_KEY' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <textarea class="form-control text-area dynamic-input" matInput formControlName="tpmEndorsementKey"
                [placeholder]="'TYPE_HERE' | translate" rows="4">
            </textarea>
        </mat-form-field>
          <div
            *ngIf="submitted || individualRegistrationForm.controls.tpmEndorsementKey?.touched && individualRegistrationForm.controls.tpmEndorsementKey?.errors">
            <div *ngIf="individualRegistrationForm.controls.tpmEndorsementKey?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'TPM_KEY' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.symmetricSecondaryKey?.errors?.minlength">
              <mat-error class="mat-error-class">
                {{ 'TPM_KEY_SHOULD_BE_OF_MINIMUM_3_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="individualRegistrationForm.controls.symmetricSecondaryKey?.errors?.maxlength">
              <mat-error class="mat-error-class">
                {{ 'TPM_KEY_SHOULD_BE_OF_MAXIMUM_500_CHARACTERS'| translate }}
              </mat-error>
            </div>
          </div>

        </div>
        
      </form>
      <div>
        <section class="section">
          <button (click)="createDevice()" mat-raised-button class="button button-submit">{{ 'SUBMIT' | translate }}</button>
          <button (click)="onReset()" mat-button class="button">{{ 'RESET' | translate }}</button>
        </section>
      </div>   
        </div>
      </section>

      <section *ngIf="asideMenus">
        <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
      </section>
    </section>
    