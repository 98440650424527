/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessKeyDetailsDto } from '../models/access-key-details-dto';
import { SolutionsAccessReportDto } from '../models/solutions-access-report-dto';

@Injectable({
  providedIn: 'root',
})
export class UsageSummaryControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAccessKeyDetails
   */
  static readonly GetAccessKeyDetailsPath = '/api/v1/accesskeydetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessKeyDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyDetails$Response(params?: {

  }): Observable<StrictHttpResponse<Array<AccessKeyDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UsageSummaryControllerService.GetAccessKeyDetailsPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessKeyDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessKeyDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyDetails(params?: {

  }): Observable<Array<AccessKeyDetailsDto>> {

    return this.getAccessKeyDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AccessKeyDetailsDto>>) => r.body as Array<AccessKeyDetailsDto>)
    );
  }

  /**
   * Path part for operation deleteAcessDetailsInRedis
   */
  static readonly DeleteAcessDetailsInRedisPath = '/api/v1/accesskeydetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAcessDetailsInRedis()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAcessDetailsInRedis$Response(params?: {

  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, UsageSummaryControllerService.DeleteAcessDetailsInRedisPath, 'delete');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAcessDetailsInRedis$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAcessDetailsInRedis(params?: {

  }): Observable<void> {

    return this.deleteAcessDetailsInRedis$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getUsageSummary
   */
  static readonly GetUsageSummaryPath = '/api/v1/usagesummmary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUsageSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsageSummary$Response(params?: {
    moduleName?: string;

  }): Observable<StrictHttpResponse<Array<SolutionsAccessReportDto>>> {

    const rb = new RequestBuilder(this.rootUrl, UsageSummaryControllerService.GetUsageSummaryPath, 'get');
    if (params) {

      rb.query('moduleName', params.moduleName, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SolutionsAccessReportDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUsageSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUsageSummary(params?: {
    moduleName?: string;

  }): Observable<Array<SolutionsAccessReportDto>> {

    return this.getUsageSummary$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SolutionsAccessReportDto>>) => r.body as Array<SolutionsAccessReportDto>)
    );
  }

  /**
   * Path part for operation saveUsageSummary
   */
  static readonly SaveUsageSummaryPath = '/api/v1/usagesummmary';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveUsageSummary()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveUsageSummary$Response(params: {
    accessInfo: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, UsageSummaryControllerService.SaveUsageSummaryPath, 'post');
    if (params) {

      rb.query('accessInfo', params.accessInfo, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveUsageSummary$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveUsageSummary(params: {
    accessInfo: string;

  }): Observable<string> {

    return this.saveUsageSummary$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
