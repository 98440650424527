<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <span class="heading-label">
          <img class="page-icon" [src]="applnGraphIcon" />
          <span class="header-span">{{'APPLN_GRPH' | translate}}</span>
          <div class="header-filter-div">
          <mat-form-field class="interal-dropdown"  >
            <mat-label>{{ 'TIME_INTERVAL' | translate }}</mat-label>
            <mat-select   class="interval-mat-select"
            placeholder="{{ 'SELECT' | translate }}" 
              [(ngModel)]="selectedInterval">
              <mat-option *ngFor="let val of dropDownValues['intervals']" [value]="val['id']" required
             > {{val['name'] | translate}}
              </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="interal-dropdown"  >
          <mat-label>{{ 'EXISTING_GRAPH' | translate }}</mat-label>
            <mat-select class="interval-mat-select"
            placeholder="{{ 'EXISTING_GRAPH' | translate }}" multiple [(ngModel)]="selectedGraph" (selectionChange)="onSelection($event)" 
            (closed)="regenerateGraph()">
              <mat-option *ngFor="let val of dropDownValues['Existing Graphs']" [value]="val" [disabled]="isDisabled(val)" required>
                {{val['graphName'] | translate}}
              </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
        </span>
      </div>
      <div class="create-form-spacing">
        
      <app-dynamic-form [jsonFile]="jsonFileLoaded" [columnsInForm]="'single'" 
      (formEmitter)="generateGraphs($event)" (resetForm)="resetForm($event)">
      </app-dynamic-form>
     
      <div class="usage-card" >
      
        <div *ngIf="cpu" class="usage-summary">
          <div class="usage-chart-area">
            <div  #cpuUsageCharts class="highcharts-container">
            <div class="chart-header">
              <span class="chart-labels">{{'CPU_USG' | translate}}</span>
            </div>
            </div>
          </div>
        </div>

        <div *ngIf="memory" class="usage-summary">
        <div  class="usage-chart-area">
          <div #memoryUsageCharts class="highcharts-container">
            <div class="chart-header">
              <span class="chart-labels">{{'MEMORY_USG' | translate}}</span>
          </div>
          </div>
        </div>
         </div>
      <div *ngIf="freeDisk" class="usage-summary">
        <div class="usage-chart-area">
          <div #freeDiskUsageCharts class="highcharts-container">
            <div class="chart-header">
              <span class="chart-labels">{{'FREE_DISK_USG' | translate}}</span>
          </div>
          </div>
        </div>
      </div>
      <div *ngIf="networkIn" class="usage-summary">
        <div class="usage-chart-area">
          <div #netInUsageCharts class="highcharts-container">
            <div class="chart-header">
              <span class="chart-labels">{{'NETWORK_IN_USG' | translate}}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="networkOut" class="usage-summary">
        <div class="usage-chart-area">
          <div #netOutUsageCharts class="highcharts-container">
            <div class="chart-header">
              <span class="chart-labels">{{'NETWORK_OUT_USG' | translate}}</span>
            </div>
          </div>
        </div>
      </div> 
    </div> 
    </div>
    <div  *ngIf='this.graphsLength && !existingGraph' class="action-buttons">
      <button (click)="saveDynamicGraphs()" mat-raised-button
      class="button button-submit">{{ 'SAVE_PROCEED' | translate }}</button>
  </div>
    </section>
    <!-- <section *ngIf="asideMenus">
      <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
    </section> -->
  </section>