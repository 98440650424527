/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ChatRoomDetailsResponseDto } from '../models/chat-room-details-response-dto';
import { DepartmentDto } from '../models/department-dto';

@Injectable({
  providedIn: 'root',
})
export class DepartmentControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createDepartment
   */
  static readonly CreateDepartmentPath = '/api/v1/department';

  /**
   * Create Department.
   *
   * API to create an department for specified Channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDepartment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDepartment$Response(params: {
    body: DepartmentDto
  }): Observable<StrictHttpResponse<DepartmentDto>> {

    const rb = new RequestBuilder(this.rootUrl, DepartmentControllerService.CreateDepartmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DepartmentDto>;
      })
    );
  }

  /**
   * Create Department.
   *
   * API to create an department for specified Channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDepartment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDepartment(params: {
    body: DepartmentDto
  }): Observable<DepartmentDto> {

    return this.createDepartment$Response(params).pipe(
      map((r: StrictHttpResponse<DepartmentDto>) => r.body as DepartmentDto)
    );
  }

  /**
   * Path part for operation getAgentChatRooms
   */
  static readonly GetAgentChatRoomsPath = '/api/v1/department/{departmentId}/chatRooms';

  /**
   * Get Department Chat Rooms.
   *
   * API to get all department related chat rooms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentChatRooms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentChatRooms$Response(params: {
    departmentId: string;
    channelNumber?: string;
    active?: boolean;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ChatRoomDetailsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, DepartmentControllerService.GetAgentChatRoomsPath, 'get');
    if (params) {
      rb.path('departmentId', params.departmentId, {});
      rb.query('channelNumber', params.channelNumber, {});
      rb.query('active', params.active, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatRoomDetailsResponseDto>;
      })
    );
  }

  /**
   * Get Department Chat Rooms.
   *
   * API to get all department related chat rooms
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgentChatRooms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentChatRooms(params: {
    departmentId: string;
    channelNumber?: string;
    active?: boolean;
    page?: number;
    limit?: number;
  }): Observable<ChatRoomDetailsResponseDto> {

    return this.getAgentChatRooms$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRoomDetailsResponseDto>) => r.body as ChatRoomDetailsResponseDto)
    );
  }

  /**
   * Path part for operation getDepartmentsByChannelNumber
   */
  static readonly GetDepartmentsByChannelNumberPath = '/api/v1/channel/{channelNumber}/department';

  /**
   * Get Departments by Channel Number.
   *
   * API to get departments by channel number
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDepartmentsByChannelNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepartmentsByChannelNumber$Response(params: {
    channelNumber: string;
  }): Observable<StrictHttpResponse<Array<DepartmentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DepartmentControllerService.GetDepartmentsByChannelNumberPath, 'get');
    if (params) {
      rb.path('channelNumber', params.channelNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DepartmentDto>>;
      })
    );
  }

  /**
   * Get Departments by Channel Number.
   *
   * API to get departments by channel number
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDepartmentsByChannelNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDepartmentsByChannelNumber(params: {
    channelNumber: string;
  }): Observable<Array<DepartmentDto>> {

    return this.getDepartmentsByChannelNumber$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DepartmentDto>>) => r.body as Array<DepartmentDto>)
    );
  }

}
