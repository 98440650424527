/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActivityLogDto } from '../models/activity-log-dto';
import { CommonId } from '../models/common-id';
import { MessageDto } from '../models/message-dto';
import { SubscriptionMetadataDto } from '../models/subscription-metadata-dto';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation reviewOk
   */
  static readonly ReviewOkPath = '/api/v1/subscription-excel/review-ok';

  /**
   * Review Billing Excel.
   *
   * The API updates the status to review of billing excel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewOk()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewOk$Response(params: {
    orderId: string;
    month: number;
    year: number;
    comments?: string;
  }): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.ReviewOkPath, 'put');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('month', params.month, {});
      rb.query('year', params.year, {});
      rb.query('comments', params.comments, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * Review Billing Excel.
   *
   * The API updates the status to review of billing excel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reviewOk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewOk(params: {
    orderId: string;
    month: number;
    year: number;
    comments?: string;
  }): Observable<MessageDto> {

    return this.reviewOk$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }

  /**
   * Path part for operation reviewNotOk
   */
  static readonly ReviewNotOkPath = '/api/v1/subscription-excel/review-not-ok';

  /**
   * Review not ok Billing Excel.
   *
   * The API updates the status and delete billing excel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewNotOk()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewNotOk$Response(params: {
    orderId: string;
    month: number;
    year: number;
    comments: string;
  }): Observable<StrictHttpResponse<SubscriptionMetadataDto>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.ReviewNotOkPath, 'patch');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('month', params.month, {});
      rb.query('year', params.year, {});
      rb.query('comments', params.comments, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionMetadataDto>;
      })
    );
  }

  /**
   * Review not ok Billing Excel.
   *
   * The API updates the status and delete billing excel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reviewNotOk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewNotOk(params: {
    orderId: string;
    month: number;
    year: number;
    comments: string;
  }): Observable<SubscriptionMetadataDto> {

    return this.reviewNotOk$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionMetadataDto>) => r.body as SubscriptionMetadataDto)
    );
  }

  /**
   * Path part for operation updateStatusUploadedToInReview
   */
  static readonly UpdateStatusUploadedToInReviewPath = '/api/v1/subscription-excel/in-review/{subscriptionMetaDataId}';

  /**
   * Review Billing Excel Status.
   *
   * The API updates the status uploaded to In Review of billing excel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateStatusUploadedToInReview()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusUploadedToInReview$Response(params: {
    subscriptionMetaDataId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.UpdateStatusUploadedToInReviewPath, 'patch');
    if (params) {
      rb.path('subscriptionMetaDataId', params.subscriptionMetaDataId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Review Billing Excel Status.
   *
   * The API updates the status uploaded to In Review of billing excel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateStatusUploadedToInReview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateStatusUploadedToInReview(params: {
    subscriptionMetaDataId: string;
  }): Observable<string> {

    return this.updateStatusUploadedToInReview$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation activateDeactivateSubscription
   */
  static readonly ActivateDeactivateSubscriptionPath = '/api/v1/subscriptions/{subscriptionId}';

  /**
   * Subscription status.
   *
   * The API Upates subscription Activate or Deactivate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateDeactivateSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateDeactivateSubscription$Response(params: {
    subscriptionId: string;
    body?: CommonId
  }): Observable<StrictHttpResponse<CommonId>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.ActivateDeactivateSubscriptionPath, 'patch');
    if (params) {
      rb.path('subscriptionId', params.subscriptionId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommonId>;
      })
    );
  }

  /**
   * Subscription status.
   *
   * The API Upates subscription Activate or Deactivate
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateDeactivateSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateDeactivateSubscription(params: {
    subscriptionId: string;
    body?: CommonId
  }): Observable<CommonId> {

    return this.activateDeactivateSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<CommonId>) => r.body as CommonId)
    );
  }

  /**
   * Path part for operation reviewExcelProcessing
   */
  static readonly ReviewExcelProcessingPath = '/api/v1/subscription-excel/review/excel-processing';

  /**
   * Review Billing Excel.
   *
   * The API updates the status to review of billing excel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewExcelProcessing()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewExcelProcessing$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.ReviewExcelProcessingPath, 'patch');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Review Billing Excel.
   *
   * The API updates the status to review of billing excel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reviewExcelProcessing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewExcelProcessing(params?: {
  }): Observable<string> {

    return this.reviewExcelProcessing$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getSubscriptionActivityLogListByMetaDataId
   */
  static readonly GetSubscriptionActivityLogListByMetaDataIdPath = '/api/v1/subscription/activity-log/{subscriptionMetaDataId}';

  /**
   * Manage Activity log List.
   *
   * The API to view the activity log list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubscriptionActivityLogListByMetaDataId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionActivityLogListByMetaDataId$Response(params: {
    subscriptionMetaDataId: string;
  }): Observable<StrictHttpResponse<Array<ActivityLogDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.GetSubscriptionActivityLogListByMetaDataIdPath, 'get');
    if (params) {
      rb.path('subscriptionMetaDataId', params.subscriptionMetaDataId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ActivityLogDto>>;
      })
    );
  }

  /**
   * Manage Activity log List.
   *
   * The API to view the activity log list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubscriptionActivityLogListByMetaDataId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionActivityLogListByMetaDataId(params: {
    subscriptionMetaDataId: string;
  }): Observable<Array<ActivityLogDto>> {

    return this.getSubscriptionActivityLogListByMetaDataId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ActivityLogDto>>) => r.body as Array<ActivityLogDto>)
    );
  }

  /**
   * Path part for operation getSubscriptionListByProfileId1
   */
  static readonly GetSubscriptionListByProfileId1Path = '/api/v1/profile/{profileId}/subscription';

  /**
   * Manage Subscription List.
   *
   * The API to view the subscription list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubscriptionListByProfileId1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionListByProfileId1$Response(params: {
    profileId: string;
    month?: number;
    year?: number;
  }): Observable<StrictHttpResponse<Array<SubscriptionMetadataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.GetSubscriptionListByProfileId1Path, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('month', params.month, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubscriptionMetadataDto>>;
      })
    );
  }

  /**
   * Manage Subscription List.
   *
   * The API to view the subscription list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubscriptionListByProfileId1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionListByProfileId1(params: {
    profileId: string;
    month?: number;
    year?: number;
  }): Observable<Array<SubscriptionMetadataDto>> {

    return this.getSubscriptionListByProfileId1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SubscriptionMetadataDto>>) => r.body as Array<SubscriptionMetadataDto>)
    );
  }

  /**
   * Path part for operation upload
   */
  static readonly UploadPath = '/api/v1/subscription-excel/upload';

  /**
   * Upload Billing Excel.
   *
   * The API uploads billing excel to azure storage account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `application/json`.
   */
  upload$Response(params: {
    orderId: string;
    comments?: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<SubscriptionMetadataDto>> {

    const rb = new RequestBuilder(this.rootUrl, SubscriptionControllerService.UploadPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('comments', params.comments, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionMetadataDto>;
      })
    );
  }

  /**
   * Upload Billing Excel.
   *
   * The API uploads billing excel to azure storage account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upload(params: {
    orderId: string;
    comments?: string;
    body?: {
'file'?: Blob;
}
  }): Observable<SubscriptionMetadataDto> {

    return this.upload$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionMetadataDto>) => r.body as SubscriptionMetadataDto)
    );
  }

}
