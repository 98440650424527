/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SettingCommonDto } from '../models/setting-common-dto';

@Injectable({
  providedIn: 'root',
})
export class DomainControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAllDomains
   */
  static readonly FindAllDomainsPath = '/api/v1/domain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllDomains$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<SettingCommonDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DomainControllerService.FindAllDomainsPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingCommonDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllDomains(params?: {
    active?: string;
  }): Observable<Array<SettingCommonDto>> {

    return this.findAllDomains$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SettingCommonDto>>) => r.body as Array<SettingCommonDto>)
    );
  }

  /**
   * Path part for operation createDomain
   */
  static readonly CreateDomainPath = '/api/v1/domain';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDomain$Response(params?: {
    body?: SettingCommonDto
  }): Observable<StrictHttpResponse<SettingCommonDto>> {

    const rb = new RequestBuilder(this.rootUrl, DomainControllerService.CreateDomainPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SettingCommonDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDomain(params?: {
    body?: SettingCommonDto
  }): Observable<SettingCommonDto> {

    return this.createDomain$Response(params).pipe(
      map((r: StrictHttpResponse<SettingCommonDto>) => r.body as SettingCommonDto)
    );
  }

  /**
   * Path part for operation findByDomainId
   */
  static readonly FindByDomainIdPath = '/api/v1/domain/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByDomainId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByDomainId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<SettingCommonDto>> {

    const rb = new RequestBuilder(this.rootUrl, DomainControllerService.FindByDomainIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SettingCommonDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByDomainId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByDomainId(params: {
    id: string;
  }): Observable<SettingCommonDto> {

    return this.findByDomainId$Response(params).pipe(
      map((r: StrictHttpResponse<SettingCommonDto>) => r.body as SettingCommonDto)
    );
  }

  /**
   * Path part for operation updateDomain
   */
  static readonly UpdateDomainPath = '/api/v1/domain/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDomain$Response(params: {
    id: string;
    body?: SettingCommonDto
  }): Observable<StrictHttpResponse<SettingCommonDto>> {

    const rb = new RequestBuilder(this.rootUrl, DomainControllerService.UpdateDomainPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SettingCommonDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDomain(params: {
    id: string;
    body?: SettingCommonDto
  }): Observable<SettingCommonDto> {

    return this.updateDomain$Response(params).pipe(
      map((r: StrictHttpResponse<SettingCommonDto>) => r.body as SettingCommonDto)
    );
  }

  /**
   * Path part for operation activateDomain
   */
  static readonly ActivateDomainPath = '/api/v1/domain/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateDomain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DomainControllerService.ActivateDomainPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateDomain(params: {
    id: string;
  }): Observable<void> {

    return this.activateDomain$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateDomain
   */
  static readonly DeactivateDomainPath = '/api/v1/domain/{id}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateDomain$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DomainControllerService.DeactivateDomainPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateDomain(params: {
    id: string;
  }): Observable<void> {

    return this.deactivateDomain$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
