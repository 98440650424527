import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PieChartService } from 'src/app/providers/pie-chart-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DeviceEnrollmentControllerService } from 'src/app/services/DeviceMgmt/services/device-enrollment-controller.service';
import { SettingCommonDto } from 'src/app/services/Platform/models';
import { DEVICE_ICON, BACK_LEFT, DOWNLOAD_IMAGE, SEARCH_IMAGE } from 'src/app/shared/constants/strings';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-group-details',
  templateUrl: './group-details.component.html',
  styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit {
  public showSpinner :any;
  public dataSource;
  public columns: any[];
  public ignoreList = ['attestationId', 'awsCertArn', 'awsCertId', 'category', 'deviceTypeId',
'groupId', 'individualEnrolmentTagMap', 'profileId', 'solDevIntfcProvId', 'thingArn', 'thingId',
'lastUpdatedBy', 'lastUpdatedDt', 'createdBy', 'createdDt', 'gatewayDeviceName', 'thingTypeName', 'active'];
  public solutionTypeId: string;
  public groupId: string;
  public groupName: string;
  public groupDetails;
  public groupDetailsData;
  public groupCreatedData;
  public chartDataObject = {
    title: '',
    subtitle: '',
    tooltip: '',
    details: []
  };
  public downloadImage;
  public totalGroupCount;
  public totalDeviceCount = 0;
  public fotaJobName: string;
  public fotaDetailsData;
  public groupIcon: string;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  public color;
  public backImage;
  public groupDetailsInputData = {};
  public status;
  public searchImage = SEARCH_IMAGE;
  public charts: any;
  public previousStatus: any;
  @ViewChild('charts', { static: true }) public chartElement: ElementRef;
  constructor(private deviceEnrollmentControllerService: DeviceEnrollmentControllerService, private router: Router,
    private translate: TranslateService, public activatedRoute: ActivatedRoute,
    private route: ActivatedRoute, private responseHandlerService: ResponseHandlerService, private pieChartService: PieChartService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.groupDetails = params;
    });
    this.getGroupSelectionCriteria();
    this.backImage = BACK_LEFT;
    this.searchImage = SEARCH_IMAGE;
    this.downloadImage = DOWNLOAD_IMAGE;
    this.groupIcon = DEVICE_ICON;
    this.color = {
      'FAILED': '#f75133', 'IN PROGRESS': '#69bdf5',  'SUCCESS': '#78BE20'};
  }

  public getGroupSelectionCriteria() {
    if (this.groupDetails) {
      this.groupId = this.groupDetails['groupId'];
      this.groupName = this.groupDetails['groupName'];
      this.solutionTypeId = this.groupDetails['solutionType'];
      this.groupCreatedData = {
        AWS_GROUP_DETAILS: [{ 'label': 'GROUP_NAME', 'value': this.groupDetails['groupName'] }],
        SEARCH_CRITERIA: [{ 'label': 'SOLUTION_TYPE', 'value': this.groupDetails['solutionType']},
              { 'label': 'DEVICE_TYPE', 'value': this.groupDetails['deviceType']},
              { 'label': 'DEVICE_INTERFACE_PROVIDER', 'value': this.groupDetails['interfaceProvider']}]};
      this.getGroupCounts(this.groupId);
    } else {
        this.responseHandlerService.returnToastMessage('error', 'GROUP_DETAILS_FAILURE');
    }
  }
  public getGroupCounts(groupId) {
    let totalCount;
    this.deviceEnrollmentControllerService.getGrpEnrollDeviceCount({ groupId: groupId}).subscribe(
      resp => {
        if (resp && resp.length) {
          resp.forEach(deviceStatus => {
            if (deviceStatus['status'] === 'Total Devices' ) {
              this.totalDeviceCount = deviceStatus['count'];
            }
          });
        if (this.charts) {
          this.pieChartService.functionNameEmitter.subscribe(status => {
            this.getGroupDetailsInputData(status);
            this.status = status;
          });
        } else {
          resp.forEach(status => {
            if (status['status'] === 'Total Devices' ) {
             totalCount = status;
             const index = resp.indexOf(status);
             resp.splice(index, 1);
            }
          });
          this.chartDataObject.details = this.getChartData(resp);
          this.chartDataObject.title = this.getTranslatedValues('DEVICE_COUNT');
          this.pieChartService.createChart(this.chartElement.nativeElement, this.chartDataObject);
          resp.push(totalCount);
          this.pieChartService.functionNameEmitter.subscribe(status => {
            this.getGroupDetailsInputData(status);
            this.status = status;
          });
        }
        } else {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
        }
      }, err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public pageEvent(event, charts) {
    this.charts = charts;
    this.groupDetailsInputData['status'] = this.status;
    this.groupDetailsInputData['page'] = event.pageIndex;
    this.groupDetailsInputData['limit'] = event.pageSize;
    this.getDeviceDetailsOnStatus(this.groupDetailsInputData);
  }

public getChartData(tempData) {
    const data = tempData;
    data.forEach(chartElement => {
      if (chartElement['status'] !== 'Total Devices' ) {
        chartElement['name'] = (this.getTranslatedValues(chartElement['status']));
        chartElement['y'] = (chartElement['count'] * this.totalDeviceCount) / 100;
        chartElement['color'] = this.color[chartElement['status']];
      }
    });
    return data;
}

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }


  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }

  public downloadErrorLog(deviceSerialNo: string) {
    this.deviceEnrollmentControllerService.getDeviceEnrollErrorLog({ deviceSerialNo: deviceSerialNo })
      .subscribe(resp => {
        this.downloadFile(resp, this.generateFilename(this.groupName, deviceSerialNo));
      }, err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public downloadFile(data, fileName) {
    const FileSaver = require('file-saver');
    const blob = new Blob([data], { type: 'text/plain' });
    FileSaver.saveAs(blob, fileName);
  }

  public generateFilename(groupName, deviceSerialNo) {
    return (groupName + '-' + deviceSerialNo);
  }

  public navigateBack() {
    this.router.navigate(['devices/manage-groups']);
  }

  public onSearchClick(event) {
    this.dataSource = null;
    this.columns = null;
    this.groupDetailsData = null;
    this.showSpinner = this.loaderService.showProgressBar();
    const deviceDetailsInputData = { groupId: this.groupId, page: 0, limit: this.pageSize, deviceSerialNo: event };
    this.deviceEnrollmentControllerService.getDeviceEnrollStatus(deviceDetailsInputData).subscribe(resp => {
      if (resp && resp['statusList'].length) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.totalDeviceCount = resp.deviceStatusCount;
        this.groupDetailsData =  resp['statusList'];
          for (const data of this.groupDetailsData) {
            if (data['enrollmentStatus'] === 'FAILED') {
            data['log'] = null;
          }
        }
        this.columns = this.filterColumns(Object.keys(this.groupDetailsData[0]));
        this.dataSource = new MatTableDataSource<SettingCommonDto>(this.groupDetailsData);
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getGroupDetailsInputData(event) {
    this.previousStatus = this.status;
    this.status = event;
    let page;
    if (this.previousStatus && this.status && (this.previousStatus !== this.status)) {
        page = 0;
    } else if (this.previousStatus && this.status && this.previousStatus === this.status) {
      page = event.page;
    }
    this.groupDetailsInputData = { groupId: this.groupId,
      status: event,
      page: page, limit: this.pageSize
    };
    this.status = event;
    this.getDeviceDetailsOnStatus(this.groupDetailsInputData);
  }

  public getDeviceDetailsOnStatus(groupDetailsInputData) {
    this.groupDetailsData = null;
    this.showSpinner = this.loaderService.showProgressBar();
    this.deviceEnrollmentControllerService.getDeviceEnrollStatus(groupDetailsInputData).subscribe(resp => {
      if (resp && resp['statusList'].length) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.totalDeviceCount = resp.deviceStatusCount;
        this.groupDetailsData = resp['statusList'];
        for (const data of this.groupDetailsData) {
          if (data['enrollmentStatus'] === 'FAILED') {
            data['log'] = null;
          }
        }
        this.columns = this.filterColumns(Object.keys(this.groupDetailsData[0]));
        this.dataSource = new MatTableDataSource<SettingCommonDto>(this.groupDetailsData);
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }
}
