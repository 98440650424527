import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FirmwareRoutingModule } from './firmware-routing.module';
import { UploadFirmwareComponent } from './upload-firmware/upload-firmware.component';
import { ManageFirmwareComponent } from './manage-firmware/manage-firmware.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DragAndDropDirective } from '../providers/drag-and-drop.directive';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

@NgModule({
  declarations: [UploadFirmwareComponent, ManageFirmwareComponent, DragAndDropDirective],
  imports: [
    CommonModule,
    FirmwareRoutingModule,
    SharedModule,
    TranslateModule,
    MatTableModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatPaginatorModule,
    MatMenuModule
  ]
})
export class FirmwareModule { }
