<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="simIcon" />
        <span>{{'MANAGE_SIM' | translate}}</span>
      </span>
    </div>
    <mat-tab-group (selectedTabChange)="matChange($event)">
      <mat-tab [label]="tab.label" *ngFor="let tab of matTabOptions">
      </mat-tab>
    </mat-tab-group>
    <span>
      <app-search-component *ngIf="formData" [comboFieldSearchArray]="formData" [dropDownValues]="dropDownValues"
        (valueSelected)="updateDropdownValues($event)" (searchFieldEmitter)="searchSimEmitter($event)">
      </app-search-component>
    </span>
    <div class="table-container" *ngIf="data">
      <div class="sim-action-btn">
         <button *ngIf="selection.selected.length && (checkPermission('SIM_D') && checkPermission('SIM_C'))"
          mat-raised-button class="button btn-background" (click)="gotoConfirmDialog(dataName, 'delete')">
          <span>{{ 'DELETE_SIM' | translate }}</span>
        </button>
        <button *ngIf="selection.selected.length && (checkPermission('SIM_ACT'))" mat-raised-button
          class="button btn-background" (click)="gotoConfirmDialog(selection.selected, 'planList', viewPlans)">
          <span>{{ 'ACTIVATE' | translate }}</span>
        </button>
        <button *ngIf="selection.selected.length && checkPermission('SIM_DACT')" mat-raised-button
          class="button btn-background" (click)="gotoConfirmDialog(dataName, 'deactivate')">
          <span>{{ 'DEACTIVATE' | translate }}</span>
        </button>
      </div>
      <mat-table [dataSource]="data" class="table-data">
        <div *ngFor="let column of columns">
          <ng-container *ngIf="column==='select'" matColumnDef="select">
            <mat-header-cell  class="column-small" *matHeaderCellDef>
              <mat-checkbox (click)="$event.stopPropagation()"  (change)="selectSims($event)"    [checked]="selectAllSims" >  </mat-checkbox>
                     </mat-header-cell>
            <mat-cell class="column-small" *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row['simReferenceId']) : null; selectAllSimsCheckBox($event,row['simReferenceId'])"
                [checked]="selection.isSelected(row['simReferenceId'])" >
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="column!=='select'" [matColumnDef]="column">
            <mat-header-cell class="header-style" [ngClass] = "determineColumnSize(column)" *matHeaderCellDef>
              <header class="device-table heading-label">{{column | appendSpace | titlecase}}</header>
            </mat-header-cell>
            <mat-cell class="tooltip" [ngClass] = "determineColumnSize(column)" *matCellDef="let dataName">
              <span *ngIf="column!=='status'  && column!=='linkedWithDevice'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column==='action'">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <img [src]="moreOptionsImage">
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item *ngIf="checkPermission('SIM_U')" (click)="editOrActivateSim(dataName, 'edit')">
                    <img [src]="editIcon" class="icon-space" class="modify-button">
                    <span>{{ 'EDIT_SIM' | translate }}</span>
                  </button>
                  <button mat-menu-item *ngIf="checkPermission('SIM_ACT')
                        && (dataName['simStatus'] === 'ACTIVATED')"
                    (click)="getSimPlans(dataName,viewPlans)">
                    <img [src]="viewPlanImage" class="icon-space" class="modify-button">
                    <span>{{'CHANGE_PLANS' | translate}}</span>
                  </button>
                  <button mat-menu-item *ngIf="checkPermission('SIM_ACT') 
                  && dataName['simStatus'] !== ('DEACTIVATION_REQUESTED' || 'DEACTIVATED')"
                    (click)="openModalForWhiteListing(viewPlans, dataName['simReferenceId'])">
                    <img [src]="simWhiteListIcon" class="icon-space" class="modify-button">
                    <span>{{ 'WHITELIST_SIM' | translate }}</span>
                  </button>
                </mat-menu>
              </span>
              <span *ngIf="column==='simReferenceId'">
                <img [src]="copyImage" [title]="'COPY' |translate" (click)="copyText(dataName['simReferenceId'])">
              </span>
              <span *ngIf="column ==='linkedWithDevice'">
                <img *ngIf="!dataName[column]" [src]="notLinkedImage" class="state-icon" [title]="dataName[column]"/>
                <img *ngIf="dataName[column]" [src]="linkedImage" class="state-icon" [title]="dataName[column]"/>
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row class="table-header-row" *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator *ngIf="data" [pageSizeOptions]="pageSizeOptions" [length]="simCount" [pageSize]="pageSize"
      showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>

<!--------------------------------------Below Table is related to Sim Provider based sim search------------------------------------- -->
<div class="table-container" *ngIf="simSearchData">
  <div class="sim-action-btn" *ngIf="simSearchData">
    <button *ngIf="selection.selected.length && (checkPermission('SIM_D') && checkPermission('SIM_C'))"
    mat-raised-button class="button btn-background" (click)="gotoConfirmDialog(dataName, 'delete')">
    <span>{{ 'DELETE_SIM' | translate }}</span>
  </button>
  <button *ngIf="selection.selected.length && (checkPermission('SIM_ACT'))" mat-raised-button
  class="button btn-background" (click)="gotoConfirmDialog(selection.selected, 'activate')">
  <span>{{ 'ACTIVATE' | translate }}</span>
</button>
<button *ngIf="selection.selected.length && checkPermission('SIM_DACT')" mat-raised-button
  class="button btn-background" (click)="gotoConfirmDialog(selection.selected, 'deactivate')">
  <span>{{ 'DEACTIVATE' | translate }}</span>
</button>
  </div>
   <mat-table [dataSource]="simSearchData" class="table-data">
    <div *ngFor="let column of columns">
      <ng-container *ngIf="column==='select'" matColumnDef="select">
        <mat-header-cell  class="column-small" *matHeaderCellDef>
          <mat-checkbox (click)="$event.stopPropagation()"  (change)="selectSims($event)"    [checked]="selectAllSims" >  </mat-checkbox>
                 </mat-header-cell>
        <mat-cell class="column-small" *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row['simId']) : null; selectAllSimsCheckBox($event,row['simId'])"
            [checked]="selection.isSelected(row['simId'])" >
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container *ngIf="column!=='select'" [matColumnDef]="column">
        <mat-header-cell class="header-style" [ngClass] = "determineColumnSize(column)" *matHeaderCellDef>
          <header class="device-table heading-label">{{column | appendSpace | titlecase}}</header>
        </mat-header-cell>
        <mat-cell class="tooltip" [ngClass] = "determineColumnSize(column)" *matCellDef="let dataName">
          <span *ngIf="column!=='linkedWithDevice'" title="{{dataName[column]}}" class="cell-value">
            {{dataName[column]}}
          </span>
          <span *ngIf="column==='action'">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <img [src]="moreOptionsImage">
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngIf="checkPermission('SIM_U')" (click)="editOrActivateSim(dataName, 'edit')">
                <img [src]="editIcon" class="icon-space" class="modify-button">
                <span>{{ 'EDIT_SIM' | translate }}</span>
              </button>
            </mat-menu>
          </span>
          <span *ngIf="column==='simId'">
            <img [src]="copyImage" [title]="'COPY' |translate" (click)="copyText(dataName['simId'])">
          </span>
          <span *ngIf="column ==='linkedWithDevice'">
            <img *ngIf="!dataName[column]" [src]="notLinkedImage" class="state-icon"/>
            <img *ngIf="dataName[column]" [src]="linkedImage" class="state-icon"/>
          </span>
        </mat-cell>
      </ng-container>
    </div>
    <mat-header-row class="table-header-row" *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
  </mat-table>
</div>
<mat-paginator *ngIf="simSearchData" [pageSizeOptions]="pageSizeOptions" [length]="simCount" [pageSize]="pageSize"
  showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>


  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>


<ng-template #viewPlans let-modal let-data>
  <div class="close-btn"><a class="rb-ic rb-ic-close" (click)="onClose()">X</a></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{((plansData && (jsonFileLoadedForWhiteList !==
        'sim-whitelisting')) ?'SIM_PLANS':'WHITELIST_SIM')| translate}} </h5>
    </div>
    <div class="modal-body">
      <mat-table *ngIf="(plansData && (jsonFileLoadedForWhiteList !== 'sim-whitelisting'))" [dataSource]="plansData"
        class="table-data">
        <div *ngFor="let column of plansColumns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell class="header-style" *matHeaderCellDef>
              <header class="device-table heading-label">{{column | appendSpace | titlecase}}</header>
            </mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName">
              <span *ngIf="column!=='action'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column==='action'">
                <button mat-raised-button 
                class="(dataName['planCode'] && dataName['planCode'] === selectedSimPlan? button : button btn-background)" 
                [disabled]="dataName['planCode'] && dataName['planCode'] === selectedSimPlan"
                (click)="gotoConfirmDialog(data,'activate', null, dataName)"
                [title]="(dataName['planCode'] && dataName['planCode'] === selectedSimPlan? 'ACTIVATED_PLAN' : 'ACTIVE') | translate">
                  {{(dataName['planCode'] && dataName['planCode'] === selectedSimPlan? 'ACTIVATED' : 'ACTIVE') | translate}}
                </button>
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row class="table-header-row" *matHeaderRowDef="plansColumns"></mat-header-row>
        <mat-row *matRowDef="let plansData; columns: plansColumns;"></mat-row>
      </mat-table>
      <div *ngIf="(!plansData && (jsonFileLoadedForWhiteList === 'sim-whitelisting'))">
        <div class="modal-body">
          <app-dynamic-form [jsonFile]="jsonFileLoadedForWhiteList" [columnsInForm]="'single'"
            (formEmitter)="whiteListingSim($event)" (resetForm)="resetForm($event)">
          </app-dynamic-form>
        </div>
      </div>
    </div>
  </div>
  
</ng-template>