import { Component, OnInit } from '@angular/core';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import {
  DeviceTunnelControllerService,
  MethodControllerService,
} from 'src/app/services/C2D/services';
import {
  DEVICE_TUNNELING_ICON_WHITE,
  HOST_COPY_ICON,
  PORT_COPY_ICON, COPY_ENDPOINT_IMAGE
} from 'src/app/shared/constants/strings';
import {MatSnackBar} from '@angular/material/snack-bar';
import {
  MatDialog } from '@angular/material/dialog';
import { 
  MatPaginator } from
  '@angular/material/paginator';
import
  {MatTabGroup,
} from '@angular/material/tabs';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import { HttpClient } from '@angular/common/http';
import { TranslationService } from 'src/app/providers/translation-service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { C2DDirectMethodControllerService } from 'src/app/services/DeviceMgmt/services/c-2-d-direct-method-controller.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BciLoaderService } from '@bci-web-core/core';
@Component({
  selector: 'app-device-tunneling',
  templateUrl: './device-tunneling.component.html',
  styleUrls: ['./device-tunneling.component.scss'],
})
export class DeviceTunnelingComponent implements OnInit {
  public deviceTunnelIcon;
  public dropDownValues = [];
  public showSpinner:any;
  public serialNo;
  public port: number;
  public host: string;
  public hostCopyImage: string;
  public portCopyImage: string;
  public respPayload: any;
  public apiurl: string;
  public showProgressbar = false;
  public tunnelingBasicsForm: FormGroup;
  public submitted = false;
  httpClient: any;
  public matTabOptions = [];
  public currentTabValue = 'connection';
  public endPointCopyIcon;
  public selectedProtocol;
  constructor(
    private methodControllerService: MethodControllerService,
    private responseHandlerService: ResponseHandlerService,
    private _snackBar: MatSnackBar,
    private translate: TranslationService,
    private route: ActivatedRoute,
    private _matDialog: MatDialog,
    private httpService: HttpClient,
    private router: Router,
    private formBuilder: FormBuilder, private deviceTunnelControllerService: DeviceTunnelControllerService,
    private c2DDirectMethodControllerService: C2DDirectMethodControllerService,private loaderService: BciLoaderService
  ) {}

  ngOnInit() {
    this.deviceTunnelIcon = DEVICE_TUNNELING_ICON_WHITE;
    this.hostCopyImage = HOST_COPY_ICON;
    this.portCopyImage = PORT_COPY_ICON;
    this.endPointCopyIcon = COPY_ENDPOINT_IMAGE;
    this.matTabOptions.push({ 'label': 'Connection', 'value': 'Connection' });
    this.serialNo = this.route.snapshot.paramMap.get('serialNo');
    this.getSupportedProtocols();
    this.tunnelingBasicsForm = this.formBuilder.group({
      protocols: ['', Validators.required],
      host: ['', Validators.required],
      servicePort: ['', Validators.required]
    });
  }

  public getSupportedProtocols() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.c2DDirectMethodControllerService.getProtocols().subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.dropDownValues['protocols'] = resp;
        } else {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.dropDownValues['protocols'] = [];
        }
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.dropDownValues['protocols'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }
 public addSelectedPort($event) {
  if ($event) {
    this.dropDownValues['protocols'].forEach(protocol => {
      if (protocol['id'] === $event.value) {
        this.tunnelingBasicsForm.controls['servicePort'].setValue(protocol['servicePort']);
        this.tunnelingBasicsForm.controls['host'].setValue('127.0.0.1');
        this.selectedProtocol = protocol;
      }
    });
  }
 }

 public establishTunneling() {
      if (this.tunnelingBasicsForm.valid) {
         this.showSpinner = this.loaderService.showProgressBar();
      this._snackBar.open(
        this.translate.translateErrorMessages('DEVICE_TUNNELING_CREATE_INFO'),
        'OK',
        {
          verticalPosition: 'top',
          panelClass: ['warning'],
        }
      );
      const tunnelDto = {
        deviceId: this.serialNo,
        solutionTypeId: this.route.snapshot.paramMap.get('solutionType'),
        businessProfileId: this.route.snapshot.paramMap.get('businessProfile'),
        protocolId: this.selectedProtocol['id'],
        protocolName: this.selectedProtocol['name'],
        host:this.tunnelingBasicsForm.controls['host'].value,
        servicePort: this.tunnelingBasicsForm.controls['servicePort'].value
      };
      this.c2DDirectMethodControllerService
          .invokeDeviceTunneling({body: tunnelDto})
          .subscribe(
            (resp) => {
              if (resp && resp['payload']) {
                this.respPayload = resp['payload'];
                this._snackBar.dismiss();
                this.loaderService.hideProgressBar(this.showSpinner);
              } else {
                this._snackBar.dismiss();
                this.loaderService.hideProgressBar(this.showSpinner);
                this.respPayload = null;
              }
            },
            (err) => {
              this.respPayload = null;
              this.loaderService.hideProgressBar(this.showSpinner);
              this._snackBar.dismiss();
              this.responseHandlerService.returnToastMessage(
                'error',
                err.error.message
              );
            }
          );
      }
  }
  public copyText(fieldId) {
    const text = document.getElementById(fieldId) as HTMLInputElement;
    text.select();
    document.execCommand('copy');
    this.responseHandlerService.returnToastMessage(
      'success',
      'COPIED_TO_CLIPBOARD'
    );
  }
  public matChange(event) {
    this.currentTabValue = this.matTabOptions[event.index].value;
  }
}
