/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ChatMessagesResponseDto } from '../models/chat-messages-response-dto';
import { ChatRoomDetailsResponseDto } from '../models/chat-room-details-response-dto';
import { AgentDto } from '../models/agent-dto';
import { MessageDto } from '../models/message-dto';

@Injectable({
  providedIn: 'root',
})
export class AgentControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

 /**
   * Path part for operation createAgent
   */
 static readonly CreateAgentPath = '/api/v1/create-agent';

 /**
  * Create Agent.
  *
  * API to create an agent for specified Channel
  *
  * This method provides access to the full `HttpResponse`, allowing access to response headers.
  * To access only the response body, use `createAgent()` instead.
  *
  * This method sends `application/json` and handles request body of type `application/json`.
  */
 createAgent$Response(params: {
   body: AgentDto
 }
): Observable<StrictHttpResponse<MessageDto>> {

   const rb = new RequestBuilder(this.rootUrl, AgentControllerService.CreateAgentPath, 'post');
   if (params) {
     rb.body(params.body, 'application/json');
   }

   return this.http.request(rb.build({
     responseType: 'json',
     accept: 'application/json',
     
   })).pipe(
     filter((r: any) => r instanceof HttpResponse),
     map((r: HttpResponse<any>) => {
       return r as StrictHttpResponse<MessageDto>;
     })
   );
 }

 /**
  * Create Agent.
  *
  * API to create an agent for specified Channel
  *
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `createAgent$Response()` instead.
  *
  * This method sends `application/json` and handles request body of type `application/json`.
  */
 createAgent(params: {
   body: AgentDto
 }
): Observable<MessageDto> {

   return this.createAgent$Response(params).pipe(
     map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
   );
 }

  /**
   * Path part for operation getChatHistory
   */
  static readonly GetChatHistoryPath = '/api/v1/chat-messages/{chatRoomId}';

  /**
   * Get All Chat Messages.
   *
   * API to get all chat messages based on chat Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChatHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChatHistory$Response(params: {
    chatRoomId: string;
    page?: number;
    limit?: number;
  }
): Observable<StrictHttpResponse<ChatMessagesResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetChatHistoryPath, 'get');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatMessagesResponseDto>;
      })
    );
  }

  /**
   * Get All Chat Messages.
   *
   * API to get all chat messages based on chat Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChatHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChatHistory(params: {
    chatRoomId: string;
    page?: number;
    limit?: number;
  }
): Observable<ChatMessagesResponseDto> {

    return this.getChatHistory$Response(params).pipe(
      map((r: StrictHttpResponse<ChatMessagesResponseDto>) => r.body as ChatMessagesResponseDto)
    );
  }

  /**
   * Path part for operation getAgentChatRooms
   */
  static readonly GetAgentChatRoomsPath = '/api/v1/agent-chatrooms/{agentId}';

  /**
   * Get Agent Chat Rooms.
   *
   * API to get all agent related chat rooms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentChatRooms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentChatRooms$Response(params: {
    agentId: string;
    active?: boolean;
    page?: number;
    limit?: number;
  }
): Observable<StrictHttpResponse<ChatRoomDetailsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetAgentChatRoomsPath, 'get');
    if (params) {
      rb.path('agentId', params.agentId, {});
      rb.query('active', params.active, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatRoomDetailsResponseDto>;
      })
    );
  }

  /**
   * Get Agent Chat Rooms.
   *
   * API to get all agent related chat rooms
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgentChatRooms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentChatRooms(params: {
    agentId: string;
    active?: boolean;
    page?: number;
    limit?: number;
  }
): Observable<ChatRoomDetailsResponseDto> {

    return this.getAgentChatRooms$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRoomDetailsResponseDto>) => r.body as ChatRoomDetailsResponseDto)
    );
  }


  /**
   * Path part for operation getAgentsByChannel
   */
  static readonly GetAgentsByChannelPath = '/api/v1/channel-agents/{channelNumber}';

  /**
   * Get all agents of a channel.
   *
   * API to get all agents related to a channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAgentsByChannel()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentsByChannel$Response(params: {
    channelNumber: string;
    page?: number;
    limit?: number;
  }
): Observable<StrictHttpResponse<Array<AgentDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetAgentsByChannelPath, 'get');
    if (params) {
      rb.path('channelNumber', params.channelNumber, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
     accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AgentDto>>;
      })
    );
  }

  /**
   * Get all agents of a channel.
   *
   * API to get all agents related to a channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAgentsByChannel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAgentsByChannel(params: {
    channelNumber: string;
    page?: number;
    limit?: number;
  }
): Observable<Array<AgentDto>> {

    return this.getAgentsByChannel$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AgentDto>>) => r.body as Array<AgentDto>)
    );
  }


  /**
   * Path part for operation transferChat
   */
  static readonly TransferChatPath = '/api/v1/chats/{chatRoomId}/transfer/agent/{agentId}';

  /**
   * Transfer chat to Another Agent.
   *
   * API to transfer chat from one agent to another of same channel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transferChat()` instead.
   *
   * This method doesn't expect any request body.
   */
  transferChat$Response(params: {
    chatRoomId: string;
    agentId: string;
    fromAgentName: string;
    toAgentName: string;
  }): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.TransferChatPath, 'post');
    if (params) {
      rb.path('chatRoomId', params.chatRoomId, {});
      rb.path('agentId', params.agentId, {});
      rb.query('fromAgentName', params.fromAgentName, {});
      rb.query('toAgentName', params.toAgentName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * Transfer chat to Another Agent.
   *
   * API to transfer chat from one agent to another of same channel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `transferChat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transferChat(params: {
    chatRoomId: string;
    agentId: string;
    fromAgentName: string;
    toAgentName: string;
  }): Observable<MessageDto> {

    return this.transferChat$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }
/**
   * Path part for operation getChatStatus
   */
static readonly GetChatStatusPath = '/api/v1/chat/status';

/**
 * Get Chat Status.
 *
 * API to get Chat status whether handled by bot or Agent
 *
 * This method provides access to the full `HttpResponse`, allowing access to response headers.
 * To access only the response body, use `getChatStatus()` instead.
 *
 * This method doesn't expect any request body.
 */
getChatStatus$Response(params: {
  secretKey: string;
  phoneNumber: string;
  channelNumber: string;
  
}
): Observable<StrictHttpResponse<boolean>> {

  const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetChatStatusPath, 'get');
  if (params) {
    rb.header('secretKey', params.secretKey, {});
    rb.query('phoneNumber', params.phoneNumber, {});
    rb.query('channelNumber', params.channelNumber, {});
  }

  return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json',
    
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
    })
  );
}

/**
 * Get Chat Status.
 *
 * API to get Chat status whether handled by bot or Agent
 *
 * This method provides access to only to the response body.
 * To access the full response (for headers, for example), `getChatStatus$Response()` instead.
 *
 * This method doesn't expect any request body.
 */
getChatStatus(params: {
  secretKey: string;
  phoneNumber: string;
  channelNumber: string;
  
}
): Observable<boolean> {

  return this.getChatStatus$Response(params).pipe(
    map((r: StrictHttpResponse<boolean>) => r.body as boolean)
  );
}

  /**
   * Path part for operation getChannelChatRooms
   */
  static readonly GetChannelChatRoomsPath = '/api/v1/channel/{channelNumber}/chatRooms';

  /**
   * Get Channel Chat Rooms.
   *
   * API to get all channel related chat rooms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getChannelChatRooms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannelChatRooms$Response(params: {
    channelNumber: string;
    active?: boolean;
    assigned?: boolean;
    page?: number;
    limit?: number;
  }
): Observable<StrictHttpResponse<ChatRoomDetailsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetChannelChatRoomsPath, 'get');
    if (params) {
      rb.path('channelNumber', params.channelNumber, {});
      rb.query('active', params.active, {});
      rb.query('assigned', params.assigned, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ChatRoomDetailsResponseDto>;
      })
    );
  }

  /**
   * Get Channel Chat Rooms.
   *
   * API to get all channel related chat rooms
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getChannelChatRooms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getChannelChatRooms(params: {
    channelNumber: string;
    active?: boolean;
    assigned?: boolean;
    page?: number;
    limit?: number;
  }
): Observable<ChatRoomDetailsResponseDto> {

    return this.getChannelChatRooms$Response(params).pipe(
      map((r: StrictHttpResponse<ChatRoomDetailsResponseDto>) => r.body as ChatRoomDetailsResponseDto)
    );
  }


/**
   * Path part for operation getAgentsByDepartmentAndChannel
   */
static readonly GetAgentsByDepartmentAndChannelPath = '/api/v1/channel/{channelNumber}';

/**
 * Get all agents in a department of a channel.
 *
 * API to get all agents related to a channel
 *
 * This method provides access to the full `HttpResponse`, allowing access to response headers.
 * To access only the response body, use `getAgentsByDepartmentAndChannel()` instead.
 *
 * This method doesn't expect any request body.
 */
getAgentsByDepartmentAndChannel$Response(params: {
  channelNumber: string;
  departmentId?: string;
  page?: number;
  limit?: number;
  active?: string;
}): Observable<StrictHttpResponse<Array<AgentDto>>> {

  const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetAgentsByDepartmentAndChannelPath, 'get');
  if (params) {
    rb.path('channelNumber', params.channelNumber, {});
    rb.query('departmentId', params.departmentId, {});
    rb.query('page', params.page, {});
    rb.query('limit', params.limit, {});
    rb.query('active', params.active, {});
  }

  return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json'
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<AgentDto>>;
    })
  );
}

/**
 * Get all agents in a department of a channel.
 *
 * API to get all agents related to a channel
 *
 * This method provides access to only to the response body.
 * To access the full response (for headers, for example), `getAgentsByDepartmentAndChannel$Response()` instead.
 *
 * This method doesn't expect any request body.
 */
getAgentsByDepartmentAndChannel(params: {
  channelNumber: string;
  departmentId?: string;
  page?: number;
  limit?: number;
  active?: string;
}): Observable<Array<AgentDto>> {

  return this.getAgentsByDepartmentAndChannel$Response(params).pipe(
    map((r: StrictHttpResponse<Array<AgentDto>>) => r.body as Array<AgentDto>)
  );
}


 /**
   * Path part for operation getSecretKey
   */
 static readonly GetSecretKeyPath = '/api/v1/secretKey';

 /**
  * Get Secret Key.
  *
  * API to get the secret key
  *
  * This method provides access to the full `HttpResponse`, allowing access to response headers.
  * To access only the response body, use `getSecretKey()` instead.
  *
  * This method doesn't expect any request body.
  */
 getSecretKey$Response(
): Observable<StrictHttpResponse<string>> {

   const rb = new RequestBuilder(this.rootUrl, AgentControllerService.GetSecretKeyPath, 'get');
   return this.http.request(rb.build({
     responseType: 'text',
      accept: 'text/plain'
   })).pipe(
     filter((r: any) => r instanceof HttpResponse),
     map((r: HttpResponse<any>) => {
       return r as StrictHttpResponse<string>;
     })
   );
 }

 /**
  * Get Secret Key.
  *
  * API to get the secret key
  *
  * This method provides access to only to the response body.
  * To access the full response (for headers, for example), `getSecretKey$Response()` instead.
  *
  * This method doesn't expect any request body.
  */
 getSecretKey(
): Observable<string> {

   return this.getSecretKey$Response().pipe(
     map((r: StrictHttpResponse<string>) => r.body as string)
   );
 }

}
