/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DeviceSimDto } from '../models/device-sim-dto';
import { DeviceSimLinkDto } from '../models/device-sim-link-dto';
import { LinkedSimsDto } from '../models/linked-sims-dto';
import { Resource } from '../models/resource';

@Injectable({
  providedIn: 'root',
})
export class DeviceSimMapControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation linkDeviceToSim
   */
  static readonly LinkDeviceToSimPath = '/api/v1/device/{deviceSerialNo}/linkSims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkDeviceToSim()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkDeviceToSim$Response(params: {
    deviceSerialNo: string;
    body?: DeviceSimDto
  }): Observable<StrictHttpResponse<Array<DeviceSimLinkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceSimMapControllerService.LinkDeviceToSimPath, 'post');
    if (params) {
      rb.path('deviceSerialNo', params.deviceSerialNo, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceSimLinkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkDeviceToSim$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  linkDeviceToSim(params: {
    deviceSerialNo: string;
    body?: DeviceSimDto
  }): Observable<Array<DeviceSimLinkDto>> {

    return this.linkDeviceToSim$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceSimLinkDto>>) => r.body as Array<DeviceSimLinkDto>)
    );
  }

  /**
   * Path part for operation linkDeviceToSimInBulk
   */
  static readonly LinkDeviceToSimInBulkPath = '/api/v1/simDevices/link';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `linkDeviceToSimInBulk()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  linkDeviceToSimInBulk$Response(params?: {
    body?: {
'multipartFile'?: Blob;
}
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceSimMapControllerService.LinkDeviceToSimInBulkPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `linkDeviceToSimInBulk$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  linkDeviceToSimInBulk(params?: {
    body?: {
'multipartFile'?: Blob;
}
  }): Observable<string> {

    return this.linkDeviceToSimInBulk$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getLinkedSims
   */
  static readonly GetLinkedSimsPath = '/api/v1/device/{deviceId}/sims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLinkedSims()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLinkedSims$Response(params: {
    deviceId: string;
  }): Observable<StrictHttpResponse<LinkedSimsDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceSimMapControllerService.GetLinkedSimsPath, 'get');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LinkedSimsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLinkedSims$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLinkedSims(params: {
    deviceId: string;
  }): Observable<LinkedSimsDto> {

    return this.getLinkedSims$Response(params).pipe(
      map((r: StrictHttpResponse<LinkedSimsDto>) => r.body as LinkedSimsDto)
    );
  }

  /**
   * Path part for operation deactivateDeviceSimMappings
   */
  static readonly DeactivateDeviceSimMappingsPath = '/api/v1/sims/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateDeviceSimMappings()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivateDeviceSimMappings$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceSimMapControllerService.DeactivateDeviceSimMappingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateDeviceSimMappings$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivateDeviceSimMappings(params?: {
    body?: Array<string>
  }): Observable<void> {

    return this.deactivateDeviceSimMappings$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDeviceLinkedToSim
   */
  static readonly GetDeviceLinkedToSimPath = '/api/v1/sim/linkedDevices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceLinkedToSim()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDeviceLinkedToSim$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<DeviceSimLinkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceSimMapControllerService.GetDeviceLinkedToSimPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceSimLinkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceLinkedToSim$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDeviceLinkedToSim(params?: {
    body?: Array<string>
  }): Observable<Array<DeviceSimLinkDto>> {

    return this.getDeviceLinkedToSim$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceSimLinkDto>>) => r.body as Array<DeviceSimLinkDto>)
    );
  }

  /**
   * Path part for operation delinkDeviceToSim
   */
  static readonly DelinkDeviceToSimPath = '/api/v1/device/{deviceSerialNo}/delinkSims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delinkDeviceToSim()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delinkDeviceToSim$Response(params: {
    deviceSerialNo: string;
    body?: DeviceSimDto
  }): Observable<StrictHttpResponse<Array<DeviceSimLinkDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceSimMapControllerService.DelinkDeviceToSimPath, 'patch');
    if (params) {
      rb.path('deviceSerialNo', params.deviceSerialNo, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceSimLinkDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `delinkDeviceToSim$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  delinkDeviceToSim(params: {
    deviceSerialNo: string;
    body?: DeviceSimDto
  }): Observable<Array<DeviceSimLinkDto>> {

    return this.delinkDeviceToSim$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceSimLinkDto>>) => r.body as Array<DeviceSimLinkDto>)
    );
  }

  /**
   * Path part for operation getDeviceSimMappingCsvTemplate
   */
  static readonly GetDeviceSimMappingCsvTemplatePath = '/api/v1/simDevices/link/csvTemplate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceSimMappingCsvTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceSimMappingCsvTemplate$Response(params?: {
  }): Observable<StrictHttpResponse<Resource>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceSimMapControllerService.GetDeviceSimMappingCsvTemplatePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Resource>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceSimMappingCsvTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceSimMappingCsvTemplate(params?: {
  }): Observable<Resource> {

    return this.getDeviceSimMappingCsvTemplate$Response(params).pipe(
      map((r: StrictHttpResponse<Resource>) => r.body as Resource)
    );
  }

}
