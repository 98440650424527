<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img (click)="navigateBack()" class="back-btn" [src]="backImage" />
        <img class="page-icon-small" [src]="whatsappIcon" />
        <span>{{'WHATSAPP_TEMPLATE_CONFIGURATION' | translate}}</span>
      </span>
    </div>
    <div class="grid-container-whatsapp-create-template-container">
      <div class="template-fields-container">
        <div class="form-spacing">
          <form>
            <div [formGroup]="templateForm" class="grid-container-whatsapp-create-template">
              <div ngClass="disable-pointer-event">
                <mat-form-field>
                  <mat-label class="label">{{'ACCOUNT' | translate}}
                    <span class="mandatory">*</span>
                  </mat-label>
                  <mat-select tabindex="-1" class="input-field" [formControlName]="'account'"
                    [placeholder]="'SELECT' | translate"
                    (selectionChange)="updateDropdownValues({child:'tenant',value:$event.value})">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of dropDownValues['account']" [value]="val.id">
                      {{val['name'] | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['account']?.touched && templateForm.controls['account']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['account']?.errors?.required">
                    {{'ACCOUNT' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>

              <div ngClass="disable-pointer-event">
                <mat-form-field>
                  <mat-label class="label">{{'TENANT' | translate}}
                    <span class="mandatory">*</span>
                  </mat-label>
                  <mat-select tabindex="-1" class="input-field" [formControlName]="'tenant'"
                    [placeholder]="'SELECT' | translate"
                    (selectionChange)="updateDropdownValues({child:'businessProfile',value:$event.value})">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of dropDownValues['tenant']" [value]="val.id">
                      {{val['name'] | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['tenant']?.touched && templateForm.controls['tenant']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['tenant']?.errors?.required">
                    {{'TENANT' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>

              <div ngClass="disable-pointer-event">
                <mat-form-field>
                  <mat-label class="label">{{'BUSINESS_PROFILE' | translate}}
                    <span class="mandatory">*</span>
                  </mat-label>
                  <mat-select tabindex="-1" class="input-field" [formControlName]="'businessProfile'"
                    [placeholder]="'SELECT' | translate"
                    (selectionChange)="updateDropdownValues({child:'channel',value:$event.value})">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of dropDownValues['businessProfile']" [value]="val.id">
                      {{val['name'] | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['businessProfile']?.touched && templateForm.controls['businessProfile']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['businessProfile']?.errors?.required">
                    {{'BUSINESS_PROFILE' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>

              <div ngClass="disable-pointer-event">
                <mat-form-field>
                  <mat-label class="label">{{'CHANNEL' | translate}}
                    <span class="mandatory">*</span>
                  </mat-label>
                  <mat-select tabindex="-1" class="input-field" [formControlName]="'channel'"
                    [placeholder]="'SELECT' | translate"
                    (selectionChange)="updateDropdownValues({child:'language',value:$event.value})">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of dropDownValues['channel']" [value]="val.id">
                      {{val['name'] | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['channel']?.touched && templateForm.controls['channel']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['channel']?.errors?.required">
                    {{'CHANNEL' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>

              <div [ngClass]="{'disable-pointer-event': disablePointer}">
                <mat-form-field>
                  <mat-label class="label">{{'LANGUAGE' | translate}}
                    <span class="mandatory">*</span>
                  </mat-label>
                  <mat-select [tabIndex]="disablePointer?-1:0" class="input-field" [formControlName]="'language'"
                    [placeholder]="'SELECT' | translate">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of dropDownValues['language']" [value]="val.id">
                      {{val['name'] | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['language']?.touched && templateForm.controls['language']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['language']?.errors?.required">
                    {{'LANGUAGE' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>

              <div [ngClass]="{'disable-pointer-event': disablePointer}">
                <mat-form-field>
                  <mat-label class="label">{{'DEVICE_CATEGORY' | translate}}
                    <span class="mandatory">*</span>
                  </mat-label>
                  <mat-select [tabIndex]="disablePointer?-1:0" class="input-field" [formControlName]="'category'" [compareWith]="compareFn"
                    [placeholder]="'SELECT' | translate" (selectionChange)="updateDropdownValues({child:'authenticationTemplate',value:$event.value})">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of dropDownValues['category']" [value]="val">
                      {{val['name'] | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['category']?.touched && templateForm.controls['category']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['category']?.errors?.required">
                    {{'DEVICE_CATEGORY' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>

              <div [ngClass]="{'disable-pointer-event': disablePointer}">
                <mat-form-field>
                  <mat-label class="label">{{ 'WHATSAPP_TEMPLATE_NAME' | translate}}
                    <span class="mandatory">*</span>
                  </mat-label>
                  <input [tabIndex]="disablePointer?-1:0" class="input-field" type="'text'" matInput
                    [formControlName]="'whatsappTemplateName'" [placeholder]="'TYPE_HERE' | translate" />
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['whatsappTemplateName']?.touched && templateForm.controls['whatsappTemplateName']?.errors">
                  <mat-error class="mat-error-class"
                    *ngIf="templateForm.controls['whatsappTemplateName']?.errors?.required">
                    {{'WHATSAPP_TEMPLATE_NAME' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                  <mat-error class="mat-error-class"
                    *ngIf="templateForm.controls['whatsappTemplateName']?.errors?.pattern">
                    {{'TEMPLATE_NAME_PATTERN_MESSAGE' | translate}}
                  </mat-error>
                </div>
              </div>

              <div [ngClass]="{'disable-pointer-event': disablePointer || isAuthTemplate }">
                <mat-form-field>
                  <mat-label class="label">{{ 'WHATSAPP_TEMPLATE_CONTENT' | translate}}
                    <span class="mandatory">*</span>
                    <span *ngIf="!isAuthTemplate && !disablePointer" class="info-icon info-icon-template-content">
                      <img [src]="infoIcon" alt="info" [title]="'FORMATTED_TEXT_TRICKS'  | translate">
                    </span>
                    <span *ngIf="isAuthTemplate" class="info-icon info-icon-template-content">
                      <img [src]="infoIcon" alt="info" [title]="'AUTH_TEMPLATE_INFO_MSG'  | translate">
                    </span>
                  </mat-label>
                  <textarea [tabIndex]="disablePointer?-1:0" class="input-field" matInput
                    [formControlName]="'whatsappTemplateContent'" [placeholder]="'TYPE_HERE' | translate" rows="4"
                    (input)="onTextInputChange()" maxlength="1024">
                 </textarea>
                  <div *ngIf=!isAuthTemplate class="emoji-left">
                    <span class="show-emoji-button" (click)="toggleEmojiPicker()"
                      [title]="'EMOJI'  | translate"><mat-icon class="rb-ic" fontIcon="bosch-ic-emoji-happy"
                        (click)="onClose()"></mat-icon></span>
                    <span class="character-count">{{ characterContentCount }} / {{
                      maxCharacterContentCount }}</span>
                  </div>
                </mat-form-field>
                <emoji-mart class="emoji-mart" set="{{set}}" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
                  [emojiTooltip]=true [showPreview]="false"
                  [style]="{ position: 'absolute', left: '20px','z-index':2,'margin-top': '-3rem'}"></emoji-mart>
                <div
                  *ngIf="submitted || templateForm.controls['whatsappTemplateContent']?.touched && templateForm.controls['whatsappTemplateContent']?.errors">
                  <mat-error class="mat-error-class"
                    *ngIf="templateForm.controls['whatsappTemplateContent']?.errors?.required">
                    {{'WHATSAPP_TEMPLATE_CONTENT' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                  <mat-error class="mat-error-class"
                    *ngIf="templateForm.controls['whatsappTemplateContent']?.errors?.maxlength">
                    {{'WHATSAPP_TEMPLATE_CONTENT_SHOULD_BE_OF_MAXIMUM_1024_CHARACTERS' | translate}}</mat-error>
                </div>
              </div>

              <div *ngIf=isAuthTemplate class = 'auth-template' [ngClass]="{'disable-pointer-event': disablePointer}">
                <div>
                  <mat-checkbox [formControlName]="'securityMessage'" [checked]="disableSecurityPointer" [tabIndex]="disablePointer?-1:0"  (change)="onCheckboxChange($event,'addSecurityMsg')">
                  </mat-checkbox><mat-label class="label-url-opt-out">{{'SECURITY_RECOMMENDATION' | translate}}</mat-label>
                </div>
                <div>
                  <mat-checkbox [formControlName]="'expiryTime'" [checked]="disableExpiryPointer" [tabIndex]="disablePointer?-1:0"  (change)="onCheckboxChange($event,'addExpiryTime')">
                  </mat-checkbox><mat-label class="label-url-opt-out">{{'EXPIRY_TIME' | translate}}</mat-label>
                </div>
             </div>
             
             <div *ngIf="disableExpiryPointer && templateForm.controls['setExpiryTime']" [ngClass]="{'disable-pointer-event': disablePointer}">
              <mat-form-field class="mat-form-field">
                <mat-label class="label">
                  {{'EXPIRES_IN' | translate}}
                  <span class="mandatory">*</span>
                </mat-label>
                <input type="number" matInput class="form-field" [placeholder]="'TYPE_HERE' | translate" [formControlName]="'setExpiryTime'" (input)="onExpiryTimeChange($event)"/>
              </mat-form-field>
              <div
              *ngIf="submitted || templateForm.controls['setExpiryTime']?.touched && templateForm.controls['setExpiryTime']?.errors">
                <div *ngIf="templateForm.controls['setExpiryTime']?.errors?.required">
                  <mat-error class="mat-error">
                    {{'EXPIRES_IN' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
                <div *ngIf="templateForm.controls['setExpiryTime']?.errors?.min">
                  <mat-error class="mat-error">
                    {{'EXPIRY_ERROR_MSG_MIN' | translate }}
                  </mat-error>
                </div>
                <div *ngIf="templateForm.controls['setExpiryTime']?.errors?.max">
                  <mat-error class="mat-error">
                    {{'EXPIRY_ERROR_MSG' | translate}}
                  </mat-error>
                </div>
              </div>
             </div>

             <div *ngIf="isAuthTemplate" [ngClass]="{'disable-pointer-event': disablePointer}">
              <mat-form-field>
                <mat-label class="label">{{ 'BUTTON_TEXT' | translate}}
                  <span class="mandatory">*</span>
                  <span *ngIf="!disablePointer" class="info-icon-template-content info-icon-buttonType info-icon-header">
                    <img [src]="infoIcon" alt="info" [title]="'COPY_CODE_MSG'  | translate">
                  </span>
                </mat-label>
                <input [tabIndex]="disablePointer?-1:0" class="input-field" type="'text'" matInput
                  [formControlName]="'copyCodeButton'" [placeholder]="'TYPE_HERE' | translate" />
              </mat-form-field>
              <div
                *ngIf="submitted || templateForm.controls['copyCodeButton']?.touched && templateForm.controls['copyCodeButton']?.errors">
                <mat-error class="mat-error-class" *ngIf="templateForm.controls['copyCodeButton']?.errors?.required">
                  {{'BUTTON_TEXT' | translate}} {{'IS_REQUIRED' | translate }}
                </mat-error>
                <mat-error class="mat-error-class" *ngIf="templateForm.controls['copyCodeButton']?.errors?.maxlength">
                  {{'BUTTON_TEXT_SHOULD_BE_OF_MAXIMUM_25_CHARACTERS' | translate}}</mat-error>
              </div>
            </div>

              <div *ngIf="templateForm.controls['footer']" [ngClass]="{'disable-pointer-event': disablePointer}">
                <div [ngClass]="{'disable-pointer-event': disableFooterPointer}">
                <mat-form-field>
                  <mat-label class="label">{{ 'FOOTER' | translate}}
                  </mat-label>
                  <textarea [tabIndex]="disablePointer?-1:0" class="input-field" matInput [formControlName]="'footer'"
                    [placeholder]="'TYPE_HERE' | translate" rows="4" maxlength="60" (input)="onTextInputFooterChange()">
                 </textarea>
                </mat-form-field>
              </div>
                <div
                  *ngIf="submitted || templateForm.controls['footer']?.touched && templateForm.controls['footer']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['footer']?.errors?.required">
                    {{'FOOTER' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['footer']?.errors?.maxlength">
                    {{'FOOTER_SHOULD_BE_OF_MAXIMUM_60_CHARACTERS' | translate }}
                  </mat-error>
                </div>
                <span class="footer-checkbox" *ngIf="!disablePointer">
                <mat-checkbox [checked]="disableFooterPointer" [tabIndex]="disablePointer?-1:0"  (change)="onCheckboxChange($event,'footer')">
                </mat-checkbox><mat-label class="label-url-opt-out">{{'ENABLE_TO_OPT_OUT' | translate}}</mat-label>
                <span> 
                  <span *ngIf="!disablePointer" class="info-icon-numbe">
                    <img [src]="infoIcon" alt="info" [title]="'DISCOVER_MORE_ABOUT_MANAGING_YOUR_WHATSAPP'  | translate"
                      class="enable-click">
                  </span>
                </span>
              </span>
                <div class="character-count">{{ characterFooterCount }} / {{ maxCharacterFooterCount }}</div>
              </div>

              <div *ngIf="templateForm.controls['headerType']" [ngClass]="{'disable-pointer-event': disablePointer}">
                <mat-form-field>
                  <mat-label class="label">{{'HEADER_TYPE' | translate}}
                  </mat-label>
                  <span *ngIf="!disablePointer" class="info-icon info-icon-header">
                    <img [src]="infoIcon" alt="info" [title]="'HEADER_TYPE_INFO'  | translate">
                  </span>
                  <mat-select [tabIndex]="disablePointer?-1:0" [compareWith]="compareFn" class="input-field"
                    [formControlName]="'headerType'" [placeholder]="'SELECT' | translate"
                    (selectionChange)="updateDropdownValues({child:'header',value:$event.value})">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of dropDownValues['headerType']" [value]="val">
                      {{val['name'] | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['headerType']?.touched && templateForm.controls['headerType']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['headerType']?.errors?.required">
                    {{'HEADER_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>

              <div *ngIf="templateForm.controls['header']" [ngClass]="{'disable-pointer-event': disablePointer}">
                <mat-form-field>
                  <mat-label class="label">{{ 'HEADER' | translate}}
                    <span class="mandatory">*</span>
                  </mat-label>
                  <input [tabIndex]="disablePointer?-1:0" class="input-field" type="'text'" matInput
                    [formControlName]="'header'" [placeholder]="'TYPE_HERE' | translate" />
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['header']?.touched && templateForm.controls['header']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['header']?.errors?.required">
                    {{'HEADER' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['header']?.errors?.maxlength">
                    {{'HEADER_SHOULD_BE_OF_MAXIMUM_60_CHARACTERS' | translate }}
                  </mat-error>
                </div>
              </div>

              <div *ngIf="templateForm.controls['file']">
                <button *ngIf="!disablePointer" (click)="OpenUploadDialog(UploadContent)" mat-raised-button
                  class="button button-field">
                  {{ (supportedMediaType === imageFormat ? 'UPLOAD_IMAGE' : (supportedMediaType === videoFormat ?
                  'UPLOAD_VIDEO' : 'UPLOAD_DOCUMENT')) | translate}} </button>
                <button *ngIf="disablePointer" [disabled]="!fileUrl" (click)="downloadFile()" mat-raised-button
                  class="button button-field">
                  {{ (supportedMediaType === imageFormat ? 'PREVIEW_IMAGE' : (supportedMediaType === videoFormat ?
                  'PREVIEW_VIDEO' : 'PREVIEW_DOCUMENT')) | translate}} </button>
                <p *ngIf="fileForUpload" class="file-name">{{uploadedFileName}}</p>
                <div
                  *ngIf="submitted || templateForm.controls['file']?.touched && templateForm.controls['file']?.errors">
                  <mat-error class="mat-error-class mat-error-class-file "
                    *ngIf="templateForm.controls['file']?.errors?.required">
                    {{'FILE' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>

              <ng-template #UploadContent let-modal>
                <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon>
                </div>
                <div class="modal-width">
                  <div>
                    <div class="modal-header">
                      <h5 class="modal-title" id="modal-basic-title">{{'FILE_UPLOAD' | translate}} </h5>
                    </div>
                    <div class="modal-body">
                      <input type="file" class="file-uploader" [accept]="supportedMediaType"
                        (change)="selectFiles($event)" />
                    </div>
                    <div class="modal-footer">
                      <button [disabled]="!fileForUpload || invalidFile" (click)="fileUpload()" mat-raised-button
                        class="button-upload">{{
                        'UPLOAD_FILE' | translate }}</button>
                    </div>
                  </div>
                </div>
              </ng-template>

              <div *ngIf="templateForm.controls['buttonsType']" [ngClass]="{'disable-pointer-event': disablePointer}">
                <mat-form-field>
                  <mat-label class="label">{{'BUTTONS_TYPE' | translate}}
                  </mat-label>
                  <span *ngIf="!disablePointer" class="info-icon info-icon-buttonType">
                    <img [src]="infoIcon" alt="info" [title]="'BUTTONS_TYPE_INFO'  | translate">
                  </span>
                  <mat-select [tabIndex]="disablePointer?-1:0" [compareWith]="compareFn" class="input-field"
                    [formControlName]="'buttonsType'" [placeholder]="'SELECT' | translate"
                    (selectionChange)="updateDropdownValues({child:'buttonText',value:$event.value})">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of dropDownValues['buttonsType']" [value]="val">
                      {{val['name'] | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
                <div
                  *ngIf="submitted || templateForm.controls['buttonsType']?.touched && templateForm.controls['buttonsType']?.errors">
                  <mat-error class="mat-error-class" *ngIf="templateForm.controls['buttonsType']?.errors?.required">
                    {{'BUTTONS_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>

              <div *ngIf="templateForm.controls['quickReply']" formArrayName="quickReply">
                <div *ngFor="let group of templateForm.get('quickReply')['controls']; index as indexNumber"
                  class="grid-layout-quick-reply">
                  <div [formGroupName]="indexNumber">
                    <div [ngClass]="{'disable-pointer-event': disablePointer}">
                      <mat-form-field>
                        <mat-label class="label">{{ 'BUTTON_TEXT' | translate}}
                          <span class="mandatory">*</span>
                        </mat-label>
                        <input [tabIndex]="disablePointer?-1:0" class="input-field" type="'text'" matInput
                          [formControlName]="'buttonText'" [placeholder]="'TYPE_HERE' | translate" />
                      </mat-form-field>
                      <div
                        *ngIf="submitted || group.controls['buttonText']?.touched && group.controls['buttonText']?.errors">
                        <mat-error class="mat-error-class" *ngIf="group.controls['buttonText']?.errors?.required">
                          {{'BUTTON_TEXT' | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                        <mat-error class="mat-error-class" *ngIf="group.controls['buttonText']?.errors?.maxlength">
                          {{'BUTTON_TEXT_SHOULD_BE_OF_MAXIMUM_25_CHARACTERS' | translate}}</mat-error>
                      </div>
                    </div>
                  </div>
                  <div class="action-buttons">
                    <button [disabled]="disablePointer" *ngIf="templateForm.get('quickReply')['controls'].length < 3"
                      type="button" mat-icon-button color="primary" aria-label="Add" class="action-button"
                      (click)="addFormGroup('quickReply')">+</button>
                    <button [disabled]="disablePointer" *ngIf="templateForm.get('quickReply')['controls'].length > 1"
                      mat-icon-button class="action-button" color="primary" aria-label="Remove/clear"
                      (click)="removeFormGroup('quickReply',indexNumber)">-</button>
                  </div>
                </div>
              </div>

              <div class="call-to-action" *ngIf="templateForm.controls['callToActionArr']"
                formArrayName="callToActionArr">
                <div *ngFor="let group of templateForm.get('callToActionArr')['controls']; index as indexNumber">
                  <div class="call-to-action-background" [formGroupName]="indexNumber"
                    [ngClass]="group.controls['urlType']?'grid-layout-action-website':'grid-layout-action-phone'">
                    <div *ngIf="group.controls['typeOfAction']" [ngClass]="{'disable-pointer-event': disablePointer}">
                      <mat-form-field class="custom-label-background">
                        <mat-label class="label">{{'TYPE_OF_ACTION' | translate}}
                          <span class="mandatory">*</span>
                        </mat-label>
                        <mat-select [tabIndex]="disablePointer?-1:0" [compareWith]="compareFn" class="input-field"
                          [formControlName]="'typeOfAction'" [placeholder]="'SELECT' | translate"
                          (selectionChange)="updateDropdownValues({child:'actionType',value:$event.value,index:indexNumber})">
                          <mat-option>{{ 'SELECT' | translate }}</mat-option>
                          <mat-option
                            [disabled]="(selectedTypeOfActionOne && indexNumber === 1 && val.name === selectedTypeOfActionOne) || (selectedTypeOfActionTwo && indexNumber === 0 && val.name === selectedTypeOfActionTwo)"
                            *ngFor="let val of dropDownValues['typeOfAction']" [value]="val">
                            {{val['name'] | translate}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div
                        *ngIf="submitted || group.controls['typeOfAction']?.touched && group.controls['typeOfAction']?.errors">
                        <mat-error class="mat-error-class" *ngIf="group.controls['typeOfAction']?.errors?.required">
                          {{'TYPE_OF_ACTION' | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                      </div>
                    </div>

                    <div *ngIf="group.controls['buttonText']" [ngClass]="{'disable-pointer-event': disablePointer}">
                      <mat-form-field class="custom-label-background">
                        <mat-label class="label">{{ 'BUTTON_TEXT' | translate}}
                          <span class="mandatory">*</span>
                        </mat-label>
                        <input [tabIndex]="disablePointer?-1:0" class="input-field" type="'text'" matInput
                          [formControlName]="'buttonText'" [placeholder]="'TYPE_HERE' | translate" />
                      </mat-form-field>
                      <div
                        *ngIf="submitted || group.controls['buttonText']?.touched && group.controls['buttonText']?.errors">
                        <mat-error class="mat-error-class" *ngIf="group.controls['buttonText']?.errors?.required">
                          {{'BUTTON_TEXT' | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                        <mat-error class="mat-error-class" *ngIf="group.controls['buttonText']?.errors?.maxlength">
                          {{'BUTTON_TEXT_SHOULD_BE_OF_MAXIMUM_25_CHARACTERS' | translate}}</mat-error>
                      </div>
                    </div>

                    <div *ngIf="group.controls['websiteUrl']" [ngClass]="{'disable-pointer-event': disablePointer}">
                      <mat-form-field class="custom-label-background">
                        <mat-label class="label">{{ 'WEBSITE_URL' | translate}}
                          <span class="mandatory">*</span>
                        </mat-label>
                        <input [tabIndex]="disablePointer?-1:0" class="input-field" type="'text'" matInput
                          [formControlName]="'websiteUrl'" [placeholder]="'TYPE_HERE' | translate" />
                      </mat-form-field>
                      <div
                        *ngIf="submitted || group.controls['websiteUrl']?.touched && group.controls['websiteUrl']?.errors">
                        <mat-error class="mat-error-class" *ngIf="group.controls['websiteUrl']?.errors?.required">
                          {{'WEBSITE_URL' | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                        <mat-error class="mat-error-class" *ngIf="group.controls['websiteUrl']?.errors?.pattern">
                          {{ 'INVALID_WEBSITE_URL' | translate }}
                        </mat-error>
                      </div>
                    </div>

                    <div class="urlType" *ngIf="group.controls['urlType']"
                      [ngClass]="{'disable-pointer-event': disablePointer}">

                      <mat-checkbox [tabIndex]="disablePointer?-1:0" [formControlName]="'urlType'"
                        class="mat-slider-toggle">
                      </mat-checkbox>
                      <span> <mat-label class="label-url">{{'ENABLE_CLICK_TRACKING' | translate}}
                          <span class="mandatory">*</span>
                        </mat-label>
                        <span *ngIf="!disablePointer" class="info-icon-numbe">
                          <img [src]="infoIcon" alt="info" [title]="'ENABLE_CLICK_TRACKING_INFO'  | translate"
                            class="enable-click">
                        </span>
                      </span>
                      <div *ngIf="submitted || group.controls['urlType']?.touched && group.controls['urlType']?.errors"
                        class="url-type-error">
                        <mat-error class="mat-error-class" *ngIf="group.controls['urlType']?.errors?.required">
                          {{'ENABLE_CLICK_TRACKING' | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                      </div>
                    </div>

                    <div *ngIf="group.controls['phoneNumber']" [ngClass]="{'disable-pointer-event': disablePointer}">
                      <mat-form-field class="custom-label-background">
                        <mat-label class="label">{{ 'PHONE_NUMBER' | translate}}
                          <span class="mandatory">*</span>
                        </mat-label>
                        <span *ngIf="!disablePointer" class="info-icon info-icon-number">
                          <img [src]="infoIcon" alt="info" [title]="'PHONE_NUMBER_INFO'  | translate">
                        </span>
                        <input [tabIndex]="disablePointer?-1:0" class="input-field" type="'text'" matInput
                          [formControlName]="'phoneNumber'" [placeholder]="'TYPE_HERE' | translate" />
                      </mat-form-field>
                      <div
                        *ngIf="submitted || group.controls['phoneNumber']?.touched && group.controls['phoneNumber']?.errors">
                        <mat-error class="mat-error-class" *ngIf="group.controls['phoneNumber']?.errors?.required">
                          {{'PHONE_NUMBER' | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                        <mat-error class="mat-error-class" *ngIf="group.controls['phoneNumber']?.errors?.pattern">
                          {{ 'INVALID_PHONE_NUMBER' | translate }}
                        </mat-error>
                      </div>
                    </div>

                    <div *ngIf="group.controls['buttonText']" class="action-buttons">
                      <button [disabled]="disablePointer"
                        *ngIf="templateForm.get('callToActionArr')['controls'].length < 2" type="button" mat-icon-button
                        color="primary" aria-label="Add" class="action-button"
                        (click)="addFormGroup('callToActionArr')">+</button>
                      <button [disabled]="disablePointer"
                        *ngIf="templateForm.get('callToActionArr')['controls'].length > 1" mat-icon-button
                        class="action-button" color="primary" aria-label="Remove/clear"
                        (click)="removeFormGroup('callToActionArr',indexNumber)">-</button>
                    </div>
                  </div>
                </div>
              </div>
        
            </div>
          </form>
          <div>
            <section [ngClass]="{'display-none':disablePointer}" class="submit-section">
              <button (click)="onSubmit()" mat-raised-button class="button button-submit">{{ 'SUBMIT' | translate
                }}</button>
              <button (click)="onReset()" mat-button class="button">{{ 'RESET' | translate }}</button>
            </section>
          </div>
        </div>
      </div>
      <div class="template-preview-container" [ngClass]="{'template-preview-container-dark': !lightMode}">
        <div class="whatsapp-preview-header">
          <div>{{'PREVIEW' | translate}}</div>
          <div><a (click)="lightMode = !lightMode" class="circular label">
              {{ lightMode ? 'Dark' : 'Light' }}
            </a>
          </div>
        </div>
        <div class="whatsapp-message-container">
          <div class="whatsapp-message">
            <div *ngIf="templateForm.controls['header']" class="text-header">{{templateForm.controls['header']?.value}}
            </div>
            <div *ngIf="templateForm.controls['headerType']?.value"
              [ngClass]="{'header': templateForm.controls['headerType'].value['name'] !== 'Text', 'header-background-size': templateForm.controls['headerType'].value['name'] === 'Location' || templateForm.controls['headerType'].value['name'] === 'Document'}"
              [style.backgroundImage]="templateForm.controls['headerType'].value['name'] === 'Location' ? 'url(' + whatsAppLocationImg + ')' : (templateForm.controls['headerType'].value['name'] === 'Document' ? 'url(' + whatsAppDocumentImg + ')' : 'none')">
              <img *ngIf="fileUrl && supportedMediaType == imageFormat" [src]="fileUrl" class="header-media">
              <video [src]="fileUrl" type="video/mp4" class="header-media" *ngIf="fileUrl && supportedMediaType == videoFormat" controls>
              </video>
            </div>
            <div class="body">
              <div [innerHTML]="templateForm.controls['whatsappTemplateContent']?.value | textFormat"></div>
              <div class="footer">{{templateForm.controls['footer']?.value}}</div>
              <div *ngIf="(isAuthTemplate && templateForm.get('setExpiryTime')?.valid && disableExpiryPointer) || footerText" class="footer">{{expiryTime}}</div>
              <div class="metadata">
                <span class="time">{{currentTime | date: "h:mm a" }}</span>
              </div>
            </div>
          </div>

          <div *ngIf="templateForm.controls['quickReply']">
            <div *ngFor="let group of templateForm.get('quickReply')['controls']; index as indexNumber"
              class="preview_action_button_container">
              <div class="quick_reply_button preview_action_button" *ngIf="indexNumber < 2">
                <span class="btntext">{{templateForm.get('quickReply')?.value[indexNumber]['buttonText']}}</span>
              </div>
            </div>
          </div>

          <div *ngIf="isAuthTemplate && templateForm.get('copyCodeButton')?.valid">
              <div class="quick_reply_button preview_action_button">
                <span class="btntext">{{templateForm.get('copyCodeButton').value}}</span>
              </div>
          </div>
          
          <div *ngIf="templateForm.controls['callToActionArr']">
            <div *ngFor="let group of templateForm.get('callToActionArr')['controls']; index as indexNumber"
              class="preview_action_button_container">
              <div class="quick_reply_button preview_action_button" *ngIf="indexNumber < 2">
                <mat-icon
                  *ngIf="templateForm.get('callToActionArr')?.value[indexNumber]['typeOfAction']['name'] == 'Visit Website'"
                  class="rb-ic icon" class="bosch-ic-externallink"></mat-icon>
                <mat-icon
                  *ngIf="templateForm.get('callToActionArr')?.value[indexNumber]['typeOfAction']['name'] == 'Call Phone Number'"
                  class="rb-ic" class="bosch-ic-call"></mat-icon>
                <span class="btntext">{{templateForm.get('callToActionArr')?.value[indexNumber]['buttonText']}}</span>
              </div>
            </div>
          </div>

          <div *ngIf="templateForm.controls['quickReply'] && templateForm.controls['quickReply'].value.length > 2"
            (click)="optionsPopup = true" class="see-more-option-container">
            <div class="seeOptionWpr">
              <div class="optionlistIcon"></div>
              <div class="options">{{'SEE_ALL_OPTIONS' | translate}}</div>
            </div>
          </div>
          <div [style.display]="optionsPopup ? 'block' : 'none'">
            <div class="overlay" ></div>
            <div class="optionPopup" >
              <div class="optionHeader">
                <div class="remove-icon" id="closePopupIcon"><img class="rb-ic" (click)="optionsPopup = false"
                    [src]="closeIcon" /></div>
                <div class="headerText">{{'ALL_OPTIONS' | translate}}</div>
              </div>
              <div *ngIf="templateForm.controls['quickReply']">
                <ul *ngFor="let group of templateForm.get('quickReply')['controls']; index as indexNumber">
                  <li>
                    <div class="tildeArrow"></div>
                    <div>{{templateForm.get('quickReply')?.value[indexNumber]['buttonText']}}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>