/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ReportDto } from '../models/report-dto';
import { ServicetypeDto } from '../models/servicetype-dto';

@Injectable({
  providedIn: 'root',
})
export class ReportControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation usageReport
   */
  static readonly UsageReportPath = '/api/v1/usageReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usageReport()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usageReport$Response(params?: {
      body?: ReportDto
  }): Observable<StrictHttpResponse<Array<ServicetypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ReportControllerService.UsageReportPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ServicetypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `usageReport$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  usageReport(params?: {
      body?: ReportDto
  }): Observable<Array<ServicetypeDto>> {

    return this.usageReport$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ServicetypeDto>>) => r.body as Array<ServicetypeDto>)
    );
  }

}
