/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ActivityLogDto } from '../models/activity-log-dto';
import { GMapBillingMetadataDto } from '../models/g-map-billing-metadata-dto';

@Injectable({
  providedIn: 'root',
})
export class GMapBillingControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation gmapReviewNotOk
   */
  static readonly GmapReviewNotOkPath = '/api/v1/gmap-billing/review-not-ok';

  /**
   * Review not ok G Map Billing Excel.
   *
   * The API updates the status and delete G map billing excel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gmapReviewNotOk()` instead.
   *
   * This method doesn't expect any request body.
   */
  gmapReviewNotOk$Response(params: {
    orderId: string;
    month: number;
    year: number;
    comments: string;
  }): Observable<StrictHttpResponse<GMapBillingMetadataDto>> {

    const rb = new RequestBuilder(this.rootUrl, GMapBillingControllerService.GmapReviewNotOkPath, 'patch');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('month', params.month, {});
      rb.query('year', params.year, {});
      rb.query('comments', params.comments, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GMapBillingMetadataDto>;
      })
    );
  }

  /**
   * Review not ok G Map Billing Excel.
   *
   * The API updates the status and delete G map billing excel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gmapReviewNotOk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gmapReviewNotOk(params: {
    orderId: string;
    month: number;
    year: number;
    comments: string;
  }): Observable<GMapBillingMetadataDto> {

    return this.gmapReviewNotOk$Response(params).pipe(
      map((r: StrictHttpResponse<GMapBillingMetadataDto>) => r.body as GMapBillingMetadataDto)
    );
  }

  /**
   * Path part for operation gmapBillingStatusInReview
   */
  static readonly GmapBillingStatusInReviewPath = '/api/v1/gmap-billing/in-review/{gmapMetadataId}';

  /**
   * Review GMAP Billing Excel.
   *
   * The API to change status of GMap billing excel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `gmapBillingStatusInReview()` instead.
   *
   * This method doesn't expect any request body.
   */
  gmapBillingStatusInReview$Response(params: {
    gmapMetadataId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, GMapBillingControllerService.GmapBillingStatusInReviewPath, 'patch');
    if (params) {
      rb.path('gmapMetadataId', params.gmapMetadataId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Review GMAP Billing Excel.
   *
   * The API to change status of GMap billing excel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `gmapBillingStatusInReview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  gmapBillingStatusInReview(params: {
    gmapMetadataId: string;
  }): Observable<string> {

    return this.gmapBillingStatusInReview$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation reviewOk
   */
  static readonly ReviewOkPath = '/api/v1/gmap-billing/review-ok';

  /**
   * Review GMAP Billing Excel.
   *
   * The API to parse and save billing excel
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `reviewOk()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewOk$Response(params: {
    orderId: string;
    month: number;
    year: number;
    comments?: string;
  }): Observable<StrictHttpResponse<GMapBillingMetadataDto>> {

    const rb = new RequestBuilder(this.rootUrl, GMapBillingControllerService.ReviewOkPath, 'put');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('month', params.month, {});
      rb.query('year', params.year, {});
      rb.query('comments', params.comments, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GMapBillingMetadataDto>;
      })
    );
  }

  /**
   * Review GMAP Billing Excel.
   *
   * The API to parse and save billing excel
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `reviewOk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  reviewOk(params: {
    orderId: string;
    month: number;
    year: number;
    comments?: string;
  }): Observable<GMapBillingMetadataDto> {

    return this.reviewOk$Response(params).pipe(
      map((r: StrictHttpResponse<GMapBillingMetadataDto>) => r.body as GMapBillingMetadataDto)
    );
  }

  /**
   * Path part for operation upload
   */
  static readonly UploadPath = '/api/v1/gmap-billing/upload';

  /**
   * Upload GMAP Billing Excel.
   *
   * The API to upload GMAP billing excel to storage Account
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upload()` instead.
   *
   * This method sends 'multipart/form-data' and handles request body of type 'multipart/form-data'.
   */
  upload$Response(params: {
    orderId: string;
    comments?: string;
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<GMapBillingMetadataDto>> {

    const rb = new RequestBuilder(this.rootUrl, GMapBillingControllerService.UploadPath, 'post');
    if (params) {
      rb.query('orderId', params.orderId, {});
      rb.query('comments', params.comments, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GMapBillingMetadataDto>;
      })
    );
  }

  /**
   * Upload GMAP Billing Excel.
   *
   * The API to upload GMAP billing excel to storage Account
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upload$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  upload(params: {
    orderId: string;
    comments?: string;
    body?: {
'file'?: Blob;
}
  }): Observable<GMapBillingMetadataDto> {

    return this.upload$Response(params).pipe(
      map((r: StrictHttpResponse<GMapBillingMetadataDto>) => r.body as GMapBillingMetadataDto)
     );
   }
   
  /**
   * Path part for operation getGmapActivityLogListByMetaDataId
   */
  static readonly GetGmapActivityLogListByMetaDataIdPath = '/api/v1/gmap-billing/activity-log/{gmapBillingMetadataId}';

  /**
   * Manage Gmap Activity log .
   *
   * The API to view the Gmap activity log
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGmapActivityLogListByMetaDataId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGmapActivityLogListByMetaDataId$Response(params: {
    gmapBillingMetadataId: string;
  }): Observable<StrictHttpResponse<Array<ActivityLogDto>>> {

    const rb = new RequestBuilder(this.rootUrl, GMapBillingControllerService.GetGmapActivityLogListByMetaDataIdPath, 'get');
    if (params) {
      rb.path('gmapBillingMetadataId', params.gmapBillingMetadataId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ActivityLogDto>>;
      })
    );
  }

  /**
   * Manage Gmap Activity log .
   *
   * The API to view the Gmap activity log
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getGmapActivityLogListByMetaDataId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGmapActivityLogListByMetaDataId(params: {
    gmapBillingMetadataId: string;
  }): Observable<Array<ActivityLogDto>> {

    return this.getGmapActivityLogListByMetaDataId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ActivityLogDto>>) => r.body as Array<ActivityLogDto>)
    );
  }

}
