import { Injectable, ViewChild, Directive } from '@angular/core';
import { TagControllerService } from '../services/DeviceMgmt/services/tag-controller.service';
import { DynamicFormComponent } from '../shared/dynamic-form/dynamic-form';
import { ResponseHandlerService } from './response-handler-service';
import { TagDto } from '../services/DeviceMgmt/models';
import { ObjectToIdConversionService } from './object-to-id-conversion-service';
import { Subject } from 'rxjs';
import { BciLoaderService } from '@bci-web-core/core';
@Directive()
@Injectable({
    providedIn: 'root'
})

export class TagsServiceProvider {
  public dropDownValues = [];
  public showSpinner :any;
  public tags = [];
  public refreshSearchSubject = new Subject <any> ();
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  constructor (private tagControllerService: TagControllerService,
    private responseHandlerService: ResponseHandlerService,
    private objectToIdConversionService: ObjectToIdConversionService,private loaderService: BciLoaderService) {
  }
  public addOrRemoveTags($event, dynamicForm, tagsList?: Array<TagDto>, entityName?: string, entityList?: any[]) {
    this.dynamicForm = dynamicForm;
      if ($event.selectedTag) {
        if (dynamicForm.selectedValuesArray.length) {
          if (dynamicForm.selectedValuesArray.includes($event.selectedTag)) {
            this.responseHandlerService.returnToastMessage('warning', 'DUPLICATE_TAG');
            dynamicForm.inputCtrl.nativeElement.value = '';
          } else {
            dynamicForm.selectedValuesArray.push($event.selectedTag);
            dynamicForm.inputCtrl.nativeElement.value = '';
            if (entityList && entityList.length) {
              this.createAttachTag($event.selectedTag, dynamicForm, tagsList, entityName, entityList);
            }
          }
        } else {
              dynamicForm.selectedValuesArray.push($event.selectedTag);
              dynamicForm.inputCtrl.nativeElement.value = '';
              if (entityList && entityList.length) {
                this.createAttachTag($event.selectedTag, dynamicForm, tagsList, entityName, entityList);
              }
            }
        } else if ($event.tag && $event.action === 'removeSelectedAttribute') {
          this.removeTag($event, tagsList, entityName, entityList);
        } else {
          this.addTag($event, dynamicForm, tagsList, entityName, entityList);
        }
}

public removeTag($event, tagsList?: Array<TagDto>, entityName?: string, entityList?: any[] ): void {
  const tagEntityDto = {};
  const index = this.dynamicForm.selectedValuesArray.indexOf($event.tag);
    if ($event.tag) {
      if (tagsList && tagsList.length && entityName && entityList && entityList.length) {
        tagsList.forEach(tag => {
          if (tag.name === $event.tag) {
            const obj = this.objectToIdConversionService.convertObjectToId(tag.id);
            this.tags.push(obj);
          }
        });
        if (this.tags.length) {
          tagEntityDto[entityName] = entityList;
          tagEntityDto['tags'] = this.tags;
          this.detachTagsFromEntities(tagEntityDto);
          if (index >= 0) {
            this.dynamicForm.selectedValuesArray.splice(index, 1);
          }
        } else if (!this.tags.length && index >= 0) {
          this.dynamicForm.selectedValuesArray.splice(index, 1);
        }
      } else {
        if (index >= 0) {
          this.dynamicForm.selectedValuesArray.splice(index, 1);
        }
      }
    }
  }

addTag(value, dynamicForm, tagsList?: Array<TagDto>, entityName?: string, entityList?: any[] ) {
  if (value) {
    if (this.dynamicForm.stringArray && this.dynamicForm.stringArray.length) {
        if (dynamicForm.selectedValuesArray && dynamicForm.selectedValuesArray.includes(value)) {
          this.responseHandlerService.returnToastMessage('warning', 'DUPLICATE_TAG');
        } else {
          this.dynamicForm.selectedValuesArray.push(value);
          if (entityList && entityList.length) {
            this.createAttachTag(value, dynamicForm, tagsList, entityName, entityList);
          }
        }
    } else {
      if (dynamicForm.selectedValuesArray && dynamicForm.selectedValuesArray.includes(value)) {
        this.responseHandlerService.returnToastMessage('warning', 'DUPLICATE_TAG');
      } else {
      this.dynamicForm.selectedValuesArray.push(value);
      if (entityList && entityList.length) {
        this.createAttachTag(value, dynamicForm, tagsList, entityName, entityList);
      }
    }
    }
  }
  this.dynamicForm.inputCtrl.nativeElement.value = '';
}

public createAttachTag($event, dynamicForm, tagsList?: Array<TagDto>, entityName?: string, entityList?: any[]) {
  const TagEntityDto = {};
  const deviceListForTag = [];
  const tags = [];
  entityList.forEach(device => {
    deviceListForTag.push(device);
  });
  const tagCommonObj = {'name': $event };
  tags.push(tagCommonObj);
  TagEntityDto[entityName] = deviceListForTag;
  TagEntityDto['tags'] = tags;
  if (tags.length) {
    this.attachTagsToEntities(TagEntityDto);
  } else {
    this.loaderService.hideProgressBar(this.showSpinner);
  }
}

public attachTagsToEntities(TagEntityDto) {
  this.tagControllerService.attachTagEntity({body: TagEntityDto}).subscribe(
    resp => {
        this.responseHandlerService.returnToastMessage('success', 'TAGGED_SUCCESSFULLY');
        this.refreshSearchSubject.next('refreshAttachSearch');
        return;
    },
    err => {
      this.responseHandlerService.returnToastMessage('error', err.error.message);
      return;
    }
  );
}

public detachTagsFromEntities(TagEntityDto) {
  this.tagControllerService.detachTagEntity({body: TagEntityDto}).subscribe(
    resp => {
      this.responseHandlerService.returnToastMessage('success', 'UNTEGGED_SUCCESSFULLY');
      this.tags = [];
      TagEntityDto = {};
      this.refreshSearchSubject.next('refreshDetachSearch');
      return;
    },
    err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        return;
      }
    );
  }
}
