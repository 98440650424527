<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="back-image" (click)="navigateBack()" [src]="backLeftImage" />
        <img class="page-icon" [src]="accessKeyIcon" />
        <span>{{'LIST_PROJECTS' |translate}}</span>
      </span>
    </div>

    <mat-table [dataSource]="dataSource" class="table-data">
      <div *ngFor="let column of columns">
        <ng-container [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
          <mat-cell class="tooltip" *matCellDef="let dataName">
            <span *ngIf="column!=='action' && column!=='active'" title="{{dataName[column]}}" class="cell-value">
              {{dataName[column]}}
            </span>
            <span *ngIf="column==='action'">
              <button class="subscribe-btn" (click)="gotoListMapKeysPage(dataName)">{{ 'VIEW_KEYS' | translate
                }}</button>
            </span>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row *matRowDef="let data; columns: columns"></mat-row>
    </mat-table>
  </section>
</section>