/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BulkSimOperationsDto } from '../models/bulk-sim-operations-dto';
import { CommonDetails } from '../models/common-details';
import { SimDetailsDto } from '../models/sim-details-dto';
import { SimDetailsResponseDto } from '../models/sim-details-response-dto';
import { SimProviderProfileDto } from '../models/sim-provider-profile-dto';

@Injectable({
  providedIn: 'root',
})
export class SimProviderControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllSimProviders
   */
  static readonly GetAllSimProvidersPath = '/api/v1/simProviders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSimProviders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSimProviders$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.GetAllSimProvidersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSimProviders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSimProviders(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.getAllSimProviders$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation getAllSimProfiles
   */
  static readonly GetAllSimProfilesPath = '/api/v1/simProfiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSimProfiles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSimProfiles$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.GetAllSimProfilesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSimProfiles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllSimProfiles(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.getAllSimProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation getProfilesBySimProviderId
   */
  static readonly GetProfilesBySimProviderIdPath = '/api/v1/simProviders/{simProviderId}/profiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfilesBySimProviderId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfilesBySimProviderId$Response(params: {
    simProviderId: string;
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.GetProfilesBySimProviderIdPath, 'get');
    if (params) {
      rb.path('simProviderId', params.simProviderId, {});
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfilesBySimProviderId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfilesBySimProviderId(params: {
    simProviderId: string;
  }): Observable<Array<CommonDetails>> {

    return this.getProfilesBySimProviderId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation deleteBulkSims
   */
  static readonly DeleteBulkSimsPath = '/api/v1/deleteSims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBulkSims()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteBulkSims$Response(params?: {
    body?: BulkSimOperationsDto
  }): Observable<StrictHttpResponse<SimDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.DeleteBulkSimsPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteBulkSims$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteBulkSims(params?: {
    body?: BulkSimOperationsDto
  }): Observable<SimDetailsDto> {

    return this.deleteBulkSims$Response(params).pipe(
      map((r: StrictHttpResponse<SimDetailsDto>) => r.body as SimDetailsDto)
    );
  }

  /**
   * Path part for operation getSimDetailsBySimRegistrationId
   */
  static readonly GetSimDetailsBySimRegistrationIdPath = '/api/v1/simDetails/{simRegId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimDetailsBySimRegistrationId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSimDetailsBySimRegistrationId$Response(params: {
    simRegId: string;
    body?: SimDetailsDto
  }): Observable<StrictHttpResponse<SimDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.GetSimDetailsBySimRegistrationIdPath, 'post');
    if (params) {
      rb.path('simRegId', params.simRegId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSimDetailsBySimRegistrationId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSimDetailsBySimRegistrationId(params: {
    simRegId: string;
    body?: SimDetailsDto
  }): Observable<SimDetailsDto> {

    return this.getSimDetailsBySimRegistrationId$Response(params).pipe(
      map((r: StrictHttpResponse<SimDetailsDto>) => r.body as SimDetailsDto)
    );
  }

  /**
   * Path part for operation activateSims
   */
  static readonly ActivateSimsPath = '/api/v1/activateSims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateSims()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateSims$Response(params?: {
    body?: BulkSimOperationsDto
  }): Observable<StrictHttpResponse<BulkSimOperationsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.ActivateSimsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkSimOperationsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateSims$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  activateSims(params?: {
    body?: BulkSimOperationsDto
  }): Observable<BulkSimOperationsDto> {

    return this.activateSims$Response(params).pipe(
      map((r: StrictHttpResponse<BulkSimOperationsDto>) => r.body as BulkSimOperationsDto)
    );
  }

  /**
   * Path part for operation deactivateSims
   */
  static readonly DeactivateSimsPath = '/api/v1/deactivateSims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateSims()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivateSims$Response(params?: {
    body?: BulkSimOperationsDto
  }): Observable<StrictHttpResponse<BulkSimOperationsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.DeactivateSimsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkSimOperationsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateSims$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deactivateSims(params?: {
    body?: BulkSimOperationsDto
  }): Observable<BulkSimOperationsDto> {

    return this.deactivateSims$Response(params).pipe(
      map((r: StrictHttpResponse<BulkSimOperationsDto>) => r.body as BulkSimOperationsDto)
    );
  }

  /**
   * Path part for operation registerBulkSim
   */
  static readonly RegisterBulkSimPath = '/api/v1/registerBulkSim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerBulkSim$Json()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerBulkSim$Json$Response(params?: {
    body?: {
'metaData'?: string;
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<Array<SimDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.RegisterBulkSimPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerBulkSim$Json$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerBulkSim$Json(params?: {
    body?: {
'metaData'?: string;
'file'?: Blob;
}
  }): Observable<Array<SimDetailsDto>> {

    return this.registerBulkSim$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimDetailsDto>>) => r.body as Array<SimDetailsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerBulkSim$FormData()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  registerBulkSim$FormData$Response(params?: {
    body?: {
'metaData'?: string;
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<Array<SimDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.RegisterBulkSimPath, 'post');
    if (params) {
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerBulkSim$FormData$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  registerBulkSim$FormData(params?: {
    body?: {
'metaData'?: string;
'file'?: Blob;
}
  }): Observable<Array<SimDetailsDto>> {

    return this.registerBulkSim$FormData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimDetailsDto>>) => r.body as Array<SimDetailsDto>)
    );
  }

  /**
   * Path part for operation registerSimProvider
   */
  static readonly RegisterSimProviderPath = '/api/v1/registerSimProvider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerSimProvider()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSimProvider$Response(params?: {
    body?: SimProviderProfileDto
  }): Observable<StrictHttpResponse<SimProviderProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.RegisterSimProviderPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimProviderProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerSimProvider$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSimProvider(params?: {
    body?: SimProviderProfileDto
  }): Observable<SimProviderProfileDto> {

    return this.registerSimProvider$Response(params).pipe(
      map((r: StrictHttpResponse<SimProviderProfileDto>) => r.body as SimProviderProfileDto)
    );
  }

  /**
   * Path part for operation registerSimProfile
   */
  static readonly RegisterSimProfilePath = '/api/v1/registerSimProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerSimProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSimProfile$Response(params?: {
    body?: SimProviderProfileDto
  }): Observable<StrictHttpResponse<SimProviderProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.RegisterSimProfilePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
       accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimProviderProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerSimProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSimProfile(params?: {
    body?: SimProviderProfileDto
  }): Observable<SimProviderProfileDto> {

    return this.registerSimProfile$Response(params).pipe(
      map((r: StrictHttpResponse<SimProviderProfileDto>) => r.body as SimProviderProfileDto)
    );
  }

  /**
   * Path part for operation deleteSimProviders
   */
  static readonly DeleteSimProvidersPath = '/api/v1/deleteSimProviders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSimProviders()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSimProviders$Response(params?: {
    body?: SimProviderProfileDto
  }): Observable<StrictHttpResponse<SimProviderProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.DeleteSimProvidersPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimProviderProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSimProviders$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSimProviders(params?: {
    body?: SimProviderProfileDto
  }): Observable<SimProviderProfileDto> {

    return this.deleteSimProviders$Response(params).pipe(
      map((r: StrictHttpResponse<SimProviderProfileDto>) => r.body as SimProviderProfileDto)
    );
  }

  /**
   * Path part for operation deleteSimProfiles
   */
  static readonly DeleteSimProfilesPath = '/api/v1/deleteSimProfiles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSimProfiles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSimProfiles$Response(params?: {
    body?: SimProviderProfileDto
  }): Observable<StrictHttpResponse<SimProviderProfileDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.DeleteSimProfilesPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimProviderProfileDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSimProfiles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSimProfiles(params?: {
    body?: SimProviderProfileDto
  }): Observable<SimProviderProfileDto> {

    return this.deleteSimProfiles$Response(params).pipe(
      map((r: StrictHttpResponse<SimProviderProfileDto>) => r.body as SimProviderProfileDto)
    );
  }

  /**
   * Path part for operation registerSim1
   */
  static readonly RegisterSim1Path = '/api/v1/registerSim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerSim1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSim1$Response(params?: {
    body?: SimDetailsDto
  }): Observable<StrictHttpResponse<SimDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.RegisterSim1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerSim1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSim1(params?: {
    body?: SimDetailsDto
  }): Observable<SimDetailsDto> {

    return this.registerSim1$Response(params).pipe(
      map((r: StrictHttpResponse<SimDetailsDto>) => r.body as SimDetailsDto)
    );
  }

  /**
   * Path part for operation getAllSims1
   */
  static readonly GetAllSims1Path = '/api/v1/searchSims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllSims1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllSims1$Response(params?: {
    page?: number;
    limit?: number;
    body?: SimDetailsDto
  }): Observable<StrictHttpResponse<SimDetailsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.GetAllSims1Path, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimDetailsResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllSims1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllSims1(params?: {
    page?: number;
    limit?: number;
    body?: SimDetailsDto
  }): Observable<SimDetailsResponseDto> {

    return this.getAllSims1$Response(params).pipe(
      map((r: StrictHttpResponse<SimDetailsResponseDto>) => r.body as SimDetailsResponseDto)
    );
  }

  /**
   * Path part for operation updateSim1
   */
  static readonly UpdateSim1Path = '/api/v1/editSim/{simRegId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSim1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSim1$Response(params: {
    simRegId: string;
    body?: SimDetailsDto
  }): Observable<StrictHttpResponse<SimDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.UpdateSim1Path, 'post');
    if (params) {
      rb.path('simRegId', params.simRegId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSim1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSim1(params: {
    simRegId: string;
    body?: SimDetailsDto
  }): Observable<SimDetailsDto> {

    return this.updateSim1$Response(params).pipe(
      map((r: StrictHttpResponse<SimDetailsDto>) => r.body as SimDetailsDto)
    );
  }
/**
   * Path part for operation saveSimProviderProfileMapping
   */
static readonly SaveSimProviderProfileMappingPath = '/api/v1/sim/providerProfileMapping';

/**
 * This method provides access to the full `HttpResponse`, allowing access to response headers.
 * To access only the response body, use `saveSimProviderProfileMapping()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
saveSimProviderProfileMapping$Response(params?: {
  body?: SimProviderProfileDto
}): Observable<StrictHttpResponse<SimProviderProfileDto>> {

  const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.SaveSimProviderProfileMappingPath, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json'
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<SimProviderProfileDto>;
    })
  );
}

/**
 * This method provides access to only to the response body.
 * To access the full response (for headers, for example), `saveSimProviderProfileMapping$Response()` instead.
 *
 * This method sends `application/json` and handles request body of type `application/json`.
 */
saveSimProviderProfileMapping(params?: {
  body?: SimProviderProfileDto
}): Observable<SimProviderProfileDto> {

  return this.saveSimProviderProfileMapping$Response(params).pipe(
    map((r: StrictHttpResponse<SimProviderProfileDto>) => r.body as SimProviderProfileDto)
  );
}
/**
   * Path part for operation getAllSimProfilesBySimProviderId
   */
static readonly GetAllSimProfilesBySimProviderIdPath = '/api/v1/simProvider/{simProviderId}/simProfiles';

/**
 * This method provides access to the full `HttpResponse`, allowing access to response headers.
 * To access only the response body, use `getAllSimProfilesBySimProviderId()` instead.
 *
 * This method doesn't expect any request body.
 */
getAllSimProfilesBySimProviderId$Response(params: {
  simProviderId: string;
}): Observable<StrictHttpResponse<Array<SimProviderProfileDto>>> {

  const rb = new RequestBuilder(this.rootUrl, SimProviderControllerService.GetAllSimProfilesBySimProviderIdPath, 'get');
  if (params) {
    rb.path('simProviderId', params.simProviderId, {});
  }

  return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json'
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<SimProviderProfileDto>>;
    })
  );
}

/**
 * This method provides access to only to the response body.
 * To access the full response (for headers, for example), `getAllSimProfilesBySimProviderId$Response()` instead.
 *
 * This method doesn't expect any request body.
 */
getAllSimProfilesBySimProviderId(params: {
  simProviderId: string;
}): Observable<Array<SimProviderProfileDto>> {

  return this.getAllSimProfilesBySimProviderId$Response(params).pipe(
    map((r: StrictHttpResponse<Array<SimProviderProfileDto>>) => r.body as Array<SimProviderProfileDto>)
  );
}

}
