<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="whatsappIcon" />
        <span>{{ 'DEPARTMENT_CREATION' | translate }}</span>
      </span>
    </div>
    <form [formGroup]="departmentForm" (ngSubmit)="createDepartment()">
      <div class="input-container">
        <div>
          <mat-form-field class="mat-form-field">
            <mat-label class="label">
              {{ 'DEPARTMENT_NAME' | translate }}
              <span class="mandatory">*</span>
            </mat-label>
            <input type="text" matInput class="form-field" [placeholder]="'TYPE_HERE' | translate" formControlName="departmentName" />
          </mat-form-field>
          <div
            *ngIf="submitted || departmentForm.controls.departmentName?.touched && departmentForm.controls.departmentName?.errors">
              <div *ngIf="departmentForm.controls.departmentName?.errors?.required">
                <mat-error class="mat-error">
                  {{ 'DEPARTMENT_NAME_REQUIRED' | translate }}
                </mat-error>
              </div>
              <div *ngIf="departmentForm.controls.departmentName?.errors?.minlength">
                <mat-error class="mat-error">
                  {{ 'DEPT_NAME_MIN' | translate }}
                </mat-error>
              </div>
              <div *ngIf="departmentForm.controls.departmentName?.errors?.maxlength">
                <mat-error class="mat-error">
                  {{ 'DEPT_NAME_MAX' | translate }}
                </mat-error>
              </div>
          </div>
        </div>
        <div>
          <mat-form-field class="mat-form-field">
            <mat-label class="label">
              {{ 'ASSIGNMENT_RULES' | translate }}
              <span class="mandatory">*</span>
              <span class="info-icon">
                <img [src]="infoIcon" alt="info" [title]="'RULES_ADD_LIMIT' | translate" class="info-images">
              </span>
            </mat-label>
            <input  matInput class="form-field" formControlName="tagInputControl" [placeholder]="'TYPE_HERE' | translate"
              #inputCtrl [matAutocomplete]="auto" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="add($event)">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
              <mat-option *ngFor="let val of filteredStrings | async" [value]="val">
                {{ val | translate }}
              </mat-option>
            </mat-autocomplete>
            <mat-chip-list #chipList>
              <mat-chip 
                *ngFor="let val of selectedValuesArray"
                class="tag-chip"
                [selectable]="selectable"
                [removable]="removable"
                (removed)="remove(val)"
                [value]="val">
                {{ val | translate }}
                  <mat-icon matChipRemove class="rb-ic" fontIcon="bosch-ic-close"></mat-icon>
                </mat-chip>
            </mat-chip-list>     
          </mat-form-field>
          <div
            *ngIf="submitted || departmentForm.controls.tagInputControl?.touched && departmentForm.controls.tagInputControl?.errors">
                <div *ngIf="departmentForm.controls.tagInputControl?.errors?.required">
                  <mat-error class="mat-error">
                    {{ 'ASSIGNMENT_RULES_REQUIRED' | translate }}
                  </mat-error>
                </div>
          </div>
        </div>
      </div>
      <div>
        <section class="section">
          <button type="submit" mat-raised-button class="button button-submit">
            {{ 'SUBMIT' | translate }}
          </button>
          <button (click)="onReset()" type="reset" mat-button class="button">
            {{ 'RESET' | translate }}
          </button>
        </section>
      </div>
    </form> 

    <div class="table-container" *ngIf="departmentList.length > 0">
      <mat-table [dataSource]="dataSource" class="table-data">
        <ng-container *ngFor="let column of columns" [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>
            {{ column | appendSpace | titlecase }}
          </mat-header-cell>
          <mat-cell class="tooltip" *matCellDef="let dataName">
            <span title="{{ dataName[column] }}" class="cell-value">
              {{ dataName[column] }}
            </span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
  </section>
</section>
