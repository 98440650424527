<div>
  <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
  <div class="popup-body">
    <div class="popup-content">
      <p>{{ data.message }}</p>
      <button mat-raised-button [mat-dialog-close]="true"
        class="manage-action-btn btn-background">{{ 'YES' | translate }}</button>
      <button type="reset" mat-button [mat-dialog-close]="false" class="manage-action-btn">{{ 'NO' | translate }}</button>
    </div>
  </div>
</div>