<!--
  ~ Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
-->
<!-- <a class="rb-ic icon" [matMenuTriggerFor]="menu" [ngClass]="classList"></a> -->

<mat-icon *ngIf="icon" class="rb-ic icon" [matMenuTriggerFor]="menu" fontIcon="bosch-ic-my-brand-frame"></mat-icon>
<mat-menu #menu="matMenu">
  <div class="header-style">
    <a [ngClass]="classList">
      <!-- tbd -->
      <div class="user-details">
        <span><img class="icon-size" [src]="userIcon" /></span>
        <span class="user-data">
          <div class="user-info">{{ 'USER_ID' | translate }} : {{ userProfileName }}</div>
          <div class="user-info">{{ 'USER_ROLE' | translate }} : {{ userProfileRole }}</div>
        </span>
      </div>
    </a>
    <div class="div-wrapper">
      <div *ngFor="let option of listOptions" class="div-seperator-class" (click)="optionSelectedListOption(option)">
        <span *ngIf="icon" ><mat-icon class="rb-ic ifont" fontIcon="{{option.icon}}" ></mat-icon></span>
        <span class={{option.labelClass}}>{{ option.label | translate }}</span>
      </div>
    </div>
  </div>
  <ul class="menu-list">
    <li>
      <div class="list-header">{{ 'SUBSCRIPTION' | translate }}</div>
    </li>
    <li>
      <div class="list-header">{{ 'NEED_HELP' | translate }}</div>
      <div class="list-div"><img class="list-icon" [src]="mailIcon" /><span class="list-description">{{'HELP_MSG' | translate}}</span></div>
    </li>
    <li>
      <div class="list-header">{{ 'DELETE_ACCOUNT' | translate }}</div>
      <div class="list-div"><div><img class="list-icon" [src]="optOutIcon" /></div><span class="list-description">{{'OPT_OUT' | translate}}</span></div>
    </li>
  </ul>
  <div *ngFor="let option of faqOptions">
    <div mat-menu-item *ngIf="routerUrl===option.route" class="faq-div">
      <span class="faq-span">{{ 'FAQ' | translate }}</span>
      <p class="help-desc">{{ 'FAQ_DESCRIPTION' | translate }} {{ option.label | translate }} </p>
      <a class="know-more">{{ 'KNOW_MORE' | translate }}</a>
    </div>
  </div>
</mat-menu>

<a [matMenuTriggerFor]="dropdown" *ngIf="dropDown" class="drop-atag">
  <span class="drop-deafult">{{ defaultValue | translate }}</span>
  <mat-icon class="bosch-ic-down arrow"></mat-icon>
</a>
<mat-menu #dropdown="matMenu">
  <div *ngFor="let option of options" class="drop-down-div" (click)="selectOption(option.langLabel)">
    <span class="drop-down-label">{{ option.langLabel | translate }}</span>
  </div>
  <div *ngIf="dropDownWithIcon">
    <div *ngFor="let option of optionsWithIcon" class="drop-down-div">
      <!-- <span class="rb-ic rb-ic-my-brand-frame icon"></span> -->
      <mat-icon class="rb-ic icon" fontIcon="bosch-ic-my-brand-frame"></mat-icon>
      <span class="drop-down-label">{{ option }}</span>
    </div>
  </div>
</mat-menu>