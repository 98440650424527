/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CustomerDto } from '../models/customer-dto';

@Injectable({
  providedIn: 'root',
})
export class CustomerControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllCustomers
   */
  static readonly GetAllCustomersPath = '/api/v1/customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomers$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CustomerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerControllerService.GetAllCustomersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CustomerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCustomers(params?: {
  }): Observable<Array<CustomerDto>> {

    return this.getAllCustomers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CustomerDto>>) => r.body as Array<CustomerDto>)
    );
  }

  /**
   * Path part for operation saveCustomerDetailsById
   */
  static readonly SaveCustomerDetailsByIdPath = '/api/v1/customer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveCustomerDetailsById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCustomerDetailsById$Response(params?: {
    body?: CustomerDto
  }): Observable<StrictHttpResponse<CustomerDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerControllerService.SaveCustomerDetailsByIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveCustomerDetailsById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveCustomerDetailsById(params?: {
    body?: CustomerDto
  }): Observable<CustomerDto> {

    return this.saveCustomerDetailsById$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerDto>) => r.body as CustomerDto)
    );
  }

  /**
   * Path part for operation getCustomerDetailsById
   */
  static readonly GetCustomerDetailsByIdPath = '/api/v1/customer/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDetailsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDetailsById$Response(params: {
    customerId: string;
  }): Observable<StrictHttpResponse<CustomerDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerControllerService.GetCustomerDetailsByIdPath, 'get');
    if (params) {
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCustomerDetailsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDetailsById(params: {
    customerId: string;
  }): Observable<CustomerDto> {

    return this.getCustomerDetailsById$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerDto>) => r.body as CustomerDto)
    );
  }

  /**
   * Path part for operation updateCustomerDetailsById
   */
  static readonly UpdateCustomerDetailsByIdPath = '/api/v1/customer/{customerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerDetailsById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerDetailsById$Response(params: {
    customerId: string;
    body?: CustomerDto
  }): Observable<StrictHttpResponse<CustomerDto>> {

    const rb = new RequestBuilder(this.rootUrl, CustomerControllerService.UpdateCustomerDetailsByIdPath, 'put');
    if (params) {
      rb.path('customerId', params.customerId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CustomerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCustomerDetailsById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCustomerDetailsById(params: {
    customerId: string;
    body?: CustomerDto
  }): Observable<CustomerDto> {

    return this.updateCustomerDetailsById$Response(params).pipe(
      map((r: StrictHttpResponse<CustomerDto>) => r.body as CustomerDto)
    );
  }

}
