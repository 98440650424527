/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse} from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BulkWhatsappUploadDto } from '../models/bulk-whatsapp-upload-dto';
import { WhatsappBulkDowloadUrlDto } from '../models/whatsapp-bulk-dowload-url-dto';
import { WhatsappJobDetailsDto } from '../models/whatsapp-job-details-dto';
import { WhatsappJobsRequestDto } from '../models/whatsapp-jobs-request-dto';
import { WhatsappJobsResponseDto } from '../models/whatsapp-jobs-response-dto';
import { WhatsappJobCostDto } from '../models/whatsapp-job-cost-dto';

@Injectable({
  providedIn: 'root',
})
export class BulkWhatsappUploadControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }



  /** Path part for operation `getBulkWhatsappJobCost()` */
  static readonly GetBulkWhatsappJobCostPath = '/api/v1/bulk-whatsapp/estimatedCost';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBulkWhatsappJobCost()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBulkWhatsappJobCost$Response(
    params: {
      categoryName: string;
      phoneNumberCount: number;
      body?: {
        'file'?: Blob;
      };
    }
  ): Observable<StrictHttpResponse<WhatsappJobCostDto>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      BulkWhatsappUploadControllerService.GetBulkWhatsappJobCostPath,
      'post'
    );
  
    if (params) {
      rb.query('categoryName', params.categoryName, {});
      rb.query('phoneNumberCount', params.phoneNumberCount, {});
      rb.body(params.body, 'multipart/form-data');
    }
  
    return this.http.request(
      rb.build({
        responseType: 'json',
        accept: 'application/json',
      })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({
          body: r.body as WhatsappJobCostDto,
        }) as StrictHttpResponse<WhatsappJobCostDto>;
      })
    );
  }
  

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBulkWhatsappJobCost$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getBulkWhatsappJobCost(
    params: {
      categoryName: string;
      phoneNumberCount: number;
      body?: {
        'file'?: Blob;
      };
    }
  ): Observable<WhatsappJobCostDto> {
    return this.getBulkWhatsappJobCost$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappJobCostDto>): WhatsappJobCostDto => r.body)
    );
  }
  

  /**
   * Path part for operation getWhatsappJobsByprofileIdAndSolutionTypeId
   */
  static readonly GetWhatsappJobsByprofileIdAndSolutionTypeIdPath = '/api/v1/bulk-whatsapp/jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhatsappJobsByprofileIdAndSolutionTypeId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappJobsByprofileIdAndSolutionTypeId$Response(params?: {
    status?: string; 
    body?: WhatsappJobsRequestDto
  }
): Observable<StrictHttpResponse<Array<WhatsappJobsResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, BulkWhatsappUploadControllerService.GetWhatsappJobsByprofileIdAndSolutionTypeIdPath, 'post');
    if (params) {
      rb.query('status', params.status, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhatsappJobsResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWhatsappJobsByprofileIdAndSolutionTypeId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getWhatsappJobsByprofileIdAndSolutionTypeId(params?: {
    status?: string;
    body?: WhatsappJobsRequestDto
  }
): Observable<Array<WhatsappJobsResponseDto>> {

    return this.getWhatsappJobsByprofileIdAndSolutionTypeId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WhatsappJobsResponseDto>>) => r.body as Array<WhatsappJobsResponseDto>)
    );
  }

  /**
   * Path part for operation parseFileAndWhatsappProcess
   */
  static readonly ParseFileAndWhatsappProcessPath = '/api/v1/bulk-whatsapp/job-scheduler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseFileAndWhatsappProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  parseFileAndWhatsappProcess$Response(params?: {
     
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BulkWhatsappUploadControllerService.ParseFileAndWhatsappProcessPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parseFileAndWhatsappProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parseFileAndWhatsappProcess(params?: {
     
  }
): Observable<string> {

    return this.parseFileAndWhatsappProcess$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation parseFileAndWhatsappOptInProcess
   */
  static readonly ParseFileAndWhatsappOptInProcessPath = '/api/v1/bulk-whatsapp/optIn-scheduler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseFileAndWhatsappOptInProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  parseFileAndWhatsappOptInProcess$Response(params?: {
     
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BulkWhatsappUploadControllerService.ParseFileAndWhatsappOptInProcessPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parseFileAndWhatsappOptInProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parseFileAndWhatsappOptInProcess(params?: {
     
  }
): Observable<string> {

    return this.parseFileAndWhatsappOptInProcess$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation parseFileAndWhatsappConversationProcess
   */
  static readonly ParseFileAndWhatsappConversationProcessPath = '/api/v1/bulk-whatsapp/conversation-scheduler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseFileAndWhatsappConversationProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  parseFileAndWhatsappConversationProcess$Response(params?: {
     
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BulkWhatsappUploadControllerService.ParseFileAndWhatsappConversationProcessPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parseFileAndWhatsappConversationProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parseFileAndWhatsappConversationProcess(params?: {
     
  }
): Observable<string> {

    return this.parseFileAndWhatsappConversationProcess$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getWhatsappJobFileUrlByJobName
   */
  static readonly GetWhatsappJobFileUrlByJobNamePath = '/api/v1/bulk-whatsapp/{jobId}/download-job-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhatsappJobFileUrlByJobName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhatsappJobFileUrlByJobName$Response(params: {
    jobId: string;
     
  }
): Observable<StrictHttpResponse<WhatsappBulkDowloadUrlDto>> {

    const rb = new RequestBuilder(this.rootUrl, BulkWhatsappUploadControllerService.GetWhatsappJobFileUrlByJobNamePath, 'get');
    if (params) {
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsappBulkDowloadUrlDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWhatsappJobFileUrlByJobName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhatsappJobFileUrlByJobName(params: {
    jobId: string;
  }): Observable<WhatsappBulkDowloadUrlDto> {

    return this.getWhatsappJobFileUrlByJobName$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappBulkDowloadUrlDto>) => r.body as WhatsappBulkDowloadUrlDto)
    );
  }

  /**
   * Path part for operation cancelSmsJobProcess
   */
  static readonly CancelSmsJobProcessPath = '/api/v1/bulk-whatsapp/{jobId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelSmsJobProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelSmsJobProcess$Response(params: {
    jobId: string;  
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BulkWhatsappUploadControllerService.CancelSmsJobProcessPath, 'patch');
    if (params) {
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelSmsJobProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelSmsJobProcess(params: {
    jobId: string;
  }
): Observable<string> {

    return this.cancelSmsJobProcess$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getWhatsappJobByJobId
   */
  static readonly GetWhatsappJobByJobIdPath = '/api/v1/bulk-whatsapp/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhatsappJobByJobId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhatsappJobByJobId$Response(params: {
    jobId: string;
  }
): Observable<StrictHttpResponse<WhatsappJobDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, BulkWhatsappUploadControllerService.GetWhatsappJobByJobIdPath, 'get');
    if (params) {
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhatsappJobDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWhatsappJobByJobId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhatsappJobByJobId(params: {
    jobId: string;
  }): Observable<WhatsappJobDetailsDto> {
    return this.getWhatsappJobByJobId$Response(params).pipe(
      map((r: StrictHttpResponse<WhatsappJobDetailsDto>) => r.body as WhatsappJobDetailsDto)
    );
  }

  /**
   * Path part for operation bulkWhatsappUploadExcel
   */
  static readonly BulkWhatsappUploadExcelPath = '/api/v1/bulk-whatsapp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkWhatsappUploadExcel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkWhatsappUploadExcel$Response(params: {
    bulkWhatsappDto: string;
    body?: {'multipartFile'?: Blob;}
  }): Observable<StrictHttpResponse<BulkWhatsappUploadDto>> {

    const rb = new RequestBuilder(this.rootUrl, BulkWhatsappUploadControllerService.BulkWhatsappUploadExcelPath, 'post');
    if (params) {
      rb.query('bulkWhatsappDto', params.bulkWhatsappDto, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkWhatsappUploadDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkWhatsappUploadExcel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkWhatsappUploadExcel(params: {
    bulkWhatsappDto: string;
    body?: {'multipartFile'?: Blob;}
  }): Observable<BulkWhatsappUploadDto> {

    return this.bulkWhatsappUploadExcel$Response(params).pipe(
      map((r: StrictHttpResponse<BulkWhatsappUploadDto>) => r.body as BulkWhatsappUploadDto)
    );
  }

}
