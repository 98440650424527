/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BusinessProfileControllerService, TenantControllerService, PlatformAdminUtilityControllerService,
  SolutionTypeControllerService
} from 'src/app/services/Platform/services';
import { BusinessProfileDto } from 'src/app/services/Platform/models';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from 'src/app/providers/translation-service';
import { MANAGE, EDIT_IMAGE, DELETE_IMAGE, CONFIGURE_IMAGE, MORE_OPTION, PROFILE_ICON } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { BciLoaderService } from '@bci-web-core/core';

/**
  * @ngdoc component
  * @name profiles.component:manageProfile
  *
  *
  * @description
  * description: To perform crud operations on the list of business profiles.
  *
  *
*/
@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss']
})

// activation/de-activation/deletion of multiple profiles to be taken up in next phase

export class ManageProfileComponent implements OnInit {
  public showSpinner :any;
  public tenantId;
  public profileData;
  public columns: any[];
  public jsonFileLoaded;
  public dataSource;
  public pageKey: string;
  public mode: string;
  public permissionList: Array<String> = [];
  public dropDownValues: any = {};
  public formData: any = {};
  asideMenus: Array<AsideCard>;
  ignoreList = ['id', 'links', 'tenant', 'active', 'realmName', 'businessTypeObject', 'solutionTypeObject', 'tenantObject',
    'interfaceTypeObject', 'interfaceTypes', 'solutionLinking'];
  selection = new SelectionModel<BusinessProfileDto>(true, []);
  selectedTenant;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  tenantValues: any;
  pageLimit: number[] = [10, 20, 30, 40, 50];
  editForm = false;
  businessProfileId: any;
  editImage: string;
  deleteImage: string;
  configureImage: string;
  moreOptionsImage: string;
  profileIcon: string;

  constructor(private translate: TranslationService, private businessProfileService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService, private _matDialog: MatDialog,
    private tenantService: TenantControllerService, private platformAdminutilityControllerService: PlatformAdminUtilityControllerService,
    private route: ActivatedRoute, private router: Router, private solutionTypeControllerService: SolutionTypeControllerService,
    private rightsideNavitemsService: RightsideNavitemsService, private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.getTenants();
    this.pageKey = 'profile';
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    if (this.route.snapshot.paramMap.get('businessProfileId') !== undefined || this.route.snapshot.paramMap.get('tenantId') !== undefined) {
      this.businessProfileId = this.route.snapshot.paramMap.get('businessProfileId');
      this.tenantId = this.route.snapshot.paramMap.get('tenantId');
    }
    this.editImage = EDIT_IMAGE;
    this.deleteImage = DELETE_IMAGE;
    this.configureImage = CONFIGURE_IMAGE;
    this.moreOptionsImage = MORE_OPTION;
    this.profileIcon = PROFILE_ICON;
  }

  /**
    * @ngdoc method
    * @name manageProfile#getBusinessProfiles
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: Fetch business profiles based on tenantId.
    *
    * @param {type} tenantId
    * @return {type} null
  */
  public getBusinessProfiles(tenantId) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.businessProfileService
      .getBusinessProfile1({ tenantId: tenantId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.profileData = resp;
            this.profileData.forEach((profile) => {
              profile['businessTypeObject'] = Object.assign({}, profile['businessType']);
              profile['tenantObject'] = profile['tenant'];
              profile['businessType'] = profile['businessType']['name'];
            });
            this.dataSource = new MatTableDataSource<BusinessProfileDto>(this.profileData);
            this.dataSource.paginator = this.paginator;
            for (const data of this.profileData) {
              data['action'] = null;
            }
            this.columns = this.filterColumns(Object.keys(this.profileData[0]));
          } else {
            this.profileData = null;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.dataSource = new MatTableDataSource<BusinessProfileDto>();
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageProfile#activateProfile
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: Activates particular business profile.
    *
    * @param {type} null
    * @return {type} null
  */
  public activateProfile() {
    this._matDialog.closeAll();
    this.formData['businessType'] = this.formData['businessTypeObject']['name'];
    this.showSpinner = this.loaderService.showProgressBar();
    this.businessProfileService
      .activateBusinessProfile({
        tenantId: this.tenantId,
        profileId: this.formData['id']
      })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'BUSINESS_PROFILE_ACTIVATE_SUCCESS');
          this.getBusinessProfiles(this.tenantId);
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
          this.formData['active'] = !this.formData['active'];
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageProfile#deActivateProfile
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: Deactivates particular business profile.
    *
    * @param {type} null
    * @return {type} null
  */
  public deActivateProfile() {
    this._matDialog.closeAll();
    this.formData['businessType'] = this.formData['businessTypeObject']['name'];
    this.showSpinner = this.loaderService.showProgressBar();
    this.businessProfileService
      .deactivateBusinessProfile({
        tenantId: this.tenantId,
        profileId: this.formData['id']
      })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'BUSINESS_PROFILE_DEACTIVATE_SUCCESS');
          this.getBusinessProfiles(this.tenantId);
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
          this.formData['active'] = !this.formData['active'];
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageProfile#deleteProfile
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: Deletes particular business profile.
    *
    * @param {type} null
    * @return {type} null
  */
  public deleteProfile() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.formData['businessType'] = this.formData['businessTypeObject']['name'];
    this.businessProfileService
      .deleteBusinessProfiles({
        tenantId: this.formData['tenant']['id'],
        profileId: this.formData['id']
      })
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this._matDialog.closeAll();
          this.responseHandlerService.returnToastMessage('success', 'BUSINESS_PROFILE_DELETE_SUCCESS');
          this.getBusinessProfiles(this.formData['tenant']['id']);
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this._matDialog.closeAll();
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageProfile#configureProfiles
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: Navigates to configure profile component.
    *
    * @param {type} data
    * @return {type} null
  */
  public configureProfiles(data) {
      this._matDialog.closeAll();
    if (data['active']) {
      this.router.navigate(['/configure-profiles', { value: data['id'], tenant: this.tenantId, businessProfileName:data['name']  }]);
    } else {
      this.responseHandlerService.returnToastMessage('error', 'ACTIVATE_THE_PROFILE_TO_CONFIGURE');
    }
  }

  /**
    * @ngdoc method
    * @name manageProfile#abortAction
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: Closes the popup and restore the business profile's active/inactive state.
    *
    * @param {type} null
    * @return {type} null
  */
  public abortAction() {
    this._matDialog.closeAll();
    this.formData['businessType'] = this.formData['businessTypeObject']['name'];
    // this.selection.clear();
    if (this.mode !== 'delete' && this.mode !== 'configure') {
      this.formData['active'] = !this.formData['active'];
    }
  }

  /**
    * @ngdoc method
    * @name manageProfile#filterColumns
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
    *
    * @param {type} columns
    * @return {type} list of columns
  */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  /**
    * @ngdoc method
    * @name manageProfile#checkPermission
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: Checks whether the logged in user has permission to perform crud operations.
    *
    * @param {type} key
    * @return {type} boolean
  */
  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  /**
    * @ngdoc method
    * @name manageProfile#getTenants
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: To populate tenants dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getTenants() {
    this.tenantService
      .findAllTenantsBasic({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.tenantValues = resp;
            this.dropDownValues['tenant'] = resp;
            if (this.tenantId === null) {
              this.selectedTenant = this.tenantValues[0]['name'];
              this.tenantId = this.tenantValues[0]['id'];
              this.getBusinessProfiles(this.tenantId);
            } else {
              const tenantDetails = this.tenantValues.find(tenant => tenant['id'] === this.tenantId);
              this.selectedTenant = tenantDetails['name'];
              this.getBusinessProfiles(this.tenantId);
            }
          } else {
            this.dropDownValues['tenant'] = [];
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageProfile#getProfilesByTenantId
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: Updates tenantId and invokes getBusinessProfiles method.
    *
    * @param {type} tenantId
    * @return {type} null
  */
  public getProfilesByTenantId(tenantId) {
    this.tenantId = tenantId;
    this.dataSource = null;
    this.profileData = null;
    this.columns = null;
    this.getBusinessProfiles(tenantId);
  }

  /**
    * @ngdoc method
    * @name manageProfile#onClose
    *
    * @methodOf
    * profiles.controller:manageProfile
    *
    * @description
    * Description: To close the popup.
    *
    * @param {type} null
    * @return {type} null
  */
  public onClose() {
    this.formData['businessType'] = this.formData['businessTypeObject']['name'];
    this._matDialog.closeAll();
  }

  public gotoConfirmDialog(data = null, mode?) {
    this.formData = data;
    this.mode = mode;
    let message;
    switch (mode) {
      case 'edit': if ((data['active'])) {
        message = this.translate.translateErrorMessages('ACTIVATE_SELECTED_PROFILE');
      } else {
        message = this.translate.translateErrorMessages('DEACTIVATE_SELECTED_PROFILE');
      }
        break;
      case 'delete': message = this.translate.translateErrorMessages('DELETE_SELECTED_PROFILE');
        break;
      case 'configure': message = this.translate.translateErrorMessages('CONFIGURE_SELECTED_PROFILE');
        break;
    }
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (mode) {
          case 'edit': if (data['active']) {
            this.activateProfile();
          } else {
            this.deActivateProfile();
          }
            break;
          case 'delete': this.deleteProfile();
            break;
          case 'configure': this.configureProfiles(data);
            break;
        }
      } else {
        this.abortAction();
      }
    });
  }

  public gotoProfilePage(fieldData) {
    this.router.navigate(['profiles/create-profiles', { profileId: fieldData['id'], tenantId: fieldData['tenant'].id }]);
  }
}

