/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PopupOverlayComponent } from '../popup-overlay/popup-overlay.component';
import {
  MCP_ICON, YOUTUBE_ICON, TWITTER_ICON, LINKEDIN_ICON, EMAIL_DASHBOARD_ICON,
  YOUTUBE_URL, TWITTER_URL, LINKEDIN_URL, SERVICE_DESK_ICON, BRAND_STORE_URL, WEBSITE_ICON, SUPPORT_PORTAL_ICON, SALES_CONTACT_URL,
  SALES_QUERY_URL,
  PERIPHERAL_SERVICES_URL,
  SOFTWARE_SERVICES_URL,
  INFRA_SERVICES_URL,
  CUSTOMER_SUPPORT_PORTAL_URL
} from '../constants/strings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
  * @this vm
  * @ngdoc FooterComponent
  * @name SharedModule.controller:FooterComponent
  *
  * @description
  * description: to implement footer according to Bosch standards throghout the application
*/
export class FooterComponent implements OnInit {

  @Input() footerOptions: any;
  @Input() tabFooterOptions: any;
  mcpLogo: any;
  websiteLogo: any;
  supportPortalIcon: any;
  linkedInLogo: any;
  twitterLogo: any;
  youtubeLogo: any;
  supportEmail: any;
  websiteUrl: any;
  linkedInUrl: any;
  twitterUrl: any;
  youtubeUrl: any;
  serviceDeskIcon: any;
  brandStoreUrl: any;
  salesContactUrl: any;
  salesQueryUrl: any;
  infraServicesUrl: any;
  softwareServicesUrl: any;
  peripheralServicesUrl: any;
  customerSupportUrl: any;
  constructor(private http: HttpClient, private router: Router, public dialog: MatDialog) { }

  ngOnInit() {
    this.mcpLogo = MCP_ICON;
    this.websiteLogo = WEBSITE_ICON;
    this.twitterLogo = TWITTER_ICON;
    this.youtubeLogo = YOUTUBE_ICON;
    this.linkedInLogo = LINKEDIN_ICON;
    this.serviceDeskIcon = SERVICE_DESK_ICON;
    this.supportEmail = EMAIL_DASHBOARD_ICON;
    this.youtubeUrl = YOUTUBE_URL;
    this.twitterUrl = TWITTER_URL;
    this.linkedInUrl = LINKEDIN_URL;
    this.brandStoreUrl = BRAND_STORE_URL;
    this.salesContactUrl = SALES_CONTACT_URL;
    this.salesQueryUrl = SALES_QUERY_URL;
    this.supportPortalIcon = SUPPORT_PORTAL_ICON;
    this.infraServicesUrl = INFRA_SERVICES_URL;
    this.softwareServicesUrl = SOFTWARE_SERVICES_URL;
    this.peripheralServicesUrl = PERIPHERAL_SERVICES_URL;
    this.customerSupportUrl = CUSTOMER_SUPPORT_PORTAL_URL;
    this.http.get('./assets/footer/options.json')
      .subscribe(data => {
        this.footerOptions = data;
      },
        err => {
          console.log('Error in fetching footer option json file.');
        });

    this.http.get('./assets/footer/tab-options.json')
      .subscribe(tabdata => {
        this.tabFooterOptions = tabdata;
      },
        err => {
          console.log('Error in fetching footer tab option json file.');
        });
  }


  /**
    * @ngdoc method
    * @name FooterComponent#onPrivacyClick
    *
    * @methodOf
    * SharedModule.controller:FooterComponent
    *
    * @description
    * Description:  mat dialog implementation is done for pop up.
    *
    * @param {type} name description
    * @return {type} name description
  */
  onPrivacyClick(): void {
    const dialogRef = this.dialog.open(PopupOverlayComponent, {
      maxWidth: '40vw',
      width: 'auto',
      data: { json: 'privacy-settings' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

    document.getElementsByTagName('body')[0].classList.add('blur-effect');
  }

}
