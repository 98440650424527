<section class="overview-container">
	<section class="main">
		<div class="header-style">
			<span class="heading-label">
				<img class="page-icon-small" [src]="firmwareIcon" />
				<span>{{'UPLOAD_FIRMWARE' |translate}}</span>
			</span>
		</div>
		<div class="create-form-spacing">
			<p class="disclaimer" *ngIf="selectedFileSize">{{fileSizeInfoMsg | translate}}</p>
			<app-dynamic-form [jsonFile]="jsonFileLoaded" [dropDownValues]="dropDownValues"
				(valueSelected)="updateDropdownValues($event)" [columnsInForm]="'multi'"
				(formEmitter)="uploadFirmware($event)" (resetForm)="resetForm($event)"
				(selectedTags)="addOrRemoveTags($event)">
			</app-dynamic-form>
		</div>
	</section>
	<section *ngIf="asideMenus">
		<app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
	</section>
</section>
