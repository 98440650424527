/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

import {
  BciImprintComponent,
  ModalWindowService,
  NavigationService,
  SidebarNavItem,
} from '@bci-web-core/core';
import { Observable } from 'rxjs';
import { KeycloakProfile } from 'keycloak-js';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { PlatformAdminUtilityControllerService } from './services/Platform/services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Mobility Cloud Platform';
  applicationTitle = 'Admin Menu';
  sidebarLinks = [];
  originalCopyOfSideLinks = [];
  userDetails: KeycloakProfile;
  sidebarFooterLinks: SidebarNavItem[] = [];
  routerUrl: any;
  showSideNav = true;
  userProfileObject = {};
  constructor(
    private titleService: Title,
    private navigationService: NavigationService,
    private modalWindowService: ModalWindowService,
    private translate: TranslateService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    protected router: Router
  ) {
    this.translate.setDefaultLang('en');
  }

  async ngOnInit() {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.translateMenuItems();
    });
    this.titleService.setTitle(this.title);
    // this.getSidebarLinks().subscribe(sidebarLinks => { this.sidebarLinks = sidebarLinks; });
    this.platformAdminUtilityControllerService
      .settings()
      .subscribe((sidebarLinks) => {
        const element: HTMLElement = document.getElementsByClassName(
          'burger'
        )[0] as HTMLElement;
        // element.click();
        // element.addEventListener('click', (e) => {
        //   this.sharedService.HamburgerContent.next('');
        // });
        this.originalCopyOfSideLinks = sidebarLinks;
        this.translateMenuItems();
      });
    this.platformAdminUtilityControllerService
      .getUserAccountDetails()
      .subscribe((resp) => {
        if (resp) {
          this.userProfileObject['userName'] = resp['userName'];
          this.userProfileObject['roles'] = resp['roles'];
        }
      });
    this.platformAdminUtilityControllerService.settings().subscribe(sidebarLinks => {
      const element: HTMLElement = document.getElementsByTagName('bci-burger')[0] as HTMLElement;
       element.click();
    //  element.addEventListener('click',(e)=>{
        //  this.sharedService.HamburgerContent.next('');
    // });
      this.originalCopyOfSideLinks = sidebarLinks;
      this.translateMenuItems();
    });
    this.platformAdminUtilityControllerService.getUserAccountDetails().subscribe(resp => {
      if (resp) {
        this.userProfileObject['userName'] = resp['userName'];
        this.userProfileObject['roles'] = resp['roles'];
      }
    });

    this.routerUrl = this.router.url;
    if (this.routerUrl.indexOf('/terms-and-conditions') !== -1) {
      this.showSideNav = false;
    }
  }

  getSidebarLinks(): Observable<SidebarNavItem[]> {
    return this.navigationService.getNavigationItems();
  }

  translateMenuItems() {
    const sidebarLinks = this.originalCopyOfSideLinks.map((sideItemLink) =>
      Object.assign({}, sideItemLink)
    );
    sidebarLinks.forEach((link) => {
      this.translate.get(link.title).subscribe((text: string) => {
        link.title = text;
      });
      if (link.items) {
        const subLinks = link.items.map((subItemLink) =>
          Object.assign({}, subItemLink)
        );
        subLinks.forEach((subLink) => {
          this.translate.get(subLink.title).subscribe((subtext: string) => {
            subLink.title = subtext;
          });
        });
        link.items = subLinks;
      }
    });

    this.sidebarLinks = sidebarLinks;
  }

  onAbout() {
    this.modalWindowService.openDialogWithComponent(BciImprintComponent);
  }
}
