<section class="overview-container">
    <section class="main">
        <div class="header-style">
            <span class="heading-label">
                <img class="page-icon" [src]="userIcon" />
                <span>{{'USER_MANAGEMENT_MANAGE' |translate}}</span>
            </span>
        </div>
        <div class="create-form-spacing">
            <div>
                <app-dynamic-form [jsonFile]="jsonFileLoaded" [dropDownValues]="dropDownValues"
                    [columnsInForm]="'single'" (valueSelected)="updateDropdownValues($event)"
                    (formEmitter)="getUserInformation($event)" (resetForm)="resetForm($event)">
                </app-dynamic-form>
                <div *ngIf="realmName" class="access-realm">
                    <div>{{ 'ACCESS' | translate }} {{ realmName }} {{'REALM' | translate}}</div>
                    <div>
                        <a href="{{aaaUrl}}/admin/master/console/#/realms/{{realmName}}" target="_blank">
                            <img [src]="arrowImage">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section *ngIf="asideMenus">
        <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
    </section>
</section>