import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
DOWNLOAD_ICON,
    REFRESH_WITH_TEXT,
    WHATSAPP_ICON,
  } from 'src/app/shared/constants/strings';
import {
    AccountControllerService,
    TenantControllerService,
    BusinessProfileControllerService,
    PlatformAdminUtilityControllerService,
  } from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { WhatsappDeliveryReportControllerService } from 'src/app/services/Whatsapp-helper/services';
import { WhatsappTemplateControllerService,BulkWhatsappUploadControllerService, WhatsappProfileConnectionDetailsControllerService } from 'src/app/services/Whatsapp/services';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BciLoaderService } from '@bci-web-core/core';
import { MatDialog } from '@angular/material/dialog';
import { DateValidatorService } from 'src/app/providers/date-validator.service';
import { MatTableDataSource } from '@angular/material/table';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { WhatsappDeliveryReportControllerV2Service } from 'src/app/services/Whatsapp-helper/services/whatsapp-delivery-report-controller-v-2.service';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';

@Component({
  selector: 'app-whatsapp-status',
  templateUrl: './whatsapp-status.component.html',
  styleUrls: ['./whatsapp-status.component.scss']
})

export class WhatsappStatusComponent implements OnInit {
  public whatsappIcon: string;
  public permissionList: Array<String> = [];
  public dropDownValues: any = {};
  public disableDownload = true;
  public accountId: string;
  public whatsappStatusData: any;
  public data: any[];
  public tenantId: string;
  public solutionTypeId;
  public profileId: string;
  public profileName: string;
  public showSpinner: any;
  public submitRequestJson: any;
  public statusData;
  public reportData;
  public reportDateToggle:boolean = true;
  public columns: any[];
  public configurationsList: Array<String> = [];
  public jsonFileLoaded: string;
  public toastDuration_10_second = 10000;
  public ignoreList = ['comments', 'downloadLink', 'profileId','request','requestId','user','updatedDt'];
  public downloadImage: string;
  public reportColumn: any[];
  public channelId:string;
  reportNameForm: FormGroup;
  public refreshIcon:string;
  public userName: string;
  public submitted = false;

  constructor(
    private httpsService: HttpClient,
    private route: ActivatedRoute,
    private accountControllerService:AccountControllerService,
    private responseHandlerService : ResponseHandlerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private bulkWhatsappUploadControllerService :BulkWhatsappUploadControllerService,
    private whatsappDeliveryReportControllerService: WhatsappDeliveryReportControllerService,
    private whatsappDeliveryReportControllerV2Service:WhatsappDeliveryReportControllerV2Service,
    private whatsappTemplateControllerService:WhatsappTemplateControllerService,
    private loaderService: BciLoaderService,
    private datePipe: DatePipe,
    private _matDialog: MatDialog, private dateValidatorService: DateValidatorService,
    private objectToIdConversionService : ObjectToIdConversionService,
    private whatsappProfileConnectionDetailsControllerService: WhatsappProfileConnectionDetailsControllerService,
    private platformAdminUtilityControllerService:PlatformAdminUtilityControllerService,
    private fb: FormBuilder,
    ) { 
  }

  ngOnInit(): void {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.getAccount();
    this.getTemplateCategories();
    this.whatsappIcon = WHATSAPP_ICON;
    if (this.permissionList.indexOf('WSP_RPT_R') > -1) {
        this.httpsService
          .get('./assets/form-fields/whatsapp-status.json')
          .subscribe((data) => {
            this.data = data as string[];
            this.whatsappStatusData = this.data['fields'];
          });
      }
    this.downloadImage = DOWNLOAD_ICON;
    this.refreshIcon = REFRESH_WITH_TEXT;
    this.viewRequest(true);
    this.initializeForm();
    this.getUserName();
  }

  public updateDropdownValues($event) {
    this.disableDownload = true;
    if ($event.value !== undefined) {
        if ($event.child.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child.child === 'solutionType') {
          this.getSolutionType($event.value);
          this.profileId = $event.value;
          this.generateExcelFilename($event.value);
          this.getChannel($event.value);
        }
        if ($event.child.child === 'channel') {
          this.solutionTypeId = $event.value;
        }
        if ($event.child.child === 'jobName') {
          this.channelId = $event.value;
          this.getWhatsappJobs();
          this.getProfileMappedTemplates($event.value);
        }
    }
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getSolutionType(profileId: string) {
    const solutionTypeActiveArray = [];
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({
        businessProfileId: profileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach((element) => {
              if (element['active'] === true) {
                solutionTypeActiveArray.push(element);
              }
            });
            this.dropDownValues['solutionType'] = solutionTypeActiveArray;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getTemplateCategories() {
    this.whatsappTemplateControllerService
      .whatsAppTemplateCategories({})
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['category'] = resp;
          } else {
            this.dropDownValues['category'] = [];
          }
        },
        (err) => {
          this.dropDownValues['category'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getWhatsappJobs() {
    this.dropDownValues['jobName'] = [];
    if (this.profileId) {
      const params = {
        status: 'completed',
        body: {
          channelId: this.channelId,
          solutionTypeId: this.solutionTypeId ? this.solutionTypeId : null
        }
      };
      this.bulkWhatsappUploadControllerService.getWhatsappJobsByprofileIdAndSolutionTypeId(params)
      .subscribe( resp => {
        if (resp && resp.length) {
          resp.forEach( row => {
            this.dropDownValues['jobName'].push({ id: row['jobId'], name: row['jobName']});
          });
        } else {
          this.dropDownValues['jobName'] = [];
        }
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
      });
    }
  }

  public getProfileMappedTemplates(channelId: string) {
    this.dropDownValues['templateName'] = [];
    this.whatsappTemplateControllerService
      .fetchChannelMappedTemplates({ channelId: channelId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach(template => {
              this.dropDownValues['templateName'].push({name: template['templateName'], id: template['id']});
            });
          } else {
            this.dropDownValues['templateName'] = [];
          }
        },
        (err) => {
          this.dropDownValues['templateName'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public generateExcelFilename(profileId) {
    this.dropDownValues['businessProfile'].forEach( row => {
      if (row['id'] === profileId) {
        this.profileName = row['name'];
      }
    });
  }


  public whatsappStatusEmitter(data: any,content) {
    this.reportNameForm.reset();
    this.reportNameForm.patchValue({emailId:this.userName});
    if (data['businessProfile'] === undefined) {
      this.responseHandlerService.returnToastMessage(
        'error',
        'PLEASE_SELECT_MANDATORY_FIELDS'
      );
      return;
    }

    if (data['mobileNumber']) {
      const regex = new RegExp('^(\\+\\d{1,3})?\\*?\\d{0,10}\\*?$');
      const validator = regex.test(data['mobileNumber']);
      if (!validator) {
        this.responseHandlerService.returnToastMessage(
          'error',
          'INVALID_MOBILE_NUMBER'
        );
        return;
      }
    }
    if (data['fromDate'] && data['toDate']) {
      data['fromDate'] = this.datePipe.transform(
        data['fromDate'],
        'yyyy-MM-dd'
      );
      data['toDate'] = this.datePipe.transform(data['toDate'], 'yyyy-MM-dd');
      if (data['fromDate'] > data['toDate']) {
        this.responseHandlerService.returnToastMessage(
          'error',
          'FROMDATE_LESSTHAN_TODATE'
        );
        return;
      }
    }
    this._matDialog.open(content);
    this.getWhatsappStatus(data);
  }

  public getWhatsappStatus(data: any) {
    const requestJson = {
      profileId: data['businessProfile'],
      solutionTypeId: data['solutionType'],
      templateId: data['templateName'],
      fromDate: this.datePipe.transform(data['fromDate'], 'yyyy-MM-dd'),
      toDate: this.datePipe.transform(data['toDate'], 'yyyy-MM-dd'),
      mobileNumber: (data['mobileNumber'] === '' ? null : data['mobileNumber']),
      channelId: data['channel'],
      msgType:this.objectToIdConversionService.getNameById(data['category'],this.dropDownValues['category']),
      jobId: data['jobName'],
    };
    this.submitRequestJson = requestJson;
  }
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  submitRequest() {
    this.submitted = true;
    if (this.reportNameForm.invalid) {
      return;
    }
    this.showSpinner = this.loaderService.showProgressBar();
    this._matDialog.closeAll();
    const reportName = this.reportNameForm.value;
    this.submitRequestJson.reportName = reportName.reportName;
    this.submitRequestJson.emailId = reportName.emailId;
    this.whatsappDeliveryReportControllerV2Service
      .submitDeliveryReportRequest({ body: this.submitRequestJson })
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
    this.responseHandlerService.returnToastMessage('success','DELIVERY_REQUEST_MESSAGE',this.toastDuration_10_second);
          this.handleApiResponse(resp);
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  private handleApiResponse(resp: any): void {
    let responseData: any[] = resp;
    responseData = responseData || [];
    responseData.forEach((data) => {
      data['createdDt'] = this.dateValidatorService.convertUTCToLocalDate(data['createdDt']);
      data['updatedDate'] = this.dateValidatorService.convertUTCToLocalDate(data['updatedDt']);
      data['download'] = null;
    });
    this.reportData = new MatTableDataSource<any>(responseData);
    this.reportColumn = this.filterColumns(Object.keys(responseData[0]));
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  viewRequest(firstTimeLoad?) {
    if(!firstTimeLoad){
    this.showSpinner = this.loaderService.showProgressBar();
    }
    let responseData = [];
    this.reportData = null;
    this.reportDateToggle = true;
    this.whatsappDeliveryReportControllerV2Service
      .fetchDeliveryReports()
      .subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          responseData = resp;
          if (resp && resp.length) {
            this.handleApiResponse(resp);
          } else {
            if(!firstTimeLoad){
              this.responseHandlerService.returnToastMessage('warning','NO_DATA_AVAILABLE');
            }
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
      * @ngdoc method
      * @name WhatsappStatusComponent#abortAction
      *
      * @methodOf
      * whatsapp.controller:WhatsappStatusComponent
      *
      * @description
      * Description: Close the popup.
      *
      * @param {type} null
      * @return {type} null
  */
  public abortAction() {
    this._matDialog.closeAll();
  }

  public getChannel(profileId: string) {
    this.whatsappProfileConnectionDetailsControllerService
      .fetchProfileMappedChannels({ profileId: profileId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            const response = resp.map(item => ({
              'id': item.id,
              'name': item.channelName
            }));
            this.dropDownValues['channel'] = response;
          } else {
            this.dropDownValues['channel'] = [];
          }
        },
        (err) => {
          this.dropDownValues['channel'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  getUserName() {
    this.platformAdminUtilityControllerService
    .getUserAccountDetails().subscribe((resp) => {
      if (resp) {
        this.userName = resp['userName'];
      }
    },(err) => {
      this.responseHandlerService.returnToastMessage(
        'error',
        err.error.message
      );
    });
  }

  allowedCharactersValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const allowedCharactersRegex = /^[0-9a-zA-Z_.-]*$/;
      const isValid = allowedCharactersRegex.test(control.value);
      return isValid ? null : { invalidCharacters: true };
    };
  }
  
  initializeForm() {
    this.reportNameForm = this.fb.group({
      reportName: ['', [Validators.required, Validators.maxLength(50),this.allowedCharactersValidator()]],
      emailId: ['', [Validators.required,Validators.email]],
    });
  }

  get formControls() {
    return this.reportNameForm.controls;
  }

  downloadReport(){
    setTimeout(() => {
      this.responseHandlerService.returnToastMessage('success','DOWLOAD_OF_YOUR_REPORT_WILL_BEGIN_SHORTLY');
    }, 2000);
  }

}
