import { Component, OnInit, Input, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DELETE_IMAGE, DOWNLOAD_ICON } from '../constants/strings';
import { TranslationService } from 'src/app/providers/translation-service';
import { ConfirmationDialogPopupComponent } from '../confirmation-dialog-popup/confirmation-dialog-popup.component';

@Component({
  selector: 'app-show-history-dailog',
  templateUrl: './app-show-history-dialog.component.html',
  styleUrls: ['./show-history-dialog.component.scss']
})

export class ShowHistoryDailogComponent implements OnInit {
  columnsToDisplay = ['startTime', 'endTime', 'procedure', 'reason', 'status'];
  ignoreList = ['id', 'scheduler', 'proposalId', 'url'];
  dataSource: any;
  columns: any;
  downloadImage;
  deleteImage;
  @Output() deleteItemEmitter: EventEmitter<any> = new EventEmitter();
  constructor(
    private _matDialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any,
    private translationService: TranslationService) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource<any>(this.data.dataKey);
    this.downloadImage = DOWNLOAD_ICON;
    this.deleteImage = DELETE_IMAGE;
    if (this.data.dataKey.length > 0) {
      this.columns = this.filterColumns(Object.keys(this.data.dataKey[0]));
    }
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  public deleteItem(proposalId, id) {
    const item = {
      proposalId: proposalId,
      id: id
    };
    this.deleteItemEmitter.emit(item);
  }

  public gotoDeleteConfirmDialog(proposalId, id) {
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: this.translationService.translateErrorMessages('DELETE_DOCUMENT_MESSAGE') }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteItem(proposalId, id);
      } else {
        this._matDialog.closeAll();
      }
    });
  }

}
