/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PeripheralSupportTypeDto } from '../models/peripheral-support-type-dto';
import { ProposalPeripheralConfigDto } from '../models/proposal-peripheral-config-dto';

@Injectable({
  providedIn: 'root',
})
export class ProposalPeripheralConfigControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllPeripheralServices
   */
  static readonly GetAllPeripheralServicesPath = '/api/v1/peripheralServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPeripheralServices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPeripheralServices$Response(params?: {
  }): Observable<StrictHttpResponse<Array<PeripheralSupportTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalPeripheralConfigControllerService.GetAllPeripheralServicesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PeripheralSupportTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPeripheralServices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPeripheralServices(params?: {
  }): Observable<Array<PeripheralSupportTypeDto>> {

    return this.getAllPeripheralServices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PeripheralSupportTypeDto>>) => r.body as Array<PeripheralSupportTypeDto>)
    );
  }

  /**
   * Path part for operation getAllByProposalId
   */
  static readonly GetAllByProposalIdPath = '/api/v1/proposal/{proposalId}/peripheralConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllByProposalId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllByProposalId$Response(params: {
    proposalId: string;
  }): Observable<StrictHttpResponse<ProposalPeripheralConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalPeripheralConfigControllerService.GetAllByProposalIdPath, 'get');
    if (params) {
      rb.path('proposalId', params.proposalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalPeripheralConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllByProposalId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllByProposalId(params: {
    proposalId: string;
  }): Observable<ProposalPeripheralConfigDto> {

    return this.getAllByProposalId$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalPeripheralConfigDto>) => r.body as ProposalPeripheralConfigDto)
    );
  }

  /**
   * Path part for operation saveProposalPeripheralConfigForApprovedPropsl
   */
  static readonly SaveProposalPeripheralConfigForApprovedPropslPath = '/api/v1/proposal/peripheralConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProposalPeripheralConfigForApprovedPropsl()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalPeripheralConfigForApprovedPropsl$Response(params?: {
    body?: ProposalPeripheralConfigDto
  }): Observable<StrictHttpResponse<ProposalPeripheralConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalPeripheralConfigControllerService.SaveProposalPeripheralConfigForApprovedPropslPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalPeripheralConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveProposalPeripheralConfigForApprovedPropsl$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalPeripheralConfigForApprovedPropsl(params?: {
    body?: ProposalPeripheralConfigDto
  }): Observable<ProposalPeripheralConfigDto> {

    return this.saveProposalPeripheralConfigForApprovedPropsl$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalPeripheralConfigDto>) => r.body as ProposalPeripheralConfigDto)
    );
  }

  /**
   * Path part for operation saveProposalPeripheralConfig
   */
  static readonly SaveProposalPeripheralConfigPath = '/api/v1/proposal/peripheralConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProposalPeripheralConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalPeripheralConfig$Response(params?: {
    body?: ProposalPeripheralConfigDto
  }): Observable<StrictHttpResponse<ProposalPeripheralConfigDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalPeripheralConfigControllerService.SaveProposalPeripheralConfigPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalPeripheralConfigDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveProposalPeripheralConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalPeripheralConfig(params?: {
    body?: ProposalPeripheralConfigDto
  }): Observable<ProposalPeripheralConfigDto> {

    return this.saveProposalPeripheralConfig$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalPeripheralConfigDto>) => r.body as ProposalPeripheralConfigDto)
    );
  }

}
