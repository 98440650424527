/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { InfraMongoOrderDto } from '../models/infra-mongo-order-dto';
import { InfraOrderDto } from '../models/infra-order-dto';
import { PeripheralConsumptionOrderDto } from '../models/peripheral-consumption-order-dto';
import { SaasOrders } from '../models/saas-orders';

@Injectable({
  providedIn: 'root',
})
export class OrderControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllInfrastructureByProfileId
   */
  static readonly GetAllInfrastructureByProfileIdPath = '/api/v1/infraOrders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllInfrastructureByProfileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInfrastructureByProfileId$Response(params: {
    profileId: string;
  }): Observable<StrictHttpResponse<Array<InfraOrderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.GetAllInfrastructureByProfileIdPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InfraOrderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllInfrastructureByProfileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInfrastructureByProfileId(params: {
    profileId: string;
  }): Observable<Array<InfraOrderDto>> {

    return this.getAllInfrastructureByProfileId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InfraOrderDto>>) => r.body as Array<InfraOrderDto>)
    );
  }

  /**
   * Path part for operation getAllPerpheralByProfileId
   */
  static readonly GetAllPerpheralByProfileIdPath = '/api/v1/profile/{profileId}/peripheral';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllPerpheralByProfileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPerpheralByProfileId$Response(params: {
    profileId: string;
    month?: number;
    year?: number;
  }): Observable<StrictHttpResponse<Array<PeripheralConsumptionOrderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.GetAllPerpheralByProfileIdPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('month', params.month, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PeripheralConsumptionOrderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllPerpheralByProfileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllPerpheralByProfileId(params: {
    profileId: string;
    month?: number;
    year?: number;
  }): Observable<Array<PeripheralConsumptionOrderDto>> {

    return this.getAllPerpheralByProfileId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PeripheralConsumptionOrderDto>>) => r.body as Array<PeripheralConsumptionOrderDto>)
    );
  }

  /**
   * Path part for operation savePeripheralConsumptionMetaData1
   */
  static readonly SavePeripheralConsumptionMetaData1Path = '/api/v1/profile/{profileId}/peripheral';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePeripheralConsumptionMetaData1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePeripheralConsumptionMetaData1$Response(params: {
    profileId: string;
    body?: Array<PeripheralConsumptionOrderDto>
  }): Observable<StrictHttpResponse<Array<PeripheralConsumptionOrderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.SavePeripheralConsumptionMetaData1Path, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PeripheralConsumptionOrderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePeripheralConsumptionMetaData1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePeripheralConsumptionMetaData1(params: {
    profileId: string;
    body?: Array<PeripheralConsumptionOrderDto>
  }): Observable<Array<PeripheralConsumptionOrderDto>> {

    return this.savePeripheralConsumptionMetaData1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PeripheralConsumptionOrderDto>>) => r.body as Array<PeripheralConsumptionOrderDto>)
    );
  }

  /**
   * Path part for operation savePeripheralConsumptionMetaData
   */
  static readonly SavePeripheralConsumptionMetaDataPath = '/api/v1/proposal/{proposalId}/order';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePeripheralConsumptionMetaData()` instead.
   *
   * This method doesn't expect any request body.
   */
  savePeripheralConsumptionMetaData$Response(params: {
    proposalId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.SavePeripheralConsumptionMetaDataPath, 'post');
    if (params) {
      rb.path('proposalId', params.proposalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePeripheralConsumptionMetaData$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  savePeripheralConsumptionMetaData(params: {
    proposalId: string;
  }): Observable<string> {

    return this.savePeripheralConsumptionMetaData$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getSaasOrderListByProfileId
   */
  static readonly GetSaasOrderListByProfileIdPath = '/api/v1/profile/{profileId}/saasServices';

  /**
   * Manage Saas Order  List.
   *
   * The API to view the SaaS order list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSaasOrderListByProfileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSaasOrderListByProfileId$Response(params: {
    profileId: string;
    month?: number;
    year?: number;
  }): Observable<StrictHttpResponse<Array<SaasOrders>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.GetSaasOrderListByProfileIdPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('month', params.month, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SaasOrders>>;
      })
    );
  }

  /**
   * Manage Saas Order  List.
   *
   * The API to view the SaaS order list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSaasOrderListByProfileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSaasOrderListByProfileId(params: {
    profileId: string;
    month?: number;
    year?: number;
  }): Observable<Array<SaasOrders>> {

    return this.getSaasOrderListByProfileId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SaasOrders>>) => r.body as Array<SaasOrders>)
    );
  }

  /**
   * Path part for operation savePeripheralConsumptionData
   */
  static readonly SavePeripheralConsumptionDataPath = '/api/v1/profile/{profileId}/peripheralConsumptionData';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `savePeripheralConsumptionData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePeripheralConsumptionData$Response(params: {
    profileId: string;
    body?: Array<PeripheralConsumptionOrderDto>
  }): Observable<StrictHttpResponse<Array<PeripheralConsumptionOrderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.SavePeripheralConsumptionDataPath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<PeripheralConsumptionOrderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `savePeripheralConsumptionData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  savePeripheralConsumptionData(params: {
    profileId: string;
    body?: Array<PeripheralConsumptionOrderDto>
  }): Observable<Array<PeripheralConsumptionOrderDto>> {

    return this.savePeripheralConsumptionData$Response(params).pipe(
      map((r: StrictHttpResponse<Array<PeripheralConsumptionOrderDto>>) => r.body as Array<PeripheralConsumptionOrderDto>)
    );
  }


  /**
   * Path part for operation getAllInfrastructureMongoByProfileId
   */
  static readonly GetAllInfrastructureMongoByProfileIdPath = '/api/v1/mongo/infraOrders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllInfrastructureMongoByProfileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInfrastructureMongoByProfileId$Response(params: {
    profileId: string;
  }): Observable<StrictHttpResponse<Array<InfraMongoOrderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, OrderControllerService.GetAllInfrastructureMongoByProfileIdPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InfraMongoOrderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllInfrastructureMongoByProfileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllInfrastructureMongoByProfileId(params: {
    profileId: string;
  }): Observable<Array<InfraMongoOrderDto>> {

    return this.getAllInfrastructureMongoByProfileId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InfraMongoOrderDto>>) => r.body as Array<InfraMongoOrderDto>)
    );
  }

  
}
