import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import {MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { HttpClient } from '@angular/common/http';
import {
  DeviceStatsControllerService
} from 'src/app/services/DeviceMgmt/services';
import { DeviceSearchDto, CommonId } from 'src/app/services/DeviceMgmt/models';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicSearchServiceService } from 'src/app/providers/dynamic-search-service.service';
import { SelectionModel } from '@angular/cdk/collections';
import {
   INWARD_DISPLAY_ICON, OUTWARD_DISPLAY_ICON,
   USER_DASHBOARD_ICON, DEV_STATS_MENU_ICON,
} from 'src/app/shared/constants/strings';
import { TranslationService } from 'src/app/providers/translation-service';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TagsServiceProvider } from 'src/app/providers/tags-service';
import { DatePipe } from '@angular/common';
import { AreaChartService } from 'src/app/providers/area-spline-chart-service';
import { LineChartService } from 'src/app/providers/line-chart-service';
import { BatteryLineChartService } from 'src/app/providers/battery-line-chart-service';
import { TranslateService } from '@ngx-translate/core';

import { DashBoardControllerService } from 'src/app/services/Report/services/dash-board-controller.service';
import { CygniDeviceMetricsControllerService } from 'src/app/services/data-analytics/services/cygni-device-metrics-controller.service';
@Component({
  selector: 'app-device-statistics',
  templateUrl: './device-statistics.component.html',
  styleUrls: ['./device-statistics.component.scss']
})
export class DeviceStatisticsComponent implements OnInit {
  public matTabOptions = [];
  public showSpinner = false;
  public deviceData;
  public columns: any[];
  public defaultColumns: any[];
  public dropDownValues: any = {};
  ignoreList = [
    'id',
    'links',
    'account',
    'manufacturer',
    'devicePropertySupport',
    'deviceInterfaceProvider',
  ];
  asideMenus: Array<AsideCard>;
  public pageKey: string;
  public formData: any;
  public data;
  public deviceCount = 0;
  public currentTabValue;
  public permissionList: Array<String> = [];
  selection = new SelectionModel<CommonId>(true, []);
  public mode: string;
  configureImage: string;
  rebootImage: string;
  deleteImage: string;
  restartImage: string;
  fotaImage: string;
  connectedImage: string;
  disconnectedImage: string;
  registeredImage: string;
  boschIotIcon: string;
  azureIcon: string;
  awsIcon: string;
  zeliotIcon: string;
  thumbUpImage: string;
  thumbDownImage: string;
  moreOptionsImage: string;
  tenantId: any;
  solutionTypeId: any;
  deviceTypeId: any;
  fotaDetails = {};
  hideFotaBtn = true;
  tabToBeLoaded;
  index = 0;
  selectionCriteria;
  selectedTab;
  selectAllDevices = false;
  public deviceIcon: string;
  public selectedTag: any;
  public jsonFileLoaded;
  public tagsArray = [];
  public deviceList = [];
  public availableTags = [];
  public searchCriteria;
  public deviceInterfaceProviderId: string;
  public profileId: string;
  public deviceTunnelIcon: string;
  deviceChart;
  transactionChart;
  devicetData = [];
  subscriptionData = [];
  todayDate = new Date();
  accountIcon;
  tenantIcon;
  profileIcon;
  backLeftImage;
  selectedAssetsOptions = [];
  selectedTransactionsOptions = [];
  subscriptionArrayList;
  assetArrayList;
  defaultDateRange;
  cardsList;
  cols;
  dateRangeList = [];
  strechGraphIcon;
  shrinkGraphIcon;
  chartName: string;
  cardDetails: any;
  generalCardDetails: any;
  networkCardDetails: any;
  graphResponse: any;
  selectedInterval: any;
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('cpuUsageCharts') public cpuUsageChartElement: ElementRef;
  @ViewChild('memoryUsageCharts') public memoryUsageChartElement: ElementRef;
  @ViewChild('freeDiskUsageCharts') public freeDiskUsageChartElement: ElementRef;
  @ViewChild('netInUsageCharts') public netInUsageChartElement: ElementRef;
  @ViewChild('netOutUsageCharts') public netOutUsageChartElement: ElementRef;
  @ViewChild('deviceGraph') public deviceGraph: ElementRef;
  /* below 3 lines of code are meant for cygni-customer demo purpose only, once demo is done, code will be removed */
  @ViewChild('batteryDataChart') public batteryDataChart: ElementRef;
  @ViewChild('voltageChart') public voltageChart: ElementRef;
  @ViewChild('socChart') public socChart: ElementRef;
  selectedDevices: any;
  public serialNo: string;
  constructor(
    private route: ActivatedRoute,
    private responseHandlerService: ResponseHandlerService,
    private _matDialog: MatDialog,
    private deviceStatsControllerService: DeviceStatsControllerService,
     private translate: TranslateService,
    private datePipe: DatePipe,
    private lineChartService: LineChartService,
    /* below 1 lines of code are meant for cygni-customer demo purpose only, once demo is done, code will be removed */
    private batteryLineChartService: BatteryLineChartService,
    private cygniDeviceMetricsControllerService: CygniDeviceMetricsControllerService
  ) { }

  ngOnInit() {
   this.getIntervals();
    this.serialNo = this.route.snapshot.paramMap.get('serialNo');
    this.deviceIcon = DEV_STATS_MENU_ICON;
    this.strechGraphIcon = OUTWARD_DISPLAY_ICON;
    this.shrinkGraphIcon = INWARD_DISPLAY_ICON;
    this.matTabOptions.push({ label: 'System Activity', value: 'systemActivity' });
    this.matTabOptions.push({ label: 'Device Information', value: 'deviceInfo' });
    // this.matTabOptions.push({ label: 'System Logs', value: 'sysLogs' });
    // this.matTabOptions.push({ label: 'Battery Data', value: 'batteryData' });
    if (this.permissionList.indexOf('DEV_TUNN_CLL') > -1) {
      this.matTabOptions.push({ label: 'System Activity', value: 'systemActivity' });
    if (this.permissionList.indexOf('DEV_TUNN_CLL') > -1) {
      this.matTabOptions.push({ label: 'Device Information', value: 'deviceInfo' });
    }
    /* below 3 lines of code are meant for cygni-customer demo purpose only, once demo is done, code will be removed */
    if (this.permissionList.indexOf('DEV_TUNN_CLL') > -1) {
      this.matTabOptions.push({ label: 'Battery Data', value: 'batteryData' });
    }
  }
  this.currentTabValue = this.matTabOptions[0].value;
  this.permissionList = this.route.snapshot.data.data['permissionList'];
  this.todayDate.setDate(this.todayDate.getDate() - 1);
  this.getDeviceSummary(this.defaultDateRange, true);
  }


  public matChange(event) {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.data = null;
    this.columns = null;
    this.currentTabValue = this.matTabOptions[event.index].value;
    this.selection.clear();
    if (event.index === 0 && this.currentTabValue === 'systemActivity') {
      this.getDeviceSummary(this.defaultDateRange, true);
    } else if (event.index === 1 && this.currentTabValue === 'deviceInfo') {
      this.graphResponse = null;
      this.getDeviceDetails();
    } else if (event.index === 2 && this.currentTabValue === 'sysLogs') {
    }
    /* below 3 lines of code are meant for cygni-customer demo purpose only, once demo is done, code will be removed */
    else if (event.index === 2 && this.currentTabValue === 'batteryData') {
      this.getDeviceSummary(this.defaultDateRange, true);
      this.deriveBatteryData();
    }
  }


  public getDeviceSummary(intervalId, active) {
    this.lineChartService.destroyChart(this.deviceChart);
    this.deviceData = [];
    this.defaultDateRange = intervalId;
    let cpuChart;
    let memoryChart; let freeDiskChart;
    let networkInChart; let networkOutChart; const timeArr = [];
    const summaryData = [];
    const summaryObj = {
      'endDate': this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      'intervalId': intervalId,
      'active': active
    };
    const dateArr = [];
    const cpuResp={};
    const memoryResp = {};
    const freeDiskResp = {};
    const networkInResp = {};
    const networkOutResp= {};
    
    this.deviceStatsControllerService.getDeviceStats({ deviceId: this.serialNo }).subscribe(resp => {
      resp.dates.forEach((date) => {
        const dateTime = date.split(' ');
        dateArr.push (dateTime[0]);
        timeArr.push(dateTime[1]);
      });
      resp['dates'] = dateArr;
      this.graphResponse = resp;
      if (this.graphResponse['cpu'] && this.graphResponse['cpu'].length) {
        cpuResp['dates'] = resp['dates'];
        cpuResp['color'] = '#d4507a';
        cpuResp['icon'] = USER_DASHBOARD_ICON;
        cpuResp['name'] = 'CPU';
        cpuResp['values'] = resp['cpu'];
        cpuResp['count'] = resp['cpu'].length;
        cpuResp['time'] = timeArr;
        summaryData.push(cpuResp);
        cpuChart = this.drawChart(this.cpuUsageChartElement, cpuChart, cpuResp, this.deviceData, 'CPU');
      } 
      if (this.graphResponse['memory'] && this.graphResponse['memory'].length) {
        memoryResp['dates'] = resp['dates'];
        memoryResp['color'] = '#1bc943';
        memoryResp['icon'] = USER_DASHBOARD_ICON;
        memoryResp['name'] = 'Memory';
        memoryResp['values'] = resp['memory'];
        memoryResp['count'] = resp['memory'].length;
        memoryResp['time'] = timeArr;
        summaryData.push(memoryResp);
        memoryChart = this.drawChart(this.memoryUsageChartElement, memoryChart, memoryResp, this.deviceData, 'memory');
      }
      if (this.graphResponse['freeDiskSpace'] && this.graphResponse['freeDiskSpace'].length) {
        freeDiskResp['dates'] = resp['dates'];
        freeDiskResp['color'] = '#feb019';
        freeDiskResp['icon'] = USER_DASHBOARD_ICON;
        freeDiskResp['name'] = 'Free Disk Space';
        freeDiskResp['values'] = resp['freeDiskSpace'];
        freeDiskResp['count'] = resp['freeDiskSpace'].length;
        freeDiskResp['time'] = timeArr;
        summaryData.push(freeDiskResp);
        freeDiskChart = this.drawChart(this.freeDiskUsageChartElement, freeDiskChart, freeDiskResp, this.deviceData, 'memory');
      }
      if (this.graphResponse['networkIn'] && this.graphResponse['networkIn'].length) {
        networkInResp['dates'] = resp['dates'];
        networkInResp['color'] = '#00629e';
        networkInResp['icon'] = USER_DASHBOARD_ICON;
        networkInResp['name'] = 'Network-In';
        networkInResp['values'] = resp['networkIn'];
        networkInResp['count'] = resp['networkIn'].length;
        networkInResp['time'] = timeArr;
        summaryData.push(networkInResp);
        networkInChart = this.drawChart(this.netInUsageChartElement, networkInChart, networkInResp, this.deviceData, 'memory');
      }
      if (this.graphResponse['networkOut'] && this.graphResponse['networkOut'].length) {
        networkOutResp['dates'] = resp['dates'];
        networkOutResp['color'] = '#4191FF';
        networkOutResp['icon'] = USER_DASHBOARD_ICON;
        networkOutResp['name'] = 'Network-Out';
        networkOutResp['values'] = resp['networkOut'];
        networkOutResp['count'] = resp['networkOut'].length;
        networkOutResp['time'] = timeArr;
        summaryData.push(networkOutResp);
        networkOutChart = this.drawChart(this.netOutUsageChartElement, networkOutChart, networkOutResp, this.deviceData, 'memory');
      }
     
    }, err => {
    });
  }

  public drawChart(chartElement, chart, summaryData, data, chartType) {
    if (chart) {
      this.lineChartService.redrawChart(chart, summaryData);
    } else {
      chart = this.lineChartService.createChart(chartElement.nativeElement, [summaryData]);
      if (chartType === 'transaction') {
        this.transactionChart = chart;
      } else if (chartType === 'asset') {
        this.deviceChart = chart;
      }
    }
    data.push(summaryData);
    return chart;
  }
  
  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }


  public getDeviceDetails() {
      this.deviceStatsControllerService.getDeviceParameter({ deviceId: this.serialNo }).subscribe(
      resp => {
        if (resp) {
          this.cardDetails = resp; 
          this.generalCardDetails = resp.general;
          this.networkCardDetails = resp.network;
        }
      },
      err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }
 
  public displayDetailedGraph(content, chartName, color) {
    if (chartName === 'cpu') {
      this.chartName = 'CPU_USG';
    } else if (chartName === 'memory') {
      this.chartName = 'MEMORY_USG';
    } else if (chartName === 'freeDiskSpace') {
      this.chartName = 'FREE_DISK_USG';
    } else if (chartName === 'networkIn') {
      this.chartName = 'NETWORK_IN_USG';
    } else if (chartName === 'networkOut') {
      this.chartName = 'NETWORK_OUT_USG';
    }
    this.deviceData = [];
    let chart;
    const summaryData = [];
    const chartResp={};
    const dateArr = [];
    const timeArr = [];
    this.deviceStatsControllerService.getDeviceStats({ deviceId: this.serialNo }).subscribe(resp => {
      resp.dates.forEach((date) => {
        const dateTime = date.split(' ');
        dateArr.push (dateTime[0]);
        timeArr.push(dateTime[1]);
      });
      resp['dates'] = dateArr;
      if (resp[chartName] && resp[chartName].length) {
        chartResp['dates'] = resp['dates'];
        chartResp['color'] = color;
        chartResp['icon'] = USER_DASHBOARD_ICON;
        chartResp['name'] = chartName;
        chartResp['values'] = resp[chartName];
        chartResp['count'] = resp[chartName].length;
        chartResp['time'] = timeArr;
        summaryData.push(chartResp);
        chart = this.drawChart(this.deviceGraph, chart, chartResp, this.deviceData, chartName);
      } }, err => {
      });

      this._matDialog.open(content, { maxHeight: '500vh', position: {
        top: '100px',
        left: '250px'
      },
      minWidth: '700vw', disableClose: true, data: null,
      });
      
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  public getIntervals() {
    this.dropDownValues['intervals'] = [
      {
        'id': 24,
        'name': 'TWENTY_FOUR_HOURS',
      },
      {
        'id': 12,
        'name': 'TWELVE_HOURS',
      },
      {
        'id': 6,
        'name': 'SIX_HOURS',
      },
      {
        'id': 3,
        'name': 'THREE_HOURS',
      },
      {
        'id': 1,
        'name': 'LAST_HOUR',
      }
    ];
  }

 /* below method meant for cygni-customer demo purpose only, once demo is done, code will be removed */
  public deriveBatteryData() {
    this.deviceData = [];
    let chart;
    const summaryData = [];
    const chartResp = {};
    const voltResp = {};
    const socResp = {};
    let VoltChart;
    let socChart;
    let batteryDataChart;
    const dateArr = [];
    const timeArr = [];
    this.cygniDeviceMetricsControllerService.getBatteryStats({deviceId: this.serialNo, body:{'params':['default']}}).subscribe(resp => {
      resp.dates.forEach((date) => {
        const dateTime = date.split(' ');
        dateArr.push (dateTime[0]);
        timeArr.push(dateTime[1]);
      });
      resp['dates'] = dateArr;
      if (resp['curr'] && resp['curr'].length) {
        chartResp['dates'] = resp['dates'];
        chartResp['color'] = '#d4507a';
        chartResp['icon'] = USER_DASHBOARD_ICON;
        chartResp['name'] = 'Time(Hr:min)';
        chartResp['values'] = resp['curr'];
        chartResp['count'] = resp['curr'].length;
        chartResp['time'] = timeArr;
        summaryData.push(chartResp);
        batteryDataChart = this.drawBatteryChart(this.batteryDataChart, batteryDataChart, chartResp, this.deviceData, 'Current');
        this.batteryLineChartService.defaultOptions.yAxis.title.text =  'Current(A)';
        
      } 
      if (resp['volt'] && resp['volt'].length) {
        voltResp['dates'] = resp['dates'];
        voltResp['color'] = '#00629e';
        voltResp['icon'] = USER_DASHBOARD_ICON;
        voltResp['name'] = 'Time(Hr:min)';
        voltResp['values'] = resp['volt'];
        voltResp['count'] = resp['volt'].length;
        voltResp['time'] = timeArr;
        summaryData.push(voltResp);
        VoltChart = this.drawBatteryChart(this.voltageChart, VoltChart, voltResp, this.deviceData, 'Voltage');
        this.batteryLineChartService.defaultOptions.yAxis.title.text =  'Voltage(V)';
      } 
      if (resp['soc'] && resp['soc'].length) {
        socResp['dates'] = resp['dates'];
        socResp['color'] = '#1bc943';
        socResp['icon'] = USER_DASHBOARD_ICON;
        socResp['name'] = 'Time(Hr:min)';
        socResp['values'] = resp['soc'];
        socResp['count'] = resp['soc'].length;
        socResp['time'] = timeArr;
        summaryData.push(socResp);
        socChart = this.drawBatteryChart(this.socChart, socChart, socResp, this.deviceData, 'SoC');
        this.batteryLineChartService.defaultOptions.yAxis.title.text = 'SoC(%)';
      }
    }, err => {
      });
  }

 /* below method of code are meant for cygni-customer demo purpose only, once demo is done, code will be removed */
  public drawBatteryChart(chartElement, chart, summaryData, data, chartType) {
    if (chart) {
      this.batteryLineChartService.redrawChart(chart, summaryData);
    } else {
      chart = this.batteryLineChartService.createChart(chartElement.nativeElement, [summaryData]);
      if (chartType === 'transaction') {
        this.transactionChart = chart;
      } else if (chartType === 'asset') {
        this.deviceChart = chart;
      }
    }
    data.push(summaryData);
    return chart;
  }
}


