import { Component, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import {
  AccountControllerService,
  BusinessProfileControllerService,
  TenantControllerService,
} from 'src/app/services/Platform/services';
import {
  SIM_REGISTRATION_ICON,
  VENDOR_AIRTEL,
  DOWNLOAD_SAMPLE_FILE_NO_LINK, SIM_VENDOR, SIM_PROVIDER
} from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SimControllerService } from 'src/app/services/SimMgmt/services/sim-controller.service';
import { SimMgmtControllerService } from 'src/app/services/SimMgmt/services/sim-mgmt-controller.service';
import { VendorControllerService } from 'src/app/services/SimMgmt/services/vendor-controller.service';
import { TranslationService } from 'src/app/providers/translation-service';
import { UtilityControllerService } from 'src/app/services/SimMgmt/services/utility-controller.service';
import { BciLoaderService } from '@bci-web-core/core';
import {
  DeviceEnrollmentControllerService, DeviceTypeControllerService, DeviceMgmtUtilityControllerService
} from 'src/app/services/DeviceMgmt/services';
import { SimProviderControllerService } from 'src/app/services/SimMgmt/services/sim-provider-controller.service';


@Component({
  selector: 'app-sim-registration',
  templateUrl: './sim-registration.component.html',
  styleUrls: ['./sim-registration.component.scss']
})
export class SimRegistrationComponent implements OnInit {
  public jsonFileLoaded;
  public showSpinner :any;
  public pageKey: string;
  public dropDownValues: any = {};
  asideMenus: Array<AsideCard>;
  public permissionList: Array<String> = [];
  public registerSimIcon;
  public matTabOptions = [];
  public currentTabValue;
  public formData: any = {};
  public data;
  public index: number;
  public selectedVendor: string;
  public businessProfileId: string;
  public simProfiles: any;
  public simReferenceId: any;
  public simData;
  public registrationType: any;
  public parentFieldName: any;
  public simRegistrationId: any;
  public activeTabIndex:number =0;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  constructor(private businessProfileControllerService: BusinessProfileControllerService,
    private tenantControllerService: TenantControllerService, private accountControllerService: AccountControllerService,
    private responseHandlerService: ResponseHandlerService, private objectToIdConversionService: ObjectToIdConversionService,
    private route: ActivatedRoute, private router: Router, private translate: TranslationService, 
    private simManagementControllerService: SimControllerService,
    private vendorControllerService: VendorControllerService, private _snackBar: MatSnackBar,
    private loaderService: BciLoaderService, private simProviderControllerService: SimProviderControllerService,
    private utilityControllerService: UtilityControllerService) { }

  ngOnInit() {
    this.registerSimIcon = SIM_REGISTRATION_ICON;
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.simReferenceId = this.route.snapshot.paramMap.get('simReferenceId');
    this.simRegistrationId = this.route.snapshot.paramMap.get('simRegistrationId');
    if (this.route.snapshot.paramMap.get('jsonFileLoaded') === 'sim-individual-registration') {
      this.registrationType = SIM_VENDOR;
      if (this.permissionList.indexOf('SIM_C') > -1) {
        this.matTabOptions.push({
          label: 'Individual Registration',
          value: 'individual',
        });
        this.matTabOptions.push({ label: 'Bulk Registration', value: 'bulk' });
        this.currentTabValue = 'individual';
      }
    } else {
      this.registrationType = SIM_PROVIDER;
      if (this.permissionList.indexOf('SIM_C') > -1) {
        this.matTabOptions.push({
          label: 'Individual Registration',
          value: 'individual',
        });
        this.matTabOptions.push({ label: 'Bulk Registration', value: 'bulk' });
        this.currentTabValue = 'individual';
      }
    }
    
    this.jsonFileLoaded = this.route.snapshot.paramMap.get('jsonFileLoaded');
    this.getAccount();
      if (this.simReferenceId && this.jsonFileLoaded === 'sim-individual-registration') {
        this.getSimDetails(this.simReferenceId);
      } else if (this.simRegistrationId && this.jsonFileLoaded === 'sim-individual-registration-no-vendor') {
        this.getProviderSimDetails(this.simRegistrationId);
        this.getSimProfilesBySimProvider(this.route.snapshot.paramMap.get('simProviderId'));
      }
  }
  /**
   * @ngdoc method
   * @name simRegistration#matChange
   *
   * @methodOf
   *sim.controller:simRegistration
   *
   * @description
   * Description: To change tabs based on selection.
   *
   * @param {type} $event $event
   * @return {type} null
   */
  public matChange(event) {
    this.data = null;
    this.currentTabValue = this.matTabOptions[event.index].value;
    if (this.registrationType === SIM_VENDOR) {
      if (this.currentTabValue === 'individual') {
        this.changeTabs('sim-individual-registration', 'individual');
      } else if (this.currentTabValue === 'bulk') {
        this.changeTabs('sim-bulk-registration', 'bulk');
      }
    } else if (this.registrationType === SIM_PROVIDER) {
      if (this.currentTabValue === 'individual') {
        this.changeTabs('sim-individual-registration-no-vendor', 'individual');
      } else if (this.currentTabValue === 'bulk') {
        this.changeTabs('sim-bulk-registration-no-vendor', 'bulk');
      }
    }
   
  }
  /**
   * @ngdoc method
   * @name simRegistration#jsonFileTobeLoaded
   *
   * @methodOf
   *sim.controller:simRegistration
   *
   * @description
   * Description: Helper method to change tabs based on selection.
   *
   * @param {type} $event $event
   * @return {type} null
   */
  public changeTabs(jsonFileTobeLoaded, tabName) {
    this.dynamicForm.submitted = false;
    this.dynamicForm.files = [];
    this.currentTabValue = tabName;
    this.jsonFileLoaded = jsonFileTobeLoaded;
    this.dynamicForm.jsonFile = jsonFileTobeLoaded;
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['solutionType'] = [];
    this.dropDownValues['vendor'] = [];
    this.dropDownValues['primaryProfile'] = [];
    this.dropDownValues['secondryProfile'] = [];
    this.dynamicForm.createForm.enable();
    this.dynamicForm.createForm.reset();
    this.dynamicForm.ngOnInit();
    this.getAccount();
    if (this.formData) {
      this.formData = null;
    }
    this.dynamicForm.sampleFileDownloadApi = '';
  
  }

  /**
   * @ngdoc method
   * @name simRegistration#updateDropdownValues
   *
   * @methodOf
   *sim.controller:simRegistration
   *
   * @description
   * Description: To populate dependent dropdown based on selected value.
   *
   * @param {type} $event $event
   * @return {type} null
   */
  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child === 'tenant') {
        this.getTenant($event.value);
      }
      if ($event.child === 'businessProfile') {
        this.getProfile($event.value);
      }
      if ($event.child === 'solutionType') {
        this.businessProfileId = $event.value;
        this.getSolutionType($event.value);
      }
      if ($event.child === 'simProvider') {
         this.getAllSimProviders();
         this.parentFieldName = $event.child;
      }
      if ($event.child === 'vendor') {
        this.getVendorsList(this.businessProfileId, $event.value);
      }
      if ($event.child === 'sampleFile') {
        this.dynamicForm.sampleFileDownloadApi = 'DOWNLOAD_SAMPLE_FILE_NO_LINK';
      }
      if ($event.child === 'primaryProfile') {
        
          if (this.currentTabValue === 'individual' && this.parentFieldName !== 'simProvider') {
            this.selectedVendor = $event.value;
            if ($event.value['name'] === VENDOR_AIRTEL && this.dynamicForm) {
              this.dynamicForm.formBase.forEach((control) => {
                if (control.name === 'mobileNo') {
                  control['required'] = true;
                }
              });
              this.dynamicForm.createForm.controls[
                'mobileNo'
              ].updateValueAndValidity();
            } else if (
              $event.value['name'] === !VENDOR_AIRTEL &&
              this.dynamicForm
            ) {
              this.dynamicForm.formBase.forEach((control) => {
                if (control.name === 'mobileNo') {
                  control['required'] = false;
                }
              });
              this.dynamicForm.createForm.controls[
                'mobileNo'
              ].updateValueAndValidity();
            }
          }
          if (this.parentFieldName !== 'simProvider') {
          this.getSimProfilesByVendorName($event.value['name']);
        } else if (this.parentFieldName === 'simProvider') {
            this.getSimProfilesBySimProvider($event.value['id']);
        }
        
      }
    }
    if ($event === 'downloadSampleFile') {
      if (this.registrationType === SIM_VENDOR) {
        this.downloadSampleFile({source: 'bulkSimVendor'});
      } else if(this.registrationType === SIM_PROVIDER) {
        this.downloadSampleFile({source: 'bulkSimProvider'});
      }
      
    }
  }

  /**
   * @ngdoc method
   * @name simRegistration#getAccount
   *
   * @methodOf
   *sim.controller:simRegistration
   *
   * @description
   * Description: To populate account dropdown.
   *
   * @param {type} null
   * @return {type} null
   */
  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  /**
   * @ngdoc method
   * @name simRegistration#getTenant
   *
   * @methodOf
   *sim.controller:simRegistration
   *
   * @description
   * Description: To populate tenant dropdown.
   *
   * @param {type} accountId
   * @return {type} null
   */
  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  /**
   * @ngdoc method
   * @name simRegistration#getProfile
   *
   * @methodOf
   *sim.controller:simRegistration
   *
   * @description
   * Description: To populate business profiles dropdown.
   *
   * @param {type} tenantId
   * @return {type} null
   */
  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  /**
   * @ngdoc method
   * @name simRegistration#getSolutionType
   *
   * @methodOf
   *sim.controller:simRegistration
   *
   * @description
   * Description: To populate solution type dropdown.
   *
   * @param {type} profileId
   * @return {type} null
   */
  public getSolutionType(profileId: string) {
    const solutionTypeActiveArray = [];
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({
        businessProfileId: profileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach((element) => {
              if (element['active'] === true) {
                solutionTypeActiveArray.push(element);
              }
            });
            this.dropDownValues['solutionType'] = solutionTypeActiveArray;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name simRegistration#getSolutionType
   *
   * @methodOf
   *sim.controller:simRegistration
   *
   * @description
   * Description: To populate solution type dropdown.
   *
   * @param {type} profileId
   * @return {type} null
   */
  public getVendorsList(businessProfileId: string, solutionTypeId: string) {
    this.vendorControllerService
      .getSimVendorsByProfileAndSolutionType({
        businessProfileId: businessProfileId,
        solutionTypeId: solutionTypeId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['vendor'] = resp;
          } else {
            this.dropDownValues['vendor'] = [];
          }
        },
        (err) => {
          this.dropDownValues['vendor'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public manageProfileCount($event) {
    if (
      this.dynamicForm &&
      this.dynamicForm.createForm.controls['profileCount'].value === false
    ) {
      if (this.dynamicForm.formBase) {
        const index = this.dynamicForm.formBase.indexOf('secondaryProfile');
        if (index) {
          this.dynamicForm.formBase.splice(index, 1);
        }
      }
      this.dynamicForm.createForm.removeControl('secondaryProfile');
      this.dynamicForm.createForm.updateValueAndValidity();
    } else if (
      this.dynamicForm &&
      this.dynamicForm.createForm.controls['profileCount'].value === true
    ) {
      if (this.simProfiles && this.simProfiles.length) {
        this.dropDownValues['secondaryProfile'] = this.simProfiles;
      }
    }
  }
  public getSimProfilesByVendorName(vendorName: string) {
    this.vendorControllerService
      .getProfilesByVendorName({ vendorName: vendorName })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['primaryProfile'] = resp;
            this.simProfiles = resp;
            if (this.simReferenceId && this.formData) {
              this.dropDownValues['secondaryProfile'] = resp;
            }
          } else {
            this.dropDownValues['primaryProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['primaryProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public registerSim(data: any) {

    /* if ((!data['iccId'] && !data['file']) || !data['simPrice'] && !data['file']) {
       this.responseHandlerService.returnToastMessage(
         'error',
         'UPLOAD_FILE_REQUIRED'
       );
       return null;
     } */

  if(this.matTabOptions[this.activeTabIndex].value=='bulk' && !data['file']){
    this.responseHandlerService.returnToastMessage(
       'error',
        'UPLOAD_FILE_REQUIRED'
       );
      return null;
  }
    this.showSpinner = this.loaderService.showProgressBar();
    const SimDetailsDto = {};
    if (data) {
      if (this.registrationType === SIM_PROVIDER) {
        SimDetailsDto['simProviderId'] = data['simProvider'];
        SimDetailsDto['simPrice'] = data['simPrice'];
        SimDetailsDto['solutionTypeId'] = data['solutionType'];
      } 
      if (this.registrationType === SIM_VENDOR) {
        SimDetailsDto['customerVendorId'] = data['vendor'];
      }
      SimDetailsDto['bprefId'] = data['businessProfile'];
      SimDetailsDto['solutionTypeId'] = data['solutionType'];
      SimDetailsDto['mobileNo'] = data['mobileNo'] && data['mobileNo'];
      SimDetailsDto['iccid'] = data['iccId'] && data['iccId'];
      if (!data['secondaryProfile']) {
        SimDetailsDto['profileType'] = 'SINGLE';
        SimDetailsDto['profiles'] = {
           primary: this.objectToIdConversionService.convertObjectToId(
            data['primaryProfile']
          ),
        };
      } else {
        SimDetailsDto['profileType'] = 'DUAL';
        SimDetailsDto['profiles'] = {
          primary: this.objectToIdConversionService.convertObjectToId(
            data['primaryProfile']
          ),
          secondary: this.objectToIdConversionService.convertObjectToId(
            data['secondaryProfile']
          ),
        };
      }
    }
    if (this.registrationType === SIM_PROVIDER && !this.simRegistrationId && data['simPrice']) {
      this.simProviderControllerService.registerSim({body: SimDetailsDto}).subscribe(
        (resp) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'SIM_CREATE_SUCCESS'
          );
          this.dynamicForm.createForm.reset();
          this.resetForm('resetForm');
          this.dynamicForm.jsonFile = 'sim-individual-registration-no-vendor';
          this.dynamicForm.ngOnInit();
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }
    if (this.simReferenceId && data['vendor']) {
      this.simManagementControllerService
        .updateSim1({ simRefId: this.simReferenceId, body: SimDetailsDto })
        .subscribe(
          (resp) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'success',
              'SIM_UPDATE_SUCCESS'
            );
            this.router.navigate(['sim/manage-sim']);
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    } else if (this.registrationType === SIM_VENDOR && !SimDetailsDto['iccid']) {
      const metaData = {
        bprefId: SimDetailsDto['bprefId'],
        customerVendorId: SimDetailsDto['customerVendorId'],
        profileType: SimDetailsDto['profileType'],
        profiles: SimDetailsDto['profiles'],
        solutionTypeId: SimDetailsDto['solutionTypeId']
      };
      const reqObj = {
        metaData: JSON.stringify(metaData),
        file: data['file'],
      };
      this.simManagementControllerService
        .bulkRegisterSim({ body: reqObj })
        .subscribe(
          (resp) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'success',
              'SIM_CREATE_SUCCESS'
            );
            this.router.navigate(['sim/manage-sim']);
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            if (err.status === 201 && err.error.text === 'SUCCESS') {
              this.responseHandlerService.returnToastMessage(
                'success',
                'SIM_CREATE_SUCCESS'
              );
              this.router.navigate(['sim/manage-sim']);
            } else if (err.status === 207) {
              this._snackBar
                .open(
                  this.translate.translateErrorMessages(
                    'BULK_REGISTER_PARTIAL_SUCCESS_MSG'
                  ),
                  'DOWNLOAD',
                  {
                    verticalPosition: 'top',
                    panelClass: ['warning'],
                  }
                )
                .onAction()
                .subscribe(() => {
                  this.downloadSimRegDetails(err.error.text);
                });
            } else {
              this.responseHandlerService.returnToastMessage(
                'error',
                err.error.message
              );
            }
          }
        );
    } else if (this.registrationType === SIM_VENDOR && data['vendor']) {
      this.simManagementControllerService
        .registerSim1({ body: SimDetailsDto })
        .subscribe(
          (resp) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'success',
              'SIM_CREATE_SUCCESS'
            );
            this.dynamicForm.createForm.reset();
            this.resetForm('resetForm');
            this.dynamicForm.jsonFile = 'sim-individual-registration';
            this.dynamicForm.ngOnInit();
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }  else if (this.simRegistrationId && data['simPrice']) {
      this.simProviderControllerService
        .updateSim({ simRegId: this.simRegistrationId, body: SimDetailsDto })
        .subscribe(
          (resp) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'success',
              'SIM_UPDATE_SUCCESS'
            );
            this.router.navigate(['sim/manage-sim']);
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    } else if (this.registrationType === SIM_PROVIDER && !data['simPrice']) {
      const metaData = {
        bprefId: SimDetailsDto['bprefId'],
        customerVendorId: SimDetailsDto['customerVendorId'],
        profileType: SimDetailsDto['profileType'],
        profiles: SimDetailsDto['profiles'],
        solutionTypeId: SimDetailsDto['solutionTypeId'],
        simProviderId: data['simProvider']
      };
      const reqObj = {
        body: {
          metaData: JSON.stringify(metaData),
          file: data.file
         }
      };
      this.simProviderControllerService
        .registerBulkSim(reqObj)
        .subscribe(
          (resp) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'success',
              'SIM_CREATE_SUCCESS'
            );
            this.router.navigate(['sim/manage-sim']);
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }
  }
  public resetForm($event) {
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['solutionType'] = [];
    this.dropDownValues['vendor'] = [];
    this.dropDownValues['primaryProfile'] = [];
    this.dropDownValues['secondaryProfile'] = [];
    this.selectedVendor = '';
    this.getAccount();
    if (this.dynamicForm.files && this.dynamicForm.files.length) {
      this.dynamicForm.sampleFileDownloadApi = '';
      this.dynamicForm.files = [];
    }
  }

  public getSimDetails(simReferenceId) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.simManagementControllerService
      .getSimDetailsBySimRefId({ simRefId: simReferenceId })
      .subscribe(
        (resp) => {
          if (resp) {
            this.simData = resp;
            this.createFormData(this.simData);
          } else {
            this.simData = null;
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.simData = null;
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public createFormData(simData) {
    this.getTenant(simData['account']['id']);
    this.getProfile(simData['tenant']['id']);
    this.getSolutionType(simData['businessProfile']['id']);
    if (this.simReferenceId && this.jsonFileLoaded === 'sim-individual-registration') {
      if (
        this.route.snapshot.paramMap.get('profileType') &&
        this.route.snapshot.paramMap.get('profileType') === 'DUAL'
      ) {
        this.formData['profileCount'] = true;
        this.formData['primaryProfile'] =
          this.route.snapshot.paramMap.get('primaryProfile');
        this.formData['secondaryProfile'] =
          this.route.snapshot.paramMap.get('secondaryProfile');
      } else if (
        this.route.snapshot.paramMap.get('profileType') &&
        this.route.snapshot.paramMap.get('profileType') === 'SINGLE'
      ) {
        this.formData['primaryProfile'] =
          this.route.snapshot.paramMap.get('primaryProfile');
        this.formData['profileCount'] = false;
      }
        this.getVendorsList(
          simData['businessProfile']['id'],
          simData['solution']['id']
        );
        if (simData['customerVendorDetails']) {
          this.getSimProfilesByVendorName(simData['customerVendorDetails']['name']);
          this.formData['vendor'] = simData['customerVendorDetails']['id'];
        }
      } else if (this.simRegistrationId && this.jsonFileLoaded === 'sim-individual-registration-no-vendor') {
        this.getAllSimProviders();
        this.formData['simPrice'] = simData['simPrice'] && simData['simPrice'];
        this.formData['simProvider'] = this.route.snapshot.paramMap.get('simProviderId');
        if (
              this.route.snapshot.paramMap.get('profileType') &&
              this.route.snapshot.paramMap.get('profileType') === 'DUAL'
            ) {
              this.formData['profileCount'] = true;
              this.simProfiles.forEach(profile => {
                if (profile.name === this.route.snapshot.paramMap.get('primaryProfile')) {
                  this.formData['primaryProfile'] = profile.id;
                }
               if (profile.name === this.route.snapshot.paramMap.get('secondaryProfile')) {
                  this.formData['secondaryProfile'] = profile.id;
                }
              });
            } else if (
              this.route.snapshot.paramMap.get('profileType') &&
              this.route.snapshot.paramMap.get('profileType') === 'SINGLE'
            ) {
              this.simProfiles.forEach(profile => {
                if (profile.name === this.route.snapshot.paramMap.get('primaryProfile')) {
                  this.formData['primaryProfile'] = profile.id;
                }
              });
            }
          
      }    
    this.formData['account'] = simData['account']['id'];
    this.formData['tenant'] = simData['tenant']['id'];
    this.formData['businessProfile'] = simData['businessProfile']['id'];
    this.formData['solutionType'] = simData['solution']['id'];
    this.formData['mobileNo'] = simData['mobileNo'];
    this.formData['iccId'] = simData['iccid'];
    this.dynamicForm.ngOnInit();
    this.loaderService.hideProgressBar(this.showSpinner);
    if (
      this.route.snapshot.paramMap.get('profileType') &&
      this.route.snapshot.paramMap.get('profileType') === 'DUAL'
    ) {
      this.dynamicForm.createForm
        .get('secondaryProfile')
        .setValue(this.route.snapshot.paramMap.get('secondaryProfile'));
      this.dynamicForm.createForm
        .get('secondaryProfile')
        .updateValueAndValidity();
      this.dynamicForm.createForm.updateValueAndValidity();
    }
  }

  public downloadSampleFile(fileTypeDetail) {
    this.utilityControllerService.downloadCsv({body: fileTypeDetail}).subscribe(
      (resp) => {
        if (resp) {
          this.downloadFile(
            resp,
            this.generateFileName('BulkSimRegistration', 'csv')
          );
        }
      },
      (err) => {
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }
  /**
   * @ngdoc method
   * @name simRegistration#downloadFile
   *
   * @methodOf
   * certificate.component:simRegistration
   *
   * @description
   * Description: Helper method to convert responce of download certificateapi into downloadable file
   *
   * @param {type} data {any}, fileName {string}
   * @return {type} null
   */
  public downloadFile(data, fileName) {
    const FileSaver = require('file-saver');
    const blob = new Blob([data], { type: 'application/csv' });
    FileSaver.saveAs(blob, fileName);
  }

  /**
   * @ngdoc method
   * @name simRegistration#generateFileName
   *
   * @methodOf
   * certificate.component:simRegistration
   *
   * @description
   * Description: Helper method to generate file name as 'BulkSimReistration.csv' for root certificate.
   *
   * @param {type} filename {string}, format {zip}
   * @return {type} null
   */
  public generateFileName(fileName, format) {
    return fileName + '.' + format;
  }

  public downloadSimRegDetails(fileRefId) {
    this.utilityControllerService
      .downloadJsonResponseFile({ fileRefId: fileRefId })
      .subscribe(
        (resp) => {
          if (resp) {
            this.downloadFile(
              resp,
              this.generateFileName('RegistrationResponse', 'txt')
            );
          }
        },
        (err) => {
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
public getAllSimProviders() {
  this.simProviderControllerService
    .getAllSimProviders()
    .subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['simProvider'] = resp;
        } else {
          this.dropDownValues['simProvider'] = [];
        }
      },
      (err) => {
        this.dropDownValues['simProvider'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      });
  }
  public getSimProfilesBySimProvider(providerId: string) {
    this.simProviderControllerService
      .getProfilesBySimProviderId({ simProviderId: providerId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['primaryProfile'] = resp;
            this.simProfiles = resp;
            if (this.simRegistrationId && this.formData) {
              this.dropDownValues['secondaryProfile'] = resp;
            }
          } else {
            this.dropDownValues['primaryProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['primaryProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  public changeForms() {
    this.matTabOptions = [];
    this.jsonFileLoaded = '';
    if (this.registrationType === SIM_VENDOR && this.permissionList.indexOf('SIM_C') > -1) {
        this.jsonFileLoaded = 'sim-individual-registration';
    } else if (this.registrationType === SIM_PROVIDER && this.permissionList.indexOf('SIM_C') > -1) {
      this.jsonFileLoaded = 'sim-individual-registration-no-vendor';
    }
    if (this.permissionList.indexOf('SIM_C') > -1) {
      this.matTabOptions.push({ label: 'Individual Registration', value: 'individual'});
      this.currentTabValue = 'individual';
      this.jsonFileLoaded = 'sim-individual-registration-no-vendor';
    }
  }

  public getProviderSimDetails(simRegistrationId) {
    this.showSpinner = this.loaderService.showProgressBar();
    const bprefId = this.route.snapshot.paramMap.get('businessProfileId');
    const solutionTypeId = this.route.snapshot.paramMap.get('solutionTypeId');
    this.simProviderControllerService
      .getSimDetailsBySimRegistrationId({ simRegId: simRegistrationId,  body: {bprefId: bprefId, solutionTypeId: solutionTypeId }})
      .subscribe(
        (resp) => {
          if (resp) {
            this.simData = resp;
            this.simData['businessProfile'] = {id: bprefId};
            this.simData['solution'] = {id: solutionTypeId};
            this.createFormData(this.simData);
          } else {
            this.simData = null;
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.simData = null;
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
}