/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TagDto } from '../models/tag-dto';
import { TagEntityDto } from '../models/tag-entity-dto';

@Injectable({
  providedIn: 'root',
})
export class TagControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAllTagsBySolutionTypeId
   */
  static readonly FindAllTagsBySolutionTypeIdPath = '/api/v1/solutionType/{solutionTypeId}/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllTagsBySolutionTypeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTagsBySolutionTypeId$Response(params: {
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<Array<TagDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TagControllerService.FindAllTagsBySolutionTypeIdPath, 'get');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TagDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllTagsBySolutionTypeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTagsBySolutionTypeId(params: {
    solutionTypeId: string;
  }): Observable<Array<TagDto>> {

    return this.findAllTagsBySolutionTypeId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TagDto>>) => r.body as Array<TagDto>)
    );
  }

  /**
   * Path part for operation detachTagEntity
   */
  static readonly DetachTagEntityPath = '/api/v1/tag/detach';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `detachTagEntity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  detachTagEntity$Response(params?: {
    body?: TagEntityDto
  }): Observable<StrictHttpResponse<TagDto>> {

    const rb = new RequestBuilder(this.rootUrl, TagControllerService.DetachTagEntityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `detachTagEntity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  detachTagEntity(params?: {
    body?: TagEntityDto
  }): Observable<TagDto> {

    return this.detachTagEntity$Response(params).pipe(
      map((r: StrictHttpResponse<TagDto>) => r.body as TagDto)
    );
  }

  /**
   * Path part for operation attachTagEntity
   */
  static readonly AttachTagEntityPath = '/api/v1/tag/attach';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `attachTagEntity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attachTagEntity$Response(params?: {
    body?: TagEntityDto
  }): Observable<StrictHttpResponse<TagDto>> {

    const rb = new RequestBuilder(this.rootUrl, TagControllerService.AttachTagEntityPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TagDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `attachTagEntity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  attachTagEntity(params?: {
    body?: TagEntityDto
  }): Observable<TagDto> {

    return this.attachTagEntity$Response(params).pipe(
      map((r: StrictHttpResponse<TagDto>) => r.body as TagDto)
    );
  }

}
