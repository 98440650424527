<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="proposalImage" />
        <span>{{'PROPOSALS' |translate}}</span>
      </span>
    </div>
    <div>
      <app-search-component *ngIf="proposalsData" [comboFieldSearchArray]="proposalsData"
      [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)" (resetButtonClicked)="onReset()"
      [gridLayout]="'grid-container-three-columns'" (searchFieldEmitter) = "onSubmit()" [displaySubmitButton]="true">
    </app-search-component>
    <section *ngIf="proposalsTableData" [ngClass]="getFilteredProposal? '' : 'setTableHeight'" class="table-container mat-elevation-z8" tabindex="0">
        <mat-table [dataSource]="proposalsTableData" class="table-data">
        <div *ngFor="let column of proposalsColumns;let even = even;">
          <ng-container [stickyEnd]="column==='action'?true:false" [sticky]="column==='accountName' ||  column==='customerId'?true:false"  [matColumnDef]="column">
            <mat-header-cell  *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
            </mat-header-cell>
            <mat-cell [ngStyle]="{'background-color':column==='accountName' && even || column==='customerId' && even ?  'white' :column==='action' && even?'#f7f7f7':'' }" class="tooltip" *matCellDef="let dataName; index as i">
              <span *ngIf="column !== 'action'" title="{{dataName[column]}}" class="cell-value">
              {{dataName[column]}}
              </span>
              <span *ngIf="column === 'action'" class="action-buttons">
                <img *ngIf="checkPermission('PROPSL_APR_REJ') && dataName['status'] === 'SUBMITTED'"
                [src]="approveImage"  class="actionIcon" [title]="'APPROVE' | translate"
                (click)="gotoProposalApproveRejectDialog(dataName, content, 'APPROVE')">
              <img *ngIf="checkPermission('PROPSL_APR_REJ') && dataName['status'] === 'SUBMITTED'"
                [src]="rejectImage"  class="actionIcon rejectIcon" [title]="'REJECT' | translate"
                (click)="gotoProposalApproveRejectDialog(dataName, content, 'REJECT')">
              <img *ngIf="checkPermission('PROPSL_APR_REJ')"
                [src]="viewImage" [title]="'VIEW' | translate" class="actionIcon viewIcon"
                (click)="displayProposalDetails(dataName)">
              <img [src]="downloadImage" class="actionIcon downloadIcon" [title]="'DOWNLOAD_DOCUMENT' | translate"
                (click)="displayUploadedDocuments(dataName['id']?dataName['id']:dataName['proposalId'])">
                <a  [href]="dataName['pdfUrl']" download *ngIf="checkPermission('PROPSL_APR_REJ') && dataName['pdfUrl'] !== null"
                ><img [src]="pdfImage" [title]="'DOWNLOAD_PROPOSAL_PDF' | translate" class="actionIcon pdf-Icon" ></a>
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="proposalsColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let data; columns: proposalsColumns"></mat-row>
      </mat-table>
    </section>
      <ng-template #content let-modal>
        <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
        <div class="modal-width" >
          <div class="modal-body">
            <mat-label class="label">{{ 'REASON' | translate }}
            </mat-label>
            <textarea class="text-area" matInput [(ngModel)]="proposalDescription"></textarea>
            <section class="section">
              <button (click)="approveRejectProposal()" mat-raised-button class="button">{{
                'SUBMIT' | translate }}</button>
            </section>
          </div>
        </div>
      </ng-template>
      <mat-paginator *ngIf="proposalsTableData && !getFilteredProposal" [pageSizeOptions]="pageSizeOptions" [length]="totalProposalsCount"
      [pageSize]="pageSize" showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
    </div>


    <div *ngIf="checkPermission('PROPSL_C') && proposalDropdownList">
      <app-dynamic-form class="dynamic-form-sales" [jsonFile]="'proposals'" [dropDownValues]="dropDownValues" [gridLayout]="'grid-container-three-columns'"
      (valueSelected)="updateDropdownValuesSalesAdmin($event)" (formEmitter)="getProposalsSalesAdmin()" (resetForm)="onResetSalesAdmin()" [emitValueAfterReset]="false"> 
      </app-dynamic-form>

        <div *ngIf="proposalSalesAdminList" class="table-container table-container-sales mat-elevation-z8" tabindex="0">
          <mat-table [dataSource]="proposalSalesAdminDataSource" class="bottom-space table-data" >
            <div *ngFor="let column of proposalSalesAdminColumns;let even = even;">
              <ng-container [stickyEnd]="column==='action'?true:false" [matColumnDef]="column">
                <mat-header-cell *matHeaderCellDef >{{column | appendSpace | titlecase | translate}}
                </mat-header-cell>
                <mat-cell class="tooltip" *matCellDef="let dataName" [ngClass]="column">
                  <span *ngIf="column!=='action'" title="{{dataName[column]}}" class="cell-value">
                    {{dataName[column]}}
                  </span>
                  <span *ngIf="column === 'action'" class="approve-reject-icon">
              
                    <img [src]="downloadImage" class="modify-button" [title]="'DOWNLOAD' | translate"
                      (click)="displayUploadedDocuments(dataName['id'])">
                    <img
                      *ngIf="checkPermission('PROPSL_C') && (dataName['status'] === 'DRAFT' || (dataName['status'] !== 'SUBMITTED' && dataName['editable']))"
                      [src]="editImage" class="modify-button" [title]="'EDIT' | translate"
                      (click)="editProposal(dataName)">

                    <button *ngIf="checkPermission('PROPSL_C') && dataName['status'] === 'SUBMITTED'" [ngClass]="{'btn-background': !disableSubmitButton}"
                      (click)="recallProposal(dataName['id'])" mat-raised-button class="button">{{ 'RECALL' |
                      translate }}</button>
                      
                      <button *ngIf="checkPermission('PROPSL_C') && (dataName['status'] === 'DRAFT')" (click)="gotoSubmitProposalDialog(submitContent,dataName['id'])" mat-raised-button class="button"
                      [disabled]="disableSubmitButton" [ngClass]="{'btn-background': !disableSubmitButton}">{{
                      'SUBMIT' | translate }}</button>
                  </span>
                </mat-cell>
              </ng-container>
            </div>
            <mat-header-row *matHeaderRowDef="proposalSalesAdminColumns"></mat-header-row>
            <mat-row *matRowDef="let data; columns: proposalSalesAdminColumns;"></mat-row>
          </mat-table>
        </div>
        <ng-template #content let-modal>
          <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
          <div class="modal-width">
            <div class="modal-body">
              <mat-label class="label">{{ 'REASON' | translate }}
              </mat-label>
              <textarea class="text-area" matInput [(ngModel)]="proposalDescription"></textarea>
              <section class="section">
                <button (click)="approveRejectProposal()" mat-raised-button class="button">{{
                  'SUBMIT' | translate }}</button>
              </section>
            </div>
          </div>
        </ng-template>

        <ng-template #submitContent let-modal>
          <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
          <div class="modal-width">
            <div class="modal-body">
              <mat-label class="label">{{ 'COMMENTS' | translate }}
              </mat-label>
              <textarea class="text-area" matInput [(ngModel)]="proposalComments"></textarea>
              <section class="section">
                <button (click)="submitProposal()" mat-raised-button class="submit-button button btn-background">
                  {{'SUBMIT' | translate }}</button>
              </section>
            </div>
          </div>
        </ng-template>
      

    </div>
    </section>
  </section>
