<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <span class="heading-label">
          <img class="page-icon" [src]="c2dIcon" />
          <span>{{'C2D' |translate}}</span>
        </span>
      </div>
      <div class="create-form-spacing">
        <div>
            <mat-form-field class="mat-form-field">
              <mat-label class="label">{{'DVEICE_ID' | translate}} <span class="mandatory">*</span>
              </mat-label>
              <input type="text" matInput name="deviceId" class="form-field" [value]=deviceId readonly
                [placeholder]=" 'TYPE_HERE' | translate" pattern="^[a-zA-Z0-9\\-]+$" [(ngModel)]=deviceId />
            </mat-form-field>
          </div>
      <app-dynamic-form [jsonFile]="jsonFileLoaded" [columnsInForm]="'single'" 
      (formEmitter)="callMethod($event)"(resetForm)="resetForm($event)" >
      </app-dynamic-form>
      <mat-form-field class="mat-form-field">
        <mat-label class="label">{{'RESPONSE_STSTUS' | translate}} <span class="mandatory">*</span>
        </mat-label>
        <input type="text" matInput name="respStatus" class="form-field" [value]=respStatus readonly
          [placeholder]=" 'TYPE_HERE' | translate"  [(ngModel)]=respStatus />
      </mat-form-field>
      <mat-form-field class="mat-form-field">
        <mat-label class="label">{{'RESPONSE_PAYLOAD' | translate}} <span class="mandatory">*</span>
        </mat-label>
        <input type="text-area" matInput name="respPayload" class="form-field resp-payload-area" [value]=respPayload readonly
          [placeholder]=" 'TYPE_HERE' | translate"  [(ngModel)]=respPayload />
      </mat-form-field>
    </div>
    </section>
    <section *ngIf="asideMenus">
      <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
    </section>
  </section>