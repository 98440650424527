<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="tenantIcon" />
        <span>{{'TENANT_MANAGEMENT_CREATE' |translate}}</span>
      </span>
    </div>
    <div class="create-form-spacing">
      <p class="disclaimer">{{'PLEASE_ENTER_CORPORATE_DETAILS_ONLY' | translate}}</p>
      <app-dynamic-form [jsonFile]="jsonFileLoaded" [dropDownValues]="dropDownValues" [columnsInForm]="'multi'"
        (valueSelected)="updateDropdownValues($event)" (formEmitter)="createTenant($event)">
      </app-dynamic-form>
    </div>
  </section>

  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>