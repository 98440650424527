/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DpsDto } from '../models/dps-dto';

@Injectable({
  providedIn: 'root',
})
export class DpsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findByDpsId
   */
  static readonly FindByDpsIdPath = '/api/v1/dps/{id}';

  /**
   * GET specific Dps.
   *
   * The api fetches the specific Dps for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByDpsId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByDpsId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<DpsDto>> {

    const rb = new RequestBuilder(this.rootUrl, DpsControllerService.FindByDpsIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DpsDto>;
      })
    );
  }

  /**
   * GET specific Dps.
   *
   * The api fetches the specific Dps for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByDpsId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByDpsId(params: {
    id: string;
  }): Observable<DpsDto> {

    return this.findByDpsId$Response(params).pipe(
      map((r: StrictHttpResponse<DpsDto>) => r.body as DpsDto)
    );
  }

  /**
   * Path part for operation updateDps
   */
  static readonly UpdateDpsPath = '/api/v1/dps/{id}';

  /**
   * Update Dps.
   *
   * The api updates the specific Dps for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDps()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDps$Response(params: {
    id: string;
    body?: DpsDto
  }): Observable<StrictHttpResponse<DpsDto>> {

    const rb = new RequestBuilder(this.rootUrl, DpsControllerService.UpdateDpsPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DpsDto>;
      })
    );
  }

  /**
   * Update Dps.
   *
   * The api updates the specific Dps for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDps$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDps(params: {
    id: string;
    body?: DpsDto
  }): Observable<DpsDto> {

    return this.updateDps$Response(params).pipe(
      map((r: StrictHttpResponse<DpsDto>) => r.body as DpsDto)
    );
  }

  /**
   * Path part for operation findAllDps
   */
  static readonly FindAllDpsPath = '/api/v1/dps';

  /**
   * GET All Dps.
   *
   * The api fetches all the Available dps
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllDps()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllDps$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<DpsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DpsControllerService.FindAllDpsPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DpsDto>>;
      })
    );
  }

  /**
   * GET All Dps.
   *
   * The api fetches all the Available dps
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllDps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllDps(params?: {
    active?: string;
  }): Observable<Array<DpsDto>> {

    return this.findAllDps$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DpsDto>>) => r.body as Array<DpsDto>)
    );
  }

  /**
   * Path part for operation createDps
   */
  static readonly CreateDpsPath = '/api/v1/dps';

  /**
   * Create a new Dps.
   *
   * The api creates a new Dps
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createDps()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDps$Response(params?: {
    body?: DpsDto
  }): Observable<StrictHttpResponse<DpsDto>> {

    const rb = new RequestBuilder(this.rootUrl, DpsControllerService.CreateDpsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DpsDto>;
      })
    );
  }

  /**
   * Create a new Dps.
   *
   * The api creates a new Dps
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createDps$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createDps(params?: {
    body?: DpsDto
  }): Observable<DpsDto> {

    return this.createDps$Response(params).pipe(
      map((r: StrictHttpResponse<DpsDto>) => r.body as DpsDto)
    );
  }

  /**
   * Path part for operation activateDps
   */
  static readonly ActivateDpsPath = '/api/v1/dps/{id}/activate';

  /**
   * Activate Dps.
   *
   * The api activates the specific Dps for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateDps()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateDps$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DpsControllerService.ActivateDpsPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activate Dps.
   *
   * The api activates the specific Dps for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateDps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateDps(params: {
    id: string;
  }): Observable<void> {

    return this.activateDps$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateDps
   */
  static readonly DeactivateDpsPath = '/api/v1/dps/{id}/deactivate';

  /**
   * Deactivate Dps.
   *
   * The api deactivates the specific Dps for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateDps()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateDps$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DpsControllerService.DeactivateDpsPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deactivate Dps.
   *
   * The api deactivates the specific Dps for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateDps$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateDps(params: {
    id: string;
  }): Observable<void> {

    return this.deactivateDps$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation findBySolDeviceIntProvId
   */
  static readonly FindBySolDeviceIntProvIdPath = '/api/v1/solDeviceIntProvId/{solDeviceIntProvId}/dps';

  /**
   * GET specific Dps for solution Device Interface Provider Id.
   *
   * The api fetches the specific Dps for the given solDeviceIntProvId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBySolDeviceIntProvId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBySolDeviceIntProvId$Response(params: {
    solDeviceIntProvId: string;
  }): Observable<StrictHttpResponse<DpsDto>> {

    const rb = new RequestBuilder(this.rootUrl, DpsControllerService.FindBySolDeviceIntProvIdPath, 'get');
    if (params) {
      rb.path('solDeviceIntProvId', params.solDeviceIntProvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DpsDto>;
      })
    );
  }

  /**
   * GET specific Dps for solution Device Interface Provider Id.
   *
   * The api fetches the specific Dps for the given solDeviceIntProvId
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findBySolDeviceIntProvId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBySolDeviceIntProvId(params: {
    solDeviceIntProvId: string;
  }): Observable<DpsDto> {

    return this.findBySolDeviceIntProvId$Response(params).pipe(
      map((r: StrictHttpResponse<DpsDto>) => r.body as DpsDto)
    );
  }

}
