<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
          <img (click)="navigateBack()" class="back-btn" [src]="backImage" />
        <img class="page-icon" [src]="proposalImage" />
        <span>{{'PROPOSAL_DETAILS' |translate}}</span>
      </span>
    </div>
    <div class="form-spacing">
      <section *ngIf="generalConfig">
        <div class="subHeader-div general-config">
          <mat-label class="label label-subHeader">{{  'GENERAL_CONFIG'  | translate }}
          </mat-label>
        </div>
        <app-dynamic-form  [jsonFile]="'display-general-configuration'" [formData]="generalConfig" [hideSubmitButton]="true"
        [gridLayout]="'grid-container-display-general-config'" [dropDownValues]="dropDownValues"
         ></app-dynamic-form>
        <mat-table *ngIf="generalConfigDataSource" [dataSource]="generalConfigDataSource" class="table-data">
        <div *ngFor="let column of generalConfigColumns;let even = even;">
          <ng-container [matColumnDef]="column">
            <mat-header-cell  *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
            </mat-header-cell>
            <mat-cell  class="tooltip" *matCellDef="let dataName; index as i">
              <span  title="{{dataName[column]}}" class="cell-value">
              {{dataName[column]}}
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="generalConfigColumns;"></mat-header-row>
        <mat-row *matRowDef="let data; columns: generalConfigColumns"></mat-row>
      </mat-table>
    </section>  
    
    <section *ngIf="infraConfigDataSource?.length">
      <div class="subHeader-div">
        <mat-label class="label label-subHeader">{{  'INFRASTRUCTURE_CONFIGURATIONS'  | translate }}
        </mat-label>
      </div>
      <mat-table [dataSource]="infraConfigDataSource" class="table-data">
      <div *ngFor="let column of infraConfigColumns;let even = even;">
        <ng-container [matColumnDef]="column">
          <mat-header-cell  *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
          </mat-header-cell>
          <mat-cell  class="tooltip" *matCellDef="let dataName; index as i">
            <span  title="{{dataName[column]}}" class="cell-value">
            {{dataName[column]}}
            </span>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="infraConfigColumns;"></mat-header-row>
      <mat-row *matRowDef="let data; columns: infraConfigColumns"></mat-row>
    </mat-table>
  </section>
  <section *ngIf="saasConfigDataSource?.length">
    <div class="subHeader-div">
      <mat-label class="label label-subHeader">{{  'SAAS_CONFIGURATIONS'  | translate }}
      </mat-label>
    </div>
    <mat-table [dataSource]="saasConfigDataSource" class="table-data">
    <div *ngFor="let column of saasConfigColumns;let even = even;">
      <ng-container [matColumnDef]="column">
        <mat-header-cell  *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
        </mat-header-cell>
        <mat-cell  class="tooltip" *matCellDef="let dataName; index as i">
          <span  title="{{dataName[column]}}" class="cell-value">
          {{dataName[column]}}
          </span>
        </mat-cell>
      </ng-container>
    </div>
    <mat-header-row *matHeaderRowDef="saasConfigColumns;"></mat-header-row>
    <mat-row *matRowDef="let data; columns: saasConfigColumns"></mat-row>
  </mat-table>
</section>

  <section *ngIf="peripheralConfigDataSource">
    <div class="subHeader-div">
      <mat-label class="label label-subHeader">{{  'PERIPHERAL_CONFIGURATIONS'  | translate }}
      </mat-label>
    </div>
    <mat-table [dataSource]="peripheralConfigDataSource" class="table-data">
    <div *ngFor="let column of peripheralConfigColumns;let even = even;">
      <ng-container [matColumnDef]="column">
        <mat-header-cell  *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
        </mat-header-cell>
        <mat-cell  class="tooltip" *matCellDef="let dataName; index as i">
          <span  title="{{dataName[column]}}" class="cell-value">
          {{dataName[column]}}
          </span>
        </mat-cell>
      </ng-container>
    </div>
    <mat-header-row *matHeaderRowDef="peripheralConfigColumns;"></mat-header-row>
    <mat-row *matRowDef="let data; columns: peripheralConfigColumns"></mat-row>
  </mat-table>
</section>

<section *ngIf="uploadedDocumentsDataSource?.length">
  <div class="subHeader-div">
    <mat-label class="label label-subHeader">{{  'UPLOADED_DOCUMENTS'  | translate }}
    </mat-label>
  </div>
  <mat-table [dataSource]="uploadedDocumentsDataSource" class="table-data">
  <div *ngFor="let column of uploadedDocumentsColumns;let even = even;">
    <ng-container [matColumnDef]="column">
      <mat-header-cell  *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
      </mat-header-cell>
      <mat-cell  class="tooltip" *matCellDef="let dataName; index as i">
        <span *ngIf="column!=='action'" title="{{dataName[column]}}" class="cell-value">
          {{dataName[column]}}
        </span>
        <span *ngIf="column==='action'">
          <a [href]="dataName['url']" download class="sample-file-link"> <img [src]="downloadImage"
              class="modify-button" [title]="'DOWNLOAD' | translate"></a>
          <img *ngIf="checkPermission('PROPSL_C') && checkPermission('PROPSL_APR_REJ')" [src]="deleteImage" (click)="gotoDeleteConfirmDialog(dataName['proposalId'], dataName['id'])"
            class="modify-button" [title]="'DELETE' | translate">
        </span>
      </mat-cell>
    </ng-container>
  </div>
  <mat-header-row *matHeaderRowDef="uploadedDocumentsColumns;"></mat-header-row>
  <mat-row *matRowDef="let data; columns: uploadedDocumentsColumns"></mat-row>
</mat-table>
</section>

<section *ngIf="this.termsAndConditionsControl?.value">
  <div class="subHeader-div">
    <mat-label class="label  terms-label label-subHeader">{{  'TERMS_AND_CONDITIONS'  | translate }}
    </mat-label>
    <textarea [formControl]="termsAndConditionsControl" rows="10" class="terms text-area" readonly matInput ></textarea>
  </div>
</section>

  </div>
  <div>
    <section  class="submit-section" *ngIf="proposalStatus == 'SUBMITTED'">
      <button mat-raised-button class="button save-continue-button button-submit"  (click)="gotoProposalApproveRejectDialog(content, 'REJECT')">{{ 'REJECT' | translate }}</button>
      <button mat-raised-button class="button"(click)="gotoProposalApproveRejectDialog(content, 'APPROVE')" >{{ 'APPROVE' | translate }}</button>
    </section>
  </div>   
  <ng-template #content let-modal>
    <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
    <div class="modal-width" >
      <div class="modal-body">
        <mat-label class="label">{{ 'REASON' | translate }}
        </mat-label>
        <textarea class="text-area" matInput [(ngModel)]="proposalDescription"></textarea>
        <section class="section">
          <button (click)="approveRejectProposal()" mat-raised-button class="button">{{
            'SUBMIT' | translate }}</button>
        </section>
      </div>
    </div>
  </ng-template>
    </section>
