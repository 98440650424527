import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { CustomerOnboardingComponent } from './customer-onboarding/customer-onboarding.component';
import { ProfileConnectionComponent } from './profile-connection/profile-connection.component';
import { CreateWhatsappTemplateComponent } from './create-whatsapp-template/create-whatsapp-template.component';
import { ManageWhatsappTemplateComponent } from './manage-whatsapp-template/manage-whatsapp-template.component';
import { WhatsappStatusComponent } from './whatsapp-status/whatsapp-status.component';
import { DataAnalyticsComponent } from './data-analytics/data-analytics.component';
import { BotConfigurationComponent } from './bot-configuration/bot-configuration.component';
import { CampaignManagementComponent } from './campaign-management/campaign-management.component';
import { CampaignManagementDetailsComponent } from './campaign-management-details/campaign-management-details.component';
import { WhatsappAnalyticsComponent } from './whatsapp-analytics/whatsapp-analytics.component';
import { ClickTrackingAnalysisComponent } from './click-tracking-analysis/click-tracking-analysis.component';
const routes: Routes = [
  {
    path: 'whatsapp/customer-onboard',
    component: CustomerOnboardingComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'WSP_ONBD_R', data: {} }
 },
 {
  path: 'whatsapp/connection-details',
  component: ProfileConnectionComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_PROF_CON_R', data: {} }
},
{
  path: 'whatsapp/create-template',
  component: CreateWhatsappTemplateComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_TMPL_R', data: {} }
},
{
  path: 'whatsapp/template-configuration',
  component: ManageWhatsappTemplateComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_TMPL_R', data: {} }
},
{
  path: 'whatsapp/whatsapp-status',
  component: WhatsappStatusComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_RPT_R', data: {} }
},
{
  path: 'whatsapp/data-analytics',
  component: DataAnalyticsComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_DBD', data: {} }
},
{
  path: 'whatsapp/bot-configuration',
  component: BotConfigurationComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_BOT_R', data: {} }
},
{
  path: 'whatsapp/campaign-management',
  component: CampaignManagementComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_BULK_R', data: {} }
},
{
  path: 'whatsapp/job-details',
  component: CampaignManagementDetailsComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_BULK_R', data: {} }
},
{
  path: 'whatsapp/analytics-details',
  component: WhatsappAnalyticsComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_ANLT_R', data: {} }
},
{
  path: 'whatsapp/click-tracking',
  component: ClickTrackingAnalysisComponent,
  canActivate: [CanAuthenticationGuard],
  data: { permission: 'WSP_BULK_R', data: {} }
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WhatsappRoutingModule { }
