/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-right-side-nav-overlay',
  templateUrl: './right-side-nav-overlay.component.html',
  styleUrls: ['./right-side-nav-overlay.component.scss'],
})

/**
  * @ngdoc component
  * @name SharedModule.component:RightSideNavOverlay
  *
  *
  * @description
  * description: Show Quick links on the right side of the page
  *
*/
export class RightSideNavOverlayComponent implements OnInit {
  @Input() asideMenuList: any;
  public asideMenus;
  public opened = false;
  protected clickEventListener: EventListener;


  constructor() {
  }

  ngOnInit() {
    this.asideMenus = this.asideMenuList;
    const activeQuickLinkIndex = this.asideMenus.findIndex(link => link['isSelected'] === true);
    const activeLink = this.asideMenus[activeQuickLinkIndex];
    this.asideMenus.splice(activeQuickLinkIndex, 1);
    this.asideMenus.unshift(activeLink);
    this.clickEventListener = () => this.closeOverlay();
  }

   /**
    * @ngdoc method
    * @name RightSideNavOverlay#addEventHandler
    *
    * @methodOf
    * SharedModule.controller:RightSideNavOverlay
    *
    * @description
    * Description: Add and remove click event listener to the page
    *
    * @param {type} null
    * @return {type} null
  */
  addEventHandler() {
    if (this.opened) {
      document.addEventListener('click', this.clickEventListener);
    } else {
      document.removeEventListener('click', this.clickEventListener);
    }
  }

  /**
    * @ngdoc method
    * @name RightSideNavOverlay#closeOverlay
    *
    * @methodOf
    * SharedModule.controller:RightSideNavOverlay
    *
    * @description
    * Description: close the overlay on clicking the page
    *
    * @param {type} null
    * @return {type} null
  */
  closeOverlay() {
    this.opened = false;
  }

}
