/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetails } from '../models/common-details';
import { SimVendorDto } from '../models/sim-vendor-dto';

@Injectable({
  providedIn: 'root',
})
export class SimManagementControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSimDetailsByProfile
   */
  static readonly GetSimDetailsByProfilePath = '/api/v1/sim/{simRefId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimDetailsByProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimDetailsByProfile$Response(params: {
    simRefId: string;
    businessProfileId?: string;
  }): Observable<StrictHttpResponse<SimVendorDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimManagementControllerService.GetSimDetailsByProfilePath, 'get');
    if (params) {
      rb.path('simRefId', params.simRefId, {});
      rb.query('businessProfileId', params.businessProfileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimVendorDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSimDetailsByProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimDetailsByProfile(params: {
    simRefId: string;
    businessProfileId?: string;
  }): Observable<SimVendorDto> {

    return this.getSimDetailsByProfile$Response(params).pipe(
      map((r: StrictHttpResponse<SimVendorDto>) => r.body as SimVendorDto)
    );
  }

  /**
   * Path part for operation saveSimResponseJson
   */
  static readonly SaveSimResponseJsonPath = '/api/v1/sim/registerationResponse';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveSimResponseJson()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSimResponseJson$Response(params?: {
    body?: {
'file'?: Blob;
}
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SimManagementControllerService.SaveSimResponseJsonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveSimResponseJson$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSimResponseJson(params?: {
    body?: {
'file'?: Blob;
}
  }): Observable<string> {

    return this.saveSimResponseJson$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation downloadJsonResponseFile
   */
  static readonly DownloadJsonResponseFilePath = '/api/v1/sim/registerationResponse/{fileRefId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadJsonResponseFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadJsonResponseFile$Response(params: {
    fileRefId: string;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, SimManagementControllerService.DownloadJsonResponseFilePath, 'get');
    if (params) {
      rb.path('fileRefId', params.fileRefId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadJsonResponseFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadJsonResponseFile(params: {
    fileRefId: string;
  }): Observable<Array<string>> {

    return this.downloadJsonResponseFile$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getSimVendorDetails
   */
  static readonly GetSimVendorDetailsPath = '/api/v1/sim-vendors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimVendorDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSimVendorDetails$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<SimVendorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SimManagementControllerService.GetSimVendorDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimVendorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSimVendorDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSimVendorDetails(params?: {
    body?: Array<string>
  }): Observable<Array<SimVendorDto>> {

    return this.getSimVendorDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimVendorDto>>) => r.body as Array<SimVendorDto>)
    );
  }

  /**
   * Path part for operation getSimVendorsByProfileAndSolutionType
   */
  static readonly GetSimVendorsByProfileAndSolutionTypePath = '/api/v1/businessProfile/{businessProfileId}/solutiontype/{solutionTypeId}/customerVendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimVendorsByProfileAndSolutionType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimVendorsByProfileAndSolutionType$Response(params: {
    businessProfileId: string;
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, SimManagementControllerService.GetSimVendorsByProfileAndSolutionTypePath, 'get');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSimVendorsByProfileAndSolutionType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimVendorsByProfileAndSolutionType(params: {
    businessProfileId: string;
    solutionTypeId: string;
  }): Observable<Array<CommonDetails>> {

    return this.getSimVendorsByProfileAndSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation registerSim
   */
  static readonly RegisterSimPath = '/api/v1/sim';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerSim()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSim$Response(params?: {
    body?: Array<SimVendorDto>
  }): Observable<StrictHttpResponse<Array<SimVendorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SimManagementControllerService.RegisterSimPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SimVendorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerSim$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSim(params?: {
    body?: Array<SimVendorDto>
  }): Observable<Array<SimVendorDto>> {

    return this.registerSim$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SimVendorDto>>) => r.body as Array<SimVendorDto>)
    );
  }

  /**
   * Path part for operation deleteSims
   */
  static readonly DeleteSimsPath = '/api/v1/sims';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteSims()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSims$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SimManagementControllerService.DeleteSimsPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteSims$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deleteSims(params?: {
    body?: Array<string>
  }): Observable<void> {

    return this.deleteSims$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation registerSimVendor
   */
  static readonly RegisterSimVendorPath = '/api/v1/vendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerSimVendor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSimVendor$Response(params?: {
    body?: SimVendorDto
  }): Observable<StrictHttpResponse<SimVendorDto>> {

    const rb = new RequestBuilder(this.rootUrl, SimManagementControllerService.RegisterSimVendorPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimVendorDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerSimVendor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSimVendor(params?: {
    body?: SimVendorDto
  }): Observable<SimVendorDto> {

    return this.registerSimVendor$Response(params).pipe(
      map((r: StrictHttpResponse<SimVendorDto>) => r.body as SimVendorDto)
    );
  }

}
