<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="accountIcon" />
        <span>{{'ACCOUNT_MANAGEMENT_CREATE' |translate}}</span>
      </span>
    </div>
    <div class="create-form-spacing">
    <app-dynamic-form [jsonFile]="jsonFileLoaded" [columnsInForm]="'single'" (formEmitter)="createAccount($event)">
    </app-dynamic-form>
  </div>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>