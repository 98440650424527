import { EventEmitter, Injectable } from '@angular/core';
import * as Highcharts from 'highcharts';

@Injectable({
    providedIn: 'root'
})
export class LineChartService {
    defaultOptions;   
    getOptions() {
    this.defaultOptions = {
            chart: {
                type: 'line',
                height: 45 + '%'
            },
            title: {
                text: ''
            },
            legend: {
                enabled: false
             },
             xAxis: {
                categories: []
            },
		    yAxis: {
                title: {
                    text: ''
                },
                gridLineWidth: 0,
                lineWidth:1
            },
            tooltip: {
                shared: true,
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                line: {
                    fillOpacity: 0
                }
            },
            series: []
        };
        return this.defaultOptions;
    }

    constructor() { }

    public createChart(container, options) {
        const chartOptions = this.getOptions();
        const chartData = [];
        options.forEach(data => {
            const chartDataObject = {
                name: '',
                data: '',
                color: '',
                fillColor: {}
            };
            chartDataObject.name = data.name;
            chartDataObject.data = data.values;
            chartDataObject.color = data.color;
            chartDataObject.fillColor = {
                linearGradient: [0, 0, 0, 150],
                stops: [
                    [0, data.color],
                    [1, Highcharts.color(data.color).setOpacity(0).get('rgba')]
                ]
            };
            chartOptions['tooltip'] = { 
                useHTML: true,
                formatter: function() {
                return '<div><b>'+ data['dates'][ this.x.indexOf(this.x)]+' : ' + '</b><b>'+ this.y +'</b></div>';}
            };
            chartOptions.xAxis.categories = data.time;
            chartData.push(chartDataObject);
        });
        chartOptions.series = chartData;
        const element = document.createElement('div');
        container.appendChild(element);
        if (!!chartOptions.chart) {
            chartOptions.chart['renderTo'] = element;
        } else {
            options.chart.renderTo = 'area-chart';
        }
        return new Highcharts.Chart(chartOptions);

    }

    public destroyChart(chart) {
        if (chart && chart.userOptions) {
            chart.destroy();
            chart = null;
        }
        return chart;
    }

    public redrawChart(chart, data) {
        chart.addSeries({
            name: data.name,
            data: data.values,
            color: data.color,
            fillColor: {
                linearGradient: [0, 0, 0, 150],
                stops: [
                    [0, data.color],
                    [1, Highcharts.color(data.color).setOpacity(0).get('rgba')]
                ]
            }
        });
    }

    public updateChart(chart, legendName, displayType) {
        if (chart.series) {
            chart.series.forEach(individualSeries => {
                if (individualSeries.name === legendName) {
                    if (displayType === 'hide' && individualSeries.visible) {
                        individualSeries.hide();
                    } else if (displayType === 'show') {
                        individualSeries.show();
                    }
                }
            });
        }
    }
}
