/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FirmwareArtifactDto } from '../models/firmware-artifact-dto';
import { FirmwareArtifactResponseDto } from '../models/firmware-artifact-response-dto';
import { MessageDto } from '../models/message-dto';

@Injectable({
  providedIn: 'root',
})
export class FirmwareControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFirmware
   */
  static readonly GetFirmwarePath = '/api/v1/firmware/{firmwareId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFirmware()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirmware$Response(params: {
    firmwareId: string;
  }): Observable<StrictHttpResponse<FirmwareArtifactDto>> {

    const rb = new RequestBuilder(this.rootUrl, FirmwareControllerService.GetFirmwarePath, 'get');
    if (params) {
      rb.path('firmwareId', params.firmwareId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FirmwareArtifactDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFirmware$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirmware(params: {
    firmwareId: string;
  }): Observable<FirmwareArtifactDto> {

    return this.getFirmware$Response(params).pipe(
      map((r: StrictHttpResponse<FirmwareArtifactDto>) => r.body as FirmwareArtifactDto)
    );
  }

  /**
   * Path part for operation deleteFirmware
   */
  static readonly DeleteFirmwarePath = '/api/v1/firmware/{firmwareId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFirmware()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFirmware$Response(params: {
    firmwareId: string;
  }): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, FirmwareControllerService.DeleteFirmwarePath, 'delete');
    if (params) {
      rb.path('firmwareId', params.firmwareId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteFirmware$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFirmware(params: {
    firmwareId: string;
  }): Observable<MessageDto> {

    return this.deleteFirmware$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }

  /**
   * Path part for operation getAllFirmware
   */
  static readonly GetAllFirmwarePath = '/api/v1/firmwares';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFirmware()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllFirmware$Response(params?: {
    allFirmwares?: string;
    page?: number;
    limit?: number;
    body?: FirmwareArtifactDto
  }): Observable<StrictHttpResponse<FirmwareArtifactResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, FirmwareControllerService.GetAllFirmwarePath, 'post');
    if (params) {
      rb.query('allFirmwares', params.allFirmwares, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FirmwareArtifactResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllFirmware$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getAllFirmware(params?: {
    allFirmwares?: string;
    page?: number;
    limit?: number;
    body?: FirmwareArtifactDto
  }): Observable<FirmwareArtifactResponseDto> {

    return this.getAllFirmware$Response(params).pipe(
      map((r: StrictHttpResponse<FirmwareArtifactResponseDto>) => r.body as FirmwareArtifactResponseDto)
    );
  }

  /**
   * Path part for operation getFirmwareVersions
   */
  static readonly GetFirmwareVersionsPath = '/api/v1/firmwareVersions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFirmwareVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirmwareVersions$Response(params: {
    deviceTypeId: string;
  }): Observable<StrictHttpResponse<{
[key: string]: Array<{
}>;
}>> {

    const rb = new RequestBuilder(this.rootUrl, FirmwareControllerService.GetFirmwareVersionsPath, 'post');
    if (params) {
      rb.query('deviceTypeId', params.deviceTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: Array<{
        }>;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getFirmwareVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFirmwareVersions(params: {
    deviceTypeId: string;
  }): Observable<{
[key: string]: Array<{
}>;
}> {

    return this.getFirmwareVersions$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<{
}>;
}>) => r.body as {
[key: string]: Array<{
}>;
})
    );
  }

  /**
   * Path part for operation upload
   */
  static readonly UploadPath = '/api/v1/firmware';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `upload()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  upload$Response(params?: {
    firmwareDto?: string;
    body?: {
'multipartFiles'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FirmwareControllerService.UploadPath, 'post');
    if (params) {
      rb.query('firmwareDto', params.firmwareDto, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `upload$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  upload(params?: {
    firmwareDto?: string;
    body?: {
'multipartFiles'?: Array<Blob>;
}
  }): Observable<void> {

    return this.upload$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
