import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { SmsConfigurationComponent } from './sms-configuration/sms-configuration.component';
import { SmsBulkUploadComponent } from './sms-bulk-upload/sms-bulk-upload.component';
import { SmsStatusComponent } from './sms-status/sms-status.component';
import { SmsJobDetailsComponent } from './sms-job-details/sms-job-details.component';


const routes: Routes = [
  {
    path: 'sms/sms-configuration',
    component: SmsConfigurationComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'SMS_R', data: {} }
  },
  {
    path: 'sms/bulk-sms',
    component: SmsBulkUploadComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'SMS_BULK_R', data: {} }
  },
  {
    path: 'sms/sms-job-details',
    component: SmsJobDetailsComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'SMS_BULK_R', data: {} }
  },
  {
    path: 'sms/sms-status',
    component: SmsStatusComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'SMS_RPT_R', data: {} }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SmsRoutingModule { }
