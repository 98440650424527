import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingRoutingModule } from './billing-routing.module';
import { BillingConfigurationComponent } from './billing-configuration/billing-configuration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { OperationConfigurationComponent } from './operation-configuration/operation-configuration.component';
import { BillingDataComponent } from './billing-data/billing-data.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import {MatExpansionModule} from '@angular/material/expansion';
import { ProposalsComponent } from './proposals/proposals.component';
import { ProposalDetailsComponent } from './proposal-details/proposal-details.component';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
  declarations: [
    BillingConfigurationComponent,
    OperationConfigurationComponent,
    BillingDataComponent,
    ProposalsComponent,
    ProposalDetailsComponent,
  ],
  imports: [
    CommonModule,
    BillingRoutingModule,
    SharedModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatSelectModule,
    MatCardModule,
    MatMenuModule,
    FormsModule,
    MatExpansionModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTreeModule,
    MatIconModule,
    MatTabsModule,
    MatRadioModule
  ]
})
export class BillingModule {}
