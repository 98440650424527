import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { TranslationService } from 'src/app/providers/translation-service';
import { SchedulerControllerService } from 'src/app/services/Scheduler/services/scheduler-controller.service';
import { ProcedureControllerService } from 'src/app/services/Scheduler/services/procedure-controller.service';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { CREATE, SCHEDULER_ICON, TOAST_MSG_DURATION } from 'src/app/shared/constants/strings';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-create-scheduler',
  templateUrl: './create-scheduler.component.html',
  styleUrls: ['./create-scheduler.component.scss']
})
export class CreateSchedulerComponent implements OnInit {

  public dropDownValues: any = {};
  public showSpinner :any;
  public dataSource;
  public columns: any[];
  parametersList;
  schedulerForm: UntypedFormGroup;
  submitted = false;
  scheduleType = 'DAY_OF_WEEK';
  procedureList = false;
  ignoreList = ['id', 'parameters'];
  schedulerId;
  schedulerDetails;
  public pageKey: string;
  asideMenus: Array<AsideCard>;
  public schedulerIcon;
  currentDate: Date;

  constructor(private formBuilder: UntypedFormBuilder, private schedulerControllerService: SchedulerControllerService,
    private translate: TranslationService, private _snackBar: MatSnackBar, private router: Router, private route: ActivatedRoute,
    private rightsideNavitemsService: RightsideNavitemsService, private procedureControllerService: ProcedureControllerService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.pageKey = 'fota';
    this.currentDate = new Date();
    this.rightsideNavitemsService.getRightsideNavItems(CREATE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.schedulerForm = this.formBuilder.group({
      name: ['', Validators.required],
      databaseName: ['', Validators.required],
      procedureId: ['', Validators.required],
      schedulerType: ['DAY_OF_WEEK', Validators.required]
    });
    this.schedulerIcon = SCHEDULER_ICON;
    this.getDatabase();
    this.getDays();
    this.getScheduleType();
    this.changeScheduleType({ 'value': 'DAY_OF_WEEK' });
    this.schedulerId = this.route.snapshot.paramMap.get('schedulerId');
    if (this.schedulerId) {
      this.getSchedulerDetails(this.schedulerId);
    }
  }

  getSchedulerDetails(schedulerId) {
    this.schedulerControllerService.schedules({ schedulerId: schedulerId })
      .subscribe(
        resp => {
          if (resp) {
            this.schedulerDetails = resp;
            this.scheduleType = this.schedulerDetails['schedulerType'];
            this.changeScheduleType({ value: this.schedulerDetails['schedulerType'] });
            this.schedulerForm.patchValue({
              name: this.schedulerDetails['name'],
              databaseName: this.schedulerDetails['procedureInfo']['databaseName'],
              procedureId: this.schedulerDetails['procedureInfo']['procedureId'],
              schedulerType: this.schedulerDetails['schedulerType'],
              startDate: resp['startDate'] ? this.changeDateFormat(resp['startDate']) : '',
              startTime: resp['startDate'] ? resp['startDate'].substr(11) : '',
              endDate: resp['endDate'] ? this.changeDateFormat(resp['endDate']) : '',
              endTime: resp['endDate'] ? resp['endDate'].substr(11) : '',
            });
            if (this.schedulerDetails['schedulerType'] !== 'SPECIFIC_DATES') {
              if (this.schedulerDetails['schedulerType'] === 'DAY_OF_WEEK') {
                (<UntypedFormArray>this.schedulerForm.get('executionTime')).controls = [];
                this.schedulerDetails['executionTime'].forEach(day => {
                  (<UntypedFormArray>this.schedulerForm.get('executionTime')).push(this.formBuilder.group({
                    dayOfWeek: [day['dayOfWeek'], Validators.required],
                    time: [(day['hours'] < 10 ? '0' + day['hours'] : day['hours']) + ':'
                      + (day['mins'] < 10 ? '0' + day['mins'] : day['mins']), Validators.required]
                  }));
                });
              } else {
                this.schedulerDetails['executionTime'].forEach(day => {
                  this.schedulerForm.get('executionTime').setValue({
                    days: day['days'],
                    hours: day['hours']
                  });
                });
              }
            } else {
              (<UntypedFormArray>this.schedulerForm.get('executionTime')).controls = [];
              this.schedulerDetails['executionTime'].forEach(day => {
                (<UntypedFormArray>this.schedulerForm.get('executionTime')).push(this.formBuilder.group({
                  specificDate: [this.changeDateFormat(day['specificDate'].substr(0, 11)), Validators.required],
                  executionTime: [day['specificDate'].substr(11), Validators.required],
                }));
              });
            }
          }
          if (this.schedulerDetails['parameterValues']) {
            this.schedulerForm.addControl('parameterValues', this.formBuilder.array([]));
            this.schedulerDetails['parameterValues'].forEach(param => {
              if (param['parameterType'] !== 'Date') {
                const attributeGroup = this.formBuilder.group({
                  parameterMasterId: [param['parameterMasterId']],
                  parameterName: [param['parameterName']],
                  parameterType: [param['parameterType']],
                  value: [param['value'], [Validators.required, Validators.pattern(param['paramRegularExpression'])]]
                });
                (<UntypedFormArray>this.schedulerForm.get('parameterValues')).push(attributeGroup);
              } else {
                const attributeGroup = this.formBuilder.group({
                  parameterMasterId: [param['parameterMasterId']],
                  parameterName: [param['parameterName']],
                  parameterType: [param['parameterType']],
                  value: [this.changeDateFormat(param['value']), Validators.required]
                });
                (<UntypedFormArray>this.schedulerForm.get('parameterValues')).push(attributeGroup);
              }
            });
          }
        },
        err => {
          this._snackBar.open(err.error.message, '', {
            duration: TOAST_MSG_DURATION,
            verticalPosition: 'top',
            panelClass: ['error'],
          });
        }
      );
  }

  public changeDateFormat(date) {
    if (date !== null) {
      const splitDate = date.split('-');
      return `${splitDate[2].split(' ')[0]}-${splitDate[1]}-${splitDate[0]}`;
    }
  }

  public getDatabase() {
    this.procedureControllerService.procedures()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.parametersList = resp;
            const databaseNames = resp.map(name => name['dataBaseName']);
            this.dropDownValues['databaseName'] = databaseNames;
            if (this.schedulerDetails) {
              this.updateDropdownValues({ value: this.schedulerDetails['procedureInfo']['databaseName'] });
            }
          } else {
            this.dropDownValues['databaseName'] = [];
          }
        },
        err => {
          this.dropDownValues['databaseName'] = [];
          this._snackBar.open(err.error.message, '', {
            duration: TOAST_MSG_DURATION,
            verticalPosition: 'top',
            panelClass: ['error'],
          });
        }
      );
  }

  public getScheduleType() {
    this.dropDownValues['scheduleType'] = [
      {
        'id': 'DAY_OF_WEEK',
        'name': 'Day Of Week',
      },
      {
        'id': 'REGULAR_INTERVAL',
        'name': 'Regular Interval',
      },
      {
        'id': 'SPECIFIC_DATES',
        'name': 'Specific Dates',
      },
    ];
  }

  public changeScheduleType(event) {
    this.scheduleType = event.value;
    if (this.scheduleType !== 'SPECIFIC_DATES') {
      this.schedulerForm.addControl('startDate', this.formBuilder.control('', [Validators.required]));
      this.schedulerForm.addControl('startTime', this.formBuilder.control('', [Validators.required]));
      this.schedulerForm.addControl('endDate', this.formBuilder.control('', [Validators.required]));
      this.schedulerForm.addControl('endTime', this.formBuilder.control('', [Validators.required]));
      if (this.scheduleType === 'DAY_OF_WEEK') {
        this.schedulerForm.removeControl('executionTime');
        this.schedulerForm.addControl('executionTime', this.formBuilder.array([this.createFormGroups()]));
      } else {
        this.schedulerForm.removeControl('executionTime');
        this.schedulerForm.addControl('executionTime', this.formBuilder.group({
          days: ['', Validators.required],
          hours: ['', Validators.required]
        }));
      }
    } else {
      this.schedulerForm.removeControl('startDate');
      this.schedulerForm.removeControl('startTime');
      this.schedulerForm.removeControl('endDate');
      this.schedulerForm.removeControl('endTime');
      this.schedulerForm.removeControl('executionTime');
      this.schedulerForm.addControl('executionTime', this.formBuilder.array([this.createSpecificDateGroups()]));
    }
  }

  public createFormGroups() {
    return this.formBuilder.group({
      dayOfWeek: ['', Validators.required],
      time: ['', Validators.required]
    });
  }

  public addFormGroup() {
    const formGroup = this.createFormGroups();
    (<UntypedFormArray>this.schedulerForm.get('executionTime')).push(formGroup);
  }

  public removeFormGroup(index: number) {
    (<UntypedFormArray>this.schedulerForm.get('executionTime')).removeAt(index);
  }

  public getDays() {
    this.dropDownValues['days'] = [
      {
        'id': 'MON',
        'name': 'Monday',
      },
      {
        'id': 'TUE',
        'name': 'Tuesday',
      },
      {
        'id': 'WED',
        'name': 'Wednesday',
      },
      {
        'id': 'THU',
        'name': 'Thursday',
      },
      {
        'id': 'FRI',
        'name': 'Friday',
      },
      {
        'id': 'SAT',
        'name': 'Saturday',
      }, {
        'id': 'SUN',
        'name': 'Sunday',
      }
    ];
  }

  public createSpecificDateGroups() {
    return this.formBuilder.group({
      specificDate: ['', Validators.required],
      executionTime: ['', Validators.required],
    });
  }

  public addSpecificDateGroups() {
    const dateFormGroup = this.createSpecificDateGroups();
    (<UntypedFormArray>this.schedulerForm.get('executionTime')).push(dateFormGroup);
  }

  public removeSpecificDateGroups(index: number) {
    (<UntypedFormArray>this.schedulerForm.get('executionTime')).removeAt(index);
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      const procedureNames = this.parametersList.find(list => list['dataBaseName'] === $event.value);
      this.dropDownValues['procedureId'] = procedureNames['procedureList'];
    }
  }

  public getParentProcedures($event) {
    const procedureId = this.dropDownValues['procedureId'].find(list => list['id'] === $event.value);
    if (procedureId['parameters'] && procedureId['parameters'].length !== 0) {
      this.schedulerForm.setControl('parameterValues', this.formBuilder.array([]));
      this.schedulerForm.addControl('parameterValues', this.formBuilder.array([]));
      procedureId['parameters'].forEach(param => {
        if (param['parameterType'] !== 'Date') {
          const attributeGroup = this.formBuilder.group({
            parameterMasterId: [param['paramterId']],
            parameterName: [param['parameterName']],
            parameterType: [param['parameterType']],
            value: ['', [Validators.required, Validators.pattern(param['paramRegularExpression'])]]
          });
          (<UntypedFormArray>this.schedulerForm.get('parameterValues')).push(attributeGroup);
        } else {
          const attributeGroup = this.formBuilder.group({
            parameterMasterId: [param['paramterId']],
            parameterName: [param['parameterName']],
            parameterType: [param['parameterType']],
            value: ['', Validators.required]
          });
          (<UntypedFormArray>this.schedulerForm.get('parameterValues')).push(attributeGroup);
        }
      });
    } else {
      this.schedulerForm.removeControl('parameterValues');
    }

    this.procedureControllerService.parentProcs({ procedureId: $event.value })
      .subscribe(
        resp => {
          if (resp && resp.length !== 0) {
            resp.forEach(procedure => {
              procedure['isScheduled'] === true ? this.procedureList = false : this.procedureList = true;
            });
            this.dataSource = new MatTableDataSource(resp);
            this.columns = this.filterColumns(Object.keys(resp[0]));
          } else {
            this.procedureList = false;
          }
        },
        err => {
          this._snackBar.open(err.error.message, '', {
            duration: TOAST_MSG_DURATION,
            verticalPosition: 'top',
            panelClass: ['error'],
          });
        }
      );
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  onSubmit(type) {
    const body = this.schedulerForm.value;
    if (this.schedulerForm.invalid) {
      this.submitted = true;
      return;
    }
    body['active'] = true;
    if (this.scheduleType === 'DAY_OF_WEEK') {
      body['startDate'] = body['startDate'] instanceof Date ? this.parseTime(body['startDate'], body['startTime']) :
        this.parseDateTime(body['startDate'], body['startTime']);
      body['endDate'] = body['endDate'] instanceof Date ? this.parseTime(body['endDate'], body['endTime']) :
        this.parseDateTime(body['endDate'], body['endTime']);
      body['executionTime'] = body['executionTime'].map(object => {
        const newObject = {};
        newObject['dayOfWeek'] = object['dayOfWeek'],
          newObject['hours'] = object['time'].split(':')[0];
        newObject['mins'] = object['time'].split(':')[1];
        newObject['deleteFlag'] = false;
        return newObject;
      });
    } else if (this.scheduleType === 'SPECIFIC_DATES') {
      body['executionTime'] = body['executionTime'].map(object => {
        object = {
          'specificDate': object['specificDate'] instanceof Date ? this.parseTime(object['specificDate'], object['executionTime'])
            : this.parseDateTime(object['specificDate'], object['executionTime'])
        };
        object['deleteFlag'] = false;
        return object;
      });
    } else {
      body['startDate'] = body['startDate'] instanceof Date ? this.parseTime(body['startDate'], body['startTime']) :
        this.parseDateTime(body['startDate'], body['startTime']);
      body['endDate'] = body['endDate'] instanceof Date ? this.parseTime(body['endDate'], body['endTime']) :
        this.parseDateTime(body['endDate'], body['endTime']);
      body['executionTime']['deleteFlag'] = false;
      body['executionTime'] = [body['executionTime']];
    }
    if (body['parameterValues']) {
      body['parameterValues'] = body['parameterValues'].map(params => {
        if (params['parameterType'] === 'Date') {
          params['value'] = this.changeDateFormat(params['value']);
        }
        return params;
      });
    }
    this.showSpinner = this.loaderService.showProgressBar();
    if (type === 'submit') {
      this.schedulerControllerService.saveScheduler({ body: body })
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this._snackBar.open(this.translate.translateErrorMessages('SCHEDULER_CREATE_SUCCESS'), '', {
              duration: TOAST_MSG_DURATION,
              verticalPosition: 'top',
              panelClass: ['success'],
            });
            this.router.navigate(['schedulers/manage-scheduler']);
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this._snackBar.open(err.error.message, '', {
              duration: TOAST_MSG_DURATION,
              verticalPosition: 'top',
              panelClass: ['error'],
            });
          }
        );
    } else {
      this.schedulerControllerService.updateScheduler({ schedulerId: this.schedulerDetails['id'], body: body })
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this._snackBar.open(this.translate.translateErrorMessages('SCHEDULER_UPDATE_SUCCESS'), '', {
              duration: TOAST_MSG_DURATION,
              verticalPosition: 'top',
              panelClass: ['success'],
            });
            this.router.navigate(['schedulers/manage-scheduler']);
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this._snackBar.open(err.error.message, '', {
              duration: TOAST_MSG_DURATION,
              verticalPosition: 'top',
              panelClass: ['error'],
            });
          }
        );
    }

  }

  parseTime(date, timeValue) {
    const formattedDate = this.formatDate(date);
    if (timeValue) {
      const time = timeValue.match(/(\d+)(:(\d\d))?\s*(p?)/);
      date.setHours(Number(time[1]) + ((Number(time[1]) < 12 && time[4]) ? 12 : 0));
      date.setMinutes(Number(time[3]) || 0);
      date.setSeconds(0, 0);
      return formattedDate + ' ' + date.toLocaleTimeString('it-IT');
    }
  }

  formatDate(date) {
    if (date !== undefined) {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    return date;
  }

  parseDateTime(date, time) {
    let modifyDate = date.split('-');
    modifyDate = `${modifyDate[2]}-${modifyDate[1]}-${modifyDate[0]}`;
    if (time.lastIndexOf('00') < 0) {
      time += ':00';
    }
    return modifyDate + ' ' + time;
  }

  onReset() {
    this.schedulerForm.reset();
    this.submitted = false;
  }

}
