<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="certificateIcon" />
        <span>{{'CERTIFICATE_MANAGEMENT' | translate}}</span>
      </span>
    </div>
    <mat-tab-group (selectedTabChange)="matChange($event)">
      <mat-tab [label]="tab.label" *ngFor="let tab of matTabOptions">
      </mat-tab>
    </mat-tab-group>
    <span>
      <app-search-component *ngIf="formData" [comboFieldSearchArray]="formData" [dropDownValues]="dropDownValues"
        (valueSelected)="updateDropdownValues($event)" (searchFieldEmitter)="searchCertEmitter($event)">
      </app-search-component>
    </span>
    <div class="table-container" *ngIf="data">
      <mat-table [dataSource]="data" class="table-data">
        <div *ngFor="let column of columns">
          <ng-container *ngIf="column==='select'" matColumnDef="select">
            <mat-header-cell class="header-style" *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
               (change)="$event ? selection.toggle(row['groupId']) : null"
                [checked]="selection.isSelected(row['groupId'])" [disabled]="selectAllGroups">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="column!=='select'" [matColumnDef]="column">
            <mat-header-cell class="header-style" *matHeaderCellDef>
              <header class="device-table heading-label">{{column | appendSpace | titlecase}}</header>
            </mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName">
              <!-- <span *ngIf="column==='status'">
                <mat-slide-toggle [disabled]="!checkPermission('GRP_EN_ACT') && !checkPermission('GRP_EN_DACT')"
                  [checked]="false" [(ngModel)]="dataName[column]" (change)="gotoConfirmDialog(dataName, 'edit')">
                </mat-slide-toggle>
              </span>
              <span *ngIf="column==='action'">
                <img [src]="deleteImage" class="icon-space modify-button"
                  [title]="'DELETE' | translate" (click)="gotoConfirmDialog(dataName, 'delete')">
              </span> -->
              <span *ngIf="column!=='status'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <!-- <span *ngIf="column==='status'">
                <mat-slide-toggle [checked]="false" (change)="gotoConfirmDialog(dataName, 'edit')">
                </mat-slide-toggle>
              </span> -->
              <span *ngIf="column==='action'">
                <mat-slide-toggle class="side-toggle" [disabled]="!checkPermission('CERT_ACT') && !checkPermission('CERT_DACT')"
                [(ngModel)]="dataName['active']" (change)="gotoConfirmDialog( dataName,'edit')">
              </mat-slide-toggle>
                <button mat-menu-item (click)="downloadCertificate(dataName)" class="download-btn">
                  <img [src]="downloadIcon" class="modify-button icon-space">
                </button> 
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row class="table-header-row" *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator *ngIf="data" [pageSizeOptions]="pageSizeOptions" [length]="certificateCount" [pageSize]="pageSize"
      showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>

