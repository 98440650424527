<div class="quickLinks-overlay">
  <mat-sidenav-container [hasBackdrop]="false">
    <mat-sidenav #sidenav mode="over" class="quickLinks-overlay-container" [fixedInViewport]="true" position="end"
      [(opened)]="opened" (openedChange)="addEventHandler()">
      <section class="aside-nav">
        <h4 class="quick-links-title">{{ 'QUICK_LINKS' | translate }}</h4>
        <ng-container *ngFor="let aside of asideMenus">
          <app-aside-nav-card [asideCard]="aside"></app-aside-nav-card>
        </ng-container>
      </section>
    </mat-sidenav>
    <mat-sidenav-content >
      <button id="toggle-btn" (click)="sidenav.toggle()" [ngClass]="!opened ? 'show-btn':'hide-btn'">
        <mat-icon fontIcon="bosch-ic-rocket"></mat-icon>
      </button>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>