import { Component, OnInit, ViewChild } from '@angular/core';
import {
  WHATSAPP_ICON,
  EDIT_IMAGE,
  DELETE_IMAGE,
  SHOW_KEY,
  HIDE_KEY
} from 'src/app/shared/constants/strings';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';
import {
  WhatsappProfileMappingControllerService,
  WhatsappProfileConnectionDetailsControllerService,
} from 'src/app/services/Whatsapp/services';
import { ActivatedRoute } from '@angular/router';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { BciLoaderService } from '@bci-web-core/core';
import { MatTableDataSource } from '@angular/material/table';
import { TranslationService } from 'src/app/providers/translation-service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-profile-connection',
  templateUrl: './profile-connection.component.html',
  styleUrls: ['./profile-connection.component.scss']
})
export class ProfileConnectionComponent implements OnInit {
  public permissionList: Array<String> = [];
  public whatsappIcon = WHATSAPP_ICON;
  public editImage = EDIT_IMAGE;
  public deleteImage = DELETE_IMAGE;
  public showSpinner:any;
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public vendorId: string;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  public submitted = false;
  public dataSource;
  public columns: any[];
  ignoreList = ['Id'];
  public connectionList :any[];
  editData: any;
  public showKeyImage = SHOW_KEY;
  public hideKeyImage = HIDE_KEY;
  showKey = {};

  constructor(
    private accountControllerService: AccountControllerService,
    private whatsappVendorService: WhatsappProfileMappingControllerService,
    private route: ActivatedRoute,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService,
    private whatsappProfileConnectionDetailsControllerService: WhatsappProfileConnectionDetailsControllerService,
    private translate: TranslationService,
    private loaderService: BciLoaderService,private _matDialog: MatDialog,
  ) {}
  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.getAccount();
    this.getVendors();
  }

  public updateDropdownValues($event) {
    this.submitted = false;
    if ($event.value !== undefined) {
      if ($event.child !== undefined) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'vendor') {
          this.profileId = $event.value;
          this.getVendorByProfileId();
          this.getProfileConnection();
        }
      }
    }
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getVendors() {
    this.whatsappVendorService.getVendorList().subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.dropDownValues['vendor'] = resp;
        } else {
          this.dropDownValues['vendor'] = [];
        }
      },
      (err) => {
        this.dropDownValues['vendor'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getVendorByProfileId() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappVendorService
      .getAllBspMappingByProfileId({ profileId: this.profileId })
      .subscribe(
        (resp) => {
          if (resp && resp['vendorDetails']) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.vendorId = resp['vendorDetails']['id'];
            this.dynamicForm.createForm.controls['vendor'].setValue(this.vendorId);
          }
        },
        (err) => {
          this.vendorId = null;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
        return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
}

  public getProfileConnection() {
    this.whatsappProfileConnectionDetailsControllerService
      .fetchProfileWhatsAppConnectionDetails({ profileId: this.profileId })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
          this.connectionList = resp;
            this.dataSource = new MatTableDataSource<any>(this.connectionList);
            for (const data of this.connectionList) {
              data['action'] = null;
            }
            this.columns = this.filterColumns(Object.keys(this.connectionList[0]));
        }
      },
        (err) => {
          this.columns = null;
          this.connectionList = null;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error',err.error.message);
        });
  }

  private isDataModified(existingValue: any, newValue: any): boolean {
    return existingValue !== newValue;
  }

  public saveProfileConnection() {
    this.showSpinner = this.loaderService.showProgressBar();
    const isId =  this.dynamicForm.createForm.controls['id'].value;
    const existingData = this.editData;
    const isAction = isId ? 'updated' : 'created';
    const resp = {
      profileWhatsAppConnectionDtoList: [{
        connectionKey: 'userId',
        connectionValue: this.dynamicForm.createForm.controls['userId'].value,
        id:isId,
        modified: isAction === 'updated' ? this.isDataModified(existingData.userId, this.dynamicForm.createForm.controls['userId'].value) : true
      },
      {
        connectionKey: 'password',
        connectionValue: this.dynamicForm.createForm.controls['password'].value,
        id:isId,
        modified: isAction === 'updated' ? this.isDataModified(existingData.password, this.dynamicForm.createForm.controls['password'].value) : true
      },
      {
        connectionKey: 'businessMobileNumber',
        connectionValue: this.dynamicForm.createForm.controls['businessMobileNumber'].value,
        id:isId,
        modified: isAction === 'updated' ? this.isDataModified(existingData.businessMobileNumber, this.dynamicForm.createForm.controls['businessMobileNumber'].value) : true
      },
      {
        connectionKey: 'channelName',
        connectionValue: this.dynamicForm.createForm.controls['channel'].value,
        id:isId,
        modified: isAction === 'updated' ? this.isDataModified(existingData.channelName, this.dynamicForm.createForm.controls['channel'].value) : true
      }
    ],
    action: isAction
  };
    const params = {
      profileId: this.profileId,
      body:resp
    };
    this.whatsappProfileConnectionDetailsControllerService
      .saveProfileWhatsAppConnectionDetails(params)
      .subscribe(
        (res) => {
          if (res) {
            this.getProfileConnection();
            this.loaderService.hideProgressBar(this.showSpinner);
            const successMessage = isAction === 'created' ? 'WHATSAPP_CONNECTION_DETAILS_ADD_SUCCESS' : 'WHATSAPP_CONNECTION_DETAILS_UPDATE_SUCCESS';
            this.responseHandlerService.returnToastMessage('success', successMessage); 
             this.dynamicForm.createForm.patchValue({
              channel:'',
              userId: '',
              password: '',
              businessMobileNumber:'',
              id: null,});
          }
          Object.keys(this.dynamicForm.createForm.controls).forEach(key => {
            this.dynamicForm.createForm.get(key).markAsPristine();
            this.dynamicForm.createForm.get(key).markAsUntouched();
          });
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }


  deleteConnection(channelId) {
      let message;
       message = this.translate.translateErrorMessages('DELETE_SELECTED_PROFILE');
      const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
          maxWidth: '400px',
          disableClose: true, data: { message: message }
      });
      dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.showSpinner = this.loaderService.showProgressBar();
            const params = {
              channelId: channelId,
            };
          this.whatsappProfileConnectionDetailsControllerService.deleteChannelWhatsAppConnectionDetails(params).
          subscribe(resp=>{
            this.loaderService.hideProgressBar(this.showSpinner);
            if(resp){
            this.getProfileConnection();
              this.responseHandlerService.returnToastMessage(
                'success','PROFILE_CONNECTION_DETAILS_DELETED_SUCCESSFULLY');
            }
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
            );
              }
           else {
              this.onClose();
          }
      });
  }

  public onClose() {
    this._matDialog.closeAll();
}

  editConnection(data) {
  this.editData= data;
  const modifiedNumber = data['businessMobileNumber'].substring(2); 
  this.editData['businessMobileNumber'] = modifiedNumber;
  this.dynamicForm.createForm.patchValue({
    userId: data['userId'],
    password: data['password'],
    id: data['Id'],
    channel: data['channelName'],
    businessMobileNumber:modifiedNumber
  });
  window.scrollTo({
    top:170,
    left: 0, 
    behavior: 'smooth',
  });
  }

  public showPassword(Id) {
    this.showKey[Id] = !this.showKey[Id];
  }

  public onReset() {
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['vendor'] = [];
    this.accountId = null;
    this.tenantId = null;
    this.profileId = null;
    this.vendorId = null;
    this.dynamicForm.createForm.reset();
    this.columns = null;
    this.connectionList = null;
    this.getAccount();
    this.getVendors();
  }
}
