/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonId } from '../models/common-id';
import { ServiceMasterDto } from '../models/service-master-dto';
import { ServiceReportMapDto } from '../models/service-report-map-dto';

@Injectable({
  providedIn: 'root',
})
export class ServiceMasterControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findTableNames
   */
  static readonly FindTableNamesPath = '/api/v1/service/tableNames';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTableNames()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findTableNames$Response(params?: {
    body?: Array<CommonId>
  }): Observable<StrictHttpResponse<Array<ServiceReportMapDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceMasterControllerService.FindTableNamesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ServiceReportMapDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findTableNames$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findTableNames(params?: {
    body?: Array<CommonId>
  }): Observable<Array<ServiceReportMapDto>> {

    return this.findTableNames$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ServiceReportMapDto>>) => r.body as Array<ServiceReportMapDto>)
    );
  }

  /**
   * Path part for operation getAdditionalServiceListWithSubscriptionFlag
   */
  static readonly GetAdditionalServiceListWithSubscriptionFlagPath = '/api/v1/service/additional';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAdditionalServiceListWithSubscriptionFlag()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalServiceListWithSubscriptionFlag$Response(params?: {
    profileId?: string;
    solutionTypeId?: string;
  }): Observable<StrictHttpResponse<Array<ServiceMasterDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceMasterControllerService.GetAdditionalServiceListWithSubscriptionFlagPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
      rb.query('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ServiceMasterDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAdditionalServiceListWithSubscriptionFlag$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAdditionalServiceListWithSubscriptionFlag(params?: {
    profileId?: string;
    solutionTypeId?: string;
  }): Observable<Array<ServiceMasterDto>> {

    return this.getAdditionalServiceListWithSubscriptionFlag$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ServiceMasterDto>>) => r.body as Array<ServiceMasterDto>)
    );
  }

  /**
   * Path part for operation getDataRetentionServiceList
   */
  static readonly GetDataRetentionServiceListPath = '/api/v1/service/dataretention';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataRetentionServiceList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataRetentionServiceList$Response(params?: {
    allServices?: string;
    onlyReports?: string;
  }): Observable<StrictHttpResponse<Array<ServiceMasterDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ServiceMasterControllerService.GetDataRetentionServiceListPath, 'get');
    if (params) {
      rb.query('allServices', params.allServices, {});
      rb.query('onlyReports', params.onlyReports, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ServiceMasterDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataRetentionServiceList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataRetentionServiceList(params?: {
    allServices?: string;
    onlyReports?: string;
  }): Observable<Array<ServiceMasterDto>> {

    return this.getDataRetentionServiceList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ServiceMasterDto>>) => r.body as Array<ServiceMasterDto>)
    );
  }

}
