import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateValidatorService {
  public today = new Date();

  constructor() { }

  public validateDateRange(fromDateValue,toDateValue) {
    if(fromDateValue && toDateValue) {
      const fromDate = new Date(fromDateValue).getTime();
      const toDate = new Date(toDateValue).getTime();
      const isValidFromDate = !isNaN(fromDate);
      const isValidToDate = !isNaN(toDate);
      if(isValidFromDate && isValidToDate) {
        return fromDate < toDate ? { 'invalidDateRange': false } : { 'invalidDateRange': true };
      }
      return { 'invalidDate': true };
    }
}

public convertUTCToLocalDate(dateString) {
  if(dateString) {
    const UTC = new Date(dateString + 'Z');
    const localDate = UTC.toLocaleString();
    return localDate;
  }
  return null; 
}

public getToday() {
  return this.today.toDateString().substring(0, 10);
}

public getYesterday() {
  const yesterday = new Date();
  yesterday.setDate(this.today.getDate() - 1);
  return yesterday.toDateString().substring(0, 15);
}

public getThisMonthRange() {
  const firstDay = new Date(this.today.getFullYear(), this.today.getMonth(), 1);
  const lastDay = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 1);
  return `${firstDay.toDateString().substring(4,10)} - ${lastDay.toDateString().substring(4,10)}`;
}

public getLastMonthRange() {
  const firstDayPrevMonth = new Date(this.today.getFullYear(), this.today.getMonth() - 1, 1);
  const lastDayPrevMonth = new Date(this.today.getFullYear(), this.today.getMonth(), 0);
  return `${firstDayPrevMonth.toDateString().substring(4,10)} - ${lastDayPrevMonth.toDateString().substring(4,10)}`;
}

public getLastSevenDaysRange() {
  const fromDate = new Date();
  fromDate.setDate(this.today.getDate() - 7);
  const toDate = new Date();
  toDate.setDate(this.today.getDate() - 1);
  return `${fromDate.toDateString().substring(4, 15)} - ${toDate.toDateString().substring(4, 15)}`;
}

public getLastThirtyDaysRange() {
  const fromDate = new Date();
  fromDate.setDate(this.today.getDate() - 30);
  const toDate = new Date();
  toDate.setDate(this.today.getDate() - 1);
  return `${fromDate.toDateString().substring(4, 15)} - ${toDate.toDateString().substring(4, 15)}`;
}

public getCustomDateRange(fromDt,toDt,includeCurrentDate = true) {
  let fromDate = fromDt;
  let toDate = toDt;
  fromDate = new Date(fromDate);
  toDate = new Date(toDate);
  if(!includeCurrentDate) {
    toDate.setDate(toDate.getDate() - 1);
  }
  return `${fromDate.toDateString().substring(4, 15)} - ${toDate.toDateString().substring(4, 15)}`;
}

public getLastOneEightyDaysRange() {
  const fromDate = new Date();
  fromDate.setDate(this.today.getDate() - 180);
  const toDate = new Date();
  toDate.setDate(this.today.getDate() - 1);
  return `${fromDate.toDateString().substring(4, 15)} - ${toDate.toDateString().substring(4, 15)}`;
}

public getLastThreeSixtyFiveDaysRange() {
  const fromDate = new Date();
  fromDate.setDate(this.today.getDate() - 365);
  const toDate = new Date();
  toDate.setDate(this.today.getDate() - 1);
  return `${fromDate.toDateString().substring(4, 15)} - ${toDate.toDateString().substring(4, 15)}`;
}

}
