<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="accessKeyIcon" />
        <span>{{'ACCESS_KEY_CONFIGURATION' |translate}}</span>
      </span>
    </div>
    <div class="page-container-margin">
      <mat-form-field *ngFor="let dropdownItem of dropdownList" class="dropdown-width">
        <mat-label class="label">{{dropdownItem.label | translate}}
          <span class="mandatory">*</span>
        </mat-label>
        <mat-select class="dropdown-style" [(ngModel)]="selectedValues[dropdownItem.dropdownName]" [id]="dropdownItem.id"
          (selectionChange)="updateDropdownValue(dropdownItem,$event)" [placeholder]="'SELECT' | translate">
          <mat-option>{{ 'SELECT' | translate }}</mat-option>
          <mat-option *ngFor="let value of dropdownValues[dropdownItem.dropdownName]" [value]="value.id" required>
            {{value.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div class="clear-float"></div>
      <form [formGroup]="generateForm">
        <div>
          <mat-label class="label expiry-email-label">{{'EXPIRY_EMAIL_ID' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <div formArrayName="emailList">
            <div *ngFor="let group of generateForm.get('emailList')['controls']; let indexNumber=index">
              <mat-form-field class="text-width" floatLabel='never'>
                <input type="text" matInput formControlName="{{indexNumber}}" id="emailId{{indexNumber}}"
                  class="form-field" [placeholder]=" 'TYPE_HERE' | translate" />
              </mat-form-field>
              <span>
                <button *ngIf="!(generateForm.get('emailList')['controls'].length >= 5) " type="button" mat-icon-button color="primary" aria-label="Add"
                class="action-button-font" (click)="addEmailControl()">+</button>
                <button *ngIf="generateForm.get('emailList')['controls'].length > 1 " mat-icon-button color="primary"
                  class="action-button-font" aria-label="Remove/clear" (click)="removeEmail(indexNumber)">-</button>
              </span>
              <div
                *ngIf="submitted || generateForm.controls['emailList'].controls[indexNumber].touched && generateForm.controls['emailList'].controls[indexNumber].errors">
                <div *ngIf="generateForm.controls['emailList'].controls[indexNumber].errors?.required">
                  <mat-error class="mat-error-class">
                    {{ 'EMAIL' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
                <mat-error class="mat-error-class" *ngIf="generateForm.controls['emailList'].controls[indexNumber].errors?.pattern">
                  {{ 'PLEASE_ENTER_A_VALID_EMAIL_ID' | translate}}
                </mat-error>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="clear-float"></div>
      <div>
        <span class="section" >
          <button mat-raised-button class="generate-btn"
            [disabled]="!((selectedValues['solution'] || multipleKeys) && (this.generateForm.status === 'VALID'))"
            (click)="gotoEditDialog(keysContent, 'generate')">{{ (multipleKeys? 'ADD_NEW': 'GENERATE')  | translate }}</button>
          <button *ngIf="multipleKeys" (click)="configureEmails()" mat-button class="update-button button">{{
            'UPDATE_EMAILS' | translate }}</button>
        </span>
      </div>
      <div class="clear-float"></div>
      <mat-table [dataSource]="dataSource" class="table-data" *ngIf="dataSource">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef [ngClass]="determineColumnSize(column)">{{column | appendSpace | titlecase | translate}}
            </mat-header-cell>
            <mat-cell class="tooltip table-cells" *matCellDef="let dataName; index as i" [ngClass]="determineColumnSize(column)">
                <span *ngIf="column !== 'accessKey'" title="{{dataName[column]}}" class="key-cell">
                  <span>{{dataName[column]}}</span>
                </span>
                <input *ngIf="column ==='accessKey'" [type]="showKey[dataName['id']]?'text':'password'" [value]="dataName[column]" matInput
                    class="access-key-input" id="{{dataName['id']}}" />
                <span *ngIf="column==='action'" class="actions-column">
                  <div [class.disabled]="!accessKeyProperties['solutionAdminKey']">
                    <img [src]="showKey[dataName['id']]?hideKeyImage:showKeyImage" class="access-key-icons"
                      [title]="(showKey[dataName['id']]? 'HIDE_KEY': 'SHOW_KEY') |translate" (click)="showAccessKey(dataName['id'])" />
                    <img [src]="copyImage" class="access-key-icons" [title]="'COPY' |translate" (click)="copyText(dataName['id'])"
                      [class.disabled]="!showKey[dataName['id']]" />
                    <img [src]="refreshImage" class="access-key-icons" [title]="'REGENERATE' |translate"
                      (click)="gotoConfirmDialog(dataName,'regenerate')" />
                    <img [src]="deleteImage" class="access-key-icons" [title]="'DELETE' |translate"
                      (click)="gotoConfirmDialog(dataName,'delete')" />
                    <img [src]="editImage" class="access-key-icons" [title]="'EDIT' |translate"
                      (click)="gotoEditDialog(keysContent,'edit',dataName)" class="modify-button">
                  </div>
                </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>

      <div class="clear-float"></div>    
      <div class="managerial-services-checkbox" *ngIf="accessKeyProperties['solutionAdminKey']">
        <mat-label class="label">{{'MANAGERIAL_SERVICES' | translate}}
        </mat-label>
        <button mat-raised-button class="button btn-background" (click)="gotoEditDialog(keysContent, 'managerial')" >
          {{'SUBSCRIBE' | translate}}
        </button>
      </div>
    </div>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
<ng-template #keysContent let-modal let-data>
  <div class="close-btn"><img  class="rb-ic" (click)="onClose()"  [src]="closeIcon"/></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{ data.title | translate }}</h5>
    </div>
    <div *ngIf="data.body ==='edit'" class="modal-body">
      <app-dynamic-form [dropDownValues]="dropdownValues" [jsonFile]="jsonFileLoaded" [formData] ="editData"
        [columnsInForm]="single" (valueSelected)="updateDropdownValue('dynamicform', $event)"
        (formEmitter)="editAccessKey($event)">
      </app-dynamic-form>
    </div>
    <div *ngIf=" data.body ==='managerial'"  class="modal-body">
      <div>
        <mat-selection-list #keylist [(ngModel)]="keysList">
          <mat-list-option *ngFor="let key of dataSource" [value]="key.id">
            {{key.name}}
          </mat-list-option>
        </mat-selection-list>
      </div>
      <section class="section">
        <button mat-raised-button class="button btn-background" (click)="onManagerialServicesChecked()">
          {{'SAVE' | translate }}</button>
      </section>
    </div>
    <div *ngIf="data.body ==='generate'" class="modal-body">
      <app-dynamic-form [dropDownValues]="dropdownValues" [jsonFile]="jsonFileLoaded"
        [columnsInForm]="single" (valueSelected)="updateDropdownValue('dynamicform', $event)"
        (formEmitter)="generateAccessKey($event)">
      </app-dynamic-form>
      
    </div>
  </div>
</ng-template>