<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="smsIcon" />
        <span>{{ 'BULK_SMS' | translate }}</span>
      </span>
    </div>

    <mat-tab-group class="demo-tab-group stretched-tabs" mat-stretch-tabs [(selectedIndex)]="selectedIndex">
      <div *ngFor="let tab of configurationsList">
        <mat-tab *ngIf="checkPermission(tab['permission'])">
          <ng-template mat-tab-label>
            <div>{{ tab["title"] | translate }}</div>
          </ng-template>
          <section class="tab-body">
            <span *ngIf="tab['name'] === 'create-job'">
              <app-dynamic-form [jsonFile]="jsonFileLoaded" [fileTransfer]="true"
                [columnsInForm]="'multi'" [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)"
                (formEmitter)="onUpload($event,UploadContent)" (buttonClickEvent)="onDownload()">
              </app-dynamic-form>
              <button (click)="onDownload()" mat-raised-button class="button save-continue-button button-submit"> {{'DOWNLOAD_TEMPLATE' | translate}}</button>
              
              <ng-template #UploadContent let-modal>
                <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
                <div class="modal-width">
                  <div>
                    <div class="modal-header">
                      <h5 class="modal-title" id="modal-basic-title">
                        {{ 'FILE_UPLOAD' | translate }}
                      </h5>
                    </div>
                    <div class="modal-body">
                      <input type="file" class="file-uploader" id="file" (change)="selectFiles($event)" />
                    </div>
                    <div class="modal-footer">
                      <button [disabled]="!fileForUpload" (click)="uploadExcelFile()" mat-raised-button
                        class="button-submit">
                        {{ 'SUBMIT' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </span>

            <span *ngIf="tab['name'] === 'manage-job'">

              <app-search-component *ngIf="bulkSmsData" [comboFieldSearchArray]="bulkSmsData"
              [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)"
              (searchFieldEmitter)="getSmsJobs()" [searchData]="searchCriteria">
            </app-search-component>

              <mat-table [dataSource]="bulkSmsTableData" class="table-data">
                <div *ngFor="let column of bulkSmsColumns">
                  <ng-container [matColumnDef]="column">
                    <mat-header-cell *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
                    </mat-header-cell>
                    <mat-cell class="tooltip" *matCellDef="let dataName; index as i">
                      <span *ngIf="column !== 'action'" title="{{ dataName[column] | translate }}" class="cell-value">
                        {{ dataName[column] | translate }}
                      </span>
                      <span *ngIf="column === 'action'" class="action-buttons">
                          <img *ngIf="dataName['status'] !== 'Canceled'" class="action-icons" [src]="downloadImage" [title]="'DOWNLOAD_FILE' | translate" (click)="downloadFile(dataName['jobId'])" >
                          <img *ngIf="checkPermission('SMS_BULK_R') && dataName['status'] === 'Yet To Start'" class="action-icons cancel-icon" [src]="rejectImage" [title]="'CANCEL' | translate" (click)="gotoConfirmDialog(dataName['jobId'])">
                          <span *ngIf="dataName['status'] === 'Completed'" class="action-icons">
                            <a (click)="displayPieChart(dataName)">{{ 'STATUS' | translate }}</a>
                          </span>
                      </span>
                    </mat-cell>
                  </ng-container>
                </div>
                <mat-header-row *matHeaderRowDef="bulkSmsColumns"></mat-header-row>
                <mat-row *matRowDef="let data; columns: bulkSmsColumns"></mat-row>
              </mat-table>
            </span>
          </section>
        </mat-tab>
      </div>
    </mat-tab-group>
  </section>
</section>
