<section class="overview-container" >
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="deviceIcon" />
        <span>{{'DEVICE_MANAGEMENT' | translate}}</span>
      </span>
    </div>
    <mat-tab-group [selectedIndex]="getSelectedIndex()" (selectedTabChange)="matChange($event)" #tabGroup>
      <mat-tab [label]="tab.label" *ngFor="let tab of matTabOptions">
      </mat-tab>
    </mat-tab-group>
    <span>
      <app-search-component *ngIf="formData" [comboFieldSearchArray]="formData" [dropDownValues]="dropDownValues"
        [selectionCriteria]="selectionCriteria" [selectedTab]="selectedTab"
        (valueSelected)="updateDropdownValues($event)" (searchFieldEmitter)="searchDevicesEmitter($event)">
      </app-search-component>
    </span>
    <!-- activation/de-activation/deletion of multiple devices to be taken up in next phase -->
    <!-- <span>
      <button  [disabled]="!checkPermission('IND_EN_U')" (click)="gotoModal(popup, null, 'activate')" mat-raised-button class="manage-action-btn">{{ 'ACTIVATE' | translate }}</button>
      <button  [disabled]="!checkPermission('IND_EN_U')" (click)="gotoModal(popup, null, 'deactivate')" class="manage-action-btn" mat-raised-button>{{ 'DE-ACTIVATE' | translate }}</button>
      <button  [disabled]="!checkPermission('IND_EN_DEL')" (click)="deleteAccount($event)" mat-raised-button color="warn" class="manage-action-btn">{{ 'DELETE' | translate }}</button>
    </span> -->
    <div class="table-container" *ngIf="data">
      <div class="fota-btn" *ngIf="currentTabValue === 'solution'">
        <!-- <button mat-raised-button class="button btn-background" *ngIf="hideFotaBtn" (click)="selectDevices()">
          <span *ngIf="!selectAllDevices">{{ 'SELECT_ALL' | translate }}</span>
          <span *ngIf="selectAllDevices">{{ 'UNSELECT_ALL' | translate }}</span>
        </button> -->
        <button mat-raised-button class="button btn-background" [class.disabled]="!selection.hasValue()" *ngIf="hideFotaBtn"
          (click)="createFota()">
          <span>{{ 'FOTA' | translate }}</span>
        </button>
        <button mat-raised-button class="button btn-background" (click)="syncDevicesQueue()">
          <span>{{ 'SYNC' | translate }}</span>
        </button>
        <button mat-raised-button class="button btn-width btn-background" [class.disabled]="!selection.hasValue()" *ngIf="hideFotaBtn"
        (click)="openModal(content)">
        <span>{{ 'MANAGE_TAGS' | translate }} </span>
      </button>
      </div>
      <div class="column-list">
          <mat-form-field>
            <mat-label class="label">{{'SELECT_COLUMNS'| translate}}</mat-label>
              <mat-select multiple (ngModelChange)="getSelectedColumns($event.value)" [(value)]="columns"  > 
                  <mat-option *ngFor="let column of defaultColumns" [value]="column"
                   [disabled]="(column==='serialNo'||column==='action')">{{ column | translate }}</mat-option>
              </mat-select>
          </mat-form-field>
      </div>
      <mat-table [dataSource]="data" class="table-data">
        <div *ngFor="let column of columns">
          <ng-container *ngIf="column==='select'" matColumnDef="select">
            <mat-header-cell  class="column-small" *matHeaderCellDef>
                 <mat-checkbox (click)="$event.stopPropagation()"  (change)="selectDevices()"    [checked]="selectAllDevices" >  </mat-checkbox>
                        </mat-header-cell>
            <mat-cell  class="column-small" *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row['serialNo']) : null; selectAllDevicesCheckBox($event,row['serialNo'])"
                [checked]="selection.isSelected(row['serialNo'])" >
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="column!=='select'" [matColumnDef]="column">
            <mat-header-cell [ngClass] = "determineColumnSize(column)" *matHeaderCellDef>
              <header class="device-table heading-label">
                {{column | appendSpace | titlecase | translate}}</header>
            </mat-header-cell>
            <mat-cell class="tooltip" [ngClass] = "determineColumnSize(column)" *matCellDef="let dataName">
              <span *ngIf="column==='status'">
                <mat-slide-toggle [disabled]="!checkPermission('IND_EN_ACT') && !checkPermission('IND_EN_DACT')"
                  [checked]="false" [(ngModel)]="dataName[column]" (change)="gotoConfirmDialog(dataName, 'edit')">
                </mat-slide-toggle>
              </span>
              <span *ngIf="column === 'FOTAApplicable'">
                <img *ngIf="dataName[column]; else displayNo" [src]="fotaImage" />
                <ng-template #displayNo> {{ 'NO' | translate }}</ng-template>
              </span>
              <span *ngIf="column === 'state'">
                <img *ngIf="dataName[column]==='CONNECTED'" [src]="connectedImage" class="state-icon" [title]="dataName[column]" />
                <img *ngIf="dataName[column]==='DISCONNECTED'" [src]="disconnectedImage" class="state-icon" [title]="dataName[column]"/>
                <img *ngIf="dataName[column]==='REGISTERED'" class="registered-icon" [src]="registeredImage" [title]="dataName[column]" />
              </span>
              <span *ngIf="column === 'interfaceProvider'">
                <img *ngIf="dataName[column]==='BOSCH_IoT_SUITE'" class="provider-icon" [src]="boschIotIcon" [title]="dataName[column]" />
                <img *ngIf="dataName[column]==='AZURE'" class="provider-icon" [src]="azureIcon" [title]="dataName[column]"/>
                <img *ngIf="dataName[column]==='AWS_IoT_Core'" class="provider-icon" [src]="awsIcon" [title]="dataName[column]"/>
                <img *ngIf="dataName[column]==='ZELIOT'" class="provider-icon" [src]="zeliotIcon" [title]="dataName[column]"/>
              </span>
              <span *ngIf="column==='action'">
                <button mat-icon-button [matMenuTriggerFor]="menu">
                  <img [src]="moreOptionsImage">
                </button>
                <mat-menu #menu="matMenu">
                  <div *ngFor="let action of dataName['devicePropertySupport']">
                    <button mat-menu-item *ngIf="action['devicePropertyName'] == 'RESTART'"
                      (click)="gotoConfirmDialog(dataName, 'restart')" [disabled]="!action['active']">
                      <img [class.disabled]="!action['active']" [src]="restartImage" class="icon-space"
                        class="modify-button">
                      <span>{{ action['devicePropertyName'] | translate }}</span>
                    </button>
                    <button mat-menu-item *ngIf="action['devicePropertyName'] == 'REBOOT'"
                      (click)="gotoConfirmDialog(dataName, 'reboot')" [disabled]="!action['active']">
                      <img [class.disabled]="!action['active']" [src]="rebootImage" class="icon-space"
                        class="modify-button">
                      <span>{{ action['devicePropertyName'] | translate }}</span>
                    </button>
                    <button mat-menu-item *ngIf="action['devicePropertyName'] == 'D_PROP_UPDATE'"
                      (click)="gotoConfirmDialog(dataName, 'configure')" [disabled]="!action['active']">
                      <img [class.disabled]="!action['active']" [src]="configureImage" class="icon-space"
                        class="modify-button">
                      <span>{{ 'CONFIGURE' | translate }}</span>
                    </button>
                    <button mat-menu-item *ngIf="action['devicePropertyName'] == 'DEVICE_TUNNELING' &&
                      checkPermission('DEV_TUNN_CLL') && dataName['state'] === 'CONNECTED' && action['active']" 
                      (click)="gotoConfirmDialog(dataName, 'tunnel')" >
                      <img  [src]="deviceTunnelIcon" class="icon-space" class="modify-button">
                    <span>{{ 'TUNNEL' | translate }}</span>
                    </button>
                    <button mat-menu-item *ngIf="action['devicePropertyName'] == 'DEVICE_STATISTICS' &&
                    checkPermission('DEV_MTD_CLL') && action['active']"
                    (click)="gotoConfirmDialog(dataName, 'stats')" >
                    <img  [src]="deviceStatsIcon" class="icon-space" class="modify-button">
                  <span>{{ 'STATISTICS' | translate }}</span>
                  </button>
                  </div>
                  <button mat-menu-item *ngIf="checkPermission('DEV_MTD_CLL')"
                    (click)="gotoConfirmDialog(dataName, 'c2d')">
                    <img [src]="c2dIcon" class="icon-space c2d-icon" class="modify-button">
                    <span>{{ 'C2D' | translate }}</span>
                  </button>
                  <button mat-menu-item *ngIf="checkPermission('DEV_DEL')"
                    (click)="gotoConfirmDialog(dataName, 'delete')">
                    <img [src]="deleteImage" class="icon-space" class="modify-button">
                    <span>{{ 'DELETE' | translate }}</span>
                  </button>
                 </mat-menu>
              </span>
              <span *ngIf="column!=='status' && column !== 'FOTAApplicable' && column !== 'state' && column !== 'interfaceProvider'" title="{{dataName[column]}}"
                class="cell-value">
                {{dataName[column]}}
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row class="table-header-row" *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator *ngIf="data" [pageSizeOptions]="pageSizeOptions" [length]="deviceCount" [pageSize]="pageSize"
      showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
<ng-template #content let-modal>
  <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{'MANAGE_TAGS' | translate}} </h5>
    </div>
    <div class="modal-body">
      <app-static-tags (availableTags)="populateExistingTags()"
         (selectedTags)="addOrRemoveTags($event)"> 
      </app-static-tags>
    </div>
  </div>

</ng-template>