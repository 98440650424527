/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AccessKeyDto } from '../models/access-key-dto';

@Injectable({
  providedIn: 'root',
})
export class SolutionAdminAccesskeyLifeCycleControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAccessKeyDetails1
   */
  static readonly GetAccessKeyDetails1Path = '/api/v1/solutiontype/{solutionTypeId}/accessKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessKeyDetails1()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyDetails1$Response(params: {
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<Array<AccessKeyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.GetAccessKeyDetails1Path, 'get');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AccessKeyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessKeyDetails1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyDetails1(params: {
    solutionTypeId: string;
  }): Observable<Array<AccessKeyDto>> {

    return this.getAccessKeyDetails1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AccessKeyDto>>) => r.body as Array<AccessKeyDto>)
    );
  }

  /**
   * Path part for operation createAccessKeyForSolutionType
   */
  static readonly CreateAccessKeyForSolutionTypePath = '/api/v1/solutiontype/{solutionTypeId}/accessKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccessKeyForSolutionType()` instead.
   *
   * This method doesn't expect any request body.
   */
  createAccessKeyForSolutionType$Response(params: {
    solutionTypeId: string;
    expiryDuration: string;
    keyName: string;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.CreateAccessKeyForSolutionTypePath, 'post');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.query('expiryDuration', params.expiryDuration, {});
      rb.query('keyName', params.keyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAccessKeyForSolutionType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createAccessKeyForSolutionType(params: {
    solutionTypeId: string;
    expiryDuration: string;
    keyName: string;
  }): Observable<{
}> {

    return this.createAccessKeyForSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation subscribeManagerialServicesForSolutionType
   */
  static readonly SubscribeManagerialServicesForSolutionTypePath = '/api/v1/accessKeyForSolution/{accessKeyId}/managerialServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscribeManagerialServicesForSolutionType()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeManagerialServicesForSolutionType$Response(params: {
    accessKeyId: string;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.SubscribeManagerialServicesForSolutionTypePath, 'post');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscribeManagerialServicesForSolutionType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeManagerialServicesForSolutionType(params: {
    accessKeyId: string;
  }): Observable<{
}> {

    return this.subscribeManagerialServicesForSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation unsubscribeManagerialServicesForSolution
   */
  static readonly UnsubscribeManagerialServicesForSolutionPath = '/api/v1/accessKeyForSolution/{accessKeyId}/managerialServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unsubscribeManagerialServicesForSolution()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsubscribeManagerialServicesForSolution$Response(params: {
    accessKeyId: string;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.UnsubscribeManagerialServicesForSolutionPath, 'delete');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unsubscribeManagerialServicesForSolution$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsubscribeManagerialServicesForSolution(params: {
    accessKeyId: string;
  }): Observable<{
}> {

    return this.unsubscribeManagerialServicesForSolution$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getExpiryEmailForAccesskey
   */
  static readonly GetExpiryEmailForAccesskeyPath = '/api/v1/solutiontype/{solutionTypeId}/accessKey/emails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getExpiryEmailForAccesskey()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpiryEmailForAccesskey$Response(params: {
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.GetExpiryEmailForAccesskeyPath, 'get');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getExpiryEmailForAccesskey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getExpiryEmailForAccesskey(params: {
    solutionTypeId: string;
  }): Observable<Array<string>> {

    return this.getExpiryEmailForAccesskey$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation configureExpiryEmailForAccesskey
   */
  static readonly ConfigureExpiryEmailForAccesskeyPath = '/api/v1/solutiontype/{solutionTypeId}/accessKey/emails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `configureExpiryEmailForAccesskey()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  configureExpiryEmailForAccesskey$Response(params: {
    solutionTypeId: string;
    body?: Array<string>
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.ConfigureExpiryEmailForAccesskeyPath, 'post');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `configureExpiryEmailForAccesskey$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  configureExpiryEmailForAccesskey(params: {
    solutionTypeId: string;
    body?: Array<string>
  }): Observable<{
}> {

    return this.configureExpiryEmailForAccesskey$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation getAccessKeyAndSolutionIdForSolutionName
   */
  static readonly GetAccessKeyAndSolutionIdForSolutionNamePath = '/api/v1/solutiontypeName/{solutionName}/accessKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessKeyAndSolutionIdForSolutionName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyAndSolutionIdForSolutionName$Response(params: {
    solutionName: string;
  }): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.GetAccessKeyAndSolutionIdForSolutionNamePath, 'get');
    if (params) {
      rb.path('solutionName', params.solutionName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: string;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessKeyAndSolutionIdForSolutionName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKeyAndSolutionIdForSolutionName(params: {
    solutionName: string;
  }): Observable<{
[key: string]: string;
}> {

    return this.getAccessKeyAndSolutionIdForSolutionName$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>) => r.body as {
[key: string]: string;
})
    );
  }

  /**
   * Path part for operation deleteAccessKeyForSolutionType
   */
  static readonly DeleteAccessKeyForSolutionTypePath = '/api/v1/accessKeyForSolution/{accessKeyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAccessKeyForSolutionType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccessKeyForSolutionType$Response(params: {
    accessKeyId: string;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.DeleteAccessKeyForSolutionTypePath, 'delete');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteAccessKeyForSolutionType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccessKeyForSolutionType(params: {
    accessKeyId: string;
  }): Observable<{
}> {

    return this.deleteAccessKeyForSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation regenerateAccessKeyForSolutionType
   */
  static readonly RegenerateAccessKeyForSolutionTypePath = '/api/v1/accessKeyForSolution/{accessKeyId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `regenerateAccessKeyForSolutionType()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAccessKeyForSolutionType$Response(params: {
    accessKeyId: string;
    expiryDuration?: string;
    action: string;
    keyName?: string;
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.RegenerateAccessKeyForSolutionTypePath, 'patch');
    if (params) {
      rb.path('accessKeyId', params.accessKeyId, {});
      rb.query('expiryDuration', params.expiryDuration, {});
      rb.query('action', params.action, {});
      rb.query('keyName', params.keyName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `regenerateAccessKeyForSolutionType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  regenerateAccessKeyForSolutionType(params: {
    accessKeyId: string;
    expiryDuration?: string;
    action: string;
    keyName?: string;
  }): Observable<{
}> {

    return this.regenerateAccessKeyForSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation checkForExpiryOfSolutionAdminAccessKeys
   */
  static readonly CheckForExpiryOfSolutionAdminAccessKeysPath = '/api/v1/checkForSolutionAdminExpiredAccessKeys';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `checkForExpiryOfSolutionAdminAccessKeys()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkForExpiryOfSolutionAdminAccessKeys$Response(params?: {
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionAdminAccesskeyLifeCycleControllerService.CheckForExpiryOfSolutionAdminAccessKeysPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `checkForExpiryOfSolutionAdminAccessKeys$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  checkForExpiryOfSolutionAdminAccessKeys(params?: {
  }): Observable<{
}> {

    return this.checkForExpiryOfSolutionAdminAccessKeys$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

}
