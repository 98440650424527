<section class="overview-container" >
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="deviceIcon" />
        <span>{{'GROUP_DEVICE_REGISTRATION' |translate}}</span>
      </span>
   </div>
    <div class="create-form-spacing">
      <p class="disclaimer" *ngIf="displayInfoMsg">{{'INFO_MSG_FOR_INDIVIDUAL_AND_GROUP_REGISTRATION' | translate}}</p>
      <form [formGroup]="groupRegistrationForm" class="create-form-spacing form-fields">
        <div>
          <mat-form-field>
            <mat-label class="label">{{'GROUP_NAME' | translate}} <span class="mandatory">*</span>
            </mat-label>
            <input type="text" matInput formControlName="groupName" class="form-field"
              [placeholder]=" 'TYPE_HERE' | translate" pattern="^[a-zA-Z0-9\\-]+$"/>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.groupName?.touched && groupRegistrationForm.controls.groupName?.errors">
            <div *ngIf="groupRegistrationForm.controls.groupName?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'GROUP_NAME' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
            <div *ngIf="groupRegistrationForm.controls.groupName?.errors?.maxlength">
              <mat-error class="mat-error-class">
                {{ 'GROUP_NAME_SHOULD_BE_OF_MAXIMUM_50_CHARACTERS'| translate }}
              </mat-error>
            </div>
            <div *ngIf="groupRegistrationForm.controls.groupName?.errors?.pattern">
              <mat-error class="mat-error-class">
                {{ 'PLEASE_ENTER_A_VALID_GROUP_NAME'| translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'ACCOUNT' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="account"  [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'tenant')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['account']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.account?.touched && groupRegistrationForm.controls.account?.errors">
            <div *ngIf="groupRegistrationForm.controls.account?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'ACCOUNT' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'TENANT' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="tenant" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'businessProfile')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['tenant']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.tenant?.touched && groupRegistrationForm.controls.tenant?.errors">
            <div *ngIf="groupRegistrationForm.controls.tenant?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'TENANT' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'BUSINESS_PROFILE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="businessProfile" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'solutionType')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['businessProfile']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.businessProfile?.touched && groupRegistrationForm.controls.businessProfile?.errors">
            <div *ngIf="groupRegistrationForm.controls.businessProfile?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'BUSINESS_PROFILE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'SOLUTION_TYPE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="solutionType" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'provider')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['solutionType']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.solutionType?.touched && groupRegistrationForm.controls.solutionType?.errors">
            <div *ngIf="groupRegistrationForm.controls.solutionType?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'SOLUTION_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'DEVICE_INTERFACE_PROVIDER' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="provider" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'iotHub')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['provider']" [value]="val.id">
                {{val.name | translate}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.provider?.touched && groupRegistrationForm.controls.provider?.errors">
            <div *ngIf="groupRegistrationForm.controls.provider?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'DEVICE_INTERFACE_PROVIDER' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>  
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'IOT_HUB' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="iotHub" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'deviceType')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['iotHub']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.iotHub?.touched && groupRegistrationForm.controls.iotHub?.errors">
            <div *ngIf="groupRegistrationForm.controls.iotHub?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'IOT_HUB' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'DEVICE_TYPE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="deviceType" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'attestationType')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['deviceType']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.deviceType?.touched && groupRegistrationForm.controls.deviceType?.errors">
            <div *ngIf="groupRegistrationForm.controls.deviceType?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'DEVICE_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'ATTESTATION_TYPE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="attestationType" [placeholder]=" 'SELECT' | translate"
             (selectionChange)="provider['name']=== env['providerAzure']? updateDropdownValues($event,'criteria'):  updateDropdownValues($event,'category')">
              <mat-option *ngIf="provider && provider['name']!== env['providerZelIot']">{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['attestationType']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.attestationType?.touched && groupRegistrationForm.controls.attestationType?.errors">
            <div *ngIf="groupRegistrationForm.controls.attestationType?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'ATTESTATION_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field class="mat-form-field">
            <mat-label class="label">{{'TAGS' | translate}}</mat-label>
            <mat-chip-list #chipList>
              <mat-chip *ngFor="let val of selectedValuesArray" class="mat-chip" [selectable]="selectable"
              [removable]="removable" (removed)="removeEntities(val)" [value]="val">
                {{val | translate }}
                <mat-icon matChipRemove *ngIf="removable" class="rb-ic" fontIcon="bosch-ic-close"></mat-icon>
              </mat-chip>
            </mat-chip-list>
            <input class="mat-chip-input"[formControl]="tags" [placeholder]="'TYPE_HERE' | translate" #inputCtrl [matAutocomplete]="auto"
                [matChipInputFor]="chipList" 
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                (matChipInputTokenEnd)="addValues($event)">
            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectEntitiesFromList($event)">
              <mat-option *ngFor="let val of filteredStrings | async "  [value]="val">
                {{val | translate}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
      </div>
        <div class="form-group form-check master-checkbox form-field" *ngIf="provider && provider['name'] === env['providerAws']">
          <mat-label class="label">{{'WITH_CA_CERT' | translate}}</mat-label>
          <mat-checkbox class="input-checkbox" formControlName="enrollmentWithCa" [(ngModel)]="enrollmentWithCert"
              (change)="getChkBoxValue($event)" >
          </mat-checkbox>
          <div *ngIf="submitted ||groupRegistrationForm.controls.enrollmentWithCa?.touched && groupRegistrationForm.controls.enrollmentWithCa?.errors">
              <div *ngIf="groupRegistrationForm.controls.enrollmentWithCa?.errors?.required">
                {{'WITH_CA_CERT' | translate}} {{'IS_REQUIRED' | translate }}
              </div>
          </div>
      </div>
        <div *ngIf ="provider && (provider['name'] === env['providerAzure'] || (provider['name'] === env['providerAws'] && enrollmentWithCert))">
          <mat-form-field class="upload-type">
            <mat-label class="label">{{ 'UPLOAD_TYPE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="criteria" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event)">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['criteria']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="submitted || groupRegistrationForm.controls.criteria?.touched && groupRegistrationForm.controls.criteria?.errors">
            <div *ngIf="groupRegistrationForm.controls.criteria?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'UPLOAD_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div *ngIf = "provider && provider['name'] === env['providerBosch']">
          <mat-form-field>
            <mat-label class="label">{{ 'DEVICE_CATEGORY' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="category" [placeholder]=" 'SELECT' | translate"
             (selectionChange)="updateDropdownValues($event, 'sampleFile')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['category']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.category?.touched && groupRegistrationForm.controls.category?.errors">
            <div *ngIf="groupRegistrationForm.controls.category?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'DEVICE_CATEGORY' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div *ngIf="((displayCertInput && provider && provider['name'] === env['providerAzure']) ||
        (displayCertInput && provider && provider['name'] === env['providerAws'] && enrollmentWithCert))" >
          <mat-form-field floatLabel="always">
            <mat-label class="label">{{(provider['name']=== env['providerAws']?'CA_CERTIFICATE':'SIGNER_CERTIFICATE') | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <textarea class="form-control text-area dynamic-input" matInput formControlName="signerCert"
                [placeholder]="'TYPE_HERE' | translate" rows="4">
            </textarea>
        </mat-form-field>
          <div
            *ngIf="submitted || groupRegistrationForm.controls.signerCert?.touched && groupRegistrationForm.controls.signerCert?.errors">
            <div *ngIf="groupRegistrationForm.controls.signerCert?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'SIGNER_CERTIFICATE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div  class="upload_File" *ngIf="((displayFileInput && provider && provider['name'] === env['providerAzure']) ||
        (displayFileInput && provider && provider['name'] === env['providerAws'] && enrollmentWithCert))">
          <input type="file" fromControlName="base64Certificate" #inputCertificate class="select-file" id="file"
              (click)="inputCertificate.value = null"
              (change)="convertTobase64($event)"/>
          <label class="input-label" for="file">{{ 'UPLOAD_CERTIFICATE' | translate }}</label>
          <div class="files-list">
            <div class="single-file" *ngFor="let file of files; let indexNumber = index">
                <div class="file-icon">
                    <img [src]="documentImage" />
                </div>
                <div class="info">
                    <h4 class="name"> {{ file?.name }} </h4>
                    <p class="size"> {{ formatBytes(file?.size) }} </p>
                </div>
                <div class="delete" (click)="removeFile(indexNumber, file?.type, 'cacert')">
                    <img [src]="deleteImage" class="modify-button">
                </div>
            </div>
        </div>
          <div *ngIf="submitted || groupRegistrationForm.controls.base64Certificate?.touched && groupRegistrationForm.controls.base64Certificate?.errors">
          <div *ngIf="groupRegistrationForm.controls.base64Certificate?.errors?.required">
            <mat-error class="mat-error-class">
              {{ 'SIGNER_CERTIFICATE' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
        </div>
      </div>
      <div  class="upload_File" *ngIf="provider && provider['name'] !== env['providerAzure']">
        <input type="file" fromControlName="excelFile" #csvFileInputType accept=".csv" class="select-file" id="excelFile"
            (click)="csvFileInputType.value = null" (change)="selectFiles($event)"/>
        <label class="input-label" for="excelFile">{{ 'UPLOAD_FILE' | translate }}</label>
        <a *ngIf="sampleFile" [href]="sampleFile" download class="sample-file-link">{{'DOWNLOAD_SAMPLE_FILE' | translate
            }}</a>
            <div class="files-list"  *ngIf="excelFiles.length" >
              <div class="single-file" *ngFor="let file of excelFiles; let indexNumber = index">
                  <div class="file-icon">
                      <img [src]="documentImage" />
                  </div>
                  <div class="info">
                      <h4 class="name"> {{ file?.name }} </h4>
                      <p class="size"> {{ formatBytes(file?.size) }} </p>
                  </div>
                  <div class="delete" (click)="removeFile(indexNumber, file?.type, 'excelFile')">
                      <img [src]="deleteImage" class="modify-button">
                  </div>
              </div>
          </div>
          <div *ngIf="submitted || groupRegistrationForm.controls.excelFile?.touched && groupRegistrationForm.controls.excelFile?.errors">
            <div *ngIf="groupRegistrationForm.controls.excelFile?.errors?.required">
              <mat-error class="mat-error-class file-error">
                {{ 'FILE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
    </div>
      </form>
      <div>
        <section class="section">
          <button (click)="createGroup()" mat-raised-button class="button button-submit">{{ 'SUBMIT' | translate }}</button>
          <button (click)="onReset()" mat-button class="button">{{ 'RESET' | translate }}</button>
        </section>
      </div>
    </div>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
