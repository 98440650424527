/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as SockJS from 'sockjs-client';
import * as Stomp from 'stompjs';
import { EnvService } from '../env.service';
@Injectable({
    providedIn: 'root'
  })
  export class WebSocketService  {

    constructor(
        private env: EnvService
      ) {
      }
    private stompClient: any;
    rootUrl: string = this.env['whatsappWebsocketConnectionUrl'] + '/whatsapp/multi-agent';

    
    connectSocket(agentId,secretKey): Observable<any> {
        return new Observable(observer => {
          let socket = new SockJS(this.rootUrl+'/ws');
          this.stompClient = Stomp.over(socket);
          this.stompClient.debug = () => {}; 
          this.stompClient.connect({}, () => {
              this.stompClient.subscribe(`/queue/wspagent_`+agentId, message => {
                observer.next(JSON.parse(message.body));
              });
           
              this.stompClient.send('/multi-agent/agent-actions',{secretKey :secretKey},
                JSON.stringify({actionId : agentId, action:'connectAgent' }));
          },(error) => {
            observer.error(error);
          })
        });
      }
    
      isSocketConnected(): boolean {
        return this.stompClient && this.stompClient.connected;
      }
    
      sendChat(request,secretKey){
        this.stompClient.send('/multi-agent/sendMessage',{secretKey :secretKey},
          JSON.stringify( request ));
      }
    
      getUnreadCount(chatRoomId,secretKey){
        this.stompClient.send('/multi-agent/agent-actions',{secretKey :secretKey},
          JSON.stringify({actionId : chatRoomId, action:'getUnReadMsgCount' }));
      }
    
      getClearUnReadMsgCount(chatRoomId,secretKey){
        this.stompClient.send('/multi-agent/agent-actions',{secretKey :secretKey},
          JSON.stringify({actionId : chatRoomId, action:'clearUnReadMsgCount' }));
      }
    
      getCloseChatRoom(chatRoomId,name,secretKey){
      this.stompClient.send('/multi-agent/agent-actions',{secretKey :secretKey},
        JSON.stringify({actionId : chatRoomId, action:'closeChatRoom',agentName:name}));
     }
     
      disConnectSocket(agentId,secretKey) {
        if (this.stompClient && this.stompClient.connected) {
          this.stompClient.send('/multi-agent/agent-actions', { secretKey: secretKey },
            JSON.stringify({ actionId: agentId, action: 'disConnectAgent' })
          );
          this.stompClient.disconnect(() => {
          });
        }
      }
      
  }