/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetails } from '../models/common-details';
import { SettingCommonDto } from '../models/setting-common-dto';
import { TenantDto } from '../models/tenant-dto';

@Injectable({
  providedIn: 'root',
})
export class AccountControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAllAccounts
   */
  static readonly FindAllAccountsPath = '/api/v1/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAccounts$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<SettingCommonDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.FindAllAccountsPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingCommonDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAccounts(params?: {
    active?: string;
  }): Observable<Array<SettingCommonDto>> {

    return this.findAllAccounts$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SettingCommonDto>>) => r.body as Array<SettingCommonDto>)
    );
  }

  /**
   * Path part for operation createAccount
   */
  static readonly CreateAccountPath = '/api/v1/account';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccount$Response(params?: {
    body?: SettingCommonDto
  }): Observable<StrictHttpResponse<SettingCommonDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.CreateAccountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SettingCommonDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createAccount(params?: {
    body?: SettingCommonDto
  }): Observable<SettingCommonDto> {

    return this.createAccount$Response(params).pipe(
      map((r: StrictHttpResponse<SettingCommonDto>) => r.body as SettingCommonDto)
    );
  }

  /**
   * Path part for operation findAllAccountsCount
   */
  static readonly FindAllAccountsCountPath = '/api/v1/accountCount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllAccountsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAccountsCount$Response(params?: {
  }): Observable<StrictHttpResponse<{ [key: string]: number }>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.FindAllAccountsCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: number }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllAccountsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllAccountsCount(params?: {
  }): Observable<{ [key: string]: number }> {

    return this.findAllAccountsCount$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: number }>) => r.body as { [key: string]: number })
    );
  }

  /**
   * Path part for operation findByAccountIdBasic
   */
  static readonly FindByAccountIdBasicPath = '/api/v1/accountbasic/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByAccountIdBasic()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAccountIdBasic$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<CommonDetails>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.FindByAccountIdBasicPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommonDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByAccountIdBasic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAccountIdBasic(params: {
    id: string;
  }): Observable<CommonDetails> {

    return this.findByAccountIdBasic$Response(params).pipe(
      map((r: StrictHttpResponse<CommonDetails>) => r.body as CommonDetails)
    );
  }

  /**
   * Path part for operation findByAccountIdBasic1
   */
  static readonly FindByAccountIdBasic1Path = '/api/v1/accountbasic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByAccountIdBasic1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAccountIdBasic1$Response(params: {
    solutionTypeIds: Array<string>;
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.FindByAccountIdBasic1Path, 'get');
    if (params) {
      rb.query('solutionTypeIds', params.solutionTypeIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByAccountIdBasic1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAccountIdBasic1(params: {
    solutionTypeIds: Array<string>;
  }): Observable<Array<CommonDetails>> {

    return this.findByAccountIdBasic1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation findByAccountId
   */
  static readonly FindByAccountIdPath = '/api/v1/account/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByAccountId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAccountId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<SettingCommonDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.FindByAccountIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SettingCommonDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByAccountId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByAccountId(params: {
    id: string;
  }): Observable<SettingCommonDto> {

    return this.findByAccountId$Response(params).pipe(
      map((r: StrictHttpResponse<SettingCommonDto>) => r.body as SettingCommonDto)
    );
  }

  /**
   * Path part for operation deleteByAccountId
   */
  static readonly DeleteByAccountIdPath = '/api/v1/account/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteByAccountId()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByAccountId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<{  }>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.DeleteByAccountIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteByAccountId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteByAccountId(params: {
    id: string;
  }): Observable<{  }> {

    return this.deleteByAccountId$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation updateAccount
   */
  static readonly UpdateAccountPath = '/api/v1/account/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccount$Response(params: {
    id: string;
    body?: SettingCommonDto
  }): Observable<StrictHttpResponse<SettingCommonDto>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.UpdateAccountPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SettingCommonDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateAccount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAccount(params: {
    id: string;
    body?: SettingCommonDto
  }): Observable<SettingCommonDto> {

    return this.updateAccount$Response(params).pipe(
      map((r: StrictHttpResponse<SettingCommonDto>) => r.body as SettingCommonDto)
    );
  }

  /**
   * Path part for operation activateAccount
   */
  static readonly ActivateAccountPath = '/api/v1/account/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateAccount$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.ActivateAccountPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateAccount(params: {
    id: string;
  }): Observable<void> {

    return this.activateAccount$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateAccount
   */
  static readonly DeactivateAccountPath = '/api/v1/account/{id}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateAccount$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.DeactivateAccountPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateAccount(params: {
    id: string;
  }): Observable<void> {

    return this.deactivateAccount$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation findTenantsByAccountId
   */
  static readonly FindTenantsByAccountIdPath = '/api/v1/account/{id}/tenants';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTenantsByAccountId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTenantsByAccountId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Array<TenantDto>>> {

    const rb = new RequestBuilder(this.rootUrl, AccountControllerService.FindTenantsByAccountIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenantDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findTenantsByAccountId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTenantsByAccountId(params: {
    id: string;
  }): Observable<Array<TenantDto>> {

    return this.findTenantsByAccountId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TenantDto>>) => r.body as Array<TenantDto>)
    );
  }

}
