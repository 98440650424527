/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TOAST_MSG_DURATION } from '../shared/constants/strings';
import { TranslationService } from './translation-service';

@Injectable({
    providedIn: 'root'
})
export class ResponseHandlerService {
    constructor(private _snackBar: MatSnackBar, private translate: TranslationService) { }

    /* @description: for displaying error messages across all modules
    */
    public returnToastMessage(type: string, message: string, duration?: number) {
        const toastDuration = duration || TOAST_MSG_DURATION;
        
        return this._snackBar.open(this.translate.translateErrorMessages(message), '', {
          duration: toastDuration,
          verticalPosition: 'top',
          panelClass: [type]
        });
    }
}
