<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="smsIcon" />
        <span>{{ 'SMS_CONFIGURATIONS' | translate }}</span>
      </span>
    </div>

    <mat-tab-group class="demo-tab-group stretched-tabs" mat-stretch-tabs (selectedIndexChange) = "onTabChange()">
      <div *ngFor="let tab of configurationsList">
        <mat-tab *ngIf="checkPermission(tab['permission'])">
          <ng-template mat-tab-label>
            <div>{{ tab["title"] | translate }}</div>
          </ng-template>
          <section class="tab-body">
            <div *ngIf="tab['name'] !== 'sms-status'" class="create-form-spacing">
              <div *ngIf="tab['name'] !== 'sms-bulk-upload'">
                <form [formGroup]="dropdownForm">
                  <div class="form-grid">
                  <div *ngFor="let dropdownItem of dropdownList">
                    <div class="select-fields">
                      <mat-form-field>
                        <mat-label class="label">{{ dropdownItem.label | translate }}
                          <span *ngIf="dropdownItem['required'] === true" class="mandatory">*</span>
                        </mat-label>
                        <mat-select [placeholder]="'SELECT' | translate" formControlName="{{ dropdownItem.name }}"
                          (selectionChange)="
                            updateDropdownValues(
                              $event,
                              dropdownItem.child,
                              tab['json']
                            )
                          ">
                          <mat-option>{{ 'SELECT'| translate }}</mat-option>
                          <mat-option *ngFor="
                              let value of dropDownValues[dropdownItem.name]
                            " [value]="value.id" required>
                            {{ value.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div *ngIf="
                          submitted ||
                          (dropdownForm.get(dropdownItem.name).touched &&
                            dropdownForm.get(dropdownItem.name).errors)
                        ">
                        <div *ngIf="
                            dropdownForm.get(dropdownItem.name).errors?.required
                          ">
                          <mat-error class="mat-error-class">
                            {{ dropdownItem.label | translate }}
                            {{ 'IS_REQUIRED' | translate }}
                          </mat-error>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                </form>
                <div class="clear-float"></div>
                <app-dynamic-form [dropDownValues]="dropDownValues" [jsonFile]="tab['json']" [hideSubmitButton]="hideSubmitButton" 
                  [columnsInForm]="tab['column']" (valueSelected)="updateDropdownValues($event)"
                  (formEmitter)="submitSmsConfiguration($event, tab['name'])" (resetForm)="resetForms($event)" (buttonClickEvent)="hideEditIcon($event)">
                </app-dynamic-form>
                <div *ngIf="tab['name'] == 'sms-template'">
                  <button *ngIf="showDownload" (click)="downloadTemplate()" mat-raised-button class="button save-continue-button button-submit"  [title]="'EXPORT' | translate" > {{'EXPORT' | translate}}</button>
                </div>
              </div>
            </div>
          </section>
        </mat-tab>
      </div>
    </mat-tab-group>
  </section>
</section>
