<div *ngIf="formBase" class="{{columnsInForm}}">
  <form [formGroup]="configureForm" class="dynamic-form">
    <div *ngFor="let arr of formBase">
      <div class="form-group" *ngIf="arr['typ'] === 'int' || arr['typ'] === 'float'">
        <mat-form-field class="dynamic-form-text-fields">
          <mat-label class="label">{{arr['lbl']}} </mat-label>
          <input type="number" matInput [formControlName]="arr['lbl']"  placeholder="{{ 'TYPE_HERE' | translate }}" />
        </mat-form-field>
        <div *ngIf="submitted || configureForm.controls[arr['lbl']].touched && configureForm.controls[arr['lbl']].errors">
          <div *ngIf="configureForm.controls[arr['lbl']]?.errors?.required">
            <mat-error class="mat-error-class">
              {{arr['lbl']}} is required
            </mat-error>
          </div>
          <mat-error *ngIf="configureForm.controls[arr['lbl']]?.errors?.min" class="mat-error-class">
              {{'MINIMUM_NUMBER_LENGTH' | translate }} {{arr['min']}} {{ 'AND' | translate }} {{arr['max']}} </mat-error>
          <mat-error *ngIf="configureForm.controls[arr['lbl']]?.errors?.max" class="mat-error-class">
              {{'MAXIMUM_NUMBER_LENGTH' | translate }} {{arr['min']}} {{ 'AND' | translate }} {{arr['max']}} </mat-error>
        </div>
      </div>

      <div class="form-group" *ngIf="arr['typ'] === 'text'">
        <mat-form-field class="dynamic-form-text-fields">
          <mat-label class="label">{{arr['lbl'] | appendSpace | titlecase | translate}} 
            <span *ngIf="!registrationForm || required" class="mandatory">*</span>
          </mat-label>
          <input [type]="text" matInput [formControlName]="arr['lbl']" placeholder="{{ 'TYPE_HERE' | translate }}" />
        </mat-form-field>
        <div *ngIf="submitted || configureForm.controls[arr['lbl']].touched && configureForm.controls[arr['lbl']].errors">
          <div *ngIf="configureForm.controls[arr['lbl']]?.errors?.required">
            <mat-error class="mat-error-class">
              {{ arr['lbl'] | appendSpace | titlecase | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
          <mat-error *ngIf="configureForm.controls[arr['lbl']]?.errors?.minlength" class="mat-error-class">
           {{arr['lbl']}} {{'MINIMUM_CHARACTER_LENGTH' | translate }} {{arr['min']}} {{'CHARACTERS' | translate}} </mat-error>
          <mat-error *ngIf="configureForm.controls[arr['lbl']]?.errors?.maxlength" class="mat-error-class">
            {{arr['lbl']}} {{'MAXIMUM_CHARACTER_LENGTH' | translate }} {{arr['max']}} {{'CHARACTERS' | translate}} </mat-error>
          <mat-error *ngIf="configureForm.controls[arr['lbl']]?.errors?.pattern">
            {{arr['lbl']}} {{ 'INVALID' | translate}} {{ arr['rgx']}}</mat-error>
        </div>
      </div>

      <div class="form-group" *ngIf="arr['typ'] === 'binary'">
          <mat-label class="radio-label">{{arr['lbl']}} </mat-label> 
          <mat-radio-group [formControlName]="arr['lbl']">
            <mat-radio-button [checked]="arr['val'] === 'true'" [value]="'true'">{{ 'TRUE' | translate }}</mat-radio-button>
            <mat-radio-button [checked]="arr['val'] === 'false'" [value]="'false'">{{ 'FALSE' | translate }}</mat-radio-button>
          </mat-radio-group>
        <div *ngIf="submitted || configureForm.controls[arr['lbl']].touched && configureForm.controls[arr['lbl']].errors">
          <mat-error *ngIf="configureForm.controls[arr['lbl']]?.errors?.required">{{arr['lbl']}} is required</mat-error>
        </div>
      </div>

      <div class="form-group form-check" *ngIf="arr['typ'] === 'enum'">
        <mat-form-field class="dynamic-form-text-fields">
          <mat-label class="label">{{arr['lbl']}}</mat-label>
          <mat-select [formControlName]="arr['lbl']">
            <mat-option *ngFor="let val of arr['opt']" [value]="val"> {{val}} </mat-option>
          </mat-select>
        </mat-form-field>
        <div  *ngIf="submitted || configureForm.controls[arr['lbl']].touched && configureForm.controls[arr['lbl']].errors">
          <div *ngIf="configureForm.controls[arr['lbl']]?.errors?.required">
            <mat-error class="mat-error-class"> {{arr['lbl']}} is required </mat-error>
          </div>
          <mat-error *ngIf="configureForm.controls[arr['lbl']]?.errors?.min" class="mat-error-class">
              {{arr['lbl']}} {{'MINIMUM_NUMBER_LENGTH' | translate }} {{arr['min']}}</mat-error>
          <mat-error *ngIf="configureForm.controls[arr['lbl']]?.errors?.max" class="mat-error-class">
              {{arr['lbl']}} {{'MAXIMUM_NUMBER_LENGTH' | translate }} {{arr['max']}}</mat-error>
        </div>
      </div>

    </div>
  </form>
  <div>
    <section class="section">
      <button (click)="onUpdate()" mat-raised-button class="button button-submit">{{(registrationForm? 'SUBMIT':'UPDATE') | translate}}</button>
      <button (click)="onReset()" mat-button class="button">{{'RESET' | translate }}</button>
    </section>
  </div>
</div>