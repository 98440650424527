/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetails } from '../models/common-details';
import { SolutionTypeDto } from '../models/solution-type-dto';

@Injectable({
  providedIn: 'root',
})
export class SolutionTypeControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation activateSolutionType
   */
  static readonly ActivateSolutionTypePath = '/api/v1/solutiontype/{id}/activate';

  /**
   * Activate SolutionType.
   *
   * The api activates the specific SolutionType for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateSolutionType()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateSolutionType$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.ActivateSolutionTypePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activate SolutionType.
   *
   * The api activates the specific SolutionType for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateSolutionType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateSolutionType(params: {
    id: string;
  }): Observable<void> {

    return this.activateSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateSolutionType
   */
  static readonly DeactivateSolutionTypePath = '/api/v1/solutiontype/{id}/deactivate';

  /**
   * Deactivate SolutionType.
   *
   * The api deactivates the specific SolutionType for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateSolutionType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateSolutionType$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.DeactivateSolutionTypePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deactivate SolutionType.
   *
   * The api deactivates the specific SolutionType for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateSolutionType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateSolutionType(params: {
    id: string;
  }): Observable<void> {

    return this.deactivateSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation findByBusinessProfilesListBasicForSolutionTypeId
   */
  static readonly FindByBusinessProfilesListBasicForSolutionTypeIdPath = '/api/v1/solutiontype/{solutionTypeId}/businessProfiles';

  /**
   * GET specific SolutionType.
   *
   * The api fetches the business profile for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByBusinessProfilesListBasicForSolutionTypeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByBusinessProfilesListBasicForSolutionTypeId$Response(params: {
    solutionTypeId: string;
    deviceInterfaceProviderId?: string;
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.FindByBusinessProfilesListBasicForSolutionTypeIdPath, 'get');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.query('deviceInterfaceProviderId', params.deviceInterfaceProviderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * GET specific SolutionType.
   *
   * The api fetches the business profile for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByBusinessProfilesListBasicForSolutionTypeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByBusinessProfilesListBasicForSolutionTypeId(params: {
    solutionTypeId: string;
    deviceInterfaceProviderId?: string;
  }): Observable<Array<CommonDetails>> {

    return this.findByBusinessProfilesListBasicForSolutionTypeId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation findAllSolutionTypeById
   */
  static readonly FindAllSolutionTypeByIdPath = '/api/v1/solutiontypebyidlist';

  /**
   * GET all SolutionType By Id .
   *
   * The api fetches all the Available solutionTypes By Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllSolutionTypeById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllSolutionTypeById$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.FindAllSolutionTypeByIdPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * GET all SolutionType By Id .
   *
   * The api fetches all the Available solutionTypes By Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllSolutionTypeById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllSolutionTypeById(params?: {
    body?: Array<string>
  }): Observable<Array<CommonDetails>> {

    return this.findAllSolutionTypeById$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation findBySolutionTypeIdBasic
   */
  static readonly FindBySolutionTypeIdBasicPath = '/api/v1/solutiontypebasic/{solutionTypeId}';

  /**
   * GET specific SolutionType.
   *
   * The api fetches the specific SolutionType for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBySolutionTypeIdBasic()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBySolutionTypeIdBasic$Response(params: {
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<CommonDetails>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.FindBySolutionTypeIdBasicPath, 'get');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommonDetails>;
      })
    );
  }

  /**
   * GET specific SolutionType.
   *
   * The api fetches the specific SolutionType for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findBySolutionTypeIdBasic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBySolutionTypeIdBasic(params: {
    solutionTypeId: string;
  }): Observable<CommonDetails> {

    return this.findBySolutionTypeIdBasic$Response(params).pipe(
      map((r: StrictHttpResponse<CommonDetails>) => r.body as CommonDetails)
    );
  }

  /**
   * Path part for operation findAllSolutionTypes
   */
  static readonly FindAllSolutionTypesPath = '/api/v1/solutiontype';

  /**
   * GET All SolutionType.
   *
   * The api fetches all the Available solutionTypes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllSolutionTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllSolutionTypes$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<SolutionTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.FindAllSolutionTypesPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SolutionTypeDto>>;
      })
    );
  }

  /**
   * GET All SolutionType.
   *
   * The api fetches all the Available solutionTypes
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllSolutionTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllSolutionTypes(params?: {
    active?: string;
  }): Observable<Array<SolutionTypeDto>> {

    return this.findAllSolutionTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SolutionTypeDto>>) => r.body as Array<SolutionTypeDto>)
    );
  }

  /**
   * Path part for operation createSolutionType
   */
  static readonly CreateSolutionTypePath = '/api/v1/solutiontype';

  /**
   * Create a new SolutionType.
   *
   * The api creates a new SolutionType
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSolutionType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSolutionType$Response(params?: {
    body?: SolutionTypeDto
  }): Observable<StrictHttpResponse<SolutionTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.CreateSolutionTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SolutionTypeDto>;
      })
    );
  }

  /**
   * Create a new SolutionType.
   *
   * The api creates a new SolutionType
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createSolutionType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSolutionType(params?: {
    body?: SolutionTypeDto
  }): Observable<SolutionTypeDto> {

    return this.createSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<SolutionTypeDto>) => r.body as SolutionTypeDto)
    );
  }

  /**
   * Path part for operation findBySolutionTypeId
   */
  static readonly FindBySolutionTypeIdPath = '/api/v1/solutiontype/{id}';

  /**
   * GET specific SolutionType.
   *
   * The api fetches the specific SolutionType for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBySolutionTypeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBySolutionTypeId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<SolutionTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.FindBySolutionTypeIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SolutionTypeDto>;
      })
    );
  }

  /**
   * GET specific SolutionType.
   *
   * The api fetches the specific SolutionType for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findBySolutionTypeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBySolutionTypeId(params: {
    id: string;
  }): Observable<SolutionTypeDto> {

    return this.findBySolutionTypeId$Response(params).pipe(
      map((r: StrictHttpResponse<SolutionTypeDto>) => r.body as SolutionTypeDto)
    );
  }

  /**
   * Path part for operation updateSolutionType
   */
  static readonly UpdateSolutionTypePath = '/api/v1/solutiontype/{id}';

  /**
   * Update SolutionType.
   *
   * The api updates the specific SolutionType for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSolutionType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSolutionType$Response(params: {
    id: string;
    body?: SolutionTypeDto
  }): Observable<StrictHttpResponse<SolutionTypeDto>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.UpdateSolutionTypePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SolutionTypeDto>;
      })
    );
  }

  /**
   * Update SolutionType.
   *
   * The api updates the specific SolutionType for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSolutionType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSolutionType(params: {
    id: string;
    body?: SolutionTypeDto
  }): Observable<SolutionTypeDto> {

    return this.updateSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<SolutionTypeDto>) => r.body as SolutionTypeDto)
    );
  }

  /**
   * Path part for operation findBySolutionTypeName
   */
  static readonly FindBySolutionTypeNamePath = '/api/v1/solutiontypebyname/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findBySolutionTypeName()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBySolutionTypeName$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<CommonDetails>> {

    const rb = new RequestBuilder(this.rootUrl, SolutionTypeControllerService.FindBySolutionTypeNamePath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommonDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findBySolutionTypeName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findBySolutionTypeName(params: {
    name: string;
  }): Observable<CommonDetails> {

    return this.findBySolutionTypeName$Response(params).pipe(
      map((r: StrictHttpResponse<CommonDetails>) => r.body as CommonDetails)
    );
  }

}
