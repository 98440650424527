<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="whatsappIcon" />
        <span>{{'CONNECTION_DETAILS' | translate}}</span>
      </span>
    </div>
<div class="form-spacing">
<app-dynamic-form [jsonFile]="'whatsapp-profile-connection'"
       [gridLayout]="'grid-container-whatsapp-profile-connection'" [dropDownValues]="dropDownValues"
        (valueSelected)="updateDropdownValues($event)" (formEmitter)="saveProfileConnection()" (resetForm)="onReset()"
        [emitValueAfterReset]="false">
      </app-dynamic-form>
    </div>
    <div class="table-container" *ngIf="connectionList">
      <mat-table [dataSource]="dataSource" class="table-data">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef>{{column }}</mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName">
              <span *ngIf="column!=='Action' && column!=='HSM Password' && column!=='Customer Support Password'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column =='HSM Password' || column =='Customer Support Password'" class="cell-value api-text">
                <input [type]="showKey[dataName['Id']]?'text':'password'" [value]="dataName[column]" #apikey   
                class="text-api-key" id="{{dataName['Id']}}"/>
            </span>
              <span *ngIf="column==='Action'">
                <img [src]="showKey[dataName['Id']]?hideKeyImage:showKeyImage" class="api-key-icons"
                [title]="(showKey[dataName['Id']]? 'HIDE_KEY': 'SHOW_KEY') |translate"  (click)="showPassword(dataName['Id'])" />
                <img [src]="editImage" class="modify-button edit-icon" [title]="'EDIT' | translate" (click)="editConnection(dataName)">
                <img [src]="deleteImage" class="modify-button delete-icon" [title]="'DELETE' | translate" (click)="deleteConnection(dataName.Id)">
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
  </section>
  </section>




