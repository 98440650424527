<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="whatsappIcon" />
        <span>{{'WHATSAPP_BOT_CONFIGURATION' | translate}}</span>
      </span>
    </div>
    <div class="form-spacing">
      <app-dynamic-form [hideSubmitButton]="true" [jsonFile]="'whatsapp-chat-bot-config'"
        [gridLayout]="'grid-container-whatsapp-chat-bot'" [dropDownValues]="dropDownValues"
        (valueSelected)="updateDropdownValues($event)"></app-dynamic-form>
      <section class="section">
        <button [disabled]="profileIdCheck" mat-raised-button class="button-image"
          (click)="openChatBotPopup(content)">{{'CREATE_CHAT_BOT'|
          translate}}</button>
        <button (click)="onReset()" mat-button class="button">{{ 'RESET' | translate }}</button>
      </section>
    </div> 
    <div class="Bot-display table-container" *ngIf="botDisplyName">
      <mat-table [dataSource]="dataSource" class="table-data" *ngIf="dataSource">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef >{{column | appendSpace | titlecase | translate}}
            </mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName">
              <span *ngIf="column=='botName'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column =='apiKey'" title="{{dataName[column]}}" class="cell-value api-text">
                  <input  [type]="showKey?'text':'password'" [value]="dataName[column]" #apikey   
                  class="text-api-key"id="{{dataName[column]}}"/>
              </span>
                <span *ngIf="column==='action'">
                  <img [src]="showKey?hideKeyImage:showKeyImage" class="api-key-icons"
                  [title]="(showKey? 'HIDE_KEY': 'SHOW_KEY') |translate" (click)="showApiKey()" />
                <img [src]="copyImage" class="api-key-icons" [title]="'COPY' |translate" (click)="copyText(dataName['apiKey'])"
                  [class.disabled]="!showKey" />
                </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
    <div *ngIf="botNotMappedProfile" class="bot-not-mapped">
      <h5>{{'BOT_NOT_MAPPED_TO_PROFILE' | translate}}</h5>
    </div>
  </section>
</section>

<ng-template #content let-modal>
  <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{'CREATE_CHAT_BOT' | translate}} </h5>
    </div>
    <div class="modal-body">
      <app-dynamic-form [jsonFile]="'chat-bot'" [columnsInForm]="'single'" (formEmitter)="createChatBot($event)">
      </app-dynamic-form>
    </div>
  </div>
</ng-template>