/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { GmapServiceDetails } from '../models/gmap-service-details';

@Injectable({
  providedIn: 'root',
})
export class MapControllerServices extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAllCurrencyTypes1
   */
  static readonly FindAllCurrencyTypes1Path = '/api/v1/getGmapServices';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllCurrencyTypes1()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCurrencyTypes1$Response(params?: {
  }
): Observable<StrictHttpResponse<Array<GmapServiceDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerServices.FindAllCurrencyTypes1Path, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GmapServiceDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllCurrencyTypes1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCurrencyTypes1(params?: {
  }
): Observable<Array<GmapServiceDetails>> {

    return this.findAllCurrencyTypes1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GmapServiceDetails>>) => r.body as Array<GmapServiceDetails>)
    );
  }

  /**
   * Path part for operation findSkuIdsWithServiceIds
   */
  static readonly FindSkuIdsWithServiceIdsPath = '/api/v1/getGmapSkus';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findSkuIdsWithServiceIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  findSkuIdsWithServiceIds$Response(params?: {
    serviceIds?: Array<string>;
  }
): Observable<StrictHttpResponse<Array<GmapServiceDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerServices.FindSkuIdsWithServiceIdsPath, 'get');
    if (params) {
      rb.query('serviceIds', params.serviceIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<GmapServiceDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findSkuIdsWithServiceIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findSkuIdsWithServiceIds(params?: {
    serviceIds?: Array<string>;
  }
): Observable<Array<GmapServiceDetails>> {

    return this.findSkuIdsWithServiceIds$Response(params).pipe(
      map((r: StrictHttpResponse<Array<GmapServiceDetails>>) => r.body as Array<GmapServiceDetails>)
    );
  }

  /**
   * Path part for operation getDailyConsumption
   */
  static readonly GetDailyConsumptionPath = '/api/v1/gmap/dailyConsumption';

  /**
   * Sync Daily consumption.
   *
   * The API will copy consumption data from BigQuery
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDailyConsumption()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDailyConsumption$Response(params?: {
    date?: string;
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerServices.GetDailyConsumptionPath, 'post');
    if (params) {
      rb.query('date', params.date, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: '*/*',
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Sync Daily consumption.
   *
   * The API will copy consumption data from BigQuery
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDailyConsumption$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDailyConsumption(params?: {
    date?: string;
  }
): Observable<string> {

    return this.getDailyConsumption$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
