/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Zeliot } from '../models/zeliot';
import { ZeliotDto } from '../models/zeliot-dto';

@Injectable({
  providedIn: 'root',
})
export class ZeliotControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findById
   */
  static readonly FindByIdPath = '/api/v1/zeliot/{id}';

  /**
   * Get specific Zeliot.
   *
   * The api fetches specific Zeliot details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ZeliotDto>> {

    const rb = new RequestBuilder(this.rootUrl, ZeliotControllerService.FindByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZeliotDto>;
      })
    );
  }

  /**
   * Get specific Zeliot.
   *
   * The api fetches specific Zeliot details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: {
    id: string;
  }): Observable<ZeliotDto> {

    return this.findById$Response(params).pipe(
      map((r: StrictHttpResponse<ZeliotDto>) => r.body as ZeliotDto)
    );
  }

  /**
   * Path part for operation updateZeliot
   */
  static readonly UpdateZeliotPath = '/api/v1/zeliot/{id}';

  /**
   * Update existing Zeliot.
   *
   * The api updates existing Zeliot details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateZeliot()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateZeliot$Response(params: {
    id: string;
    body?: ZeliotDto
  }): Observable<StrictHttpResponse<ZeliotDto>> {

    const rb = new RequestBuilder(this.rootUrl, ZeliotControllerService.UpdateZeliotPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZeliotDto>;
      })
    );
  }

  /**
   * Update existing Zeliot.
   *
   * The api updates existing Zeliot details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateZeliot$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateZeliot(params: {
    id: string;
    body?: ZeliotDto
  }): Observable<ZeliotDto> {

    return this.updateZeliot$Response(params).pipe(
      map((r: StrictHttpResponse<ZeliotDto>) => r.body as ZeliotDto)
    );
  }

  /**
   * Path part for operation activateZeliot
   */
  static readonly ActivateZeliotPath = '/api/v1/zeliot/{id}/activate';

  /**
   * Activate Zeliot.
   *
   * The api activates specified Zeliot
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateZeliot()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateZeliot$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ZeliotControllerService.ActivateZeliotPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activate Zeliot.
   *
   * The api activates specified Zeliot
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateZeliot$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateZeliot(params: {
    id: string;
  }): Observable<void> {

    return this.activateZeliot$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateZeliot
   */
  static readonly DeactivateZeliotPath = '/api/v1/zeliot/{id}/deactivate';

  /**
   * Deactivate Zeliot.
   *
   * The api de-activates specified Zeliot
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateZeliot()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateZeliot$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ZeliotControllerService.DeactivateZeliotPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deactivate Zeliot.
   *
   * The api de-activates specified Zeliot
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateZeliot$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateZeliot(params: {
    id: string;
  }): Observable<void> {

    return this.deactivateZeliot$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation findIotHubs
   */
  static readonly FindIotHubsPath = '/api/v1/iothubs/solDevIntfProv/{solDeviceIntfProvId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findIotHubs()` instead.
   *
   * This method doesn't expect any request body.
   */
  findIotHubs$Response(params: {
    solDeviceIntfProvId: string;
  }): Observable<StrictHttpResponse<Array<Zeliot>>> {

    const rb = new RequestBuilder(this.rootUrl, ZeliotControllerService.FindIotHubsPath, 'get');
    if (params) {
      rb.path('solDeviceIntfProvId', params.solDeviceIntfProvId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Zeliot>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findIotHubs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findIotHubs(params: {
    solDeviceIntfProvId: string;
  }): Observable<Array<Zeliot>> {

    return this.findIotHubs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Zeliot>>) => r.body as Array<Zeliot>)
    );
  }

  /**
   * Path part for operation findAll
   */
  static readonly FindAllPath = '/api/v1/zeliot';

  /**
   * Get all Zeliot.
   *
   * The api fetches all Zeliot details
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<ZeliotDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ZeliotControllerService.FindAllPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ZeliotDto>>;
      })
    );
  }

  /**
   * Get all Zeliot.
   *
   * The api fetches all Zeliot details
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(params?: {
    active?: string;
  }): Observable<Array<ZeliotDto>> {

    return this.findAll$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ZeliotDto>>) => r.body as Array<ZeliotDto>)
    );
  }

  /**
   * Path part for operation createZeliot
   */
  static readonly CreateZeliotPath = '/api/v1/zeliot';

  /**
   * Create a new IoT for Zeliot.
   *
   * The api creates a new IoT for Zeliot
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createZeliot()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createZeliot$Response(params?: {
    body?: ZeliotDto
  }): Observable<StrictHttpResponse<ZeliotDto>> {

    const rb = new RequestBuilder(this.rootUrl, ZeliotControllerService.CreateZeliotPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ZeliotDto>;
      })
    );
  }

  /**
   * Create a new IoT for Zeliot.
   *
   * The api creates a new IoT for Zeliot
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createZeliot$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createZeliot(params?: {
    body?: ZeliotDto
  }): Observable<ZeliotDto> {

    return this.createZeliot$Response(params).pipe(
      map((r: StrictHttpResponse<ZeliotDto>) => r.body as ZeliotDto)
    );
  }

}
