import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { UpdatePackageComponent } from './update-package/update-package.component';
import { DistributionPackageComponent } from './distribution-package/distribution-package.component';
import { OtaAssignmentComponent } from './ota-assignment/ota-assignment.component';
import { OtaCampaignComponent } from './ota-campaign/ota-campaign.component';
import { UpdatePackageDetailsComponent } from './update-package-details/update-package-details.component';
import { AddUpdatePackageComponent } from './add-update-package/add-update-package.component';

/**
  * @ngdoc overview
  * @name MODULE_NAME.module:FotaAdvanceModule
  *
  * @description
  * description: To route to different components in fota Advance module based on the path given
*/
const routes: Routes = [
  {
    path: 'ota-advance/update-package',
    component: UpdatePackageComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'DEV_FOTA_R', data: {} }
  },
  {
    path: 'ota-advance/add-package',
    component: AddUpdatePackageComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'DEV_FOTA_R', data: {} }
  },
  {
    path: 'ota-advance/distribution-package',
    component: DistributionPackageComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'DEV_FOTA_R', data: {} }
  },
  {
    path: 'ota-advance/assignment',
    component: OtaAssignmentComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'DEV_FOTA_R', data: {} }
  },
  {
    path: 'ota-advance/campaign',
    component: OtaCampaignComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'DEV_FOTA_R', data: {} }
  },
  {
    path: 'ota-advance/package-details',
    component: UpdatePackageDetailsComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'DEV_FOTA_R', data: {} }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FotaAdvanceRoutingModule { }
