<!--
  ~ Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
-->
<div class="app-background">
  <mat-grid-list [cols]="cols" rowHeight="20vh" *ngIf="checkPermission('ACC_C')">
    <mat-grid-tile *ngFor="let card of cardsList | keyvalue">
      <mat-card [class]="card.value['style']">
        <div class="summary-details">
          <img [src]="card.value['icon']" class="icon-size">
          <div class="summary-text">
            <span class="card-text">{{card.value['text'] | translate}}</span>
            <div class="card-count" [ngStyle]="{'color': card.value['color']}">{{card.value['data'].active}}</div>
          </div>
          <!-- tbd -->
          <span class="back-arrow">
            <img [src]=" backLeftImage" class="back-arrow">
          </span>
        </div>
        <div>
          <mat-progress-bar [ngClass]="card.value['progressClass']" class="progress-container" mode="determinate"
            [value]="(card.value['data'].active*100)/card.value['data'].total">
          </mat-progress-bar>
        </div>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
  <span class="date-box-message">
    <span class="message-style">{{'DATE_RANGE_MSG' | translate}}
      <span class="today-date">{{todayDate | date}}</span></span>
    <mat-form-field class="date-selection-box">
      <mat-select (selectionChange)="onDateRangeSelection($event)" [(ngModel)]="defaultDateRange"class="select-dropdown">
        <mat-option *ngFor="let dateRange of dateRangeList" [value]="dateRange.id">{{dateRange['label'] | translate}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </span>

  <div class="parent-container">
    <span>
      <mat-button-toggle-group class="filter-buttons">
        <mat-button-toggle checked class="button-align" (click)="getAssetSummary(defaultDateRange, true)">
          {{ 'ACTIVE' | translate }}</mat-button-toggle>
        <mat-button-toggle class="button-align" (click)="getAssetSummary(defaultDateRange, false)">
          {{ 'INACTIVE' | translate }}</mat-button-toggle>
      </mat-button-toggle-group>
    </span>
    <mat-form-field class="filter-dropdown">
      <mat-select [(ngModel)]="selectedAssetsOptions" class="option-selection-box select-dropdown" multiple>
        <mat-option #allAssetsSelected (click)="toggleAllSelection('asset')" value="ALL">{{'ALL' | translate}}
        </mat-option>
        <mat-option *ngFor="let option of assetArrayList" (click)="filterGraph('asset')" [value]="option">
          {{option | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div class="asset-card">{{'ASSET_DETAILS' | translate}}
      <div class="asset-summary">
        <div class="asset-chart-area">
          <div #assetsCharts></div>
        </div>
        <div class="info-div">
          <ng-container>
            <div *ngFor="let asset of assetData">
              <div class="icon-text">
                <img [src]="asset['icon']" />
                <div class="text-count-div">
                  <div class="status-text-size">{{asset['name'] | translate}}</div>
                  <div class="count-div" [ngStyle]="{'color': asset['color']}">{{asset['count']}}</div>
                </div>
              </div>
              <hr>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- tbd after job charts are ready -->
  <!-- <div class="job-card">{{'JOB_DETAILS' | translate}}</div> -->
  <div class="parent-container">
    <div class="transaction-card"
      [ngClass]="{'transaction-card-bar': barChartArea, 'transaction-card-chart': !barChartArea}">{{'TRANSACTION_DETAILS' | translate}}
      <mat-form-field class="transaction-filter-dropdown">
        <mat-select [(ngModel)]="selectedTransactionsOptions" class="option-selection-box select-dropdown" multiple>
          <mat-option #allTransactionsSelected (click)="toggleAllSelection('transaction')" value="ALL">
            {{'ALL' | translate}}</mat-option>
          <mat-option *ngFor="let option of subscriptionArrayList" (click)="filterGraph('transaction')"
            [value]="option">
            {{option | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="form-spacing" *ngIf="gMapShowHide">
        <app-search-component *ngIf="gMapDataJson" [comboFieldSearchArray]="gMapDataJson"
          [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)"
          (searchFieldEmitter)="onSubmit($event)" [gridLayout]="'grid-container-gmap-dashboard'">
        </app-search-component>
      </div>
      <div class="text-display" *ngIf="gMapShowHide">
        <span class="data-available">{{'DATE_AVAILABLE_UNTIL'|translate}}: {{todayDate | date:'dd/MM/yyyy'}} - {{TimeZone}}</span>
        <span class="total-cost">
        <span class="total-cost-level">
       {{'TOTAL_COST_OF' | translate}}: </span><span class="total-cost-value"> ${{totalCostOf}} </span><mat-icon class="rb-ic" fontIcon="bosch-ic-info-i-frame" [title]="'TOTAL_COST_INFO'  | translate" class="multiline-title"></mat-icon></span>
      </div>
      <!-- need for styling the dropdown -->
      <div class="clear-float"></div>
      <div class="transaction-summary">
        <div [ngClass]="{'bar-chart-area': barChartArea, 'transaction-chart-area': !barChartArea}">
          <div #subscriptionCharts></div>
        </div>
        <div class="info-div">
          <ng-container *ngIf="!gMapShowHide">
            <div *ngFor="let subscription of subscriptionData">
              <div class="icon-text">
                <img [src]="subscription['icon']">
                <div class="text-count-div">
                  <div class="status-text-size">{{subscription['name'] |translate}}</div>
                  <div class="count-div" [ngStyle]="{'color': subscription['color']}">{{subscription['count']}}</div>
                </div>
              </div>
              <hr>
            </div>
          </ng-container>
        </div>
      </div>
      <button class="see-more-button" (click)="openModal(content)" mat-flat-button *ngIf="gMapShowHide">{{'SEE_MORE' |
        translate}}</button>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="close-btn"><img class="rb-ic close-icon" (click)="onClose()" [src]="closeIcon" /></div>
  <div class="modal-width">
    <div class="table-container">
      <mat-table [dataSource]="dataSource" class="bottom-space table-data" *ngIf="gMaplList">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column}}
            </mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName" [ngClass]="column">
              <span *ngIf="column!=='Color'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column =='Color'" title="{{dataName[column]}}" class="cell-value column-small">
                <div class="color-circle" [style.backgroundColor]="dataName[column]"></div>
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
      <div *ngIf="!gMaplList">{{'T_NO_DATA_FOUND'| translate}}</div>
    </div>
  </div>
</ng-template>