/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CertificateBasicDto } from '../models/certificate-basic-dto';
import { CertificateCommonParamsDto } from '../models/certificate-common-params-dto';
import { CertificateDto } from '../models/certificate-dto';
import { CertificateSearchDto } from '../models/certificate-search-dto';
import { CertificateSearchResultDto } from '../models/certificate-search-result-dto';
import { IssuerNameFetchResult } from '../models/issuer-name-fetch-result';

@Injectable({
  providedIn: 'root',
})
export class CertificateManagementControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation searchCertificates
   */
  static readonly SearchCertificatesPath = '/api/v1/certificates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchCertificates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchCertificates$Response(params?: {
    page?: number;
    limit?: number;
    body?: CertificateSearchDto
  }): Observable<StrictHttpResponse<CertificateSearchResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.SearchCertificatesPath, 'post');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CertificateSearchResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `searchCertificates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  searchCertificates(params?: {
    page?: number;
    limit?: number;
    body?: CertificateSearchDto
  }): Observable<CertificateSearchResultDto> {

    return this.searchCertificates$Response(params).pipe(
      map((r: StrictHttpResponse<CertificateSearchResultDto>) => r.body as CertificateSearchResultDto)
    );
  }

  /**
   * Path part for operation getRootCertificate
   */
  static readonly GetRootCertificatePath = '/api/v1/solutiontype/{solutionId}/businessprofile/{profileId}/rootcertificate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRootCertificate()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRootCertificate$Response(params: {
    solutionId: string;
    profileId: string;
  }): Observable<StrictHttpResponse<CertificateDto>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.GetRootCertificatePath, 'get');
    if (params) {
      rb.path('solutionId', params.solutionId, {});
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CertificateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRootCertificate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRootCertificate(params: {
    solutionId: string;
    profileId: string;
  }): Observable<CertificateDto> {

    return this.getRootCertificate$Response(params).pipe(
      map((r: StrictHttpResponse<CertificateDto>) => r.body as CertificateDto)
    );
  }

  /**
   * Path part for operation findIssuerDetails
   */
  static readonly FindIssuerDetailsPath = '/api/v1/certificates/issuers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findIssuerDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findIssuerDetails$Response(params?: {
    body?: CertificateSearchDto
  }): Observable<StrictHttpResponse<Array<IssuerNameFetchResult>>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.FindIssuerDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IssuerNameFetchResult>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findIssuerDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findIssuerDetails(params?: {
    body?: CertificateSearchDto
  }): Observable<Array<IssuerNameFetchResult>> {

    return this.findIssuerDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<IssuerNameFetchResult>>) => r.body as Array<IssuerNameFetchResult>)
    );
  }

  /**
   * Path part for operation createRootCertificate
   */
  static readonly CreateRootCertificatePath = '/api/v1/rootcertificate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRootCertificate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRootCertificate$Response(params?: {
    body?: CertificateDto
  }): Observable<StrictHttpResponse<CertificateDto>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.CreateRootCertificatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CertificateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createRootCertificate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createRootCertificate(params?: {
    body?: CertificateDto
  }): Observable<CertificateDto> {

    return this.createRootCertificate$Response(params).pipe(
      map((r: StrictHttpResponse<CertificateDto>) => r.body as CertificateDto)
    );
  }

  /**
   * Path part for operation createIntermediateCertificate
   */
  static readonly CreateIntermediateCertificatePath = '/api/v1/intermediatecertificate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createIntermediateCertificate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIntermediateCertificate$Response(params?: {
    body?: CertificateCommonParamsDto
  }): Observable<StrictHttpResponse<CertificateDto>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.CreateIntermediateCertificatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CertificateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createIntermediateCertificate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIntermediateCertificate(params?: {
    body?: CertificateCommonParamsDto
  }): Observable<CertificateDto> {

    return this.createIntermediateCertificate$Response(params).pipe(
      map((r: StrictHttpResponse<CertificateDto>) => r.body as CertificateDto)
    );
  }

  /**
   * Path part for operation createLeafCertificate
   */
  static readonly CreateLeafCertificatePath = '/api/v1/leafcertificate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLeafCertificate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLeafCertificate$Response(params?: {
    body?: CertificateCommonParamsDto
  }): Observable<StrictHttpResponse<CertificateDto>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.CreateLeafCertificatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CertificateDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createLeafCertificate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLeafCertificate(params?: {
    body?: CertificateCommonParamsDto
  }): Observable<CertificateDto> {

    return this.createLeafCertificate$Response(params).pipe(
      map((r: StrictHttpResponse<CertificateDto>) => r.body as CertificateDto)
    );
  }

  /**
   * Path part for operation findRootAndIntermediateCert
   */
  static readonly FindRootAndIntermediateCertPath = '/api/v1/solutiontype/{solutionId}/businessprofile/{profileId}/rootintermediatecertificate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findRootAndIntermediateCert()` instead.
   *
   * This method doesn't expect any request body.
   */
  findRootAndIntermediateCert$Response(params: {
    solutionId: string;
    profileId: string;
  }): Observable<StrictHttpResponse<Array<CertificateBasicDto>>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.FindRootAndIntermediateCertPath, 'get');
    if (params) {
      rb.path('solutionId', params.solutionId, {});
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CertificateBasicDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findRootAndIntermediateCert$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findRootAndIntermediateCert(params: {
    solutionId: string;
    profileId: string;
  }): Observable<Array<CertificateBasicDto>> {

    return this.findRootAndIntermediateCert$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CertificateBasicDto>>) => r.body as Array<CertificateBasicDto>)
    );  
  }

  /**
   * Path part for operation downloadCertificateZip
   */
  static readonly DownloadCertificateZipPath = '/api/v1/certificate/{certId}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadCertificateZip()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadCertificateZip$Response(params: {
    certId: string;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.DownloadCertificateZipPath, 'get');
    if (params) {
      rb.path('certId', params.certId, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/zip'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadCertificateZip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadCertificateZip(params: {
    certId: string;
  }): Observable<Array<string>> {

    return this.downloadCertificateZip$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation generateValidityCertificate
   */
  static readonly GenerateValidityCertificatePath = '/api/v1/rootcertificate/{rootCertId}/verificationtoken/{verificationToken}/validitycertificate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateValidityCertificate()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateValidityCertificate$Response(params: {
    rootCertId: string;
    verificationToken: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.GenerateValidityCertificatePath, 'get');
    if (params) {
      rb.path('rootCertId', params.rootCertId, {});
      rb.path('verificationToken', params.verificationToken, {});
    }

    return this.http.request(rb.build({
      responseType: 'blob',
      accept: 'application/zip'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `generateValidityCertificate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  generateValidityCertificate(params: {
    rootCertId: string;
    verificationToken: string;
  }): Observable<void> {

    return this.generateValidityCertificate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation activateCertificate
   */
  static readonly ActivateCertificatePath = '/api/v1/certificate/{certId}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateCertificate()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateCertificate$Response(params: {
    certId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.ActivateCertificatePath, 'patch');
    if (params) {
      rb.path('certId', params.certId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateCertificate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateCertificate(params: {
    certId: string;
  }): Observable<void> {

    return this.activateCertificate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateCertificate
   */
  static readonly DeactivateCertificatePath = '/api/v1/certificate/{certId}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateCertificate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateCertificate$Response(params: {
    certId: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CertificateManagementControllerService.DeactivateCertificatePath, 'patch');
    if (params) {
      rb.path('certId', params.certId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateCertificate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateCertificate(params: {
    certId: string;
  }): Observable<void> {

    return this.deactivateCertificate$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
