/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetails } from '../models/common-details';
import { ProposalGeneralDetailsDto } from '../models/proposal-general-details-dto';

@Injectable({
  providedIn: 'root',
})
export class GeneralConfigurationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAllClassTypes
   */
  static readonly FindAllClassTypesPath = '/api/v1/classTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllClassTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllClassTypes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, GeneralConfigurationControllerService.FindAllClassTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllClassTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllClassTypes(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.findAllClassTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation findAllRevenueTypes
   */
  static readonly FindAllRevenueTypesPath = '/api/v1/revenueTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllRevenueTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllRevenueTypes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, GeneralConfigurationControllerService.FindAllRevenueTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllRevenueTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllRevenueTypes(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.findAllRevenueTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation findAllChannelPartners
   */
  static readonly FindAllChannelPartnersPath = '/api/v1/channelPartners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllChannelPartners()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllChannelPartners$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, GeneralConfigurationControllerService.FindAllChannelPartnersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllChannelPartners$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllChannelPartners(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.findAllChannelPartners$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation getProposalGeneralDetails
   */
  static readonly GetProposalGeneralDetailsPath = '/api/v1/proposal/generalDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProposalGeneralDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalGeneralDetails$Response(params?: {
    profileId?: string;
    version?: string;
  }): Observable<StrictHttpResponse<ProposalGeneralDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneralConfigurationControllerService.GetProposalGeneralDetailsPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
      rb.query('version', params.version, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalGeneralDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProposalGeneralDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalGeneralDetails(params?: {
    profileId?: string;
    version?: string;
  }): Observable<ProposalGeneralDetailsDto> {

    return this.getProposalGeneralDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalGeneralDetailsDto>) => r.body as ProposalGeneralDetailsDto)
    );
  }

  /**
   * Path part for operation saveProposalGeneralDetails
   */
  static readonly SaveProposalGeneralDetailsPath = '/api/v1/proposal/generalDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProposalGeneralDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalGeneralDetails$Response(params?: {
    body?: ProposalGeneralDetailsDto
  }): Observable<StrictHttpResponse<ProposalGeneralDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneralConfigurationControllerService.SaveProposalGeneralDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalGeneralDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveProposalGeneralDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalGeneralDetails(params?: {
    body?: ProposalGeneralDetailsDto
  }): Observable<ProposalGeneralDetailsDto> {

    return this.saveProposalGeneralDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalGeneralDetailsDto>) => r.body as ProposalGeneralDetailsDto)
    );
  }

  /**
   * Path part for operation updateProposalGeneralDetails
   */
  static readonly UpdateProposalGeneralDetailsPath = '/api/v1/proposal/generalDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProposalGeneralDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProposalGeneralDetails$Response(params?: {
    body?: ProposalGeneralDetailsDto
  }): Observable<StrictHttpResponse<ProposalGeneralDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, GeneralConfigurationControllerService.UpdateProposalGeneralDetailsPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalGeneralDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateProposalGeneralDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProposalGeneralDetails(params?: {
    body?: ProposalGeneralDetailsDto
  }): Observable<ProposalGeneralDetailsDto> {

    return this.updateProposalGeneralDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalGeneralDetailsDto>) => r.body as ProposalGeneralDetailsDto)
    );
  }

  /**
   * Path part for operation findAllCurrencyTypes
   */
  static readonly FindAllCurrencyTypesPath = '/api/v1/currencyTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllCurrencyTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCurrencyTypes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, GeneralConfigurationControllerService.FindAllCurrencyTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllCurrencyTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllCurrencyTypes(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.findAllCurrencyTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

}
