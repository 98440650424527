<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="deviceIcon" />
        <span>{{ "DEVICE_SIM_MAPPING" | translate }}</span>
      </span>
    </div>
    <mat-tab-group>
      <mat-tab label="Individual Sim Mapping">
        <mat-tab-group [selectedIndex]="getSelectedIndex()" (selectedTabChange)="matChange($event)" #tabGroup>
          <mat-tab [label]="tab.label" *ngFor="let tab of matTabOptions"> </mat-tab>
        </mat-tab-group>
        <span>
          <app-search-component *ngIf="formData" [comboFieldSearchArray]="formData" [dropDownValues]="dropDownValues"
            [selectedTab]="selectedTab" (valueSelected)="updateDropdownValues($event)"
            (searchFieldEmitter)="searchDevicesEmitter($event)">
          </app-search-component>
        </span>
        <div class="table-container" *ngIf="gridEnable">
          <mat-table [dataSource]="data" class="table-data">
            <div *ngFor="let column of columns">
              <ng-container [matColumnDef]="column">
                <mat-header-cell [ngClass]="determineColumnSize(column)" *matHeaderCellDef>
                  <header class="device-table heading-label">
                    {{ column | appendSpace | titlecase | translate }}
                  </header>
                </mat-header-cell>
                <mat-cell class="tooltip" [ngClass]="determineColumnSize(column)" *matCellDef="let dataName">
                  <span *ngIf="column === 'action'">
                    <ng-container *ngFor="let action of dataName['devicePropertySupport']">
                      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="(action['devicePropertyName'] == 'SINGLE_SIM_SUPPORT' ||
                        action['devicePropertyName'] == 'DUAL_SIM_SUPPORT' ) &&
                          action['active']">
                        <img [src]="moreOptionsImage" />
                      </button>
                    </ng-container>
                    <mat-menu #menu="matMenu">
                      <div *ngFor="let action of dataName['devicePropertySupport']">
                        <button mat-menu-item *ngIf="
                            (action['devicePropertyName'] == 'SINGLE_SIM_SUPPORT' ||
                              action['devicePropertyName'] == 'DUAL_SIM_SUPPORT') &&
                            action['active']
                          " (click)="linkSimDialogue(content, action,dataName['serialNo'])">
                          <img [src]="restartImage" class="icon-space" class="modify-button" />
                          <span>{{ "Link" }}</span>
                        </button>

                        <button *ngIf="
                            (action['devicePropertyName'] == 'SINGLE_SIM_SUPPORT' ||
                              action['devicePropertyName'] == 'DUAL_SIM_SUPPORT') &&
                            action['active']
                          " (click)="deLinkSimDialogue(content1, action,dataName['serialNo'])"mat-menu-item>
                          <img [src]="rebootImage" class="icon-space" class="modify-button" />
                          <span>{{ "Delink" }}</span>
                        </button>
                      </div>
                    </mat-menu>
                  </span>
                  <span title="{{ dataName[column] }}" class="cell-value">
                    {{ dataName[column] }}
                  </span>
                </mat-cell>
              </ng-container>
            </div>
            <mat-header-row class="table-header-row" *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let data; columns: columns"></mat-row>
          </mat-table>
        </div>
        <mat-paginator *ngIf="gridEnable" [pageSizeOptions]="pageSizeOptions" [length]="deviceCount" [pageSize]="pageSize"
          showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>

      </mat-tab>
      <mat-tab label="Bulk Sim Mapping">
        <form [formGroup]="bulkSimMappingForm" class="create-form-spacing form-fields">
          <div class="box-view">
            <a class="sample-file-link" (click)="getCSVFile()">{{'DOWNLOAD_SAMPLE_FILE' |
              translate
              }}</a>
            <div class="file-uploader">
              <input type="file" #csvFileInputType accept=".csv" class="select-file"
                (click)="csvFileInputType.value = null" (change)="selectFiles($event)" id="excelFile" />
              <div class="file-upload-content">
                <span class="heading-label">
                  <img class="page-icon-small" [src]=" cloudUploadImage" (click)="csvFileInputType.click()" />
                </span>
                <h5 *ngIf="excelFiles?.length === 0" (click)="csvFileInputType.click()" >{{ 'UPLOAD_FILE' | translate }}</h5>
                <div class="files-list" *ngIf="excelFiles.length">
                  <div class="single-file" *ngFor="let file of excelFiles; let indexNumber = index">
                    <div class="file-icon">
                      <img [src]="documentImage" />
                    </div>
                    <div class="info">
                      <h4 class="name"> {{ file?.name }} </h4>
                      <p class="size"> {{ formatBytes(file?.size) }} </p>
                    </div>
                    <div class="delete" (click)="removeFile(indexNumber, file?.type, 'excelFile')">
                      <img [src]="deleteImg" class="modify-button">
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div>
              <section class="section">
                <button [disabled]="!excelFiles[0]" (click)="uploadCSV()" mat-raised-button class="button-submit">{{
                  'SUBMIT' | translate
                  }}</button>
              </section>
            </div>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
<ng-template #content let-modal>
  <div class="close-btn">
    <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
  </div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{'LINK_SIM_ID'| translate}}</h5>
    </div>
    <div class="modal-body">
      <app-dynamic-form *ngIf="!formData" [jsonFile]="jsonFileLoaded" (formEmitter)="submitData($event)"
        [columnsInForm]="'single'" (resetForm)="resetForm($event)"></app-dynamic-form>
        <app-dynamic-form *ngIf="formData" [jsonFile]="jsonFileLoaded" [formData]="formData" (formEmitter)="submitData($event)"
        [columnsInForm]="'single'" (resetForm)="resetForm($event)"></app-dynamic-form>
    </div>
  </div>
</ng-template>

<ng-template #content1 let-modal>
  <div class="close-btn">
    <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
  </div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{'DE_LINK_SIM_ID'| translate}}</h5>
    </div>
    <div class="modal-body">
        <form [formGroup]="delinkingForm">
          <div class="create-form-spacing form-fields">
            <div *ngIf="delinkSimSupport==='SINGLE_SIM_SUPPORT' || delinkSimSupport==='DUAL_SIM_SUPPORT'">
              <mat-form-field class="field-with-action-btn">
                <mat-label class="label">{{'SIM_REFERENCE_ID_1' | translate}} </mat-label>
                <input type="text" matInput #linkSim1_Id formControlName="linkSim1_Id" class="form-field"
                  [placeholder]=" 'TYPE_HERE' | translate" />
              </mat-form-field>
              <div class="close-btn center-align"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="disbleField('linkSim1_Id')"></mat-icon></div>
            </div>
            <div *ngIf="delinkSimSupport==='DUAL_SIM_SUPPORT'">
              <mat-form-field class="field-with-action-btn">
                <mat-label class="label">{{'SIM_REFERENCE_ID_2' | translate}} </mat-label>
                <input type="text" matInput #linkSim2_Id formControlName="linkSim2_Id" class="form-field"
                  [placeholder]=" 'TYPE_HERE' | translate" />
              </mat-form-field>
              <div class="close-btn center-align"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="disbleField('linkSim2_Id')" ></mat-icon></div>
            </div>
          </div>
        </form>
        <div class="action-buttons">
            <button [disabled]="disableBtn" (click)="submitDeLinkData('submit')" mat-raised-button
              class="button button-submit">{{ 'SUBMIT' | translate }}</button>
            <button (click)="onReset()" [disabled]="disableBtn" mat-button
              class="button reset-btn">{{ 'RESET' | translate }}</button>
        </div>
    </div>
  </div>
</ng-template>
