/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ObjectToIdConversionService {
    constructor() { }

    /*
    */
    public convertObjectToId(objectId) {
        if (objectId) {
            if (!objectId['id']) {
                return { id: objectId };
            }
        }
        return objectId;
    }

    public convertIdToObject(objectId) {
        if (objectId) {
            return objectId['id'];
        }
        return objectId;
    }

    public convertObjectToName(object) {
        if (object) {
            return object['name'];
        }
        return object;
    }

    public convertNameToObject(objectId) {
        if (objectId) {
            if (!objectId['name']) {
                return { name: objectId };
            }
        }
        return objectId;
    }

    public getNameById(id: string, data: any[]): string {
        const item = data.find(item => item.id === id);
        return item ? item.name : null;
    }
}

