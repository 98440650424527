<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="deviceTunnelIcon" />
        <span>{{ "DEVICE_TUNNEL" | translate }}</span>
      </span>
    </div>
    <div class="layout">
      <form [formGroup]="tunnelingBasicsForm">
        <div class="create-form-spacing form-fields">
          <div class="service-div">
            <div class="input-div">
              <mat-form-field>
                <mat-label class="label">{{ "PROTOCOL" | translate }}
                  <span class="mandatory">*</span>
                </mat-label>
                <mat-select class="form-field" formControlName="protocols" [placeholder]="'SELECT' | translate"
                  (selectionChange)="addSelectedPort($event)">
                  <mat-option>{{ "SELECT" | translate }}</mat-option>
                  <mat-option *ngFor="let val of dropDownValues['protocols']" [value]="val.id">
                    {{ val.name }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div *ngIf="
                  submitted ||
                  (tunnelingBasicsForm.controls.protocols?.touched &&
                    tunnelingBasicsForm.controls.protocols?.errors)
                ">
                <div *ngIf="
                    tunnelingBasicsForm.controls.protocols?.errors?.required
                  ">
                  <mat-error class="mat-error-class">
                    {{ "PROTOCOL" | translate }} {{ "IS_REQUIRED" | translate }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="input-div">
              <mat-form-field>
                <mat-label class="label">{{ "HOST" | translate }} <span class="mandatory">*</span>
                </mat-label>
                <input type="text" matInput formControlName="host" class="form-field" 
                pattern="^([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})$"
                  [placeholder]="'127.0.0.1' | translate" />
              </mat-form-field>
              <div *ngIf="
                  submitted ||
                  (tunnelingBasicsForm.controls.host?.touched &&
                    tunnelingBasicsForm.controls.host?.errors)
                ">
                <div *ngIf="tunnelingBasicsForm.controls.host?.errors?.required">
                  <mat-error class="mat-error-class">
                    {{ "HOST" | translate }} {{ "IS_REQUIRED" | translate }}
                  </mat-error>
                </div>
                <div *ngIf="tunnelingBasicsForm.controls.host?.errors?.pattern">
                  <mat-error class="mat-error-class">
                    {{ "PLEASE_ENTER_VALID_IP" | translate }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="input-div">
              <mat-form-field>
                <mat-label class="label">{{ "SERVICE_PORT" | translate }}
                  <span class="mandatory">*</span>
                </mat-label>
                <input type="text" matInput formControlName="servicePort" class="form-field"
                  [placeholder]="'80' | translate" pattern="[0-9]+" />
              </mat-form-field>
              <div *ngIf="
                  submitted ||
                  (tunnelingBasicsForm.controls.servicePort?.touched &&
                    tunnelingBasicsForm.controls.servicePort?.errors)
                ">
                <div *ngIf="
                    tunnelingBasicsForm.controls.servicePort?.errors?.required
                  ">
                  <mat-error class="mat-error-class">
                    {{ "SERVICE_PORT" | translate }}
                    {{ "IS_REQUIRED" | translate }}
                  </mat-error>
                </div>
              </div>
            </div>
            <div class="button-div">
              <button (click)="establishTunneling()" mat-raised-button class="button button-submit">
                {{ "CONNECT" | translate }}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="respPayload" class="details-div">
        <mat-tab-group (selectedTabChange)="matChange($event)">
          <mat-tab [label]="tab.label" *ngFor="let tab of matTabOptions">
          </mat-tab>
        </mat-tab-group>
        <div class="device-div">
          <span class="device-span">{{ "DEVICE_T" | translate }}</span>
          <h5 class="h5">{{ serialNo }}</h5>
        </div>
        <div class="endpoint-div">
          <!-- <span>{{'PUBLIC_ENDPOINT' | translate}}</span> -->
          <input id="endpoint" aria-hidden="true" class="enpoint-input" [value]="respPayload['host'] + ':' + respPayload['port']" />

          <div *ngIf="respPayload && respPayload['host']" class="payload-div">
            <span>{{ "HOST" | translate }}</span>
            <input id="host" class="host-port-input" [value]="respPayload['host']" readonly />
          </div>
          <div *ngIf="respPayload && respPayload['port']"  class="payload-div">
            <span>{{ "PORT" | translate }}</span>
            <input id="port" class="host-port-input" [value]="respPayload['port']" readonly />
          </div>
          <div class="action-btn-div">
            <button mat-raised-button class="button button-submit" (click)="copyText('endpoint')">
              <img [src]="endPointCopyIcon" class="copy-icons" [title]="'COPY_HOST' | translate" />
              {{ "END_POINT" | translate }}
            </button>
            <button mat-raised-button class="button button-submit" (click)="copyText('host')">
              <img [src]="hostCopyImage" class="copy-icons" [title]="'COPY_HOST' | translate" />
              {{ "HOST" | translate }}
            </button>
            <button mat-raised-button class="button button-submit" (click)="copyText('port')">
              <img [src]="portCopyImage" class="copy-icons" [title]="'COPY_PORT' | translate" />
              {{ "PORT" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
