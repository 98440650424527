import { NgModule } from '@angular/core';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
@NgModule({
    declarations: [],
    imports: [
        MatIconModule
    ],
    exports: [
        MatIconModule
    ],
    providers: [MatIconRegistry]
})
export class MaterialModule { }
