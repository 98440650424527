<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="deviceIcon" />
        <span>{{'GROUP_MANAGEMENT' |translate}}</span>
      </span>
    </div>
    <mat-tab-group (selectedTabChange)="matChange($event)">
      <mat-tab [label]="tab.label" *ngFor="let tab of matTabOptions">
      </mat-tab>

    </mat-tab-group>
    <span>
      <app-search-component *ngIf="formData" [comboFieldSearchArray]="formData" [dropDownValues]="dropDownValues"
        (valueSelected)="updateDropdownValues($event)" (searchFieldEmitter)="searchGroupsEmitter($event)">
      </app-search-component>
    </span>
    <!-- activation/de-activation/deletion of multiple groups to be taken up in next phase -->
    <!-- <span>
          <button  [disabled]="!checkPermission('GRP_EN_U')" (click)="gotoModal(popup, null, 'activate')" mat-raised-button class="manage-action-btn">{{ 'ACTIVATE' | translate }}</button>
          <button  [disabled]="!checkPermission('GRP_EN_U')" (click)="gotoModal(popup, null, 'deactivate')" class="manage-action-btn" mat-raised-button>{{ 'DE-ACTIVATE' | translate }}</button>
          <button  [disabled]="!checkPermission('GRP_EN_DEL')" (click)="deleteAccount($event)" mat-raised-button color="warn" class="manage-action-btn">{{ 'DELETE' | translate }}</button>
        </span> -->
        <div class="fota-btn" *ngIf="currentTabValue === 'solution' && !hideActionButtons">
          <button mat-raised-button class="button btn-background" *ngIf="groupCount" (click)="selectGroups()">
            <span *ngIf="!selectAllGroups">{{ 'SELECT_ALL' | translate }}</span>
            <span *ngIf="selectAllGroups">{{ 'UNSELECT_ALL' | translate }}</span>
          </button>
          <button mat-raised-button class="button btn-width btn-background" [class.disabled]="!selection.hasValue()"  *ngIf="groupCount"
          (click)="openModal(content)">
          <span>{{ 'MANAGE_TAGS' | translate }} </span>
        </button>
        </div>
    <div class="table-container" *ngIf="data">
      <mat-table [dataSource]="data" class="table-data">
        <div *ngFor="let column of columns">
          <ng-container *ngIf="column==='select'" matColumnDef="select">
            <mat-header-cell class="header-style" *matHeaderCellDef>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
               (change)="$event ? selection.toggle(row['groupId']) : null; selectAllGroupsCheckBox()"
                [checked]="selection.isSelected(row['groupId'])" [disabled]="selectAllGroups">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <ng-container *ngIf="column!=='select'" [matColumnDef]="column">
            <mat-header-cell class="header-style" *matHeaderCellDef>
              <header class="device-table heading-label">{{column | appendSpace | titlecase}}</header>
            </mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName">
              <span *ngIf="column==='status'">
                <mat-slide-toggle [disabled]="!checkPermission('GRP_EN_ACT') && !checkPermission('GRP_EN_DACT')"
                  [checked]="false" [(ngModel)]="dataName[column]" (change)="gotoConfirmDialog(dataName, 'edit')">
                </mat-slide-toggle>
              </span>
              <span *ngIf="column!=='status' && column !== 'interfaceProvider'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column === 'interfaceProvider'">
                <img *ngIf="dataName[column]==='BOSCH_IoT_SUITE'" class="provider-icon" [src]="boschIotIcon" [title]="dataName[column]" />
                <img *ngIf="dataName[column]==='AZURE'" class="provider-icon" [src]="azureIcon" [title]="dataName[column]"/>
                <img *ngIf="dataName[column]==='AWS_IoT_Core'" class="provider-icon" [src]="awsIcon" [title]="dataName[column]"/>
                <img *ngIf="dataName[column]==='ZELIOT'" class="provider-icon" [src]="zeliotIcon" [title]="dataName[column]"/>
              </span>
              <span *ngIf="column==='action'">
                <span class="action-icons">
                <img *ngIf="checkPermission('GRP_EN_DEL')" [src]="deleteImage" class="icon-space modify-button"
                  [title]="'DELETE' | translate" (click)="gotoConfirmDialog(dataName, 'delete')">
                    <img *ngIf="dataName['interfaceProvider']==='AWS_IoT_Core'" [src]="detailsImage" [title]="'MORE_INFO' | translate" class="action-button" (click)="goToDetailsPage(dataName)"/>
                  </span>
              </span>
             
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row class="table-header-row" *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
    <mat-paginator *ngIf="data" [pageSizeOptions]="pageSizeOptions" [length]="groupCount" [pageSize]="pageSize"
      showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>

<ng-template #content let-modal>
  <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{'MANAGE_TAGS' | translate}} </h5>
    </div>
    <div class="modal-body">
      <app-static-tags (availableTags)="populateExistingTags()"
      (selectedTags)="addOrRemoveTags($event)"> 
   </app-static-tags>
    </div>
  </div>
  
</ng-template>