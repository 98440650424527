import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { UsageReportComponent } from './usage-report/usage-report.component';

const routes: Routes = [
  {
    path: 'reports/usage-reports',
    component: UsageReportComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'REP_R', data: {} }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
