<!--
  ~ Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
-->
<div>
  <div class="policy-header">
    <div class="line">
      <div class="heading-container">
        <div class="heading-content">
          <h1 class="policy-heading">{{ footerList[activeTabIndex].option_label | translate }}</h1>
          <h3 class="policy-heading-desc">{{ footerList[activeTabIndex].heading_desc | translate }}</h3>
          <div class="dot">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="policy-container">
    <mat-tab-group dynamicHeight [selectedIndex]="selected.value" (selectedIndexChange)="switchTab($event)" mat-stretch-tabs class="stretched-tabs">
      <mat-tab *ngFor="let tab of footerList" label="{{tab.option_label | translate }}">
        <div class="policy-content">
          <p *ngFor="let item of tab.data" [ngClass]="item.class?item.class:''">
            <b class="policy-sub-heading" *ngIf="item.heading === 'COPYRIGHT'">&copy; </b>
            <b class="policy-sub-heading" *ngIf="item.heading">{{ item.heading | translate }}</b>
            <br *ngIf="item.heading"><a *ngIf="item.link" target="_blank" href="{{item.link}}">{{ item.content | translate }}</a>
            <span *ngIf="!item.link">{{ item.content | translate }}</span>
            <span *ngIf="item.sub_content">
               <p *ngFor="let subItem of item.sub_content">
                <i>{{ subItem.heading | translate }}</i>
                <br><a *ngIf="subItem.link" target="_blank" href="{{subItem.link}}">{{ subItem.content | translate  }}</a>
                <span *ngIf="!subItem.link">{{ subItem.content | translate }}</span>
          </p>
          </span>
          </p>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>