import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { PieChartService } from 'src/app/providers/pie-chart-service';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import {
  AccountControllerService, BusinessProfileControllerService, ServiceMasterControllerService,
  SolutionTypeControllerService,
  TenantControllerService
} from 'src/app/services/Platform/services';
import { ReportControllerService } from 'src/app/services/Report/services';
import { DOWNLOAD_IMAGE, REPORT_ICON } from 'src/app/shared/constants/strings';
import { MatTableDataSource } from '@angular/material/table';
import { DatePipe } from '@angular/common';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-usage-report',
  templateUrl: './usage-report.component.html',
  styleUrls: ['./usage-report.component.scss']
})
export class UsageReportComponent implements OnInit {
  public data;
  public dropDownValues = {};
  public usageReportData;
  public chartProperties = {};
  public totalAggrCount = 0;
  public aggregatedReportsList;
  public reportIcon;
  public downloadIcon;
  public color;
  public showSpinner :any;
  public charts = [];
  public dataSource = [];
  public ignoreList = ['id', 'active', 'color', 'y', 'percentileAvgMonth', 'percentileAvgYear', 'percentile', 'others', 'name',
    'businesProfileList', 'totalAgregate'];
  public columns: any[];
  @ViewChild('charts', { static: true }) public chartElement: ElementRef;
  tabularData = [];
  searchParams: any;
  selectedServiceList = [];
  public viewType = 'charts';

  constructor(private translate: TranslateService, private tenantControllerService: TenantControllerService,
    private accountControllerService: AccountControllerService,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private httpService: HttpClient, private reportControllerService: ReportControllerService,
    private serviceMasterControllerService: ServiceMasterControllerService,
    private objectToIdConversionService: ObjectToIdConversionService, private pieChartService: PieChartService,
    private responseHandlerService: ResponseHandlerService, private datePipe: DatePipe,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.httpService
      .get('./assets/form-fields/usage-report.json')
      .subscribe(data => {
        this.data = data as string[];
        this.usageReportData = this.data['fields'];
        this.data['fields'].forEach(field => {
          if (field.type === 'date' && field.name === 'toDate') {
            field.value = this.datePipe.transform(new Date(), 'MM-yyyy');
          }
        });
      });
    this.reportIcon = REPORT_ICON;
    this.downloadIcon = DOWNLOAD_IMAGE;
    this.getServiceList();
    this.getSolutionType();
    this.color = [
      '#FF8C00', '#35a6c9', '#FF5E0E', '#FBCEB1', '#FCAE1E', '#FF6347', '#FFF1D7', '#ffca18', '#E26310', '#FFA836', '#C35214', '#FABA5F',
      '#8A3324', '#D99058', '#FFC82A', '#E2725B', '#D8A175',
      '#BB772E', '#F1B42F', '#78BE20',
      '#f75133', 'grey'
    ];
  }

  public getSolutionType() {
    this.solutionTypeControllerService.findAllSolutionTypes()
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
        }
      );
  }

  public getBusinessProfiles(tenantId) {
    this.dropDownValues['profile'] = [];
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['profile'] = resp;
          } else {
            this.dropDownValues['profile'] = [];
          }
        },
        err => {
          this.dropDownValues['profile'] = [];
        }
      );
  }

  public getAccounts(solutionType: []) {
    this.accountControllerService
      .findByAccountIdBasic1({ solutionTypeIds: solutionType })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['account'] = resp;
          } else {
            this.dropDownValues['account'] = [];
          }
        },
        err => {
          this.dropDownValues['account'] = [];
        }
      );
  }

  public getTenants(accountId) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        err => {
          this.dropDownValues['tenant'] = [];
        }
      );
  }

  public getServiceList() {
    this.serviceMasterControllerService.getDataRetentionServiceList({ allServices: 'true', onlyReports: 'true' }).subscribe(resp => {
      if (resp && resp.length) {
        this.dropDownValues['serviceType'] = resp;
      } else {
        this.dropDownValues['serviceType'] = [];
      }
    },
      err => {
        this.dropDownValues['serviceType'] = [];
      }
    );
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      switch ($event.child.child) {
        case 'account':
          this.getAccounts($event.value);
          break;
        case 'tenant':
          this.getTenants($event.value);
          break;
        case 'profile':
          this.getBusinessProfiles($event.value);
          break;
      }
    }
  }

  public searchUsageReportsEmitter(searchData, requestType) {
    this.viewType = 'charts';
    if (searchData.serviceType && searchData.solutionType && searchData.fromDate &&
      searchData.serviceType.length > 0 && searchData.solutionType.length > 0) {
      if (searchData.account && !searchData.profile) {
        this.charts.forEach(chart => {
          chart = this.pieChartService.destroyChart(chart);
        });
        this.responseHandlerService.returnToastMessage('warning', 'PLEASE_SELECT_MANDATORY_FIELDS');
      } else {
        this.charts.forEach(chart => {
          chart = this.pieChartService.destroyChart(chart);
        });
        this.charts = [];
        const solutionTypeValues = [];
        const profileValues = [];
        const serviceTypeValue = [];
        if (searchData.serviceType) {
          searchData.serviceType.forEach(serviceType => {
            serviceTypeValue.push(this.objectToIdConversionService.convertObjectToId(serviceType));
          });
        }
        if (searchData.solutionType) {
          solutionTypeValues.push(this.objectToIdConversionService.convertObjectToId(searchData.solutionType));
          /** Below code as been commented to provide a quick fix to a prod issue, because we had to convert solution
           * type dropdown from multiselect to single select
              searchData.solutionType.forEach(solutionType => {
              solutionTypeValues.push(this.objectToIdConversionService.convertObjectToId(solutionType));
           }); */
        }
        if (searchData.profile) {
          searchData.profile.forEach(profile => {
            profileValues.push(this.objectToIdConversionService.convertObjectToId(profile));
          });
        }

        if (!searchData.toDate) {
          searchData.toDate = this.datePipe.transform(new Date(), 'MM-yyyy');

        }
        const searchObj = {
          serviceType: serviceTypeValue,
          solutionType: solutionTypeValues,
          accountId: searchData.account && { id: searchData.account },
          tenantId: searchData.tenant && { id: searchData.tenant },
          businessProfileId: profileValues,
          fromDate: searchData.fromDate,
          toDate: searchData.toDate
        };
        this.getChartData(searchObj, requestType);
        this.searchParams = searchData;
      }
    } else {
      this.responseHandlerService.returnToastMessage('warning', 'PLEASE_SELECT_MANDATORY_FIELDS');
    }
  }

  public getChartData(reportsObj, requestType) {
    let serviceReportList;
    this.showSpinner = this.loaderService.showProgressBar();
    this.reportControllerService.usageReport({ body: reportsObj }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.aggregatedReportsList = resp;
      this.aggregatedReportsList.forEach(report => {
        const chartDataObject = {
          title: '',
          subtitle: '',
          tooltip: '',
          details: []
        };
        chartDataObject.title = report['serviceName'];
        serviceReportList = report['solutiontypeList'];
        if (serviceReportList.length < 1) {
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
        } else {
          chartDataObject.details = this.parsedata(serviceReportList, report['totalAgregate']);
          if (requestType === 'tabular') {
            this.viewType = 'tabular';
            this.tabularData[report['serviceName']] = chartDataObject.details;
            if (!this.selectedServiceList.includes(report['serviceName'])) {
              this.selectedServiceList.push(report['serviceName']);
            }
            this.getUsageReportTabularData(this.tabularData[report['serviceName']], report['serviceName']);
          } else {
            this.viewType = 'charts';
            this.dataSource = [];
            this.columns = null;
            this.charts.push(this.pieChartService.createChart(this.chartElement.nativeElement, chartDataObject));
          }
        }
      });
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public parsedata(chartData, totalAggregateService) {
    const tempArray = [];
    let index = 0;
    chartData.forEach(solutionType => {
      solutionType['businesProfileList'].forEach(bp => {
        bp['solutionTypeName'] = solutionType['name'];
        bp['profileName'] = bp['name'];
        bp['name'] = (this.getTranslatedValues('BP: ' + bp['name'] + '<br>' + 'ST: ' + solutionType['name']));
        bp['y'] = (bp['totalAgregate'] * 100) / totalAggregateService;
        bp['count'] = bp['totalAgregate'];
        bp['color'] = this.color[index++];
        tempArray.push(bp);
      });
      if (solutionType['others']) {
        solutionType['name'] = (this.getTranslatedValues('OTHERS' + '-' + solutionType['name']));
        solutionType['solutionTypeName'] = solutionType['name'];
        solutionType['y'] = (solutionType['others'] * 100) / totalAggregateService;
        solutionType['count'] = solutionType['others'];
        solutionType['color'] = this.color[index++];
        tempArray.push(solutionType);
      }
    });
    return tempArray;
  }

  public getTranslatedValues(key: string) {
    let translatedValue;
    this.translate.get(key).subscribe((translatedKey: string) => {
      translatedValue = translatedKey;
    });
    return translatedValue;
  }

  public getUsageReportTabularData(tabularData, serviceName) {
    if (tabularData) {
      this.showSpinner = this.loaderService.showProgressBar();
      this.dataSource[serviceName] = new MatTableDataSource<any>(tabularData);
      this.columns = this.filterColumns(Object.keys(tabularData[0]));
      this.loaderService.hideProgressBar(this.showSpinner);
    } else {
      this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
    }
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }
}
