<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="operationConfigurationIcon" />
        <span>{{'OPERATIONS_CONFIGURATION' |translate}}</span>
      </span>
    </div>
    <mat-accordion class="accordion-style">
      <mat-expansion-panel class="configuration-card">
        <mat-expansion-panel-header class="card-header-style" (click)="onAccordionExpansion('infrastructure')">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon" [src]="infrastructureImage" />
            <span>{{ 'INFRA_SERVICES' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-tab-group (selectedTabChange)="matChange($event)">
          <mat-tab [label]="tab.label | translate" *ngFor="let tab of matTabOptions">
          </mat-tab>
        </mat-tab-group>
        <div class="create-form-spacing">
          <mat-form-field *ngFor="let dropdownItem of businessProfileDropdownList" class="dropdown-width">
            <mat-label class="label">{{dropdownItem.label | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select [placeholder]="'SELECT' | translate"
              (selectionChange)="updateDropdownValues($event, dropdownItem.child, 'infraStructure')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let value of dropDownValues[dropdownItem.name]" [value]="value.id" required>
                {{value.name}}
              </mat-option>
            </mat-select>
            
          </mat-form-field>
          <div class="clear-float"></div>

          <mat-accordion class="accordion-style" *ngIf="infraDataSource || mongoServiceDataSource" multi>
            <mat-expansion-panel class="configuration-card" [expanded]="true" *ngIf="infraDataSource">
              <mat-expansion-panel-header class="card-header-style">
                <mat-panel-title class="card-header-label">
                  <img class="accordion-icon" [src]="infrastructureImage" />
                  <span>{{ 'CLOUD_INFRA' | translate }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>
          <div class="table-container">
            <mat-table [dataSource]="infraDataSource" class="bottom-space table-data" *ngIf="infraDataSource">
              <div *ngFor="let column of columns">
                <ng-container [matColumnDef]="column">
                  <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase | translate}}
                  </mat-header-cell>
                  <mat-cell class="tooltip mat-cell" *matCellDef="let dataName"
                    [ngClass]="{'subscription-add-button': ((column==='subscriptionId' || column==='subscriptionName' || column==='fromCurrencyType') && !dataName[column])}">
                    <span *ngIf="column!=='action'" title="{{dataName[column]}}" class="cell-value">
                      {{dataName[column]}}
                    </span>
                    <!-- configure subscription -->
                    <button
                      *ngIf="infrastructureList && (column==='subscriptionId' || column==='subscriptionName' || column==='fromCurrencyType') && !dataName[column]"
                      type="button" mat-icon-button color="primary"
                      (click)="openSubscriptionDialog(subscriptionContent, dataName, 'add')" [title]="'ADD' | translate"><span
                        class="add-icon">+</span>
                    </button>
                    <span
                      *ngIf="column === 'action' && infrastructureList && (dataName['subscriptionId'] || dataName['subscriptionName'])">
                      <img [src]="editImage" class="modify-button" [title]="'EDIT' | translate"
                        (click)="openSubscriptionDialog(subscriptionContent, dataName, 'edit')">
                    </span>
                    <!-- manage subscription -->
                    <span *ngIf="column === 'actions' && subscriptionList && subscriptionList.length"
                      class="subscrpn-action-span">
                      <button *ngIf="dataName['url']" (click)="callInReviewAPI(dataName, 'infra')" class="subscrpn-action-icons">
                        <a href="{{dataName['url']}}" target="_blank">
                          <img [src]="downloadIcon" [title]="'DOWNLOAD_FILE' | translate" /></a>
                      </button>
                      <button *ngIf="dataName['reviewable'] && dataName['status'] === 'In Review'"
                        (click)="openReviewExcelDialog(reviewContent, dataName, 'R', 'infra','cloudInfra')" class="subscrpn-action-icons">
                        <img [src]="reviewOKIcon" [title]="'REVIEW_OK' | translate">
                      </button>
                      <button *ngIf="dataName['reviewable'] && dataName['status'] === 'In Review'"
                        (click)="openReviewExcelDialog(reviewContent, dataName, 'NR', 'infra','cloudInfra')" class="subscrpn-action-icons">
                        <img [src]="reviewNotOkIcon" [title]="'REVIEW_NOT_OK' | translate">
                      </button>
                      <button class="subscrpn-action-icons">
                        <img [src]="dataName['lock'] ? lockCloseIcon : lockOpenIcon">
                      </button>
                      <button *ngIf="!dataName['lock'] && dataName['active'] && dataName['status'] !== 'In Review'"
                        (click)="openModal(subscriptionContent, dataName['orderId'],'cloudInfra')" class="subscrpn-action-icons">
                        <img [src]="uploadIcon" [title]="'UPLOAD_FILE' | translate">
                      </button>
                      <button *ngIf="dataName['id']"
                        (click)="getSubscriptionActivityLog(dataName['id'])" class="subscrpn-action-icons">
                        <img [src]="activityLogIcon" [title]="'ACTIVITY_LOG' | translate">
                      </button>
                    </span>
                  </mat-cell>
                </ng-container>
              </div>
              <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
              <mat-row *matRowDef="let data; columns: columns;"></mat-row>
            </mat-table>
          </div>
             </mat-expansion-panel>

              <mat-expansion-panel class="configuration-card" [expanded]="true" *ngIf="mongoServiceDataSource">
                <mat-expansion-panel-header class="card-header-style">
                  <mat-panel-title class="card-header-label">
                    <img class="accordion-icon" [src]="infrastructureImage" />
                    <span>{{ 'MONGO_SERVICE' | translate }}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="table-container">
                  <mat-table [dataSource]="mongoServiceDataSource" class="bottom-space table-data" *ngIf="mongoServiceDataSource">
                    <div *ngFor="let column of mongoServiceColumns">
                      <ng-container [matColumnDef]="column">
                        <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase | translate}}
                        </mat-header-cell>
                        <mat-cell class="tooltip mat-cell" *matCellDef="let dataName"
                          [ngClass]="{'subscription-add-button': ((column==='organizationId' || column==='organizationName') && !dataName[column])}">
                          <span *ngIf="column!=='action'" title="{{dataName[column]}}" class="cell-value">
                            {{dataName[column]}} 
                          </span>
                          <!-- configure subscription -->
                          <button
                            *ngIf="mongoServiceList && (column==='organizationId' || column==='organizationName') && !dataName[column]"
                            type="button" mat-icon-button color="primary"
                            (click)="openMongoSubscriptionDialog(subscriptionContent, dataName, 'add')" [title]="'ADD' | translate"><span
                              class="add-icon">+</span>
                          </button>
                          <span
                            *ngIf="column === 'action' && mongoServiceList && (dataName['organizationId'] || dataName['organizationName'])">
                            <img [src]="editImage" class="modify-button" [title]="'EDIT' | translate"
                              (click)="openMongoSubscriptionDialog(subscriptionContent, dataName, 'edit')">
                          </span>
                          <!-- manage subscription -->
                          <span *ngIf="column === 'actions' && mongoSubscriptionList && mongoSubscriptionList.length"
                            class="subscrpn-action-span">
                            <button *ngIf="dataName['url']" (click)="callInReviewAPI(dataName, 'infra')" class="subscrpn-action-icons">
                              <a href="{{dataName['url']}}" target="_blank">
                                <img [src]="downloadIcon" [title]="'DOWNLOAD_FILE' | translate" /></a>
                            </button>
                            <button *ngIf="dataName['reviewable'] && dataName['status'] === 'In Review'"
                              (click)="openReviewExcelDialog(reviewContent, dataName, 'R', 'infra','mongoService')" class="subscrpn-action-icons">
                              <img [src]="reviewOKIcon" [title]="'REVIEW_OK' | translate">
                            </button>
                            <button *ngIf="dataName['reviewable'] && dataName['status'] === 'In Review'"
                              (click)="openReviewExcelDialog(reviewContent, dataName, 'NR', 'infra','mongoService')" class="subscrpn-action-icons">
                              <img [src]="reviewNotOkIcon" [title]="'REVIEW_NOT_OK' | translate">
                            </button>
                            <button class="subscrpn-action-icons">
                              <img [src]="dataName['lock'] ? lockCloseIcon : lockOpenIcon">
                            </button>
                            <button *ngIf="!dataName['lock'] && dataName['active'] && dataName['status'] !== 'In Review'"
                              (click)="openModal(subscriptionContent, dataName['orderId'],'mongoService')" class="subscrpn-action-icons">
                              <img [src]="uploadIcon" [title]="'UPLOAD_FILE' | translate">
                            </button>
                            <button *ngIf="dataName['id']"
                            (click)="getSubscriptionActivityLog(dataName['id'])" class="subscrpn-action-icons">
                            <img [src]="activityLogIcon" [title]="'ACTIVITY_LOG' | translate">
                          </button>
                          </span>
                        </mat-cell>
                      </ng-container>
                    </div>
                    <mat-header-row *matHeaderRowDef="mongoServiceColumns"></mat-header-row>
                    <mat-row *matRowDef="let data; columns: mongoServiceColumns;"></mat-row>
                  </mat-table>
                </div>
                </mat-expansion-panel>
              </mat-accordion>

          <ng-template #subscriptionContent let-modal>
            <div  class="close-btn subscriptionContent"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
            <div class="modal-width">
              <div *ngIf="infrastructureList && infrastructureList.length || mongoServiceList && mongoServiceList.length">
                <div class="modal-body">
                  <app-dynamic-form [jsonFile]="jsonFileLoaded" [formData]="formData" [dropDownValues]="dropDownValues"
                    (formEmitter)="addSubscription($event)" (resetForm)="resetForm($event)">
                  </app-dynamic-form>
                </div>
              </div>
              <div *ngIf="subscriptionList && subscriptionList.length || mongoSubscriptionList && mongoSubscriptionList.length">
                <div class="modal-header">
                  <h5 class="modal-title" id="modal-basic-title">{{'FILE_UPLOAD' | translate}} </h5>
                </div>
                <div class="modal-body">
                  <input type="file" #csvFileInputType class="file-uploader" id="file" (click)="csvFileInputType.value"
                    (change)="selectFiles($event)" />
                </div>
                <div class="modal-footer">
                  <button [disabled]="!fileForUpload" (click)="uploadExcelFile()" mat-raised-button
                    class="button-submit">{{
                    'UPLOAD_FILE' | translate }}</button>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="configuration-card">
        <mat-expansion-panel-header class="card-header-style" (click)="onAccordionExpansion('peripheral')">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon" [src]="peripheralImage" />
            <span>{{ 'PERIPHERAL_SERVICES' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <mat-form-field *ngFor="let dropdownItem of businessProfileDropdownList" class="dropdown-width">
            <mat-label class="label">{{dropdownItem.label | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select [placeholder]="'SELECT' | translate"
              (selectionChange)="updateDropdownValues($event, dropdownItem.child, 'peripheralServices')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let value of dropDownValues[dropdownItem.name]" [value]="value.id" required>
                {{value.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="clear-float"></div>
        <div *ngIf="peripheralOrderServices && peripheralOrderServices.length" class="table-container">
          <form [formGroup]="peripheralGridForm">
            <mat-table [dataSource]="peripheralOrderDataSource" class="bottom-space table-data">
              <div *ngFor="let column of columns">
                <ng-container [matColumnDef]="column">
                  <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase | translate
                    }}
                  </mat-header-cell>
                  <mat-cell class="tooltip mat-cell" *matCellDef="let dataName" [ngClass]="column">
                    <span title="{{dataName[column]}}" class="cell-value">
                      {{ dataName[column] }}
                    </span>
                  </mat-cell>
                </ng-container>
              </div>
              <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
              <mat-row *matRowDef="let data; columns: columns;"></mat-row>
            </mat-table>
          </form>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="configuration-card">
        <mat-expansion-panel-header class="card-header-style" (click)="onAccordionExpansion('saas')">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon" [src]="saasImage" />
            <span>{{ 'SOFTWARE_SERVICES' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <mat-form-field *ngFor="let dropdownItem of businessProfileDropdownList" class="dropdown-width">
            <mat-label class="label">{{dropdownItem.label | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select [placeholder]="'SELECT' | translate"
              (selectionChange)="updateDropdownValues($event, dropdownItem.child, 'saasServices')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let value of dropDownValues[dropdownItem.name]" [value]="value.id" required>
                {{value.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="clear-float"></div>
        <div *ngIf="saasOrdersList && saasOrdersList.length" class="table-container">
          <mat-table [dataSource]="saasOrderDataSource" class="bottom-space table-data">
            <div *ngFor="let column of columns">
              <ng-container [matColumnDef]="column">
                <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase | translate }}
                </mat-header-cell>
                <mat-cell class="tooltip mat-cell" *matCellDef="let dataName" [ngClass]="column">
                  <span *ngIf="column!=='consumptionUnit'" title="{{dataName[column]}}" class="cell-value">
                    {{ dataName[column] }}
                  </span>
                  <span *ngIf="column === 'actions' && saasOrdersList && saasOrdersList.length"
                    class="subscrpn-action-span">
                    <button *ngIf="dataName['url']" (click)="callInReviewAPI(dataName, 'gmap')" class="subscrpn-action-icons">
                      <a href="{{dataName['url']}}" target="_blank">
                        <img [src]="downloadIcon" [title]="'DOWNLOAD_FILE' | translate" /></a>
                    </button>
                    <button *ngIf="dataName['reviewable'] && dataName['status'] === 'In Review'"
                      (click)="openReviewExcelDialog(reviewContent, dataName, 'R', 'gmap')" class="subscrpn-action-icons">
                      <img [src]="reviewOKIcon" [title]="'REVIEW_OK' | translate">
                    </button>
                    <button *ngIf="dataName['reviewable'] && dataName['status'] === 'In Review'"
                      (click)="openReviewExcelDialog(reviewContent, dataName, 'NR', 'gmap')" class="subscrpn-action-icons">
                      <img [src]="reviewNotOkIcon" [title]="'REVIEW_NOT_OK' | translate">
                    </button>
                    <button class="subscrpn-action-icons">
                      <img [src]="dataName['lock'] ? lockCloseIcon : lockOpenIcon">
                    </button>
                    <button *ngIf="!dataName['lock'] && dataName['isGmapsService'] && dataName['status'] !== 'In Review'"
                      (click)="openModal(saasContent, dataName['orderId'])" class="subscrpn-action-icons">
                      <img [src]="uploadIcon" [title]="'UPLOAD_FILE' | translate">
                    </button>
                    <button *ngIf="dataName['gmapMetaDataId']"
                      (click)="getGmapActivityLog(dataName['gmapMetaDataId'])" class="subscrpn-action-icons">
                      <img [src]="activityLogIcon" [title]="'ACTIVITY_LOG' | translate">
                    </button>
                  </span>
                </mat-cell>
              </ng-container>
            </div>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let data; columns: columns;"></mat-row>
          </mat-table>
          <section class="section">
          </section>
        </div>
        <ng-template #saasContent let-modal>
          <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
          <div class="modal-width">
            <div *ngIf="saasOrdersList && saasOrdersList.length">
              <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title">{{'FILE_UPLOAD' | translate}} </h5>
              </div>
              <div class="modal-body">
                <input type="file" #csvFileInputType class="file-uploader" id="file" (click)="csvFileInputType.value"
                  (change)="selectFiles($event)" />
              </div>
              <div class="modal-footer">
                <button [disabled]="!fileForUpload" (click)="uploadGMapExcelFile()" mat-raised-button
                  class="button-submit">{{
                  'UPLOAD_FILE' | translate }}</button>
              </div>
            </div>
          </div>
        </ng-template>
      </mat-expansion-panel>

      <mat-expansion-panel class="configuration-card">
        <mat-expansion-panel-header class="card-header-style" (click)="onAccordionExpansion('billing-list')">
          <mat-panel-title class="card-header-label">
            <img class="accordion-icon" [src]="billingListImage" />
            <span>{{ 'BILLING_LIST' | translate }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="panel-body">
          <mat-form-field *ngFor="let dropdownItem of businessProfileDropdownList" class="dropdown-width">
            <mat-label class="label">{{dropdownItem.label | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select [placeholder]="'SELECT' | translate"
              (selectionChange)="updateDropdownValues($event, dropdownItem.child, 'billingList')">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let value of dropDownValues[dropdownItem.name]" [value]="value.id" required>
                {{value.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label class="label">{{ 'BILLING_PERIOD' | translate}}
            </mat-label>
            <input [ngModel]="billingListPeriod" [placeholder]="'SELECT' | translate" />
            <input matInput [matDatepicker]="picker" hidden disabled class="dynamic-input">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false" startView="multi-year"
              (monthSelected)="getBillingListByMonth($event, picker)">
            </mat-datepicker>
          </mat-form-field>
        </div>
        <div class="clear-float"></div>
        <div *ngIf="billingList && billingList.length" class="table-container">
          <mat-table [dataSource]="billingListDataSource" class="bottom-space table-data">
            <div *ngFor="let column of columns">
              <ng-container [matColumnDef]="column">
                <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase | translate}}
                </mat-header-cell>
                <mat-cell class="tooltip mat-cell" *matCellDef="let dataName" [ngClass]="column">
                  <span *ngIf="column!=='status'" title="{{dataName[column]}}" class="cell-value">
                    {{dataName[column]}}
                  </span>
                  <span *ngIf="column==='status'" title="{{dataName[column]}}" class="cell-value billing-order-status">
                    <a (click)="getWorkflowExecutionStatus(dataName)">{{dataName[column]}}</a>
                    <img *ngIf="dataName['sentToMss']" [src]="Ok_Icon" class="billing-order-ok-icon">
                  </span>
                </mat-cell>
              </ng-container>
            </div>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let data; columns: columns;"></mat-row>
          </mat-table>
        </div>
        <div *ngIf="workflowExecutionStatus && workflowExecutionStatus.length" class="workflow-execution-icons-div">
          <div *ngFor="let executionStatus of workflowExecutionStatus">
            <a *ngIf="executionStatus['retryable']" class="retry-workflow" (click)="retryWorkflow()">{{ 'RETRY' |
              translate }}</a>
            <img [src]="executionStatus['workflowStepIcon']" class="workflow-step-success-icon"
              [ngClass]="{'workflow-step-failed-icon': executionStatus['status'] === 'FAILED'}">
            <img [src]="rightArrowIcon" class="workflow-arrow-icon">
            <p class="workflow-step-status">{{ executionStatus['workflowStepName'] }}</p>
            <a *ngIf="executionStatus['retryable']"  class="error-log" (click)="displayErrorLog(errorLog, executionStatus['statusReason'])">
              {{'WHAT_WENT_WRONG' | translate}}
            </a>
          </div>
          <span *ngIf="billingOrderStatus" class="workflow-status">{{ billingOrderStatus }}</span>
        </div>
      </mat-expansion-panel>

    </mat-accordion>

    <ng-template #reviewContent let-modal>
      <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
      <div class="modal-width">
        <div class="modal-body">
          <mat-label class="label">{{ 'REVIEW_COMMENTS' | translate }}
          </mat-label>
          <textarea class="text-area" matInput [(ngModel)]="reviewComments"></textarea>
          <section class="section">
            <button (click)="reviewExcel(serviceType)" mat-raised-button class="button">{{
              'SUBMIT' | translate }}</button>
          </section>
        </div>
      </div>
    </ng-template>
    <ng-template #errorLog let-modal let-data>
      <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
      <div class="modal-width">
        <div class="modal-body">
          <mat-label class="label">{{ 'WHAT_WENT_WRONG' | translate }}
          </mat-label>
          <p>{{data | translate}}</p>
        </div>
      </div>
    </ng-template>
  </section>
</section>
