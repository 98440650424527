import { Component, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DEVICE_ICON, C2D_WHITE_ICON } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { BciLoaderService } from '@bci-web-core/core';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { MethodControllerService} from 'src/app/services/C2D/services/method-controller.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-c2d-method',
  templateUrl: './c2d-method.component.html',
  styleUrls: ['./c2d-method.component.scss']
})
export class C2dMethodComponent implements OnInit {
  public jsonFileLoaded;
  public showSpinner :any;
  public pageKey: string;
  public c2dIcon;
  public deviceId: string;
  public respStatus: any;
  public respPayload: any;
  asideMenus: Array<AsideCard>;
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  constructor(private responseHandlerService: ResponseHandlerService, private router: Router,
    private rightsideNavitemsService: RightsideNavitemsService, 
    private loaderService: BciLoaderService, private route: ActivatedRoute,
    private MethodControllerService: MethodControllerService) { }

  ngOnInit(): void {
    this.jsonFileLoaded = 'c2d-method-integration';
    this.pageKey = 'c2d';
    this.deviceId = this.route.snapshot.paramMap.get('serialNo');
    this.respStatus = null;
    this.respPayload = null;
    this.c2dIcon = C2D_WHITE_ICON;
  }

  public callMethod(data: any) {
    this.respStatus = null;
    this.respPayload = null;
    this.showSpinner = this.loaderService.showProgressBar();
    const MethodInvokeDto = {
      methodName: data['methodName'],
      payload: {key: data['methodPayload'] }
    };
    let accessKey = data['accessKey'];
      this.MethodControllerService
      .invokeAppMethod({ id: this.deviceId, body: MethodInvokeDto, header: accessKey})
      .subscribe(
        resp => {
          if (resp) {
            this.respStatus = resp.status;
            this.respPayload = JSON.stringify(resp.payload) ;
          }
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'C2D_INVOKE_SUCCESS');
        },
        err => {
          this.respStatus = err.status;
          this.respPayload = err.error.message;
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
 }

 public resetForm($event) {
  if ($event) {
    this.dynamicForm.createForm.reset();
    this.respStatus = null;
    this.respPayload = null;
  }
}
}
