import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
  SmsTemplateControllerService,
  BulkSmsUploadControllerService
} from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { DOWNLOAD_ICON, REJECT_ICON, SMS_ICON } from 'src/app/shared/constants/strings';
import { TranslationService } from 'src/app/providers/translation-service';
import { DataSharingService } from 'src/app/providers/data-sharing.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-sms-bulk-upload',
  templateUrl: './sms-bulk-upload.component.html',
  styleUrls: ['./sms-bulk-upload.component.scss']
})
export class SmsBulkUploadComponent implements OnInit {

  public configurationsList: Array<String> = [];
  public bulkSmsData;
  public jsonFileLoaded: string;
  public dropDownValues: any = {};
  public searchCriteria;
  public showSpinner :any;
  public data: any[];
  public columns: any[];
  public tabName: string;
  public accountId: string;
  public tenantId: string;
  public routeAccountId: string;
  public routeTenantId: string;
  public solutionTypeId;
  public routeSolutionTypeId;
  public profileId: string;
  public routeProfileId: string;
  public senderId: string;
  public dropdownList: [];
  public permissionList: Array<String> = [];
  public ignoreList = ['profileName', 'jobId'];
  public bulkSmsTableData;
  public bulkSmsColumns;
  public currentDate = new Date();
  public selectedDateTime;
  public startDateAndTime;
  public fileForUpload;
  public bulkSmsFormData;
  public bulkSmsEmailList = [];
  public jobStartDate;
  public jobStartTime;
  public downloadImage = DOWNLOAD_ICON;
  public rejectImage = REJECT_ICON;
  public selectedIndex = 0;
  public smsIcon;
  public smsJobId;

  @ViewChildren(DynamicFormComponent)
  dynamicForms: QueryList<DynamicFormComponent>;

  constructor(
    private httpsService: HttpClient,
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService,
    private smsTempateControllerService: SmsTemplateControllerService,
    private bulkSmsControllerService: BulkSmsUploadControllerService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private _matDialog: MatDialog,
    private translate: TranslationService,
    private dataSharingService: DataSharingService,
    private loaderService: BciLoaderService

  ) {}

  ngOnInit() {
    this.smsJobId = this.route.snapshot.paramMap.get('smsJobId');
    this.routeAccountId = this.route.snapshot.paramMap.get('accountId');
    this.routeTenantId = this.route.snapshot.paramMap.get('tenantId');
    this.routeSolutionTypeId = this.route.snapshot.paramMap.get('solutionTypeId');
    this.routeProfileId = this.route.snapshot.paramMap.get('profileId');
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.httpsService
      .get('./assets/form-fields/bulk-sms-tabs.json')
      .subscribe((data) => {
        this.configurationsList = data as string[];
        this.dropdownList = this.configurationsList['dropdownList'];
        this.configurationsList = this.configurationsList['fields'];
        if (this.routeSolutionTypeId && this.routeProfileId) {
            this.selectedIndex = 1;
            this.accountId = this.routeAccountId;
            this.tenantId = this.routeTenantId;
            this.profileId = this.routeProfileId;
            this.solutionTypeId = this.routeSolutionTypeId;
            this.getTenant(this.accountId);
            this.getProfile(this.tenantId);
            this.getSolutionType(this.profileId);
            this.searchCriteria = {
              account: this.accountId,
              tenant: this.tenantId,
              businessProfile: this.profileId,
              solutionType: this.solutionTypeId };
            this.getSmsJobs();
        }
      });
    this.getAccount();
    if (this.permissionList.indexOf('SMS_BULK_R') > -1) {
      this.jsonFileLoaded = 'sms-bulk-upload';
      this.httpsService
        .get('./assets/form-fields/bulk-sms-manage.json')
        .subscribe((data) => {
          this.data = data as string[];
          this.bulkSmsData = this.data['fields'];
        });
    }
    this.smsIcon = SMS_ICON;
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined && $event.child.child !== undefined ) {
      if ( $event.child.child === 'tenant') {
        this.getTenant($event.value);
        this.accountId = $event.value;
      }
      if ($event.child.child === 'businessProfile') {
        this.getProfile($event.value);
        this.tenantId = $event.value;
      }
      if ($event.child.child === 'solutionType') {
        this.getSolutionType($event.value);
        this.profileId = $event.value;
      }
      if ($event.child.child === 'generate') {
        this.solutionTypeId = $event.value;
      }
   } else if ($event.value !== undefined && $event.child !== undefined) {
        if ( $event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'solutionType') {
          this.getSolutionType($event.value);
          this.profileId = $event.value;
          this.getSmsHeader($event.value);
        }
        if ($event.child === 'jobName') {
          this.solutionTypeId = $event.value;
        }
        if ($event.child === 'templateName') {
          this.bulkSmsTemplateHeader($event.value);
        }
    }
  }
  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getSolutionType(profileId: string) {
    const solutionTypeActiveArray = [];
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({
        businessProfileId: profileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach((element) => {
              if (element['active'] === true) {
                solutionTypeActiveArray.push(element);
              }
            });
            this.dropDownValues['solutionType'] = solutionTypeActiveArray;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  public bulkSmsTemplateHeader(headerId:any) {
    this.smsTempateControllerService
      .getSmsTemplates({ businessProfileId: this.profileId,headerId: headerId})
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            const templateNames = [];
            resp.forEach((row) => {
              templateNames.push({
                id: row['smsTemplateId'],
                name: row['smsTemplateName'],
              });
            });
            this.dropDownValues['templateName'] = templateNames;
          } else {
            this.dropDownValues['templateName'] = [];
          }
        },
        (err) => {
          this.dropDownValues['templateName'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
    }

  getSmsHeader(profileId:any) {
    this.businessProfileControllerService
      .getSmsHeaderByBusinessProfile({ businessProfileId: profileId })
      .subscribe(
        (resp) => {
          if (resp) {
            const senderId = resp.map(item => ({ 'id': item.headerId,'name': item.senderId}));
            this.dropDownValues['senderId'] = senderId;
          } else {
            this.responseHandlerService.returnToastMessage(
              'error',
              'NO_SENDER_ID_FOUND'
            );
            this.dropDownValues['senderId'] = [];
          }
        },
        (err) => {
          this.dropDownValues['senderId'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getSmsJobs() {
    if (this.solutionTypeId && this.profileId) {
      this.showSpinner = this.loaderService.showProgressBar();
      const params = {
        body: {
          profileId: this.profileId,
          solutionTypeId: this.solutionTypeId
        },
      };
      this.bulkSmsControllerService
        .getSmsJobsByprofileIdAndSolutionTypeId(params)
        .subscribe(
          (resp) => {
            if (resp && resp.length) {
              const bulkSmsArray: any [] = [];
              resp.forEach(data => {
                  bulkSmsArray.push (
                    {
                      'jobName': data['jobName'],
                      'createdBy': data['createdBy'],
                      'createdDate': data['createdDate'],
                      'profileName': data['profileName'],
                      'scheduledTime': data['scheduledTime'],
                      'status': data['status'],
                      'action': null,
                      'jobId': data['jobId']
                    }
                  );
                });
              this.loaderService.hideProgressBar(this.showSpinner);
              this.bulkSmsTableData = new MatTableDataSource<any>(bulkSmsArray);
              this.bulkSmsColumns = this.filterColumns(Object.keys(bulkSmsArray[0]));
            } else {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.bulkSmsTableData = null;
              this.bulkSmsColumns = null;
              this.responseHandlerService.returnToastMessage(
                'error',
                'NO_DATA_AVAILABLE'
              );
            }
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }
  }

  public onClose() {
    this.fileForUpload = null;
    this._matDialog.closeAll();
  }

  public selectFiles(event) {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].name.split('.').pop() === 'xlsx') {
        this.fileForUpload = event.target.files[0];
      } else {
        this.fileForUpload = null;
        this.responseHandlerService.returnToastMessage(
          'error',
          'UPLOAD_VALID_XLSX_FILE'
        );
      }
    }
  }
  public uploadExcelFile() {
    this.showSpinner = this.loaderService.showProgressBar();
    let grpData;
    const groupData = {
      businessProfileId: this.bulkSmsFormData.businessProfile,
      solutionTypeId: this.bulkSmsFormData.solutionType,
      templateId: this.bulkSmsFormData.templateName,
      headerId: this.bulkSmsFormData.senderId,
      emailIdList: this.bulkSmsEmailList,
      startDateAndTime: this.startDateAndTime,
      jobName: this.bulkSmsFormData.jobName,
    };

    const reqObj = {
      body: {
        bulksmsDto: JSON.stringify(groupData),
        file: this.fileForUpload,
      },
    };
    grpData = reqObj;
    this.bulkSmsControllerService.bulkSmsUploadExcel(grpData).subscribe(
      (resp) => {
        if (resp) {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'JOB_CREATION_SUCCESS'
          );
          this.searchCriteria = {
            account: this.accountId,
            tenant: this.tenantId,
            businessProfile: this.profileId,
            solutionType: this.solutionTypeId };
          this.dataSharingService.setData(this.searchCriteria);
          this.getSmsJobs();
          this.onClose();
          this.selectedIndex = 1;
        }
      },

      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

public onUpload(data, content) {
 this.bulkSmsFormData = data;
 this.jobStartDate = new Date(this.bulkSmsFormData['jobStartDate']);
 this.jobStartTime = this.bulkSmsFormData['jobStartTime'].match(/(\d+)(:(\d\d))?\s*(p?)/);
 if (this.jobStartDate && this.jobStartTime) {
    this.selectedDateTime = this.jobStartDate;
            this.selectedDateTime.setHours(
              Number(this.jobStartTime[1]) + (Number(this.jobStartTime[1]) < 12 && this.jobStartTime[4] ? 12 : 0)
            );
            this.selectedDateTime.setMinutes(Number(this.jobStartTime[3]) || 0);
            this.selectedDateTime.setSeconds(0, 0);
            this.startDateAndTime = this.datePipe.transform( this.selectedDateTime, 'dd-MM-yyyy HH:mm:ss');
 }
    this.bulkSmsFormData['email'].forEach((email) => {
      this.bulkSmsEmailList.push(email.email);
    });
    this._matDialog.open(content);
  }

  public onDownload() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.bulkSmsControllerService.getBulkSmsTemplateUrl().subscribe(
      (resp) => {
        if (resp) {
          this.loaderService.hideProgressBar(this.showSpinner);
          window.location.assign(resp.url);
        }
      },
      (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public gotoConfirmDialog(id) {
    const message = this.translate.translateErrorMessages('CANCEL_SELECTED_JOB');
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
  });
  dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const params = {
          jobId: id
        };
        this.showSpinner = this.loaderService.showProgressBar();
        this.bulkSmsControllerService.cancelSmsJobProcess(params).subscribe((resp) => {
          if (resp) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage( 'success', resp);
            this.getSmsJobs();
          }
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      } else {
        this._matDialog.closeAll();
      }
  });
  }

  public downloadFile(id) {
    const params = { jobId: id };
    this.bulkSmsControllerService.getSmsJobFileUrlByJobName(params).subscribe((resp) => {
        this.showSpinner = this.loaderService.showProgressBar();
        if (resp.url) {
          window.location.assign(resp.url);
          this.loaderService.hideProgressBar(this.showSpinner);
        }
    },
    (err) => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }
  public displayPieChart(data) {
      this.router.navigate(['sms/sms-job-details', { smsJobId : data['jobId']}]);
  }
}
