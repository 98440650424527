<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <div class="heading-label">
          <img class="header-icon" [src]="fotaIcon" />
          <span>{{ 'UPDATE_PACKAGE' | translate }}</span>
        </div>
        <div class="button-container">
            <button mat-raised-button [title]="'ADD_UPDATE_PACKAGE'|translate" (click)="addUpdatePackage()">{{
          'ADD_UPDATE_PACKAGE' | translate }}</button>
      </div>
    </div>
    <div class="form-spacing">
      <div *ngIf="!formData">
        <app-dynamic-form [hideSubmitButton]="true" [jsonFile]="'ota-update-package'" [dropDownValues]="dropDownValues"
          [gridLayout]="'grid-container-columns'" (valueSelected)="updateDropdownValues($event)"
          (formEmitter)="updatePackageEmitter($event)" >
        </app-dynamic-form>
      </div>
      <div *ngIf="formData">
        <app-dynamic-form [hideSubmitButton]="true" [jsonFile]="'ota-update-package'" [dropDownValues]="dropDownValues" [formData]="formData"
          [gridLayout]="'grid-container-columns'" (valueSelected)="updateDropdownValues($event)"
          (formEmitter)="updatePackageEmitter($event)">
        </app-dynamic-form>
      </div>
    </div>

   <div class="table-container">
    <mat-table [dataSource]="dataSource" class="table-data" *ngIf="updatePackageData">
      <div *ngFor="let column of columns">
        <ng-container [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
          <mat-cell class="tooltip" *matCellDef="let dataName">
            <span *ngIf="column!=='name' && column!=='active'" title="{{dataName[column]}}" class="cell-value">
              {{dataName[column]}}
            </span>
            <span *ngIf="column==='name'">
              <a class="view-details" title="{{dataName[column]}}" (click)="gotoViewPage(dataName)">{{dataName[column]}}</a>
            </span>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row *matRowDef="let data; columns: columns;"></mat-row>
    </mat-table>
  </div>
  <mat-paginator *ngIf="dataSource" [pageSizeOptions]="pageSizeOptions" [length]="packageCount" [pageSize]="pageSize"
    showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
      </section>
      </section>