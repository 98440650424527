import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CertificateRoutingModule } from './certificate-routing.module';
import { CreateCertificatesComponent } from './create-certificates/create-certificates.component';
import { ManageCertificatesComponent } from './manage-certificates/manage-certificates.component';
import { SharedModule } from '../shared/shared.module';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
  declarations: [CreateCertificatesComponent, ManageCertificatesComponent],
  imports: [
    CommonModule,
    CertificateRoutingModule,
    CommonModule,
    SharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatButtonModule,
    MatCardModule,
    TranslateModule,
    MatSlideToggleModule,
    FormsModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [
    MatButtonModule,
    MatFormFieldModule
  ]
})
export class CertificateModule { }
