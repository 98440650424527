import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
@Component({
  selector: 'app-static-tags',
  templateUrl: './static-tags.component.html',
  styleUrls: ['./static-tags.component.scss']
})
export class StaticTagsComponent implements OnInit {
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  stringArray: any = [];
  selectedValuesArray: any = [];
  filteredStrings: Observable<string[]>;
  createForm: UntypedFormGroup;
  tags = new UntypedFormControl();
  @Input() dropDownValues: any = {};
  @Output() availableTags: EventEmitter<any> = new EventEmitter();
  @Output() selectedTags: EventEmitter<any> = new EventEmitter();
  @ViewChild('inputCtrl', { static: true }) inputCtrl: ElementRef<HTMLInputElement>;
  constructor (
    private httpService: HttpClient, private formBuilder: UntypedFormBuilder,
    private responseHandlerService: ResponseHandlerService) {
      this.filteredStrings = this.tags.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => value ? this.filterStringsList(value) : this.stringArray.slice()));
    }

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      tags: ['']
    });
    this.availableTags.emit();
  }
  /**
   * @ngdoc method
   * @name StaticTags#addValues
   *
   * @methodOf
   * SharedModule.controller:StaticTags
   *
   * @description
   * Description: Triggers event to add selected tag to array
   *
   * @param {type} event: MatAutocompleteSelectedEvent
   * @return {type} null
  */
addValues(event: MatChipInputEvent): void {
  this.selectedTags.emit(event.value);
}

/**
 * @ngdoc method
 * @name StaticTags#remove
 *
 * @methodOf
 * SharedModule.controller:StaticTags
 *
 * @description
 * Description: Triggers the event for selected value to remove from list of tags
 *
 * @param {type} event: MatAutocompleteSelectedEvent
 * @return {type} null
*/
removeEntities(event) {
this.selectedTags.emit({tag: event, action: 'removeSelectedAttribute' });
}

/**
 * @ngdoc method
 * @name StaticTags#selectEntitiesFromList
 *
 * @methodOf
 * SharedModule.controller:StaticTags
 *
 * @description
 * Description: Triggers the event with selected value from list of tags
 *
 * @param {type} event: MatAutocompleteSelectedEvent
 * @return {type} null
*/
selectEntitiesFromList(event: MatAutocompleteSelectedEvent): void {
 this.selectedTags.emit({selectedTag: event.option.value});
}

/**
 * @ngdoc method
 * @name StaticTags#filterStringsList
 *
 * @methodOf
 * SharedModule.controller:StaticTags
 *
 * @description
 * Description: Filter the list of tags
 *
 * @param {type} value: string
 * @return {type} Array of filtered tags
*/
private filterStringsList(value: string): string[] {
const filterValue = value.toLowerCase();
return this.stringArray.filter(tag => tag.toLowerCase().includes(filterValue));
}
}
