import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procure-sim',
  templateUrl: './procure-sim.component.html',
  styleUrls: ['./procure-sim.component.scss']
})
export class ProcureSimComponent implements OnInit {

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit() {
  }

}
