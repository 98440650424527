<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
      <img class="page-icon-small" [src]="vendorRegIcon" />
        <span>{{'REGISTER_VENDOR' | translate}}</span>
        </span>
    </div>
    <form [formGroup]="vendorRegistrationForm">
    <div class="create-form-spacing form-fields">
      <div>
        <mat-form-field>
          <mat-label class="label">{{ 'ACCOUNT' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-select class="form-field" formControlName="account" [placeholder]=" 'SELECT' | translate"
          (selectionChange)="updateDropdownValues($event, 'tenant')">
            <mat-option>{{ 'SELECT' | translate }}</mat-option>
            <mat-option *ngFor="let val of dropDownValues['account']" [value]="val.id">
              {{val.name}}</mat-option>
          </mat-select>
        </mat-form-field>
          <div
            *ngIf="submitted || vendorRegistrationForm.controls.account?.touched && vendorRegistrationForm.controls.account?.errors">
            <div *ngIf="vendorRegistrationForm.controls.account?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'ACCOUNT' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
      </div>
      <div>
        <mat-form-field>
          <mat-label class="label">{{ 'TENANT' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-select class="form-field" formControlName="tenant" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'businessProfile')">
            <mat-option>{{ 'SELECT' | translate }}</mat-option>
            <mat-option *ngFor="let val of dropDownValues['tenant']" [value]="val.id">
              {{val.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div
        *ngIf="submitted || vendorRegistrationForm.controls.tenant?.touched && vendorRegistrationForm.controls.tenant?.errors">
        <div *ngIf="vendorRegistrationForm.controls.tenant?.errors?.required">
          <mat-error class="mat-error-class">
            {{ 'TENANT' | translate}} {{'IS_REQUIRED' | translate }}
          </mat-error>
        </div>
      </div>
      </div>
      <div>
        <mat-form-field>
          <mat-label class="label">{{ 'BUSINESS_PROFILE' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-select class="form-field" formControlName="businessProfile" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'solutionType')">
            <mat-option>{{ 'SELECT' | translate }}</mat-option>
            <mat-option *ngFor="let val of dropDownValues['businessProfile']" [value]="val.id">
              {{val.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div
        *ngIf="submitted || vendorRegistrationForm.controls.businessProfile?.touched && vendorRegistrationForm.controls.businessProfile?.errors">
        <div *ngIf="vendorRegistrationForm.controls.businessProfile?.errors?.required">
          <mat-error class="mat-error-class">
            {{ 'BUSINESS_PROFILE' | translate}} {{'IS_REQUIRED' | translate }}
          </mat-error>
      </div>
      </div>

      </div>
      <div>
        <mat-form-field>
          <mat-label class="label">{{ 'SOLUTION_TYPE' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-select class="form-field" formControlName="solutionType" [placeholder]=" 'SELECT' | translate" (selectionChange)="updateDropdownValues($event, 'vendor')">
            <mat-option>{{ 'SELECT' | translate }}</mat-option>
            <mat-option *ngFor="let val of dropDownValues['solutionType']" [value]="val.id">
              {{val.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div
        *ngIf="submitted || vendorRegistrationForm.controls.solutionType?.touched && vendorRegistrationForm.controls.solutionType?.errors">
        <div *ngIf="vendorRegistrationForm.controls.solutionType?.errors?.required">
          <mat-error class="mat-error-class">
            {{ 'SOLUTION_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
          </mat-error>
        </div>
      </div>

      </div>
      <div>
        <mat-form-field>
          <mat-label class="label">{{ 'VENDOR' | translate}}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-select class="form-field" formControlName="vendor" [placeholder]=" 'SELECT' | translate" (selectionChange)="populateVendorAttributes($event)">
            <mat-option>{{ 'SELECT' | translate }}</mat-option>
            <mat-option *ngFor="let val of dropDownValues['vendor']" [value]="val.id">
              {{val.name}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div
        *ngIf="submitted || vendorRegistrationForm.controls.vendor?.touched && vendorRegistrationForm.controls.vendor?.errors">
        <div *ngIf="vendorRegistrationForm.controls.vendor?.errors?.required">
          <mat-error class="mat-error-class">
            {{ 'VENDOR' | translate}} {{'IS_REQUIRED' | translate }}
          </mat-error>
        </div>
      </div>
      </div>
    </div>
  </form>
    <div *ngIf="vendorAttributes" class= "dynamic-form-device" >
        <app-dynamic-form-device  [deviceProperties]="vendorAttributes" [required]=true
            (formEmitter)="registerVendor($event)" (resetForm)="resetForm($event)" [columnsInForm]="'multi'"></app-dynamic-form-device>
    </div>
  </section>
</section>

<div cdkTrapFocus class="loader-background-container">
  <div class="loader-backdrop"></div>
  <div class="loader-overlay">
  </div>
</div>