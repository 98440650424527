/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, QueryList, ViewChild, ViewChildren  } from '@angular/core';
import {
  BusinessProfileControllerService, ProfileConfigurationControllerService,
  ServiceMasterControllerService
} from 'src/app/services/Platform/services';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CREATE, BACK_LEFT, PROFILE_ICON, DOCUMENT_IMAGE, DELETE_IMAGE, INFO_ICON_IMAGE,
  EDIT_IMAGE,
  BLACK_DOWNLOAD_ICON,
  DOWNLOAD_ICON} from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { EnvService } from 'src/app/env.service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { TranslationService } from 'src/app/providers/translation-service';
import { MatDialog} from '@angular/material/dialog';
import { BciLoaderService } from '@bci-web-core/core';
import { WhatsappProfileMappingControllerService} from 'src/app/services/Whatsapp/services';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder, UntypedFormBuilder, Validators, FormControl, UntypedFormGroup} from '@angular/forms';
import { DeviceSimMapControllerService, StorageAccountControllerService } from 'src/app/services/DeviceMgmt/services';
import { WhiteListDevicesControllerService } from 'src/app/services/DeviceMgmt/services/white-list-devices-controller.service';
import { DemoAccountConfigurationDto } from 'src/app/services/Platform/models';
@Component({
  selector: 'app-configure-profile',
  templateUrl: './configure-profile.component.html',
  styleUrls: ['./configure-profile.component.scss']
})

/**
  * @this vm
  * @ngdoc controller
  * @name profiles.controller:ConfigureProfile
  *
  * @description
  * description: to configure profile details of selected profile
*/
export class ConfigureProfileComponent implements OnInit {
  public dropDownValues: any = {};
  public showSpinner :any;
  public profileUsageData: any;
  public otpData: any;
  public pageKey: string;
  public profileJsonLoaded: string;
  public otpJsonLoaded: string;
  public emailConfigurationJsonLoaded: string;
  public smsConfigurationJsonLoaded: string;
  public whatsappConfigurationJsonLoaded: string;
  public emailConfigurationData: any;
  public smsConfigurationData: any;
  public whatsappConfigurationData: any;
  public businessProfileId: string;
  public selectedSolutionType: string;
  public selectedTenantId: string;
  public emailConfigId: string;
  public serviceList;
  public serviceId: string;
  public configId = {};
  public backLeftImage: string;
  public profileIcon;
  public editImage;
  public solutionTypeList;
  public files;
  public documentImage;
  public deleteImage;
  public infoIconImage;
  public selectedFile;
  asideMenus: Array<AsideCard>;
  public vendorId;
  public action;
  public whatsappDropdownList;
  public ignoreList = ['hideEditIcon', 'id'];
  public vendorIdCopy;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  @ViewChildren(DynamicFormComponent)
  dynamicForms: QueryList<DynamicFormComponent>;
  retentionData:any;
  dataRetentionForm:FormGroup;
  dataRetentionControlsData: any = [];
  dataRetentionEnable: boolean;
  fotaConfigId: any;
  fotaConfigurationJsonLoaded: string;
  fotaConfigurationData: any;
  businessTypeId: string;
  businessTypeName: string;
  businessProfileName: string;
  geoFenceEnabled: any;
  dynamicCertificateEnabled:boolean;
  storageAccountsList: any;
  storageAccountId: any;
  sampleFile: string;
  dynamicCertificateFile: Blob;
  dynamicCertificateForm: any;
  dynamicCertificates:any;
  downloadImage: any;
  certificateData: any;
  dynamicCertificateSubmit: boolean;
  public demoAccountConfigServiceList = [];
  public demoAccountConfigForm;
  public demoAccountConfigFormSubmitted = false;
  public isDemoConfigExists = false;
  defaultDemoConfig: UntypedFormGroup;

  constructor(private route: ActivatedRoute,
    private router: Router, private profileConfigurationControllerService: ProfileConfigurationControllerService,
    private translationService: TranslationService, private responseHandlerService: ResponseHandlerService,
    private rightsideNavitemsService: RightsideNavitemsService, private _matDialog: MatDialog,
    private serviceMasterControllerService: ServiceMasterControllerService, private env: EnvService,
    private whatsappVendorService: WhatsappProfileMappingControllerService, private httpsService: HttpClient,
    private businessProfileControllerService: BusinessProfileControllerService, private loaderService:BciLoaderService,
    private formBuilder:FormBuilder,private StorageAccountControllerService:StorageAccountControllerService,
    private DeviceSimMapControllerService: DeviceSimMapControllerService,
     private WhiteListDevicesControllerService:WhiteListDevicesControllerService,
     private UntypedFormBuilder: UntypedFormBuilder,
    ) {
      }
     
  ngOnInit() {
    this.businessProfileId = this.route.snapshot.paramMap.get('value');
    this.selectedTenantId = this.route.snapshot.paramMap.get('tenant');
    this.businessTypeId = this.route.snapshot.paramMap.get('businessTypeId');
    this.businessProfileName = this.route.snapshot.paramMap.get('businessProfileName');
    if (this.businessProfileId === null) {
      this.router.navigate(['profiles/manage-profiles']);
    }
    this.pageKey = 'profile';
    this.rightsideNavitemsService.getRightsideNavItems(CREATE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.getServiceList();
    this.getSolutionTypesForProfileId();
    this.getBusinessUsageConfiguration();
    this.getOTPConfiguration();
    this.getSmsConfiguration();
    this.getWhatsappConfiguration();
    this.getEmailConfiguration();
    this.fotaConfiguration();
    this.dynamicCertificateConfiguration();
    this.backLeftImage = BACK_LEFT;
    this.profileIcon = PROFILE_ICON;
    this.documentImage = DOCUMENT_IMAGE;
    this.deleteImage = DELETE_IMAGE;
    this.infoIconImage = INFO_ICON_IMAGE;
    this.editImage = EDIT_IMAGE;
    this.downloadImage =  DOWNLOAD_ICON;
    this.dynamicCertificateForm = this.UntypedFormBuilder.group({
      excelFile: ['', Validators.required],
      storageAccountId :['', Validators.required]
            });
    this.demoAccountConfigForm = new FormGroup({});
  }

 
  public getServiceList() {
    this.serviceMasterControllerService.getDataRetentionServiceList().subscribe(resp => {
      if (resp && resp['length']) {
        this.serviceList = resp;
        this.demoAccountConfiguration();
      }
    }, err => {
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getSolutionTypesForProfileId() {
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({
        businessProfileId: this.businessProfileId,
        providerName: this.env['providerBosch']
      })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.solutionTypeList = resp;
          }
        },
        err => {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getRootCertificateConfiguration(solutionTypeId) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.selectedSolutionType = solutionTypeId;
    this.businessProfileControllerService.getRootCertificate({
      businessProfileId: this.businessProfileId,
      solutionTypeId: solutionTypeId
    }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.files = resp;
      if (resp['message'] === null) {
        this.selectedFile = [];
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public uploadRootCertificate() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.businessProfileControllerService.uploadRootCertificate({
      businessProfileId: this.businessProfileId, solutionTypeId: this.selectedSolutionType,
      body: { file: this.selectedFile }
    }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', 'ROOT_CERTIFICATE_UPLOAD_SUCCESS');
      this.getRootCertificateConfiguration(this.selectedSolutionType);
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  /**
  * @ngdoc method
  * @name configureProfile#getDataRetentionBasedOnService
  *
  * @methodOf
  * profiles.controller:configureProfile
  *
  * @description
  * Description: to get details of data retention configuration of selected profile for selected service
  *
  * @param {type} null
  * @return {type} null
*/
  public getDataRetentionBasedOnService(event) {
    this.serviceId = event;
    this.showSpinner = this.loaderService.showProgressBar();
    if (event) {
      this.profileConfigurationControllerService.getDataRetProfConfig({ profileId: this.businessProfileId, serviceId: event })
        .subscribe(resp => {
          if (resp && resp['length']) {
            this.dataRetentionEnable = true;
            this.retentionData = resp;
            this.dataRetentionCreateForm();
            this.loaderService.hideProgressBar(this.showSpinner);
        } else {
          this.loaderService.hideProgressBar(this.showSpinner);
            this.retentionData =[];
            this.dataRetentionEnable = false;
          }
        }, err => {
          this.loaderService.hideProgressBar(this.showSpinner);
            this.dataRetentionEnable = false;
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          if (err.status !== 404) {
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        });
    } else {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('warning', 'PLEASE_SELECT_SERVICE');
    }
  }

  /**
    * @ngdoc method
    * @name configureProfile#getBusinessUsageConfiguration
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to get details of usage configuration of selected profile
    *
    * @param {type} null
    * @return {type} null
  */
  public getBusinessUsageConfiguration() {
    const usageDetailsDto: any = { businessProfileId: this.businessProfileId };
    this.profileConfigurationControllerService.findProfileUsageDetails(usageDetailsDto).subscribe(
      resp => {
        if (resp) {
          const data: any = resp;
          this.profileUsageData = data;
          this.profileJsonLoaded = 'profile-usage-configuration';
        }
      },
      err => {
        this.profileJsonLoaded = 'profile-usage-configuration';
        if (err.status !== 404) {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      }
    );
  }

  /**
    * @ngdoc method
    * @name configureProfile#getOTPConfiguration
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to get details of OTP configuration of selected profile
    *
    * @param {type} null
    * @return {type} null
  */
  public getOTPConfiguration() {
    const OTPDetailsDto: any = { businessProfileId: this.businessProfileId };
    this.profileConfigurationControllerService.findOtpConfigurationDetails(OTPDetailsDto).subscribe(
      resp => {
        if (resp) {
          const data: any = resp;
          this.otpData = data;
          if (this.otpData && this.otpData['otpLength']) {
            this.otpData['otpLength'] = this.otpData['otpLength'].toString();
          }
          if (this.otpData && this.otpData['otpExpiry']) {
            this.otpData['otpExpiry'] = this.otpData['otpExpiry'].toString();
          }
          this.otpJsonLoaded = 'otp-configuration';
        }
      },
      err => {
        this.otpJsonLoaded = 'otp-configuration';
        if (err.status !== 404) {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      }
    );
  }

  /**
   * @ngdoc method
   * @name configureProfile#getSmsConfiguration
   *
   * @methodOf
   * profiles.controller:configureProfile
   *
   * @description
   * Description: to get details of sms configuration of selected profile
   *
   * @param {type} null
   * @return {type} null
 */
  public getSmsConfiguration() {
    this.profileConfigurationControllerService.getSmsConfiguration({ businessProfileId: this.businessProfileId }).subscribe(
      resp => {
        if (resp) {
          const data: any = resp;
          this.smsConfigurationData = data;
          this.smsConfigurationData.active = (this.smsConfigurationData.active === true) ? 'YES' : 'NO';
          this.smsConfigurationJsonLoaded = 'sms-configuration';
        }
      },
      err => {
        this.smsConfigurationJsonLoaded = 'sms-configuration';
        if (err.status !== 404) {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      }
    );
  }

  /**
    * @ngdoc method
    * @name configureProfile#getEmailConfiguration
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to get details of email configuration of selected profile
    *
    * @param {type} null
    * @return {type} null
  */
  public getEmailConfiguration() {
    this.profileConfigurationControllerService.getEmailConfiguration({ businessProfileId: this.businessProfileId }).subscribe(
      resp => {
        if (resp) {
          const data: any = resp;
          this.emailConfigurationData = data;
          this.emailConfigId = data.emailConfigId;
          this.emailConfigurationData.isConfigRequired = (this.emailConfigurationData.isConfigRequired === true) ? 'YES' : 'NO';
          this.emailConfigurationJsonLoaded = 'email-configuration';
        }
      },
      err => {
        this.emailConfigurationJsonLoaded = 'email-configuration';
        if (err.status !== 404) {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      }
    );
  }


 /**
    * @ngdoc method
    * @name configureProfile#dynamicCertificateConfiguration
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to get details of dynamic certificate Configuration of selected profile
    *
    * @param {type} null
    * @return {type} null
  */
 public dynamicCertificateConfiguration() {
  this.businessProfileControllerService.getBusinessProfile(
   { 
     tenantId: this.selectedTenantId,
     profileId:this.businessProfileId
   }
   ).subscribe(
   resp => {
     if (resp) {
      this.certificateData = resp;
      this.dynamicCertificateEnabled =this.certificateData.certDownloadEnabled;
    
      }
      },
   err => {
     if (err.status !== 404) {
       this.responseHandlerService.returnToastMessage('error', err.error.message);
     }
   }
 );
}

    
public storageAccounts(){
  this.StorageAccountControllerService.getStorageAccountsByProfileId(
    {
      profileId:this.businessProfileId
    }).subscribe(data=>{
      if(data){
        this.storageAccountsList = data;
      }
      },
    err =>{
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    } );
}

     /**
    * @ngdoc method
    * @name configureProfile#getStorageAccount
    *
    * @methodOf
    * profiles.controller:dynamic Certificate configuration 
    *
    * @description
    * Description: get storage account details while selecting the storage account dropdown
    *  
     * @param {type} null
     * @return {type} null
  */


 public getStorageAccount(val){
    this.storageAccountId =val;
     this.dynamicCertificateForm.get('storageAccountId').setValue(val);
     this.showSpinner = this.loaderService.showProgressBar();
    this.WhiteListDevicesControllerService.getWhiteListedDevicesList(
      {
        profileId:this.businessProfileId,
        storageAccountId:val
      }).subscribe( 
        resp =>{
          this.loaderService.hideProgressBar(this.showSpinner);
         this.dynamicCertificates = resp;
        },
        err =>{
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
       }
    );
 }

 
    
     /**
    * @ngdoc method
    * @name configureProfile#certificateEnableDisable
    *
    * @methodOf
    * profiles.controller:dynamic Certificate configuration 
    *
    * @description
    * Description: once disable the dynamic certificate then re-initiate the depending variables
    *
     * @param {type} null
    * @return {type} null
  */
 public certificateEnableDisable(event){
  if(!event.checked){
    this.storageAccountId =null;
    this.dynamicCertificates=[];
    this.dynamicCertificateForm.get('excelFile').setValue(null);
    this.dynamicCertificateForm.get('storageAccountId').setValue(null);
  }
 }

   public selectFiles(event) {
       if (event.target.files && event.target.files.length > 0) {
          this.dynamicCertificateForm.value.excelFile = event.target.files[0];
          this.dynamicCertificateForm.get('excelFile').setValue(event.target.files[0]);
        }
    }


    
     /**
    * @ngdoc method
    * @name configureProfile#downloadDynamicCertificate
    *
    * @methodOf
    * profiles.controller:dynamic Certificate configuration 
    *
    * @description
    * Description: to download each dynamic certificated based on list of files
    *
     * @param {type} null
    * @return {type} null
  */
 
     public downloadDynamicCertificate(id,fileName){
      this.showSpinner = this.loaderService.showProgressBar();
      this.WhiteListDevicesControllerService.downloadWhiteListedDevices(
        {
        whitelistId:id}).subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          if (resp) {
                this.downloadFile(resp, fileName );
           }
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
     }

     public downloadFile(data, fileName) {
      const FileSaver = require('file-saver');
      const blob = new Blob([data], { type: 'application/csv' });
      FileSaver.saveAs(blob, fileName);
    }

    public activateDynamicCertificate(){
      this.showSpinner = this.loaderService.showProgressBar();
        const sendData = {
      active:this.certificateData.active,
      businessType:this.certificateData.businessType,
      certDownloadEnabled: this.dynamicCertificateEnabled,
      geoFenceEnabled: this.geoFenceEnabled,
      id:this.certificateData.id,
      interfaceTypes:this.certificateData.interfaceTypes,
      solutionLinking:this.certificateData.solutionLinking,
      name:this.certificateData.name,
      tenant:this.certificateData.tenant
    };
    this.businessProfileControllerService.updateBusinessProfiles(
      {
        profileId:this.businessProfileId,
        tenantId:this.selectedTenantId,
        body:sendData
      }
    ).subscribe(resp=>{
      this.loaderService.hideProgressBar(this.showSpinner);
      if(this.dynamicCertificateEnabled==false){
        this.responseHandlerService.returnToastMessage('success', 'CERTIFICATE_CONFIGURATION_DEACTIVATION_SUCCESS');
      } 
    },
    err => {  
        if (err.status !== 404) {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
        this.loaderService.hideProgressBar(this.showSpinner);
      }
   );
    }

     /**
    * @ngdoc method
    * @name configureProfile#uploadDynamicCertificate
    *
    * @methodOf
    * profiles.controller:dynamic Certificate configuration 
    *
    * @description
    * Description: to upload dynamic certificate.
    *
    * @param {type} null
    * @return {type} null
  */

  public uploadDynamicCertificate(){
    this.dynamicCertificateSubmit = true;
     if(!this.dynamicCertificateEnabled || (this.dynamicCertificateForm.value.excelFile && this.dynamicCertificateForm.value.storageAccountId)){
      this.activateDynamicCertificate();
    }
      if(!this.dynamicCertificateForm.value.excelFile && !this.dynamicCertificateForm.value.storageAccountId ){
      return 0;
     } 
 
   const data = this.dynamicCertificateForm.value;
  if(data['excelFile']){
        const obj={
        businessProfile:{
        id:this.businessProfileId,
        name:this.businessProfileName,
        },
        tenantId:this.selectedTenantId,
        storageAccountId:this.storageAccountId
        };
        data.file = data['excelFile'];
        const params = {
          body:{
            whiteListDeviceUploadRequest: JSON.stringify(obj),
            file:data.file
          }
        };
        const formData:any = params;
        this.WhiteListDevicesControllerService.uploadWhiteListedDevices(formData).subscribe(
          resp => {
              this.loaderService.hideProgressBar(this.showSpinner);

              this.responseHandlerService.returnToastMessage(
              'success',
              'FILE_UPLOAD_SUCCESS'
            );
            this.getStorageAccount(this.storageAccountId);
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
   }
  }

 /**
    * @ngdoc method
    * @name configureProfile#fotaConfiguration
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to get details of fotaConfiguration of selected profile
    *
    * @param {type} null
    * @return {type} null
  */

  public fotaConfiguration() {
     this.businessProfileControllerService.getBusinessProfile(
      { 
        tenantId: this.selectedTenantId,
        profileId:this.businessProfileId
      }
      ).subscribe(
      resp => {
        if (resp) {
          const data: any = resp;
          this.geoFenceEnabled = data.geoFenceEnabled;
          this.dynamicCertificateEnabled = data.certDownloadEnabled;
            this.fotaConfigurationData = data;
        }
      },
      err => {
        if (err.status !== 404) {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      }
    );
  }
 
    /**
    * @ngdoc method
    * @name configureProfile#configureFotaConfiguration
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to configure details of Fota Configuration details of selected profile
    *
    * @param {type} null
    * @return {type} null
  */

  public configureFotaConfiguration(){
          this.showSpinner = this.loaderService.showProgressBar();
          const sendData = {
          active:this.fotaConfigurationData.active,
          businessType:this.fotaConfigurationData.businessType,
          certDownloadEnabled: this.dynamicCertificateEnabled,
          geoFenceEnabled: this.geoFenceEnabled,
          id:this.fotaConfigurationData.id,
          interfaceTypes:this.fotaConfigurationData.interfaceTypes,
          solutionLinking:this.fotaConfigurationData.solutionLinking,
          name:this.fotaConfigurationData.name,
          tenant:this.fotaConfigurationData.tenant
        };
        this.businessProfileControllerService.updateBusinessProfiles(
          {
            profileId:this.businessProfileId,
            tenantId:this.selectedTenantId,
            body:sendData
          }
        ).subscribe(resp=>{
          this.loaderService.hideProgressBar(this.showSpinner);
          if(this.geoFenceEnabled==true){
            this.responseHandlerService.returnToastMessage('success', 'OTA_CONFIGURATION_ACTIVATION_SUCCESS');
          } 
          if(this.geoFenceEnabled==false){
            this.responseHandlerService.returnToastMessage('success', 'OTA_CONFIGURATION_DEACTIVATION_SUCCESS');
          } 
        
        },
        err => {  
        if (err.status !== 404) {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
        this.loaderService.hideProgressBar(this.showSpinner);
        }
      );
    }

   public onResetDynamicCertificate(){}

 
  /**
    * @ngdoc method
    * @name configureProfile#configureProfileUsage
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to configure details of data retention details of selected profile
    *
    * @param {type} null
    * @return {type} null
  */
  public configureProfileUsage(data: any) {
    const profileUsageDtoObj = data;
    const profileUsageDetailsDto = { body: profileUsageDtoObj, businessProfileId: this.businessProfileId };
    this.showSpinner = this.loaderService.showProgressBar();
    if (this.profileUsageData) {
      this.profileConfigurationControllerService
        .updateProfileUsage(profileUsageDetailsDto)
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.profileUsageData = resp;
            this.responseHandlerService.returnToastMessage('success', 'PROFILE_USAGE_EDIT_SUCCESS');
            this.getBusinessUsageConfiguration();
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    } else {
      this.profileConfigurationControllerService
        .createProfileUsage(profileUsageDetailsDto)
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.profileUsageData = resp;
            this.responseHandlerService.returnToastMessage('success', 'PROFILE_USAGE_CREATE_SUCCESS');
            this.getBusinessUsageConfiguration();
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    }
  }

  /**
    * @ngdoc method
    * @name configureProfile#configureOTP
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to configure details of OTP details of selected profile
    *
    * @param {type} null
    * @return {type} null
  */
  public configureOTP(data: any) {
    const otpDtoObj = data;
    const otpUsageDetailsDto = { body: otpDtoObj, businessProfileId: this.businessProfileId };
    this.showSpinner = this.loaderService.showProgressBar();
    this.profileConfigurationControllerService
      .updateOtpConfiguration(otpUsageDetailsDto)
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.otpData = resp;
          this.responseHandlerService.returnToastMessage('success', 'OTP_CONFIGURATION_EDIT_SUCCESS');
          this.getOTPConfiguration();
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error[0].message);
        }
      );
  }
  
  /**
    * @ngdoc method
    * @name configureProfile#dataRetentionCreateForm
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: create formControls for binding data retention in Template
    *
    * @param {type} null
    * @return {type} null
  */
  public dataRetentionCreateForm(){
    this.dataRetentionForm = this.formBuilder.group({
      dataRetentionDetails: this.formBuilder.array(this.retentionData.map(x => this.formBuilder.group({
        sourceTableName:[x.sourceTableName,[]],
        configId: [x.configId, []],
        archFlag: [x.archFlag, []],
          isDefaultArchive: [x.isDefaultArchive, []],
          archivalPeriod: [x.archivalPeriod, []],
          purgeFlag: [x.purgeFlag, []],
          isDefaultPurge: [x.isDefaultPurge, []],
          purgePeriod: [x.purgePeriod, []],
          maxArchivalPurgePeriod:[x.maxArchivalPurgePeriod,[]],
          minArchivalPurgePeriod:[x.minArchivalPurgePeriod,[]]
      })))
    });
     this.dataRetentionControlsData = this.dataRetentionForm.controls.dataRetentionDetails.value;
    }
     
 /**
    * @ngdoc method
    * @name configureProfile#onResetDataRetentionGrid
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: reset dataretention form
    *
    * @param {type} null
    * @return {type} null
  */

    public onResetDataRetentionGrid(){
      this.getDataRetentionBasedOnService(this.serviceId);
    }


  /**
    * @ngdoc method
    * @name configureProfile#configureDataRetention
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to configure details of data retention details of selected profile
    *
    * @param {type} null
    * @return {type} null
  */
  public configureDataRetention() {
    this.showSpinner = this.loaderService.showProgressBar();
    const dataRetentionDtoObj = [];
    this.dataRetentionForm.controls.dataRetentionDetails.value.forEach(ele=>{
     let obj ={};
    obj['configId'] = ele.configId;
    obj['sourceTableName'] = ele.sourceTableName;
    obj['archivalPeriod'] = ele.archivalPeriod;
    obj['purgePeriod'] = ele.purgePeriod;
    obj['archFlag'] = ele.archFlag;
    obj['purgeFlag'] = ele.purgeFlag;
    obj['maxArchivalPurgePeriod'] = ele.maxArchivalPurgePeriod;
    obj['minArchivalPurgePeriod'] = ele.minArchivalPurgePeriod;
    obj['isDefaultPurge'] = ele.isDefaultPurge;
    obj['isDefaultArchive'] = ele.isDefaultArchive;
    dataRetentionDtoObj.push(obj);
    });
    if (dataRetentionDtoObj.length) {
      this.profileConfigurationControllerService.updateDataRetProfConfig({
        profileId: this.businessProfileId, serviceId: this.serviceId, body: dataRetentionDtoObj
      })
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('success', 'DATA_RETENTION_CONFIGURATION_EDIT_SUCCESS');
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    } else {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('warning', 'DATA_RETENTION_NOT_CONFIGURED_FOR_SELECTED_PROFILE');
    }
  }

  /**
 * @ngdoc method
 * @name configureProfile#configureSms
 *
 * @methodOf
 * profiles.controller:configureProfile
 *
 * @description
 * Description: to configure details of sms configuration details of selected profile
 *
 * @param {type} null
 * @return {type} null
*/
  public configureSms(data: any) {
    const smsConfigurationDtoObj: any = {
      'active': (data['active'] === 'YES') ? true : false,
      'defaultFlag': data['defaultFlag'],
      'smsUrl': data['smsUrl'],
      'smsAuthType': data['smsAuthType'],
      'sasKey': data['sasKey'],
      'username': data['username'],
      'password': data['password']
    };
    const smsConfigurationDetailsDto = { body: smsConfigurationDtoObj, businessProfileId: this.businessProfileId };
    this.showSpinner = this.loaderService.showProgressBar();
    if (this.smsConfigurationData) {
      this.profileConfigurationControllerService.updateSmsConfiguration(smsConfigurationDetailsDto)
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.smsConfigurationData = data;
            this.responseHandlerService.returnToastMessage('success', 'SMS_CONFIGURATION_EDIT_SUCCESS');
            this.getSmsConfiguration();
            if (smsConfigurationDtoObj['active'] === false) {
              this.smsConfigurationData = null;
            }
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    } else {
      if (!smsConfigurationDetailsDto.body['active']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'SMS_CONFIGURATION_EDIT_SUCCESS');
      } else {
        this.profileConfigurationControllerService.saveSmsConfiguration(smsConfigurationDetailsDto)
          .subscribe(
            resp => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.smsConfigurationData = data;
              this.responseHandlerService.returnToastMessage('success', 'SMS_CONFIGURATION_CREATE_SUCCESS');
              this.getSmsConfiguration();
              if (smsConfigurationDtoObj['active'] === false) {
                this.smsConfigurationData = null;
              }
            },
            err => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
      }
    }
  }

  /**
    * @ngdoc method
    * @name configureProfile#configureEmailConfiguration
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to configure details of data retention details of selected profile
    *
    * @param {type} null
    * @return {type} null
  */
  public configureEmailConfiguration(data: any) {
    const emailConfigurationDtoObj: any = {
      'isConfigRequired': (data['isConfigRequired'] === 'YES') ? true : false,
      'isMasterConfiguration': data['isMasterConfiguration'],
      'isMasterSubscription': data['isMasterSubscription'],
      'fromAddress': data['fromAddress'],
      'username': data['username'],
      'password': data['password'],
      'otpUsername': data['otpUsername'],
      'otpPassword': data['otpPassword'],
      'port': data['port'],
      'hostName': data['hostName']
    };
    if (this.emailConfigurationData && this.emailConfigurationData['isMasterConfiguration'] === false) {
      emailConfigurationDtoObj['emailConfigId'] = this.emailConfigId;
    }
    const emailConfigurationDetailsDto = { body: emailConfigurationDtoObj, businessProfileId: this.businessProfileId };
    this.showSpinner = this.loaderService.showProgressBar();
    if (this.emailConfigurationData) {
      this.profileConfigurationControllerService
        .updateEmailConfiguration(emailConfigurationDetailsDto)
        .subscribe(
          resp => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.emailConfigurationData = data;
            this.responseHandlerService.returnToastMessage('success', 'EMAIL_CONFIGURATION_EDIT_SUCCESS');
            this.getEmailConfiguration();
            if (emailConfigurationDtoObj['isConfigRequired'] === false) {
              this.emailConfigurationData = null;
            }
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    } else {
      if (!emailConfigurationDetailsDto.body['isConfigRequired']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'EMAIL_CONFIGURATION_EDIT_SUCCESS');
      } else {
        this.profileConfigurationControllerService
          .saveEmailConfiguration(emailConfigurationDetailsDto)
          .subscribe(
            resp => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.emailConfigurationData = data;
              this.responseHandlerService.returnToastMessage('success', 'EMAIL_CONFIGURATION_CREATE_SUCCESS');
              this.getEmailConfiguration();
              if (emailConfigurationDtoObj['isConfigRequired'] === false) {
                this.emailConfigurationData = null;
              }
            },
            err => {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('error', err.error.message);
            }
          );
      }
    }
  }

  /**
    * @ngdoc method
    * @name configureProfile#onBack
    *
    * @methodOf
    * profiles.controller:configureProfile
    *
    * @description
    * Description: to navigate back to the manage profile screen
    *
    * @param {type} null
    * @return {type} null
  */
  public navigateBack() {
    this.router.navigate(['profiles/manage-profiles', {
      businessProfileId: this.businessProfileId,
      tenantId: this.selectedTenantId
    }]);
  }

  public removeFile() {
    this.businessProfileControllerService.deleteRootCertificate({
      businessProfileId: this.businessProfileId,
      solutionTypeId: this.selectedSolutionType
    })
      .subscribe(resp => {
        this.responseHandlerService.returnToastMessage('success', 'ROOT_CERTIFICATED_DELETED_SUCCESSFULLY');
        this.getRootCertificateConfiguration(this.selectedSolutionType);
      }, err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        this.getRootCertificateConfiguration(this.selectedSolutionType);
      });
  }

  public resetCertificateConfigurations() {
    this.files = null;
    this.selectedSolutionType = null;
  }

  public gotoConfirmDialog() {
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: this.translationService.translateErrorMessages('DELETE_UPLOADED_CERTIFICATE_MESSAGE') }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeFile();
      } else {
        this._matDialog.closeAll();
      }
    });
  }
  
  public deleteUploadFile() {
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: this.translationService.translateErrorMessages('DELETE_UPLOADED_CERTIFICATE_MESSAGE') }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.removeUploadedFile();
      } else {
        this._matDialog.closeAll();
      }
    });
  }

  public removeUploadedFile(){
    this.dynamicCertificateForm.get('excelFile').setValue(null);
  }
 
  public getSampleCSVFile() {
   
  }

  public getWhatsappConfiguration() {
    this.httpsService.get('./assets/form-fields/whatsapp-configuration.json').subscribe((resp) => {
      if (resp) {
        this.whatsappDropdownList = resp['fields'];
      }
    });
    this.getVendorByProfileId();
    this.getVendors();
  }

  public getVendorByProfileId () {
    this.whatsappVendorService.getAllBspMappingByProfileId( {profileId: this.businessProfileId} ).subscribe((resp) => {
      if (resp && resp['vendorDetails']) {
        this.action = 'UPDATE';
        this.vendorIdCopy = resp['vendorDetails']['id'];
        this.vendorId = resp['vendorDetails']['id'];
        this.whatsappConfigurationData = {vendor: resp['vendorDetails']['id'], vendorId: null};
        this.whatsappConfigurationJsonLoaded = 'whatsapp-configuration';
      }
    }, (err) => {
      this.vendorId = null;
      this.whatsappConfigurationJsonLoaded = 'whatsapp-configuration';
      if (err.error.message !== 'T_VENDOR_PROFILE_NOT_MAPPED') {
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      } else {
        this.action = 'ADD';
      }
    });
  }

  public getVendors() {
    this.whatsappVendorService.getVendorList().subscribe((resp) => {
      if (resp && resp.length) {
        this.dropDownValues['vendor'] = resp;
      } else {
        this.dropDownValues['vendor'] = [];
      }
    }, (err) => {
      this.dropDownValues['vendor'] = [];
      this.responseHandlerService.returnToastMessage(
        'error',
        err.error.message
      );
    });
  }

    public mapVendor() {
    this.showSpinner = this.loaderService.showProgressBar();
    const params = {
            profileId: this.businessProfileId,
            vendorId:this.vendorId
    };

    if (this.action === 'ADD') {
      this.whatsappVendorService.saveMapDetails(params).subscribe((resp) => {
        if (resp) {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'VENDOR_MAP_SUCCESS'          );
          this.getVendorByProfileId();
      }}, (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      });
    } else if (this.action === 'UPDATE') {
      this.whatsappVendorService.updateMappingDetails(params).subscribe((resp) => {
        if (resp) {
          this.whatsappConfigurationData = {vendor: this.vendorId, vendorId: null};
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'VENDOR_UPDATION_SUCCESS'  );
          this.vendorIdCopy = this.vendorId;
      }}, (err) => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      });
    }

  }

  public configureWhatsapp(event) {
    if ((!this.vendorIdCopy) || (this.vendorId !== this.vendorIdCopy)) {
      this.mapVendor();
    }
  }

  public updateWhatsappDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child === 'businessAccount') {
        this.vendorId = $event.value;
      }
    }
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public demoAccountConfiguration() {
    this.serviceList.forEach(service => {
      if(service['demoAccountEnabled']) {
        service['label'] = service['label'].toUpperCase();
        this.demoAccountConfigServiceList.push(service);
        this.demoAccountConfigForm.addControl(service['id'], new FormControl(0, [Validators.required, Validators.pattern('^[0-9]+$')]));
        this.defaultDemoConfig = Object.assign({},this.demoAccountConfigForm.value);
      }
    });
    this.getDemoAccountConfiguration();
  }

  public getDemoAccountConfiguration() {
    this.profileConfigurationControllerService.getDemoAccountConfiguration({ businessProfileId: this.businessProfileId})
    .subscribe(resp => {
      if (resp && resp['length']) {
        const demoAccountConfigObject = resp.reduce((result, config) => {
          result[config.serviceId] = config.transactionLimit;
          return result;
      }, {});
      this.demoAccountConfigForm.setValue({...this.demoAccountConfigForm.value,...demoAccountConfigObject});
      this.defaultDemoConfig = Object.assign({},this.demoAccountConfigForm.value);
      this.isDemoConfigExists = true;
    }
    },
    err => {
      if (err.status !== 404) {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    });
  }

  public submitDemoAccountConfiguration() {
    this.demoAccountConfigFormSubmitted = true;
    if(!this.demoAccountConfigForm.valid) {
      return;
    }
    this.showSpinner = this.loaderService.showProgressBar();
    const demoAccountConfigurations: DemoAccountConfigurationDto[] = Object.entries(this.demoAccountConfigForm.value).map(([serviceId, transactionLimit]) => ({
      serviceId,
      transactionLimit : Number(transactionLimit)
    }));
    
    const params = {
    body:demoAccountConfigurations,
    businessProfileId: this.businessProfileId
    };

    const demoConfigMethod = this.isDemoConfigExists?'updateDemoAccountConfiguration':'saveDemoAccountConfiguration';
    this.profileConfigurationControllerService[demoConfigMethod](params)
    .subscribe(resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage(
          'success',
          'DEMO_ACCOUNT_CONFIGURED_SUCCESSFULLY'  );
          this.getDemoAccountConfiguration();      }
    }, (err) => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage(
        'error',
        err.error.message
      );
    });
  }

  public resetDemoAccountConfiguration() {
    this.demoAccountConfigForm.reset(this.defaultDemoConfig);
    this.demoAccountConfigFormSubmitted = false;
  }


}

