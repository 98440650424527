import { Component, OnInit, ViewChild } from '@angular/core';
import { VENDOR_REGISTRATION_ICON } from 'src/app/shared/constants/strings';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import {
  AccountControllerService,
  BusinessProfileControllerService,
  TenantControllerService,
} from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { EnvService } from 'src/app/env.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup} from '@angular/forms';
import { SimMgmtControllerService } from 'src/app/services/SimMgmt/services/sim-mgmt-controller.service';
import { DynamicFormDeviceComponent } from 'src/app/shared/dynamic-form-device/dynamic-form-device.component';
import { VendorControllerService } from 'src/app/services/SimMgmt/services/vendor-controller.service';
import { BciLoaderService } from '@bci-web-core/core';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
@Component({
  selector: 'app-vendor-registration',
  templateUrl: './vendor-registration.component.html',
  styleUrls: ['./vendor-registration.component.scss']
})
export class VendorRegistrationComponent implements OnInit {
  public vendorRegistrationForm: UntypedFormGroup;
  public vendorRegIcon: any;
  public dropDownValues: any = {};
  vendorsList: any = [];
  public vendorAttributes: any;
  public selectedVendorId: string;
  public selectedVendorName: string;
  public showSpinner :any;
  public submitted = false;
  @ViewChild(DynamicFormDeviceComponent)
  dynamicDeviceForm: DynamicFormDeviceComponent;
  constructor(
    private businessProfileControllerService: BusinessProfileControllerService,
    private tenantControllerService: TenantControllerService,
    private accountControllerService: AccountControllerService,
    private responseHandlerService: ResponseHandlerService,
    private envService: EnvService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private vendorControllerService: VendorControllerService,private loaderService: BciLoaderService
  ) {}

  ngOnInit() {
    this.vendorRegIcon = VENDOR_REGISTRATION_ICON;
    this.vendorRegistrationForm = this.formBuilder.group({
      account: ['', Validators.required],
      tenant: ['', Validators.required],
      businessProfile: ['', Validators.required],
      solutionType: ['', Validators.required],
      vendor: ['', Validators.required],
    });
    this.getAccount();
  }
  /**
   * @ngdoc method
   * @name vendorRegistration#updateDropdownValues
   *
   * @methodOf
   *sim.controller:vendorRegistration
   *
   * @description
   * Description: To populate dependent dropdown.
   *
   * @param {type} dropdown select event
   * @return {type} null
   */
  public updateDropdownValues($event, childField) {
    if ($event.value !== undefined) {
      if (childField && childField === 'tenant') {
        this.dropDownValues['tenant'] = [];
        this.getTenant($event.value);
      }
      if (childField && childField === 'businessProfile') {
        this.dropDownValues['businessProfile'] = [];
        this.getProfile($event.value);
      }
      if (childField && childField === 'solutionType') {
        this.dropDownValues['solutionType'] = [];
        this.getSolutionType($event.value);
      }
      if (childField && childField === 'vendor') {
        this.dropDownValues['vendor'] = [];
        this.getVendorsList();
      }
    }
  }

  /**
   * @ngdoc method
   * @name vendorRegistration#getAccount
   *
   * @methodOf
   *sim.controller:vendorRegistration
   *
   * @description
   * Description: To populate account dropdown.
   *
   * @param {type} null
   * @return {type} null
   */
  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  /**
   * @ngdoc method
   * @name vendorRegistration#getTenant
   *
   * @methodOf
   *sim.controller:vendorRegistration
   *
   * @description
   * Description: To populate tenant dropdown.
   *
   * @param {type} accountId
   * @return {type} null
   */
  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  /**
   * @ngdoc method
   * @name vendorRegistration#getProfile
   *
   * @methodOf
   *sim.controller:vendorRegistration
   *
   * @description
   * Description: To populate business profiles dropdown.
   *
   * @param {type} tenantId
   * @return {type} null
   */
  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  /**
   * @ngdoc method
   * @name vendorRegistration#getSolutionType
   *
   * @methodOf
   *sim.controller:vendorRegistration
   *
   * @description
   * Description: To populate solution type dropdown.
   *
   * @param {type} profileId
   * @return {type} null
   */
  public getSolutionType(profileId: string) {
    const solutionTypeActiveArray = [];
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({
        businessProfileId: profileId,
      })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            resp.forEach((element) => {
              if (element['active'] === true) {
                solutionTypeActiveArray.push(element);
              }
            });
            this.dropDownValues['solutionType'] = solutionTypeActiveArray;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        (err) => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }
  public getVendorsList() {
    this.vendorControllerService.getAllVendors().subscribe(
      (resp) => {
        if (resp && resp.length) {
          this.vendorsList = resp;
          this.dropDownValues['vendor'] = resp;
        } else {
          this.dropDownValues['vendor'] = [];
        }
      },
      (err) => {
        this.dropDownValues['vendor'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public populateVendorAttributes($event) {
    this.vendorsList.forEach((vendor) => {
      if (vendor.id === $event.value) {
        this.selectedVendorId = vendor.id;
        this.selectedVendorName = vendor.name;
        this.vendorAttributes = vendor['vendorAttributes'];
      }
    });
    if (this.dynamicDeviceForm && this.dynamicDeviceForm.deviceProperties) {
      this.dynamicDeviceForm.deviceProperties = this.vendorAttributes;
      this.dynamicDeviceForm.ngOnInit();
    }
  }

  public registerVendor(data) {
    this.submitted = true;
    if (this.vendorRegistrationForm.valid) {
      this.showSpinner = this.loaderService.showProgressBar();
      const simVendorDto = {
        profileId: this.vendorRegistrationForm.get('businessProfile').value,
        solutionTypeId: this.vendorRegistrationForm.get('solutionType').value,
        vendorId: this.vendorRegistrationForm.get('vendor').value,
        vendorName: this.selectedVendorName,
        vendorAttributes: data,
      };
      this.vendorControllerService
        .registerSimVendor({ body: simVendorDto })
        .subscribe(
          (res) => {
            if (res) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'success',
                'VENDOR_REGISTRATION_SUCCESS'
              );
              this.submitted = false;
              this.dropDownValues = {};
              this.selectedVendorName = '';
              this.vendorRegistrationForm.reset();
              this.vendorAttributes = null;
              this.getAccount();
            }
          },
          (err) => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }
  }


public resetForm($event) {
  this.submitted = false;
  this.dropDownValues   = {};
  this.selectedVendorName = '';
  this.vendorRegistrationForm.reset();
  this.vendorAttributes = null;
  this.getAccount();
}
}

