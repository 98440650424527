/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { WhiteListedDeviceDto } from '../models/white-listed-device-dto';

@Injectable({
  providedIn: 'root',
})
export class WhiteListDevicesControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation uploadWhiteListedDevices
   */
  static readonly UploadWhiteListedDevicesPath = '/api/v1/whitelistdevices/upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadWhiteListedDevices()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadWhiteListedDevices$Response(params: {
    whiteListDeviceUploadRequest: string;
    body?: {
'multipartFile'?: Blob;
}
  }): Observable<StrictHttpResponse<WhiteListedDeviceDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteListDevicesControllerService.UploadWhiteListedDevicesPath, 'post');
    if (params) {
      rb.query('whiteListDeviceUploadRequest', params.whiteListDeviceUploadRequest, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
        responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WhiteListedDeviceDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadWhiteListedDevices$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadWhiteListedDevices(params: {
    whiteListDeviceUploadRequest: string;
    body?: {
'multipartFile'?: Blob;
}
  }): Observable<WhiteListedDeviceDto> {

    return this.uploadWhiteListedDevices$Response(params).pipe(
      map((r: StrictHttpResponse<WhiteListedDeviceDto>) => r.body as WhiteListedDeviceDto)
    );
  }

  /**
   * Path part for operation downloadWhiteListedDevices
   */
  static readonly DownloadWhiteListedDevicesPath = '/api/v1/whitelistdevices/download/{whitelistId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadWhiteListedDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadWhiteListedDevices$Response(params: {
    whitelistId: string;
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteListDevicesControllerService.DownloadWhiteListedDevicesPath, 'get');
    if (params) {
      rb.path('whitelistId', params.whitelistId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/csv'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `downloadWhiteListedDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadWhiteListedDevices(params: {
    whitelistId: string;
  }): Observable<Array<string>> {

    return this.downloadWhiteListedDevices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation getWhiteListedDevicesList
   */
  static readonly GetWhiteListedDevicesListPath = '/api/v1/businessprofile/{profileId}/storageaccount/{storageAccountId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhiteListedDevicesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhiteListedDevicesList$Response(params: {
    profileId: string;
    storageAccountId: string;
  }): Observable<StrictHttpResponse<Array<WhiteListedDeviceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteListDevicesControllerService.GetWhiteListedDevicesListPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('storageAccountId', params.storageAccountId, {});
    }

    return this.http.request(rb.build({
        responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhiteListedDeviceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWhiteListedDevicesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhiteListedDevicesList(params: {
    profileId: string;
    storageAccountId: string;
  }): Observable<Array<WhiteListedDeviceDto>> {

    return this.getWhiteListedDevicesList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WhiteListedDeviceDto>>) => r.body as Array<WhiteListedDeviceDto>)
    );
  }

  /**
   * Path part for operation getWhiteListedDevices
   */
  static readonly GetWhiteListedDevicesPath = '/api/v1/whitelist/device/{deviceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWhiteListedDevices()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhiteListedDevices$Response(params: {
    deviceId: string;
  }): Observable<StrictHttpResponse<Array<WhiteListedDeviceDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhiteListDevicesControllerService.GetWhiteListedDevicesPath, 'get');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
        responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<WhiteListedDeviceDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getWhiteListedDevices$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWhiteListedDevices(params: {
    deviceId: string;
  }): Observable<Array<WhiteListedDeviceDto>> {

    return this.getWhiteListedDevices$Response(params).pipe(
      map((r: StrictHttpResponse<Array<WhiteListedDeviceDto>>) => r.body as Array<WhiteListedDeviceDto>)
    );
  }

}
