<section class="overview-container">
  <section class="main">
    <div class="info" *ngIf="!fotaJobId && !(fotaState['solutionTypeDetails'] && fotaState['deviceTypeDetails']) && !geoFenceBlock">
      {{ 'NO_DEVICES' | translate }}</div>
    <div *ngIf="!geoFenceBlock">
      <div class="geoFence" *ngIf="!fotaState['solutionTypeDetails'] ">
      <mat-form-field class="select-box geoFece-selectbox">
        <mat-label class="label">{{'TENANT' | translate}}</mat-label>
        <mat-select placeholder="{{'SELECT'|translate}}" (selectionChange)="getProfile($event.value)">
          <mat-option *ngFor="let val of tenantValues" [value]="val['id']" required>{{val['name']}}</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="select-box geoFece-selectbox">
        <mat-label class="label">{{'BUSINESS_PROFILE' | translate}}</mat-label>
        <mat-select placeholder="{{'SELECT'|translate}}"  (selectionChange)="validateGeofence($event.value)" >
          <mat-option *ngFor="let val of businessProfileValues" [value]="val['id']" required>{{val['name']}}</mat-option>
        </mat-select>
      </mat-form-field>
    
       </div>
        <div class="geoFenceButtons" *ngIf="!fotaState['solutionTypeDetails']">
    <button (click)="goToManageDevicePage()" mat-raised-button
    class=" geoFenc-button button-back btn-background">{{ 'SELECT_DEVICES' | translate }}</button>
    <button [class.disabled]="!geoFenceEnable" (click)="gotoGeoFencingPage()" mat-raised-button
      class=" geoFenc-button button-back btn-background">{{ 'FENCING_FOTA' | translate }}</button>
        </div>
      </div> 
    <mat-card *ngIf="selectedCriteria">
     <mat-card-content>
        <header class="heading">{{ 'PROCEED_WITH_SELECTION_CRITERIA_AND_DEVICES' | translate }}</header>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button (click)="continueWithSelectedCriteria()">{{ 'CONTINUE' | translate }}</button>
        <button mat-button (click)="onDiscard()">{{ 'DISCARD' | translate }}</button>
      </mat-card-actions>
    </mat-card>
   
    <mat-expansion-panel class="panel" *ngIf="fotaState['solutionTypeDetails']" expanded>
      <mat-expansion-panel-header class="header-style">
        <mat-panel-title class="heading-label">
          <span class="heading-label">
            <span>{{'FOTA_JOB' |translate}}</span>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="fota-details panel-body">
        <div *ngIf="fotaState['solutionTypeDetails']">
          <h6 class="search-criteria-heading">{{ 'SOLUTION_TYPE' | translate}}</h6>
          <span> {{ fotaState['solutionTypeDetails']['name'] }}</span>
        </div>
        <div *ngIf="fotaState['deviceTypeDetails']">
          <h6 class="search-criteria-heading">{{ 'DEVICE_TYPE' | translate}}</h6>
          <span> {{ fotaState['deviceTypeDetails']['name'] }}</span>
        </div>
        <div *ngIf="fotaState['groupDetails']">
          <h6 class="search-criteria-heading">{{ 'GROUP' | translate}}</h6>
          <span> {{ fotaState['groupDetails']['name'] }}</span>
        </div>
        <div *ngIf="fotaState['currentFirwareVersion']">
          <h6 class="search-criteria-heading">{{ 'FIRMWARE_VERSION' | translate}}</h6>
          <span> {{ fotaState['currentFirwareVersion']['name'] }}</span>
        </div>
        <div *ngIf="fotaState['businessProfile']">
          <h6 class="search-criteria-heading">{{ 'BUSINESS_PROFILE' | translate}}</h6>
          <span> {{ fotaState['businessProfile']['name'] }}</span>
        </div>
        <div *ngIf="fotaState['deviceInterfaceProvider']">
          <h6 class="search-criteria-heading">{{ 'DEVICE_INTERFACE_PROVIDER' | translate}}</h6>
          <span> {{ fotaState['deviceInterfaceProvider']['name'] }}</span>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel  *ngIf="fotaState['solutionTypeDetails'] || editExistingJob" class="panel" expanded>
      <mat-expansion-panel-header class="header-style zero-padding">
        <mat-panel-title class="heading-label">
          <span class="heading-label">
            <img class="page-icon" [src]="fotaIcon" />
            <span>{{'CREATE_FOTA_JOB' |translate}}</span>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="panel-body">
        <form [formGroup]="fotaForm" class="form-fields">
          <div>
            <mat-form-field>
              <mat-label class="label">{{'JOB_NAME' | translate}} <span class="mandatory">*</span> </mat-label>
              <input type="text" matInput formControlName="jobName" class="form-field" [readonly]="fotaJobId"
                [placeholder]=" 'TYPE_HERE' | translate" />
            </mat-form-field>
            <div *ngIf="submitted || fotaForm.controls.jobName?.touched && fotaForm.controls.jobName?.errors">
              <div *ngIf="fotaForm.controls.jobName?.errors?.required">
                <mat-error>
                  {{ 'JOB_NAME' | translate}} {{'IS_REQUIRED' | translate }}
                </mat-error>
              </div>
              <mat-error *ngIf="fotaForm.controls.jobName?.errors?.maxlength">
                {{ 'JOB_NAME_SHOULD_BE_OF_MAXIMUM_50_CHARACTERS' | translate}}</mat-error>
            </div>
          </div>

          <div>
            <div class="align-row">
            <mat-form-field>
              <mat-label class="label">{{ 'FIRMWARE_ARTIFACT' | translate}}
                <span class="mandatory">*</span>
              </mat-label>
              <mat-select class="form-field" [class.disabled]="disableBtn" formControlName="firmwareArtf"
                [placeholder]=" 'SELECT' | translate">
                <mat-option>{{ 'SELECT' | translate }}</mat-option>
                <mat-option *ngFor="let val of dropDownValues['fwversion']" [value]="val.firmwareId">
                  {{val['File Name:Version']}}</mat-option>
              </mat-select>
            </mat-form-field>
            <span class="info-icon">
              <img [src]="infoIconImage" alt="info" [title]="'FIRMWARE_ARTIFACT_INFO_MESSAGE'  | translate">
          </span>
        </div>
            <div *ngIf="submitted || fotaForm.controls.firmwareArtf?.touched && fotaForm.controls.firmwareArtf?.errors">
              <div *ngIf="fotaForm.controls.firmwareArtf?.errors?.required">
                <mat-error class="mat-error-class">
                  {{ 'FIRMWARE_ARTIFACT' | translate}} {{'IS_REQUIRED' | translate }}
                </mat-error>
              </div>
            </div>
          </div>

          <div>
            <mat-form-field>
              <mat-label class="label">{{ 'OPERATING_TYPE' | translate}}
                <span class="mandatory">*</span>
              </mat-label>
              <mat-select class="form-field" [class.disabled]="disableBtn" formControlName="operatingType"
                [placeholder]=" 'SELECT' | translate">
                <mat-option *ngFor="let val of dropDownValues['operatingType']" [value]="val.id" [disabled]="fotaState.deviceTypeDetails && (val['name'] === 'DEVICETWIN' && fotaState['deviceInterfaceProvider']['name'] === env['providerZelIot'])">
                  {{val['name'] | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div
              *ngIf="submitted || fotaForm.controls.operatingType?.touched && fotaForm.controls.operatingType?.errors">
              <div *ngIf="fotaForm.controls.operatingType?.errors?.required">
                <mat-error class="mat-error-class">
                  {{ 'OPERATING_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
                </mat-error>
              </div>
            </div>
          </div>
          <div>
            <mat-label class="label label-color">{{'EMAIL' | translate}} <span class="mandatory">*</span> </mat-label>
            <div formArrayName="fotaEmailList">
              <div *ngFor="let group of fotaForm.get('fotaEmailList')['controls']; let indexNumber=index">
                <div class="email">
                  <div class="email-input">
                    <mat-form-field floatLabel='never'>
                      <input type="text" matInput formControlName="{{indexNumber}}" id="emailId{{indexNumber}}"
                        class="form-field" [placeholder]=" 'TYPE_HERE' | translate" />
                    </mat-form-field>
                    <div
                      *ngIf="submitted || fotaForm.controls['fotaEmailList'].controls[indexNumber].touched && fotaForm.controls['fotaEmailList'].controls[indexNumber].errors">
                      <div *ngIf="fotaForm.controls['fotaEmailList'].controls[indexNumber].errors?.required">
                        <mat-error class="mat-error-class">
                          {{ 'EMAIL' | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                      </div>
                      <mat-error *ngIf="fotaForm.controls['fotaEmailList'].controls[indexNumber].errors?.pattern">
                        {{ 'PLEASE_ENTER_A_VALID_EMAIL_ID' | translate}}
                      </mat-error>
                    </div>
                  </div>
                  <div>
                    <button type="button" mat-icon-button color="primary" aria-label="Add"
                    class="action-button-font" (click)="addEmailControl()">+</button>
                  </div>
                  <div>
                    <button *ngIf="fotaForm.get('fotaEmailList')['controls'].length > 1" mat-icon-button color="primary"
                      class="action-button-font" aria-label="Remove/clear" (click)="removeEmail(indexNumber)">-</button>
                  </div>

                </div>
              </div>
            </div>

          </div>

          <div>
            <div class="date-time">
              <div>
                <mat-form-field class="form-date">
                  <mat-label class="label">{{'DATE' | translate}} <span class="mandatory">*</span> </mat-label>
                  <input matInput [matDatepicker]="picker" [placeholder]="'SELECT' | translate" (dateChange)="isToday(jobStartDate)"
                    formControlName="jobStartDate" #jobStartDate>
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <div *ngIf="submitted || fotaForm.controls.jobStartDate?.touched">
                  <div *ngIf="fotaForm.controls.jobStartDate?.errors?.required">
                    <mat-error class="mat-error-class">
                      {{ 'DATE' | translate}} {{'IS_REQUIRED' | translate }}
                    </mat-error>
                  </div>
                  <mat-error *ngIf="isNotToday" class="mat-error-class">
                    {{ 'STARTDATE_LESSTHAN_CURRENTDATE' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>
              <div>
                <mat-form-field>
                  <mat-label class="label">{{'START_TIME' | translate}} <span class="mandatory">*</span> </mat-label>
                  <input type="time" matInput formControlName="jobStartTime" #jobStartTime class="form-field" (change)="isToday(jobStartTime)"
                    [placeholder]=" 'TYPE_HERE' | translate" />
                </mat-form-field>
                <div *ngIf="submitted || fotaForm.controls.jobStartTime?.touched">
                  <div *ngIf="fotaForm.controls.jobStartTime?.errors?.required">
                    <mat-error class="mat-error-class">
                      {{ 'START_TIME' | translate}} {{'IS_REQUIRED' | translate }}
                    </mat-error>
                  </div>
                  <mat-error *ngIf="checkTodayDate" class="mat-error-class">
                    {{ 'STARTTIME_LESSTHAN_CURRENTTIME' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>
            </div>

            <div>
              <mat-form-field>
                <mat-label class="label">{{ 'CAMPAIGN_TYPE' | translate}}
                  <span class="mandatory">*</span>
                </mat-label>
                <mat-select class="form-field" formControlName="campaignType" [placeholder]=" 'SELECT' | translate"
                  (selectionChange)="getTabOptions($event.value)">
                  <mat-option *ngFor="let val of dropDownValues['campaignType']" [value]="val.id" [disabled]="fotaState.deviceTypeDetails && (val['name'] === 'TIME_CAMPAIGN' && fotaState['deviceInterfaceProvider']['name'] === env['providerBosch'])
                  || fotaState.deviceTypeDetails && (val['name'] === 'GROUP_CAMPAIGN' && fotaState['deviceInterfaceProvider']['name'] === env['providerZelIot']) ">
                    {{val['name'] | translate }}</mat-option>
                </mat-select>
              </mat-form-field>
              <div
                *ngIf="submitted || fotaForm.controls.campaignType?.touched && fotaForm.controls.campaignType?.errors">
                <div *ngIf="fotaForm.controls.campaignType?.errors?.required">
                  <mat-error class="mat-error-class">
                    {{ 'CAMPAIGN_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
                  </mat-error>
                </div>
              </div>
            </div>

            <mat-tab-group *ngIf="matTabOptions.length>0">
              <mat-tab *ngFor="let tab of matTabOptions" class="tab-class" [label]="tab.label"
                [disabled]="tab.disabled">
                <div *ngIf="tab.value==='TimeCampaign'" class="campaign-details">
                  <!-- dynamic formGroups -->
                  <div>
                    <mat-label class="label label-color">{{ 'OCCURRENCE' | translate}}
                      <span class="mandatory">*</span></mat-label>
                    <div formArrayName="fotaJobTimingsDtoList">
                      <div *ngIf="isSchedularValid">
                        <mat-error class="mat-error-class">
                          {{ 'OCCURRENCE' | translate}} {{'IS_REQUIRED' | translate }}
                        </mat-error>
                      </div>
                      <div
                        *ngFor="let group of fotaForm.get('fotaJobTimingsDtoList')['controls']; let indexNumber=index">
                        <div class="time-labels" [formGroupName]="indexNumber">
                          <div class="checkbox-day">
                            <mat-checkbox formControlName="dayOfWeek" (change)="checkboxChange(indexNumber)">
                              {{ weekDays[indexNumber] | translate }}</mat-checkbox>
                          </div>
                          <div>
                            <mat-form-field>
                              <mat-label class="label">{{'UPDATE_START_TIME' | translate}} </mat-label>
                              <input type="time" matInput formControlName="jobStartTime" class="form-field"
                                [placeholder]=" 'TYPE_HERE' | translate" (change)="checkboxChange(indexNumber)" />
                            </mat-form-field>
                            <div *ngIf="timeDifference === indexNumber">
                              <mat-error class="mat-error-class">
                                {{'START_DATE_ERROR_MSG' | translate}}
                              </mat-error>
                            </div>
                            <div
                              *ngIf="submitted || group.get('jobStartTime').touched && group.get('jobStartTime').errors">
                              <div *ngIf="group.get('jobStartTime').errors?.required">
                                <mat-error class="mat-error-class">
                                  {{ 'UPDATE_START_TIME' | translate}} {{'IS_REQUIRED' | translate }}
                                </mat-error>
                              </div>
                            </div>
                          </div>
                          <div>
                            <mat-form-field>
                              <mat-label class="label">{{'UPDATE_END_TIME' | translate}} </mat-label>
                              <input type="time" matInput formControlName="jobEndTime" class="form-field"
                                [placeholder]=" 'TYPE_HERE' | translate" (change)="checkboxChange(indexNumber)" />
                            </mat-form-field>
                            <div *ngIf="submitted || group.get('jobEndTime').touched">
                              <div *ngIf="group.get('jobEndTime').errors?.required">
                                <mat-error class="mat-error-class">
                                  {{ 'UPDATE_END_TIME' | translate}} {{'IS_REQUIRED' | translate }}
                                </mat-error>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mat-card">
                    <mat-card>
                      <header class="heading">{{ 'ADVANCED_SETTINGS' | translate }}</header>
                      <div>
                        <div>
                          <mat-label class="label label-color">{{ 'CONCURRENCY' | translate}}
                            <span class="mandatory">*</span>
                          </mat-label>
                        </div>
                        <div class="slider">
                          <div class="slider-grid">
                            <mat-slider class="slider-length" formControlName="concurrency" tickInterval="1"
                              [min]="concurrency_minlength" [max]="concurrency_maxlength" step="1"
                              [(ngModel)]="concurrencyValue"></mat-slider>
                          </div>
                          <div class="slider-size">{{concurrencyValue}}</div>
                        </div>
                        <div
                          *ngIf="submitted || fotaForm.controls.concurrency?.touched && fotaForm.controls.concurrency?.errors">
                          <div *ngIf="fotaForm.controls.concurrency?.errors?.required">
                            <mat-error class="mat-error-class">
                              {{ 'CONCURRENCY' | translate}} {{'IS_REQUIRED' | translate }}
                            </mat-error>
                          </div>
                        </div>
                      </div>
                      <div>
                        <mat-form-field>
                          <mat-label class="label">{{ 'CONCURRENCY_TYPE' | translate}}
                            <span class="mandatory">*</span>
                          </mat-label>
                          <mat-select class="form-field" [class.disabled]="disableBtn" formControlName="concurrencyType"
                            [placeholder]=" 'SELECT' | translate">
                            <mat-option>{{ 'SELECT' | translate }}</mat-option>
                            <mat-option *ngFor="let val of dropDownValues['concurrencyType']" [value]="val.id">
                              {{ val['name'] | translate}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div
                          *ngIf="submitted || fotaForm.controls.concurrencyType?.touched && fotaForm.controls.concurrencyType?.errors">
                          <div *ngIf="fotaForm.controls.concurrencyType?.errors?.required">
                            <mat-error class="mat-error-class">
                              {{ 'CONCURRENCY_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
                            </mat-error>
                          </div>
                        </div>
                      </div>
                      <div>
                        <mat-form-field>
                          <mat-label class="label">{{'TIME_LAPSE' | translate}}
                            <span class="mandatory">*</span></mat-label>
                          <input type="number" class="form-field" matInput formControlName="timeInterval"
                            [min]="timeInterval_minlength" [max]="timeInterval_maxlength"
                            [placeholder]=" 'TYPE_HERE' | translate" />
                          <span matSuffix>{{'HR' | translate}}</span>
                        </mat-form-field>
                        <div
                          *ngIf="submitted || fotaForm.controls.timeInterval?.touched && fotaForm.controls.timeInterval?.errors">
                          <div *ngIf="fotaForm.controls.timeInterval?.errors?.required">
                            <mat-error class="mat-error-class">
                              {{ 'TIME_LAPSE' | translate}} {{'IS_REQUIRED' | translate }}
                            </mat-error>
                          </div>
                          <div *ngIf="fotaForm.controls.timeInterval?.errors?.min">
                            <mat-error class="mat-error-class">
                              {{ 'TIME_LAPSE_SHOULD_BE_GREATER_THAN_ZERO' | translate}}
                            </mat-error>
                          </div>
                          <div *ngIf="fotaForm.controls.timeInterval?.errors?.max">
                            <mat-error class="mat-error-class">
                              {{ 'TIME_LAPSE_SHOULD_BE_MAXIMUM_OF_THREE_HOURS' | translate}}
                            </mat-error>
                          </div>
                        </div>
                      </div>
                    </mat-card>
                  </div>
                </div>
                <div *ngIf="tab.value==='GroupCampaign'" class="campaign-details">
                  <div>
                      <mat-label class="label label-bold">{{ 'ACTION_TYPE' | translate}}
                        <span class="mandatory">*</span>
                      </mat-label>
                      <div class="action-type">
                        <div  class="action-type-radio-buttons">
                        <mat-radio-group formControlName="actionType"
                          (change)="onActionTypeChange($event)" required>
                          <mat-radio-button value="FORCED">{{'FORCED' | translate}}</mat-radio-button>
                          <mat-radio-button value="TIME_FORCED">{{'TIME_FORCED' | translate}}</mat-radio-button>
                        </mat-radio-group>
                        <div *ngIf="submitted || fotaForm.controls.actionType?.touched">
                          <div *ngIf="fotaForm.controls.actionType?.errors?.required">
                            <mat-error class="mat-error-class">
                              {{ 'ACTION_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
                            </mat-error>
                          </div>    
                        </div>  
                      </div>                
                        <div class="time-forced">
                          <mat-form-field>
                            <mat-label class="label">{{'DATE' | translate}} <span class="mandatory"
                                *ngIf="fotaForm.controls.timeForcedStartDate?.value && fotaForm.controls.timeForcedStartDate?.value['mandatory']">*</span>
                            </mat-label>
                            <input matInput [matDatepicker]="picker" [placeholder]="'SELECT' | translate" (dateChange)="isToday(timeForcedStartDate)"
                              formControlName="timeForcedStartDate" #timeForcedStartDate>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                          </mat-form-field>
                          <div *ngIf="submitted || fotaForm.controls.timeForcedStartDate?.touched">
                            <div *ngIf="fotaForm.controls.timeForcedStartDate?.errors?.required">
                              <mat-error class="mat-error-class">
                                {{ 'DATE' | translate}} {{'IS_REQUIRED' | translate }}
                              </mat-error>
                            </div>
                            <mat-error *ngIf="isNotToday" class="mat-error-class">
                              {{ 'FORCEDDATE_LESSTHAN_CURRENTDATE' | translate}} {{'IS_REQUIRED' | translate }}
                            </mat-error>
                          </div>
                        </div>
                        <div class="time-forced">
                          <mat-form-field>
                            <mat-label class="label">{{'TIME' | translate}} <span class="mandatory"
                                *ngIf="fotaForm.controls.timeForcedStartTime?.value && fotaForm.controls.timeForcedStartTime?.value['mandatory']">*</span>
                            </mat-label>
                            <input type="time" matInput formControlName="timeForcedStartTime" #timeForcedStartTime class="form-field"
                              [placeholder]="'TYPE_HERE' | translate" (change)="isToday(timeForcedStartTime)" />
                          </mat-form-field>
                          <div *ngIf="submitted || fotaForm.controls.timeForcedStartTime?.touched">
                            <div *ngIf="fotaForm.controls.timeForcedStartTime?.errors?.required">
                              <mat-error class="mat-error-class">
                                {{ 'TIME' | translate}} {{'IS_REQUIRED' | translate }}
                              </mat-error>
                            </div>
                            <mat-error *ngIf="checkTodayDate" class="mat-error-class">
                              {{ 'FORCEDTIME_LESSTHAN_CURRENTTIME' | translate}} {{'IS_REQUIRED' | translate }}
                            </mat-error>
                          </div>
                        </div>
                      
                      </div>
                  </div> 
                    <div class="single-row-field">
                      <mat-form-field>
                        <mat-label class="label">{{ 'NO_OF_GROUPS' | translate}}
                          <span class="mandatory">*</span>
                        </mat-label>
                        <input type="text" matInput formControlName="numberOfGroups" class="form-field"
                          [placeholder]=" 'TYPE_HERE' | translate"/>
                      </mat-form-field>
                      <div
                        *ngIf="submitted || fotaForm.controls.numberOfGroups?.touched && fotaForm.controls.numberOfGroups?.errors">
                        <div *ngIf="fotaForm.controls.numberOfGroups?.errors?.required">
                          <mat-error class="mat-error-class">
                            {{ 'NO_OF_GROUPS' | translate}} {{'IS_REQUIRED' | translate }}
                          </mat-error>
                        </div>
                        <div *ngIf="fotaForm.controls.numberOfGroups?.errors?.pattern">
                          <mat-error class="mat-error-class">
                            {{ 'PLEASE_ENTER_VALID_NO_OF_GROUPS' | translate}}
                          </mat-error>
                        </div>
                      </div>
                    </div>

                  <div class="trigger-threshold-row">
                    <div class="align-row">
                    <mat-form-field>
                      <mat-label class="label">{{ 'TRIGGER_THRESHOLD' | translate}}
                      </mat-label>
                      <input type="text" matInput formControlName="triggerThreshold" class="form-field"
                        [placeholder]=" 'TYPE_HERE' | translate"/>
                      <span matSuffix class="percentage">%</span>
                    </mat-form-field>
                    <span class="info-icon">
                      <img [src]="infoIconImage" alt="info" [title]="'FOTA_TRIGGER_THRESHOLD_INFO_MSG'  | translate">
                  </span>
                  </div>
                    <div
                      *ngIf="submitted || fotaForm.controls.triggerThreshold?.touched && fotaForm.controls.triggerThreshold?.errors">
                      <div *ngIf="fotaForm.controls.triggerThreshold?.errors?.min">
                        <mat-error class="mat-error-class">
                          {{ 'PLEASE_ENTER_VALID_TRIGGER_THRESHOLD' | translate}}
                        </mat-error>
                      </div>
                      <div *ngIf="fotaForm.controls.triggerThreshold?.errors?.max">
                        <mat-error class="mat-error-class">
                          {{ 'PLEASE_ENTER_VALID_TRIGGER_THRESHOLD' | translate}}
                        </mat-error>
                      </div>
                    </div>                  
                  </div>
                  <div class="error-threshold-field">
                    <div class="align-row">
                    <mat-form-field>
                      <mat-label class="label">{{ 'ERROR_THRESHOLD' | translate}}
                      </mat-label>
                      <input type="text" matInput formControlName="errorThreshold" class="form-field"
                        [placeholder]=" 'TYPE_HERE' | translate" pattern="[0-9]+" />
                        <span matSuffix class="percentage">%</span>
                    </mat-form-field>
                    <span class="info-icon">
                      <img [src]="infoIconImage" alt="info" [title]="'FOTA_ERROR_THRESHOLD_INFO_MSG'  | translate">
                  </span>
                  </div>
                    <div
                      *ngIf="submitted || fotaForm.controls.errorThreshold?.touched && fotaForm.controls.errorThreshold?.errors">
                      <div *ngIf="fotaForm.controls.errorThreshold?.errors?.min">
                        <mat-error class="mat-error-class">
                          {{ 'PLEASE_ENTER_VALID_ERROR_THRESHOLD' | translate}}
                        </mat-error>
                      </div>
                      <div *ngIf="fotaForm.controls.errorThreshold?.errors?.max">
                        <mat-error class="mat-error-class">
                          {{ 'PLEASE_ENTER_VALID_ERROR_THRESHOLD' | translate}}
                        </mat-error>
                      </div>
                    </div>
                  </div>                
              </div>  
              </mat-tab>
            </mat-tab-group>
          </div>
        </form>
        <div>
          <section class="section">
            <button *ngIf="!fotaJobId" [disabled]="disableBtn" (click)="onSubmit('submit')" mat-raised-button
              class="button button-submit">{{ 'SUBMIT' | translate }}</button>
            <button *ngIf="fotaJobId" (click)="onSubmit('update')" mat-raised-button
              class="button">{{ 'UPDATE' | translate }}</button>
            <button (click)="gotoConfirmDialog()" [disabled]="disableBtn" mat-raised-button
              class="button button-large button-submit">{{ 'SUBMIT_AND_PLAY' | translate }}</button>
            <button (click)="onReset()" [disabled]="disableBtn" mat-button
              class="button">{{ 'RESET' | translate }}</button>
          </section>
        </div>
      </div>
    </mat-expansion-panel>

    <div *ngIf="geoFenceBlock  && !fotaState['solutionTypeDetails']" class="searchForm">
      <div class="create-geofence-buttons">
      <span>
        <img class="back-image" (click)="navigateBack()" [src]="backLeftImage" />
        </span>
      <input type="text" class="form-control searchbar" [(ngModel)]="placeSearch"  (keydown.enter)="$event.preventDefault()"
      placeholder="Search Location" autocorrect="off" autocapitalize="off" spellcheck="off" 
      type="text" #search>
             <button (click)="saveAndFetchGeofence()" *ngIf="this.polygonArray || this.CircleArray || this.existingGeofenceDetail" mat-raised-button
          class="createShape-button button-back btn-background">{{ 'SAVE_GET-GEOFENCE' | translate }}</button>
      </div>

       <div class="existing-geofence">
          <mat-form-field class="dynamic-form-text-fields existing-geofence-dropdown">
            <mat-label class="label storagelable">Existing Geofence</mat-label>
            <mat-select placeholder="select"  (selectionChange)='getgeofenceId()'  class="dynamic-input custom-select">
                 <mat-option  *ngFor="let val of existingGeofenceList" [value]="val['geoFenceId']" class="geofenceNameText">
              <div class="geofence-items">
                <div>
                <span [title]="val.geoFenceName"> {{val.geoFenceName}}</span>  
                 </div> 
                 <div>
                  <img [src]="viewImage" title="view GeoFence" (click)="viewExistingGeoFence($event,val)" class="modify-button">
                 </div>
                 <div>
                  <img [src]="deleteImage"  title="Delete GeoFence" (click)="confirmGeofenceDelete($event,val)" class="modify-button">
                 </div>
              </div>  
              </mat-option>
            </mat-select>
          </mat-form-field> 
      </div> 
    </div>

     <div  class="select-shape" *ngIf="geoFenceBlock  && !fotaState['solutionTypeDetails']">
       <div>
        <img [src]="ellipseIcon" title="Circle" (click)="drawGeoFence('circle','CIRCLE_ICON')" class="modify-button">
       </div>
       <div>
        <img [src]="polygonIcon"   title="Polygon" (click)="drawGeoFence('polygon','POLYGON_ICON')" class="modify-button">
       </div>
       <div>
        <img [src]="deleteImage"   title="Clear Shape" (click)="clearDrewShape()" class="modify-button">
       </div>
    </div>
   <div class="layer-container" *ngIf="geoFenceBlock  && !fotaState['solutionTypeDetails']">
  <div class="main-div">
    <span>
      <img [src]="layersIcon" title="Circle"  class="layers-button">
    </span>
  </div>
  <div class="hover-div">
    <span>
      <img [src]="defaultIcon" (click)="mapTypeChange('roadmap','DEFAULT_ICON')" class="layers-button">
    </span>
     <span>
      <img [src]="satelliteIcon"  (click)="mapTypeChange('hybrid','SATELLITE_ICON')" class="layers-button">
     </span>
     <span>
      <img [src]="terrainIcon"  (click)="mapTypeChange('terrain','TERRAIN_ICON')" class="layers-button">
     </span>
  </div>
  </div> 
      
    
 <div *ngIf="geoFenceBlock && !fotaState['solutionTypeDetails']" class="geoFenceSection">
  <div class="geofenceBlock" >
  <agm-map 
 [latitude]="lat" 
 [longitude]="long"
 [zoom]="zoom"
 [minZoom]="minZoomLevel"
 (mapReady)="onMapReady($event)"
 [zoomControlOptions]="zoomControlOptions"
 [mapTypeId]="maptype?maptype:'roadmap'"
   >
 <agm-marker 
      [latitude]="marker.lat" 
      [longitude]="marker.lng" 
      *ngFor="let marker of Devicelocations; let i = index"
  ></agm-marker> 
  <agm-marker *ngIf="!Devicelocations"
     [latitude]="lat" 
      [longitude]="long" 
  ></agm-marker> 
 </agm-map>
</div>

<div class="listOfDevices" >
  <div class="filters-section" *ngIf="this.overlayCompleteEvent || this.existingGeofenceDetail">
    <mat-form-field  class="filters-matformfield">
      <mat-label class="label">{{'DEVICE_TYPE' | translate}}</mat-label>
      <mat-select placeholder="{{'SELECT'|translate}}"  (selectionChange)="getTags($event.value)" >
        <mat-option class="matoption" value=null>None</mat-option>
        <mat-option *ngFor="let val of deviceTypes" [value]="val['id']" required>{{val['name']}}</mat-option>
      </mat-select>
     </mat-form-field>
     <mat-form-field class="filters-matformfield">
      <mat-label class="label">{{'TAGS' | translate}}</mat-label>
      <mat-select #selectMultiTagsDropdown placeholder="{{'SELECT'|translate}}"  (selectionChange)="tagsChange($event.value,selectMultiTagsDropdown)" multiple >
        <mat-option value=null>{{'SELECT_UNSELECT_ALL'| translate}}</mat-option>
        <mat-option *ngFor="let val of tags" [value]="val['id']" required>{{val['name']}}</mat-option>
      </mat-select>
     </mat-form-field>
  </div>
 
  <div class="deviceListContainer" *ngIf="deviceListSection" >
  <mat-table #mytable *ngIf="this.noDeviceDataFound;else noData" [dataSource]="geoFencedeviceList" class="geofence-table-data">
    <ng-container  matColumnDef="select">
      <mat-header-cell  class="column-small selectAllCheckbox" *matHeaderCellDef>
        <mat-checkbox (click)="$event.stopPropagation()" 
         (change)="selectDevices($event)" 
          [checked]="selectAllDevices" >  </mat-checkbox>
      </mat-header-cell>
      <mat-cell class=" header-cell column-small" *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row['deviceId']) : null; selectAllDevicesCheckBox($event,row)"
        [checked]="selection.isSelected(row['deviceId'])" >
      </mat-checkbox>
      </mat-cell>
    </ng-container>
    <ng-container  matColumnDef="name">
      <mat-header-cell class="geofence-header-style" *matHeaderCellDef>
        <header class="geofence-table-header heading-label">{{'DEVICE_SERIAL_NUMBER' | translate}}</header>
      </mat-header-cell>
      <mat-cell class=" geofence-data-cell data-cell tooltip" *matCellDef="let dataName">
        <span  title=" {{dataName['deviceId']}}" class="cell-value">
          {{dataName['deviceId']}}
        </span>
      </mat-cell>
      </ng-container>
    <mat-header-row class="table-header-row" *matHeaderRowDef="columns"></mat-header-row>
    <mat-row class="geofence-tableRow" *matRowDef="let geoFencedeviceList; columns: columns;"></mat-row>
  </mat-table>
  <ng-template #noData><span class="no-data">{{'NO_DATA_AVAILABLE'| translate}}</span></ng-template>
  </div>
  <div class="save-proceed-buttons" *ngIf="deviceListSection">
    <button *ngIf="!this.existingGeofenceDetail && proceedToFota" (click)="confirmSaveGeofence(content)" mat-raised-button
    class="saveandproceed button-back btn-background">{{'SAVE_PROCEED'| translate }}</button>
    <button (click)="confirmProceedToFota()" [class.disabled]="!numSelectedRows" mat-raised-button
    class="saveandproceed button-back btn-background">{{ 'PROCEED_TO_FOTA' | translate }}</button>
   
  </div>
 
</div>
</div>

 <ng-template #content let-modal>
  <div class="close-btn"><img  class="rb-ic" (click)="onClose()"  [src]="closeIcon" /></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{'SAVE_GEOFENCE'| translate}}</h5>
    </div>
    <div class="modal-body">
        <form [formGroup]="geoFenceForm">
          <div class="create-form-spacing form-fields">
            <div>
              <mat-form-field class="geoFence-matfield">
                <mat-label class="label">{{'GEOFENCE_NAME' | translate}} <span class="mandatory-mark">*</span> </mat-label>
                <input type="text" matInput  formControlName="geoFenceName" class="form-field"
                  [placeholder]=" 'ENTER_GEOFENCE_NAME' | translate" />
                  <div *ngIf="geoFenceForm.controls.geoFenceName?.touched && geoFenceForm.controls.geoFenceName?.errors">
                    <div *ngIf="geoFenceForm.controls.geoFenceName?.errors?.required">
                      <mat-error class="geoFenceNameError">
                        {{ 'GEOFENCE_NAME' | translate}} {{'IS_REQUIRED' | translate }}
                      </mat-error>
                    </div>
                    </div>
              </mat-form-field>
              <div class="close-btn center-align"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="disbleField('linkSim1_Id')"></mat-icon></div>
            </div>
                </div>
        </form>
        <div class="action-buttons">
            <button  (click)="saveGeofence()" mat-raised-button
            class="button button-submit">{{ 'SAVE_PROCEED' | translate }}</button>
        </div>
    </div>
  </div>
</ng-template> 