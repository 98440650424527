import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { SYS_GRPH, APPLN_GRPH, DEV_STATS_MENU_ICON, SYS_GPH_ICON, APPLN_GPH_ICON } from 'src/app/shared/constants/strings';
import { TranslationService } from 'src/app/providers/translation-service';
@Component({
  selector: 'app-system-application-graphs',
  templateUrl: './system-application-graphs.component.html',
  styleUrls: ['./system-application-graphs.component.scss']
})
export class SystemApplicationGraphsComponent implements OnInit {
public graphTypeOptions: any;
public serialNo: string;
public deviceIcon: string;
asideMenus: Array<AsideCard>;
  profileId: string;
  constructor(private router: Router,private route: ActivatedRoute,  private translate: TranslationService,) { }

  ngOnInit(): void {
    this.deviceIcon = DEV_STATS_MENU_ICON;
    this.serialNo = this.route.snapshot.paramMap.get('serialNo');
    this.profileId = this.route.snapshot.paramMap.get('profileId');
    this.graphTypeOptions
    = [{
      'description': 'User can view basic device graphs like, CPU Usage, Memory Usage, Network-In, Network-Out, Free Disk Space on device. This feature does not need subscription',
      'name': 'System Graphs',
      'imgSrc': SYS_GPH_ICON,
      'value': SYS_GRPH
      },
      {
        'description': 'User can create set of customized graphs to view, just by adding a graph name and y-axis coordinates for selected device. User must subscribe to this feature.',
        'name': 'Application Graphs',
        'imgSrc': APPLN_GPH_ICON,
        'value': APPLN_GRPH
      }];
  }
  public getGraphType($event) {
    if ($event === SYS_GRPH) {
      this.router.navigate([ 'device-statistics',{ serialNo: this.serialNo }]);
    } 
    // else if ($event === APPLN_GRPH){
    //   this.router.navigate([ 'application-graphs',{ serialNo: this.serialNo , profileId:this.profileId }]);
    // }
   }
}
