<!--
  ~ Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
-->
<div class="app-content">
  <app-header [loggedInUserProfile]="userProfileObject"></app-header>
    <div class="body-content" *ngIf="showSideNav">
    <bci-content class="bci-content">
      <bci-sidebar [sidebarLinks]="sidebarLinks" [applicationTitle]="applicationTitle" [applicationTitleLineTwo]="applicationTitleLineTwo" class="bci-side-bar">
        <!-- <bci-sidebar-footer [sidebarFooterLinks]="sidebarFooterLinks" [showTime]="showSidebarFooterTime">
        </bci-sidebar-footer> -->
        <!-- <div class="side-nav-div" fxHide.gt-sm></div> -->
        <div class="content">
          <ng-content>
          </ng-content>
          <router-outlet class="router-outlet"></router-outlet>
        </div>
      </bci-sidebar>
    </bci-content>
  </div>
     <div class="body-content" *ngIf="!showSideNav">
    <bci-content class="bci-content">
      <div class="content">
        <ng-content>
        </ng-content>
        <router-outlet></router-outlet>
      </div>
    </bci-content>
  </div>
    <!--<app-footer></app-footer>-->
  </div>
  <app-footer class="app-footer"></app-footer>