import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SimRoutingModule } from './sim-routing.module';
import { RegisterSimComponent } from './register-sim/register-sim.component';
import { ManageSimComponent } from './manage-sim/manage-sim.component';
import { ProcureSimComponent } from './procure-sim/procure-sim.component';
import { VendorRegistrationComponent } from './vendor-registration/vendor-registration.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { SimRegistrationComponent } from './sim-registration/sim-registration.component';

@NgModule({
  declarations: [RegisterSimComponent, ManageSimComponent, ProcureSimComponent, VendorRegistrationComponent, SimRegistrationComponent],
  imports: [
    CommonModule,
    SimRoutingModule,
    SharedModule,
    MatTableModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatButtonModule,
    MatCardModule,
    TranslateModule,
    MatSlideToggleModule,
    FormsModule,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatMenuModule
   ],
  exports: [
    MatButtonModule,
    MatFormFieldModule
  ]
})
export class SimModule { }
