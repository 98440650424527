/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetails } from '../models/common-details';
import { MapProjectDto } from '../models/map-project-dto';
import { MessageBto } from '../models/message-bto';

@Injectable({
  providedIn: 'root',
})
export class MapControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation requestMoreKeysForMapServiceForProfile
   */
  static readonly RequestMoreKeysForMapServiceForProfilePath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/mapservice/moreKeys';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestMoreKeysForMapServiceForProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestMoreKeysForMapServiceForProfile$Response(params: {
    profileId: string;
    solutionTypeId: string;
    noOfKeys: number;
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.RequestMoreKeysForMapServiceForProfilePath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.query('noOfKeys', params.noOfKeys, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `requestMoreKeysForMapServiceForProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestMoreKeysForMapServiceForProfile(params: {
    profileId: string;
    solutionTypeId: string;
    noOfKeys: number;
  }): Observable<MessageBto> {

    return this.requestMoreKeysForMapServiceForProfile$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

  /**
   * Path part for operation requestRegernateKeyForMapService
   */
  static readonly RequestRegernateKeyForMapServicePath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/mapservice/{apiKeyId}/regenerate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestRegernateKeyForMapService()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestRegernateKeyForMapService$Response(params: {
    profileId: string;
    solutionTypeId: string;
    apiKeyId: string;
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.RequestRegernateKeyForMapServicePath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.path('apiKeyId', params.apiKeyId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `requestRegernateKeyForMapService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestRegernateKeyForMapService(params: {
    profileId: string;
    solutionTypeId: string;
    apiKeyId: string;
  }): Observable<MessageBto> {

    return this.requestRegernateKeyForMapService$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

  /**
   * Path part for operation subscribeMapServiceForProfile
   */
  static readonly SubscribeMapServiceForProfilePath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/mapservice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscribeMapServiceForProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeMapServiceForProfile$Response(params: {
    profileId: string;
    solutionTypeId: string;
    mode: 'DEFAULT' | 'CUSTOM';
    noOfKeys?: number;
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.SubscribeMapServiceForProfilePath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.query('mode', params.mode, {});
      rb.query('noOfKeys', params.noOfKeys, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `subscribeMapServiceForProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subscribeMapServiceForProfile(params: {
    profileId: string;
    solutionTypeId: string;
    mode: 'DEFAULT' | 'CUSTOM';
    noOfKeys?: number;
  }): Observable<MessageBto> {

    return this.subscribeMapServiceForProfile$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

  /**
   * Path part for operation unsubscribeMapServiceForProfile
   */
  static readonly UnsubscribeMapServiceForProfilePath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/mapservice';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `unsubscribeMapServiceForProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsubscribeMapServiceForProfile$Response(params: {
    profileId: string;
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.UnsubscribeMapServiceForProfilePath, 'delete');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `unsubscribeMapServiceForProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  unsubscribeMapServiceForProfile(params: {
    profileId: string;
    solutionTypeId: string;
  }): Observable<MessageBto> {

    return this.unsubscribeMapServiceForProfile$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

  /**
   * Path part for operation getMapProjects
   */
  static readonly GetMapProjectsPath = '/api/v1/map-projects';

  /**
   * Get map projects with api keys.
   *
   * The API fetches map projects and api keys
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMapProjects()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMapProjects$Response(params?: {
    profileId?: string;
    solutionTypeId?: string;
  }): Observable<StrictHttpResponse<Array<MapProjectDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.GetMapProjectsPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
      rb.query('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MapProjectDto>>;
      })
    );
  }

  /**
   * Get map projects with api keys.
   *
   * The API fetches map projects and api keys
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMapProjects$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMapProjects(params?: {
    profileId?: string;
    solutionTypeId?: string;
  }): Observable<Array<MapProjectDto>> {

    return this.getMapProjects$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MapProjectDto>>) => r.body as Array<MapProjectDto>)
    );
  }

  /**
   * Path part for operation saveMapProject
   */
  static readonly SaveMapProjectPath = '/api/v1/map-projects';

  /**
   * Save map projects.
   *
   * The API saves map projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveMapProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveMapProject$Response(params?: {
    body?: MapProjectDto
  }): Observable<StrictHttpResponse<MapProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.SaveMapProjectPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MapProjectDto>;
      })
    );
  }

  /**
   * Save map projects.
   *
   * The API saves map projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveMapProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveMapProject(params?: {
    body?: MapProjectDto
  }): Observable<MapProjectDto> {

    return this.saveMapProject$Response(params).pipe(
      map((r: StrictHttpResponse<MapProjectDto>) => r.body as MapProjectDto)
    );
  }

  /**
   * Path part for operation updateMapProject
   */
  static readonly UpdateMapProjectPath = '/api/v1/map-projects/{mapProjectId}';

  /**
   * Update map projects.
   *
   * The API updates map projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMapProject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMapProject$Response(params: {
    mapProjectId: string;
    body?: MapProjectDto
  }): Observable<StrictHttpResponse<MapProjectDto>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.UpdateMapProjectPath, 'put');
    if (params) {
      rb.path('mapProjectId', params.mapProjectId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MapProjectDto>;
      })
    );
  }

  /**
   * Update map projects.
   *
   * The API updates map projects
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMapProject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMapProject(params: {
    mapProjectId: string;
    body?: MapProjectDto
  }): Observable<MapProjectDto> {

    return this.updateMapProject$Response(params).pipe(
      map((r: StrictHttpResponse<MapProjectDto>) => r.body as MapProjectDto)
    );
  }

  /**
   * Path part for operation requestRestrictionTypeForMapServiceForProfile
   */
  static readonly RequestRestrictionTypeForMapServiceForProfilePath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/mapservice/restrictionTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestRestrictionTypeForMapServiceForProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestRestrictionTypeForMapServiceForProfile$Response(params: {
    profileId: string;
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.RequestRestrictionTypeForMapServiceForProfilePath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `requestRestrictionTypeForMapServiceForProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  requestRestrictionTypeForMapServiceForProfile(params: {
    profileId: string;
    solutionTypeId: string;
  }): Observable<MessageBto> {

    return this.requestRestrictionTypeForMapServiceForProfile$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

  /**
   * Path part for operation getAllRestrictionTypes
   */
  static readonly GetAllRestrictionTypesPath = '/api/v1/restriction-types';

  /**
   * Get all Restriction Types.
   *
   * The API fetches all available restriction types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllRestrictionTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRestrictionTypes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.GetAllRestrictionTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * Get all Restriction Types.
   *
   * The API fetches all available restriction types
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllRestrictionTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllRestrictionTypes(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.getAllRestrictionTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation saveMapProjectForProfile
   */
  static readonly SaveMapProjectForProfilePath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/map-projects';

  /**
   * Save map projects.
   *
   * The API saves map projects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveMapProjectForProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveMapProjectForProfile$Response(params: {
    profileId: string;
    solutionTypeId: string;
    body?: MapProjectDto
  }
): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, MapControllerService.SaveMapProjectForProfilePath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  static readonly GetMapProjectsforProfileAndSolutionTypePath = '/api/v1/businessProfile/{profileId}/solutiontype/{solutionTypeId}/map-projects';

     /**
   * Get map projects with api keys.
   *
   * The API fetches map projects and api keys
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMapProjectsforProfileAndSolutionType()` instead.
   *
   * This method doesn't expect any request body.
   */
     getMapProjectsforProfileAndSolutionType$Response(params: {
      profileId: string;
      solutionTypeId: string;
      active?: boolean;
      projectId?: string;
    }): Observable<StrictHttpResponse<Array<MapProjectDto>>> {
  
      const rb = new RequestBuilder(this.rootUrl, MapControllerService.GetMapProjectsforProfileAndSolutionTypePath, 'get');
      if (params) {
        rb.path('profileId', params.profileId, {});
        rb.path('solutionTypeId', params.solutionTypeId, {});
        rb.query('active', params.active, {});
        rb.query('projectId', params.projectId, {});
      }
  
      return this.http.request(rb.build({
        responseType: 'json',
        accept: 'application/json'
      })).pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<MapProjectDto>>;
        })
      );
    }
  
    /**
     * Get map projects with api keys.
     *
     * The API fetches map projects and api keys
     *
     * This method provides access to only to the response body.
     * To access the full response (for headers, for example), `getMapProjectsforProfileAndSolutionType$Response()` instead.
     *
     * This method doesn't expect any request body.
     */
    getMapProjectsforProfileAndSolutionType(params: {
      profileId: string;
      solutionTypeId: string; 
      active?: boolean;
      projectId?:string;
    }
  ): Observable<Array<MapProjectDto>> {
  
      return this.getMapProjectsforProfileAndSolutionType$Response(params).pipe(
        map((r: StrictHttpResponse<Array<MapProjectDto>>) => r.body as Array<MapProjectDto>)
      );
    }
  
    saveMapProjectForProfile(params: {
      profileId: string;
      solutionTypeId: string;
      body?: MapProjectDto
    }
  ): Observable<MessageBto> {
  
      return this.saveMapProjectForProfile$Response(params).pipe(
        map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
      );
    }

     /**
   * Path part for operation getMapProjectsforSolutionAdmin
   */
  static readonly GetMapProjectsforSolutionAdminPath = '/api/v1/admin/map-projects';

  /**
   * Get map projects with id token.
   *
   * The API fetches map projects based on login
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMapProjectsforSolutionAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMapProjectsforSolutionAdmin$Response(params?: {
    
  }
): Observable<StrictHttpResponse<Array<string>>> {
    const rb = new RequestBuilder(this.rootUrl, MapControllerService.GetMapProjectsforSolutionAdminPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * Get map projects with id token.
   *
   * The API fetches map projects based on login
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getMapProjectsforSolutionAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMapProjectsforSolutionAdmin(params?: {
    
  }
): Observable<Array<string>> {

    return this.getMapProjectsforSolutionAdmin$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }
}
