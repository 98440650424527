/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetails } from '../models/common-details';
import { TenantDetailsResponseDto } from '../models/tenant-details-response-dto';
import { TenantDto } from '../models/tenant-dto';

@Injectable({
  providedIn: 'root',
})
export class TenantControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findAllTenants
   */
  static readonly FindAllTenantsPath = '/api/v1/tenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllTenants()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenants$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<TenantDto>>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.FindAllTenantsPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenantDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllTenants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenants(params?: {
    active?: string;
  }): Observable<Array<TenantDto>> {

    return this.findAllTenants$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TenantDto>>) => r.body as Array<TenantDto>)
    );
  }

  /**
   * Path part for operation createTenant
   */
  static readonly CreateTenantPath = '/api/v1/tenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant$Response(params?: {
    body?: TenantDto
  }): Observable<StrictHttpResponse<TenantDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.CreateTenantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTenant(params?: {
    body?: TenantDto
  }): Observable<TenantDto> {

    return this.createTenant$Response(params).pipe(
      map((r: StrictHttpResponse<TenantDto>) => r.body as TenantDto)
    );
  }

  /**
   * Path part for operation findTenantById
   */
  static readonly FindTenantByIdPath = '/api/v1/tenant/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTenantById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTenantById$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<TenantDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.FindTenantByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findTenantById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTenantById(params: {
    id: string;
  }): Observable<TenantDto> {

    return this.findTenantById$Response(params).pipe(
      map((r: StrictHttpResponse<TenantDto>) => r.body as TenantDto)
    );
  }

  /**
   * Path part for operation deleteTenant
   */
  static readonly DeleteTenantPath = '/api/v1/tenant/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.DeleteTenantPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenant(params: {
    id: string;
  }): Observable<void> {

    return this.deleteTenant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateTenant
   */
  static readonly UpdateTenantPath = '/api/v1/tenant/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenant()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenant$Response(params: {
    id: string;
    body?: TenantDto
  }): Observable<StrictHttpResponse<TenantDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.UpdateTenantPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateTenant$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTenant(params: {
    id: string;
    body?: TenantDto
  }): Observable<TenantDto> {

    return this.updateTenant$Response(params).pipe(
      map((r: StrictHttpResponse<TenantDto>) => r.body as TenantDto)
    );
  }

  /**
   * Path part for operation findAllTenantsCount
   */
  static readonly FindAllTenantsCountPath = '/api/v1/tenantCount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllTenantsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenantsCount$Response(params?: {
  }): Observable<StrictHttpResponse<{
[key: string]: number;
}>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.FindAllTenantsCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        [key: string]: number;
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllTenantsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenantsCount(params?: {
  }): Observable<{
[key: string]: number;
}> {

    return this.findAllTenantsCount$Response(params).pipe(
      map((r: StrictHttpResponse<{
[key: string]: number;
}>) => r.body as {
[key: string]: number;
})
    );
  }

  /**
   * Path part for operation findTenantByIdBasic
   */
  static readonly FindTenantByIdBasicPath = '/api/v1/tenantbasic/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTenantByIdBasic()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTenantByIdBasic$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<CommonDetails>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.FindTenantByIdBasicPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CommonDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findTenantByIdBasic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTenantByIdBasic(params: {
    id: string;
  }): Observable<CommonDetails> {

    return this.findTenantByIdBasic$Response(params).pipe(
      map((r: StrictHttpResponse<CommonDetails>) => r.body as CommonDetails)
    );
  }

  /**
   * Path part for operation activateTenant
   */
  static readonly ActivateTenantPath = '/api/v1/tenant/{id}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateTenant$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.ActivateTenantPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateTenant(params: {
    id: string;
  }): Observable<void> {

    return this.activateTenant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateTenant
   */
  static readonly DeactivateTenantPath = '/api/v1/tenant/{id}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateTenant()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateTenant$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.DeactivateTenantPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateTenant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateTenant(params: {
    id: string;
  }): Observable<void> {

    return this.deactivateTenant$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation findAllTenantsBasic
   */
  static readonly FindAllTenantsBasicPath = '/api/v1/tenantbasic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllTenantsBasic()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenantsBasic$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.FindAllTenantsBasicPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllTenantsBasic$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenantsBasic(params?: {
    active?: string;
  }): Observable<Array<CommonDetails>> {

    return this.findAllTenantsBasic$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation findAllTenantsBasicByAccount
   */
  static readonly FindAllTenantsBasicByAccountPath = '/api/v1/account/{accountId}/tenantbasic';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllTenantsBasicByAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenantsBasicByAccount$Response(params: {
    accountId: string;
    active?: string;
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.FindAllTenantsBasicByAccountPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllTenantsBasicByAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenantsBasicByAccount(params: {
    accountId: string;
    active?: string;
  }): Observable<Array<CommonDetails>> {

    return this.findAllTenantsBasicByAccount$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation findAllTenantsByAccount
   */
  static readonly FindAllTenantsByAccountPath = '/api/v1/account/{accountId}/tenant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllTenantsByAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenantsByAccount$Response(params: {
    accountId: string;
    active?: string;
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<TenantDetailsResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, TenantControllerService.FindAllTenantsByAccountPath, 'get');
    if (params) {
      rb.path('accountId', params.accountId, {});
      rb.query('active', params.active, {});
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantDetailsResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllTenantsByAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllTenantsByAccount(params: {
    accountId: string;
    active?: string;
    page?: number;
    limit?: number;
  }): Observable<TenantDetailsResponseDto> {

    return this.findAllTenantsByAccount$Response(params).pipe(
      map((r: StrictHttpResponse<TenantDetailsResponseDto>) => r.body as TenantDetailsResponseDto)
    );
  }

}
