/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { IoTHubDto } from '../models/io-t-hub-dto';

@Injectable({
  providedIn: 'root',
})
export class IoTHubControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findByIoTHubId
   */
  static readonly FindByIoTHubIdPath = '/api/v1/iothub/{id}';

  /**
   * GET specific IoTHub.
   *
   * The api fetches the specific IoTHub for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByIoTHubId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByIoTHubId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<IoTHubDto>> {

    const rb = new RequestBuilder(this.rootUrl, IoTHubControllerService.FindByIoTHubIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IoTHubDto>;
      })
    );
  }

  /**
   * GET specific IoTHub.
   *
   * The api fetches the specific IoTHub for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByIoTHubId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByIoTHubId(params: {
    id: string;
  }): Observable<IoTHubDto> {

    return this.findByIoTHubId$Response(params).pipe(
      map((r: StrictHttpResponse<IoTHubDto>) => r.body as IoTHubDto)
    );
  }

  /**
   * Path part for operation updateIoTHub
   */
  static readonly UpdateIoTHubPath = '/api/v1/iothub/{id}';

  /**
   * Update IoTHub.
   *
   * The api updates the specific IoTHub for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIoTHub()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIoTHub$Response(params: {
    id: string;
    body?: IoTHubDto
  }): Observable<StrictHttpResponse<IoTHubDto>> {

    const rb = new RequestBuilder(this.rootUrl, IoTHubControllerService.UpdateIoTHubPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IoTHubDto>;
      })
    );
  }

  /**
   * Update IoTHub.
   *
   * The api updates the specific IoTHub for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateIoTHub$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateIoTHub(params: {
    id: string;
    body?: IoTHubDto
  }): Observable<IoTHubDto> {

    return this.updateIoTHub$Response(params).pipe(
      map((r: StrictHttpResponse<IoTHubDto>) => r.body as IoTHubDto)
    );
  }

  /**
   * Path part for operation activateIoTHub
   */
  static readonly ActivateIoTHubPath = '/api/v1/iothub/{id}/activate';

  /**
   * Activate IoTHub.
   *
   * The api activates the specific IoTHub for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateIoTHub()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateIoTHub$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IoTHubControllerService.ActivateIoTHubPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activate IoTHub.
   *
   * The api activates the specific IoTHub for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateIoTHub$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateIoTHub(params: {
    id: string;
  }): Observable<void> {

    return this.activateIoTHub$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateIoTHub
   */
  static readonly DeactivateIoTHubPath = '/api/v1/iothub/{id}/deactivate';

  /**
   * Deactivate IoTHub.
   *
   * The api deactivates the specific IoTHub for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateIoTHub()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateIoTHub$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, IoTHubControllerService.DeactivateIoTHubPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deactivate IoTHub.
   *
   * The api deactivates the specific IoTHub for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateIoTHub$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateIoTHub(params: {
    id: string;
  }): Observable<void> {

    return this.deactivateIoTHub$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation findAllIoTHubs
   */
  static readonly FindAllIoTHubsPath = '/api/v1/iothub';

  /**
   * GET All IoTHub.
   *
   * The api fetches all the Available iotHub
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllIoTHubs()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllIoTHubs$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<IoTHubDto>>> {

    const rb = new RequestBuilder(this.rootUrl, IoTHubControllerService.FindAllIoTHubsPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<IoTHubDto>>;
      })
    );
  }

  /**
   * GET All IoTHub.
   *
   * The api fetches all the Available iotHub
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllIoTHubs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllIoTHubs(params?: {
    active?: string;
  }): Observable<Array<IoTHubDto>> {

    return this.findAllIoTHubs$Response(params).pipe(
      map((r: StrictHttpResponse<Array<IoTHubDto>>) => r.body as Array<IoTHubDto>)
    );
  }

  /**
   * Path part for operation createIoTHub
   */
  static readonly CreateIoTHubPath = '/api/v1/iothub';

  /**
   * Create a new IoTHub.
   *
   * The api creates a new IoTHub
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createIoTHub()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIoTHub$Response(params?: {
    body?: IoTHubDto
  }): Observable<StrictHttpResponse<IoTHubDto>> {

    const rb = new RequestBuilder(this.rootUrl, IoTHubControllerService.CreateIoTHubPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IoTHubDto>;
      })
    );
  }

  /**
   * Create a new IoTHub.
   *
   * The api creates a new IoTHub
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createIoTHub$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createIoTHub(params?: {
    body?: IoTHubDto
  }): Observable<IoTHubDto> {

    return this.createIoTHub$Response(params).pipe(
      map((r: StrictHttpResponse<IoTHubDto>) => r.body as IoTHubDto)
    );
  }
}
