<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="deviceIcon" />
        <span>{{'DEVICE_STATISTICS' | translate}}</span>
      </span>
    </div>
    <div class="device-name">
      <span>{{'DEVICE' | translate}}</span>
      <span>{{' / '}}</span>
      <span class="device-class">{{serialNo}}</span>
    </div>
    <mat-tab-group class="tab-grp"  (selectedTabChange)="matChange($event)" #tabGroup>
      <mat-tab [label]="tab.label" *ngFor="let tab of matTabOptions">
      </mat-tab>
    </mat-tab-group>
      <div class="details-main-div" *ngIf="currentTabValue === 'deviceInfo'">
      <div class="gen-net-div">
        <div class="info-div">
          <span class="card-labels">{{'GENERAL_DETAILS' | translate}}</span>
          <div class ="generalcard" *ngFor="let item of generalCardDetails | keyvalue">
           <span class="labelkey"> {{item.key  | appendSpace | titlecase}}</span> 
           <span class="labelvalue"> {{item.value }}  </span> 
          </div>
        </div>

        <div  class="info-div">
          <span class="card-labels">{{'NETWORK_DETAILS' | translate}}</span>
          <div class="networkcard" *ngFor="let item of networkCardDetails | keyvalue">
            <span class="labelkey"> {{item.key | appendSpace | titlecase}} </span> 
            <span class="labelvalue"> {{item.value }} </span> 
           </div>
        </div>
      </div>
      </div> 
     <div class="battery-data-div" *ngIf="currentTabValue === 'batteryData'">
        <div  #voltageChart class="battery-highcharts-container">
          <div class="chart-header">
            <span class="battery-chart-labels">{{'VOLTAGE(V)' | translate}}</span>
          </div>
        </div>
        <div  #socChart class="battery-highcharts-container">
          <div class="chart-header">
            <span class="battery-chart-labels">{{'SoC(%)' | translate}}</span>
          </div>
        </div>
        <div  #batteryDataChart class="battery-highcharts-container">
          <div class="chart-header">
            <span class="battery-chart-labels">{{'CURRENT(A)' | translate}}</span>
          </div>
        </div>     
     </div>
     
  <div class="usage-card" *ngIf="currentTabValue === 'systemActivity'">
      <div class="usage-summary">
        <div class="usage-chart-area">
          <div  #cpuUsageCharts class="highcharts-container">
          <div class="chart-header">
            <span class="chart-labels">{{'CPU_USG' | translate}}</span>
            <img [src]="strechGraphIcon" class="graph-action-icon" (click)="displayDetailedGraph(content, 'cpu', '#d4507a')"/>
          </div>
          </div>
        </div>
        
      </div>
      <div class="usage-summary">
      <div class="usage-chart-area">
        <div #memoryUsageCharts class="highcharts-container">
          <div class="chart-header">
            <span class="chart-labels">{{'MEMORY_USG' | translate}}</span>
          <img [src]="strechGraphIcon" class="graph-action-icon" (click)="displayDetailedGraph(content, 'memory', '#1bc943' )"/>
        </div>
        </div>
      </div>
    </div>
    <div class="usage-summary">
      <div class="usage-chart-area">
        <div #freeDiskUsageCharts class="highcharts-container">
          <div class="chart-header">
            <span class="chart-labels">{{'FREE_DISK_USG' | translate}}</span>
          <img [src]="strechGraphIcon" class="graph-action-icon" (click)="displayDetailedGraph(content, 'freeDiskSpace', '#feb019')"/>
        </div>
        </div>
      </div>
    </div>
    <div class="usage-summary">
      <div class="usage-chart-area">
        <div #netInUsageCharts class="highcharts-container">
          <div class="chart-header">
            <span class="chart-labels">{{'NETWORK_IN_USG' | translate}}</span>
          <img [src]="strechGraphIcon" class="graph-action-icon" (click)="displayDetailedGraph(content, 'networkIn', '#00629e' )"/>
          </div>
        </div>
      </div>
    </div>
    <div class="usage-summary">
      <div class="usage-chart-area">
        <div #netOutUsageCharts class="highcharts-container">
          <div class="chart-header">
            <span class="chart-labels">{{'NETWORK_OUT_USG' | translate}}</span>
          <img [src]="strechGraphIcon" class="graph-action-icon" (click)="displayDetailedGraph(content, 'networkOut', '#4191FF' )"/>
          </div>
        </div>
      </div>
    </div>
  </div> 
   
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>

<ng-template #content let-modal>
  <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
   <div class="modal-width" [class.blurred]="showSpinner">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{chartName | translate}} </h5>
    </div>
    <div class="modal-body">
      <div class="usage-summary">
        <div class="usage-chart-area">
          <div #deviceGraph class="highcharts-container-device"></div>
        </div>
      </div>
    </div>
  </div>

</ng-template>