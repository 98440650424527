import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  public sharedData = new BehaviorSubject<any>(null);
  constructor() { }

  public setData(data) {
      this.sharedData.next(data);
  }

  public getData() {
      return this.sharedData.asObservable();
  }
}
