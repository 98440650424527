import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { ListMapKeysComponent } from './list-map-keys/list-map-keys.component';
import { MapProjectsListComponent } from './map-projects-list/map-projects-list.component';
import { SolutionAdminKeyComponent } from './solution-admin-key/solution-admin-key.component';
import { TenantAdminKeyComponent } from './tenant-admin-key/tenant-admin-key.component';

const routes: Routes = [
  {
    path: 'access-key/tenant-key',
    component: TenantAdminKeyComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'BP_KEY_R', data: {} }
  },
  {
    path: 'access-key/solution-key',
    component: SolutionAdminKeyComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'BP_KEY_R', data: {} }
  },
  {
    path: 'access-key/list-map-keys',
    component: ListMapKeysComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'BP_KEY_R', data: {} }
  },
  {
    path: 'access-key/map-project-list',
    component: MapProjectsListComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'BP_KEY_R', data: {} }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccessKeysRoutingModule { }
