import { Component, OnInit, ViewChild } from '@angular/core';
import { WHATSAPP_ICON } from 'src/app/shared/constants/strings';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';
import { WhatsappWabaOnBoardControllerService } from 'src/app/services/Whatsapp/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { BciLoaderService } from '@bci-web-core/core';
@Component({  selector: 'app-customer-onboarding',
  templateUrl: './customer-onboarding.component.html',
  styleUrls: ['./customer-onboarding.component.scss']
})
export class CustomerOnboardingComponent implements OnInit {
  public permissionList: Array<String> = [];
  public whatsappIcon;
  public showSpinner:any;
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public formData;
  public disableButton = true;
  public rejectComments: string;
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  hideSubmitButton: boolean = false;
  constructor(
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private route: ActivatedRoute,
    private responseHandlerService: ResponseHandlerService,
    private _matDialog: MatDialog,
    private whatsappWabaOnBoardFormControllerService: WhatsappWabaOnBoardControllerService,
    private loaderService:BciLoaderService
  ) {}

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    if (!this.checkPermission('WSP_ONBD_C')) {
      this.hideSubmitButton = true;
      setTimeout(() => {
        this.dynamicForm.formBase.forEach((control) => {
          if (
            control.name === 'account' ||
            control.name === 'tenant' ||
            control.name === 'businessProfile'
          ) {
            control['disableEvent'] = false;
          } else {
            if (
              control.name === 'status' ||
              control.label === 'CUSTOMER_ONBOARD_STATUS'
            ) {
              control['displayNone'] = false;
            }
            control['disableEvent'] = true;
          }
        });
      }, 500);
    }
    this.whatsappIcon = WHATSAPP_ICON;
    this.getAccount();
    this.getUseCase();
    this.getIndustry();
    this.getCountryAndCurrency();
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child !== undefined) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
        }
        if ($event.child === 'businessAccount') {
          this.profileId = $event.value;
          if (!this.checkPermission('WSP_ONBD_C')) {
            this.getOnBoardingDetails();
          }
        }
      }
    }
  }

  public onBlur(event) {
    if (
      (event.createForm.controls['prodNumber'].value &&
        event.createForm.controls['prodNumber'].valid) ||
      (event.createForm.controls['uatNumber'].value &&
        event.createForm.controls['uatNumber'].valid)
    ) {
      if (
        event.createForm.controls['prodNumber'].value ===
        event.createForm.controls['uatNumber'].value
      ) {
        event.createForm.controls['prodNumber'].setErrors({ duplicate: true });
      } else {
        event.createForm.controls['prodNumber'].setErrors(null);
      }
    }
  }

  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getUseCase() {
    this.whatsappWabaOnBoardFormControllerService
      .fetchUseCaseList({})
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['useCase'] = resp;
          } else {
            this.dropDownValues['useCase'] = [];
          }
        },
        (err) => {
          this.dropDownValues['useCase'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getIndustry() {
    this.whatsappWabaOnBoardFormControllerService
      .fetchIndustryList({})
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['industry'] = resp;
          } else {
            this.dropDownValues['industry'] = [];
          }
        },
        (err) => {
          this.dropDownValues['industry'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public getCountryAndCurrency() {
    this.whatsappWabaOnBoardFormControllerService
      .fetchCountryWithCurrencyList({})
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['countryAndCurrency'] = resp;
          } else {
            this.dropDownValues['countryAndCurrency'] = [];
          }
        },
        (err) => {
          this.dropDownValues['countryAndCurrency'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  public onSubmit(event) {
    if (event) {
      this.showSpinner = this.loaderService.showProgressBar();
      const onBoardingDetails = {
        businessName: event.businessName,
        fbBusinessManagerId: event.facebookBusinessManagerID,
        countryId: event.countryAndCurrency,
        useCaseId: event.useCase,
        isInternalMsg: event.internationalMessages,
        uatNumber: event.uatNumber,
        prodNumber: event.prodNumber,
        displayName: event.displayName,
        industryId: event.industry,
        about: event.about,
        eMail: event.email,
        website: event.website,
        address: event.address,
        description: event.description,
        businessContactEmail: event.pocEmail,
        businessContactPhoneNumber: event.phone,
        businessContactPocName: event.pocName,
        incomingMessagesCallBackUrl: event.incomingMessage,
        messageDeliveryReportsCallBackUrl: event.messageDeliveryReports
      };
      const profilePhoto = event.profilePhoto;
      const profileID = event.businessProfile;
      let grpData;
      const reqObj = {
        profileId: profileID,
        body: {
          onBoardDetailsMeta: JSON.stringify(onBoardingDetails),
          file: profilePhoto
        },
      };
      grpData = reqObj;
      this.whatsappWabaOnBoardFormControllerService
        .saveOnBoardDetails(grpData)
        .subscribe(
          (resp) => {
            if (resp) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage(
                'success',
                'CUSTOMER_ONBOARDED_SUCCESS'
              );
            }
          },
          (err) => {
           this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        );
    }
  }

  public onReset() {
    this.formData = {};
    this.dynamicForm.formData = this.formData;
    this.dynamicForm.createForm = this.dynamicForm.toFormGroup(
      this.dynamicForm.formBase,
      this.dynamicForm.formData
    );
    this.dynamicForm.imgSrc = null;
    this.dynamicForm.formBase.forEach((control) => {
      if (control.name === 'profilePhoto') {
        control['downloadUrl'] = null;
      }
    });
    this.disableButton = true;
    this.dynamicForm.createForm.reset();
    this.accountId = null;
    this.tenantId = null;
    this.profileId = null;
    this.rejectComments = null;
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.getAccount();
  }

  public getOnBoardingDetails() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappWabaOnBoardFormControllerService
      .fetchOnBoardDetails({ profileId: this.profileId })
      .subscribe(
        (res) => {
          if (res) {
            this.formData = {
              account: this.accountId,
              tenant: this.tenantId,
              businessProfile: this.profileId,
              businessName: res.businessName,
              facebookBusinessManagerID: res.fbBusinessManagerId,
              countryAndCurrency: res.countryId,
              useCase: res.useCaseId,
              uatNumber: res.uatNumber,
              prodNumber: res.prodNumber,
              displayName: res.displayName,
              industry: res.industryId,
              about: res.about,
              website: res.website,
              address: res.address,
              description: res.description,
              incomingMessage: res.incomingMessagesCallBackUrl,
              messageDeliveryReports: res.messageDeliveryReportsCallBackUrl,
              pocName: res.businessContactPocName,
              email: res.email,
              phone: res.businessContactPhoneNumber,
              status: res.status,
              profilePhoto: res.profilePictureUrl,
              onBoardDetailsId: res.onBoardDetailsId,
              pocEmail: res.businessContactEmail,
              internationalMessages: res.internalMsg ? 'YES' : 'NO'
            };
            if (res.status === 'In progress') {
              this.disableButton = false;
            } else {
              this.disableButton = true;
            }
            this.dynamicForm.formBase.forEach((control) => {
              if (res.profilePictureUrl && control.name === 'profilePhoto') {
                control['downloadUrl'] = res.profilePictureUrl;
              } else {
                control['downloadUrl'] = null;
              }
            });
            this.dynamicForm.formData = this.formData;
            this.dynamicForm.imgSrc = res.profilePictureUrl;
            this.dynamicForm.createForm = this.dynamicForm.toFormGroup(
              this.dynamicForm.formBase,
              this.dynamicForm.formData
            );
          }
          this.loaderService.hideProgressBar(this.showSpinner);
        },
        (err) => {
          this.formData = {
            account: this.accountId,
            tenant: this.tenantId,
            businessProfile: this.profileId,
          };
          this.dynamicForm.formData = this.formData;
          this.dynamicForm.createForm = this.dynamicForm.toFormGroup(
            this.dynamicForm.formBase,
            this.dynamicForm.formData
          );
          this.dynamicForm.imgSrc = null;
          this.dynamicForm.formBase.forEach((control) => {
            if (control.name === 'profilePhoto') {
              control['downloadUrl'] = null;
            }
          });
          this.loaderService.hideProgressBar(this.showSpinner);
          if (err.error.message === 'T_EMPTY_LIST') {
            this.responseHandlerService.returnToastMessage(
              'error',
              'NO_DATA_AVAILABLE'
            );
          } else {
            this.responseHandlerService.returnToastMessage(
              'error',
              err.error.message
            );
          }
        }
      );
  }

  public updateStatus(params: { profileId: string; status: string }) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.whatsappWabaOnBoardFormControllerService
      .updateOnBoardWaBaStatus(params)
      .subscribe(
        (res) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'success',
            'CUSTOMER_ONBOARD_STATUS_UPDATED_SUCCESSFULLY'
          );
          this.getOnBoardingDetails();
        },
        (err) => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  public onApprove() {
    if (this.profileId) {
      const params = {
        profileId: this.profileId,
        status: 'Completed'
      };
      this.updateStatus(params);
    }
  }

  public onReject() {
    if (this.rejectComments) {
      this.onClose();
      if (this.profileId) {
        const params = {
          profileId: this.profileId,
          status: 'Rejected'
        };
        this.updateStatus(params);
      }
    }
  }

  public openRejectCommentsDialog(content) {
    this._matDialog.open(content, {
      maxHeight: '80vh',
      minWidth: '30vw',
      disableClose: true,
      data: null
    });
  }

  public onClose() {
    this._matDialog.closeAll();
    this.rejectComments = null;
  }
}
