<section class="overview-container" >
    <section class="main">
      <div class="header-style">
        <span class="heading-label">
          <img class="page-icon-small" [src]="whatsappIcon" />
          <span>{{'CUSTOMER_ONBOARD' | translate}}</span>
        </span>
      </div>
<div class="form-spacing">
  <app-dynamic-form [jsonFile]="'whatsapp-customer-onboarding'"
  [gridLayout]="'grid-container-whatsapp-onboard'" [hideSubmitButton]="hideSubmitButton" [dropDownValues]="dropDownValues"  (valueSelected)="updateDropdownValues($event)"
  (formEmitter)="onSubmit($event)" (emitValueAfterReset)="false" (resetForm)="onReset()" (emitFormOnBlur)="onBlur($event)" ></app-dynamic-form>
      </div>
      <section class="section" *ngIf="!checkPermission('WSP_ONBD_C')">
        <button [disabled]="disableButton" (click)="onApprove()" mat-raised-button class="button save-continue-button button-submit"
            >{{ 'Complete' |
            translate }}</button>
        <button [disabled]="disableButton" (click)="openRejectCommentsDialog(reviewContent)" mat-raised-button class="button save-continue-button button-submit">{{
            'REJECT' | translate }}</button>
        <button (click)="onReset()" mat-button class="button">{{
            'RESET' | translate }}</button>  
    </section>
    <ng-template #reviewContent let-modal>
      <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
      <div class="modal-width" >
        <div class="modal-body">
          <mat-label class="label label-comment">{{ 'COMMENTS' | translate }}
            <span class="mandatory">*</span>
          </mat-label>
          <mat-form-field  class="form-field-text-area">
          <textarea class="text-area" matInput [(ngModel)]="rejectComments"></textarea>
        </mat-form-field>
          <section class="comment-section">
            <button (click)="onReject()" mat-raised-button class="button">{{
              'SUBMIT' | translate }}</button>
          </section>
        </div>
      </div>
    </ng-template>
    </section>
    </section>
  
  

