import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ASSIGNMENT_RULE, WHATSAPP_ICON, INFO_ICON_IMAGE } from 'src/app/shared/constants/strings';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DepartmentControllerService } from 'src/app/services/Whatsapp-agent/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { PlatformAdminUtilityControllerService } from 'src/app/services/Platform/services';
import { MatTableDataSource } from '@angular/material/table';
import { DepartmentDto } from 'src/app/services/Whatsapp-agent/models';
import { map, startWith } from 'rxjs/operators';
import { TranslationService } from 'src/app/providers/translation-service';

@Component({
  selector: 'app-department-config',
  templateUrl: './department-config.component.html',
  styleUrls: ['./department-config.component.scss']
})
export class DepartmentConfigComponent implements OnInit {
  @ViewChild('inputCtrl') inputCtrl: ElementRef<HTMLInputElement>;
  public submitted = false;
  public infoIcon: string;
  selectedValuesArray: string[] = []; 
  whatsappIcon: string = WHATSAPP_ICON; 
  tags = new UntypedFormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  stringArray: string[] = []; 
  filteredStrings: Observable<string[]>;
  wabaNumber: any;
  departmentList: DepartmentDto[] = []; 
  dataSource: MatTableDataSource<DepartmentDto>;
  columns: string[]; 
  selectable = true;
  removable = true;
  ignoreList: string[] = ['id', 'channelNumber'];
  assignmentRule = ASSIGNMENT_RULE;
  departmentForm: UntypedFormGroup;
  
  constructor(
    private departmentControllerService: DepartmentControllerService,
    private responseHandlerService: ResponseHandlerService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private translate: TranslationService,
    private formBuilder: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {
    this.infoIcon = INFO_ICON_IMAGE;
    this.getWabaNumber(); 
    this.initializeForm();
    this.initializeComponent();
  }

  private initializeComponent() {
    this.filteredStrings = this.tags.valueChanges.pipe(
      startWith(''),
      map(value => this.filterItems(value || ''))
    );
  }

  private initializeForm() {
    this.departmentForm = this.formBuilder.group({
      departmentName: ['', [Validators.required,Validators.minLength(2), Validators.maxLength(25)]],
      tagInputControl: [[], Validators.required]
    });
  }

  private filterItems(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.stringArray.filter(option => option.toLowerCase().includes(filterValue));
  }

  createDepartment() {
    this.submitted = true;
    const data = this.departmentForm.value;
    if(this.departmentForm.valid && this.selectedValuesArray.length > 0) {
    const reqObj = {
      body: {
        departmentName: data['departmentName'],
        channelNumber: this.wabaNumber,
        assignmentRules: this.selectedValuesArray
      },
    };

    this.departmentControllerService.createDepartment(reqObj).subscribe(
      (resp) => {
        if (resp) {
          this.responseHandlerService.returnToastMessage('success', 'DEPARTMENT_CREATED_SUCCESSFULLY');
          this.getDepartmentDetailsList(); 
          this.onReset(); 
        }
      },
      (err) => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
    }
  }

  getWabaNumber() {
    this.platformAdminUtilityControllerService
      .getUserAccountDetails().subscribe(
        (resp) => {
          if (resp) {
            this.wabaNumber = resp['wabaNumber'];
            this.getDepartmentDetailsList(); 
          }
        },
        (err) => {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  getDepartmentDetailsList() {
    const para = {
      channelNumber: this.wabaNumber,
    };
  
    this.departmentControllerService.getDepartmentsByChannelNumber(para).subscribe(
      (resp) => {
        this.departmentList = resp?.filter(department => department.departmentName.toLowerCase() !== 'default') || [];
        if (this.departmentList.length > 0) {
          this.dataSource = new MatTableDataSource<DepartmentDto>(this.departmentList);
          this.columns = this.filterColumns(Object.keys(this.departmentList[0]));
        }
      },
      (err) => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }
  

  filterColumns(columnKeys: string[]): string[] {
   
    return columnKeys.filter(key => !this.ignoreList.includes(key));
  }

  onReset() { 
    this.submitted = false;
    this.departmentForm.reset();
    if (this.selectedValuesArray && this.selectedValuesArray.length) {
      this.selectedValuesArray = [];
    }
  }

  addOrRemoveTags($event, action) {
    if ($event.value && action === 'addSelectedAttribute') {
      if (this.selectedValuesArray.length >= this.assignmentRule) {
        this.responseHandlerService.returnToastMessage('error', `${this.translate.translateErrorMessages('RULES_LIMIT_REACHED')} ${this.assignmentRule} ${this.translate.translateErrorMessages('RULES')}`);
        this.inputCtrl.nativeElement.value = '';
        return;
      }
      if (this.selectedValuesArray.length) {
        if (this.selectedValuesArray.includes($event.value)) {
          this.responseHandlerService.returnToastMessage('warning', 'RULE_ADDED');
          this.inputCtrl.nativeElement.value = '';
        } else {
          this.selectedValuesArray.push($event.value);
          this.inputCtrl.nativeElement.value = '';
        }
      } else {
            this.selectedValuesArray.push($event.value);
            this.inputCtrl.nativeElement.value = '';
          }
      } else if ($event && action === 'removeSelectedAttribute') {
        const index = this.selectedValuesArray.indexOf($event);
        if (this.selectedValuesArray && this.selectedValuesArray.length && index >= 0) {
            this.selectedValuesArray.splice(index, 1);
        }
      }
  }

  add(event: MatChipInputEvent): void {
    this.addOrRemoveTags(event, 'addSelectedAttribute');
  }

  remove(event) {
    this.addOrRemoveTags(event, 'removeSelectedAttribute');
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.addOrRemoveTags(event.option, 'addSelectedAttribute');
  }
}
