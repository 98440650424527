
import { Component, OnInit, ViewChild } from '@angular/core';
import { PlatformAdminUtilityControllerService, SolutionTypeControllerService } from 'src/app/services/Platform/services';
import { DeviceTypeControllerService } from 'src/app/services/DeviceMgmt/services/device-type-controller.service';
import { FirmwareControllerService } from 'src/app/services/DeviceMgmt/services/firmware-controller.service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CREATE, DELETE_IMAGE, DOCUMENT_IMAGE, FIRMWAREVERSION_MAXLENGTH,
  FIRMWARE_ICON, FILE_SIZE_LIMIT, CONTACT_ADMIN} from 'src/app/shared/constants/strings';
import { Router } from '@angular/router';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { StorageAccountControllerService, TagControllerService } from 'src/app/services/DeviceMgmt/services';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { TagsServiceProvider } from 'src/app/providers/tags-service';
import { EnvService } from 'src/app/env.service';
import { BciLoaderService } from '@bci-web-core/core';
@Component({
  selector: 'app-upload-firmware',
  templateUrl: './upload-firmware.component.html',
  styleUrls: ['./upload-firmware.component.scss']
})
export class UploadFirmwareComponent implements OnInit {
  public jsonFileLoaded: string;
  public dropDownValues = [];
  public showSpinner :any;
  public solutionType = [];
  public pageKey: string;
  public firmwareIcon: string;
  public solutionTypeId: string;
  asideMenus: Array<AsideCard>;
  public selectedProvider;
  public selectedfirmWareVersionForZeliot;
  public selectedFileSize;
  public provider;
  public fileSizeInfoMsg: string;

  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
    constructor(private solutionTypeControllerService: SolutionTypeControllerService,
    private deviceTypeControllerService: DeviceTypeControllerService,
    private firmwareControllerService: FirmwareControllerService, private responseHandlerService: ResponseHandlerService,
    private router: Router, private rightsideNavitemsService: RightsideNavitemsService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private storageAccountControllerService: StorageAccountControllerService,
    private tagControllerService: TagControllerService, private tagService: TagsServiceProvider,
    private env: EnvService,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.jsonFileLoaded = 'firmware-upload';
    this.getSolutionType();
    this.compressionType();
    this.pageKey = 'firmware';
    this.rightsideNavitemsService.getRightsideNavItems(CREATE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.firmwareIcon = FIRMWARE_ICON;
  }

  /**
  * @ngdoc method
  * @name  UploadFirmwareComponent#getSolutionType
  *
  * @methodOf
  * firmware.controller:UploadFirmwareComponent
  *
  * @description
  * Description: To populate solution type dropdown.
  *
  * @param {type} null
  * @return {type} null
*/
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
  * @ngdoc method
  * @name UploadFirmwareComponent#updateDropdownValues
  *
  * @methodOf
  * firmware.controller:UploadFirmwareComponent
  *
  * @description
  * Description: To populate dependent dropdown.
  *
  * @param {type} dropdown select event
  * @return {type} null
  */
  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child === 'deviceInterfaceProvider') {
        this.solutionTypeId = $event.value;
        this.getDeviceInterFaceProvider($event.value);
      }
      if ($event.child === 'storageName') {
        this.dynamicForm.clearUploadedFiles();
        this.provider = $event.value;
      }
      if ($event.child === 'deviceType') {
        this.getDeviceType(this.solutionTypeId, this.provider);
        this.getAvailableTags(this.solutionTypeId);
        this.selectedFileSize = $event.value['fileSize'];
        if (this.selectedFileSize < 20) {
          this.fileSizeInfoMsg = FILE_SIZE_LIMIT + this.selectedFileSize + 'MB. ' + CONTACT_ADMIN;
        } else {
          this.fileSizeInfoMsg = FILE_SIZE_LIMIT + this.selectedFileSize + 'MB.';
        }
      }
      if ($event.child === 'firmwareVersionList') {
        if (this.selectedProvider === this.env['providerZelIot'] ) {
          this.getFirmwareVersionList($event.value);
        }
      }
    } else if ($event.dropdownValue && !$event.viewValue) {
        this.getStorageAccounts(this.solutionTypeId, $event.dropdownValue['id']);
        this.selectedProvider = $event.dropdownValue['name'];
    } else if ($event.dropdownValue && $event.viewValue) {
        if (this.selectedProvider === this.env['providerZelIot']) {
          this.selectedfirmWareVersionForZeliot = $event.viewValue;
        }
    }
  }

  /**
    * @ngdoc method
    * @name UploadFirmwareComponent#getDeviceType
    *
    * @methodOf
    *firmware.controller:UploadFirmwareComponent
    *
    * @description
    * Description: To populate device type dropdown.
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
 public getDeviceType(solutionTypeId, deviceInterfaceProviderId ) {
  this.deviceTypeControllerService
    .findAllDeviceTypesBasedOnSolutionType({ id: solutionTypeId, deviceInterfaceProviderId: deviceInterfaceProviderId, active: 'true' })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['deviceType'] = resp;
        } else {
          this.dropDownValues['deviceType'] = [];
        }
      },
      err => {
        this.dropDownValues['deviceType'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
}
  /**
    * @ngdoc method
    * @name UploadFirmwareComponent#getDeviceInterFaceProvider
    *
    * @methodOf
    * SettingsModule.controller:UploadFirmwareComponent
    *
    * @description
    * gets the list of device interface providers using GET method
    *
    * @param {type} solutionTypeId,profileId
    * @return {type} null
  */
  /* @description: to populate device interface provider dropdown */
  public getDeviceInterFaceProvider(solutionTypeId: string) {
    this.platformAdminUtilityControllerService.deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: '', active: 'true' })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['deviceInterfaceProvider'] = resp;
        } else {
          this.dropDownValues['deviceInterfaceProvider'] = [];
        }
      },
      err => {
        this.dropDownValues['deviceInterfaceProvider'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  /**
  * @ngdoc method
  * @name UploadFirmwareComponent#getStorageAccounts
  *
  * @methodOf
  * firmware.controller:UploadFirmwareComponent
  *
  * @description
  * Description: To populate storage account dropdown
  *
  * @param {type} event
  * @return {type} null
  */
  public getStorageAccounts(solutionTypeId: string, deviceInterfaceProviderId: string) {
    this.storageAccountControllerService.getAllStorageAccount({ solutionTypeId: solutionTypeId,
      deviceInterfaceProvider: deviceInterfaceProviderId })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['storageName'] = resp;
        } else {
          this.dropDownValues['storageName'] = [];
          this.selectedFileSize = '';
          this.fileSizeInfoMsg = '';
        }
      },
      err => {
        this.dropDownValues['storageName'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        this.selectedFileSize = '';
        this.fileSizeInfoMsg = '';
      }
    );
  }
  public compressionType() {
    this.dropDownValues['compressionType'] = [
      {
        'id': 'ZIP',
        'name': 'ZIP',
      },
      {
        'id': 'TAR',
        'name': 'TAR',
      }
    ];
  }

  /**
  * @ngdoc method
  * @name UploadFirmwareComponent#uploadFirmware
  *
  * @methodOf
  * firmware.controller:UploadFirmwareComponent
  *
  * @description
  * Description: To upload a firmware.
  *
  * @param {type} event
  * @return {type} null
  */
  public uploadFirmware(data: any) {
    this.showSpinner = this.loaderService.showProgressBar();
    const fileUpload = {};
    let reqObj = {};
    const tags = [];
    for (const tag of this.dynamicForm.selectedValuesArray) {
      const tagEntity = {};
      tagEntity['name'] = tag;
      tags.push(tagEntity);
    }
    if (this.selectedProvider !== this.env['providerZelIot']) {
      if (data.file.length === 1 ) {
        fileUpload['fileOption'] = 'SINGLE';
      } else {
        fileUpload['compressionType'] = data.compressionType;
        fileUpload['fileOption'] = 'MULTIPLE';
      }
    }
    const firmwareDto = {
      solutionType: {
        id: data.solutionType
      },
      deviceType: {
        id: data.deviceType
      },
      deviceInterfaceProvider: {
        id: data.deviceInterfaceProvider
      },
      storageAccount: {
        id: data.storageName
      },
      fileOption:  fileUpload['fileOption'],
      firmwareName: data.firmwareName,
      firmwareVersion: data.firmwareversion,
      compressionType: data.compressionType,
      tags: tags
    };
    if (this.selectedProvider === this.env['providerZelIot']) {
      firmwareDto['firmwareVersionId'] = data['firmwareVersionList'];
      firmwareDto['firmwareVersion'] = this.selectedfirmWareVersionForZeliot;
      delete firmwareDto['fileOption'];
      delete firmwareDto['compressionType'];
      delete firmwareDto['file'];
      reqObj = {
        body: {
          firmwareDto: JSON.stringify(firmwareDto)
        }
      };
    } else {
      reqObj = {
        body: {
          firmwareDto: JSON.stringify(firmwareDto),
          file: data.file
        }
      };
    }
    this.firmwareControllerService.upload(reqObj)
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'FIRMWARE_UPLOAD_SUCCESS');
          this.router.navigate(['firmware/manage-firmware']);
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          if (err.status === 413) {
            this.responseHandlerService.returnToastMessage('error', 'ERROR_UPLOADING_FILE_PLEASE_CONTACT_SYSTEM_ADMIN');
          } else {
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        }
      );
  }
 /**
  * @ngdoc method
  * @name UploadFirmwareComponent#resetForm
  *
  * @methodOf
  * firmware.controller:UploadFirmwareComponent
  *
  * @description
  * Description: To reset form if error occurs while uploading firmware
  *
  * @param {type} $event
  * @return {type} null
  */
  public resetForm($event) {
    if ($event) {
      this.dynamicForm.formBase.forEach((formField) => {
        if (formField.name === 'compressionType') {
          const formFieldIndex = this.dynamicForm.formBase.indexOf(formField);
          this.dynamicForm.formBase.splice(formFieldIndex, 1);
        }
      });
      this.dynamicForm.createForm.reset();
      this.selectedFileSize = '';
      this.fileSizeInfoMsg = '';
    }
  }

 /**
  * @ngdoc method
  * @name UploadFirmwareComponent#getAvailableTags
  *
  * @methodOf
  * firmware.controller:UploadFirmwareComponent
  *
  * @description
  * Description: Fetch the list of available static tags
  * @param {type} solutionTypeId
  * @return {type} null
  */
  public getAvailableTags(solutionTypeId: string) {
    this.dropDownValues['tags'] = null;
    this.tagControllerService.findAllTagsBySolutionTypeId({ solutionTypeId: solutionTypeId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['tags'] = resp;
              resp.forEach(tag => {
                if (tag) {
                  this.dynamicForm.stringArray.push(tag.name);
              }
              });
          } else {
            this.dropDownValues['tags'] = [];
          }
        },
        err => {
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
  * @ngdoc method
  * @name UploadFirmwareComponent#getAvailableTags
  *
  * @methodOf
  * firmware.controller:UploadFirmwareComponent
  *
  * @description
  * Description: Will call the tags common service to perform operations like adding and removing the tags from ui
  * @param {type} $event
  * @return {type} null
  */
  public addOrRemoveTags($event) {
    this.tagService.addOrRemoveTags($event, this.dynamicForm);
  }

   /**
  * @ngdoc method
  * @name UploadFirmwareComponent#getFirmwareVersionList
  *
  * @methodOf
  * firmware.controller:UploadFirmwareComponent
  *
  * @description
  * Description: This methods gets list of firmware version list when provider selected as ZELIOT
  * @param {type} deviceTypeId
  * @return {type} null
  */
 public getFirmwareVersionList(deviceTypeId) {
  this.firmwareControllerService.getFirmwareVersions({deviceTypeId: deviceTypeId}).subscribe (
    resp => {
      if (resp && resp.firmwareList.length) {
        this.dropDownValues['firmwareVersionList'] = resp.firmwareList;
      } else {
        this.dropDownValues['firmwareVersionList'] = [];
      }
    },
    err => {
      this.dropDownValues['firmwareVersionList'] = [];
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
}

}
