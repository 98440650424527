<section class="overview-container">
    <section class="main">
        <ng-container>
            <mat-expansion-panel class="panel" expanded>
                <mat-expansion-panel-header class="header-style">
                    <mat-panel-title class="heading-label">
                        <span class="heading-content">
                            <img (click)="navigateBack()" [src]="backImage" class="back-image"/>
                            <img class="page-icon" [src]="smsIcon" />
                            <span class="title">{{'SMS_JOB_DETAILS' |translate}}</span>
                        </span>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="sms-details-panel" *ngIf="smsDetailsData">
                    <mat-card class="panel-body" *ngFor="let card of smsDetailsData | keyvalue">
                        <h5>{{card.key | translate}}</h5>
                        <div *ngFor="let basic of card.value" class="individual-detail">
                            <header class="sms-detail-label">{{basic['label'] | translate}}</header>
                            <span>{{basic['value']}}</span>
                        </div>
                    </mat-card>
                </div>
            </mat-expansion-panel>

            <div #charts id="pie-chart"></div>
            <div class="info-div">
                <ng-container *ngFor="let data of chartDataObject['details']">
                    <div>
                        <span class="status-text-size">{{data['name'] | translate}} :</span>
                        <div class="overview-status-size" [ngStyle]="{'color': data['color']}">{{ data['count'] }}
                        </div>
                        <hr>
                    </div>
                </ng-container>
            </div>
            <div class="clear-float"></div>
        </ng-container>
        <section class="overview-container">
            <section class="main">
                <div class="table-container" *ngIf="jobDetailsData">
                    <mat-table [dataSource]="dataSource" class="bottom-space table-data">
                        <div *ngFor="let column of columns">
                            <ng-container *ngIf="column!=='select'" [matColumnDef]="column">
                                <mat-header-cell *matHeaderCellDef>
                                    {{column | appendSpace | titlecase | translate}}
                                </mat-header-cell>
                                <mat-cell class="tooltip" *matCellDef="let dataName">
                                    <span title="{{dataName[column]}}" class="cell-value">
                                        {{dataName[column]}}
                                    </span>
                                </mat-cell>
                            </ng-container>
                        </div>
                        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
                    </mat-table>
                </div>
                <mat-paginator *ngIf="dataSource" [pageSizeOptions]="pageSizeOptions" [length]="totalSmsCount"
                    [pageSize]="pageSize" showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
            </section>
        </section>
    </section>
</section>
