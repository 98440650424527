/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MessageDto } from '../models/message-dto';
import { ProposalDetailsCountDto } from '../models/proposal-details-count-dto';
import { ProposalDocumentsMetadataDto } from '../models/proposal-documents-metadata-dto';
import { ProposalDto } from '../models/proposal-dto';
import { ProposalStatusDto } from '../models/proposal-status-dto';
import { ProposalCompleteDetails } from '../models/proposal-complete-details';
import { ProposalTncDto } from '../models/proposal-tnc-dto';
import { ProposalTermsAndConditions } from '../models/proposal-terms-and-conditions';

@Injectable({
  providedIn: 'root',
})
export class ProposalControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllProposalsForApproval
   */
  static readonly GetAllProposalsForApprovalPath = '/api/v1/proposals/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProposalsForApproval()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProposalsForApproval$Response(params?: {
    page?: number;
    limit?: number;
  }): Observable<StrictHttpResponse<ProposalDetailsCountDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.GetAllProposalsForApprovalPath, 'get');
    if (params) {
      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalDetailsCountDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllProposalsForApproval$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProposalsForApproval(params?: {
    page?: number;
    limit?: number;
  }): Observable<ProposalDetailsCountDto> {

    return this.getAllProposalsForApproval$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalDetailsCountDto>) => r.body as ProposalDetailsCountDto)
    );
  }

  /**
   * Path part for operation uploadProposalDocuments
   */
  static readonly UploadProposalDocumentsPath = '/api/v1/proposal/{proposalId}/upload-documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadProposalDocuments()` instead.
   *
   * This method sends 'multipart/form-data' and handles request body of type 'multipart/form-data'.
   */
  uploadProposalDocuments$Response(params: {
    proposalId: string;
    documentName: string;
    body?: {
'files'?: Array<Blob>;
}
  }): Observable<StrictHttpResponse<ProposalDocumentsMetadataDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.UploadProposalDocumentsPath, 'post');
    if (params) {
      rb.path('proposalId', params.proposalId, {});
      rb.query('documentName', params.documentName, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalDocumentsMetadataDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `uploadProposalDocuments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  uploadProposalDocuments(params: {
    proposalId: string;
    documentName: string;
    body?: {
'files'?: Array<Blob>;
}
  }): Observable<ProposalDocumentsMetadataDto> {

    return this.uploadProposalDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalDocumentsMetadataDto>) => r.body as ProposalDocumentsMetadataDto)
    );
  }

  /**
   * Path part for operation getProposalDocuments
   */
  static readonly GetProposalDocumentsPath = '/api/v1/proposal/{proposalId}/documents-list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProposalDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalDocuments$Response(params: {
    proposalId: string;
  }): Observable<StrictHttpResponse<Array<ProposalDocumentsMetadataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.GetProposalDocumentsPath, 'get');
    if (params) {
      rb.path('proposalId', params.proposalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProposalDocumentsMetadataDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProposalDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalDocuments(params: {
    proposalId: string;
  }): Observable<Array<ProposalDocumentsMetadataDto>> {

    return this.getProposalDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProposalDocumentsMetadataDto>>) => r.body as Array<ProposalDocumentsMetadataDto>)
    );
  }

  /**
   * Path part for operation deleteProposalDocuments
   */
  static readonly DeleteProposalDocumentsPath = '/api/v1/proposal/{proposalId}/delete-documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteProposalDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProposalDocuments$Response(params: {
    proposalId: string;
    id: string;
  }): Observable<StrictHttpResponse<MessageDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.DeleteProposalDocumentsPath, 'delete');
    if (params) {
      rb.path('proposalId', params.proposalId, {});
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteProposalDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteProposalDocuments(params: {
    proposalId: string;
    id: string;
  }): Observable<MessageDto> {

    return this.deleteProposalDocuments$Response(params).pipe(
      map((r: StrictHttpResponse<MessageDto>) => r.body as MessageDto)
    );
  }

  /**
   * Path part for operation resubmitProposalToMss
   */
  static readonly ResubmitProposalToMssPath = '/api/v1/proposal/resubmit-mss';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resubmitProposalToMss()` instead.
   *
   * This method doesn't expect any request body.
   */
  resubmitProposalToMss$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.ResubmitProposalToMssPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resubmitProposalToMss$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resubmitProposalToMss(params?: {
  }): Observable<void> {

    return this.resubmitProposalToMss$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getAllProposals
   */
  static readonly GetAllProposalsPath = '/api/v1/proposals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllProposals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProposals$Response(params: {
    profileId: string;
  }): Observable<StrictHttpResponse<Array<ProposalDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.GetAllProposalsPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProposalDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllProposals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllProposals(params: {
    profileId: string;
  }): Observable<Array<ProposalDto>> {

    return this.getAllProposals$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProposalDto>>) => r.body as Array<ProposalDto>)
    );
  }

  /**
   * Path part for operation getSubmittedProposal
   */
  static readonly GetSubmittedProposalPath = '/api/v1/submited-proposal';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubmittedProposal()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubmittedProposal$Response(params: {
    profileId: string;
  }): Observable<StrictHttpResponse<ProposalDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.GetSubmittedProposalPath, 'get');
    if (params) {
      rb.query('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubmittedProposal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubmittedProposal(params: {
    profileId: string;
  }): Observable<ProposalDto> {

    return this.getSubmittedProposal$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalDto>) => r.body as ProposalDto)
    );
  }

  /**
   * Path part for operation getProposalTnc
   */
  static readonly GetProposalTncPath = '/api/v1/proposal/{proposalId}/tnc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProposalTnc()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalTnc$Response(params: {
    proposalId: string;
  }): Observable<StrictHttpResponse<ProposalTermsAndConditions>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.GetProposalTncPath, 'get');
    if (params) {
      rb.path('proposalId', params.proposalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalTermsAndConditions>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProposalTnc$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalTnc(params: {
    proposalId: string;
  }): Observable<ProposalTermsAndConditions> {

    return this.getProposalTnc$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalTermsAndConditions>) => r.body as ProposalTermsAndConditions)
    );
  }
  
  /**
   * Path part for operation approveRejectProposals
   */
  static readonly ApproveRejectProposalsPath = '/api/v1/proposal/approval';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveRejectProposals()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approveRejectProposals$Response(params?: {
    body?: ProposalStatusDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.ApproveRejectProposalsPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `approveRejectProposals$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  approveRejectProposals(params?: {
    body?: ProposalStatusDto
  }): Observable<string> {

    return this.approveRejectProposals$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation submitRecallProposal
   */
  static readonly SubmitRecallProposalPath = '/api/v1/proposal/submit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitRecallProposal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitRecallProposal$Response(params?: {
    body?: ProposalStatusDto
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.SubmitRecallProposalPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `submitRecallProposal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  submitRecallProposal(params?: {
    body?: ProposalStatusDto
  }): Observable<string> {

    return this.submitRecallProposal$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

   
  /**
   * Path part for operation getProposalDetails
   */
  static readonly GetProposalDetailsPath = '/api/v1/proposal/{proposalId}/view';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProposalDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalDetails$Response(params: {
    proposalId: string;
  }): Observable<StrictHttpResponse<ProposalCompleteDetails>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.GetProposalDetailsPath, 'get');
    if (params) {
      rb.path('proposalId', params.proposalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalCompleteDetails>;
      })
    );
  }
  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProposalDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProposalDetails(params: {
    proposalId: string;
  }): Observable<ProposalCompleteDetails> {

    return this.getProposalDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalCompleteDetails>) => r.body as ProposalCompleteDetails)
    );
  }

  /**
   * Path part for operation saveProposalTnc
   */
  static readonly SaveProposalTncPath = '/api/v1/proposal/{proposalId}/tnc';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProposalTnc()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalTnc$Response(params: {
    proposalId: string;
    body?: ProposalTncDto
  }): Observable<StrictHttpResponse<ProposalTermsAndConditions>> {

    const rb = new RequestBuilder(this.rootUrl, ProposalControllerService.SaveProposalTncPath, 'post');
    if (params) {
      rb.path('proposalId', params.proposalId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProposalTermsAndConditions>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveProposalTnc$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProposalTnc(params: {
    proposalId: string;
    body?: ProposalTncDto
  }): Observable<ProposalTermsAndConditions> {

    return this.saveProposalTnc$Response(params).pipe(
      map((r: StrictHttpResponse<ProposalTermsAndConditions>) => r.body as ProposalTermsAndConditions)
    );
  }

}
