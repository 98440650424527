import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { SettingCommonDto } from 'src/app/services/Platform/models';
import { MatTableDataSource} from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { DELETE_IMAGE, FIRMWARE_ICON, MANAGE,  ADD_TAGS, REMOVE_TAGS } from 'src/app/shared/constants/strings';
import { PlatformAdminUtilityControllerService, SolutionTypeControllerService } from 'src/app/services/Platform/services';
import { HttpClient } from '@angular/common/http';
import { DeviceTypeControllerService } from 'src/app/services/DeviceMgmt/services/device-type-controller.service';
import { TranslationService } from 'src/app/providers/translation-service';
import { FirmwareControllerService } from 'src/app/services/DeviceMgmt/services/firmware-controller.service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { CommonId } from 'src/app/services/DeviceMgmt/models';
import { DynamicSearchServiceService } from 'src/app/providers/dynamic-search-service.service';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { TagsServiceProvider } from 'src/app/providers/tags-service';
import { TagControllerService } from 'src/app/services/DeviceMgmt/services';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { SearchComponentComponent } from 'src/app/shared/search-component/search-component.component';
import { StaticTagsComponent } from 'src/app/shared/static-tags/static-tags.component';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-manage-firmware',
  templateUrl: './manage-firmware.component.html',
  styleUrls: ['./manage-firmware.component.scss']
})
export class ManageFirmwareComponent implements OnInit {
  public dropDownValues = [];
  selection = new SelectionModel<CommonId>(true, []);
  public data;
  public totalFirmwareCount;
  public columns = [];
  public deleteImage: string;
  public dataSource;
  public ignoreList = ['links', 'id', 'active', 'firmwareId', 'url', 'solutionTypeId', 'deviceTypeId',
'solutionType', 'deviceType', 'checkSumType', 'technicalId', 'tags', 'tagsList', 'uploadedBy', 'uploadedDate' ];
  public solutionType = [];
  public formData;
  public showSpinner :any;
  public mode: string;
  public searchData;
  public firmwareIcon: string;
  public solutionTypeId: string;
  public selectAllFirmwares = false;
  public selectedTag;
  public jsonFileLoaded;
  public tagsArray = [];
  public firmwareList;
  public availableTags = [];
  public hideActionButtons = true;
  @ViewChild(StaticTagsComponent) dynamicForm: StaticTagsComponent;
  @ViewChild(SearchComponentComponent) searchForm: SearchComponentComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  public pageKey: string;
  asideMenus: Array<AsideCard>;
  constructor(private httpService: HttpClient,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private deviceTypeControllerService: DeviceTypeControllerService, private translate: TranslationService,
    private firmwareControllerService: FirmwareControllerService, private _matDialog: MatDialog,
    private responseHandlerService: ResponseHandlerService, private dynamicSearchServiceService: DynamicSearchServiceService,
    private rightsideNavitemsService: RightsideNavitemsService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private tagControllerService: TagControllerService, private tagService: TagsServiceProvider,private loaderService: BciLoaderService) {
      this.tagService.refreshSearchSubject.subscribe( refreshSearch => {
        if (refreshSearch) {
          this.reloadData(refreshSearch);
        }
      });
    }

  ngOnInit() {
    this.httpService
      .get('./assets/form-fields/firmware-list.json')
      .subscribe(data => {
        this.formData = data as string[];
        this.formData = this.formData['fields'];
        this.dropDownValues = this.dynamicSearchServiceService.generateDynamicFormDropdown(this.formData);
      });
    this.pageKey = 'firmware';
    this.jsonFileLoaded = 'attach-tag';
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.getSolutionType();
    this.deleteImage = DELETE_IMAGE;
    this.firmwareIcon = FIRMWARE_ICON;
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.data.data.forEach(row => this.selection.select(row));
  }

  public checkboxLabel(row?: CommonId): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  /**
    * @ngdoc method
    * @name  ManageFirmwareComponent#getSolutionType
    *
    * @methodOf
    * firmware.controller:ManageFirmwareComponent
    *
    * @description
    * Description: To populate solution type dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name ManageFirmwareComponent#updateDropdownValues
    *
    * @methodOf
    * firmware.controller:ManageFirmwareComponent
    *
    * @description
    * Description: To populate dependent dropdown.
    *
    * @param {type} dropdown select event
    * @return {type} null
  */
  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.value && $event.parentName === 'tags') {
        this.selectedTag = $event.value;
      }
      if ($event.child) {
        if ($event.child.child === 'deviceInterfaceProvider') {
          this.dropDownValues['tags'] = [];
          this.getDeviceInterFaceProvider($event.value);
          this.solutionTypeId = $event.value;
          this.getAvailableTags($event.value);
        }
        if ($event.child.child === 'deviceType') {
          this.getDeviceType(this.solutionTypeId, $event.value);
        }
      }
    } else if ($event.parentName === 'solutionType') {
      this.dropDownValues['tags'] = [];
    }
  }

  /**
    * @ngdoc method
    * @name ManageFirmwareComponent#getDeviceType
    *
    * @methodOf
    *firmware.controller:ManageFirmwareComponent
    *
    * @description
    * Description: To populate device type dropdown.
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
 public getDeviceType(solutionTypeId, deviceInterfaceProviderId ) {
  this.deviceTypeControllerService
    .findAllDeviceTypesBasedOnSolutionType({ id: solutionTypeId, deviceInterfaceProviderId: deviceInterfaceProviderId, active: 'true' })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['deviceType'] = resp;
        } else {
          this.dropDownValues['deviceType'] = [];
        }
      },
      err => {
        this.dropDownValues['deviceType'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}

  public pageEvent(event) {
    this.searchData['page'] = event.pageIndex;
    this.searchData['limit'] = event.pageSize;
    this.getFirmwareList(this.searchData);
    this.selection.clear();
    this.selectAllFirmwares = false;
  }

  public searchForFirmwares(searchData) {
    const tags = [];
    if (searchData.tags) {
      this.selectedTag = searchData['tags'];
      searchData.tags.forEach(tagId => {
          if (tagId) {
            const object = { id: tagId };
            tags.push(object);
          }
        });
      }
    if (searchData['solutionType'] && searchData['deviceType'] && searchData['deviceInterfaceProvider']) {
      const firmwareArtifactDto = {};
      firmwareArtifactDto['solutionType'] = { id: searchData['solutionType'] };
      firmwareArtifactDto['deviceType'] = { id: searchData['deviceType'] };
      firmwareArtifactDto['deviceInterfaceProvider'] = { id: searchData['deviceInterfaceProvider'] };
      firmwareArtifactDto['tags'] = tags;
      this.searchData = {
                          body: firmwareArtifactDto,
                          page: 0,
                          limit: this.pageSize
                        };
      this.getFirmwareList(this.searchData);
    } else {
      this.responseHandlerService.returnToastMessage('warning', 'PLEASE_SELECT_MANDATORY_FIELDS');
      this.dataSource = null;
      this.hideActionButtons = true;
    }
  }

  /**
    * @ngdoc method
    * @name ManageFirmwareComponent#getFirmwareList
    *
    * @methodOf
    * firmware.controller:ManageFirmwareComponent
    *
    * @description
    * Description: Fetches the list of firmware.
    *
    * @param {type} event
    * @return {type} null
  */
  public getFirmwareList(searchData) {
    this.showSpinner = this.loaderService.showProgressBar();
    const arr = [];
    this.selection.clear();
    this.selectAllFirmwares = false;
    this.firmwareControllerService
      .getAllFirmware(searchData)
      .subscribe(
        resp => {
            if (resp.firmwareList && resp.firmwareList.length) {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.data = resp.firmwareList;
            this.totalFirmwareCount = resp.totalFirmwares;
            this.data.forEach(firmware => {
                firmware['storageAccount'] = firmware['storageAccount']['name'];
                firmware['action'] = null;
            });
            this.columns = this.filterColumns(Object.keys(this.data[0]));
            this.columns.unshift('select');
            this.dataSource = new MatTableDataSource<SettingCommonDto>(this.data);
            this.hideActionButtons = false;
          } else {
            this.dataSource = null;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.hideActionButtons = true;
            this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          }
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.dataSource = null;
          this.hideActionButtons = true;
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name ManageFirmwareComponent#filterColumns
    *
    * @methodOf
    * firmware.controller:ManageFirmwareComponent
    *
    * @description
    * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
    *
    * @param {type} columns
    * @return {type} list of columns
  */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  /**
    * @ngdoc method
    * @name ManageFirmwareComponent#deleteFirmware
    *
    * @methodOf
    * firmware.controller:ManageFirmwareComponent
    *
    * @description
    * Description: Deletes particular firmware.
    *
    * @param {type} null
    * @return {type} null
  */
  public deleteFirmware() {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    this.firmwareControllerService.deleteFirmware({firmwareId: this.data['firmwareId']})
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', resp['message']);
          this.getFirmwareList(this.searchData);
        },
        err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name ManageFirmwareComponent#abortAction
    *
    * @methodOf
    * firmware.controller:ManageFirmwareComponent
    *
    * @description
    * Description: Closes the popup
    *
    * @param {type} null
    * @return {type} null
  */
  public abortAction() {
    this._matDialog.closeAll();
  }

  /**
    * @ngdoc method
    * @name ManageFirmwareComponent#gotoConfirmDialog
    *
    * @methodOf
    * firmware.controller:ManageFirmwareComponent
    *
    * @description
    * Description: open the popup
    *
    * @param {type} null
    * @return {type} null
  */
  public gotoConfirmDialog(data = null, mode?) {
    this.data = data;
    let message;
    switch (mode) {
      case 'delete': message = this.translate.translateErrorMessages('DELETE_SELECTED_FIRMWARE');
    }
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (mode) {
          case 'delete': this.deleteFirmware();
            break;
        }
      } else {
        this.abortAction();
      }
    });
  }

  /**
    * @ngdoc method
    * @name UploadFirmwareComponent#getDeviceInterFaceProvider
    *
    * @methodOf
    * firmware.controller.controller:UploadFirmwareComponent
    *
    * @description
    * gets the list of device interface providers using GET method
    *
    * @param {type} solutionTypeId,profileId
    * @return {type} null
  */
  /* @description: to populate device interface provider dropdown */
  public getDeviceInterFaceProvider(solutionTypeId: string) {
    this.platformAdminUtilityControllerService.deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: '', active: 'true' })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['deviceInterfaceProvider'] = resp;
        } else {
          this.dropDownValues['deviceInterfaceProvider'] = [];
        }
      },
      err => {
        this.dropDownValues['deviceInterfaceProvider'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  /**
  * @ngdoc method
  * @name CreateDevice#getAvailableTags
  *
  * @methodOf
  * firmware.controller:manageDevice
  *
  * @description
  * Description: Fetch the list of available static tags
  * @param {type} solutionTypeId
  * @return {type} null
  */
 public getAvailableTags(solutionTypeId: string) {
  this.dropDownValues['tags'] = null;
  this.tagControllerService.findAllTagsBySolutionTypeId({ solutionTypeId: solutionTypeId })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['tags'] = resp;
          this.availableTags = resp;
          if (this.dynamicForm) {
            resp.forEach(tag => {
              if (tag) {
                this.dynamicForm.stringArray.push(tag.name);
              }
            });
          }
          // Below code line clears previously selected tags, if same solutiontype is selected again
          this.searchForm.fields['tags'] = [];
        } else {
          this.dropDownValues['tags'] = [];
        }
      },
      err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}

  /**
    * @ngdoc method
    * @name ManageFirmwareComponent#selectFirmwares
    *
    * @methodOf
    * device.controller:ManageFirmwareComponent
    *
    * @description
    * select or unselect all devices in the table.
    *
    * @param {type} null
    * @return {type} null
  */
 public selectFirmwares() {
  this.selectAllFirmwares = !this.selectAllFirmwares;
  if (this.selectAllFirmwares) {
    this.data.forEach(row => {
      if (row !== undefined) {
        this.selection.select(row['firmwareId']);
      }
    });
  } else {
    this.selection.clear();
  }
}

public selectAllFirmwaresCheckBox() {
  if (this.isAllSelected()) {
    this.selectAllFirmwares = !this.selectAllFirmwares;
  }
}

 /**
    * @ngdoc method
    * @name ManageFirmwareComponent#onClose
    *
    * @methodOf
    * device.controller:ManageFirmwareComponent
    *
    * @description
    * Description: To close the popup.
    *
    * @param {type} null
    * @return {type} null
  */
 public onClose() {
  this._matDialog.closeAll();
  this.tagsArray = [];
  this.selection.clear();
  this.selectAllFirmwares = false;
}

/**
* @ngdoc method
* @name ManageFirmwareComponent#openModal
*
* @methodOf
* firmware.controller:ManageFirmwareComponent
*
* @description
* Description: Opens up the modal to select and attach the tags
* @param {type} content
* @return {type} null
*/
public openModal(content) {
  this.getAvailableTags(this.solutionTypeId);
  const firmwareList = this.selection['selected'].map(firmware => {
    return { 'id': firmware };
  });
  this.firmwareList = firmwareList;
  if (firmwareList.length) {
    firmwareList.forEach(selectedFirmware => {
      if (this.data.indexOf(selectedFirmware)) {
        this.data.forEach(firmware => {
          if (firmware.tagNames && firmware.firmwareId === selectedFirmware.id) {
            firmware.tagNames.forEach(tag => {
              if (this.tagsArray && this.tagsArray.indexOf(tag) === -1) {
                this.tagsArray.push(tag);
              }
            });
          }
        });
      }
    });
  }
  this._matDialog.open(content, { maxHeight: '80vh', minWidth: '30vw', disableClose: true, data: null });
 }

/**
* @ngdoc method
* @name ManageFirmwareComponent#addOrRemoveTags
*
* @methodOf
* firmware.controller:manageFirmwareComponent
*
* @description
* Description: Will call the tags common service to perform operations like adding and removing the tags from ui
* @param {type} $event
* @return {type} null
*/
addOrRemoveTags($event) {
this.tagService.addOrRemoveTags($event, this.dynamicForm, this.availableTags, 'firmwares', this.firmwareList);
}


/**
  * @ngdoc method
  * @name ManageFirmwareComponent#populateExistingTags
  *
  * @methodOf
  * firmware.controller:ManageFirmwareComponent
  *
  * @description
  * Description: This method will populate attached tags to the selected device list, once dynamic form on modal is loaded. Its event based
  * @param {type} $event
  * @return {type} null
  */
 public populateExistingTags($event) {
  if (this.dynamicForm && this.tagsArray.length) {
    this.dynamicForm.selectedValuesArray = this.tagsArray;
  }
}

public reloadData(refreshSearch) {
  if (refreshSearch && refreshSearch === 'refreshAttachSearch') {
    this.getFirmwareList(this.searchData);
    this.loaderService.hideProgressBar(this.showSpinner);
    this.getAvailableTags(this.solutionTypeId);
  } else if (refreshSearch && refreshSearch === 'refreshDetachSearch') {
    this.getFirmwareList(this.searchData);
    this.loaderService.hideProgressBar(this.showSpinner);
  }
}
 /**
    * @ngdoc method
    * @name determineColumnSize
    *
    * @methodOf
    * firmware.controller:ManageFirmwareComponent
    *
    * @description
    * Description: This method will return large or small based on the column size requirements.
    *
    * @param {type} string
    * @return {type} string
  */
public determineColumnSize(columnName) {
  if (columnName === 'action') {
    return 'column-small';
  } else {
    return 'column-large';
  }
}
}

