/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetails } from '../models/common-details';
import { SimVendorDto } from '../models/sim-vendor-dto';
import { VendorDto } from '../models/vendor-dto';

@Injectable({
  providedIn: 'root',
})
export class VendorControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllVendors
   */
  static readonly GetAllVendorsPath = '/api/v1/vendors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllVendors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVendors$Response(params?: {
  }): Observable<StrictHttpResponse<Array<VendorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, VendorControllerService.GetAllVendorsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VendorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllVendors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllVendors(params?: {
  }): Observable<Array<VendorDto>> {

    return this.getAllVendors$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VendorDto>>) => r.body as Array<VendorDto>)
    );
  }

  /**
   * Path part for operation registerSimVendor
   */
  static readonly RegisterSimVendorPath = '/api/v1/vendors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerSimVendor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSimVendor$Response(params?: {
    body?: SimVendorDto
  }): Observable<StrictHttpResponse<SimVendorDto>> {

    const rb = new RequestBuilder(this.rootUrl, VendorControllerService.RegisterSimVendorPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SimVendorDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `registerSimVendor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerSimVendor(params?: {
    body?: SimVendorDto
  }): Observable<SimVendorDto> {

    return this.registerSimVendor$Response(params).pipe(
      map((r: StrictHttpResponse<SimVendorDto>) => r.body as SimVendorDto)
    );
  }

  /**
   * Path part for operation getProfilesByVendorName
   */
  static readonly GetProfilesByVendorNamePath = '/api/v1/profiles/{vendorName}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfilesByVendorName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfilesByVendorName$Response(params: {
    vendorName: string;
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, VendorControllerService.GetProfilesByVendorNamePath, 'get');
    if (params) {
      rb.path('vendorName', params.vendorName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfilesByVendorName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfilesByVendorName(params: {
    vendorName: string;
  }): Observable<Array<CommonDetails>> {

    return this.getProfilesByVendorName$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation getSimVendorsByProfileAndSolutionType
   */
  static readonly GetSimVendorsByProfileAndSolutionTypePath = '/api/v1/businessProfile/{businessProfileId}/solutiontype/{solutionTypeId}/customerVendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSimVendorsByProfileAndSolutionType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimVendorsByProfileAndSolutionType$Response(params: {
    businessProfileId: string;
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, VendorControllerService.GetSimVendorsByProfileAndSolutionTypePath, 'get');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSimVendorsByProfileAndSolutionType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSimVendorsByProfileAndSolutionType(params: {
    businessProfileId: string;
    solutionTypeId: string;
  }): Observable<Array<CommonDetails>> {

    return this.getSimVendorsByProfileAndSolutionType$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

}
