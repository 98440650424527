<!--
  ~ Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
-->
<div class="popup-open">
  <div class="close-btn"> <mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onCloseClick()"></mat-icon></div>
  <div *ngFor="let popupItem of popupOptions">
    <p class="popup-title">{{ popupItem.heading | translate }}</p>
    <div class="popup-overlay-content">{{ popupItem.content | translate }}</div>
    <div class="sub-content-container">
      <input type="checkbox" class="check-box" [(ngModel)]="checkboxValue"(change)="changeInCheckboxValue()">
      <label class="checkbox-label">{{ popupItem.sub_content.title | translate }}</label>
      <div class="sub-content">{{ popupItem.sub_content.data | translate }}
        <a routerLink="{{popupItem.sub_content.path}}" [queryParams]="{tabindex: popupItem.sub_content.index}" target="_blank">{{ popupItem.sub_content.link | translate }}</a>
      </div>
      <div>
        <button class="rb-btn rb-primary save-btn" mat-button [disabled]="!enableSubmitButton" (click)="submit()">{{ popupItem.button.save | translate }}</button>
        <div class="btn-data">{{ popupItem.button.data | translate }}
          <a routerLink="{{popupItem.button.path}}" [queryParams]="{tabindex: popupItem.button.index}" class="btn-link" target="_blank">{{ popupItem.button.link | translate }}</a>
        </div>
      </div>
    </div>
  </div>
</div>