import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ota-assignment',
  templateUrl: './ota-assignment.component.html',
  styleUrls: ['./ota-assignment.component.scss']
})
export class OtaAssignmentComponent implements OnInit {

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

}
