    <section class="overview-container">
        <section class="main">
            <ng-container>
                <mat-expansion-panel class="panel" expanded>
                    <mat-expansion-panel-header class="header-style">
                        <mat-panel-title class="heading-label">
                            <span class="heading-label">
                                <img (click)="navigateBack()" [src]="backImage" />
                                <img class="page-icon" [src]="fotaIcon" />
                                <span>{{'FOTA_JOB_DETAILS' |translate}}</span>
                            </span>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="fota-details-panel" *ngIf="fotaDetailsData">
                        <mat-card class="panel-body" *ngFor="let card of fotaDetailsData | keyvalue">
                            <h5>{{card.key | translate}}</h5>
                            <div *ngFor="let basic of card.value" class="individual-detail">
                                <header class="fota-detail-label">{{basic['label'] | translate}}</header>
                                <span>{{basic['value'] | translate}}</span>
                            </div>
                        </mat-card>
                    </div>
                </mat-expansion-panel>
                <div class="details-search">
                    <mat-form-field class="form-field" floatLabel="never">
                        <input type="text" matInput [placeholder]=" 'DEVICE_SERIAL_NUMBER' | translate" #device />
                    </mat-form-field>
                    <div (click)="onSearchClick(device.value)">
                        <img class="search-icon" [src]="searchImage" alt="search">
                    </div>
                </div>

                <div #charts id="pie-chart"></div>
                <div class="info-div">
                    <ng-container *ngFor="let data of chartDataObject['details']">
                        <div>
                            <span class="status-text-size">{{data['name'] | translate}} :</span>
                            <div class="overview-status-size" [ngStyle]="{'color': data['color']}">{{ data['count'] }}
                            </div>
                            <hr>
                        </div>
                    </ng-container>
                </div>
                <div class="clear-float"></div>
            </ng-container>
            <section class="overview-container">
                <section class="main">
                    <div class="table-container" *ngIf="jobDetailsData">
                        <mat-table [dataSource]="dataSource" class="bottom-space table-data">
                            <div *ngFor="let column of columns">
                                <ng-container *ngIf="column!=='select'" [matColumnDef]="column">
                                    <mat-header-cell *matHeaderCellDef [ngClass]="column">
                                        {{column | appendSpace | titlecase | translate}}
                                    </mat-header-cell>
                                    <mat-cell class="tooltip" *matCellDef="let dataName" [ngClass]="column">
                                        <span *ngIf="column==='log'">
                                            <img [src]="downloadImage" (click)="downloadErrorLog(dataName['deviceSerialNumber'])"
                                                class="download-icon">
                                        </span>
                                        <span *ngIf="column !== 'log'" title="{{dataName[column]}}" class="cell-value">
                                            {{dataName[column]}}
                                        </span>
                                    </mat-cell>
                                </ng-container>
                            </div>
                            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                            <mat-row *matRowDef="let data; columns: columns;"></mat-row>
                        </mat-table>
                    </div>
                    <mat-paginator *ngIf="dataSource" [pageSizeOptions]="pageSizeOptions" [length]="totalDeviceCount"
                        [pageSize]="pageSize" showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
                </section>
            </section>
        </section>
    </section>
    