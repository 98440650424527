<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <div class="heading-label">
          <img (click)="navigateBack()" class="back-btn" [src]="backImage" />
          <span>{{ 'CREATE_UPDATE_PACKAGE' | translate }}</span>
        </div>
      </div>
      <div class="form-spacing">
        <div>
          <app-dynamic-form [jsonFile]="'ota-create-package'" [dropDownValues]="dropDownValues"
            [gridLayout]="'grid-container-columns'" (valueSelected)="updateDropdownValues($event)"
            (formEmitter)="onSubmit($event)">
          </app-dynamic-form>
        </div>
      </div>
    </section>
</section>