import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SchedulerRoutingModule } from './scheduler-routing.module';
import { CreateSchedulerComponent } from './create-scheduler/create-scheduler.component';
import { ManageSchedulerComponent } from './manage-scheduler/manage-scheduler.component';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AppendSpacePipe } from '../shared/pipes/title-case-pipe';
import { SharedModule } from '../shared/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
@NgModule({
  declarations: [CreateSchedulerComponent, ManageSchedulerComponent],
  imports: [
    CommonModule,
    MatPaginatorModule,
    SchedulerRoutingModule,
    MatTableModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    MatDatepickerModule,
    SharedModule,
    FormsModule,
    MatSlideToggleModule,
    MatMenuModule
  ]
})
export class SchedulerModule { }
