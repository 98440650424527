/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ItemDto } from '../models/item-dto';
import { JobStatusDto } from '../models/job-status-dto';
import { Scheduler } from '../models/scheduler';
import { SchedulerDto } from '../models/scheduler-dto';
import { SchedulerSearchDto } from '../models/scheduler-search-dto';

@Injectable({
  providedIn: 'root',
})
export class SchedulerControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation calllog1
   */
  static readonly Calllog1Path = '/api/v1/schedulers/{schedulerId}/calllog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calllog1()` instead.
   *
   * This method doesn't expect any request body.
   */
  calllog1$Response(params: {
    schedulerId: string;

  }): Observable<StrictHttpResponse<Array<JobStatusDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.Calllog1Path, 'get');
    if (params) {

      rb.path('schedulerId', params.schedulerId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<JobStatusDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `calllog1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calllog1(params: {
    schedulerId: string;

  }): Observable<Array<JobStatusDto>> {

    return this.calllog1$Response(params).pipe(
      map((r: StrictHttpResponse<Array<JobStatusDto>>) => r.body as Array<JobStatusDto>)
    );
  }

  /**
   * Path part for operation schedules
   */
  static readonly SchedulesPath = '/api/v1/schedulers/{schedulerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `schedules()` instead.
   *
   * This method doesn't expect any request body.
   */
  schedules$Response(params: {
    schedulerId: string;

  }): Observable<StrictHttpResponse<SchedulerDto>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.SchedulesPath, 'get');
    if (params) {

      rb.path('schedulerId', params.schedulerId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SchedulerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `schedules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  schedules(params: {
    schedulerId: string;

  }): Observable<SchedulerDto> {

    return this.schedules$Response(params).pipe(
      map((r: StrictHttpResponse<SchedulerDto>) => r.body as SchedulerDto)
    );
  }

  /**
   * Path part for operation updateScheduler
   */
  static readonly UpdateSchedulerPath = '/api/v1/schedulers/{schedulerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateScheduler()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScheduler$Response(params: {
    schedulerId: string;
      body?: SchedulerDto
  }): Observable<StrictHttpResponse<SchedulerDto>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.UpdateSchedulerPath, 'put');
    if (params) {

      rb.path('schedulerId', params.schedulerId, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SchedulerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateScheduler$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateScheduler(params: {
    schedulerId: string;
      body?: SchedulerDto
  }): Observable<SchedulerDto> {

    return this.updateScheduler$Response(params).pipe(
      map((r: StrictHttpResponse<SchedulerDto>) => r.body as SchedulerDto)
    );
  }

  /**
   * Path part for operation deleteScheduler
   */
  static readonly DeleteSchedulerPath = '/api/v1/schedulers/{schedulerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteScheduler()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScheduler$Response(params: {
    schedulerId: string;

  }): Observable<StrictHttpResponse<Scheduler>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.DeleteSchedulerPath, 'delete');
    if (params) {

      rb.path('schedulerId', params.schedulerId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Scheduler>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteScheduler$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteScheduler(params: {
    schedulerId: string;

  }): Observable<Scheduler> {

    return this.deleteScheduler$Response(params).pipe(
      map((r: StrictHttpResponse<Scheduler>) => r.body as Scheduler)
    );
  }

  /**
   * Path part for operation fetchAllSchedules
   */
  static readonly FetchAllSchedulesPath = '/api/v1/searchSchedulers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchAllSchedules()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchAllSchedules$Response(params?: {
    page?: number;
    limit?: number;
      body?: SchedulerSearchDto
  }): Observable<StrictHttpResponse<Array<SchedulerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.FetchAllSchedulesPath, 'post');
    if (params) {

      rb.query('page', params.page, {});
      rb.query('limit', params.limit, {});

      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SchedulerDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchAllSchedules$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fetchAllSchedules(params?: {
    page?: number;
    limit?: number;
      body?: SchedulerSearchDto
  }): Observable<Array<SchedulerDto>> {

    return this.fetchAllSchedules$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SchedulerDto>>) => r.body as Array<SchedulerDto>)
    );
  }

  /**
   * Path part for operation schedulersCount
   */
  static readonly SchedulersCountPath = '/api/v1/schedulersCount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `schedulersCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  schedulersCount$Response(params?: {

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.SchedulersCountPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `schedulersCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  schedulersCount(params?: {

  }): Observable<string> {

    return this.schedulersCount$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation findSchedulerTypes
   */
  static readonly FindSchedulerTypesPath = '/api/v1/schedulerTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findSchedulerTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findSchedulerTypes$Response(params?: {

  }): Observable<StrictHttpResponse<Array<ItemDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.FindSchedulerTypesPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ItemDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findSchedulerTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findSchedulerTypes(params?: {

  }): Observable<Array<ItemDto>> {

    return this.findSchedulerTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ItemDto>>) => r.body as Array<ItemDto>)
    );
  }

  /**
   * Path part for operation activateScheduler
   */
  static readonly ActivateSchedulerPath = '/api/v1/schedulers/{schedulerId}/activate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateScheduler()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateScheduler$Response(params: {
    schedulerId: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.ActivateSchedulerPath, 'patch');
    if (params) {

      rb.path('schedulerId', params.schedulerId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateScheduler$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateScheduler(params: {
    schedulerId: string;

  }): Observable<string> {

    return this.activateScheduler$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation deactivateScheduler
   */
  static readonly DeactivateSchedulerPath = '/api/v1/schedulers/{schedulerId}/deactivate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateScheduler()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateScheduler$Response(params: {
    schedulerId: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.DeactivateSchedulerPath, 'patch');
    if (params) {

      rb.path('schedulerId', params.schedulerId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateScheduler$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateScheduler(params: {
    schedulerId: string;

  }): Observable<string> {

    return this.deactivateScheduler$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation saveScheduler
   */
  static readonly SaveSchedulerPath = '/api/v1/schedulers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveScheduler()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveScheduler$Response(params?: {
      body?: SchedulerDto
  }): Observable<StrictHttpResponse<SchedulerDto>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.SaveSchedulerPath, 'post');
    if (params) {


      rb.body(params.body, 'application/json');
    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SchedulerDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveScheduler$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveScheduler(params?: {
      body?: SchedulerDto
  }): Observable<SchedulerDto> {

    return this.saveScheduler$Response(params).pipe(
      map((r: StrictHttpResponse<SchedulerDto>) => r.body as SchedulerDto)
    );
  }

  /**
   * Path part for operation execute
   */
  static readonly ExecutePath = '/api/v1/schedulers/{schedulerId}/execute';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `execute()` instead.
   *
   * This method doesn't expect any request body.
   */
  execute$Response(params: {
    schedulerId: string;

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.ExecutePath, 'get');
    if (params) {

      rb.path('schedulerId', params.schedulerId, {});

    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `execute$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  execute(params: {
    schedulerId: string;

  }): Observable<string> {

    return this.execute$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation test
   */
  static readonly TestPath = '/api/v1/health';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `test()` instead.
   *
   * This method doesn't expect any request body.
   */
  test$Response(params?: {

  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, SchedulerControllerService.TestPath, 'get');
    if (params) {


    }
    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `test$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  test(params?: {

  }): Observable<string> {

    return this.test$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
