<section class="overview-container" *ngIf="deviceProperties">
    <section class="main">
        <div class="header-style">
            <span class="heading-label">
                <img class="page-icon" [src]="deviceIcon" />
                <span>{{'DEVICE_CONFIGURATIONS' |translate}}</span>
            </span>
        </div>
        <div class="create-form-spacing">
            <app-dynamic-form-device [deviceId]="deviceId" [deviceProperties]="deviceProperties"
                (formEmitter)="configureDevice($event)" [columnsInForm]="'single'"></app-dynamic-form-device>
        </div>
    </section>
</section>
