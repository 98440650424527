import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BciLoaderService } from '@bci-web-core/core';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { BillingInvoiceControllerService } from 'src/app/services/Billing/services';
import { AccountControllerService, BusinessProfileControllerService, TenantControllerService } from 'src/app/services/Platform/services';
import { DOWNLOAD_ICON, GENERATE_IMAGE, OPERATION_CONFIGURATION_ICON } from 'src/app/shared/constants/strings';

@Component({
  selector: 'app-billing-data',
  templateUrl: './billing-data.component.html',
  styleUrls: ['./billing-data.component.scss']
})
export class BillingDataComponent implements OnInit {
  public dropDownValues = [];
  public showSpinner :any;
  public columns: any[];
  public ignoreList = ['profileId', 'proposalId', 'year'];
  public generateImage;
  public billingDataDropdownList;
  public profileId;
  public billingData;
  public yearSelected;
  public monthSelected = null;
  public billingIcon: string;
  public billingDataSource;
  public downloadIcon: string;

  constructor(private accountControllerService: AccountControllerService,
    private tenantService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private responseHandlerService: ResponseHandlerService, private billingInvoiceControllerService: BillingInvoiceControllerService,
    private httpService: HttpClient,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.getBillingDataDropdowns('./assets/form-fields/billing-data.json');
    this.generateImage = GENERATE_IMAGE;
    this.billingIcon = OPERATION_CONFIGURATION_ICON;
    this.downloadIcon = DOWNLOAD_ICON;
    this.getYearDropdown();
    this.dropDownValues['month'] = [
      { name: 'JAN', id: 1 },
      { name: 'FEB', id: 2 },
      { name: 'MAR', id: 3 },
      { name: 'APR', id: 4 },
      { name: 'MAY', id: 5 },
      { name: 'JUNE', id: 6 },
      { name: 'JULY', id: 7 },
      { name: 'AUG', id: 8 },
      { name: 'SEPT', id: 9 },
      { name: 'OCT', id: 10 },
      { name: 'NOV', id: 11 },
      { name: 'DEC', id: 12 }];
  }

  getBillingDataDropdowns(filePath) {
    this.httpService
      .get(filePath).subscribe(data => {
        this.billingDataDropdownList = data as string[];
        this.billingDataDropdownList = this.billingDataDropdownList['fields'];
      });
    this.getAccount();
  }

  public getAccount() {
    this.accountControllerService
      .findAllAccounts({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.dropDownValues['account'] = resp;
          } else {
            this.dropDownValues['account'] = [];
          }
        },
        err => {
          this.dropDownValues['account'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getTenant(accountId: string) {
    this.tenantService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        err => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        err => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  public updateDropdownValues($event, dropdownName, childDropdown?) {
    if ($event.value !== undefined) {
      if (childDropdown) {
        if (childDropdown === 'tenant') {
          this.getTenant($event.value);
        }
        if (childDropdown === 'businessProfile') {
          this.getProfile($event.value);
        }
      }
      if (dropdownName === 'businessProfile') {
        this.profileId = $event.value;
      }
      if (dropdownName === 'year') {
        this.yearSelected = $event.value;
      }
      if (dropdownName === 'month') {
        this.monthSelected = $event.value;
      }
    }
  }


  // function to be completed
  public generateBillingInvoice() {
    this.showSpinner = this.loaderService.showProgressBar();
    const params = { profileid: this.profileId, year: this.yearSelected, month: this.monthSelected };
    this.billingInvoiceControllerService.billingDetails(params).subscribe(resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.billingData = resp;
        this.billingDataSource = new MatTableDataSource<any>(this.billingData);
        this.columns = this.filterColumns(Object.keys(this.billingData[0]));
      } else {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.billingData = null;
        this.columns = null;
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
      }
    }, err => {
      this.billingData = null;
      this.columns = null;
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getYearDropdown() {
    this.dropDownValues['year'] = [];
    let previousYear = 2000;
    const currentYear = new Date().getFullYear();
    while (currentYear >= previousYear) {
      this.dropDownValues['year'].push({ name: previousYear, id: previousYear });
      previousYear++;
    }
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }
}
