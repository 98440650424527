/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DesiredPropertyDto } from '../models/desired-property-dto';
import { MethodResultDto } from '../models/method-result-dto';
import { Property } from '../models/property';

@Injectable({
  providedIn: 'root',
})
export class DeviceTwinControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDeviceTwin
   */
  static readonly GetDeviceTwinPath = '/api/v1/device/{deviceId}/getTwinProperties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDeviceTwin()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceTwin$Response(params: {
    deviceId: string;
  }): Observable<StrictHttpResponse<Property>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceTwinControllerService.GetDeviceTwinPath, 'get');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Property>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDeviceTwin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDeviceTwin(params: {
    deviceId: string;
  }): Observable<Property> {

    return this.getDeviceTwin$Response(params).pipe(
      map((r: StrictHttpResponse<Property>) => r.body as Property)
    );
  }

  /**
   * Path part for operation updateDesiredProperty
   */
  static readonly UpdateDesiredPropertyPath = '/api/v1/device/{deviceId}/updateDesiredProperty';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDesiredProperty()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDesiredProperty$Response(params: {
    deviceId: string;
    body?: DesiredPropertyDto
  }): Observable<StrictHttpResponse<MethodResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceTwinControllerService.UpdateDesiredPropertyPath, 'patch');
    if (params) {
      rb.path('deviceId', params.deviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MethodResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDesiredProperty$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDesiredProperty(params: {
    deviceId: string;
    body?: DesiredPropertyDto
  }): Observable<MethodResultDto> {

    return this.updateDesiredProperty$Response(params).pipe(
      map((r: StrictHttpResponse<MethodResultDto>) => r.body as MethodResultDto)
    );
  }

}
