import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { BciLoaderService } from '@bci-web-core/core';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { SettingCommonDto } from 'src/app/services/Platform/models';
import { MapControllerService } from 'src/app/services/Platform/services';
import { ACCESS_KEY_ICON, BACK_LEFT, VIEW_ICON } from 'src/app/shared/constants/strings';

@Component({
  selector: 'app-map-projects-list',
  templateUrl: './map-projects-list.component.html',
  styleUrls: ['./map-projects-list.component.scss']
})
export class MapProjectsListComponent implements OnInit {
  public backLeftImage: string;
  public accessKeyIcon;
  public inputData;
  public showSpinner :any;
  public mapKeysData: any[];
  public columns: any[];
  public dataSource;
  public ignoreList = ['id', 'mapKey','mapProjectApiKeys', 'projectId', 'available','active',
'account','tenant','profile','solutionType'];
  public viewImage: string;
  businessProfileId: string;
  solutionTypeId: string;


  constructor(private router:Router, private route: ActivatedRoute,private loaderService: BciLoaderService,
    private mapControllerService: MapControllerService, 
    private responseHandlerService: ResponseHandlerService,) { }

  ngOnInit(): void {
    this.inputData = { businessProfileId: this.route.snapshot.paramMap.get('businessProfileId'),
    solutionTypeId: this.route.snapshot.paramMap.get('solutionTypeId')};
    this.businessProfileId = this.inputData['businessProfileId'],
    this.solutionTypeId = this.inputData['solutionTypeId'],
    this.backLeftImage = BACK_LEFT;
    this.accessKeyIcon = ACCESS_KEY_ICON;
    this.viewImage = VIEW_ICON;
      this.getMapKeys();
    
  }

  public navigateBack() {
    this.router.navigate(['access-key/tenant-key']);
  }
  
  public getMapKeys() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.mapControllerService.getMapProjectsforProfileAndSolutionType({
      profileId: this.inputData['businessProfileId'],
      solutionTypeId: this.inputData['solutionTypeId'],
      active:true,
    }).subscribe(resp => {
      if (resp && resp['length']) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.mapKeysData = resp;
        for (const data of this.mapKeysData) {
          data['projectId'] = data['project']['id'];
          data['active'] = data['project']['active'];
          data['project'] = data['project']['name'];
          data['noOfKeys'] = data['mapProjectApiKeys'] ? data['mapProjectApiKeys'].length : 0;
          data['action'] = null;
        }
        this.dataSource = new MatTableDataSource<SettingCommonDto>(this.mapKeysData);
        this.columns = this.filterColumns(Object.keys(this.mapKeysData[0]));
      } else {
       
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
         this.navigateBack();
      }
    },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  public determineColumnSize(columnName) {
    if (columnName === 'apiKey') {
      return 'column-large';
    } else {
      return 'column-small';
    }
  }

  public gotoListMapKeysPage(data) {
    this.router.navigate(['access-key/list-map-keys', { businessProfileId: this.businessProfileId,
      solutionTypeId: this.solutionTypeId ,projectId:data['projectId']}]);
  }

}
