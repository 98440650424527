/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetailsDto } from '../models/common-details-dto';
import { ProfileVendorMappingDto } from '../models/profile-vendor-mapping-dto';

@Injectable({
  providedIn: 'root',
})
export class WhatsappProfileMappingControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getProfileVendorMappingList
   */
  static readonly GetProfileVendorMappingListPath = '/api/v1/profile/bsp';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProfileVendorMappingList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileVendorMappingList$Response(params?: {
    active?: boolean;
  }): Observable<StrictHttpResponse<Array<ProfileVendorMappingDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileMappingControllerService.GetProfileVendorMappingListPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileVendorMappingDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProfileVendorMappingList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProfileVendorMappingList(params?: {
    active?: boolean;
  }): Observable<Array<ProfileVendorMappingDto>> {

    return this.getProfileVendorMappingList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileVendorMappingDto>>) => r.body as Array<ProfileVendorMappingDto>)
    );
  }

  /**
   * Path part for operation getAllBspMappingByProfileId
   */
  static readonly GetAllBspMappingByProfileIdPath = '/api/v1/profile/{profileId}/vendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllBspMappingByProfileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBspMappingByProfileId$Response(params: {
    profileId: string;
  }): Observable<StrictHttpResponse<ProfileVendorMappingDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileMappingControllerService.GetAllBspMappingByProfileIdPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileVendorMappingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllBspMappingByProfileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllBspMappingByProfileId(params: {
    profileId: string;
  }): Observable<ProfileVendorMappingDto> {

    return this.getAllBspMappingByProfileId$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileVendorMappingDto>) => r.body as ProfileVendorMappingDto)
    );
  }

  /**
   * Path part for operation saveMapDetails
   */
  static readonly SaveMapDetailsPath = '/api/v1/profile/{profileId}/vendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveMapDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveMapDetails$Response(params: {
    profileId: string;
    vendorId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileMappingControllerService.SaveMapDetailsPath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('vendorId', params.vendorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveMapDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  saveMapDetails(params: {
    profileId: string;
    vendorId: string;
  }): Observable<string> {

    return this.saveMapDetails$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateMappingDetails
   */
  static readonly UpdateMappingDetailsPath = '/api/v1/profile/{profileId}/vendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMappingDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMappingDetails$Response(params: {
    profileId: string;
    vendorId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileMappingControllerService.UpdateMappingDetailsPath, 'patch');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('vendorId', params.vendorId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateMappingDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMappingDetails(params: {
    profileId: string;
    vendorId: string;
  }): Observable<string> {

    return this.updateMappingDetails$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getVendorList
   */
  static readonly GetVendorListPath = '/api/v1/bspList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVendorList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVendorList$Response(params?: {
    active?: boolean;
  }): Observable<StrictHttpResponse<Array<CommonDetailsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileMappingControllerService.GetVendorListPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetailsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getVendorList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVendorList(params?: {
    active?: boolean;
  }): Observable<Array<CommonDetailsDto>> {

    return this.getVendorList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetailsDto>>) => r.body as Array<CommonDetailsDto>)
    );
  }

}
