/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { BusinessProfileControllerService, AccountControllerService, TenantControllerService,
  PlatformAdminUtilityControllerService } from 'src/app/services/Platform/services';
import {
  DeviceEnrollmentControllerService, DeviceTypeControllerService, DeviceMgmtUtilityControllerService, TagControllerService
} from 'src/app/services/DeviceMgmt/services';
import { Router } from '@angular/router';
import { CREATE, DEVICE_ICON, DELETE_IMAGE, DOCUMENT_IMAGE, CSV_FILE, GOOGLE_CSV_FORMAT} from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { EnvService } from 'src/app/env.service';
import { TagsServiceProvider } from 'src/app/providers/tags-service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs/internal/Observable';
import { startWith, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BciLoaderService } from '@bci-web-core/core';
/**
  * @ngdoc component
  * @name device.component:createGroup
  *
  *
  * @description
  * description: To register a new device(Group enrollment).
  *
  *
*/
@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss','../common-device-styles.scss']
})
export class CreateGroupComponent implements OnInit {
  public showSpinner :any;
  public pageKey: string;
  public dropDownValues: any = {};
  public deviceIcon: string;
  asideMenus: Array <AsideCard>;
  public profileId: string;
  public provider: any ;
  public enrollmentType: string;
  public displayInfoMsg = false;
  groupRegistrationForm: UntypedFormGroup;
  public files: any = [];
  public excelFiles: any = [];
  submitted = false;
  deleteImage = DELETE_IMAGE;
  documentImage = DOCUMENT_IMAGE;
  public sampleFile: any;
  public displayCertInput = false;
  public displayFileInput = false;
  public enrollmentWithCert = false;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  stringArray: any = [];
  selectedValuesArray: any = [];
  filteredStrings: Observable<string[]>;
  tags = new UntypedFormControl();
  public env: any;
  public solutionTypeId: string ;
  @ViewChild('inputCtrl', { static: true }) inputCtrl: ElementRef<HTMLInputElement>;
  constructor(private responseHandlerService: ResponseHandlerService, private tenantControllerService: TenantControllerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private deviceEnrollmentControllerService: DeviceEnrollmentControllerService,
    private accountControllerService: AccountControllerService,
    private deviceTypeControllerService: DeviceTypeControllerService,
    private deviceMgmtUtilityControllerService: DeviceMgmtUtilityControllerService, private router: Router,
    private rightsideNavitemsService: RightsideNavitemsService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private tagControllerService: TagControllerService, private envService: EnvService,
    private tagService: TagsServiceProvider, private formBuilder: UntypedFormBuilder, private loaderService:  BciLoaderService) {
      this.filteredStrings = this.tags.valueChanges.pipe(
        startWith(null),
        map((value: string | null) => value ? this.filterStringsList(value) : this.stringArray.slice()));
     }

  ngOnInit() {
    this.env = this.envService;
    this.getAccount();
    this.getUploadType();
    this.pageKey = 'group';
    this.rightsideNavitemsService.getRightsideNavItems(CREATE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.deviceIcon = DEVICE_ICON;
    this.groupRegistrationForm = this.formBuilder.group({
      groupName: ['', [Validators.required, Validators.maxLength(50)]],
      account: ['', Validators.required],
      tenant: ['', Validators.required],
      businessProfile: ['', Validators.required],
      solutionType: ['', Validators.required],
      provider: ['', Validators.required],
      iotHub: ['', Validators.required],
      deviceType: ['', Validators.required],
      attestationType: ['', Validators.required],
      enrollmentWithCa: [false, Validators.required],
      category: [''],
      excelFile: ['', Validators.required],
      criteria: [''],
      signerCert: [''],
      base64Certificate: ['', Validators.required],
      tags: ['']
    });
  }

  /**
    * @ngdoc method
    * @name createGroup#getAccount
    *
    * @methodOf
    * device.controller:createGroup
    *
    * @description
    * Description: To populate account dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getAccount() {
    this.accountControllerService
      .findAllAccounts({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.dropDownValues['account'] = resp;
          } else {
            this.dropDownValues['account'] = [];
          }
        },
        err => {
          this.dropDownValues['account'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createGroup#getTenant
    *
    * @methodOf
    * device.controller:createGroup
    *
    * @description
    * Description: To populate tenant dropdown.
    *
    * @param {type} accountId
    * @return {type} null
  */
  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        err => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createGroup#updateDropdownValues
    *
    * @methodOf
    * device.controller:createGroup
    *
    * @description
    * Description: To populate dependent dropdown.
    *
    * @param {type} dropdown select event
    * @return {type} null
  */
  public updateDropdownValues($event, childField) {
      if ($event.value !== undefined) {
        if (!childField && $event.value === 'FILE') {
          this.displayFileInput = true;
          this.displayCertInput = false;
          this.groupRegistrationForm.get('base64Certificate').setValidators(Validators.required);
          this.groupRegistrationForm.get('base64Certificate').updateValueAndValidity();
          this.groupRegistrationForm.get('signerCert').setValue('');
          this.groupRegistrationForm.get('signerCert').clearValidators();
          this.groupRegistrationForm.get('signerCert').updateValueAndValidity();

        } else if (!childField && $event.value === 'TEXTAREA') {
          this.displayFileInput = false;
          this.displayCertInput = true;
          this.files = [];
          this.groupRegistrationForm.get('signerCert').setValidators(Validators.required);
          this.groupRegistrationForm.get('base64Certificate').clearValidators();
          this.groupRegistrationForm.get('base64Certificate').updateValueAndValidity();
        }
      if (childField && childField === 'tenant') {
        this.getTenant($event.value);
      }
      if (childField && childField === 'businessProfile') {
        this.getProfile($event.value);
      }
      if (childField && childField === 'solutionType') {
        this.profileId = $event.value;
        this.getSolutionType($event.value);
      }
      if (childField && childField === 'provider') {
        this.solutionTypeId = $event.value;
        this.getDeviceInterFaceProvider($event.value, this.profileId);
        this.getAvailableTags($event.value);
      }
      if (childField && childField === 'iotHub') {
        this.provider = this.dropDownValues['provider'].find(dropDownOption => dropDownOption.id === $event.value);
        this.getIotHubList(this.solutionTypeId, $event.value, this.profileId);
        this.providerBasedManipulations(this.provider);
        }
      if (childField && childField === 'deviceType') {
        this.getDeviceType(this.solutionTypeId, this.provider['id']);
      }
      if (childField && childField === 'attestationType') {
        if (this.provider['name'] === this.env['providerBosch']) {
          this.enrollmentType = this.env['providerBosch'];
        } else if (this.provider['name'] === this.env['providerAws']) {
          this.enrollmentType = this.env['providerAws'];
        } else if (this.provider.name === this.env['providerZelIot']) {
          this.enrollmentType = this.env['providerZelIot'];
        } else {
          this.enrollmentType = 'groupEnrollment';
        }
        this.getAttestationType(this.enrollmentType);
      }
      if (childField && childField === 'category' && this.provider['name'] === this.env['providerBosch']) {
        this.getDeviceCategories();
      }
      if (childField && childField === 'sampleFile') {
        this.excelFiles = [];
        this.getCSVFile($event.value);
      }
    }
  }
/**
    * @ngdoc method
    * @name createDevice#disableInfoMessage
    *
    * @methodOf
    * device.controller:createGroup
    *
    * @description
    * Description: To remove didplay info message from UI when form is reset
    *
    * @param {type} $event)
    * @return {type} null
  */
public disableInfoMessage($event) {
    if ($event) {
      this.displayInfoMsg = false;
    }
}
  /**
    * @ngdoc method
    * @name createGroup#getProfile
    *
    * @methodOf
    * device.controller:createGroup
    *
    * @description
    * Description: To populate business profiles dropdown.
    *
    * @param {type} tenantId
    * @return {type} null
  */
  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        err => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createGroup#getSolutionType
    *
    * @methodOf
    * device.controller:createGroup
    *
    * @description
    * Description: To populate solutype type dropdown.
    *
    * @param {type} profileId
    * @return {type} null
  */
  public getSolutionType(profileId: string) {
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({ businessProfileId: profileId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createGroup#getDeviceType
    *
    * @methodOf
    * device.controller:createGroup
    *
    * @description
    * Description: To populate device type dropdown.
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
   public getDeviceType(solutionTypeId, deviceInterfaceProviderId ) {
    this.deviceTypeControllerService
      .findAllDeviceTypesBasedOnSolutionType({ id: solutionTypeId, deviceInterfaceProviderId: deviceInterfaceProviderId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['deviceType'] = resp;
          } else {
            this.dropDownValues['deviceType'] = [];
          }
        },
        err => {
          this.dropDownValues['deviceType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createGroup#getAttestationType
    *
    * @methodOf
    * device.controller:createGroup
    *
    * @description
    * Description: To populate attestation types dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getAttestationType(enrollmentType) {
    this.deviceMgmtUtilityControllerService
      .getAllAttestationTypes({ enrollmentType: enrollmentType})
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['attestationType'] = resp;
          } else {
            this.dropDownValues['attestationType'] = [];
          }
        },
        err => {
          this.dropDownValues['attestationType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name createGroup#createGroup
    *
    * @methodOf
    * device.controller:createGroup
    *
    * @description
    * Description: To register a new device.
    *
    * @param {type} data
    * @return {type} null
  */
  public createGroup() {
    this.submitted = true;
    if (this.groupRegistrationForm.valid) {
    const data = this.groupRegistrationForm.value;
    let grpData;
    const tags = [];
    for (const tag of this.selectedValuesArray) {
      const tagEntity = {};
      tagEntity['name'] = tag;
      tags.push(tagEntity);
    }
    const groupData = {
      groupName: data['groupName'],
      signerCert: data['signerCert'],
      isEnrollmentWithCa: data['enrollmentWithCa'],
      deviceType: {
        id: data['deviceType']
      },
      attestationType: {
        id: data['attestationType']
      },
      account: {
        id: data['account']
      },
      tenant: {
        id: data['tenant']
      },
      businessProfile: {
        id: data['businessProfile']
      },
      solutionType: {
        id: data['solutionType']
      },
      provider: {
        id: data['provider']
      },
      iotHub: {
        id: data['iotHub']
      },
      category: {
        id: data['category']
      },
      tags: tags,
      base64Certificate: data['base64Certificate']
    };
    const file = data['file'];
    if (this.provider['name'] !== this.env['providerAzure'] || data['excelFile']) {
      data.file = data['excelFile'];
    }
    const reqObj = {
       body: {
         metaData: JSON.stringify(groupData),
         file: data.file
        }
      };
    grpData = reqObj;
    this.showSpinner = this.loaderService.showProgressBar();
      this.deviceEnrollmentControllerService
      .createGroupEnrollment(grpData)
      .subscribe(
        resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'GROUP_CREATE_SUCCESS');
         this.router.navigate(['devices/manage-groups']);
        },
        err => {
       this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
    this.submitted = false;
    }
  }

/**
    * @ngdoc method
    * @name createGroup#getDeviceInterFaceProvider
    *
    * @methodOf
    * device.controller:getDeviceInterFaceProvider
    *
    * @description
    * Description: Fetches device interface provider associated with selected solutionType and businessProfile
    *
    * @param {type} solutionTypeId,profileId
    * @return {type} null
  */
 public getDeviceInterFaceProvider(solutionTypeId: string, profileId: string) {
  this.platformAdminUtilityControllerService.deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: profileId, active: 'true' })
   .subscribe(
     resp => {
       if (resp && resp.length) {
        this.dropDownValues['provider'] = resp;
      } else {
         this.dropDownValues['provider'] = [];
       }
     },
     err => {
       this.dropDownValues['provider'] = [];
       this.responseHandlerService.returnToastMessage('error', err.error.message);
     }
   );
  }

  /**
    * @ngdoc method
    * @name createGroup#getDeviceCategories
    *
    * @methodOf
    * device.controller:getDeviceCategories
    *
    * @description
    * Description: Fetches a list of categories of devices when provider is 'BOSCH-IO'
    *
    * @param {type} null
    * @return {type} null
  */
  getDeviceCategories() {
    this.deviceEnrollmentControllerService.getDeviceCetegories({active: 'true'})
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['category'] = resp;
        } else {
          this.dropDownValues['category'] = [];
        }
      },
      err => {
        this.dropDownValues['category'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
   }

/**
    * @ngdoc method
    * @name createGroup#getCSVFile
    *
    * @methodOf
    * device.controller:CreateGroup
    *
    * @description
    * Description: Fetches sample csv file link for download, based on chosen device category
    *
    * @param {string} categoryId
    * @param {string} deviceIntfProviderId
    * @return {type} null
  */
public getCSVFile(categoryId?: string, deviceIntfProviderId?: string) {
this.deviceEnrollmentControllerService.getdownloadCsv({ categoryId: categoryId, deviceIntfProviderId: deviceIntfProviderId })
  .subscribe(
    resp => {
      if (resp) {
        this.sampleFile = resp.name;
      }
    },
    err => {
      this.sampleFile = '';
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    }
  );
 }

 /**
  * @ngdoc method
  * @name CreateGroup#getAvailableTags
  *
  * @methodOf
  * firmware.controller:CreateGroup
  *
  * @description
  * Description: Fetch the list of available static tags
  * @param {type} solutionTypeId
  * @return {type} null
  */
public getAvailableTags(solutionTypeId: string) {
  this.dropDownValues['tags'] = null;
  this.stringArray = [];
  this.tagControllerService.findAllTagsBySolutionTypeId({ solutionTypeId: solutionTypeId })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['tags'] = resp;
            resp.forEach(tag => {
              if (tag) {
                this.stringArray.push(tag.name);
            }
            });
        } else {
          this.dropDownValues['tags'] = [];
        }
      },
      err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}

/**
  * @ngdoc method
  * @name CreateGroup#getAvailableTags
  *
  * @methodOf
  * firmware.controller:createGroup
  *
  * @description
  * Description: Will call the tags common service to perform operations like adding and removing the tags from ui
  * @param {type} $event
  * @return {type} null
  */
addOrRemoveTags($event, action) {
  if ($event.value && action === 'addSelectedAttribute') {
    if (this.selectedValuesArray.length) {
      if (this.selectedValuesArray.includes($event.value)) {
        this.responseHandlerService.returnToastMessage('warning', 'DUPLICATE_TAG');
        this.inputCtrl.nativeElement.value = '';
      } else {
        this.selectedValuesArray.push($event.value);
        this.inputCtrl.nativeElement.value = '';
      }
    } else {
          this.selectedValuesArray.push($event.value);
          this.inputCtrl.nativeElement.value = '';
        }
    } else if ($event && action === 'removeSelectedAttribute') {
      const index = this.selectedValuesArray.indexOf($event);
      if (this.selectedValuesArray && this.selectedValuesArray.length && index >= 0) {
          this.selectedValuesArray.splice(index, 1);
      }
    }
}

/**
     * @ngdoc method
     * @name CreateGroup#getUploadType
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description: Assigns values to upload type dropdown list
     *
     * @param {type} null
     * @return {type} null
    */
public getUploadType() {
  this.dropDownValues['criteria'] = [
    {
      'id': 'FILE',
      'name': 'FILE',
      'value': 'FILE'
    },
    {
      'id': 'TEXTAREA',
      'name': 'TEXTAREA',
      'value': 'TEXTAREA',
    }
  ];
}
/**
     * @ngdoc method
     * @name CreateGroup#getUploadType
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description: Convert the selected file content to base64
     *
     * @param {type} event
     * @return {type} null
    */
public convertTobase64(event) {
  if (event) {
    for (const file of event.target.files) {
      if (this.files.length > 0) {
         this.files = [];
         this.files.push(file);
      } else {
        this.files.push(file);
      }
    }
    const fileReader: FileReader = new FileReader();
    let text;
    if (event.target.files && event.target.files.length > 0) {
      fileReader.onloadend = (e) => {
        text = fileReader.result;
        this.groupRegistrationForm.get('base64Certificate').setValue(btoa(text));
      };
      fileReader.readAsText(new Blob([event.target.files[0]]));
    }
  }
}

/**
     * @ngdoc method
     * @name CreateGroup#removeFile
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description: Removes files from file array
     *
     * @param index (number)
     * @param fileType (string)
     * @param deletionType (string)
    */
removeFile(index: number, fileType, deletionType) {
  if (deletionType === 'cacert') {
    if ((fileType !== GOOGLE_CSV_FORMAT || fileType !==  CSV_FILE) && this.groupRegistrationForm.value.base64Certificate) {
          this.files.splice(index, 1);
          this.groupRegistrationForm.value.base64Certificate = null;
    }
  } else if (deletionType === 'excelFile') {
   if ((fileType === GOOGLE_CSV_FORMAT || fileType === CSV_FILE) && this.groupRegistrationForm.value.excelFile) {
      this.excelFiles.splice(index, 1);
      this.groupRegistrationForm.value.excelFile = null;
    }
  }
}
/**
  * @ngdoc method
  * @name CreateGroup#selectFiles
  *
  * @methodOf
  * SharedModule.controller:CreateGroup
  *
  * @description
  * Description: To calculate size of file.
  *
  * @param bytes (File size in bytes)
  * @param decimals (Decimals point)
  * @return {type} number
*/
formatBytes(bytes, decimals) {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const kiloBytes = 1024;
  const decimalValue = decimals <= 0 ? 0 : decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const index = Math.floor(Math.log(bytes) / Math.log(kiloBytes));
  return parseFloat((bytes / Math.pow(kiloBytes, index)).toFixed(decimalValue)) + ' ' + sizes[index];
}

/**
     * @ngdoc method
     * @name CreateGroup#selectFiles
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description: To add seleted excelfiles into file array
     *
     * @param {type} event
     * @return {type} null
    */
public selectFiles(event) {
  if (event.target.files && event.target.files.length > 0) {
    this.groupRegistrationForm.value.excelFile = event.target.files[0];
    this.groupRegistrationForm.get('excelFile').setValue(event.target.files[0]);
    if (this.groupRegistrationForm.value.excelFile.type === CSV_FILE ||
      this.groupRegistrationForm.value.excelFile.type === GOOGLE_CSV_FORMAT) {
      this.excelFiles.pop();
      this.excelFiles.push( event.target.files[0]);
    }
  }
}

/**
     * @ngdoc method
     * @name CreateGroup#getChkBoxValue
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description: To manage visiility of fields when provider is AWS
     *
     * @param {type} $event
     * @return {type} null
    */
public getChkBoxValue($event) {
  if ($event && $event.checked ) {
   this.enrollmentWithCert = true;
   this.groupRegistrationForm.controls['criteria'].setValidators(Validators.required);
  } else  {
    this.enrollmentWithCert = false;
    this.files = [];
    this.groupRegistrationForm.controls['criteria'].clearValidators();
    this.groupRegistrationForm.controls['base64Certificate'].clearValidators();
    this.groupRegistrationForm.controls['signerCert'].clearValidators();
  }
}

 /**
     * @ngdoc method
     * @name CreateGroup#onReset
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description: To reset group enrollment form
     *
     * @param {type} null
     * @return {type} null
    */
public onReset() {
  this.submitted = false;
  this.displayInfoMsg = false;
  this.groupRegistrationForm.reset();
  this.provider = {};
  this.enrollmentWithCert = false;
  this.displayFileInput = false;
  this.displayCertInput = false;
  this.files = [];
  this.excelFiles = [];
  this.dropDownValues = {};
  this.getAccount();
  if (this.selectedValuesArray && this.selectedValuesArray.length) {
    this.selectedValuesArray = [];
  }
}

 /**
     * @ngdoc method
     * @name CreateGroup#addValues
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description: Triggers event to add selected tag to array
     *
     * @param {type} event: MatAutocompleteSelectedEvent
     * @return {type} null
    */
   addValues(event: MatChipInputEvent): void {
     this.addOrRemoveTags(event, 'addSelectedAttribute');
  }

  /**
     * @ngdoc method
     * @name CreateGroup#remove
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description: Triggers the event for selected value to remove from list of tags
     *
     * @param {type} event: MatAutocompleteSelectedEvent
     * @return {type} null
    */
  removeEntities(event) {
    this.addOrRemoveTags(event, 'removeSelectedAttribute');
  }

  /**
     * @ngdoc method
     * @name CreateGroup#selectEntitiesFromList
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description: Triggers the event with selected value from list of tags
     *
     * @param {type} event: MatAutocompleteSelectedEvent
     * @return {type} null
    */
  selectEntitiesFromList(event: MatAutocompleteSelectedEvent): void {
    this.addOrRemoveTags(event.option, 'addSelectedAttribute');
  }

  /**
     * @ngdoc method
     * @name CreateGroup#filterList
     *
     * @methodOf
     * SharedModule.controller:CreateGroup
     *
     * @description
     * Description:Filter the tags list based on key pressed event
     *
     * @param {type} value: string
     * @return {type} null
    */
   private filterStringsList(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.stringArray.filter(tag => tag.toLowerCase().includes(filterValue));
    }

    public getIotHubList(solutionTypeId: string, deviceInterfaceProviderId: string, profileId: string ) {
      this.platformAdminUtilityControllerService.getIotHubsBySolDevIntfProvAndProfile
      ({solutionTypeId: solutionTypeId, deviceInterfaceProviderId: deviceInterfaceProviderId,
      profileId: profileId }) .subscribe(resp => {
        if (resp && resp.length) {
          this.dropDownValues['iotHub'] = resp;
        } else {
          this.dropDownValues['iotHub'] = [];
        }
      },
        err => {
          this.dropDownValues['iotHub'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
    }

  public providerBasedManipulations(selectedProvider) {
    this.displayFileInput = false;
        this.displayCertInput = false;
        this.groupRegistrationForm.controls['criteria'].reset();
        this.provider = selectedProvider;
        this.sampleFile = '';
        this.displayInfoMsg = false;
        this.groupRegistrationForm.get('enrollmentWithCa').setValue(false);
        this.groupRegistrationForm.get('category').clearValidators();
        this.groupRegistrationForm.get('category').updateValueAndValidity();
        this.groupRegistrationForm.get('criteria').clearValidators();
        this.groupRegistrationForm.get('criteria').updateValueAndValidity();
        this.groupRegistrationForm.get('excelFile').setValue('');
        this.groupRegistrationForm.get('excelFile').clearValidators();
        this.groupRegistrationForm.get('excelFile').updateValueAndValidity();
        this.groupRegistrationForm.get('base64Certificate').setValue('');
        this.groupRegistrationForm.get('base64Certificate').clearValidators();
        this.groupRegistrationForm.get('base64Certificate').updateValueAndValidity();
        this.groupRegistrationForm.get('signerCert').setValue('');
        this.groupRegistrationForm.get('signerCert').clearValidators();
        this.groupRegistrationForm.get('signerCert').updateValueAndValidity();
        if ( this.provider['name'] === this.env['providerBosch'] ) {
          this.groupRegistrationForm.get('category').setValidators(Validators.required);
          this.groupRegistrationForm.get('category').updateValueAndValidity();
          this.groupRegistrationForm.get('excelFile').setValidators(Validators.required);
          this.groupRegistrationForm.get('excelFile').updateValueAndValidity();
          this.displayInfoMsg = true;
        } else if (this.provider['name'] === this.env['providerAzure']) {
            this.groupRegistrationForm.get('criteria').setValidators(Validators.required);
            this.groupRegistrationForm.get('criteria').updateValueAndValidity();
        } else {
          if (this.provider['name'] === this.env['providerAws'] || this.provider['name'] === this.env['providerZelIot']) {
            this.groupRegistrationForm.get('excelFile').setValidators(Validators.required);
            this.groupRegistrationForm.get('excelFile').updateValueAndValidity();
          this.getCSVFile('', this.provider['id']);
          }
        }
  }
}
