import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BciLoaderService } from '@bci-web-core/core';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import {
  AccountControllerService,
  TenantControllerService,
  BusinessProfileControllerService,
} from 'src/app/services/Platform/services';
import { ProfileBotMappingDto } from 'src/app/services/Whatsapp/models/profile-bot-mapping-dto';
import { ProfileBotMappingControllerService } from 'src/app/services/Whatsapp/services/profile-bot-mapping-controller.service';
import { COPY_IMAGE, HIDE_KEY, SHOW_KEY, WHATSAPP_ICON } from 'src/app/shared/constants/strings';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';

@Component({
  selector: 'app-bot-configuration',
  templateUrl: './bot-configuration.component.html',
  styleUrls: ['./bot-configuration.component.scss'],
})
export class BotConfigurationComponent implements OnInit {
  @ViewChild(DynamicFormComponent) dynamicForm: DynamicFormComponent;
  public dropDownValues: any = {};
  public accountId: string;
  public tenantId: string;
  public profileId: string;
  public whatsappIcon: string;
  public dropdownList: [];
  public showSpinner: any;
  public formData;
  public routeAccountId: string;
  public routeTenantId: string;
  public routeProfileId: string;
  public profileIdCheck = true;
  public botDisplyName: any;
  public apiKey: string;
  public botName: string;
  public copyImage: string;
  public showKeyImage: string;
  public hideKeyImage: string;
  public showKey = false;
  public botNotMappedProfile:boolean = false;
  public dataSource;
  public columns: any[];
  ignoreList = [];
  chatBotList:any;

  constructor(
    private accountControllerService: AccountControllerService,
    private tenantControllerService: TenantControllerService,
    private responseHandlerService: ResponseHandlerService,
    private businessProfileControllerService: BusinessProfileControllerService,
    private profileBotMappingControllerService: ProfileBotMappingControllerService,
    private _matDialog: MatDialog,
    private loaderService: BciLoaderService
  ) {}

  ngOnInit() {
    this.whatsappIcon = WHATSAPP_ICON;
    this.copyImage = COPY_IMAGE;
    this.showKeyImage = SHOW_KEY;
    this.hideKeyImage = HIDE_KEY;
    this.getAccount();
  }

  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.child !== undefined) {
        if ($event.child === 'tenant') {
          this.getTenant($event.value);
          this.accountId = $event.value;
          this.profileIdCheck = true;
          this.botNotMappedProfile = false;
          this.botDisplyName = false;
        }
        if ($event.child === 'businessProfile') {
          this.getProfile($event.value);
          this.tenantId = $event.value;
          this.profileIdCheck = true;
          this.botNotMappedProfile = false;
          this.botDisplyName = false;
        }
        if ($event.child === 'chat') {
          this.profileId = $event.value;
          this.getProfileChatBot($event.value);
        }
      }
    }
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#getAccount
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To populate account dropdown.
   *
   * @param {type} null
   * @return {type} null
   */
  public getAccount() {
    this.accountControllerService.findAllAccounts({ active: 'true' }).subscribe(
      (resp) => {
        if (resp && resp['length']) {
          this.dropDownValues['account'] = resp;
        } else {
          this.dropDownValues['account'] = [];
        }
      },
      (err) => {
        this.dropDownValues['account'] = [];
        this.responseHandlerService.returnToastMessage(
          'error',
          err.error.message
        );
      }
    );
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#getTenant
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To populate account dropdown.
   *
   * @param {type} accountId
   * @return {type} null
   */
  public getTenant(accountId: string) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        (err) => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#getProfile
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To populate profile dropdown.
   *
   * @param {type} tenantId
   * @return {type} null
   */
  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        (resp) => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        (err) => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
        }
      );
  }

  /**
   * @ngdoc method
   * @name manageWhatsappTemplate#getProfileChatBot
   *
   * @methodOf
   * ManageTemplate.controller:manageWhatsappTemplate
   *
   * @description
   * Description: To fetch profile mapped Templates.
   *
   * @param {type} profileId
   * @return {type} null
   */
  public getProfileChatBot(profileId: string) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.profileBotMappingControllerService
      .getBotDetailsByProfile({ profileId: profileId })
      .subscribe(
        (resp) => {
          if (resp) {
            this.profileIdCheck = true;
            this.botDisplyName = true;
            this.botNotMappedProfile = false;
            this.chatBotList = [resp];
            this.loaderService.hideProgressBar(this.showSpinner);
            this.dataSource = new MatTableDataSource<ProfileBotMappingDto>(
              this.chatBotList
            );
            for (const data of this.chatBotList) {
              data['action'] = null;
            }
            this.columns = this.filterColumns(
              Object.keys(this.chatBotList[0])
            );
          } else {
            this.profileIdCheck = true;
            this.botDisplyName = true;
            this.loaderService.hideProgressBar(this.showSpinner);
          }
        },
        (err) => {
          if (err.error.message === 'Bot not mapped to profile') {
            this.profileIdCheck = false;
            this.botDisplyName = false;
            this.botNotMappedProfile = true;
          }
          this.loaderService.hideProgressBar(this.showSpinner);
        }
      );
  }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }


  openChatBotPopup(content) {
    this._matDialog.open(content, {
      maxHeight: '80vh',
      minWidth: '15vw',
      disableClose: true,
    });
  }

  createChatBot(data) {
    this.showSpinner = this.loaderService.showProgressBar();
    const reqObj = {
      profileId: this.profileId,
      body: {
        botName: data.botName,
        apiKey: data.apiKey,
      },
    };
    this.profileBotMappingControllerService
      .saveProfileBotMapDetails(reqObj)
      .subscribe(
        (resp) => {
          if (resp) {
            this.profileIdCheck = false;
            this.loaderService.hideProgressBar(this.showSpinner);
            this.onClose();
            this.getProfileChatBot(this.profileId);
            this.responseHandlerService.returnToastMessage(
              'success',
              'CHAT_BOT_CREATED_SUCCESSFULLY'
            );
          } else {
            this.profileIdCheck = false;
            this.loaderService.hideProgressBar(this.showSpinner);
          }
        },
        (err) => {
          this.responseHandlerService.returnToastMessage(
            'error',
            err.error.message
          );
          this.profileIdCheck = false;
          this.onClose();
          this.loaderService.hideProgressBar(this.showSpinner);
        }
      );
  }

  onClose() {
    this._matDialog.closeAll();
  }

  public copyText(id) {
    const input = document.createElement('input');
    input.value = id;
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
    this.responseHandlerService.returnToastMessage('success', 'COPIED_TO_CLIPBOARD');
  }

  public showApiKey() {
    this.showKey = !this.showKey;
  }
  
  public onReset() {
    this.dynamicForm.createForm.reset();
    this.accountId = null;
    this.tenantId = null;
    this.profileId = null;
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['account'] = [];
    this.dropDownValues['tenant'] = [];
    this.getAccount();
    this.profileIdCheck = true;
    this.botDisplyName = false;
    this.botNotMappedProfile = false;
  }
}
