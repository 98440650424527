/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ManufacturerDto } from '../models/manufacturer-dto';
import { ProtocolDto } from '../models/protocol-dto';

@Injectable({
  providedIn: 'root',
})
export class ManufacturerControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation findByManufacturerId
   */
  static readonly FindByManufacturerIdPath = '/api/v1/manufacturer/{id}';

  /**
   * GET specific Manufacturer.
   *
   * The api fetches the specific Manufacturer for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findByManufacturerId()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByManufacturerId$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<ManufacturerDto>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerControllerService.FindByManufacturerIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManufacturerDto>;
      })
    );
  }

  /**
   * GET specific Manufacturer.
   *
   * The api fetches the specific Manufacturer for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findByManufacturerId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findByManufacturerId(params: {
    id: string;
  }): Observable<ManufacturerDto> {

    return this.findByManufacturerId$Response(params).pipe(
      map((r: StrictHttpResponse<ManufacturerDto>) => r.body as ManufacturerDto)
    );
  }

  /**
   * Path part for operation updateManufacturer
   */
  static readonly UpdateManufacturerPath = '/api/v1/manufacturer/{id}';

  /**
   * Update Manufacturer.
   *
   * The api updates the specific Manufacturer for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateManufacturer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateManufacturer$Response(params: {
    id: string;
    body?: ManufacturerDto
  }): Observable<StrictHttpResponse<ManufacturerDto>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerControllerService.UpdateManufacturerPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManufacturerDto>;
      })
    );
  }

  /**
   * Update Manufacturer.
   *
   * The api updates the specific Manufacturer for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateManufacturer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateManufacturer(params: {
    id: string;
    body?: ManufacturerDto
  }): Observable<ManufacturerDto> {

    return this.updateManufacturer$Response(params).pipe(
      map((r: StrictHttpResponse<ManufacturerDto>) => r.body as ManufacturerDto)
    );
  }

  /**
   * Path part for operation findAllManufacturers
   */
  static readonly FindAllManufacturersPath = '/api/v1/manufacturer';

  /**
   * GET All Manufacturers.
   *
   * The api fetches all the Available manufacturers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllManufacturers()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllManufacturers$Response(params?: {
    active?: string;
    zeliot?: string;
  }): Observable<StrictHttpResponse<Array<ManufacturerDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerControllerService.FindAllManufacturersPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
      rb.query('zeliot', params.zeliot, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ManufacturerDto>>;
      })
    );
  }

  /**
   * GET All Manufacturers.
   *
   * The api fetches all the Available manufacturers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllManufacturers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllManufacturers(params?: {
    active?: string;
    zeliot?: string;
  }): Observable<Array<ManufacturerDto>> {

    return this.findAllManufacturers$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ManufacturerDto>>) => r.body as Array<ManufacturerDto>)
    );
  }

  /**
   * Path part for operation createManufacturer
   */
  static readonly CreateManufacturerPath = '/api/v1/manufacturer';

  /**
   * Create a new Manufacturer.
   *
   * The api creates a new Manufacturer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createManufacturer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createManufacturer$Response(params?: {
    body?: ManufacturerDto
  }): Observable<StrictHttpResponse<ManufacturerDto>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerControllerService.CreateManufacturerPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ManufacturerDto>;
      })
    );
  }

  /**
   * Create a new Manufacturer.
   *
   * The api creates a new Manufacturer
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createManufacturer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createManufacturer(params?: {
    body?: ManufacturerDto
  }): Observable<ManufacturerDto> {

    return this.createManufacturer$Response(params).pipe(
      map((r: StrictHttpResponse<ManufacturerDto>) => r.body as ManufacturerDto)
    );
  }

  /**
   * Path part for operation activateManufacturer
   */
  static readonly ActivateManufacturerPath = '/api/v1/manufacturer/{id}/activate';

  /**
   * Activate Manufacturer.
   *
   * The api activates the specific Manufacturer for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `activateManufacturer()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateManufacturer$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerControllerService.ActivateManufacturerPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Activate Manufacturer.
   *
   * The api activates the specific Manufacturer for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `activateManufacturer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  activateManufacturer(params: {
    id: string;
  }): Observable<void> {

    return this.activateManufacturer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deactivateManufacturer
   */
  static readonly DeactivateManufacturerPath = '/api/v1/manufacturer/{id}/deactivate';

  /**
   * Deactivate Manufacturer.
   *
   * The api deactivates the specific Manufacturer for the given Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deactivateManufacturer()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateManufacturer$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerControllerService.DeactivateManufacturerPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
       responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deactivate Manufacturer.
   *
   * The api deactivates the specific Manufacturer for the given Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deactivateManufacturer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deactivateManufacturer(params: {
    id: string;
  }): Observable<void> {

    return this.deactivateManufacturer$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getProtocolsForManufacturer
   */
  static readonly GetProtocolsForManufacturerPath = '/api/v1/manufacturer/{id}/protocol';

  /**
   * GET supported protocols.
   *
   * The api fetches supported protocols for the given Manufacturer Id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProtocolsForManufacturer()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProtocolsForManufacturer$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Array<ProtocolDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ManufacturerControllerService.GetProtocolsForManufacturerPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProtocolDto>>;
      })
    );
  }

  /**
   * GET supported protocols.
   *
   * The api fetches supported protocols for the given Manufacturer Id
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProtocolsForManufacturer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProtocolsForManufacturer(params: {
    id: string;
  }): Observable<Array<ProtocolDto>> {

    return this.getProtocolsForManufacturer$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProtocolDto>>) => r.body as Array<ProtocolDto>)
    );
  }

}
