import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-distribution-package',
  templateUrl: './distribution-package.component.html',
  styleUrls: ['./distribution-package.component.scss']
})
export class DistributionPackageComponent implements OnInit {

  constructor() { }

  // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
  ngOnInit(): void {
  }

}
