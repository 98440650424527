/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AttestationTypeDto } from '../models/attestation-type-dto';
import { CommonDetails } from '../models/common-details';
import { DevicePropertyDto } from '../models/device-property-dto';

@Injectable({
  providedIn: 'root',
})
export class DeviceMgmtUtilityControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllDeviceProperties
   */
  static readonly GetAllDevicePropertiesPath = '/api/v1/deviceInterfProviderId/{providerId}/deviceProperties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllDeviceProperties()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDeviceProperties$Response(params: {
    providerId: string;
  }): Observable<StrictHttpResponse<Array<DevicePropertyDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceMgmtUtilityControllerService.GetAllDevicePropertiesPath, 'get');
    if (params) {
      rb.path('providerId', params.providerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DevicePropertyDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllDeviceProperties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllDeviceProperties(params: {
    providerId: string;
  }): Observable<Array<DevicePropertyDto>> {

    return this.getAllDeviceProperties$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DevicePropertyDto>>) => r.body as Array<DevicePropertyDto>)
    );
  }

  /**
   * Path part for operation getAllFileTypeExtensions
   */
  static readonly GetAllFileTypeExtensionsPath = '/api/v1/fileExtentions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllFileTypeExtensions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFileTypeExtensions$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceMgmtUtilityControllerService.GetAllFileTypeExtensionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllFileTypeExtensions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllFileTypeExtensions(params?: {
  }): Observable<Array<CommonDetails>> {

    return this.getAllFileTypeExtensions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation getAllAttestationTypes
   */
  static readonly GetAllAttestationTypesPath = '/api/v1/attestationType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllAttestationTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAttestationTypes$Response(params: {
    enrollmentType: string;
  }): Observable<StrictHttpResponse<Array<AttestationTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, DeviceMgmtUtilityControllerService.GetAllAttestationTypesPath, 'get');
    if (params) {
      rb.query('enrollmentType', params.enrollmentType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AttestationTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllAttestationTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllAttestationTypes(params: {
    enrollmentType: string;
  }): Observable<Array<AttestationTypeDto>> {

    return this.getAllAttestationTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AttestationTypeDto>>) => r.body as Array<AttestationTypeDto>)
    );
  }

}
