/**
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, ViewChild } from '@angular/core';
import { DeviceEnrollmentControllerService, TagControllerService } from 'src/app/services/DeviceMgmt/services';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DynamicSearchServiceService } from 'src/app/providers/dynamic-search-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import {
  BusinessProfileControllerService, TenantControllerService, SolutionTypeControllerService, PlatformAdminUtilityControllerService
} from 'src/app/services/Platform/services';
import { CommonId, GroupSearchtDto } from 'src/app/services/DeviceMgmt/models';
import { SelectionModel } from '@angular/cdk/collections';
import { MANAGE, DELETE_IMAGE, ACTIVE, DEVICE_ICON, ADD_TAGS, AWS_ICON,
  REMOVE_TAGS, BOSCH_IOT_ICON, AZURE_ICON, ZELIOT_ICON, DETAILS_IMAGE } from 'src/app/shared/constants/strings';
import { TranslationService } from 'src/app/providers/translation-service';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { TagsServiceProvider } from 'src/app/providers/tags-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { SearchComponentComponent } from 'src/app/shared/search-component/search-component.component';
import { StaticTagsComponent } from 'src/app/shared/static-tags/static-tags.component';
import { BciLoaderService } from '@bci-web-core/core';

/**
  * @ngdoc component
  * @name device.component:manageGroup
  *
  *
  * @description
  * description: To perform crud operations on the list of groups.
  *
  *
*/
@Component({
  selector: 'app-manage-group',
  templateUrl: './manage-group.component.html',
  styleUrls: ['./manage-group.component.scss']
})
export class ManageGroupComponent implements OnInit {
  public showSpinner :any;
  public deviceData;
  public columns: any[];
  public jsonFileLoaded;
  public data;
  public groupCount = 0;
  public mode: string;
  public matTabOptions = [];
  public dropDownValues: any = {};
  ignoreList = ['id', 'links', 'groupId', 'deviceInterfaceProvider'];
  asideMenus: Array<AsideCard>;
  public pageKey: string;
  public formData: any;
  public individualFormData: any;
  public currentTabValue = 'business';
  public deviceIcon: string;
  public solutionTypeId: string;
  public permissionList: Array<String> = [];
  public selectAllGroups = false;
  public selectedTag;
  public jsonFileForTags;
  public tagsArray = [];
  public groupList;
  public availableTags = [];
  public hideActionButtons = true;
  @ViewChild(StaticTagsComponent) dynamicForm: StaticTagsComponent;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(SearchComponentComponent) searchForm: SearchComponentComponent;
  pageSizeOptions: number[] = [10, 20, 30, 40, 50];
  pageSize: number = this.pageSizeOptions[0];
  selection = new SelectionModel<CommonId>(true, []);
  searchDto: any = null;
  deleteImage;
  detailsImage;
  boschIotIcon: string;
  azureIcon: string;
  awsIcon: string;
  zeliotIcon: string;
  constructor(private translate: TranslationService, private route: ActivatedRoute,
    private dynamicSearchServiceService: DynamicSearchServiceService, private router: Router,
    private tenantControllerService: TenantControllerService, private businessProfileControllerService: BusinessProfileControllerService,
    private solutionTypeControllerService: SolutionTypeControllerService,
    private deviceEnrollmentControllerService: DeviceEnrollmentControllerService,
    private _matDialog: MatDialog, private httpService: HttpClient,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private rightsideNavitemsService: RightsideNavitemsService, private responseHandlerService: ResponseHandlerService,
    private tagControllerService: TagControllerService, private tagService: TagsServiceProvider,private loaderService: BciLoaderService) {
      this.tagService.refreshSearchSubject.subscribe( refreshSearch => {
        if (refreshSearch) {
          this.reloadData(refreshSearch);
        }
      });
    }

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.jsonFileLoaded = 'device-search-business';
    this.jsonFileForTags = 'attach-tag';
    this.pageKey = 'group';
    if (this.permissionList.indexOf('GRP_SER_BP') > -1) {
      this.matTabOptions.push({ 'label': 'Business', 'value': 'business' });
    }
    if (this.permissionList.indexOf('GRP_SER_ST') > -1) {
      this.matTabOptions.push({ 'label': 'Solution', 'value': 'solution' });
    }
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });

    this.httpService
      .get('./assets/form-fields/group-search.json')
      .subscribe(data => {
        this.dynamicSearchServiceService.setFormData(data as string[]);
        this.formData = this.dynamicSearchServiceService.generateDynamicForm(this.matTabOptions[0].value);
        this.dropDownValues = this.dynamicSearchServiceService.generateDynamicFormDropdown(this.formData);
      });
    if (this.matTabOptions[0].value === 'business') {
      this.getTenant();
      this.currentTabValue = 'business';
    } else if (this.matTabOptions[0].value === 'solution') {
      this.getSolutionType();
      this.hideActionButtons = true;
      this.currentTabValue = 'solution';
    }
    this.deleteImage = DELETE_IMAGE;
    this.deviceIcon = DEVICE_ICON;
    this.boschIotIcon = BOSCH_IOT_ICON;
    this.azureIcon = AZURE_ICON;
    this.awsIcon = AWS_ICON;
    this.zeliotIcon = ZELIOT_ICON;
    this.detailsImage = DETAILS_IMAGE;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.data.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: CommonId): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  /**
    * @ngdoc method
    * @name ManageDeviceComponent#matChange
    *
    * @methodOf
    * device.controller:ManageDeviceComponent
    *
    * @description
    * to trigger action like fetching data in case tab changes.
    *
    * @param {type} click event
    * @return {type} null
  */
  public matChange(event) {
    this.data = null;
    this.columns = null;
    this.currentTabValue = this.matTabOptions[event.index].value;
    this.formData = this.dynamicSearchServiceService.generateDynamicForm(this.currentTabValue);
    this.dropDownValues = this.dynamicSearchServiceService.generateDynamicFormDropdown(this.formData);
    this.individualFormData = this.dynamicSearchServiceService.generateIndividualField(this.currentTabValue);
    this.selection.clear();
    this.hideActionButtons = true;
    if (event.index === 0) {
      this.getTenant();
    } else if (event.index === 1) {
      this.getSolutionType();
    }
  }

  /**
    * @ngdoc method
    * @name manageGroup#gotoModal
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: Open popup for crud operations.
    *
    * @param {type} content, data, mode
    * @return {type} null
  */
  public gotoModal(content, data = null, mode?) {
    this.mode = mode;
    this.deviceData = data;
    this._matDialog.open(content, { disableClose: true, data });
  }

  /**
    * @ngdoc method
    * @name manageGroup#searchGroupsEmitter
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description:
    *
    * @param {type}
    * @return {type}
  */
  public searchGroupsEmitter(event) {
    this.data = null;
    this.searchGroups(event);
  }

  /**
    * @ngdoc method
    * @name manageGroup#searchGroups
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: Search group based business or solution
    *
    * @param {type} selected dropdown values
    * @return {type} details of group based on search
  */
  public searchGroups(searchData) {
    const tags = [];
    if ( searchData.tags) {
      this.selectedTag = searchData['tags'];
      searchData.tags.forEach(tagId => {
          if (tagId) {
            const object = { id: tagId };
            tags.push(object);
          }
        });
      }
    if (this.currentTabValue === 'business') {
      const searchObj: GroupSearchtDto = {
        tenant: searchData.tenant && { id: searchData.tenant },
        businessProfile: searchData.businessProfile && { id: searchData.businessProfile },
        groupName: searchData.groupName
      };
      this.searchDto = { body: searchObj, page: 0, limit: this.pageSize };
      this.ignoreList = ['id', 'links', 'account', 'tenant', 'profile', 'groupId', 'deviceInterfaceProvider'];
    } else if (this.currentTabValue === 'solution') {
      const searchObj: GroupSearchtDto = {
        solutionType: searchData.solutionType && { id: searchData.solutionType },
        deviceInterfaceProvider: searchData.deviceInterfaceProvider && { id: searchData.deviceInterfaceProvider },
        businessProfile: searchData.businessProfile && { id: searchData.businessProfile },
        groupName: searchData.groupName,
        tags: tags
      };
      this.searchDto = { body: searchObj, page: 0, limit: this.pageSize };
      this.ignoreList = ['id', 'links', 'account', 'solutionType', 'deviceType', 'manufacturer', 'groupId', 'deviceInterfaceProvider'];
    }
    if (searchData.criteria === 'ADVANCE') {
      this.deviceEnrollmentControllerService
        .getGroupEnrollmenCount({ searchtype: this.currentTabValue, body: this.searchDto.body })
        .subscribe(
          resp => {
            if (resp) {
              this.groupCount = resp;
              this.getGroups(this.searchDto);
            } else {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
              this.hideActionButtons = true;
            }
          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.hideActionButtons = true;
          }
        );
    } else {
      this.groupCount = 1;
      this.getGroups(this.searchDto);
    }
  }

  /**
    * @ngdoc method
    * @name manageGroup#getDevices
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: Fetches group list.
    *
    * @param {type} searchdto
    * @return {type} null
  */
  public getGroups(searchdto) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.selection.clear();
    this.selectAllGroups = false;
    if (this.currentTabValue === 'business') {
      this.deviceEnrollmentControllerService
        .getGroupEnrollmentOnBusinessProfile(searchdto)
        .subscribe(
          resp => {
            if (resp && resp.length) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.data = resp;
              this.data.paginator = this.paginator;
              for (const data of this.data) {
                data['action'] = null;
                data['status'] = (data['status'] === 'ACTIVE' ? true : false);
                data['account'] = data['account'] && data['account']['name'];
                data['tenant'] = data['tenant'] && data['tenant']['name'];
                data['profile'] = data['profile'] && data['profile']['name'];
                data['solutionType'] = data['solutionType'] && data['solutionType']['name'];
                data['deviceType'] = data['deviceType'] && data['deviceType']['name'];
                data['interfaceProvider'] = data['deviceInterfaceProvider'] && data['deviceInterfaceProvider']['name'];
              }
              if (!this.columns) {
                this.columns = this.filterColumns(Object.keys(this.data[0]));
                this.columns.unshift('select');
              }
            } else {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
            }

          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    } else if (this.currentTabValue === 'solution') {
      this.deviceEnrollmentControllerService
        .getGroupEnrollmentOnSolutionType(searchdto)
        .subscribe(
          resp => {
            if (resp && resp.length) {
              this.loaderService.hideProgressBar(this.showSpinner);
              this.deviceData = resp;
              this.data = resp;
              for (const data of this.deviceData) {
                data['action'] = null;
                data['status'] = (data['status'] === 'ACTIVE' ? true : false);
                data['account'] = data['account'] && data['account']['name'];
                data['tenant'] = data['tenant'] && data['tenant']['name'];
                data['profile'] = data['profile'] && data['profile']['name'];
                data['interfaceProvider'] = data['deviceInterfaceProvider'] && data['deviceInterfaceProvider']['name'];
                data['solutionType'] = data['solutionType'] && data['solutionType']['name'];
                data['deviceType'] = data['deviceType'] && data['deviceType']['name'];
                data['tags'] = data['tags'] && data['tags'];
              }
              this.columns = this.filterColumns(Object.keys(this.deviceData[0]));
              this.columns.unshift('select');
              this.hideActionButtons = false;
            } else {
              this.hideActionButtons = true;
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
            }

          },
          err => {
            this.loaderService.hideProgressBar(this.showSpinner);
            this.hideActionButtons = true;
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          }
        );
    }
  }

  /**
    * @ngdoc method
    * @name manageGroup#deleteDevice
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: Deletes particular group.
    *
    * @param {type} data
    * @return {type} null
  */
  public deleteGroup(data) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    this.deviceEnrollmentControllerService.deleteGroupEnrollment({ id: data['groupId'] }).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'GROUP_DELETE_SUCCESS');
        this.getGroups(this.searchDto);
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }

  /**
    * @ngdoc method
    * @name manageGroup#activateGroup
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: De-Activates particular device group.
    *
    * @param {type} data
    * @return {type} null
  */
  public activateGroup(data) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    data['status'] = ACTIVE;
    this.deviceEnrollmentControllerService.activateGroup({ id: data['groupId'] }).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'DEVICE_GROUP_ACTIVATE_SUCCESS');
        this.getGroups(this.searchDto);
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        data['status'] = false;
      }
    );
  }

  /**
    * @ngdoc method
    * @name manageGroup#deActivateGroup
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: De-activates particular device grouop.
    *
    * @param {type} data
    * @return {type} null
  */
  public deActivateGroup(data) {
    this._matDialog.closeAll();
    this.showSpinner = this.loaderService.showProgressBar();
    data['status'] = false;
    this.deviceEnrollmentControllerService.deactivateGroup({ id: data['groupId'] }).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'DEVICE_GROUP_DEACTIVATE_SUCCESS');
        this.getGroups(this.searchDto);
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        data['status'] = true;
      }
    );
  }

  /**
    * @ngdoc method
    * @name manageGroup#abortAction
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: Closes the popup and restore the device active/inactive state.
    *
    * @param {type} null
    * @return {type} null
  */
  public abortAction() {
    this._matDialog.closeAll();
    if (this.mode !== 'delete') {
      this.deviceData['status'] = !this.deviceData['status'];
    }
  }

  /**
    * @ngdoc method
    * @name manageGroup#filterColumns
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: updates columns array by filtering the keys present in ignoreList. Keys in columns array will be shown in table.
    *
    * @param {type} columns
    * @return {type} list of columns
  */
  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item => this.ignoreList.indexOf(item) <= -1));
    }
  }

  /**
    * @ngdoc method
    * @name manageGroup#checkPermission
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: Checks whether the logged in user has permission to perform crud operations.
    *
    * @param {type} key
    * @return {type} boolean
  */
  public checkPermission(key: string) {
    if (this.permissionList.indexOf(key) <= -1) {
      return false;
    }
    return true;
  }

  /**
    * @ngdoc method
    * @name manageGroup#getTenant
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: To populate tenant dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getTenant() {
    this.tenantControllerService
      .findAllTenantsBasic({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        err => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageGroup#updateDropdownValues
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: To populate dependent dropdown.
    *
    * @param {type} dropdown select event
    * @return {type} null
  */
  public updateDropdownValues($event) {
    if ($event.value !== undefined) {
      if ($event.value && $event.parentName === 'tags') {
        this.selectedTag = $event.value;
      }
      if ($event.child) {
      switch ($event.child.child) {
        case 'businessProfile':
          if ($event.parentName === 'tenant') {
            this.getProfile($event.value);
          }
          if ($event.parentName === 'deviceInterfaceProvider') {
            this.getBusinessProfileOnSolution(this.solutionTypeId, $event.value);
          }
          break;
        case 'deviceInterfaceProvider':
        this.dropDownValues['tags'] = [];
        this.getDeviceInterfaceProviders($event.value);
        this.getAvailableTags($event.value);
         this.solutionTypeId = $event.value;
          break;
      }
    }
    } else if ($event.parentName === 'solutionType') {
      this.dropDownValues['tags'] = [];
    }
  }

  public getBusinessProfileOnSolution(solutionTypeId: string, deviceInterfaceProviderId: string) {
    this.solutionTypeControllerService
      .findByBusinessProfilesListBasicForSolutionTypeId({ solutionTypeId: solutionTypeId,
        deviceInterfaceProviderId: deviceInterfaceProviderId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        err => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageGroup#getProfile
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: To populate business profile dropdown.
    *
    * @param {type} tenantId
    * @return {type} null
  */
  public getProfile(tenantId: string) {
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        err => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageGroup#getSolutionType
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: To populate solution type dropdown.
    *
    * @param {type} null
    * @return {type} null
  */
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageGroup#pageEvent
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: To fetch the next list of devices.
    *
    * @param {type} event
    * @return {type} null
  */
  public pageEvent(event) {
    this.searchDto['page'] = event.pageIndex;
    this.searchDto['limit'] = event.pageSize;
    this.getGroups(this.searchDto);
    this.selection.clear();
    this.selectAllGroups = false;
  }

  /**
    * @ngdoc method
    * @name manageGroup#onClose
    *
    * @methodOf
    * device.controller:manageGroup
    *
    * @description
    * Description: To close the popup.
    *
    * @param {type} null
    * @return {type} null
  */
  public onClose() {
    this._matDialog.closeAll();
    this.tagsArray = [];
    this.selection.clear();
    this.selectAllGroups = false;
  }

  public getDeviceInterfaceProviders(solutionTypeId) {
    this.platformAdminUtilityControllerService.deviceInterfaceProviders({ solutionTypeId: solutionTypeId, profileId: '', active: 'true' }).subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['deviceInterfaceProvider'] = resp;
        } else {
          this.responseHandlerService.returnToastMessage('warning', 'NO_DATA_AVAILABLE');
          this.dropDownValues['deviceInterfaceProvider'] = [];
        }
      },
      err => {
        this.dropDownValues['deviceInterfaceProvider'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public gotoConfirmDialog(data = null, mode?) {
    this.deviceData = data;
    this.mode = mode;
    let message;
    switch (mode) {
      case 'edit': if ((data['status'])) {
        message = this.translate.translateErrorMessages('ACTIVATE_SELECTED_DEVICE_GROUP');
      } else {
        message = this.translate.translateErrorMessages('DEACTIVATE_SELECTED_DEVICE_GROUP');
      }
        break;
      case 'delete': message = this.translate.translateErrorMessages('DELETE_SELECTED_DEVICE_GROUP');
        break;

    }
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (mode) {
          case 'edit': if (data['status']) {
            this.activateGroup(data);
          } else {
            this.deActivateGroup(data);
          }
            break;
          case 'delete': this.deleteGroup(data);
            break;
        }
      } else {
        this.abortAction();
      }
    });
  }

  /**
  * @ngdoc method
  * @name CreateDevice#getAvailableTags
  *
  * @methodOf
  * firmware.controller:manageDevice
  *
  * @description
  * Description: Fetch the list of available static tags
  * @param {type} solutionTypeId
  * @return {type} null
  */
 public getAvailableTags(solutionTypeId: string) {
  this.dropDownValues['tags'] = null;
  this.tagControllerService.findAllTagsBySolutionTypeId({ solutionTypeId: solutionTypeId })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['tags'] = resp;
          this.availableTags = resp;
          if (this.dynamicForm) {
            resp.forEach(tag => {
              if (tag) {
                this.dynamicForm.stringArray.push(tag.name);
              }
            });
          }
          // Below code line clears previously selected tags, if same solutiontype is selected again
          this.searchForm.fields['tags'] = [];
        } else {
          this.dropDownValues['tags'] = [];
        }
      },
      err => {
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}

/**
    * @ngdoc method
    * @name ManageGroupComponent#selectGroups
    *
    * @methodOf
    * device.controller:manageGroupComponent
    *
    * @description
    * select or unselect all devices in the table.
    *
    * @param {type} null
    * @return {type} null
  */
 public selectGroups() {
  this.selectAllGroups = !this.selectAllGroups;
  if (this.selectAllGroups) {
    this.data.forEach(row => {
      if (row !== undefined) {
        this.selection.select(row['groupId']);
      }
    });
  } else {
    this.selection.clear();
  }
}

public selectAllGroupsCheckBox() {
  if (this.isAllSelected()) {
    this.selectAllGroups = !this.selectAllGroups;
  }
}

/**
* @ngdoc method
* @name ManageGroup#openModal
*
* @methodOf
* firmware.controller:manageGroup
*
* @description
* Description: Opens up the modal to select and attach the tags
* @param {type} content
* @return {type} null
*/
 public openModal(content) {
  this.getAvailableTags(this.solutionTypeId);
  const groupList = this.selection['selected'].map(device => {
    return { 'id': device };
  });
  this.groupList = groupList;
  if (groupList.length) {
    groupList.forEach(selectedGroup => {
      if (this.deviceData.indexOf(selectedGroup)) {
        this.deviceData.forEach(group => {
          if (group.tags && group.groupId === selectedGroup.id) {
            group.tags.forEach(tag => {
              if (this.tagsArray && this.tagsArray.indexOf(tag) === -1) {
                this.tagsArray.push(tag);
              }
            });
          }
        });
      }
    });
  }
  this._matDialog.open(content, { maxHeight: '80vh', minWidth: '30vw', disableClose: true, data: null });
 }

/**
* @ngdoc method
* @name ManageGroup#addOrRemoveTags
*
* @methodOf
* firmware.controller:manageGroup
*
* @description
* Description: Will call the tags common service to perform operations like adding and removing the tags from ui
* @param {type} $event
* @return {type} null
*/
addOrRemoveTags($event) {
this.tagService.addOrRemoveTags($event, this.dynamicForm, this.availableTags, 'groupEnrollments', this.groupList);
}

/**
  * @ngdoc method
  * @name ManageGroup#populateExistingTags
  *
  * @methodOf
  * firmware.controller:manageGroup
  *
  * @description
  * Description: This method will populate attached tags to the selected device list, once dynamic form on modal is loaded. Its event based
  * @param {type} $event
  * @return {type} null
  */
 public populateExistingTags($event) {
  if (this.dynamicForm && this.tagsArray.length) {
    this.dynamicForm.selectedValuesArray = this.tagsArray;
  }
}

public reloadData(refreshSearch) {
  if (refreshSearch && refreshSearch === 'refreshAttachSearch') {
    this.getGroups(this.searchDto);
    this.loaderService.hideProgressBar(this.showSpinner);
    this.getAvailableTags(this.solutionTypeId);
  } else if (refreshSearch && refreshSearch === 'refreshDetachSearch') {
    this.getGroups(this.searchDto);
    this.loaderService.hideProgressBar(this.showSpinner);
  }
}

public goToDetailsPage(data) {
  this.router.navigate(['group-details'], { queryParams: data });
}
}

