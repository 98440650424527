<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <div class="heading-label">
        <img (click)="navigateBack()" class="back-btn" [src]="backImage" />
        <span>{{ 'UPDATE_PACKAGE_DETAILS' | translate }}</span>
      </div>
    </div>
    <div class="container" *ngIf="packageData">
      <div class="left">
        <div class="details">{{'DETAILS' |translate}}</div>
        <div *ngFor="let key of getPropertyOrder(packageData)">
          <div class="pair">
            <div class="key">{{ key | appendSpace | titlecase }}:</div>
            <div class="value" *ngIf="!isObject(packageData[key])">
              {{ packageData[key] }}
            </div>
            <div class="value" *ngIf="isObject(packageData[key])">
              <ng-container *ngFor="let nestedProperty of packageData[key] | keyvalue">
                <div class="nested-pair">
                  <div class="nested-key">{{ nestedProperty.key | appendSpace | titlecase }}:</div>
                  <div class="nested-value">{{ nestedProperty.value }}</div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div>
          <div class="pair">
            <div class="key">{{'DOWNLOAD' | translate}}</div>
            <div class="value link" (click)="downloadDynamicCertificate()" title="{{downloadedFile}}">{{'FILE' |translate}}</div>
          </div>
        </div>
      </div>
      <span class="horizontal-line"></span>
      <div class="right">
        <div *ngFor="let property of createUpdatedPackageData | keyvalue">
          <div class="date-heading">{{ property.key}}</div>
          <div class="value date-value">{{ property.value }}</div><br>
        </div>
        <div class="vertical-line"></div>
        <p class="date-heading"> {{'ACTION' | translate}}</p>
        <p class="link">{{'EDIT' | translate}}</p>
        <p class="link" (click)="gotoConfirmDialog()">{{'DELETE'| translate}}</p>
      </div>
    </div>
    <div>
    </div>
  </section>
</section>