import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import { BillingConfigurationComponent } from './billing-configuration/billing-configuration.component';
import { BillingDataComponent } from './billing-data/billing-data.component';
import { OperationConfigurationComponent } from './operation-configuration/operation-configuration.component';
import { ProposalsComponent } from './proposals/proposals.component';
import { ProposalDetailsComponent } from './proposal-details/proposal-details.component';

const routes: Routes = [
  {
    path: 'billing/proposals',
    component: ProposalsComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'PROPSL_R', data: {} }
  },
  {
    path: 'billing/proposal-details',
    component: ProposalDetailsComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'PROPSL_APR_REJ', data: {} }
  },
  {
    path: 'billing/create-proposal',
    component: BillingConfigurationComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'PROPSL_C', data: {} }
  },
  {
    path: 'billing/billing-data',
    component: BillingDataComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'PROPSL_R', data: {} }
  },
  {
    path: 'billing/operation-configuration',
    component: OperationConfigurationComponent,
    canActivate: [CanAuthenticationGuard],
    data: { permission: 'SUBCPN_R', data: {} }
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingRoutingModule { }
