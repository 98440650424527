<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="profileIcon" />
        <span>{{'PROFILE_MANAGEMENT_CREATE' |translate}}</span>
      </span>
    </div>
    <div class="create-form-spacing">
      <form [formGroup]="createProfileForm" class="create-form-spacing form-fields">
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'TENANT' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="tenant" [placeholder]=" 'SELECT' | translate">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['tenant']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || createProfileForm.controls.tenant?.touched && createProfileForm.controls.tenant?.errors">
            <div *ngIf="createProfileForm.controls.tenant?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'TENANT' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{'BUSINESS_PROFILE_NAME' | translate}} <span class="mandatory">*</span>
            </mat-label>
            <input type="text" matInput formControlName="profileName" class="form-field"
              [placeholder]=" 'TENANTCODE_SOLUTIONTYPE_COUNTRYCODE2CHARS' | translate" />
          </mat-form-field>
          <div
            *ngIf="submitted || createProfileForm.controls.profileName?.touched && createProfileForm.controls.profileName?.errors">
            <div *ngIf="createProfileForm.controls.profileName?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'BUSINESS_PROFILE_NAME' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
            <div *ngIf="createProfileForm.controls.profileName?.errors?.maxlength">
              <mat-error class="mat-error-class">
                {{ 'BUSINESS_PROFILE_NAME_SHOULD_BE_OF_MAXIMUM_50_CHARACTERS'| translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'REALM' | translate}}
            </mat-label>
            <mat-select *ngIf="!profile['profileId']" class="form-field" formControlName="realmName"
              [placeholder]=" 'SELECT' | translate">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['realmName']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
            <input type="text" *ngIf="profile['profileId']" matInput formControlName="realmName" class="form-field"
              readonly />
          </mat-form-field>
        </div>
        <div>
          <mat-form-field>
            <mat-label class="label">{{ 'BUSINESS_TYPE' | translate}}
              <span class="mandatory">*</span>
            </mat-label>
            <mat-select class="form-field" formControlName="businessType" [placeholder]=" 'SELECT' | translate">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of dropDownValues['businessType']" [value]="val.id">
                {{val.name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <div
            *ngIf="submitted || createProfileForm.controls.businessType?.touched && createProfileForm.controls.businessType?.errors">
            <div *ngIf="createProfileForm.controls.businessType?.errors?.required">
              <mat-error class="mat-error-class">
                {{ 'BUSINESS_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
              </mat-error>
            </div>
          </div>
        </div>
        <div formArrayName="solutionTypeProvidersubscriptionGrp">
          <div
            *ngFor="let group of createProfileForm.get('solutionTypeProvidersubscriptionGrp')['controls']; let indexNumber = index">
            <div [formGroupName]="indexNumber">
              <div class="form-array-field">
                <mat-form-field class="form-array-field">
                  <mat-label class="label">{{ 'SOLUTION_TYPE' | translate}}
                    <span class="mandatory">*</span>
                  </mat-label>
                  <mat-select class="form-field" (selectionChange)="getDeviceInterfaceProviders($event.value, group)"
                    formControlName="solutionType" [placeholder]=" 'SELECT' | translate">
                    <mat-option *ngFor="let val of group.get('solutionType')?.dropdownValues" [value]="val.id">
                      {{val.name}}</mat-option>
                  </mat-select>
                <div *ngIf="submitted || group.get('solutionType')?.touched && group.get('solutionType')?.errors"  class="error-message">
                  <div *ngIf="group.get('solutionType')?.errors?.required">
                    <mat-error class="mat-error-class">
                      {{ 'SOLUTION_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
                    </mat-error>
                  </div>
                </div>
              </mat-form-field>
              </div>

              <div class="form-array-field">
                <mat-form-field class="form-array-field">
                  <mat-label class="label">{{ 'DEVICE_INTERFACE_PROVIDER' | translate}}
                  </mat-label>
                  <mat-select class="form-field" (selectionChange)="disableSubscriptionField($event, group)"
                    formControlName="deviceInterfaceProvider" [placeholder]=" 'SELECT' | translate">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of group.get('deviceInterfaceProvider')?.dropdownValues" [disabled]="!val.active"
                      [value]="val.id">
                      {{val.name | translate}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-array-field">
                <mat-form-field class="form-array-field">
                  <mat-label class="label" [class.disabled]="group.get('subscription')?.status ==='DISABLED'">
                    {{ 'IOT_HUB_NAME' | translate}}
                  </mat-label>
                  <mat-select class="form-field" formControlName="subscription" [placeholder]=" 'SELECT' | translate" multiple>
                    <mat-option *ngFor="let val of group.get('subscription')?.dropdownValues" [value]="val.id">
                      {{val.name}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <button type="button" mat-icon-button color="primary" [title]="'ADD' | translate"
                  (click)="addSolutionTypeProviderGroup()" class="action-buttons"><span
                    class="action-button-font">+</span></button>
                <button *ngIf="createProfileForm.get('solutionTypeProvidersubscriptionGrp')['controls']?.length > 1"
                  mat-icon-button color="primary" [title]="'REMOVE' | translate" class="action-buttons"
                  (click)="removeSolutionTypeProviderGroup(indexNumber)"><span
                    class="action-button-font">-</span></button>
              </div>
            </div>
            <div class="clear-float"></div>
          </div>
        </div>
        <div>
          <mat-label class="label-color label-checkbox">{{ 'DEMO_ACCOUNT' | translate}}
          </mat-label>
          <mat-checkbox formControlName="demoAccount">
         </mat-checkbox>
        </div>

      </form>
      <div>
        <section class="section">
          <button (click)="saveProfile()" mat-raised-button class="button button-submit">{{ 'SUBMIT' | translate }}</button>
          <button (click)="onReset()" mat-button class="button">{{ 'RESET' | translate }}</button>
        </section>
      </div>
    </div>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>