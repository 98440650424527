<section class="overview-container">
    <section class="main">
      <div class="header-style">
        <div class="heading-label">
          <img class="page-icon" [src]="whatsappIcon" />
          <span>{{'DELIVERY_STATUS' | translate}}</span>
        </div>
      </div>
      <app-search-component *ngIf="whatsappStatusData" [comboFieldSearchArray]="whatsappStatusData"
            [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)"
            (searchFieldEmitter)="whatsappStatusEmitter($event,content)">
    </app-search-component>
    <div class="refresh-icon" *ngIf="reportData">
      <img [src]="refreshIcon" class="" (click)="viewRequest()" [title]="'CLICK_HERE_TO_VIEW_OR_REFRESH_YOUR_SUBMITTED_REQUEST'|translate">
    </div>
    <mat-table *ngIf="reportDateToggle" [dataSource]="reportData" class="table-data">
      <div *ngFor="let column of reportColumn">
        <ng-container [matColumnDef]="column">
          <mat-header-cell *matHeaderCellDef>{{ column | appendSpace | titlecase | translate }}
          </mat-header-cell>
          <mat-cell class="tooltip" *matCellDef="let dataName; index as i">
            <span title="{{ dataName[column] }}" class="cell-value">
              {{ dataName[column] }}
            </span>
            <span *ngIf="column==='download'">
              <a *ngIf="dataName['status'] === 'Completed'" 
              [href]="dataName['downloadLink']" download class="download-icon">
              <img [src]="downloadImage" [title]="'DOWNLOAD' | translate" class="modify-button" (click)="downloadReport()"></a>
            </span>
          </mat-cell>
        </ng-container>
     </div>
     <mat-header-row *matHeaderRowDef="reportColumn"></mat-header-row>
     <mat-row *matRowDef="let data; columns: reportColumn"></mat-row>
</mat-table> 
    
</section>
</section>
<ng-template #content let-modal>
  <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
  <div class="modal-width">
    <div>
      <form [formGroup]="reportNameForm">
        <div class="modal-body">
          <mat-form-field>
            <mat-label class="label">{{'REPORT_NAME' | translate}}<span class="mandatory">*</span>
            </mat-label>
            <input class="input-field" type="'text'" matInput formControlName="reportName"
              [placeholder]="'TYPE_HERE' | translate" />
          </mat-form-field>
          <div *ngIf="submitted && formControls.reportName?.touched && formControls.reportName.errors">
            <mat-error *ngIf="formControls.reportName.errors.required">
              {{ 'REPORT_NAME' | translate }} {{'IS_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="formControls.reportName.errors.maxlength">
              {{ 'REPORT_NAME_MAX_LENGTH_EXCEEDED' | translate }}
            </mat-error>
            <mat-error *ngIf="formControls.reportName.errors.invalidCharacters">
              {{ 'INVALID_CHARACTERS' | translate }}
            </mat-error>
          </div>
          <mat-form-field>
            <mat-label class="label">{{'EMAIL' | translate}}<span class="mandatory">*</span>
            </mat-label>
            <input class="input-field" type="'email'" matInput formControlName="emailId"
              [placeholder]="'TYPE_HERE' | translate" />
          </mat-form-field>
          <div *ngIf="submitted && reportNameForm.controls.emailId?.touched && reportNameForm.controls.emailId?.errors">
            <mat-error *ngIf="reportNameForm.controls.emailId.errors.required">
              {{ 'EMAIL' | translate }} {{'IS_REQUIRED' | translate }}
            </mat-error>
            <mat-error *ngIf="reportNameForm.controls.emailId.errors.email">
              {{ 'INVALID_EMAIL_ID'| translate }}
            </mat-error>
          </div>
        </div>
        <div class="modal-footer">
          <button mat-raised-button (click)="submitRequest()">{{ 'SUBMIT' | translate }}</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
