<!--
  ~ Copyright (C) 2018 Robert Bosch Manufacturing Solutions GmbH, Germany. All rights reserved.
-->
<div fxLayout="row wrap" fxLayoutAlign="center center" class="header-div">
    <div fxFlex="100%">
        <div class="tool-bar">
            <div class="rb-ribbon header-rib"></div>
            <div class="header-title" fxHide.lt-md>
                <div class="title">
                    <img [src]="boschImage" class="img-resize">
                    <span class="project-title">{{ 'PJMS' | translate }}</span>
                </div>
                <div fxFlex fxLayout fxLayoutAlign="end" class="header-temp">
                    <div *ngTemplateOutlet="headerRef">
                    </div>
                </div>
            </div>
            <div class="header-title tab-header" fxHide>
                <div class="title tab-title">
                    <img [src]="boschImage" class="img-resize">
                    <span class="project-title tab-project-title">{{ 'PJMS' | translate }}</span>
                </div>
                <div fxFlex fxLayout fxLayoutAlign="end" class="tab-header-opt">
                    <div *ngTemplateOutlet="headerRef">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #headerRef>
    <!-- Future Implementation - Search bar
        <mat-form-field class="search-bar" *ngIf="showSearchBar">
        <input matInput type="text" [(ngModel)]="value" class="search">
        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
            <mat-icon class="material-icons">close</mat-icon>
        </button>
    </mat-form-field>-->
    <ul fxLayout class="navigation-items header-ul">
        <!-- Future Implementation - Search bar
        <li class="header-li">
            <a class="rb-ic rb-ic-search header-a last-seperator icon search-icon" (click)="onSearchSelect()"></a>
        </li>-->
        <li class="lang-li" fxHide.lt-md>
            <app-menu [options]="langjson" [dropDown]="true" class="disabled-lang-dropdown" [defaultValue]="defaultlang"
                (languageChange)="selectLanguage($event)"></app-menu>
        </li>
        <li class="header-li" [ngClass]="showHeaderopt ? '' : 'hide-header'">
            <mat-icon class="rb-ic" class="bosch-ic-notification-active" (click)="onClose()"></mat-icon>
        </li>
        <li class="header-li" [ngClass]="showHeaderopt ? 'hide-header' : ''">
            <a *ngIf="loginStatus" (click)="gotoModal(userPopover)"
                class="rb-ic rb-ic-login active login-icon icon seperator"></a>
            <p *ngIf="!loginStatus" class="login-class">{{ 'LOGIN' | translate }}</p>
        </li>
        <li class="header-li" [ngClass]="showHeaderopt ? '' : 'hide-header'">
            <app-menu [userProfileName]="loggedInUserProfile?.userName" [userProfileRole]="loggedInUserProfile?.roles"
                [listOptions]="profilejson" [icon]="true" classList="bosch-ic-my-brand-frame profile-img"
                (languageChange)="optionSelect($event, userPopover)"></app-menu>
        </li>
    </ul>
    <!-- <app-menu [listOptions]="taboptionjson" [icon]="true" classList="rb-ic-options" fxHide
        [ngClass]="showHeaderopt ? '' : 'hide-header'"></app-menu> -->
</ng-template>

<ng-template #userPopover>
    <div class="spacing">
        <p>
            {{ 'Logout_message' | translate }}
        </p>
        <button (click)="logout()" mat-raised-button class="manage-action-btn btn-background">{{ 'LOGOUT' | translate }}</button>
        <button (click)="cancel()" mat-button class="manage-action-btn">{{ 'CANCEL' | translate }}</button>
    </div>
</ng-template>