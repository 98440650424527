/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { EnvService } from 'src/app/env.service';

/**
 * Global configuration
 */
@Injectable({
  providedIn: 'root',
})
export class ApiConfiguration {
  constructor(
    private env: EnvService
  ) {
  }
  rootUrl: string = this.env['apiUrl'] + '/billingservice';
}

/**
 * Parameters for `ApiModule.forRoot()`
 */
export interface ApiConfigurationParams {
  rootUrl?: string;
}