/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SplitPercentageDto } from '../models/split-percentage-dto';

@Injectable({
  providedIn: 'root',
})
export class SmsSplitPercentControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAllServiceProviders
   */
  static readonly GetAllServiceProvidersPath = '/api/v1/split-percents';

  /**
   * List all service providers.
   *
   * The API to fetch all SMS service providers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllServiceProviders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllServiceProviders$Response(params?: {
  }): Observable<StrictHttpResponse<Array<SplitPercentageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsSplitPercentControllerService.GetAllServiceProvidersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SplitPercentageDto>>;
      })
    );
  }

  /**
   * List all service providers.
   *
   * The API to fetch all SMS service providers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllServiceProviders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllServiceProviders(params?: {
  }): Observable<Array<SplitPercentageDto>> {

    return this.getAllServiceProviders$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SplitPercentageDto>>) => r.body as Array<SplitPercentageDto>)
    );
  }

  /**
   * Path part for operation updateSplitPercent
   */
  static readonly UpdateSplitPercentPath = '/api/v1/split-percents';

  /**
   * Update Split Percentage.
   *
   * The API to enable/disable and to update split percent of service providers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSplitPercent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSplitPercent$Response(params?: {
    body?: Array<SplitPercentageDto>
  }): Observable<StrictHttpResponse<Array<SplitPercentageDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsSplitPercentControllerService.UpdateSplitPercentPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SplitPercentageDto>>;
      })
    );
  }

  /**
   * Update Split Percentage.
   *
   * The API to enable/disable and to update split percent of service providers
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSplitPercent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSplitPercent(params?: {
    body?: Array<SplitPercentageDto>
  }): Observable<Array<SplitPercentageDto>> {

    return this.updateSplitPercent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SplitPercentageDto>>) => r.body as Array<SplitPercentageDto>)
    );
  }

}
