import { Component, OnInit, ViewChild } from '@angular/core';
import {
  BusinessProfileControllerService,
  AccountControllerService,
  TenantControllerService
} from 'src/app/services/Platform/services';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ACCESS_KEY_ICON, COPY_IMAGE, DELETE_IMAGE, DETAILS_ICON_BLUE, GENERATE_IMAGE,
  HIDE_KEY, MANAGE, SHOW_KEY, EDIT_IMAGE, CLOSE_ICON
} from 'src/app/shared/constants/strings';
import { AccesskeyLifeCycleControllerService } from 'src/app/services/Platform/services/accesskey-life-cycle-controller.service';
import { TranslationService } from 'src/app/providers/translation-service';
import {  MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogPopupComponent } from 'src/app/shared/confirmation-dialog-popup/confirmation-dialog-popup.component';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { MapControllerService } from 'src/app/services/Platform/services';
import { ServiceMasterControllerService } from 'src/app/services/Platform/services';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { BciLoaderService } from '@bci-web-core/core';

@Component({
  selector: 'app-tenant-admin-key',
  templateUrl: './tenant-admin-key.component.html',
  styleUrls: ['./tenant-admin-key.component.scss']
})
export class TenantAdminKeyComponent implements OnInit {
  serviceList;
  additionalServiceList;
  businessProfileId: string;
  solutionTypeId: string;
  selectedValues = {};
  accessKeyProperties = { tenantAdminKey: null, managerialService: null, expiryDateTime: null };
  showKey = {};
  public dropdownValues: any = {};
  public editData: any = {};
  dropdownList = [];
  showKeyImage;
  hideKeyImage;
  copyImage;
  deleteImage;
  refreshImage;
  accessKeyIcon;
  detailsImage;
  messageArray;
  public showSpinner :any;
  public pageKey: string;
  public serviceListJson;
  asideMenus: Array<AsideCard>;
  dataSource;
  columns = [];
  ignoreList = ['id', 'validity', 'managerialServices'];
  multipleKeys = false;
  editImage;
  generateForm: UntypedFormGroup;
  accessKeyId: string;
  jsonFileLoaded;
  noOfKeys = 0;
  expiryEmails;
  subscriptionList: any = {};
  accessKeyFlags: any = {};
  keysList = [];
  public disableButton:any;

  @ViewChild(MatTable) table: MatTable<any>;
  closeIcon: string;
  constructor(private accountControllerService: AccountControllerService, private tenantControllerService: TenantControllerService,
    private accesskeyLifeCycleControllerService: AccesskeyLifeCycleControllerService,
    private responseHandlerService: ResponseHandlerService,
    private route: ActivatedRoute,
    private router: Router,
    private businessProfileControllerService: BusinessProfileControllerService,
    private mapControllerService: MapControllerService,
    private serviceMasterControllerService: ServiceMasterControllerService,
    private translate: TranslationService, private _matDialog: MatDialog, private rightsideNavitemsService: RightsideNavitemsService,
    private formBuilder: UntypedFormBuilder,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.pageKey = 'tenant-admin-key';
    this.jsonFileLoaded = 'create-access-key';
    this.getValuesForExpiryDuration();
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.dropdownList = [
      {
        label: 'ACCOUNT',
        dropdownName: 'ACCOUNT',
        id: 'Account',
        childArray: ['TENANT', 'PROFILE', 'SOLUTION']
      },
      {
        label: 'TENANT',
        dropdownName: 'TENANT',
        id: 'Tenant',
        childArray: ['PROFILE', 'SOLUTION']
      },
      {
        label: 'PROFILE',
        dropdownName: 'PROFILE',
        id: 'Profile',
        childArray: ['SOLUTION']
      },
      {
        label: 'SOLUTION',
        id: 'Solution',
        dropdownName: 'SOLUTION',
      }
    ];

    this.messageArray = {
      Config_Service: 'CONFIG_SERVICE_MSG',
      Push_Notification_Service: 'PUSH_NOTIFICATION_SERVICE_MSG',
      Email_Service: 'EMAIL_SERVICE_MSG',
      SMS_Service: 'SMS_SERVICE_MSG',
      C2D_Service: 'C2D_SERVICE_MSG',
      Map_Service: 'MAP_SERVICE_MSG',
      Whatsapp_service: 'WHATSAPP_SERVICE_MSG',
      Accesskeymanagement_Service: 'ACCESSKEY_SERVICE_MSG'
    };
    this.getAccounts();
    this.selectedValues['solution'] = false;
    this.generateForm = this.formBuilder.group({
      emailList: this.formBuilder.array([this.createEmailFormControl()]),
    });

    this.copyImage = COPY_IMAGE;
    this.deleteImage = DELETE_IMAGE;
    this.refreshImage = GENERATE_IMAGE;
    this.showKeyImage = SHOW_KEY;
    this.hideKeyImage = HIDE_KEY;
    this.accessKeyIcon = ACCESS_KEY_ICON;
    this.detailsImage = DETAILS_ICON_BLUE;
    this.editImage = EDIT_IMAGE;
    this.closeIcon = CLOSE_ICON;
  }

  public copyText(id) {
    const text = document.getElementById(id) as HTMLInputElement;
    text.select();
    document.execCommand('copy');
    this.responseHandlerService.returnToastMessage('success', 'COPIED_TO_CLIPBOARD');
  }

  public getServiceList(accessKey) {
    this.accesskeyLifeCycleControllerService.getServiceListWithAccessKeyFlag({
      accessKeyId: accessKey, type: 'payperuse'
    }).subscribe(resp => {
      if (resp && resp['length']) {
        this.serviceList = resp;
        resp.forEach( service => {
          let obj = {};
          if ( this.subscriptionList[service['id']]) {
            obj = this.subscriptionList[service['id']];
          }
          if ( this.accessKeyFlags[service['id']] === undefined || service['accesskeyflag'] ) {
            this.accessKeyFlags[service['id']] = service['accesskeyflag'];
          }
          obj[accessKey] = service['accesskeyflag'];
          this.subscriptionList[service['id']] = obj;
        });
      }
    }, err => {
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getAdditionalserviceList() {
    this.serviceMasterControllerService.getAdditionalServiceListWithSubscriptionFlag({
      profileId: this.businessProfileId, solutionTypeId: this.solutionTypeId
    }).subscribe(resp => {
      if (resp && resp['length']) {
        this.additionalServiceList = resp;
      }
    }, err => {
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });

  }

  public getSolutionType(profileId) {
    this.businessProfileControllerService
      .findBySolutionTypeListBasicForBusinessProfileId({ businessProfileId: profileId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropdownValues['SOLUTION'] = resp;
          } else {
            this.dropdownValues['SOLUTION'] = [];
          }
        },
        err => {
          this.dropdownValues['SOLUTION'] = [];
        }
      );
  }

  public getBusinessProfiles(tenantId) {
    this.dropdownValues['PROFILE'] = [];
    this.businessProfileControllerService
      .getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropdownValues['PROFILE'] = resp;
          } else {
            this.dropdownValues['PROFILE'] = [];
          }
        },
        err => {
          this.dropdownValues['PROFILE'] = [];
        }
      );
  }

  public getAccounts() {
    this.accountControllerService
      .findAllAccounts({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropdownValues['ACCOUNT'] = resp;
          } else {
            this.dropdownValues['ACCOUNT'] = [];
          }
        },
        err => {
          this.dropdownValues['ACCOUNT'] = [];
        }
      );
  }

  public getTenants(accountId) {
    this.tenantControllerService
      .findAllTenantsBasicByAccount({ accountId: accountId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropdownValues['TENANT'] = resp;
          } else {
            this.dropdownValues['TENANT'] = [];
          }
        },
        err => {
          this.dropdownValues['TENANT'] = [];
        }
      );
  }

  public updateDropdownValue(dropdownOption, event) {
    if (dropdownOption.childArray) {
      dropdownOption.childArray.forEach(child => {
        this.selectedValues[child] = null;
        this.dropdownValues[child] = null;
        this.accessKeyProperties['tenantAdminKey'] = null;
        this.multipleKeys = false;
      });
      this.resetEmailControls();
      this.dataSource = null;
    }
    if (dropdownOption.dropdownName === 'ACCOUNT' && event.value !== undefined) {
      this.getTenants(event.value);
    }
    if (dropdownOption.dropdownName === 'TENANT' && event.value !== undefined) {
      this.getBusinessProfiles(event.value);
    }
    if (dropdownOption.dropdownName === 'PROFILE' && event.value !== undefined) {
      this.businessProfileId = event.value;
      this.getSolutionType(event.value);
    }
    if (dropdownOption.dropdownName === 'SOLUTION' && event.value !== undefined) {
      this.solutionTypeId = event.value;
      this.getValuesForExpiryDuration();
      this.getAccessKey();
      this.getExpiryEmails();
      this.getMapKeys();
      this.selectedValues['solution'] = true;
    }
    if (dropdownOption === 'validity' && event.value !== undefined) {
      this.selectedValues['validity'] = event.value;
    }
  }

  public getAccessKey() {
    this.accessKeyFlags = {};
    this.subscriptionList = {};
    this.accesskeyLifeCycleControllerService.getAccessKeyDetails1({
      profileId: this.businessProfileId, solutionTypeId: this.solutionTypeId
    }).subscribe(resp => {
      this.accessKeyProperties['tenantAdminKey'] = true;
      const dataTable = [];
      resp.forEach( key => {
        dataTable.push({
          name: key['keyName'],
          accessKey: key['accessKey'],
          expiry: key['expiryDateTime'] ? this.changeDateFormat(key['expiryDateTime'].substring(0, 16)) : '',
          id: key['id'],
          validity: key['expiryDuration'],
          managerialServices: key['managerialServices']
        });
        this.getServiceList(key['id']);
      });
      this.columns = this.filterColumns(
        Object.keys(dataTable[0])
      );
      this.columns.push('action');
      this.dataSource = dataTable;
      this.noOfKeys = this.dataSource.length;
      this.multipleKeys = this.dataSource.length > 0;
      this.getAdditionalserviceList();
    }, err => {
      this.dataSource = null;
      this.noOfKeys = 0;
      this.multipleKeys = false;
      this.accessKeyProperties['tenantAdminKey'] = null;
    });
  }

  public gotoModal(content) {
    this._matDialog.open(content, { disableClose: true });
  }

  public onClose() {
    this._matDialog.closeAll();
  }

  public onUpdateSubscription(serviceId) {
    Object.entries(this.subscriptionList[serviceId]).forEach(([key, value]) => {
      if ( value === false && this.keysList.includes(key)) {
        this.showSpinner = this.loaderService.showProgressBar();
        this._matDialog.closeAll();
        this.accesskeyLifeCycleControllerService.subscribeServiceForProfile({
          accessKeyId: key, serviceId: serviceId
        }).subscribe(resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'SUBSCRIPTION_UPDATE_SUCCESS');
          this.getAccessKey();
        }, err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      }
      if ( value === true && !this.keysList.includes(key)) {
        this.showSpinner = this.loaderService.showProgressBar();
        this.accesskeyLifeCycleControllerService.unsubscribeServiceForProfile({
          accessKeyId: key, serviceId: serviceId
        }).subscribe(resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'SUBSCRIPTION_UPDATE_SUCCESS');
          this.getAccessKey();
        }, err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      }
    });
    this._matDialog.closeAll();
  }

  public additionalServiceSubscribe(type) {
    this.onClose();
    this.showSpinner = this.loaderService.showProgressBar();
    this.mapControllerService.subscribeMapServiceForProfile({
      profileId: this.businessProfileId,
      solutionTypeId: this.solutionTypeId,
      mode: type
    }).subscribe(resp => {
      if (resp) {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', resp.message);
        this.getAdditionalserviceList();
      }
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public subscriptionHandler(data, id) {
    this._matDialog.closeAll();
    switch (data) {
      case 'subscribe':
        if ( !(this.dataSource.length > 1) ) {
          Object.entries(this.subscriptionList[id]).forEach(([key, value]) => {
            this.keysList = value ? [] : [key];
          });
        }
        this.onUpdateSubscription(id);
        break;
      case 'managerial':
        this.onManagerialServicesClick();
    }
  }

  public additionalServiceUnsubscribe() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.mapControllerService.unsubscribeMapServiceForProfile({
      profileId: this.businessProfileId,
      solutionTypeId: this.solutionTypeId
    }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', resp.message);
      this.getAdditionalserviceList();

    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public generateAccessKey(event) {
    let duplicate = false;
    if ( this.dataSource ) {
      this.dataSource.forEach( row => {
        if (event.name === row.name) {
          duplicate = true;
        }
      });
    }
    if ( !duplicate ) {
      if ( !this.multipleKeys || this.noOfKeys < 5) {
        this.showSpinner = this.loaderService.showProgressBar();
        this.accesskeyLifeCycleControllerService.createAccessKeyForBusinessProfile({
          profileId: this.businessProfileId, solutionTypeId: this.solutionTypeId,
          expiryDuration: event.validity, keyName: event.name
        }).subscribe(resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'ACCESS_KEY_GENERATION_SUCCESS');
          this.getAccessKey();
        }, err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      } else {
        this.responseHandlerService.returnToastMessage('warning', 'MAXIMUM_KEYS_ADDED');
      }
      this.selectedValues['validity'] = null;
      this._matDialog.closeAll();
    } else {
      this.responseHandlerService.returnToastMessage('warning', 'DUPLICATE_KEY_NAME');
    }
  }

  public regenerateAccessKey(accessKey) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.accesskeyLifeCycleControllerService.regenerateAccessKeyForBusinessProfile({
      accessKeyId: accessKey.id,
      expiryDuration: accessKey.validity, action: 'REGENERATE'
    }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', 'ACCESS_KEY_REGENERATION_SUCCESS');
      this.getAccessKey();
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public getValuesForExpiryDuration() {
    this.accesskeyLifeCycleControllerService.getPossibleValuesForAccessKeyExpiry().subscribe(resp => {
      if (resp && resp.length) {
        this.dropdownValues['validity'] = resp;
      } else {
        this.dropdownValues['validity'] = [];
      }
    },
      err => {
        this.dropdownValues['validity'] = [];
      });
  }

  public deleteAccessKey(accessKey) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.accesskeyLifeCycleControllerService.deleteAccessKeyForBusinessProfile({
      accessKeyId: accessKey.id
    }).subscribe(resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('success', 'ACCESS_KEY_DELETE_SUCCESS');
      this.getAccessKey();
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }

  public gotoConfirmDialog(accessKey= null, mode?) {
    let message;
    switch (mode) {
      case 'regenerate': message = this.translate.translateErrorMessages('REGENERATE_ACCESS_KEY');
        break;
      case 'delete':
      message = 'DELETE_ACCESS_KEY';
      for (const list in this.subscriptionList) {
        if (this.subscriptionList[list][accessKey.id]) {
          message = 'DELETE_ACCESS_KEY_SUBSCRIBED';
        }
      }
        message = this.translate.translateErrorMessages(message);
        break;
    }
    const dialogRef = this._matDialog.open(ConfirmationDialogPopupComponent, {
      maxWidth: '400px',
      disableClose: true, data: { message: message }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (mode) {
          case 'regenerate': this.regenerateAccessKey(accessKey);
            break;
          case 'delete': this.deleteAccessKey(accessKey);
            break;
        }
      } else {
        this._matDialog.closeAll();
      }
    });
  }

  public showAccessKey(id) {
    this.showKey[id] = !this.showKey[id];
  }

  public onManagerialServicesClick() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.dataSource.forEach( row => {
    if ( row['managerialServices'] === false && this.keysList.includes(row.id)) {
      this.accesskeyLifeCycleControllerService.subscribeManagerialServicesForProfile({
        accessKeyId: row['id']
      }).subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'SUBSCRIBED_TO_MANAGERIAL_SERVICES');
        this.getAccessKey();
      }, err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
    } else if (row['managerialServices'] && !this.keysList.includes(row.id)) {
      this.accesskeyLifeCycleControllerService.unsubscribeManagerialServicesForProfile({
        accessKeyId: row['id']
      }).subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.getAccessKey();
        this.responseHandlerService.returnToastMessage('success', 'UNSUBSCRIBED_TO_MANAGERIAL_SERVICES');
      }, err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
    }
    });
    this._matDialog.closeAll();
  }

  public getMapKeys() {
    this.mapControllerService.getMapProjectsforProfileAndSolutionType({
      profileId: this.businessProfileId,
      solutionTypeId: this.solutionTypeId,
      active:true,
    }).subscribe(resp => {
      if (resp && resp['length']) {
        this.disableButton = true;
      } else {
        this.disableButton = false;
      }
    },
      err => {
        console.log(err);
      });
  }

  public gotoListMapKeysPage(accessKeyFlag: boolean) {
    this.router.navigate(['access-key/map-project-list', { businessProfileId: this.businessProfileId,
      solutionTypeId: this.solutionTypeId }]);
    }

  public filterColumns(columns = []) {
    if (Array.isArray(columns)) {
      return columns.filter((item) => this.ignoreList.indexOf(item) <= -1);
    }
  }

  public determineColumnSize(columnName) {
    if (columnName === 'accessKey') {
      return 'large-column';
    } else if (columnName === 'name') {
      return 'small-column';
    } else {
      return 'medium-column';
    }
  }

  public gotoEditDialog(content, event, eventData= null) {
    let data;
    this.keysList = [];
    switch (event) {
      case 'edit': data = {
        title : 'EDIT_ACCESS_KEY_NAME',
        body : 'edit'
      };
      this.editData['name'] = eventData['name'];
      this.editData['validity'] = eventData['validity'];
      this.accessKeyId = eventData['id'];
      break;
      case 'subscribe': data = {
        title : 'ACCESS_KEY_SUBSCRIPTION',
        body : 'subscribe',
        serviceId : eventData
      };
      Object.entries(this.subscriptionList[eventData]).forEach(([key, value], index) => {
        if ( value === true ) {
          this.keysList.push(key);
        }
      });
      break;
      case 'generate': data = {
        title : 'CREATE_ACCESS_KEY',
        body : 'generate'
      };
      if (!this.multipleKeys) {
        this.configureEmails();
      }
      break;
      case 'managerial':
      this.dataSource.forEach( row => {
        if ( row['managerialServices'] ) {
          this.keysList.push(row['id']);
        }
      });
      data = {
        title : 'MANAGERIAL_SERVICE_SUBSCRIPTION',
        body : 'managerial',
      };
    }
    this._matDialog.open(content, { maxHeight: '150vh', minWidth: '30vw', disableClose: true, data: data});
  }

  private createEmailFormControl() {
    return this.formBuilder.control('', [Validators.required,
    Validators.pattern('^[a-zA-Z0-9._]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*(\\.[a-zA-Z]{2,15})$')]);
  }

  public addEmailControl() {
    const emailControl = this.createEmailFormControl();
    (<UntypedFormArray>this.generateForm.get('emailList')).push(emailControl);
  }

  public removeEmail(index: number) {
    (<UntypedFormArray>this.generateForm.get('emailList')).removeAt(index);
  }

  public resetEmailControls() {
    this.generateForm.reset();
    while (this.generateForm.get('emailList')['controls'].length !== 0) {
      this.removeEmail(0);
    }
    this.addEmailControl();
  }

  public changeDateFormat(dateTime) {
    if (dateTime) {
      const splitDateTime = dateTime.split('T');
      const splitDate = splitDateTime[0].split('-');
      const time = splitDateTime[1];
      return `${splitDate[2].split(' ')[0]}-${splitDate[1]}-${splitDate[0]} ${time}`;
    }
  }

  public editAccessKey(event) {
    let duplicate;
    let action;
    this.dataSource.forEach( row => {
      if (event.name === row.name) {
        duplicate = true;
      }
    });
      if (event.validity !== this.editData['validity']) {
        action = 'RESET';
      } else if (event.name !== this.editData['name'] && !duplicate) {
        action = 'KEYNAMECHANGE';
      } else if (event.name !== this.editData['name'] && duplicate) {
        this.responseHandlerService.returnToastMessage('warning', 'DUPLICATE_KEY_NAME');
       }

      if (action) {
        this.accesskeyLifeCycleControllerService.regenerateAccessKeyForBusinessProfile({
          accessKeyId: this.accessKeyId, expiryDuration: event.validity, action: action,
          keyName: event.name
        }).subscribe(resp => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('success', 'KEY_EDIT_SUCCESS');
          this.getAccessKey();
        }, err => {
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
      }
    this._matDialog.closeAll();

  }

  public configureEmails() {
    if ( this.generateForm.status === 'VALID' ) {
      this.accesskeyLifeCycleControllerService.configureExpiryEmailIds({
        profileId: this.businessProfileId, solutionTypeId: this.solutionTypeId,
      body: this.generateForm.value.emailList}).subscribe(resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'EMAIL_CONFIGURED_SUCCESFULLY');
      }, err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
    }
  }

  public getExpiryEmails() {
    this.accesskeyLifeCycleControllerService.getTenantAdminExpiryEmailIds({
      profileId: this.businessProfileId, solutionTypeId: this.solutionTypeId
    }).subscribe( resp => {
      resp.forEach( (id, index) => {
        if ( index > 0) {
          this.addEmailControl();
        }
      });
      this.generateForm.get('emailList').setValue(resp);
    }, err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    });
  }
}
