/**
 * Copyright © 2021-22 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
   NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private
   Limited. Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is
   obtained from Robert Bosch Engineering and Business Solutions Private Limited.
*/

import { Component, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from '../../shared/aside-nav-card/aside-nav-card.component';
import {
  DeviceEnrollmentControllerService, DeviceTypeControllerService, DeviceMgmtUtilityControllerService
} from 'src/app/services/DeviceMgmt/services';
import { AccountControllerService, BusinessProfileControllerService, PlatformAdminUtilityControllerService,
  SolutionTypeControllerService,
  TenantControllerService } from 'src/app/services/Platform/services';
import { CREATE, CERTIFICATE_ICON, VALIDITY_LEFT_MSG, CLOSE_ICON } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ObjectToIdConversionService } from 'src/app/providers/object-to-id-conversion-service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { EnvService } from 'src/app/env.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificateManagementControllerService } from 'src/app/services/Certificate/services/certificate-management-controller.service';
import { HttpClient } from '@angular/common/http';
import { CertificateMgmtUtilityControllerService } from 'src/app/services/Certificate/services';
import { MatDialog } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { BciLoaderService } from '@bci-web-core/core';

/**
  * @ngdoc component
  * @name certificate.component:createCertificates
  *
  *
  * @description
  * description: To create a new root, intermediate and leaf.
  *
  *
*/
@Component({
  selector: 'app-create-certificates',
  templateUrl: './create-certificates.component.html',
  styleUrls: ['./create-certificates.component.scss']
})
export class CreateCertificatesComponent implements OnInit {

  public jsonFileLoaded;
  public showSpinner :any;
  public pageKey: string;
  public dropDownValues: any = {};
  public certificateIcon: string;
  public algoRithRSAValues: any;
  public algoRithDSAValues: any;
  public algoRithECValues: any;
  public keySizeEC: any;
  public keySizeRSA: any;
  public keySizeDSA: any;
  public selectedCountry: string;
  public selectedState: string;
  public matTabOptions = [];
  public currentTabValue;
  public formData: any = {};
  public data;
  public solutionTypeId;
  public permissionList: Array<String> = [];
  public displayInfoMsg = false;
  public showActionButtons = false;
  public certificateId;
  public certificateName;
  public index: number;
  public validityMsg;
  submitted = false;
  verificationForm: UntypedFormGroup;
  asideMenus: Array<AsideCard>;
  public closeIcon: string;
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  constructor(private businessProfileControllerService: BusinessProfileControllerService,
    private tenantControllerService: TenantControllerService, private httpService: HttpClient,
    private deviceEnrollmentControllerService: DeviceEnrollmentControllerService,
    private accountControllerService: AccountControllerService, private deviceTypeControllerService: DeviceTypeControllerService,
    private deviceMgmtUtilityControllerService: DeviceMgmtUtilityControllerService, private responseHandlerService: ResponseHandlerService,
    private rightsideNavitemsService: RightsideNavitemsService, private objectToIdConversionService: ObjectToIdConversionService,
    private platformAdminUtilityControllerService: PlatformAdminUtilityControllerService,
    private solutionTypeControllerService: SolutionTypeControllerService, private route: ActivatedRoute,
    private env: EnvService, private router: Router, private certificateManagementControllerService: CertificateManagementControllerService,
    private certificateMgmtUtilityControllerService: CertificateMgmtUtilityControllerService, private formBuilder: UntypedFormBuilder,
    private _matDialog: MatDialog,private loaderService: BciLoaderService) { }

  ngOnInit() {
    this.pageKey = 'certificate';
    this.rightsideNavitemsService.getRightsideNavItems(CREATE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.certificateIcon = CERTIFICATE_ICON;
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    this.validityMsg = VALIDITY_LEFT_MSG;
    this.closeIcon = CLOSE_ICON;
    this.getSolutionType();
    if ((this.permissionList.indexOf('R_CERT_C') > -1) && (this.permissionList.indexOf('I_CERT_C') > -1)
    && (this.permissionList.indexOf('L_CERT_C') > -1) ) {
      this.matTabOptions.push({ 'label': 'Root', 'value': 'root' });
      this.matTabOptions.push({ 'label': 'Intermediate', 'value': 'intermediate' });
      this.matTabOptions.push({ 'label': 'Leaf', 'value': 'leaf' });
      this.currentTabValue = 'root';
      this.jsonFileLoaded = 'root-certificate';
      this.displayInfoMsg = false;
    }
    if ((this.permissionList.indexOf('R_CERT_C') === -1) && (this.permissionList.indexOf('I_CERT_C') > -1)
    && (this.permissionList.indexOf('L_CERT_C') > -1)) {
      this.matTabOptions.push({ 'label': 'Intermediate', 'value': 'intermediate' });
      this.matTabOptions.push({ 'label': 'Leaf', 'value': 'leaf' });
      this.currentTabValue = 'intermediate';
      this.jsonFileLoaded = 'intermediate-certificate';
      this.displayInfoMsg = true;
    }
    this.verificationForm = this.formBuilder.group({
      verificationCode: ['', [Validators.required, Validators.maxLength(100)]]
    });
  }

/**
    * @ngdoc method
    * @name CreateCertificates#getSolutionType
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * gets the list of solution types using GET method
    *
    * @param {type} null
    * @return {type} null
  */
  /* @description: to populate solution type dropdown */
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
            } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }
  /**
    * @ngdoc method
    * @name CreateCertificates#updateDropdownValues
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: To populate dependent dropdown.
    *
    * @param {type} dropdown select event
    * @return {type} null
  */
 public updateDropdownValues($event) {
  if ($event.value !== undefined) {
    if ($event.child === 'businessProfile') {
      this.getBusinessProfileOnSolution($event.value);
      this.getCertificateDetailsData();
      this.getCountry();
      this.solutionTypeId = $event.value;
    }
    if ($event.child === 'parentCertificate') {
      if (this.currentTabValue === 'intermediate' || this.currentTabValue === 'leaf') {
        this.findRootAndIntermediateCert(this.solutionTypeId,  $event.value);
        this.dynamicForm.validityMessage = '';
      }
    }
    if ($event.child === 'country') {
      this.getExistingCertDetails(this.solutionTypeId, $event.value);
    }
    if ($event.child === 'state') {
      this.getState($event.value.id);
      this.selectedCountry = $event.value.id;
    }
    if ($event.child === 'keyAlgorithm') {
      this.selectedState = $event.value.id;
    }
    if ($event.child === 'keySize') {
      if ($event.value === 'DSA') {
        this.dropDownValues['keySize'] = this.keySizeDSA;
        this.dropDownValues['signatureAlgorithm'] = this.algoRithDSAValues;
      } else if ($event.value === 'RSA') {
        this.dropDownValues['keySize'] = this.keySizeRSA;
        this.dropDownValues['signatureAlgorithm'] = this.algoRithRSAValues;
      } else if ($event.value === 'EC') {
        this.dropDownValues['keySize'] = this.keySizeEC;
        this.dropDownValues['signatureAlgorithm'] = this.algoRithECValues;
      }
    }
    if ($event.child === 'validity' && $event.value && this.dropDownValues['parentCertificate']) {
      this.dynamicForm.validityMessage = this.validityMsg + ' ' + $event.value.validityLeft + ' days';
    }
  }
}
/**
    * @ngdoc method
    * @name CreateCertificates#getBusinessProfileOnSolution
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: To populate profile dropdown.
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
 public getBusinessProfileOnSolution(solutionTypeId: string) {
  this.solutionTypeControllerService
    .findByBusinessProfilesListBasicForSolutionTypeId({ solutionTypeId: solutionTypeId })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['businessProfile'] = resp;
        } else {
          this.dropDownValues['businessProfile'] = [];
        }
      },
      err => {
        this.dropDownValues['businessProfile'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}
public getExistingCertDetails(solutionId: string, profileId: string) {
  this.certificateManagementControllerService.getRootCertificate({solutionId, profileId})
  .subscribe(
    resp => {
      if (resp) {
        this.responseHandlerService.returnToastMessage('warning', 'EXISTING_ROOTCERT_INFO');
        this.getState(resp['country']['id']);
        this.formData['keyAlgorithm'] =  resp['keyAlgorithm']['id'];
        if ( resp['keyAlgorithm']['id'] === 'DSA') {
          this.dropDownValues['keySize'] = this.keySizeDSA;
          this.dropDownValues['signatureAlgorithm'] = this.algoRithDSAValues;
        } else if ( resp['keyAlgorithm']['id'] === 'RSA') {
          this.dropDownValues['keySize'] = this.keySizeRSA;
          this.dropDownValues['signatureAlgorithm'] = this.algoRithRSAValues;
        } else if ( resp['keyAlgorithm']['id'] === 'EC') {
          this.dropDownValues['keySize'] = this.keySizeEC;
          this.dropDownValues['signatureAlgorithm'] = this.algoRithECValues;
        }
        this.formData['solutionType'] = resp['solutionType']['id'];
        this.formData['businessProfile'] = resp['businessProfile']['id'];
        this.formData['commonName'] =  resp['commonName'];
        this.formData['organization'] =  resp['organization'];
        this.formData['location'] =  resp['location'];
        this.formData['keySize'] =  resp['keySize']['id'];
        this.formData['keyAlgorithm'] =  resp['keyAlgorithm']['id'];
        this.formData['validity'] =  resp['validity']['id'];
        this.formData['country'] =  resp['country']['id'];
        this.formData['signatureAlgorithm'] =  resp['signatureAlgorithm']['id'];
        this.formData['state'] =  resp['state']['id'];
        this.dynamicForm.createForm.patchValue(this.formData);
        this.dynamicForm.createForm.disable();
        this.dynamicForm.submitButton['disabled'] = true;
        this.certificateId = resp['id'];
        this.certificateName = resp['name'];
        this.showActionButtons = true;
      }
    },
    err => {
      this.showActionButtons = false;
      this.dynamicForm.createForm.enable();
      this.dynamicForm.submitButton['disabled'] = false;
      this.getCertificateDetailsData();
    }
  );
}
/**
    * @ngdoc method
    * @name CreateCertificates#getCertificateDetailsData
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: To populate keyLengths, keyAlgorithms and certificateTypes dropdown.
    *
    * @param {type} solutionTypeId
    * @return {type} null
  */
public getCertificateDetailsData() {
 this.certificateMgmtUtilityControllerService.getListOfValues().subscribe(
  resp => {
    if (resp) {
      if (resp.keySize.RSA && resp.keySize.DSA && resp.keySize.EC) {
        this.keySizeRSA = resp.keySize.RSA;
        this.keySizeDSA = resp.keySize.DSA;
        this.keySizeEC = resp.keySize.EC;
      }
      if (resp.keyAlgorithm.length) {
        this.dropDownValues['keyAlgorithm'] = resp.keyAlgorithm;
      }
      if (resp.signatureAlgorithm.RSA && resp.signatureAlgorithm.DSA && resp.signatureAlgorithm.EC) {
        this.algoRithDSAValues = resp.signatureAlgorithm.DSA;
        this.algoRithRSAValues = resp.signatureAlgorithm.RSA;
        this.algoRithECValues = resp.signatureAlgorithm.EC;
      }
      if (resp['validityList']) {
        this.dropDownValues['validity'] = resp['validityList'];
      }
    } else {
      this.dropDownValues['keySize'] = [];
      this.dropDownValues['keyAlgorithm'] = [];
      this.dropDownValues['certificateTypes'] = [];
      this.algoRithDSAValues = null;
      this.algoRithRSAValues = null;
    }
  },
  err => {
    this.dropDownValues['keySize'] = [];
      this.dropDownValues['keyAlgorithm'] = [];
      this.dropDownValues['certificateTypes'] = [];
    this.responseHandlerService.returnToastMessage('error', err.error.message);
  }
);
}
/**
  * @ngdoc method
  * @name CreateCertificates#getCountry
  *
  * @methodOf
  * TenantsModule.controller:createCertificates
  *
  * @description
  * Description: to populate country dropdown
  *
  * @param {type} name description
  * @return {type} name description
*/
public getCountry() {
  this.platformAdminUtilityControllerService
    .getAllCountries()
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['country'] = resp;
        } else {
          this.dropDownValues['country'] = [];
        }
      },
      err => {
        this.dropDownValues['country'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}

  /**
 * @ngdoc method
 * @name CreateCertificates#getState
 *
 * @methodOf
 * TenantsModule.controller:createCertificates
 *
 * @description
 * Description: to populate state dropdown based on selected country
 *
 * @param {type} name description : name of the selected country
 * @return {type} name description
*/
public getState(countryName: string) {
  this.platformAdminUtilityControllerService
    .getAllStateOfCountry({ id: countryName })
    .subscribe(
      resp => {
        if (resp && resp.length) {
          this.dropDownValues['state'] = resp;
        } else {
          this.dropDownValues['state'] = [];
        }
      },
      err => {
        this.dropDownValues['state'] = [];
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
}

/**
    * @ngdoc method
    * @name CreateCertificates#matChange
    *
    * @methodOf
    * device.controller:createCertificates
    *
    * @description
    * to trigger action like fetching data in case tab changes.
    *
    * @param {type} click event
    * @return {type} null
  */
 public matChange(event) {
  this.data = null;
  this.currentTabValue = this.matTabOptions[event.index].value;
    if (this.currentTabValue === 'root') {
      this.changeTabs('root-certificate', 'root');
      this.displayInfoMsg = false;
      this.dropDownValues['keySize'] = [];
      this.dropDownValues['country'] = [];
      this.dropDownValues['state'] = [];
      this.dropDownValues['keyAlgorithm'] = [];
      this.dropDownValues['signatureAlgorithm'] = [];
    } else if (this.currentTabValue === 'intermediate') {
      this.changeTabs('intermediate-certificate', 'intermediate');
      this.displayInfoMsg = true;
      if (this.dropDownValues['parentCertificate']) {
        this.dropDownValues['parentCertificate'] = [];
      }
    } else if (this.currentTabValue === 'leaf') {
      this.changeTabs('leaf-certificate', 'leaf');
      this.displayInfoMsg = true;
      if (this.dropDownValues['parentCertificate']) {
        this.dropDownValues['parentCertificate'] = [];
      }
  }
}
/**
    * @ngdoc method
    * @name CreateCertificates#changeTabs
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: A helper function to set current tab name and json file to be loaded for selected tab
    *
    * @param {type} djsonFileTobeLoadedata, tabName
    * @return {type} null
  */
public changeTabs(jsonFileTobeLoaded, tabName) {
  this.dynamicForm.submitted = false;
  this.dynamicForm.createForm.enable();
  this.dynamicForm.createForm.reset();
  this.currentTabValue = tabName;
  this.jsonFileLoaded = jsonFileTobeLoaded;
  this.dynamicForm.jsonFile = jsonFileTobeLoaded;
  this.dynamicForm.ngOnInit();
  this.getSolutionType();
  this.dropDownValues['businessProfile'] = [];
  this.dropDownValues['validity'] = [];
  this.dynamicForm.submitButton['disabled'] = false;
  this.showActionButtons = false;
  this.dynamicForm.validityMessage = '';
}
/**
* @ngdoc method
* @name CreateCertificates#openModal
*
* @methodOf
* firmware.controller:createCertificates
*
* @description
* Description: Opens up the modal to select and attach the tags
* @param {type} content
* @return {type} null
*/
public openModal(content) {
  this.submitted = false;
  this.verificationForm.reset();
  this._matDialog.open(content, { maxHeight: '200vh', minWidth: '30vw', disableClose: true, data: null });
}
 /**
    * @ngdoc method
    * @name CreateCertificates#onClose
    *
    * @methodOf
    * device.controller:createCertificates
    *
    * @description
    * Description: To close the popup.
    *
    * @param {type} null
    * @return {type} null
  */
 public onClose() {
  this._matDialog.closeAll();
}
/**
    * @ngdoc method
    * @name CreateCertificates#findRootAndIntermediateCert
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: A helper function to set current tab name and json file to be loaded for selected tab
    *
    * @param {type} solutionTypeID,  profileId  solutionId: string;
    profileId: string;
    * @return {type} null
  */
 public findRootAndIntermediateCert(solutionTypeId, profileId) {
  this.certificateManagementControllerService.findRootAndIntermediateCert({solutionId: solutionTypeId, profileId: profileId}).subscribe(
    resp => {
      if (resp && resp.length) {
      this.dropDownValues['parentCertificate'] = resp;
      } else {
        this.dropDownValues['parentCertificate'] = [];
      }
    },
    err => {
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    }
  );
}
/**
    * @ngdoc method
    * @name CreateCertificates#createCertificate
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: creates root, intermediate and leaf certificate
    *
    * @param {type} data {any}
    * @return {type} null
  */
public createCertificate(data: any) {
  this.showSpinner = this.loaderService.showProgressBar();
  const bodyJSON = data;
  bodyJSON['solutionType'] = this.objectToIdConversionService.convertObjectToId(data['solutionType']);
  bodyJSON['businessProfile'] = this.objectToIdConversionService.convertObjectToId(data['businessProfile']);
  bodyJSON['parentCertificate'] = this.objectToIdConversionService.convertObjectToId(data['parentCertificate']);
  bodyJSON['validity'] = this.objectToIdConversionService.convertObjectToId(data['validity']);
  if (this.currentTabValue === 'root') {
  bodyJSON['commonName'] = data['commonName'];
  bodyJSON['organization'] = data['organization'];
  bodyJSON['location'] = data['location'];
  bodyJSON['country'] = this.objectToIdConversionService.convertObjectToId(this.selectedCountry);
  bodyJSON['state'] =  this.objectToIdConversionService.convertObjectToId(this.selectedState);
  bodyJSON['keySize'] = this.objectToIdConversionService.convertObjectToId(data['keySize']);
  bodyJSON['keyAlgorithm'] = this.objectToIdConversionService.convertObjectToId(data['keyAlgorithm']);
  bodyJSON['signatureAlgorithm'] = this.objectToIdConversionService.convertObjectToId(data['signatureAlgorithm']);
  this.certificateManagementControllerService.createRootCertificate({body: bodyJSON}).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'CERTIFICATE_CREATED_SUCCESSFULLY');
        this.dynamicForm.createForm.reset();
        this.solutionTypeId = null;
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  } else if (this.currentTabValue === 'intermediate') {
      this.certificateManagementControllerService.createIntermediateCertificate({body:  bodyJSON}).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'CERTIFICATE_CREATED_SUCCESSFULLY');
        this.dynamicForm.createForm.reset();
        this.solutionTypeId = null;
        this.dropDownValues['parentCertificate'] = [];
        this.dynamicForm.validityMessage = '';
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  } else {
    this.certificateManagementControllerService.createLeafCertificate({body:  bodyJSON}).subscribe(
      resp => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('success', 'CERTIFICATE_CREATED_SUCCESSFULLY');
        this.dynamicForm.createForm.reset();
        this.solutionTypeId = null;
        this.dropDownValues['parentCertificate'] = [];
        this.dynamicForm.validityMessage = '';
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      }
    );
  }
}
/**
    * @ngdoc method
    * @name CreateCertificates#downloadRootCertificate
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: To download existing root certificate
    *
    * @param {type} null
    * @return {type} null
  */
public downloadRootCertificate() {
  this.showSpinner = this.loaderService.showProgressBar();
  this.certificateManagementControllerService.downloadCertificateZip({certId: this.certificateId}).subscribe(
    resp => {
      this.loaderService.hideProgressBar(this.showSpinner);
      if (resp) {
        this.downloadFile(resp, this.generateFilename(this.certificateName, 'zip'));
      }
    },
    err => {
      this.loaderService.hideProgressBar(this.showSpinner);
      this.responseHandlerService.returnToastMessage('error', err.error.message);
    }
  );
}

/**
    * @ngdoc method
    * @name CreateCertificates#verifyCertificate
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: To download validity certificate
    *
    * @param {type} data {any}
    * @return {type} null
  */
public verifyCertificate(verificationToken) {
  if (verificationToken) {
    this.showSpinner = this.loaderService.showProgressBar();
    this.certificateManagementControllerService.generateValidityCertificate({rootCertId: this.certificateId,
    verificationToken: verificationToken}).subscribe(
      resp => {
          this.downloadFile(resp, this.generateFilename(this.certificateName, '-ValidityCertificate.zip'));
          this.loaderService.hideProgressBar(this.showSpinner);
          this.onClose();
          this.submitted = false;
      },
      err => {
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
        this.onClose();
        this.submitted = false;
      }
    );
  }
}


/**
    * @ngdoc method
    * @name CreateCertificates#downloadFile
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: Helper method to convert responce of download certificateapi into downloadable file
    *
    * @param {type} data {any}, fileName {string}
    * @return {type} null
*/
 public downloadFile(data, fileName) {
  const FileSaver = require('file-saver');
  const blob = new Blob([data], { type: 'application/zip' });
  FileSaver.saveAs(blob, fileName);
}

/**
    * @ngdoc method
    * @name CreateCertificates#generateFilename
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: Helper method to generate file name as 'certificatename.zip' or 'certificatename-ValidityCertificate.zip'.
    *
    * @param {type} filename {string}, format {zip}
    * @return {type} null
*/
public generateFilename(fileName, format) {
  if (fileName && format && format === 'zip') {
    return (fileName + '.' + format);
  } else if (fileName && format && format === '-ValidityCertificate.zip') {
    return (fileName + format);
  }
}
/**
    * @ngdoc method
    * @name CreateCertificates#verifyCertificate
    *
    * @methodOf
    * certificate.component:createCertificates
    *
    * @description
    * Description: To download validity certificate
    *
    * @param {type} data {any}
    * @return {type} null
  */
public resetForm($event) {
  if ($event) {
    this.dynamicForm.createForm.reset();
    this.dynamicForm.createForm.enable();
    this.showActionButtons = false;
    this.dynamicForm.submitButton['disabled'] = false;
    this.dynamicForm.validityMessage = '';
    this.dropDownValues['businessProfile'] = [];
    this.dropDownValues['keySize'] = [];
    this.dropDownValues['country'] = [];
    this.dropDownValues['state'] = [];
    this.dropDownValues['keyAlgorithm'] = [];
    this.dropDownValues['signatureAlgorithm'] = [];
    this.dropDownValues['parentCertificate'] = [];
    this.dropDownValues['validity'] = [];
  }
}
 /**
      * @ngdoc method
      * @name CreateCertificate#onSubmit
      *
      * @methodOf
      * fota.controller:createCertificate
      *
      * @description
      * Description: generate validation certificate.
      *
      * @param {type} null
      * @return {type} null
  */
 onSubmit() {
  this.submitted = true;
  if (this.verificationForm.valid) {
  const body = this.verificationForm.value;
  this.verifyCertificate(body['verificationCode']);
 }
}
}
