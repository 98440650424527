/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MongoSubscriptionMetadataDto } from '../models/mongo-subscription-metadata-dto';
import { OrganaizationDto } from '../models/organaization-dto';
import { SubscriptionDto } from '../models/subscription-dto';

@Injectable({
  providedIn: 'root',
})
export class InfrastructureConfigurationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation addOrganaisationDetails
   */
  static readonly AddOrganaisationDetailsPath = '/api/v1/mongo/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOrganaisationDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrganaisationDetails$Response(params?: {
    body?: OrganaizationDto
  }): Observable<StrictHttpResponse<OrganaizationDto>> {

    const rb = new RequestBuilder(this.rootUrl, InfrastructureConfigurationControllerService.AddOrganaisationDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganaizationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addOrganaisationDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addOrganaisationDetails(params?: {
    body?: OrganaizationDto
  }): Observable<OrganaizationDto> {

    return this.addOrganaisationDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OrganaizationDto>) => r.body as OrganaizationDto)
    );
  }

  /**
   * Path part for operation editOrganaisationDetails
   */
  static readonly EditOrganaisationDetailsPath = '/api/v1/mongo/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editOrganaisationDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editOrganaisationDetails$Response(params?: {
    body?: OrganaizationDto
  }): Observable<StrictHttpResponse<OrganaizationDto>> {

    const rb = new RequestBuilder(this.rootUrl, InfrastructureConfigurationControllerService.EditOrganaisationDetailsPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganaizationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editOrganaisationDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editOrganaisationDetails(params?: {
    body?: OrganaizationDto
  }): Observable<OrganaizationDto> {

    return this.editOrganaisationDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OrganaizationDto>) => r.body as OrganaizationDto)
    );
  }

  /**
   * Path part for operation getSubscriptionListByProfileId
   */
  static readonly GetSubscriptionListByProfileIdPath = '/api/v1/profile/{profileId}/subscription/mongo';

  /**
   * Manage Mongo Organization List.
   *
   * The API to view the mongo  list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubscriptionListByProfileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionListByProfileId$Response(params: {
    profileId: string;
    month?: number;
    year?: number;
  }): Observable<StrictHttpResponse<Array<MongoSubscriptionMetadataDto>>> {

    const rb = new RequestBuilder(this.rootUrl, InfrastructureConfigurationControllerService.GetSubscriptionListByProfileIdPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.query('month', params.month, {});
      rb.query('year', params.year, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MongoSubscriptionMetadataDto>>;
      })
    );
  }

  /**
   * Manage Mongo Organization List.
   *
   * The API to view the mongo  list
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSubscriptionListByProfileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubscriptionListByProfileId(params: {
    profileId: string;
    month?: number;
    year?: number;
  }): Observable<Array<MongoSubscriptionMetadataDto>> {

    return this.getSubscriptionListByProfileId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MongoSubscriptionMetadataDto>>) => r.body as Array<MongoSubscriptionMetadataDto>)
    );
  }

  /**
   * Path part for operation addSubscriptionDetails
   */
  static readonly AddSubscriptionDetailsPath = '/api/v1/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSubscriptionDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSubscriptionDetails$Response(params?: {
    body?: SubscriptionDto
  }): Observable<StrictHttpResponse<SubscriptionDto>> {

    const rb = new RequestBuilder(this.rootUrl, InfrastructureConfigurationControllerService.AddSubscriptionDetailsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `addSubscriptionDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSubscriptionDetails(params?: {
    body?: SubscriptionDto
  }): Observable<SubscriptionDto> {

    return this.addSubscriptionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionDto>) => r.body as SubscriptionDto)
    );
  }

  /**
   * Path part for operation editSubscription
   */
  static readonly EditSubscriptionPath = '/api/v1/subscription';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editSubscription()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSubscription$Response(params?: {
    body?: SubscriptionDto
  }): Observable<StrictHttpResponse<SubscriptionDto>> {

    const rb = new RequestBuilder(this.rootUrl, InfrastructureConfigurationControllerService.EditSubscriptionPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubscriptionDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editSubscription$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSubscription(params?: {
    body?: SubscriptionDto
  }): Observable<SubscriptionDto> {

    return this.editSubscription$Response(params).pipe(
      map((r: StrictHttpResponse<SubscriptionDto>) => r.body as SubscriptionDto)
    );
  }

}
