/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CommonDetails } from '../models/common-details';
import { CountryDto } from '../models/country-dto';
import { DeviceInterfaceProviderDto } from '../models/device-interface-provider-dto';
import { EventHubDetailsBto } from '../models/event-hub-details-bto';
import { InterfaceTypeDto } from '../models/interface-type-dto';
import { MenuDto } from '../models/menu-dto';
import { MessageBto } from '../models/message-bto';
import { RealmDto } from '../models/realm-dto';
import { SettingCommonDto } from '../models/setting-common-dto';
import { StateDto } from '../models/state-dto';
import { UserDetailsDto } from '../models/user-details-dto';

@Injectable({
  providedIn: 'root',
})
export class PlatformAdminUtilityControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation encodeText
   */
  static readonly EncodeTextPath = '/api/v1/encodeText';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `encodeText()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  encodeText$Response(params?: {
    body?: MessageBto
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.EncodeTextPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `encodeText$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  encodeText(params?: {
    body?: MessageBto
  }): Observable<MessageBto> {

    return this.encodeText$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

  /**
   * Path part for operation getAllStateOfCountry
   */
  static readonly GetAllStateOfCountryPath = '/api/v1/country/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllStateOfCountry()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStateOfCountry$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<Array<StateDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetAllStateOfCountryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<StateDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllStateOfCountry$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllStateOfCountry(params: {
    id: string;
  }): Observable<Array<StateDto>> {

    return this.getAllStateOfCountry$Response(params).pipe(
      map((r: StrictHttpResponse<Array<StateDto>>) => r.body as Array<StateDto>)
    );
  }

  /**
   * Path part for operation getBusinessProfileIdByName
   */
  static readonly GetBusinessProfileIdByNamePath = '/api/v1/reference/businessProfile/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBusinessProfileIdByName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusinessProfileIdByName$Response(params: {
    name: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetBusinessProfileIdByNamePath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBusinessProfileIdByName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBusinessProfileIdByName(params: {
    name: string;
  }): Observable<string> {

    return this.getBusinessProfileIdByName$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getAvailableRealmsList
   */
  static readonly GetAvailableRealmsListPath = '/api/v1/freeRealmsList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAvailableRealmsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableRealmsList$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RealmDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetAvailableRealmsListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RealmDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAvailableRealmsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAvailableRealmsList(params?: {
  }): Observable<Array<RealmDto>> {

    return this.getAvailableRealmsList$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RealmDto>>) => r.body as Array<RealmDto>)
    );
  }

  /**
   * Path part for operation encodeMessage
   */
  static readonly EncodeMessagePath = '/api/v1/encodeMessage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `encodeMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  encodeMessage$Response(params?: {
    body?: MessageBto
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.EncodeMessagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `encodeMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  encodeMessage(params?: {
    body?: MessageBto
  }): Observable<MessageBto> {

    return this.encodeMessage$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

  /**
   * Path part for operation deviceInterfaceProviders
   */
  static readonly DeviceInterfaceProvidersPath = '/api/v1/deviceinterfaceprovider';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deviceInterfaceProviders()` instead.
   *
   * This method doesn't expect any request body.
   */
  deviceInterfaceProviders$Response(params: {
    solutionTypeId: string;
    profileId: string;
    active?: string;
  }): Observable<StrictHttpResponse<Array<DeviceInterfaceProviderDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.DeviceInterfaceProvidersPath, 'get');
    if (params) {
      rb.query('solutionTypeId', params.solutionTypeId, {});
      rb.query('profileId', params.profileId, {});
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DeviceInterfaceProviderDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deviceInterfaceProviders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deviceInterfaceProviders(params: {
    solutionTypeId: string;
    profileId: string;
    active?: string;
  }): Observable<Array<DeviceInterfaceProviderDto>> {

    return this.deviceInterfaceProviders$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DeviceInterfaceProviderDto>>) => r.body as Array<DeviceInterfaceProviderDto>)
    );
  }

  /**
   * Path part for operation getUserAccountDetails
   */
  static readonly GetUserAccountDetailsPath = '/api/v1/useraccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserAccountDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAccountDetails$Response(params?: {
  }): Observable<StrictHttpResponse<UserDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetUserAccountDetailsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<UserDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserAccountDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserAccountDetails(params?: {
  }): Observable<UserDetailsDto> {

    return this.getUserAccountDetails$Response(params).pipe(
      map((r: StrictHttpResponse<UserDetailsDto>) => r.body as UserDetailsDto)
    );
  }

  /**
   * Path part for operation getAccessTokenBySolutionTypeAndBusinessProfile
   */
  static readonly GetAccessTokenBySolutionTypeAndBusinessProfilePath = '/api/v1/accessKeyByBusinessAndSolution/{profile}/{solutionType}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessTokenBySolutionTypeAndBusinessProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessTokenBySolutionTypeAndBusinessProfile$Response(params: {
    profile: string;
    solutionType: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetAccessTokenBySolutionTypeAndBusinessProfilePath, 'get');
    if (params) {
      rb.path('profile', params.profile, {});
      rb.path('solutionType', params.solutionType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessTokenBySolutionTypeAndBusinessProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessTokenBySolutionTypeAndBusinessProfile(params: {
    profile: string;
    solutionType: string;
  }): Observable<string> {

    return this.getAccessTokenBySolutionTypeAndBusinessProfile$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation settings
   */
  static readonly SettingsPath = '/api/v1/menu-settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settings()` instead.
   *
   * This method doesn't expect any request body.
   */
  settings$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MenuDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.SettingsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MenuDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `settings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settings(params?: {
  }): Observable<Array<MenuDto>> {

    return this.settings$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MenuDto>>) => r.body as Array<MenuDto>)
    );
  }

  /**
   * Path part for operation getAccessKey
   */
  static readonly GetAccessKeyPath = '/api/v1/cert/accessKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccessKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKey$Response(params?: {
  }): Observable<StrictHttpResponse<{ [key: string]: string }>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetAccessKeyPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: string }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAccessKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccessKey(params?: {
  }): Observable<{ [key: string]: string }> {

    return this.getAccessKey$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: string }>) => r.body as { [key: string]: string })
    );
  }

  /**
   * Path part for operation findAllInterfaceTypes
   */
  static readonly FindAllInterfaceTypesPath = '/api/v1/interfaceType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllInterfaceTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllInterfaceTypes$Response(params?: {
    active?: string;
  }): Observable<StrictHttpResponse<Array<InterfaceTypeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.FindAllInterfaceTypesPath, 'get');
    if (params) {
      rb.query('active', params.active, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<InterfaceTypeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllInterfaceTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllInterfaceTypes(params?: {
    active?: string;
  }): Observable<Array<InterfaceTypeDto>> {

    return this.findAllInterfaceTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<InterfaceTypeDto>>) => r.body as Array<InterfaceTypeDto>)
    );
  }

  /**
   * Path part for operation resetEula
   */
  static readonly ResetEulaPath = '/api/v1/users/resetEulaFlags/{realm}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetEula()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetEula$Response(params: {
    realm: string;
  }): Observable<StrictHttpResponse<{  }>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.ResetEulaPath, 'post');
    if (params) {
      rb.path('realm', params.realm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{  }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `resetEula$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetEula(params: {
    realm: string;
  }): Observable<{  }> {

    return this.resetEula$Response(params).pipe(
      map((r: StrictHttpResponse<{  }>) => r.body as {  })
    );
  }

  /**
   * Path part for operation getCountOfAllUsersByActiveStatus
   */
  static readonly GetCountOfAllUsersByActiveStatusPath = '/api/v1/users/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCountOfAllUsersByActiveStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountOfAllUsersByActiveStatus$Response(params?: {
  }): Observable<StrictHttpResponse<{ [key: string]: number }>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetCountOfAllUsersByActiveStatusPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{ [key: string]: number }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCountOfAllUsersByActiveStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCountOfAllUsersByActiveStatus(params?: {
  }): Observable<{ [key: string]: number }> {

    return this.getCountOfAllUsersByActiveStatus$Response(params).pipe(
      map((r: StrictHttpResponse<{ [key: string]: number }>) => r.body as { [key: string]: number })
    );
  }

  /**
   * Path part for operation getEventHubDetailsById
   */
  static readonly GetEventHubDetailsByIdPath = '/api/v1/solutiontype/{solutionTypeId}/eventHub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEventHubDetailsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventHubDetailsById$Response(params: {
    solutionTypeId: string;
  }): Observable<StrictHttpResponse<EventHubDetailsBto>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetEventHubDetailsByIdPath, 'get');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EventHubDetailsBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEventHubDetailsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEventHubDetailsById(params: {
    solutionTypeId: string;
  }): Observable<EventHubDetailsBto> {

    return this.getEventHubDetailsById$Response(params).pipe(
      map((r: StrictHttpResponse<EventHubDetailsBto>) => r.body as EventHubDetailsBto)
    );
  }

  /**
   * Path part for operation saveEventHubDetails
   */
  static readonly SaveEventHubDetailsPath = '/api/v1/solutiontype/{solutionTypeId}/eventHub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveEventHubDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveEventHubDetails$Response(params: {
    solutionTypeId: string;
    body?: EventHubDetailsBto
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.SaveEventHubDetailsPath, 'post');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveEventHubDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveEventHubDetails(params: {
    solutionTypeId: string;
    body?: EventHubDetailsBto
  }): Observable<MessageBto> {

    return this.saveEventHubDetails$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

  /**
   * Path part for operation updateEventHubDetails
   */
  static readonly UpdateEventHubDetailsPath = '/api/v1/solutiontype/{solutionTypeId}/eventHub';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEventHubDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEventHubDetails$Response(params: {
    solutionTypeId: string;
    body?: EventHubDetailsBto
  }): Observable<StrictHttpResponse<MessageBto>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.UpdateEventHubDetailsPath, 'patch');
    if (params) {
      rb.path('solutionTypeId', params.solutionTypeId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessageBto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEventHubDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEventHubDetails(params: {
    solutionTypeId: string;
    body?: EventHubDetailsBto
  }): Observable<MessageBto> {

    return this.updateEventHubDetails$Response(params).pipe(
      map((r: StrictHttpResponse<MessageBto>) => r.body as MessageBto)
    );
  }

  /**
   * Path part for operation getIotHubsBySolDevIntfProvAndProfile
   */
  static readonly GetIotHubsBySolDevIntfProvAndProfilePath = '/api/v1/iothubsBySolDevIntfProvProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getIotHubsBySolDevIntfProvAndProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIotHubsBySolDevIntfProvAndProfile$Response(params: {
    solutionTypeId: string;
    deviceInterfaceProviderId: string;
    profileId?: string;
  }): Observable<StrictHttpResponse<Array<CommonDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetIotHubsBySolDevIntfProvAndProfilePath, 'get');
    if (params) {
      rb.query('solutionTypeId', params.solutionTypeId, {});
      rb.query('deviceInterfaceProviderId', params.deviceInterfaceProviderId, {});
      rb.query('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CommonDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getIotHubsBySolDevIntfProvAndProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getIotHubsBySolDevIntfProvAndProfile(params: {
    solutionTypeId: string;
    deviceInterfaceProviderId: string;
    profileId?: string;
  }): Observable<Array<CommonDetails>> {

    return this.getIotHubsBySolDevIntfProvAndProfile$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CommonDetails>>) => r.body as Array<CommonDetails>)
    );
  }

  /**
   * Path part for operation findAllBusinessTypes
   */
  static readonly FindAllBusinessTypesPath = '/api/v1/businessType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllBusinessTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllBusinessTypes$Response(params?: {
  }): Observable<StrictHttpResponse<Array<SettingCommonDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.FindAllBusinessTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SettingCommonDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findAllBusinessTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAllBusinessTypes(params?: {
  }): Observable<Array<SettingCommonDto>> {

    return this.findAllBusinessTypes$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SettingCommonDto>>) => r.body as Array<SettingCommonDto>)
    );
  }

  /**
   * Path part for operation getAllCountries
   */
  static readonly GetAllCountriesPath = '/api/v1/country';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCountries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCountries$Response(params?: {
  }): Observable<StrictHttpResponse<Array<CountryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.GetAllCountriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CountryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllCountries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCountries(params?: {
  }): Observable<Array<CountryDto>> {

    return this.getAllCountries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<CountryDto>>) => r.body as Array<CountryDto>)
    );
  }

  /**
   * Path part for operation permissions
   */
  static readonly PermissionsPath = '/api/v1/permissions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissions$Response(params?: {
  }): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.PermissionsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `permissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissions(params?: {
  }): Observable<Array<string>> {

    return this.permissions$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

  /**
   * Path part for operation test
   */
  static readonly TestPath = '/api/v1/test';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `test()` instead.
   *
   * This method doesn't expect any request body.
   */
  test$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, PlatformAdminUtilityControllerService.TestPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `test$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  test(params?: {
  }): Observable<string> {

    return this.test$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

}
