/*!
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private Limited.
Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained from
Robert Bosch Engineering and Business Solutions Private Limited.
 */

import { NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';



/**
  * @ngdoc overview
  * @name UsersModule.module:UsersModule
  *
  * @description
  * description :
*/
@NgModule({
  declarations: [ManageUserComponent],
  imports: [
    CommonModule,
    UsersRoutingModule,
    TranslateModule,
    SharedModule,
    MatCardModule,
    
  ]
})
export class UsersModule { }
