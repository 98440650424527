/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { LoginRealmDetails } from '../models/login-realm-details';
import { RealmDetailsHash } from '../models/realm-details-hash';

@Injectable({
  providedIn: 'root',
})
export class RealmSyncControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation deleteRealmDetails
   */
  static readonly DeleteRealmDetailsPath = '/api/v1/realmDbFetch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRealmDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRealmDetails$Response(params: {
    id: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, RealmSyncControllerService.DeleteRealmDetailsPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteRealmDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRealmDetails(params: {
    id: string;
  }): Observable<string> {

    return this.deleteRealmDetails$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateRealmDetails
   */
  static readonly UpdateRealmDetailsPath = '/api/v1/realmDbFetch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRealmDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRealmDetails$Response(params: {
    id: string;
    body?: LoginRealmDetails
  }): Observable<StrictHttpResponse<LoginRealmDetails>> {

    const rb = new RequestBuilder(this.rootUrl, RealmSyncControllerService.UpdateRealmDetailsPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginRealmDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateRealmDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRealmDetails(params: {
    id: string;
    body?: LoginRealmDetails
  }): Observable<LoginRealmDetails> {

    return this.updateRealmDetails$Response(params).pipe(
      map((r: StrictHttpResponse<LoginRealmDetails>) => r.body as LoginRealmDetails)
    );
  }

  /**
   * Path part for operation getRedisDetails
   */
  static readonly GetRedisDetailsPath = '/api/v1/realmRedisFetch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRedisDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRedisDetails$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RealmDetailsHash>>> {

    const rb = new RequestBuilder(this.rootUrl, RealmSyncControllerService.GetRedisDetailsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RealmDetailsHash>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRedisDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRedisDetails(params?: {
  }): Observable<Array<RealmDetailsHash>> {

    return this.getRedisDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RealmDetailsHash>>) => r.body as Array<RealmDetailsHash>)
    );
  }

  /**
   * Path part for operation getRealmDetails
   */
  static readonly GetRealmDetailsPath = '/api/v1/realmDbFetch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRealmDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRealmDetails$Response(params?: {
  }): Observable<StrictHttpResponse<Array<LoginRealmDetails>>> {

    const rb = new RequestBuilder(this.rootUrl, RealmSyncControllerService.GetRealmDetailsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<LoginRealmDetails>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getRealmDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRealmDetails(params?: {
  }): Observable<Array<LoginRealmDetails>> {

    return this.getRealmDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LoginRealmDetails>>) => r.body as Array<LoginRealmDetails>)
    );
  }

  /**
   * Path part for operation saveRealmDetails
   */
  static readonly SaveRealmDetailsPath = '/api/v1/realmDbFetch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveRealmDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveRealmDetails$Response(params: {
    accessInfo: string;
    body?: LoginRealmDetails
  }): Observable<StrictHttpResponse<LoginRealmDetails>> {

    const rb = new RequestBuilder(this.rootUrl, RealmSyncControllerService.SaveRealmDetailsPath, 'post');
    if (params) {
      rb.query('accessInfo', params.accessInfo, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoginRealmDetails>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveRealmDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveRealmDetails(params: {
    accessInfo: string;
    body?: LoginRealmDetails
  }): Observable<LoginRealmDetails> {

    return this.saveRealmDetails$Response(params).pipe(
      map((r: StrictHttpResponse<LoginRealmDetails>) => r.body as LoginRealmDetails)
    );
  }

  /**
   * Path part for operation syncRedisWithDbDetails
   */
  static readonly SyncRedisWithDbDetailsPath = '/api/v1/realmSyncRedis';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `syncRedisWithDbDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  syncRedisWithDbDetails$Response(params: {
    accessInfo: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RealmSyncControllerService.SyncRedisWithDbDetailsPath, 'get');
    if (params) {
      rb.query('accessInfo', params.accessInfo, {});
    }

    return this.http.request(rb.build({
     responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `syncRedisWithDbDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  syncRedisWithDbDetails(params: {
    accessInfo: string;
  }): Observable<void> {

    return this.syncRedisWithDbDetails$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
