 /* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { DataRetProfConfigDto } from '../models/data-ret-prof-config-dto';
import { EmailConfigurationDto } from '../models/email-configuration-dto';
import { OtpConfigurationDto } from '../models/otp-configuration-dto';
import { ProfileUsageDetailsDto } from '../models/profile-usage-details-dto';
import { SmsConfigurationDto } from '../models/sms-configuration-dto';
import { DemoAccountConfigurationDto } from '../models/demo-account-configuration-dto';

@Injectable({
  providedIn: 'root',
})
export class ProfileConfigurationControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSmsConfiguration
   */
  static readonly GetSmsConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/smsConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsConfiguration$Response(params: {
    businessProfileId: string;
  }): Observable<StrictHttpResponse<SmsConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.GetSmsConfigurationPath, 'get');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsConfiguration(params: {
    businessProfileId: string;
  }): Observable<SmsConfigurationDto> {

    return this.getSmsConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<SmsConfigurationDto>) => r.body as SmsConfigurationDto)
    );
  }

  /**
   * Path part for operation saveSmsConfiguration
   */
  static readonly SaveSmsConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/smsConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveSmsConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSmsConfiguration$Response(params: {
    businessProfileId: string;
    body?: SmsConfigurationDto
  }): Observable<StrictHttpResponse<SmsConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.SaveSmsConfigurationPath, 'post');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveSmsConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveSmsConfiguration(params: {
    businessProfileId: string;
    body?: SmsConfigurationDto
  }): Observable<SmsConfigurationDto> {

    return this.saveSmsConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<SmsConfigurationDto>) => r.body as SmsConfigurationDto)
    );
  }

  /**
   * Path part for operation updateSmsConfiguration
   */
  static readonly UpdateSmsConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/smsConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateSmsConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSmsConfiguration$Response(params: {
    businessProfileId: string;
    body?: SmsConfigurationDto
  }): Observable<StrictHttpResponse<SmsConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.UpdateSmsConfigurationPath, 'patch');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateSmsConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateSmsConfiguration(params: {
    businessProfileId: string;
    body?: SmsConfigurationDto
  }): Observable<SmsConfigurationDto> {

    return this.updateSmsConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<SmsConfigurationDto>) => r.body as SmsConfigurationDto)
    );
  }

  /**
   * Path part for operation getEmailConfiguration
   */
  static readonly GetEmailConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/emailConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmailConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailConfiguration$Response(params: {
    businessProfileId: string;
  }): Observable<StrictHttpResponse<EmailConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.GetEmailConfigurationPath, 'get');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmailConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmailConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailConfiguration(params: {
    businessProfileId: string;
  }): Observable<EmailConfigurationDto> {

    return this.getEmailConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<EmailConfigurationDto>) => r.body as EmailConfigurationDto)
    );
  }

  /**
   * Path part for operation saveEmailConfiguration
   */
  static readonly SaveEmailConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/emailConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveEmailConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveEmailConfiguration$Response(params: {
    businessProfileId: string;
    body?: EmailConfigurationDto
  }): Observable<StrictHttpResponse<EmailConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.SaveEmailConfigurationPath, 'post');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmailConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveEmailConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveEmailConfiguration(params: {
    businessProfileId: string;
    body?: EmailConfigurationDto
  }): Observable<EmailConfigurationDto> {

    return this.saveEmailConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<EmailConfigurationDto>) => r.body as EmailConfigurationDto)
    );
  }

  /**
   * Path part for operation updateEmailConfiguration
   */
  static readonly UpdateEmailConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/emailConfiguration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateEmailConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmailConfiguration$Response(params: {
    businessProfileId: string;
    body?: EmailConfigurationDto
  }): Observable<StrictHttpResponse<EmailConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.UpdateEmailConfigurationPath, 'patch');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmailConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateEmailConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateEmailConfiguration(params: {
    businessProfileId: string;
    body?: EmailConfigurationDto
  }): Observable<EmailConfigurationDto> {

    return this.updateEmailConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<EmailConfigurationDto>) => r.body as EmailConfigurationDto)
    );
  }

  /**
   * Path part for operation findProfileUsageDetails
   */
  static readonly FindProfileUsageDetailsPath = '/api/v1/businessProfile/{businessProfileId}/profileUsageDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findProfileUsageDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProfileUsageDetails$Response(params: {
    businessProfileId: string;
  }): Observable<StrictHttpResponse<ProfileUsageDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.FindProfileUsageDetailsPath, 'get');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileUsageDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findProfileUsageDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findProfileUsageDetails(params: {
    businessProfileId: string;
  }): Observable<ProfileUsageDetailsDto> {

    return this.findProfileUsageDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileUsageDetailsDto>) => r.body as ProfileUsageDetailsDto)
    );
  }

  /**
   * Path part for operation createProfileUsage
   */
  static readonly CreateProfileUsagePath = '/api/v1/businessProfile/{businessProfileId}/profileUsageDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProfileUsage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProfileUsage$Response(params: {
    businessProfileId: string;
    body?: ProfileUsageDetailsDto
  }): Observable<StrictHttpResponse<ProfileUsageDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.CreateProfileUsagePath, 'post');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileUsageDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createProfileUsage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createProfileUsage(params: {
    businessProfileId: string;
    body?: ProfileUsageDetailsDto
  }): Observable<ProfileUsageDetailsDto> {

    return this.createProfileUsage$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileUsageDetailsDto>) => r.body as ProfileUsageDetailsDto)
    );
  }

  /**
   * Path part for operation updateProfileUsage
   */
  static readonly UpdateProfileUsagePath = '/api/v1/businessProfile/{businessProfileId}/profileUsageDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfileUsage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileUsage$Response(params: {
    businessProfileId: string;
    body?: ProfileUsageDetailsDto
  }): Observable<StrictHttpResponse<ProfileUsageDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.UpdateProfileUsagePath, 'patch');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileUsageDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateProfileUsage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileUsage(params: {
    businessProfileId: string;
    body?: ProfileUsageDetailsDto
  }): Observable<ProfileUsageDetailsDto> {

    return this.updateProfileUsage$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileUsageDetailsDto>) => r.body as ProfileUsageDetailsDto)
    );
  }

  /**
   * Path part for operation findOtpConfigurationDetails
   */
  static readonly FindOtpConfigurationDetailsPath = '/api/v1/businessProfile/{businessProfileId}/otpConfigration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOtpConfigurationDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOtpConfigurationDetails$Response(params: {
    businessProfileId: string;
  }): Observable<StrictHttpResponse<OtpConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.FindOtpConfigurationDetailsPath, 'get');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OtpConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `findOtpConfigurationDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOtpConfigurationDetails(params: {
    businessProfileId: string;
  }): Observable<OtpConfigurationDto> {

    return this.findOtpConfigurationDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OtpConfigurationDto>) => r.body as OtpConfigurationDto)
    );
  }

  /**
   * Path part for operation createOtpConfiguration
   */
  static readonly CreateOtpConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/otpConfigration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createOtpConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOtpConfiguration$Response(params: {
    businessProfileId: string;
    body?: OtpConfigurationDto
  }): Observable<StrictHttpResponse<OtpConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.CreateOtpConfigurationPath, 'post');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OtpConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createOtpConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createOtpConfiguration(params: {
    businessProfileId: string;
    body?: OtpConfigurationDto
  }): Observable<OtpConfigurationDto> {

    return this.createOtpConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<OtpConfigurationDto>) => r.body as OtpConfigurationDto)
    );
  }

  /**
   * Path part for operation updateOtpConfiguration
   */
  static readonly UpdateOtpConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/otpConfigration';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOtpConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOtpConfiguration$Response(params: {
    businessProfileId: string;
    body?: OtpConfigurationDto
  }): Observable<StrictHttpResponse<OtpConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.UpdateOtpConfigurationPath, 'patch');
    if (params) {
      rb.path('businessProfileId', params.businessProfileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OtpConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateOtpConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateOtpConfiguration(params: {
    businessProfileId: string;
    body?: OtpConfigurationDto
  }): Observable<OtpConfigurationDto> {

    return this.updateOtpConfiguration$Response(params).pipe(
      map((r: StrictHttpResponse<OtpConfigurationDto>) => r.body as OtpConfigurationDto)
    );
  }

  /**
   * Path part for operation getEmailConfigurationForMicroSeService
   */
  static readonly GetEmailConfigurationForMicroSeServicePath = '/api/v1/businessProfile/{businessRealm}/email';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmailConfigurationForMicroSeService()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailConfigurationForMicroSeService$Response(params: {
    businessRealm: string;
  }): Observable<StrictHttpResponse<EmailConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.GetEmailConfigurationForMicroSeServicePath, 'get');
    if (params) {
      rb.path('businessRealm', params.businessRealm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmailConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmailConfigurationForMicroSeService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailConfigurationForMicroSeService(params: {
    businessRealm: string;
  }): Observable<EmailConfigurationDto> {

    return this.getEmailConfigurationForMicroSeService$Response(params).pipe(
      map((r: StrictHttpResponse<EmailConfigurationDto>) => r.body as EmailConfigurationDto)
    );
  }

  /**
   * Path part for operation getEmailConfigurationDefault
   */
  static readonly GetEmailConfigurationDefaultPath = '/api/v1/defaultemail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEmailConfigurationDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailConfigurationDefault$Response(params?: {
  }): Observable<StrictHttpResponse<EmailConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.GetEmailConfigurationDefaultPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EmailConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getEmailConfigurationDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEmailConfigurationDefault(params?: {
  }): Observable<EmailConfigurationDto> {

    return this.getEmailConfigurationDefault$Response(params).pipe(
      map((r: StrictHttpResponse<EmailConfigurationDto>) => r.body as EmailConfigurationDto)
    );
  }

  /**
   * Path part for operation getSmsConfigurationForMicroService
   */
  static readonly GetSmsConfigurationForMicroServicePath = '/api/v1/businessProfile/{businessRealm}/sms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsConfigurationForMicroService()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsConfigurationForMicroService$Response(params: {
    businessRealm: string;
  }): Observable<StrictHttpResponse<SmsConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.GetSmsConfigurationForMicroServicePath, 'get');
    if (params) {
      rb.path('businessRealm', params.businessRealm, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsConfigurationForMicroService$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsConfigurationForMicroService(params: {
    businessRealm: string;
  }): Observable<SmsConfigurationDto> {

    return this.getSmsConfigurationForMicroService$Response(params).pipe(
      map((r: StrictHttpResponse<SmsConfigurationDto>) => r.body as SmsConfigurationDto)
    );
  }

  /**
   * Path part for operation getSmsConfigurationDefault
   */
  static readonly GetSmsConfigurationDefaultPath = '/api/v1/defaultsms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsConfigurationDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsConfigurationDefault$Response(params?: {
  }): Observable<StrictHttpResponse<SmsConfigurationDto>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.GetSmsConfigurationDefaultPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsConfigurationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsConfigurationDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsConfigurationDefault(params?: {
  }): Observable<SmsConfigurationDto> {

    return this.getSmsConfigurationDefault$Response(params).pipe(
      map((r: StrictHttpResponse<SmsConfigurationDto>) => r.body as SmsConfigurationDto)
    );
  }

  /**
   * Path part for operation getDataRetProfConfig
   */
  static readonly GetDataRetProfConfigPath = '/api/v1/businessProfile/{profileId}/dataretention/{serviceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDataRetProfConfig()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataRetProfConfig$Response(params: {
    profileId: string;
    serviceId: string;
  }): Observable<StrictHttpResponse<Array<DataRetProfConfigDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.GetDataRetProfConfigPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('serviceId', params.serviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataRetProfConfigDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDataRetProfConfig$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDataRetProfConfig(params: {
    profileId: string;
    serviceId: string;
  }): Observable<Array<DataRetProfConfigDto>> {

    return this.getDataRetProfConfig$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DataRetProfConfigDto>>) => r.body as Array<DataRetProfConfigDto>)
    );
  }

  /**
   * Path part for operation updateDataRetProfConfig
   */
  static readonly UpdateDataRetProfConfigPath = '/api/v1/businessProfile/{profileId}/dataretention/{serviceId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDataRetProfConfig()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDataRetProfConfig$Response(params: {
    profileId: string;
    serviceId: string;
    body?: Array<DataRetProfConfigDto>
  }): Observable<StrictHttpResponse<Array<DataRetProfConfigDto>>> {

    const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.UpdateDataRetProfConfigPath, 'patch');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.path('serviceId', params.serviceId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DataRetProfConfigDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateDataRetProfConfig$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDataRetProfConfig(params: {
    profileId: string;
    serviceId: string;
    body?: Array<DataRetProfConfigDto>
  }): Observable<Array<DataRetProfConfigDto>> {

    return this.updateDataRetProfConfig$Response(params).pipe(
      map((r: StrictHttpResponse<Array<DataRetProfConfigDto>>) => r.body as Array<DataRetProfConfigDto>)
    );
  }


   /**
   * Path part for operation getDemoAccountConfiguration
   */
   static readonly GetDemoAccountConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/demoAccountConfiguration';

   /**
    * This method provides access to the full `HttpResponse`, allowing access to response headers.
    * To access only the response body, use `getDemoAccountConfiguration()` instead.
    *
    * This method doesn't expect any request body.
    */
   getDemoAccountConfiguration$Response(params: {
     businessProfileId: string;
   }): Observable<StrictHttpResponse<Array<DemoAccountConfigurationDto>>> {
 
     const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.GetDemoAccountConfigurationPath, 'get');
     if (params) {
       rb.path('businessProfileId', params.businessProfileId, {});
     }
 
     return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
     })).pipe(
       filter((r: any) => r instanceof HttpResponse),
       map((r: HttpResponse<any>) => {
         return r as StrictHttpResponse<Array<DemoAccountConfigurationDto>>;
       })
     );
   }
 
   /**
    * This method provides access to only to the response body.
    * To access the full response (for headers, for example), `getDemoAccountConfiguration$Response()` instead.
    *
    * This method doesn't expect any request body.
    */
   getDemoAccountConfiguration(params: {
     businessProfileId: string;
   }): Observable<Array<DemoAccountConfigurationDto>> {
 
     return this.getDemoAccountConfiguration$Response(params).pipe(
       map((r: StrictHttpResponse<Array<DemoAccountConfigurationDto>>) => r.body as Array<DemoAccountConfigurationDto>)
     );
   }
 
   /**
    * Path part for operation saveDemoAccountConfiguration
    */
   static readonly SaveDemoAccountConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/demoAccountConfiguration';
 
   /**
    * This method provides access to the full `HttpResponse`, allowing access to response headers.
    * To access only the response body, use `saveDemoAccountConfiguration()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   saveDemoAccountConfiguration$Response(params: {
     businessProfileId: string;
     body?: Array<DemoAccountConfigurationDto>
   }): Observable<StrictHttpResponse<Array<DemoAccountConfigurationDto>>> {
 
     const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.SaveDemoAccountConfigurationPath, 'post');
     if (params) {
       rb.path('businessProfileId', params.businessProfileId, {});
       rb.body(params.body, 'application/json');
     }
 
     return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
     })).pipe(
       filter((r: any) => r instanceof HttpResponse),
       map((r: HttpResponse<any>) => {
         return r as StrictHttpResponse<Array<DemoAccountConfigurationDto>>;
       })
     );
   }
 
   /**
    * This method provides access to only to the response body.
    * To access the full response (for headers, for example), `saveDemoAccountConfiguration$Response()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   saveDemoAccountConfiguration(params: {
     businessProfileId: string;
     body?: Array<DemoAccountConfigurationDto>
   }): Observable<Array<DemoAccountConfigurationDto>> {
 
     return this.saveDemoAccountConfiguration$Response(params).pipe(
       map((r: StrictHttpResponse<Array<DemoAccountConfigurationDto>>) => r.body as Array<DemoAccountConfigurationDto>)
     );
   }
 
   /**
    * Path part for operation updateDemoAccountConfiguration
    */
   static readonly UpdateDemoAccountConfigurationPath = '/api/v1/businessProfile/{businessProfileId}/demoAccountConfiguration';
 
   /**
    * This method provides access to the full `HttpResponse`, allowing access to response headers.
    * To access only the response body, use `updateDemoAccountConfiguration()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   updateDemoAccountConfiguration$Response(params: {
     businessProfileId: string;
     body?: Array<DemoAccountConfigurationDto>
   }): Observable<StrictHttpResponse<Array<DemoAccountConfigurationDto>>> {
 
     const rb = new RequestBuilder(this.rootUrl, ProfileConfigurationControllerService.UpdateDemoAccountConfigurationPath, 'patch');
     if (params) {
       rb.path('businessProfileId', params.businessProfileId, {});
       rb.body(params.body, 'application/json');
     }
 
     return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
     })).pipe(
       filter((r: any) => r instanceof HttpResponse),
       map((r: HttpResponse<any>) => {
         return r as StrictHttpResponse<Array<DemoAccountConfigurationDto>>;
       })
     );
   }
 
   /**
    * This method provides access to only to the response body.
    * To access the full response (for headers, for example), `updateDemoAccountConfiguration$Response()` instead.
    *
    * This method sends `application/json` and handles request body of type `application/json`.
    */
   updateDemoAccountConfiguration(params: {
     businessProfileId: string;
     body?: Array<DemoAccountConfigurationDto>
   }): Observable<Array<DemoAccountConfigurationDto>> {
 
     return this.updateDemoAccountConfiguration$Response(params).pipe(
       map((r: StrictHttpResponse<Array<DemoAccountConfigurationDto>>) => r.body as Array<DemoAccountConfigurationDto>)
     );
   }
 

}

 
 
