<section class="overview-container" >
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="accountIcon" />
        <span>{{'ACCOUNT_MANAGEMENT_MANAGE' |translate}}</span>
      </span>
    </div>
    <!-- <span>
   <button  *ngIf="checkPermission('ACC_U')" (click)="gotoModal(popup, null, 'activate')" mat-raised-button class="manage-action-btn">{{ 'ACTIVATE' | translate }}</button>
   <button  *ngIf="checkPermission('ACC_U')" (click)="gotoModal(popup, null, 'deactivate')" class="manage-action-btn" mat-raised-button>{{ 'DE-ACTIVATE' | translate }}</button>
   <button  *ngIf="checkPermission('ACC_DEL')" (click)="deleteAccount($event)" mat-raised-button color="warn" class="manage-action-btn">{{ 'DELETE' | translate }}</button>
</span> -->
    <div class="table-container">
      <mat-table [dataSource]="dataSource" class="bottom-space table-data" *ngIf="accountData">
        <div *ngFor="let column of columns">
          <!-- <ng-container *ngIf="column==='select'" matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)"></mat-checkbox>
            </mat-cell>
          </ng-container> -->
          <ng-container *ngIf="column!=='select'" [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase}}</mat-header-cell>
            <mat-cell class="tooltip" *matCellDef="let dataName" [ngClass]="column">
              <span *ngIf="column!=='action' && column!=='active'" title="{{dataName[column]}}" class="cell-value">
                {{dataName[column]}}
              </span>
              <span *ngIf="column==='action'">
                <mat-slide-toggle [disabled]="!checkPermission('ACC_ACT') && !checkPermission('ACC_DACT')"
                  [(ngModel)]="dataName['active']" (change)="gotoConfirmDialog(dataName, 'edit')">
                </mat-slide-toggle>
                <img *ngIf="checkPermission('ACC_U')" [class.disabled]="!dataName['active']" [src]="editImage"
                  (click)="gotoModal(content, dataName)" class="modify-button">
                <img *ngIf="checkPermission('ACC_DEL')" [src]="deleteImage"
                  (click)="gotoConfirmDialog(dataName, 'delete')" class="modify-button">
              </span>
            </mat-cell>
          </ng-container>

        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>
<ng-template #content let-modal>
  <div class="close-btn"><img  class="rb-ic" (click)="onClose()"  [src]="closeIcon" /></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title" id="modal-basic-title">{{jsonFileLoaded | titlecase}}</h5>
    </div>
    <div class="modal-body">
      <app-dynamic-form [jsonFile]="jsonFileLoaded" [formData]="accountData" (formEmitter)="editAccount($event)">
      </app-dynamic-form>
    </div>
  </div>
</ng-template>