/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AverageResponseTimeDto } from '../models/average-response-time-dto';
import { ErrorResponseCountDto } from '../models/error-response-count-dto';
import { QueueMessageCountDto } from '../models/queue-message-count-dto';

@Injectable({
  providedIn: 'root',
})
export class SmsReportControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAverageResponseTime
   */
  static readonly GetAverageResponseTimePath = '/api/v1/average-response-time';

  /**
   * Get average response time.
   *
   * The API to get average response time
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAverageResponseTime()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAverageResponseTime$Response(params?: {
    from?: string;
    to?: string;
    email?: boolean;
  }): Observable<StrictHttpResponse<Array<AverageResponseTimeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsReportControllerService.GetAverageResponseTimePath, 'get');
    if (params) {
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<AverageResponseTimeDto>>;
      })
    );
  }

  /**
   * Get average response time.
   *
   * The API to get average response time
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAverageResponseTime$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAverageResponseTime(params?: {
    from?: string;
    to?: string;
    email?: boolean;
  }): Observable<Array<AverageResponseTimeDto>> {

    return this.getAverageResponseTime$Response(params).pipe(
      map((r: StrictHttpResponse<Array<AverageResponseTimeDto>>) => r.body as Array<AverageResponseTimeDto>)
    );
  }

  /**
   * Path part for operation getErrorResponseCount
   */
  static readonly GetErrorResponseCountPath = '/api/v1/error-response-count';

  /**
   * Get invalid response count.
   *
   * The API to get invalid response count
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getErrorResponseCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorResponseCount$Response(params?: {
    from?: string;
    to?: string;
    email?: boolean;
  }): Observable<StrictHttpResponse<Array<ErrorResponseCountDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsReportControllerService.GetErrorResponseCountPath, 'get');
    if (params) {
      rb.query('from', params.from, {});
      rb.query('to', params.to, {});
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ErrorResponseCountDto>>;
      })
    );
  }

  /**
   * Get invalid response count.
   *
   * The API to get invalid response count
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getErrorResponseCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getErrorResponseCount(params?: {
    from?: string;
    to?: string;
    email?: boolean;
  }): Observable<Array<ErrorResponseCountDto>> {

    return this.getErrorResponseCount$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ErrorResponseCountDto>>) => r.body as Array<ErrorResponseCountDto>)
    );
  }

  /**
   * Path part for operation getQueueMessageCount
   */
  static readonly GetQueueMessageCountPath = '/api/v1/queue-message-count';

  /**
   * Get queue message count.
   *
   * The API to get queue message count
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getQueueMessageCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQueueMessageCount$Response(params?: {
    email?: boolean;
  }): Observable<StrictHttpResponse<Array<QueueMessageCountDto>>> {

    const rb = new RequestBuilder(this.rootUrl, SmsReportControllerService.GetQueueMessageCountPath, 'get');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<QueueMessageCountDto>>;
      })
    );
  }

  /**
   * Get queue message count.
   *
   * The API to get queue message count
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getQueueMessageCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getQueueMessageCount(params?: {
    email?: boolean;
  }): Observable<Array<QueueMessageCountDto>> {

    return this.getQueueMessageCount$Response(params).pipe(
      map((r: StrictHttpResponse<Array<QueueMessageCountDto>>) => r.body as Array<QueueMessageCountDto>)
    );
  }

}
