<div>
  <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
  <div class="popup-body">
    <div class="popup-content">
      <p>{{data.title | translate}}</p>

      <div class="table-container" *ngIf="this.data.dataKey && this.data.dataKey?.length !== 0">
        <mat-table [dataSource]="dataSource" class="table-data">
          <div *ngFor="let column of columns">
            <ng-container [matColumnDef]="column">
              <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
              <mat-cell class="tooltip " *matCellDef="let dataName">
                <span *ngIf="column!=='action'" title="{{dataName[column] | translate}}" class="cell-value">
                  {{dataName[column] | translate}}
                </span>
                <span *ngIf="column==='action'">
                  <a [href]="dataName['url']" download class="sample-file-link"> <img [src]="downloadImage"
                      class="modify-button" [title]="'DOWNLOAD' | translate"></a>
                  <img [src]="deleteImage" (click)="gotoDeleteConfirmDialog(dataName['proposalId'], dataName['id'])"
                    class="modify-button" [title]="'DELETE' | translate">
                </span>
              </mat-cell>
            </ng-container>
          </div>
          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let data; columns: columns;"></mat-row>
        </mat-table>


      </div>
      <div *ngIf="this.data.dataKey?.length === 0">{{ 'NO_DATA_AVAILABLE' | translate }}</div>
    </div>
  </div>
</div>