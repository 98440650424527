import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { APPLN_GRPH } from 'src/app/shared/constants/strings';
import { ActivatedRoute, Router } from '@angular/router';
import { DEVICE_ICON, APPLN_GPH_ICON } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';
import { BciLoaderService } from '@bci-web-core/core';
import { LineChartService } from 'src/app/providers/line-chart-service';
import { DeviceGraphControllerService } from 'src/app/services/DeviceMgmt/services/device-graph-controller.service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { DeviceGraphDto } from 'src/app/services/DeviceMgmt/models/device-graph-dto';
@Component({
  selector: 'app-application-graphs',
  templateUrl: './application-graphs.component.html',
  styleUrls: ['./application-graphs.component.scss']
})
export class ApplicationGraphsComponent implements OnInit,AfterViewInit {
public applnGraphIcon: string;
public jsonFileLoaded: any;
public showSpinner: any;
public pageKey: string;
public dropDownValues: any = {};
public selectedInterval: any;
public interval: string = '/assets/form-fields/graph-intervals.json';
asideMenus: Array<AsideCard>;
  @ViewChild('cpuUsageCharts') public cpuUsageChartElement: ElementRef;
  @ViewChild('memoryUsageCharts') public memoryUsageChartElement: ElementRef;
  @ViewChild('freeDiskUsageCharts') public freeDiskUsageChartElement: ElementRef;
  @ViewChild('netInUsageCharts') public netInUsageChartElement: ElementRef;
  @ViewChild('netOutUsageCharts') public netOutUsageChartElement: ElementRef;
  @ViewChild('deviceGraph') public deviceGraph: ElementRef;
  deviceData: any;
  graphsLength: number;
  chartElement: any;
   networkin: boolean;
  cpu: boolean=true;
  networkIn: boolean =true;
  networkOut: boolean=true;
  freeDisk: boolean=true;
  memory: boolean=true;
  serialNo: any;
  profileId: string;
  SaveGraphArray: any[];
  @ViewChild(DynamicFormComponent, { static: true }) dynamicForm: DynamicFormComponent;
  selectedGraph: DeviceGraphDto[]=[];
  isSelected: boolean;
  existingGraph: boolean;
  constructor(
     private lineChartService: LineChartService,
     private DeviceGraphControllerService:DeviceGraphControllerService,
     private route: ActivatedRoute,private cdRef: ChangeDetectorRef,
     private loaderService: BciLoaderService,
     private responseHandlerService: ResponseHandlerService,
     private http: HttpClient
     ) {
   }
  
   ngOnInit(): void {
    this.serialNo = this.route.snapshot.paramMap.get('serialNo');
    this.profileId= this.route.snapshot.paramMap.get('profileId');
    this.applnGraphIcon = APPLN_GPH_ICON;
    this.jsonFileLoaded = 'application-graphs';
    this.getIntervals();
    this.getExistingGraph();
      }

    public ngAfterViewInit(){
    this.freeDisk = this.networkIn = this.networkOut = this.cpu = this.memory =false;
    }

  public generateGraphs(value?) {
        this.showSpinner = this.loaderService.showProgressBar();
        this.existingGraph = false;
        let graphArray=[];
        this.SaveGraphArray=[];
        value.graphs.forEach(ele => {
        graphArray.push({graphName: ele.graphName,yaxis:ele.yAxis});
        });
        value.graphs.forEach(ele => {
        this.SaveGraphArray.push({graphName: ele.graphName,yaxis:ele.yAxis,profileId: this.profileId});
        });
        const obj ={
          deviceId:this.serialNo,
          interval:this.selectedInterval,
          body:{
            graphDetails:graphArray
          }
        };
        this.DeviceGraphControllerService.generateDynamicGraph(obj).subscribe(
          resp=>{
          this.loaderService.hideProgressBar(this.showSpinner);
          this.constructGraphsData(resp);
        },
        err=>{
          this.loaderService.hideProgressBar(this.showSpinner);
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        });
     }
  
     public constructGraphsData(resp){
      const dateArr = [];
      const timeArr =[];
      this.graphsLength = resp.graphDetails.length;
      let cpuChart:any,networkInChart:any,memoryChart:any,
      freeDiskChart:any,networkOutChart:any;
      const chartData ={};
      resp.graphDetails.forEach((graphDetails)=>{
        if(graphDetails.errorMsg){
          this.responseHandlerService.returnToastMessage('error',  graphDetails.errorMsg);
         return;
        }
        graphDetails.timeValues.forEach((date) => {
          const dateTime = date.split(' ');
          dateArr.push (dateTime[0]);
          timeArr.push(dateTime[1]);
        });
        resp['dates'] = dateArr;
        chartData['dates'] = resp['dates'];
        chartData['color'] = '#d4507a';
        chartData['name'] = graphDetails['graphName'];
        chartData['values'] = graphDetails['yaxisValues'].map(str => parseFloat(str));
        chartData['time'] = timeArr;
        if(graphDetails.yaxis && graphDetails.yaxis=='mem'){
          this.memory = true;
          this.cdRef.detectChanges();
          this.chartElement = this.memoryUsageChartElement;
          this.networkIn?this.networkIn=true:this.networkIn=false;
          this.cpu?this.cpu=true:this.cpu=false;
          this.freeDisk?this.freeDisk=true:this.freeDisk=false;
          this.networkOut?this.networkOut=true:this.networkOut=false;
           memoryChart = this.drawChart(this.chartElement, memoryChart, chartData, this.deviceData, 'CPU');
        }
        else if(graphDetails.yaxis && graphDetails.yaxis=='nin') {
          this.networkIn = true;
          this.cdRef.detectChanges();
          this.chartElement = this.netInUsageChartElement;
          this.memory?this.memory=true:this.memory=false;
          this.cpu?this.cpu=true:this.cpu=false;
          this.freeDisk?this.freeDisk=true:this.freeDisk=false;
          this.networkOut?this.networkOut=true:this.networkOut=false;
          networkInChart = this.drawChart(this.chartElement, cpuChart, chartData, this.deviceData, 'CPU');
          }
        else if(graphDetails.yaxis && graphDetails.yaxis=='cpu'){
          this.cpu = true;
          this.cdRef.detectChanges();
          this.chartElement = this.cpuUsageChartElement;
          this.memory?this.memory=true:this.memory=false;
          this.networkIn?this.networkIn=true:this.networkIn=false;
          this.freeDisk?this.freeDisk=true:this.freeDisk=false;
          this.networkOut?this.networkOut=true:this.networkOut=false;
          cpuChart = this.drawChart(this.chartElement, cpuChart, chartData, this.deviceData, 'CPU');
        }
        else if(graphDetails.yaxis && graphDetails.yaxis=='not'){
          this.networkOut = true;
          this.cdRef.detectChanges();
          this.chartElement = this.netOutUsageChartElement;
          this.memory?this.memory=true:this.memory=false;
          this.networkIn?this.networkIn=true:this.networkIn=false;
          this.freeDisk?this.freeDisk=true:this.freeDisk=false;
          this.cpu?this.cpu=true:this.cpu=false;
        networkOutChart = this.drawChart(this.chartElement, networkOutChart, chartData, this.deviceData, 'CPU');
        }
        else if(graphDetails.yaxis && graphDetails.yaxis=='fds'){
          this.freeDisk = true;
          this.cdRef.detectChanges();
          this.chartElement = this.freeDiskUsageChartElement;
          this.memory?this.memory=true:this.memory=false;
          this.networkIn?this.networkIn=true:this.networkIn=false;
          this.networkOut?this.networkOut=true:this.networkOut=false;
          this.cpu?this.cpu=true:this.cpu=false;
           freeDiskChart = this.drawChart(this.chartElement, freeDiskChart, chartData, this.deviceData, 'CPU');
        }
        else {
          err=>{
            this.responseHandlerService.returnToastMessage('error', err.error.message);
          };
        }
      });
     }
    public saveDynamicGraphs(){
          this.showSpinner = this.loaderService.showProgressBar();
           const obj ={
              deviceId:this.serialNo,
              body:this.SaveGraphArray
          };
            this.DeviceGraphControllerService.saveDynamicGraph(obj).subscribe(
            resp=>{
              this.loaderService.hideProgressBar(this.showSpinner);
              this.responseHandlerService.returnToastMessage('success', 'GRAPHS_SAVED_SUCCESSFULLY');
            },
           err=>{
            this.responseHandlerService.returnToastMessage('error', err.error.message);
            this.loaderService.hideProgressBar(this.showSpinner);
          }
     );
    }

    public drawChart(chartElement, chart, summaryData, data, chartType) {
      chart = this.lineChartService.createChart(chartElement.nativeElement, [summaryData]);
      return chart;
    }
        
    public resetForm($event) {
      if ($event) {
        this.dynamicForm.createForm.reset();
      }
      this.deselectGraph();
      this.existingGraph = true;
      this.selectedGraph = [];
    }
    
 public getIntervals() {
  this.http.get(this.interval).subscribe(
    (resp:any) => {
      this.dropDownValues['intervals'] = resp.fields;
    },
    error => {
      console.error('Error loading intervals:', error);
    }
  );
  }

  public getExistingGraph() {
    const deviceGraphs = {
      body: {
        deviceSlNo: this.serialNo,
        profileId: this.profileId
      },
    };
    this.DeviceGraphControllerService.getExistingDynamicGraphs(deviceGraphs).subscribe(
      resp=>{
        this.dropDownValues['Existing Graphs'] = resp;
      },
     err=>{
      this.responseHandlerService.returnToastMessage('error', err.error.message);
      this.loaderService.hideProgressBar(this.showSpinner);
    }
    );
  }

  public onSelection(event) {
    this.selectedGraph = event.value;
    if (this.selectedGraph.length > 4) {
      this.selectedGraph.pop();
    }
  }

  public isDisabled(graph:DeviceGraphDto) {
    return this.selectedGraph.length >= 4 && !(this.selectedGraph.some((deviceGraph) =>
      deviceGraph.deviceGraphId === graph.deviceGraphId));
  }

  public regenerateGraph() {
    this.showSpinner = this.loaderService.showProgressBar();
    this.existingGraph = true;
    this.deselectGraph();
    const graphArray=[];
    const value = this.selectedGraph;
    value.forEach(ele => {
      graphArray.push({graphName: ele.graphName,yaxis:ele.yaxis});
      });
      const obj ={
        deviceId:this.serialNo,
        interval:this.selectedInterval,
        body:{
          graphDetails:graphArray
        }
      };
      this.DeviceGraphControllerService.generateExistingDynamicGraph(obj).subscribe(
        resp=>{
        this.loaderService.hideProgressBar(this.showSpinner);
        this.constructGraphsData(resp);
      },
      err=>{
        this.loaderService.hideProgressBar(this.showSpinner);
        this.responseHandlerService.returnToastMessage('error', err.error.message);
      });
  }

  public deselectGraph() {
    this.cpu = false;
    this.memory = false;
    this.networkIn = false;
    this.networkOut = false;
    this.freeDisk = false;
  }
}
