<section class="overview-container">
    <section class="main">
        <div class="header-style">
            <span class="heading-label">
                <img  class="back-btn" (click)="navigateBack()" [src]="backImage" />
              <img class="page-icon-small" [src]="whatsappIcon" />
              <span>{{'WHATSAPP_CLICK_TRACKING_ANALYSIS' | translate}}</span>
            </span>
          </div>
          <div class="whatsapp-card">
            <div class="whatsapp-details-panel" *ngIf="whatsappDetailsData">
                <mat-card class="panel-body" *ngFor="let card of whatsappDetailsData | keyvalue">
                    <h5>{{card.key | translate}}</h5>
                    <div *ngFor="let basic of card.value" class="individual-detail">
                        <header class="whatsapp-detail-label">{{basic['label'] | translate}}</header>
                        <span>{{basic['value']}}</span>
                    </div>
                </mat-card>
            </div>
          </div>
            <div #charts id="pie-chart"></div>
            <div class="info-div">
                <ng-container *ngFor="let data of chartDataObject['details']">
                      <span class="status-text-size">{{data['name'] | translate}} :</span>
                      <div class="overview-status-size" [ngStyle]="{'color': data['color']}">{{ data['count'] }}
                      </div> 
                      <hr>
                </ng-container>
            </div>
            <div class="clear-float"></div>
            <div class="table-container" *ngIf="clickCount">
                <mat-table [dataSource]="dataSource" class="bottom-space table-data">
                    <div *ngFor="let column of columns">
                        <ng-container  [matColumnDef]="column">
                            <mat-header-cell *matHeaderCellDef>
                                {{column | appendSpace | titlecase | translate}}
                            </mat-header-cell>
                            <mat-cell class="tooltip" *matCellDef="let dataName">
                                <span title="{{dataName[column]}}" class="cell-value">
                                    {{dataName[column]}}
                                </span>
                            </mat-cell>
                        </ng-container>
                    </div>
                    <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
                    <mat-row *matRowDef="let data; columns: columns;"></mat-row>
                </mat-table>
            </div>
            <mat-paginator *ngIf="dataSource" [pageSizeOptions]="pageSizeOptions" [length]="totalClickCount"
                        [pageSize]="pageSize" showFirstLastButtons (page)="pageEvent($event)"></mat-paginator>
    </section>
</section>
