<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="certificateIcon" />
        <span>{{'CREATE_CERTIFICATE' | translate}}</span>
      </span>
    </div>
    <mat-tab-group (selectedTabChange)="matChange($event)">
      <mat-tab [label]="tab.label" *ngFor="let tab of matTabOptions">
      </mat-tab>
    </mat-tab-group>
    <div class="create-form-spacing">
      <p class="disclaimer" *ngIf="displayInfoMsg">{{'INFO_MSG_FOR_INTERMEDIATE_AND_LEAF_CERTIFICATE' | translate}}</p>
      <app-dynamic-form [jsonFile]="jsonFileLoaded" [dropDownValues]="dropDownValues"
        (valueSelected)="updateDropdownValues($event)" [columnsInForm]="'multi'"
        (formEmitter)="createCertificate($event)" (resetForm)="resetForm($event)">
      </app-dynamic-form>
      <div class="verify-btn" *ngIf="showActionButtons" >
        <button mat-raised-button class="button" (click)="downloadRootCertificate()"> 
          <span>{{ 'DOWNLOAD' | translate }}</span>
        </button>
        <button mat-raised-button class="button" (click)="openModal(content)">
          <span>{{ 'VERIFY' | translate }}</span>
        </button>
      </div>
    </div>
  </section>
  <section *ngIf="asideMenus">
    <app-right-side-nav-overlay [asideMenuList]="asideMenus"></app-right-side-nav-overlay>
  </section>
</section>


<ng-template #content let-modal>
  <div class="close-btn"><img  class="rb-ic" (click)="onClose()"  [src]="closeIcon" /></div>
  <div class="modal-width">
    <div class="modal-header">
      <h5 class="modal-title title" id="modal-basic-title"> {{'GENERATE_VALIDITY_CERTIFICATE' | translate}} </h5>
    </div>
    <div class="modal-body modal-body-margin">
      <form [formGroup]="verificationForm" class="form-fields">
      <mat-form-field>
        <mat-label class="label">{{'VERIFICATION_CODE' | translate}}</mat-label>
        <input type="text" matInput formControlName="verificationCode" class="form-field" 
        [placeholder]="'TYPE_HERE' | translate" pattern="^[a-zA-Z0-9\\-]+$"/>
      </mat-form-field>
      <div *ngIf="submitted || verificationForm.controls.verificationCode?.touched && verificationForm.controls.verificationCode?.errors">
        <div *ngIf="verificationForm.controls.verificationCode?.errors?.required">
          <mat-error class="mat-error-class">
              {{'VERIFICATION_CODE' | translate}} {{'IS_REQUIRED' | translate }}
          </mat-error>
      </div>
      <mat-error class="mat-error-class" *ngIf="verificationForm.controls.verificationCode?.errors?.pattern">
        {{'INVALID_VERIFICATION_CODE' | translate}}
      </mat-error>
      <mat-error class="mat-error-class" *ngIf="verificationForm.controls.verificationCode?.errors?.maxlength">
        {{'VERIFICATION_CODE_SHOULD_BE_OF_MAXIMUM_100_CHARACTERS' | translate}}
      </mat-error>
       </div>
      </form>
    </div>
    <div class="modal-footer">
      <button mat-raised-button class="button footer-button" (click)="onSubmit()">
        <span>{{ 'GENERATE' | translate }}</span>
      </button>
     </div>
  </div>
  
</ng-template>