<div>
  <div class="close-btn"><button class="rb-ic rb-ic-close" [mat-dialog-close]="true"></button></div>
  <div class="popup-body">
    <div class="popup-content">
      <p>{{ 'DISCARDED_DEVICES_LIST' |translate }}</p>
      <mat-table  class="device-list" [dataSource]="dataSource">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>
            <mat-cell class="tooltip " *matCellDef="let dataName">
              <span title="{{dataName[column]}}"
                class="cell-value">
                {{dataName[column]}}
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
      <mat-paginator  [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>
    </div>
  </div>
</div>