<mat-tab-group  #tabs  class="demo-tab-group stretched-tabs"  mat-stretch-tabs (selectedTabChange)="matChange($event)">

  <div *ngFor="let setting of settingsList">
    <mat-tab *ngIf="checkPermission(setting['permission'])">
      <ng-template mat-tab-label>
        <div>{{setting['title'] | translate}}
        </div>
      </ng-template>
      <section class="main">
        <div class="tab-content" *ngIf="checkPermission(permission+'R')">
          <div *ngIf="checkPermission(permission+'C') && setting['title'] !== 'SIM'" class="add-reset-btn">
            <button mat-raised-button (click)="gotoModal(content, pageTitle, dataName)" class="add-btn"
              [disabled]="setting['title'] === 'IOT_HUB' && !selectedProvider || setting['title'] === 'GMAP_CONFIGURATION' && !selectedSolutionType">
              <img [src]="addImage" (click)="gotoModal(content, pageTitle, dataName)"
                [class.disabled]="setting['title'] === 'IOT_HUB' && !selectedProvider || setting['title'] === 'GMAP_CONFIGURATION' && !selectedSolutionType" class="add-image">
              <span class="add-text">{{ 'ADD' | translate }}</span>
            </button>
          </div>
          <div *ngIf="setting['title'] === 'EUA_RESET'" class="add-reset-btn">
            <button mat-raised-button (click)="gotoConfirmDialog(dataName, jsonFileLoaded, 'eua')"
              class="add-btn btn-background">
              {{ 'RESET_EUA' | translate }}
            </button>
          </div>
          <mat-form-field *ngIf="setting['title'] === 'CONFIG_SERVICE' || setting['title'] === 'STORAGE_ACCOUNT'"
            class="dropdown-width">
            <mat-label>{{'SELECT_SOLUTION' | translate}}</mat-label>
            <mat-select placeholder="{{ 'SELECT' | translate }}" (selectionChange)="getDataBySolutionId($event.value)"
              [(ngModel)]="selectedSolution">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of solutionTypeValues" [value]="val['id']" required>{{val['name']}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field *ngIf="setting['title'] === 'IOT_HUB'" class="dropdown-width">
            <mat-label>{{'DEVICE_INTERFACE_PROVIDER' | translate}}</mat-label>
            <mat-select placeholder="{{ 'SELECT' | translate }}" (selectionChange)="getDataByProvider($event.value)"
              [(ngModel)]="selectedProvider">
              <mat-option>{{ 'SELECT' | translate }}</mat-option>
              <mat-option *ngFor="let val of deviceInterfaceProviderValues" [value]="val" required>
                {{val['name'] | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div *ngIf="selectSolutionType">
            <mat-error *ngIf="selectSolutionType" class="mat-error-class">
              {{'SOLUTION_TYPE' | translate}} {{'IS_REQUIRED' | translate }}
            </mat-error>
          </div>
          <div *ngIf="setting['title'] === 'GMAP_CONFIGURATION'">
            <app-dynamic-form [jsonFile]="'gmap-config-filter'"
          [gridLayout]="'grid-container-gmap'" [hideSubmitButton]="false" [dropDownValues]="dropDownValues"  (valueSelected)="updateDropdownValues($event)"
              (formEmitter)="getDataBysolutionTypes()" (resetForm)="onReset()" [emitValueAfterReset]="false"></app-dynamic-form>
          </div>
          <div *ngIf="setting['title'] === 'SIM'">
            <mat-tab-group  #tabs class="simTabs" (selectedTabChange)="SimMatChange($event)"  [(selectedIndex)]="simActiveTabsIndex" (selectedIndexChange)="onTabChange()">
              <mat-tab class="simTabs"  *ngFor="let tab of simMatTabOptions">
                <ng-template mat-tab-label>
                  <div>{{tab.label | translate}}
                  </div>
                </ng-template>
                <div *ngIf="tab['value'] === 'provider-profile'" class="provider-tab">
                  <mat-form-field class="dropdown-width-mapping">
                    <mat-label>{{'PROVIDER_MAPPING' | translate}}
                      <span class="mandatory">*</span>
                    </mat-label>
                    <mat-select [placeholder]="'SELECT' | translate" (selectionChange)="simProviderChange($event.value)" [(ngModel)]="selectedSimProvider">
                    <mat-option>{{ 'SELECT' | translate }}</mat-option>
                    <mat-option *ngFor="let val of providerList" [value]="val['id']" required>{{val['name']}}
                    </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-form-field class="dropdown-width-mapping">
                    <mat-label>{{'PROFILE_MAPPING' | translate}}</mat-label>
                    <mat-select [placeholder]="'SELECT' | translate" multiple
                        [(ngModel)]="selectedProfiles">
                    <mat-option *ngFor="let val of profileList" [value]="val['id']" [selected]="selectedProfiles && selectedProfiles.includes(val['id'])" required>{{val['name']}}
                    </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngIf="requiredError">
                    <mat-error *ngIf="!selectedSimProvider" class="mat-error-class">
                      {{'SIM_PROVIDER_IS_REQUIRED' | translate}} 
                    </mat-error>
                  </div>
                  <div>
                    <button mat-raised-button (click)="saveMappingDetails()" class="add-btn">
                      <span class="add-text">{{ 'SAVE' | translate }}</span>
                    </button>
                  </div>  
                </div>
                <div *ngIf="tab['value'] !== 'provider-profile'" class="add-reset-btn sim-add-button">
                  <button mat-raised-button (click)="gotoModal(content,  setting['title'], dataName)" class="add-btn">
                    <img [src]="addImage" (click)="gotoModal(content, setting['title'], dataName)"
                      class="add-image">
                    <span class="add-text">{{ 'ADD' | translate }}</span>
                  </button>
                </div>
              </mat-tab>
            </mat-tab-group>
            </div>
          <mat-table [dataSource]="data" *ngIf="columns" class="table-bottom-space" [ngClass]="{'table-top-space': tableTopSpace}">
            <ng-container [matColumnDef]="column" *ngFor="let column of columns">
              <mat-header-cell [ngClass]="determineColumnSize(column, setting['title'])" *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase}}
              </mat-header-cell>
              <mat-cell [ngClass]="determineColumnSize(column, setting['title'])" class="tooltip" *matCellDef="let dataName" [ngClass]="column">
                <span *ngIf="column!=='action' && column!=='active' && column !== 'available'"
                  title="{{dataName[column]}}" class="cell-value">
                  {{((dataName[column] && column === 'deviceInterfaceProvider')?((settingType === 'solution-type')?dataName[column][0]:dataName[column] | translate) : dataName[column])}}
                </span>
                <span *ngIf="column==='action' && pageTitle !== 'Interface Type'">
                  <mat-slide-toggle
                    *ngIf="pageTitle !== 'CONFIG_SERVICE' && pageTitle !== 'SUBSCRIPTION' && pageTitle !== 'STORAGE_ACCOUNT' && pageTitle !== 'SIM'"
                    [disabled]="!checkPermission(permission+'ACT') && !checkPermission(permission+'DACT')"
                    [(ngModel)]="dataName['active']" (change)="gotoConfirmDialog(dataName, jsonFileLoaded, 'edit')">
                  </mat-slide-toggle>
                  <img *ngIf="pageTitle === 'GMAP_CONFIGURATION' && dataName['available']" [src]="availableImage" class="icon-space" class="modify-button"
                    [title]="'AVAILABLE' | translate">
                  <img *ngIf="pageTitle === 'GMAP_CONFIGURATION' && !dataName['available']" [src]="notAvailableImage" class="icon-space" class="modify-button"
                    [title]="'NOT_AVAILABLE' | translate">
                  <span *ngIf="checkPermission(permission+'U')">
                    <img [src]="editImage" [class.disabled]="pageTitle !== 'GMAP_CONFIGURATION' && !dataName['active']"
                      (click)="gotoModal(content, pageTitle, dataName)" class="modify-button" [title]="'EDIT' |translate">
                  </span>
                  <span *ngIf="pageTitle == 'SIM'">
                    <img [src]="deleteImage"   (click)="confirmDelete(simMatTabOptions[simActiveTabsIndex].label, dataName['id'])" class="sim-delete" [title]="'DELETE' |translate">
                  </span>
                </span>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
            <mat-row *matRowDef="let data; columns: columns;"></mat-row>
          </mat-table> 
        </div>
      </section>
    </mat-tab>
  </div>

  <ng-template #content let-modal>
    <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
    <div class="modal-width" >
      <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">{{pageTitle | translate}} </h5>
      </div>
      <div *ngIf="!formData" class="modal-body">
        <app-dynamic-form [jsonFile]="jsonFileLoaded" [columnsInForm]="column" [dropDownValues]="dropDownValues"
          (buttonClickEvent)="generateConnectionString($event)"
          (valueSelected)="getSelectedDeviceInterfaceProvider($event)" (formEmitter)="sendData($event,settingType)">
        </app-dynamic-form>
      </div>
      <div *ngIf="formData" class="modal-body">
        <app-dynamic-form [jsonFile]="jsonFileLoaded" [formData]="formData" [columnsInForm]="column" [formArrayLength]="mapProjectApiKeysLength"
          (buttonClickEvent)="generateConnectionString($event)"
          (valueSelected)="getSelectedDeviceInterfaceProvider($event)" [dropDownValues]="dropDownValues"
          (formEmitter)="updateData($event,settingType)"></app-dynamic-form>
      </div>
    </div>
  </ng-template>
</mat-tab-group>