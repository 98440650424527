/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MethodInvokeDto } from '../models/method-invoke-dto';
import { MethodResultDto } from '../models/method-result-dto';
import { TunnelingProtocolDto } from '../models/tunneling-protocol-dto';
import { TunnelingRequestDto } from '../models/tunneling-request-dto';

@Injectable({
  providedIn: 'root',
})
export class C2DDirectMethodControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getProtocols
   */
  static readonly GetProtocolsPath = '/api/v1/protocols';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProtocols()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProtocols$Response(params?: {
  }): Observable<StrictHttpResponse<Array<TunnelingProtocolDto>>> {

    const rb = new RequestBuilder(this.rootUrl, C2DDirectMethodControllerService.GetProtocolsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TunnelingProtocolDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getProtocols$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProtocols(params?: {
  }): Observable<Array<TunnelingProtocolDto>> {

    return this.getProtocols$Response(params).pipe(
      map((r: StrictHttpResponse<Array<TunnelingProtocolDto>>) => r.body as Array<TunnelingProtocolDto>)
    );
  }

  /**
   * Path part for operation invokeReboot
   */
  static readonly InvokeRebootPath = '/api/v1/device/{id}/action/reboot';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invokeReboot()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeReboot$Response(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<StrictHttpResponse<MethodResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, C2DDirectMethodControllerService.InvokeRebootPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MethodResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `invokeReboot$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeReboot(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<MethodResultDto> {

    return this.invokeReboot$Response(params).pipe(
      map((r: StrictHttpResponse<MethodResultDto>) => r.body as MethodResultDto)
    );
  }

  /**
   * Path part for operation getDesiredProperties
   */
  static readonly GetDesiredPropertiesPath = '/api/v1/device/{id}/properties';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDesiredProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDesiredProperties$Response(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<StrictHttpResponse<{
}>> {

    const rb = new RequestBuilder(this.rootUrl, C2DDirectMethodControllerService.GetDesiredPropertiesPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<{
        }>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDesiredProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getDesiredProperties(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<{
}> {

    return this.getDesiredProperties$Response(params).pipe(
      map((r: StrictHttpResponse<{
}>) => r.body as {
})
    );
  }

  /**
   * Path part for operation invokeAppMethod
   */
  static readonly InvokeAppMethodPath = '/api/v1/device/{id}/action';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invokeAppMethod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeAppMethod$Response(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<StrictHttpResponse<MethodResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, C2DDirectMethodControllerService.InvokeAppMethodPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MethodResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `invokeAppMethod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeAppMethod(params: {
    id: string;
    body?: MethodInvokeDto
  }): Observable<MethodResultDto> {

    return this.invokeAppMethod$Response(params).pipe(
      map((r: StrictHttpResponse<MethodResultDto>) => r.body as MethodResultDto)
    );
  }

  /**
   * Path part for operation invokeDeviceTunneling
   */
  static readonly InvokeDeviceTunnelingPath = '/api/v1/tunnel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `invokeDeviceTunneling()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeDeviceTunneling$Response(params?: {
    body?: TunnelingRequestDto
  }): Observable<StrictHttpResponse<MethodResultDto>> {

    const rb = new RequestBuilder(this.rootUrl, C2DDirectMethodControllerService.InvokeDeviceTunnelingPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MethodResultDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `invokeDeviceTunneling$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  invokeDeviceTunneling(params?: {
    body?: TunnelingRequestDto
  }): Observable<MethodResultDto> {

    return this.invokeDeviceTunneling$Response(params).pipe(
      map((r: StrictHttpResponse<MethodResultDto>) => r.body as MethodResultDto)
    );
  }

}
