/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BulkSmsUploadDto } from '../models/bulk-sms-upload-dto';
import { SmsBulkDowloadUrlDto } from '../models/sms-bulk-dowload-url-dto';
import { SmsBulkUploadTemplateUrlDto } from '../models/sms-bulk-upload-template-url-dto';
import { SmsJobDetailsDto } from '../models/sms-job-details-dto';
import { SmsJobsRequestDto } from '../models/sms-jobs-request-dto';
import { SmsJobsResponseDto } from '../models/sms-jobs-response-dto';

@Injectable({
  providedIn: 'root',
})
export class BulkSmsUploadControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation bulkSmsUploadExcel
   */
  static readonly BulkSmsUploadExcelPath = '/api/v1/smsservice/bulk-sms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkSmsUploadExcel()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkSmsUploadExcel$Response(params: {
    bulksmsDto: string;
    body?: { 'multipartFile'?: Blob }
  }): Observable<StrictHttpResponse<BulkSmsUploadDto>> {

    const rb = new RequestBuilder(this.rootUrl, BulkSmsUploadControllerService.BulkSmsUploadExcelPath, 'post');
    if (params) {
      rb.query('bulksmsDto', params.bulksmsDto, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BulkSmsUploadDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `bulkSmsUploadExcel$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  bulkSmsUploadExcel(params: {
    bulksmsDto: string;
    body?: { 'multipartFile'?: Blob }
  }): Observable<BulkSmsUploadDto> {

    return this.bulkSmsUploadExcel$Response(params).pipe(
      map((r: StrictHttpResponse<BulkSmsUploadDto>) => r.body as BulkSmsUploadDto)
    );
  }

  /**
   * Path part for operation getSmsJobsByprofileIdAndSolutionTypeId
   */
  static readonly GetSmsJobsByprofileIdAndSolutionTypeIdPath = '/api/v1/smsservice/bulk-sms/smsjobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsJobsByprofileIdAndSolutionTypeId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSmsJobsByprofileIdAndSolutionTypeId$Response(params?: {
    status?: string;
    body?: SmsJobsRequestDto
  }): Observable<StrictHttpResponse<Array<SmsJobsResponseDto>>> {

    const rb = new RequestBuilder(this.rootUrl, BulkSmsUploadControllerService.GetSmsJobsByprofileIdAndSolutionTypeIdPath, 'post');
    if (params) {
      rb.query('status', params.status, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SmsJobsResponseDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsJobsByprofileIdAndSolutionTypeId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  getSmsJobsByprofileIdAndSolutionTypeId(params?: {
    status?: string;
    body?: SmsJobsRequestDto
  }): Observable<Array<SmsJobsResponseDto>> {

    return this.getSmsJobsByprofileIdAndSolutionTypeId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SmsJobsResponseDto>>) => r.body as Array<SmsJobsResponseDto>)
    );
  }
  /**
   * Path part for operation parseFileAndSmsProcess
   */
  static readonly ParseFileAndSmsProcessPath = '/api/v1/smsservice/bulk-sms/job-scheduler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `parseFileAndSmsProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  parseFileAndSmsProcess$Response(params?: {
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BulkSmsUploadControllerService.ParseFileAndSmsProcessPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `parseFileAndSmsProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  parseFileAndSmsProcess(params?: {
  }): Observable<string> {

    return this.parseFileAndSmsProcess$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation getSmsJobFileUrlByJobName
   */
  static readonly GetSmsJobFileUrlByJobNamePath = '/api/v1/smsservice/bulk-sms/{jobId}/download-job-file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsJobFileUrlByJobName()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobFileUrlByJobName$Response(params: {
    jobId: string;
  }): Observable<StrictHttpResponse<SmsBulkDowloadUrlDto>> {

    const rb = new RequestBuilder(this.rootUrl, BulkSmsUploadControllerService.GetSmsJobFileUrlByJobNamePath, 'get');
    if (params) {
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsBulkDowloadUrlDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsJobFileUrlByJobName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobFileUrlByJobName(params: {
    jobId: string;
  }): Observable<SmsBulkDowloadUrlDto> {

    return this.getSmsJobFileUrlByJobName$Response(params).pipe(
      map((r: StrictHttpResponse<SmsBulkDowloadUrlDto>) => r.body as SmsBulkDowloadUrlDto)
    );
  }

  /**
   * Path part for operation getBulkSmsTemplateUrl
   */
  static readonly GetBulkSmsTemplateUrlPath = '/api/v1/smsservice/bulk-sms/download-template';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBulkSmsTemplateUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBulkSmsTemplateUrl$Response(params?: {
  }): Observable<StrictHttpResponse<SmsBulkUploadTemplateUrlDto>> {

    const rb = new RequestBuilder(this.rootUrl, BulkSmsUploadControllerService.GetBulkSmsTemplateUrlPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsBulkUploadTemplateUrlDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getBulkSmsTemplateUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBulkSmsTemplateUrl(params?: {
  }): Observable<SmsBulkUploadTemplateUrlDto> {

    return this.getBulkSmsTemplateUrl$Response(params).pipe(
      map((r: StrictHttpResponse<SmsBulkUploadTemplateUrlDto>) => r.body as SmsBulkUploadTemplateUrlDto)
    );
  }

  /**
   * Path part for operation cancelSmsJobProcess
   */
  static readonly CancelSmsJobProcessPath = '/api/v1/smsservice/bulk-sms/{jobId}/cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelSmsJobProcess()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelSmsJobProcess$Response(params: {
    jobId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, BulkSmsUploadControllerService.CancelSmsJobProcessPath, 'patch');
    if (params) {
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `cancelSmsJobProcess$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelSmsJobProcess(params: {
    jobId: string;
  }): Observable<string> {

    return this.cancelSmsJobProcess$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }


  /**
   * Path part for operation getSmsJobByJobId
   */
  static readonly GetSmsJobByJobIdPath = '/api/v1/smsservice/bulk-sms/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSmsJobByJobId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobByJobId$Response(params: {
    jobId: string;
  }): Observable<StrictHttpResponse<SmsJobDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, BulkSmsUploadControllerService.GetSmsJobByJobIdPath, 'get');
    if (params) {
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmsJobDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSmsJobByJobId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSmsJobByJobId(params: {
    jobId: string;
  }): Observable<SmsJobDetailsDto> {

    return this.getSmsJobByJobId$Response(params).pipe(
      map((r: StrictHttpResponse<SmsJobDetailsDto>) => r.body as SmsJobDetailsDto)
    );
  }


}
