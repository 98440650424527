<section class="overview-container">
    <section class="main">
        <div class="container">
            <div class="leftSide"  *ngIf="superAgentLogin">
                  <mat-form-field>
                    <mat-label class="label">{{'Chats'|translate}}</mat-label>
                    <mat-select [(value)]="selectedChats" class="input-field" (selectionChange)="onChatChange($event)"
                    [placeholder]="'SELECT' | translate">
                      <mat-option *ngFor="let chat of allChats" [value]="chat.value">{{ chat.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                    <mat-form-field>
                        <mat-label class="label">{{'DEPARTMENT'| translate}}</mat-label>
                        <mat-select  [(value)]="selectedDepartment" class="input-field" (selectionChange)="onDepartmentChange($event)"
                        [placeholder]="'SELECT' | translate">
                          <mat-option>{{ 'SELECT' | translate }}</mat-option>
                          <mat-option *ngFor="let department of allDepartments" [value]="department.id">{{ department.name }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      
                      <mat-form-field>
                        <mat-label class="label">{{'AGENT'|translate}}</mat-label>
                        <mat-select  [(value)]="selectedAgent" class="input-field" (selectionChange)="onAgentChange($event)"
                        [placeholder]="'SELECT' | translate">
                          <mat-option>{{ 'SELECT' | translate }}</mat-option>
                          <mat-option *ngFor="let agent of agentLists" [value]="agent.id">{{ agent.name}}</mat-option>                        </mat-select>
                      </mat-form-field>
            </div>
            <div class="chatRoom">
                <div class="left-header" *ngIf="!superAgentLogin">
                    <mat-form-field>
                        <mat-select [(value)]="selectedStatus" class="input-field" (selectionChange)="onStatusChange($event)">
                            <mat-option *ngFor="let status of allStatuse" [value]="status">{{ status }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <!-- Chat List  -->
                <div class="chatlist" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                [scrollWindow]="false" (scrolled)="onScrollDown()">
                <div *ngIf="noChats && chatList.length == 0" class="no-conversations">
                    {{'NO_CONVERSATION_YET'|translate}}
                </div>
                    <div class="block" *ngFor="let chat of chatList"
                    [ngClass]="{'active': selectedChatId === chat.id, 'unread': chat.unreadCount > 0 && selectedChatId !== chat.id}"
                    (click)="selectChat(chat)">
                        <div class="imgBox">
                            <img [src]="user" class="cover" alt="">
                        </div>
                        <div class="details" >
                            <div class="listHead">
                                <p>{{ chat.userName || chat.phoneNumber }}</p>
                                <p class="time">{{ chat.time }}</p>
                            </div>
                            <div class="message_p">
                                <p>{{ chat.content }}</p>
                                <b *ngIf="chat.unReadCount > 0 && selectedChatId !== chat.id">{{ chat.unReadCount }}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Right side -->
            <div class="rightSide" [ngClass]="{'super-agent': superAgentLogin, 'agent': !superAgentLogin}">
                <div class="header">
                    <div class="imgText">
                        <div class="user-img">
                            <img [src]="user" alt="" class="cover">
                        </div>
                        <h4 *ngIf="userName">{{userName}} <br></h4>
                    </div>
                    <div *ngIf="!isInputDisabled" class="nav_icons">
                        <mat-form-field class="status-form-field">
                            <mat-select [(value)]="selectedChatRoom" class="input-field select-dropdown" (selectionChange)="onChange(content,$event)" [disabled]="!userName">
                                <mat-option *ngFor="let chat of allChatStatus" [value]="chat">{{ chat }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <!-- CHAT-BOX -->
                <div class="chatbox" #chatBox infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
                    [scrollWindow]="false" (scrolledUp)="onScrollUp()">
                    <div class="chat-message">
                        <div class="message" *ngFor="let msg of selectedChatMessages"
                            [ngClass]="{'my_msg': msg.fromAgent, 'friend_msg': !msg.fromAgent}">
                            <div *ngIf="msg.systemMessage" class="session-msg">{{msg.content}}</div>
                                <div *ngIf="!msg.mediaUrl && !msg.systemMessage" class="message-content">
                                    <p [innerHTML]="msg.content | linkify"></p>
                                    <span>{{ msg.time }}</span>
                                 </div>
                            <div *ngIf="msg.mediaUrl" class= "whatsapp-media-content" [ngClass]="{'media-section-agent': msg.fromAgent, 'media-section-cust': !msg.fromAgent}">
                                <div class="media-container">
                                    <img *ngIf="msg.mediaType === 'IMAGE' || msg.mediaType === 'image/jpeg'" 
                                         [src]="msg.mediaUrl" class="image-media" (click)="openImage(msg.mediaUrl)"> 

                                    <video *ngIf="msg.mediaType === 'VIDEO' || msg.mediaType === 'video/mp4'" [src]="msg.mediaUrl" type="video/mp4" class="image-media" controls></video>
                                    <audio *ngIf="msg.mediaType === 'AUDIO' || msg.mediaType === 'audio/mpeg' || msg.mediaType === 'audio/ogg; codecs=opus'" [src]="msg.mediaUrl" class="audio-content" controls>
                                        <source [src]="msg.mediaUrl" type="audio/mpeg">
                                    </audio>
                                    <div *ngIf="msg.mediaType === 'DOCUMENT' || msg.mediaType === 'application/pdf'">
                                        <mat-card class="pdf-preview">
                                            <div>
                                              <img  [src]="pdfIcon" alt="PDF" class="pdf-img"/>
                                            </div>
                                            <div class="file-details">
                                                <!-- hardcoding for time being will be changing to actual filename and size later -->
                                                <h5>Sample.pdf</h5>
                                                <h6>100kb</h6>
                                            </div>
                                            <img (click)="downloadPDF($event, msg.mediaUrl)" class="download-icon" [src]="downloadIcon" alt="Download"/>
                                        </mat-card>
                                    </div>
                                    <div *ngIf="!msg.systemMessage" class="caption-media">{{msg.content}}</div>
                                    <span>{{ msg.time }}</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- CHAT INPUT -->
                <div class="chat_input">
                    <button mat-icon-button [disabled]="isInputDisabled"  (click)="toggleEmojiPicker()"><mat-icon class="rb-ic" fontIcon="bosch-ic-emoji-happy"></mat-icon></button>
                    <button mat-icon-button [disabled]="isInputDisabled" (click)="OpenUploadDialog(UploadContent)"><mat-icon class="rb-ic file-upload-icon" fontIcon="bosch-ic-paperclip" ></mat-icon></button>
                    <input type="text" [placeholder]="'TYPE_MSG' | translate" [(ngModel)]="messageInput"
                        (keyup.enter)="sendChatAgent()"  [disabled]="isInputDisabled">
                    <img [src]="chatSendIcon" class="chat-send" (click)="sendChatAgent()" [title]="'SEND' |translate">
                </div>
                <div class="emoji-left">
                    <emoji-mart class="emoji-mart" set="{{set}}" *ngIf="showEmojiPicker && !isInputDisabled" (emojiSelect)="addEmoji($event)"
                    [emojiTooltip]=true [showPreview]="false" class="emoji-popup"></emoji-mart>
                </div>
            </div>
        </div>
    </section>
</section>

<ng-template #content let-modal>
    <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon></div>
    <div class="modal-width">
      <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">{{modalTitle| translate}} </h5>
      </div>
      <div class="modal-body">
        <app-dynamic-form [jsonFile]="'agent-transfer'" [columnsInForm]="'single'"  (valueSelected)="updateDropdownValues($event)" (formEmitter)="transferAgent($event)"
         [dropDownValues]="dropDownValues">
        </app-dynamic-form>
      </div>
    </div>
  </ng-template>

  <ng-template #UploadContent let-modal>
    <div class="close-btn"><mat-icon class="rb-ic" fontIcon="bosch-ic-close" (click)="onClose()"></mat-icon>
    </div>
    <div class="modal-width">
      <div>
        <div class="modal-header">
          <h5 class="modal-title" id="modal-basic-title">{{'FILE_UPLOAD' | translate}} </h5>
        </div>
        <div class="modal-body">
          <input type="file" class="file-uploader"
            (change)="selectFiles($event)" />
            <input type="text" [placeholder]="'TYPE_MSG' | translate" [(ngModel)]="messageFileInput" class="file-uploader"
                 [disabled]="isInputDisabled">
        </div>
        <div class="modal-footer">
          <button [disabled]="!fileName" (click)="fileUpload()" mat-raised-button
            class="button-upload">{{
            'UPLOAD_FILE' | translate }}</button>
        </div>
      </div>
    </div> 
  </ng-template>