/*!
 * Copyright © 2020-21 Robert Bosch Engineering and Business Solutions Private Limited. All Rights Reserved.
NOTICE:  All information contained herein is, and remains the property of Robert Bosch Engineering and Business Solutions Private Limited.
Dissemination of this information or reproduction of this material is strictly forbidden unless prior written permission is obtained from
Robert Bosch Engineering and Business Solutions Private Limited.
 */

import { Component, OnInit } from '@angular/core';
import { AsideCard } from 'src/app/shared/aside-nav-card/aside-nav-card.component';
import { EnvService } from 'src/app/env.service';
import { SolutionTypeControllerService } from 'src/app/services/Platform/services/solution-type-controller.service';
import { KeycloakService } from 'keycloak-angular';
import { ActivatedRoute } from '@angular/router';
import { TenantControllerService, BusinessProfileControllerService } from 'src/app/services/Platform/services';
import { MANAGE, ARROW_IMAGE, USER_ICON } from 'src/app/shared/constants/strings';
import { RightsideNavitemsService } from 'src/app/providers/rightside-navitems.service';
import { ResponseHandlerService } from 'src/app/providers/response-handler-service';


/**
  * @ngdoc component
  * @name UsersModule.component:manageUser
  *
  *
  * @description
  * description: To route to particular realm management based on selected solution type and business profile.
  *              To directly route to master realm page.
  *
*/
@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {
  keycloakUrl: string;
  public pageKey: string = null;
  asideMenus: Array<AsideCard>;
  aaaUrl: string;
  public dropDownValues: any = {};
  public permissionList: Array<String> = [];
  public jsonFileLoaded;
  realmName: string;
  arrowImage;
  userIcon;
  constructor(private keycloakAngular: KeycloakService,
    private env: EnvService, private businessProfileControllerService: BusinessProfileControllerService,
    private tenantControllerService: TenantControllerService, private solutionTypeControllerService: SolutionTypeControllerService,
    private responseHandlerService: ResponseHandlerService, private route: ActivatedRoute,
    private rightsideNavitemsService: RightsideNavitemsService) { }

  ngOnInit() {
    this.permissionList = this.route.snapshot.data.data['permissionList'];
    if (this.permissionList.indexOf('ST_BP_SER') > -1) {
      this.jsonFileLoaded = 'user-management-solution-type';
      this.getSolutionType();
    } else {
      this.jsonFileLoaded = 'user-management-tenant';
      this.getTenant();
    }
    const userRoles = this.keycloakAngular.getKeycloakInstance().realmAccess;
    // const rolejson = userRoles['roles'];
    this.pageKey = 'user';
    this.aaaUrl = this.env['aaaUrl'];
    this.rightsideNavitemsService.getRightsideNavItems(MANAGE, this.pageKey).then(navItemsList => {
      this.asideMenus = navItemsList as Array<AsideCard>;
    });
    this.arrowImage = ARROW_IMAGE;
    this.userIcon = USER_ICON;
  }

  /**
    * @ngdoc method
    * @name manageUser#getSolutionType
    *
    * @methodOf
    * UsersModule.controller:manageUser
    *
    * @description
    * Description: To populate solution types dropdown
    *
    * @param {type} name description
    * @return {type} name description
  */
  public getSolutionType() {
    this.solutionTypeControllerService
      .findAllSolutionTypes({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['solutionType'] = resp;
          } else {
            this.dropDownValues['solutionType'] = [];
          }
        },
        err => {
          this.dropDownValues['solutionType'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }


  /**
  * @ngdoc method
  * @name manageUser#updateDropdownValues
  *
  * @methodOf
  * UsersModule.controller:manageUser
  *
  * @description
  * Description: To populate dependent dropdown
  *
  * @param {type} name description
  * @return {type} name description
*/
  public updateDropdownValues($event) {
    if ($event.value !== undefined && $event.child === 'businessProfile') {
      if (this.permissionList.indexOf('ST_BP_SER') > -1) {
        this.getBusinessProfiles($event.value);
      } else {
        this.getProfileForTenant($event.value);
      }
    }
    if (this.realmName !== undefined) {
      this.realmName = '';
    }
  }

  /**
  * @ngdoc method
  * @name manageUser#getBusinessProfiles
  *
  * @methodOf
  * UsersModule.controller:manageUser
  *
  * @description
  * Description: Fetches business profiles based on solutionTypeId
  *
  * @param {type} name description
  * @return {type} name description
*/
  public getBusinessProfiles(solutionTypeId) {
    this.solutionTypeControllerService
      .findByBusinessProfilesListBasicForSolutionTypeId({ solutionTypeId: solutionTypeId })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        err => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageUser#getUserInformation
    *
    * @methodOf
    * UsersModule.controller:manageUser
    *
    * @description
    * Description: Updates the realmName to get user information
    *
    * @param {type} name description
    * @return {type} name description
  */
  public getUserInformation(event) {
    const businessProfile = this.dropDownValues['businessProfile'].find(profile => event.businessProfile === profile.id);
    if (businessProfile['realmName']) {
      this.realmName = businessProfile['realmName'];
    } else {
      this.realmName = '';
      this.responseHandlerService.returnToastMessage('error', 'GET_REALM_ERROR');
    }
  }

  /**
    * @ngdoc method
    * @name manageUser#getTenant
    *
    * @methodOf
    * UsersModule.controller:manageUser
    *
    * @description
    * Description: To populate tenant dropdown
    *
    * @param {type} name description
    * @return {type} name description
  */
  public getTenant() {
    this.tenantControllerService
      .findAllTenantsBasic({ active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp['length']) {
            this.dropDownValues['tenant'] = resp;
          } else {
            this.dropDownValues['tenant'] = [];
          }
        },
        err => {
          this.dropDownValues['tenant'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

  /**
    * @ngdoc method
    * @name manageUser#getProfileForTenant
    *
    * @methodOf
    * UsersModule.controller:manageUser
    *
    * @description
    * Description: Fetches business profiles based on tenantId
    *
    * @param {type} name description
    * @return {type} name description
  */
  public getProfileForTenant(tenantId: string) {
    this.businessProfileControllerService.getBusinessProfiles({ tenantId: tenantId, active: 'true' })
      .subscribe(
        resp => {
          if (resp && resp.length) {
            this.dropDownValues['businessProfile'] = resp;
          } else {
            this.dropDownValues['businessProfile'] = [];
          }
        },
        err => {
          this.dropDownValues['businessProfile'] = [];
          this.responseHandlerService.returnToastMessage('error', err.error.message);
        }
      );
  }

   /**
   * @ngdoc method
   * @name manageUser#resetForm
   *
   * @methodOf
   * UsersModule.controller:manageUser
   *
   * @description
   * Description: To reset form if error occurs
   *
   * @param {type} $event
   * @return {type} null
   * **/
    public resetForm($event) {
      this.dropDownValues['businessProfile'] = [];
    }

}
