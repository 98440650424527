import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CanAuthenticationGuard } from '../providers/auth-guard';
import {RegisterSimComponent } from './register-sim/register-sim.component';
import { ManageSimComponent } from './manage-sim/manage-sim.component';
import { ProcureSimComponent } from './procure-sim/procure-sim.component';
import { VendorRegistrationComponent } from './vendor-registration/vendor-registration.component';
import { SimRegistrationComponent } from './sim-registration/sim-registration.component';
const routes: Routes = [
  {
    path: 'sim/register-sim',
    component: RegisterSimComponent,
    canActivate: [CanAuthenticationGuard],
    data: {permission: 'SIM_C', data: {}}
  },
  {
    path: 'sim/manage-sim',
    component: ManageSimComponent,
    canActivate: [CanAuthenticationGuard],
    data: {permission: 'SIM_R', data: {}}
  },
  {
    path: 'sim/vendor-registration',
    component: VendorRegistrationComponent,
    canActivate: [CanAuthenticationGuard],
    data: {permission: 'SIM_C', data: {}}
  },
  {
    path: 'sim/sim-registration',
    component: SimRegistrationComponent,
    canActivate: [CanAuthenticationGuard],
    data: {permission: 'SIM_C', data: {}}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SimRoutingModule { }
