/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ProfileWhatsAppConnectionDto } from '../models/profile-whats-app-connection-dto';
import { WhatsappAnalyticsDto } from '../models/whatsapp-analytics-dto';
import { ProfileWhatsappConnectionsListDto } from '../models/profile-whatsapp-connections-list-dto';
import { ProfileChannelMappingDto } from '../models/profile-channel-mapping-dto';

@Injectable({
  providedIn: 'root',
})
export class WhatsappProfileConnectionDetailsControllerService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation fetchProfileWhatsAppConnectionDetails
   */
  static readonly FetchProfileWhatsAppConnectionDetailsPath = '/api/v1/profile/{profileId}/profile-connection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchProfileWhatsAppConnectionDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchProfileWhatsAppConnectionDetails$Response(params: {
    profileId: string;
  }): Observable<StrictHttpResponse<Array<{[key: string]: string;}>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.FetchProfileWhatsAppConnectionDetailsPath, 'get');
    if (params) {
      rb.path('profileId', params.profileId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<{[key: string]: string;}>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchProfileWhatsAppConnectionDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchProfileWhatsAppConnectionDetails(params: {
    profileId: string;
}): Observable<Array<{[key: string]: string;}>> {
    return this.fetchProfileWhatsAppConnectionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<{[key: string]: string;}>>) => r.body as Array<{
      [key: string]: string;}>)
    );
  }

  /**
   * Path part for operation saveProfileWhatsAppConnectionDetails
   */
  static readonly SaveProfileWhatsAppConnectionDetailsPath = '/api/v1/profile/{profileId}/profile-connection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `saveProfileWhatsAppConnectionDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProfileWhatsAppConnectionDetails$Response(params: {
    profileId: string;
    body: ProfileWhatsappConnectionsListDto
  }): Observable<StrictHttpResponse<ProfileWhatsappConnectionsListDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.SaveProfileWhatsAppConnectionDetailsPath, 'post');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileWhatsappConnectionsListDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `saveProfileWhatsAppConnectionDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  saveProfileWhatsAppConnectionDetails(params: {
    profileId: string;
    body: ProfileWhatsappConnectionsListDto
  }): Observable<ProfileWhatsappConnectionsListDto> {

    return this.saveProfileWhatsAppConnectionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileWhatsappConnectionsListDto>) => r.body as ProfileWhatsappConnectionsListDto)
    );
  }

  /**
   * Path part for operation deleteChannelWhatsAppConnectionDetails
   */
    static readonly DeleteChannelWhatsAppConnectionDetailsPath = '/api/v1/channelDetails/{channelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteChannelWhatsAppConnectionDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChannelWhatsAppConnectionDetails$Response(params: {
      channelId: string;
  }): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.DeleteChannelWhatsAppConnectionDetailsPath, 'delete');
    if (params) {
      rb.path('channelId', params.channelId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteChannelWhatsAppConnectionDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteChannelWhatsAppConnectionDetails(params: {
      channelId: string;
    }
  ): Observable<string> {

    return this.deleteChannelWhatsAppConnectionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateProfileWhatsAppConnectionDetails
   */
  static readonly UpdateProfileWhatsAppConnectionDetailsPath = '/api/v1/profile/{profileId}/profile-connection';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProfileWhatsAppConnectionDetails()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileWhatsAppConnectionDetails$Response(params: {
    profileId: string;
    body?: Array<ProfileWhatsAppConnectionDto>
  }): Observable<StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.UpdateProfileWhatsAppConnectionDetailsPath, 'patch');
    if (params) {
      rb.path('profileId', params.profileId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateProfileWhatsAppConnectionDetails$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateProfileWhatsAppConnectionDetails(params: {
    profileId: string;
    body?: Array<ProfileWhatsAppConnectionDto>
  }): Observable<Array<ProfileWhatsAppConnectionDto>> {

    return this.updateProfileWhatsAppConnectionDetails$Response(params).pipe(
      map((r: StrictHttpResponse<Array<ProfileWhatsAppConnectionDto>>) => r.body as Array<ProfileWhatsAppConnectionDto>)
    );
  }

  /**
   * Path part for operation fetchChannelDetails
   */
  static readonly FetchChannelDetailsPath = '/api/v1/channelDetails/{channelId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fetchChannelDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchChannelDetails$Response(params: {
    channelId: string;  
  }
): Observable<StrictHttpResponse<ProfileChannelMappingDto>> {

    const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.FetchChannelDetailsPath, 'get');
    if (params) {
      rb.path('channelId', params.channelId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProfileChannelMappingDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `fetchChannelDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fetchChannelDetails(params: {
    channelId: string;
  }
): Observable<ProfileChannelMappingDto> {

    return this.fetchChannelDetails$Response(params).pipe(
      map((r: StrictHttpResponse<ProfileChannelMappingDto>) => r.body as ProfileChannelMappingDto)
    );
  }

/**
   * Path part for operation fetchProfileMappedChannels
   */
static readonly FetchProfileMappedChannelsPath = '/api/v1/profile-channels/{profileId}';

/**
 * This method provides access to the full `HttpResponse`, allowing access to response headers.
 * To access only the response body, use `fetchProfileMappedChannels()` instead.
 *
 * This method doesn't expect any request body.
 */
fetchProfileMappedChannels$Response(params: {
  profileId: string;
  
}
): Observable<StrictHttpResponse<Array<ProfileChannelMappingDto>>> {

  const rb = new RequestBuilder(this.rootUrl, WhatsappProfileConnectionDetailsControllerService.FetchProfileMappedChannelsPath, 'get');
  if (params) {
    rb.path('profileId', params.profileId, {});
  }

  return this.http.request(rb.build({
    responseType: 'json',
    accept: 'application/json'
  })).pipe(
    filter((r: any) => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ProfileChannelMappingDto>>;
    })
  );
}

/**
 * This method provides access to only to the response body.
 * To access the full response (for headers, for example), `fetchProfileMappedChannels$Response()` instead.
 *
 * This method doesn't expect any request body.
 */
fetchProfileMappedChannels(params: {
  profileId: string;
}
): Observable<Array<ProfileChannelMappingDto>> {

  return this.fetchProfileMappedChannels$Response(params).pipe(
    map((r: StrictHttpResponse<Array<ProfileChannelMappingDto>>) => r.body as Array<ProfileChannelMappingDto>)
  );
}

}
