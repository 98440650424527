<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon" [src]="billingIcon" />
        <span>{{'BILLING_DATA_MANAGEMENT' |translate}}</span>
      </span>
    </div>
    <div class="panel-body">
      <mat-form-field *ngFor="let dropdownItem of billingDataDropdownList" class="dropdown-width">
        <mat-label class="label">{{dropdownItem.label | translate}}
          <span *ngIf="dropdownItem.required" class="mandatory">*</span>
        </mat-label>
        <mat-select [placeholder]="'SELECT' | translate"
          (selectionChange)="updateDropdownValues($event, dropdownItem['name'], dropdownItem['child'])">
          <mat-option>{{ 'SELECT' | translate }}</mat-option>
          <mat-option *ngFor="let value of dropDownValues[dropdownItem.name]" [value]="value.id" required>
            {{value.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span [class]="" (click)="generateBillingInvoice()">
        <img [src]="generateImage" alt="search">
      </span>
    </div>
    <div class="clear-float"></div>
    <div *ngIf="billingData && billingData.length" class="table-container">
      <mat-table [dataSource]="billingDataSource" class="bottom-space table-data">
        <div *ngFor="let column of columns">
          <ng-container [matColumnDef]="column">
            <mat-header-cell *matHeaderCellDef [ngClass]="column">{{column | appendSpace | titlecase | translate }}
            </mat-header-cell>
            <mat-cell class="tooltip mat-cell" *matCellDef="let dataName" [ngClass]="column">
              <span *ngIf="column !== 'invoiceUrl'" title="{{dataName[column]}}" class="cell-value">
                {{ dataName[column] }}
              </span>
              <span *ngIf="column === 'invoiceUrl'">
                <button *ngIf="dataName['invoiceUrl']">
                  <a href="{{dataName['invoiceUrl']}}" target="_blank">
                    <img [src]="downloadIcon" [title]="'DOWNLOAD_FILE' | translate" /></a>
                </button>
              </span>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row *matRowDef="let data; columns: columns;"></mat-row>
      </mat-table>
    </div>
  </section>
</section>