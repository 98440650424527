<div>
<form  [formGroup]="createForm" class="static-form">
<div class="form-div">
  <mat-form-field class="mat-form-field">
      <mat-label class="label">{{'TAGS' | translate}}</mat-label>
      <mat-chip-list #chipList aria-label="">
        <mat-chip *ngFor="let val of selectedValuesArray" class="mat-chip" [selectable]="selectable"
        [removable]="removable" (removed)="removeEntities(val)" [value]="val">
          {{val | translate }}
          <mat-icon matChipRemove *ngIf="removable" class="rb-ic" fontIcon="bosch-ic-close"></mat-icon>
        </mat-chip>
      </mat-chip-list>
      <input class="mat-chip-input" [formControl]="tags" [placeholder]="'TYPE_HERE' | translate" #inputCtrl [matAutocomplete]="auto"
          [matChipInputFor]="chipList" 
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="addValues($event)">
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectEntitiesFromList($event)">
        <mat-option *ngFor="let val of filteredStrings | async "  [value]="val">
          {{val | translate}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>
</form>
</div>
