<section class="overview-container">
  <section class="main">
    <div class="header-style">
      <span class="heading-label">
        <img class="page-icon-small" [src]="reportIcon" />
        <span>{{'USAGE_REPORTS' |translate}}</span>
      </span>
    </div>
    <span>
      <app-search-component *ngIf="usageReportData" [comboFieldSearchArray]="usageReportData"
        [dropDownValues]="dropDownValues" (valueSelected)="updateDropdownValues($event)"
        (searchFieldEmitter)="searchUsageReportsEmitter($event,'charts')">
      </app-search-component>
    </span>
    <span>
      <div class="button-grp" *ngIf="aggregatedReportsList">
        <button class="button-align btn-background" mat-raised-button
          (click)="viewType === 'charts'? searchUsageReportsEmitter(searchParams,'tabular') : searchUsageReportsEmitter(searchParams,'charts')">{{(viewType === 'charts' ? 'TABULAR_VIEW':'GRAPHICAL_VIEW') | translate}}</button>
      </div>
    </span>
    <section>
      <div #charts id="pie-chart"></div>
    </section>
    <div *ngIf="viewType === 'tabular' && dataSource">
      <div *ngFor="let service of selectedServiceList" class="service-table">
        <h4>{{service | translate}}
          <span><img [src]="downloadIcon" class="download-report-icon" *ngIf="dataSource[service]"
              [title]="'DOWNLOAD_CSV' |translate"
              (click)="exporter?.exportTable('csv',{fileName:'Report_Data-' + service})" /></span>
        </h4>
        <mat-table matTableExporter [dataSource]="dataSource[service]" #exporter="matTableExporter">
          <div *ngFor="let column of columns">
            <ng-container [matColumnDef]="column">
              <mat-header-cell *matHeaderCellDef>{{column | appendSpace | titlecase}}</mat-header-cell>

              <mat-cell class="tooltip " *matCellDef="let dataName">
                <span title="{{dataName[column]}}" class="cell-value">
                  {{dataName[column]}}
                </span>
              </mat-cell>
            </ng-container>
          </div>
          <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
          <mat-row *matRowDef="let data; columns: columns;"></mat-row>
        </mat-table>
      </div>
    </div>
  </section>
</section>
