import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textFormat'
})
export class TextFormatPipe implements PipeTransform {
    transform(value: string | null | undefined): string {
    if (value === null || value === undefined) {
      return '';
    }
      let formattedContent = value;
      formattedContent = formattedContent.replace(/\*([^\*]+)\*/g, '<b>$1</b>');
      formattedContent = formattedContent.replace(/\_([^\_]+)\_/g, '<i>$1</i>');
      formattedContent = formattedContent.replace(/\~([^\~]+)\~/g, '<s>$1</s>');
      return formattedContent;
    }
}